import { createAction } from "redux-actions";

const PULL_NOTIFICATION_BROADCAST_INFO =
  "DIAGNOSTICS/pull_notification_broadcast_info";
const PULL_NOTIFICATION_BROADCAST_INFO_SUCCEEDED =
  "DIAGNOSTICS/pull_notification_broadcast_info_succeeded";
const PULL_NOTIFICATION_BROADCAST_INFO_FAILED =
  "DIAGNOSTICS/pull_notification_broadcast_info_failed";
export const pullNotificationBroadcastInfo = createAction(
  PULL_NOTIFICATION_BROADCAST_INFO,
);
export const pullNotificationBroadcastInfoSucceeded = createAction(
  PULL_NOTIFICATION_BROADCAST_INFO_SUCCEEDED,
);
export const pullNotificationBroadcastInfoFailed = createAction(
  PULL_NOTIFICATION_BROADCAST_INFO_FAILED,
);

const UPDATE_DOCUMENT_WITH_DIAGNOSTICs_DATA =
  "DIAGNOSTICS/update_document_with_diagnostics_data";
export const updateDocumentWithDiagnosticsData = createAction(
  UPDATE_DOCUMENT_WITH_DIAGNOSTICs_DATA,
);
