import { createAction } from "redux-actions";

const INIT_DEADLINE_VOTES = "VOTES/init_deadline_votes";
const INIT_VOTES_SUCCEEDED = "VOTES/init_votes_succeeded";

export const GET_VOTES = "VOTES/get_votes";
export const GET_VOTES_SUCCEEDED = "VOTES/get_votes_succeeded";

export const CREATE_VOTE = "VOTES/create_new_vote";
export const CAST_NEW_VOTE_SUCCEEDED = "VOTES/cast_new_vote_succeeded";

export const UPDATE_VOTE = "VOTES/update_vote";
export const UPDATE_VOTE_SUCCEEDED = "VOTES/update_vote_succeeded";

export const INIT_VOTES_MEMBER_NAMES = "VOTES/init_votes_member_names";
export const INIT_VOTES_MEMBER_NAMES_SUCCEEDED =
  "VOTES/init_votes_member_names_succeeded";

export const REMOVE_VOTE = "VOTES/remove_vote";
export const REMOVE_VOTE_SUCCEEDED = "VOTES/remove_vote_succeeded";

const OVERRIDE_OFFICIAL_VOTE = "VOTES/override_official_vote";
const OVERRIDE_OFFICIAL_VOTE_SUCCEEDED =
  "VOTES/override_official_vote_succeeded";

export const initDeadlineVotes = createAction(INIT_DEADLINE_VOTES);
export const initVotesSucceeded = createAction(INIT_VOTES_SUCCEEDED);

export const getAllVotes = createAction(GET_VOTES);
export const getAllVotesSucceeded = createAction(GET_VOTES_SUCCEEDED);

export const createVote = createAction(CREATE_VOTE);
export const castNewVoteSucceeded = createAction(CAST_NEW_VOTE_SUCCEEDED);

export const updateVote = createAction(UPDATE_VOTE);
export const updateVoteSucceeded = createAction(UPDATE_VOTE_SUCCEEDED);

export const initVotesMemberNames = createAction(INIT_VOTES_MEMBER_NAMES);
export const initVotesMemberNamesSucceeded = createAction(
  INIT_VOTES_MEMBER_NAMES_SUCCEEDED,
);

export const removeVote = createAction(REMOVE_VOTE);
export const removeVoteSucceeded = createAction(REMOVE_VOTE_SUCCEEDED);

export const overrideOfficialVote = createAction(OVERRIDE_OFFICIAL_VOTE);
export const overrideOfficialVoteSucceeded = createAction(
  OVERRIDE_OFFICIAL_VOTE_SUCCEEDED,
);
