import React, { Component } from "react";
import { withTranslation } from "react-i18next";

import ModalWrapper from "../../../../common/modal-wrapper/ModalWrapper";
import DeleteModal from "../../../documents/components/modal-content/DeleteModal";

class DeadlineDeleteModal extends Component {
  render() {
    const { t, showModal, onCancel, onSubmit, itemUri, itemTitle } = this.props;
    return (
      <ModalWrapper
        isOpen={showModal}
        requestCloseHandler={onCancel}
        disableSlide
      >
        <DeleteModal
          id={itemUri}
          title={t("general.dialog.delete.title", { itemName: itemTitle })}
          message={t("general.dialog.delete.message", { itemName: itemTitle })}
          submitHandler={onSubmit}
          cancelHandler={onCancel}
        />
      </ModalWrapper>
    );
  }
}

export default withTranslation("common")(DeadlineDeleteModal);
