import React from "react";
import { useTranslation } from "react-i18next";

const AboutConfirmationModal = (props) => {
  const { cancelHandler, submitHandler, id } = props;
  const { t } = useTranslation("common");

  const handleSubmit = () => {
    submitHandler();
  };

  return (
    <>
      <div className="group group--first group--full-underlined">
        <h3 className="headline-4">{t("about.modals.warning")}</h3>
      </div>
      <div
        className="group group--last-underlined"
        style={{ marginBottom: 10 }}
      >
        <h3>{t("about.modals.editWarning")}</h3>
      </div>
      <div className="group group--btn u-align-right">
        <button className="button" onClick={() => cancelHandler(id)}>
          {t("general.button.cancel")}
        </button>
        <button className="button button--primary" onClick={handleSubmit}>
          {t("general.button.continue")}
        </button>
      </div>
    </>
  );
};

export default AboutConfirmationModal;
