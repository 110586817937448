import React, {
  useState,
  useEffect,
  useCallback,
  forwardRef,
  useImperativeHandle,
} from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import classNames from "classnames";
import MeetingList from "../../../meetings/pages/meeting-tab/MeetingList";
import Sidebar from "../../../meetings/components/sidebar/Sidebar";
import {
  getMeetings,
  getMeetingsFilters,
  isProcessingMeetings,
} from "../../../../../state/meetings/selectors";
import {
  initMeetings,
  resetMeetingsFilters,
  setMeetingsFilterValue,
} from "../../../../../state/meetings/actions";
import { TIMEFRAME } from "../../../../../api/utils";
import {
  SlimTab,
  SlimTabsSwitcher,
} from "../../../../common/slim-tabs/SlimTabs";
import {
  SHOW_PAST_MEETINGS,
  SHOW_UPCOMING_MEETINGS,
} from "../../../meetings/pages/meeting-tab/MeetingTab";

export const ReferenceMeetingList = forwardRef((props, ref) => {
  const { t } = useTranslation("common");
  const dispatch = useDispatch();
  const history = useHistory();

  const meetings = useSelector(getMeetings);
  const filters = useSelector(getMeetingsFilters);
  const isProcessing = useSelector(isProcessingMeetings);

  const [selectedMeetings, setSelectedMeetings] = useState([]);

  useEffect(() => {
    setSelectedMeetings([...(props.selectedMeetings ?? [])]);
    dispatch(resetMeetingsFilters());
    dispatch(initMeetings());
  }, [props.selectedMeetings, dispatch]);

  useImperativeHandle(ref, () => ({
    onSelect() {
      onAddSelectedMeetingsHandler();
    },
  }));

  const onClickCheckbox = (element, isChecked) => {
    if (isChecked) {
      setSelectedMeetings((prevState) => [...prevState, element]);
    } else {
      setSelectedMeetings((prevState) =>
        prevState.filter((el) => el.uri !== element.uri),
      );
    }
  };

  const showSetEmptyButton = useCallback(selectedMeetings.length === 0, [
    selectedMeetings,
  ]);

  const onLeave = useCallback(() => {
    if (props.isModal) {
      props.onSelect();
    } else {
      history.push(props.basePath);
    }
  }, [props, history]);

  const onMeetingSelectorCancelClick = () => {
    onLeave();
  };

  const onAddSelectedMeetingsHandler = () => {
    props.onSetMeeting(selectedMeetings);
    onLeave();
  };

  const handleUpcomingFilter = (tab) => {
    dispatch(
      setMeetingsFilterValue({
        timeframe:
          tab === SHOW_PAST_MEETINGS ? TIMEFRAME.PAST : TIMEFRAME.FUTURE,
        month: null,
      }),
    );
    dispatch(initMeetings());
  };

  const renderButtons = () => (
    <div className="documents__buttons">
      <button
        className={classNames("button button--small", {
          "button--disabled": isProcessing,
        })}
        onClick={onMeetingSelectorCancelClick}
      >
        {t("general.button.cancel")}
      </button>

      <button
        className={classNames("button button--small button--primary", {
          "button--disabled": isProcessing,
        })}
        onClick={onAddSelectedMeetingsHandler}
      >
        {!showSetEmptyButton
          ? t("general.button.addSelected")
          : t("references.meeting.setEmptyList")}
      </button>
    </div>
  );

  return (
    <div className="tab-content references">
      <div className="detail__form">
        <div className="group group--small group__flex detail__group group--underlined">
          <span className="headline-4 u-text-bold" id="documents">
            {t("meetings.header.name")}
          </span>
          {!props.isModal && renderButtons()}
        </div>
        <div className="layout__content layout__content--with-cards meetings">
          <div className="layout__sidebar">
            <Sidebar disableSticky />
          </div>
          <div className="layout__content">
            <SlimTabsSwitcher
              onTab={handleUpcomingFilter}
              defaultTab={SHOW_UPCOMING_MEETINGS}
              selectedTab={
                filters.timeframe === TIMEFRAME.PAST
                  ? SHOW_PAST_MEETINGS
                  : SHOW_UPCOMING_MEETINGS
              }
              enabled={!isProcessing}
            >
              <SlimTab
                label={t("meetings.sidebar.showUpcoming")}
                name={SHOW_UPCOMING_MEETINGS}
              />
              <SlimTab
                label={t("meetings.sidebar.showPast")}
                name={SHOW_PAST_MEETINGS}
              />
            </SlimTabsSwitcher>
          </div>
          <div className="layout__cards">
            <MeetingList
              useTable
              useCheckbox
              onClickCheckbox={onClickCheckbox}
              meetings={meetings}
              selectedMeetings={selectedMeetings}
            />
          </div>
        </div>
      </div>
    </div>
  );
});
