import React, { Fragment, createRef } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import DeadlineCard from "../../components/deadline-card/DeadlineCard";
import {
  isFetchingDeadlines,
  hasMoreDeadlinesToLoad,
} from "../../../../../state/deadlines/selectors";

import "./DeadlineTab.scss";
import history from "../../../../../utils/history";
import {
  deleteDeadline,
  initDeadlines,
  pullDeadlinesPageRequest,
  updateIsRegistered,
} from "../../../../../state/deadlines/actions";

import {
  generateDialogLink,
  DIALOG_TYPES,
} from "../../../../../utils/dynamicLink";
import { isConsultant, userId } from "../../../../../state/user/selectors";
import { voteStatistics, votes } from "../../../../../state/votes/selectors";
import Loader from "../../../../common/loader/Loader";
import { InfiniteWaypointScroller } from "../../../../common/infinite-scroll-wrapper/InfiniteScrollWrapper";
import { findUserVote } from "../../../../../services/votes/userVote";

import AnalyticsTracking, {
  ResourcesTypes,
} from "../../../../../services/analytics-tracking/analytics-tracking";
import DeadlineTable from "./DeadlineTable";
import { getDeadlineStagesSelectItems } from "../../../../../state/values/selectors";
import { createNotificationFromDeadline } from "../../../../../state/notifications/actions";
import DeadlineDeleteModal from "../../components/delete-modal/DeadlineDeleteModal";

class DeadlineList extends React.Component {
  infinityLoaderRef = createRef();
  state = {
    isDeleteModalOpen: false,
    deadlineToDelete: {},
    markedAsRemoved: "",
  };

  getStageLabel(value) {
    const stages = this.props.deadlineStages;

    var stage = value ? stages.find((s) => s.value === `${value}`) : null;
    return stage ? stage.label : null;
  }

  onCancelDelete = (uri) => {
    AnalyticsTracking.closeDeleteDialog(ResourcesTypes.deadline, uri);
    this.setState({ isDeleteModalOpen: false });
  };

  onSubmitDelete = (uri) => {
    AnalyticsTracking.deletionConfirmed(ResourcesTypes.deadline, uri);
    this.props.deleteDeadline(uri);
    this.setState({ isDeleteModalOpen: false, markedAsRemoved: uri });
  };

  getDeadlineActions(data) {
    const { t, isConsultant } = this.props;
    if (!isConsultant) {
      return [];
    }

    const { uri: deadlineUri, isRegistered } = data;

    const deleteDeadlineActionCallback = () => {
      AnalyticsTracking.showDeleteDialog(ResourcesTypes.deadline, deadlineUri);
      this.setState({ isDeleteModalOpen: true, deadlineToDelete: data });
    };

    const createNotificationFromDeadline = () => {
      this.props.createNotificationFromDeadline(data);
    };

    const editDeadlineActionCallback = () => {
      history.push(generateDialogLink(DIALOG_TYPES.EDIT_DEADLINE, deadlineUri));
    };

    const updateIsRegistered = () => {
      this.props.updateIsRegistered({
        deadlineUri: deadlineUri,
        isRegistered: !isRegistered,
      });
    };

    return [
      {
        label: t("deadlines.details.cards.editDeadline"),
        callback: editDeadlineActionCallback,
      },
      {
        label: t("deadlines.details.cards.createNotification"),
        callback: createNotificationFromDeadline,
      },
      {
        label: t("deadlines.details.cards.deleteDeadline"),
        callback: deleteDeadlineActionCallback,
      },
      {
        label: isRegistered
          ? t("card.unmarkAsRegistered")
          : t("card.markAsRegistered"),
        callback: updateIsRegistered,
      },
    ];
  }

  renderTable = (deadlines) => {
    const { useCheckbox, onClickCheckbox, selectedDeadlines } = this.props;
    return (
      <DeadlineTable
        deadlines={deadlines}
        useCheckbox={useCheckbox}
        onClickCheckbox={onClickCheckbox}
        selectedDeadlines={selectedDeadlines}
      />
    );
  };

  renderCard = (deadline, i) => {
    const { voteStatistics, userId, votes } = this.props;

    return (
      <DeadlineCard
        key={i}
        //highlight={transformedUri === this.state.lastOppendDeadlineId}
        active={deadline.uri !== this.state.markedAsRemoved}
        deadline={deadline}
        votes={voteStatistics[deadline.uri]}
        userVote={findUserVote(votes, deadline.uri, userId)}
        stage={this.getStageLabel(deadline.stage)}
        callbackAction={initDeadlines}
        customActions={this.getDeadlineActions(deadline)}
      />
    );
  };

  renderItems() {
    const {
      t,
      i18n,
      deadlines,
      hasMoreDeadlinesToLoad,
      isFetchingDeadlines,
      useTable,
    } = this.props;

    if (
      !hasMoreDeadlinesToLoad &&
      !isFetchingDeadlines &&
      (!Object.keys(deadlines) || !Object.keys(deadlines).length)
    ) {
      return (
        <p
          key="nothing_to_show"
          className="headline-4 deadlines__nothing-to-show"
        >
          {t("general.content.nothingToShow")}
        </p>
      );
    }

    const deadlinesFlatten = Object.keys(deadlines).reduce(
      (acc, month) => [...acc, ...deadlines[month]],
      [],
    );

    // ## without month year heading
    return useTable
      ? this.renderTable(deadlinesFlatten)
      : deadlinesFlatten.map(this.renderCard);

    // ## with month year heading. DO NOT DELETE this - most probably will need to bring it back.
    // return Object.keys(deadlines).map((month, index) => {

    //   const monthYearHeading = new Date(month)
    //     .toLocaleDateString(i18n.language, { month: "long", year: "numeric" })
    //     .toUpperCase();

    //   return (
    //     <Fragment key={index}>
    //       <span className="card__title">{monthYearHeading}</span>
    //       {useTable
    //         ? this.renderTable(deadlines[month])
    //         : deadlines[month].map(this.renderCard)}
    //     </Fragment>
    //   );
    // });
  }

  loadMoreItems = (page) => {
    this.props.pullDeadlinesPageRequest({ page });
  };

  render() {
    return (
      <div className="layout__cards">
        {/* <InfiniteScrollWrapper */}
        <InfiniteWaypointScroller
          pageStart={0}
          initialLoad={false}
          loadMore={this.loadMoreItems}
          hasMore={this.props.hasMoreDeadlinesToLoad}
          loader={<Loader key="infinity_scroll_loader" type="content" />}
          // isModal={this.props.isModal}
        >
          {this.renderItems()}
        </InfiniteWaypointScroller>
        <DeadlineDeleteModal
          showModal={this.state.isDeleteModalOpen}
          onCancel={this.onCancelDelete}
          onSubmit={this.onSubmitDelete}
          itemUri={this.state.deadlineToDelete.uri}
          itemTitle={this.state.deadlineToDelete.title}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isFetchingDeadlines: isFetchingDeadlines(state),
  votes: votes(state),
  voteStatistics: voteStatistics(state),
  hasMoreDeadlinesToLoad: hasMoreDeadlinesToLoad(state),
  userId: userId(state),
  isConsultant: isConsultant(state),
  deadlineStages: getDeadlineStagesSelectItems(state),
});

const mapDispatchToProps = {
  initDeadlines,
  pullDeadlinesPageRequest,
  createNotificationFromDeadline,
  deleteDeadline,
  updateIsRegistered,
};

export default compose(
  withTranslation("common"),
  connect(mapStateToProps, mapDispatchToProps),
)(DeadlineList);
