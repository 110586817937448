import { createAction } from "redux-actions";

export const GET_PUBLISHED_STANDARDS =
  "PUBLISHED_STANDARDS/get_published_standards";
export const GET_PUBLISHED_STANDARDS_SUCCEEDED =
  "PUBLISHED_STANDARDS/get_published_standards_succeeded";
export const GET_PUBLISHED_STANDARDS_FAILED =
  "PUBLISHED_STANDARDS/get_published_standards_failed";

export const getPublishedStandards = createAction(GET_PUBLISHED_STANDARDS);
export const getPublishedStandardsSucceeded = createAction(
  GET_PUBLISHED_STANDARDS_SUCCEEDED,
);
export const getPublishedStandardsFailed = createAction(
  GET_PUBLISHED_STANDARDS_FAILED,
);

const SET_PUBLISHED_STANDARDS_CURRENT_PAGE =
  "PUBLISHED_STANDARDS/set_current_page";
export const setPublishedStandardsCurrentPage = createAction(
  SET_PUBLISHED_STANDARDS_CURRENT_PAGE,
);

const FILTER_PUBLISHED_STANDARDS_REQUEST =
  "PUBLISHED_STANDARDS/filter_notifications_request";
const FILTER_PUBLISHED_STANDARDS_SUCCEEDED =
  "PUBLISHED_STANDARDS/filter_notifications_succeeded";
const FILTER_PUBLISHED_STANDARDS_FAILED =
  "PUBLISHED_STANDARDS/filter_notifications_failed";

export const filterPublishedStandardsRequest = createAction(
  FILTER_PUBLISHED_STANDARDS_REQUEST,
);
export const filterPublishedStandardsSucceeded = createAction(
  FILTER_PUBLISHED_STANDARDS_SUCCEEDED,
);
export const filterPublishedStandardsFailed = createAction(
  FILTER_PUBLISHED_STANDARDS_FAILED,
);

const SET_STANDARDS_SORTING_TYPE = "PUBLISHED_STANDARDS/set_sorting_type";
export const setPublishedStandardSortingType = createAction(
  SET_STANDARDS_SORTING_TYPE,
);
