import i18next from "i18next";
import en from "../translations/en/common.json";
import da from "../translations/da/common.json";

const i18Init = (language) => {
  return i18next.init({
    react: {
      useSuspense: false,
      wait: false,
    },
    interpolation: { escapeValue: false },
    lng: language,
    resources: {
      en: {
        common: en,
      },
      da: {
        common: da,
      },
    },
  });
};

export default i18Init;
