import { createSelector } from "reselect";
import { normalizeCommitteesList } from "./normalizer";

export const nationalCommittees = (state) => state.nationalCommittees;
export const rawUserNationalCommittees = (state) =>
  state.nationalCommittees.data;
export const userNationalCommittees = (state) =>
  Object.values(state.nationalCommittees.data);
export const selectedNationalCommittee = (state) =>
  state.nationalCommittees.selectedCommittee;

export const getSelectedNationalCommitteeTitle = createSelector(
  [nationalCommittees],
  (nationalCommittees) => {
    const selectedCommittee = nationalCommittees.selectedCommittee;
    if (nationalCommittees.data.hasOwnProperty(selectedCommittee)) {
      return nationalCommittees.data[selectedCommittee].title;
    }
    return null;
  },
);

export const getInternationalCommittees = createSelector(
  [rawUserNationalCommittees, selectedNationalCommittee],
  (rawUserNationalCommittees, selectedNationalCommittee) => {
    const committee = rawUserNationalCommittees[selectedNationalCommittee];
    return committee && committee.internationalCommittees
      ? committee.internationalCommittees.map((item) => ({
          ...item,
          label: item.name + " - " + item.description,
        }))
      : [];
  },
);

export const getInternationalCommitteesCount = createSelector(
  [rawUserNationalCommittees, selectedNationalCommittee],
  (rawUserNationalCommittees, selectedNationalCommittee) => {
    const committee = rawUserNationalCommittees[selectedNationalCommittee];
    return committee && committee.internationalCommittees
      ? committee.internationalCommittees.length
      : 0;
  },
);

export const getCommitteesList = createSelector(
  [rawUserNationalCommittees, selectedNationalCommittee],
  (rawUserNationalCommittees, selectedNationalCommittee) => {
    const committee = rawUserNationalCommittees[selectedNationalCommittee];
    return committee && committee.internationalCommittees
      ? normalizeCommitteesList(committee.internationalCommittees)
      : [];
  },
);

// the provider can resolve national and internal committee title
export const getCommitteeTitleProvider = createSelector(
  [rawUserNationalCommittees, selectedNationalCommittee],
  (rawUserNationalCommittees, selectedNationalCommittee) => {
    return (committeeName) => {
      const nationalCommitee =
        rawUserNationalCommittees[selectedNationalCommittee];
      if (nationalCommitee) {
        if (committeeName == selectedNationalCommittee)
          return nationalCommitee.title;

        if (nationalCommitee.internationalCommittees) {
          const committee = nationalCommitee.internationalCommittees.find(
            (c) => c.name == committeeName,
          );
          if (committee) return committee.description;
        }
      }
      return null;
    };
  },
);
