import { handleActions } from "redux-actions";
import * as actions from "./actions";
import { normalizeStandards } from "./normalizer";

const initialState = {
  isFetching: true,
  isFetched: false,
  data: {},
  errorMessage: null,
  pagination: {
    currentPage: 1,
  },
  sortingType: null,
};

const actionHandlers = {
  [actions.getPublishedStandards]: (state) => ({
    ...state,
    isFetching: true,
    isFetched: false,
  }),
  [actions.getPublishedStandardsSucceeded]: (state, { payload }) => ({
    ...state,
    isFetching: false,
    isFetched: true,
    data: {
      ...state.data,
      ...normalizeStandards(payload),
    },
  }),
  [actions.getPublishedStandardsFailed]: (state, { payload }) => ({
    ...state,
    isFetching: false,
    isFetched: true,
    errorMessage: payload,
  }),
  [actions.setPublishedStandardSortingType]: (state, { payload }) => ({
    ...state,
    sortingType: payload.value,
  }),
};

export default handleActions(actionHandlers, initialState);
