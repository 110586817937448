export function shouldRenderDocumentId(id) {
  return id && parseInt(id) !== 0;
}

export const documentFilters = {
  filterEmptyDocuments: (responses) => {
    return responses?.filter((d) => d !== null && d.status === 200);
  },

  // filterPassedDSFDocuments: (documents) =>  {
  //     const now = new Date();
  //     function filter(d) {atob
  //         try {
  //             const data = d.data.metadata;
  //             if(data.documentType === "DSF" && data.documentSubType === "90" && data.documentDeadline) {
  //                 return new Date(data.documentDeadline) > now;
  //             }
  //         } catch(e) {
  //             console.error(e);
  //         }
  //         return true;
  //     }

  //     return documents.filter(filter)
  // }
};
