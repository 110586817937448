import {
  takeLatest,
  call,
  select,
  put,
  take,
  all,
  takeLeading,
} from "redux-saga/effects";
import { selectedNationalCommittee } from "../state/national-committees/selectors";
import {
  getDeadlineUrl,
  getDeadlinesUrl,
  getAggDeadlinesUrl,
  createDeadlineUrl,
  updateDeadlineUrl,
  deleteDeadlineUrl,
  getRelatedDeadlinesUrl,
  updateIsRegisteredUrl,
} from "../api/resources/deadlines";
import { getDocumentSafeUrl } from "../api/resources/document";
import {
  waitForNotificationDocumentId,
  waitForNotificationTypedDocumentId,
} from "../api/resources/notifications";
import { addGeneralDeadlineInformation } from "../state/deadlines/actions";
import * as actions from "../state/deadlines/actions";
import {
  generateDialogLink,
  DIALOG_TYPES,
  generateCloseDialogLink,
  generateDialogLinkForPage,
  PAGE_TYPES,
} from "../utils/dynamicLink";
import {
  transformCommitteeName,
  handleOtherCommitteeId,
} from "../utils/transform";
import history from "../utils/history";
import { addPageQueryParam } from "../api/utils";
import { initDocuments } from "../state/document/actions";
import * as documentActions from "../state/document/actions";
import {
  getNewDeadlineFormData,
  getDeadlinesFilters,
} from "../state/deadlines/selectors";
import * as selectors from "../state/deadlines/selectors";
import * as userSelectors from "../state/user/selectors";
import { mapToApiModel } from "../api/mappers/deadline";
import { buildQueryParams } from "../services/deadlines/url";
import { normalizeDeadline } from "../state/deadlines/normalizer";
import { handleCheckUserPrivilleges } from "./user";
import {
  fetchDeadlineCommentsStatistics,
  fetchDeadlinesCommentsStatistics,
} from "./comments";
import {
  normalizeDocument,
  removeReferenceById,
} from "../state/document/normalizers.js";

import { fetchDeadlinesVotesStats } from "./votes";
import { notificationActionType, notificationType } from "../utils/constants";
import { documentFilters } from "../utils/documentUtils";
import { getMeetingUrl } from "../api/resources/meetings";
import { referenceListToReferenceDataPerType } from "../utils/references";
import { normalizeObjectOfReferences } from "../state/references/normalizers";
import { handleCreateNotificationBySystem } from "./notifications";

function* handleInitDeadlines(action) {
  yield call(handlePullDeadlinesPageRequest);
}

// reload from edit page would come here
function* handleInitDeadlineForm(action) {
  try {
    yield put(initDocuments());

    const deadlineUri = action.payload;
    let deadline = yield select(selectors.getDeadline);

    if (!deadline || deadline.uri !== deadlineUri) {
      const deadlineData = yield call(getDeadlineUrl, deadlineUri);
      deadline = normalizeDeadline({ deadline: deadlineData.data });

      const documentUri = [
        ...(deadline.referencesRawResponsePerType.document?.map(
          (doc) => doc.uri,
        ) ?? []),
      ];
      const meetingUri = [
        ...(deadline.referencesRawResponsePerType.meeting?.map(
          (doc) => doc.uri,
        ) ?? []),
      ];

      let documents = yield all(
        documentUri?.map((uri) => call(getDocumentSafeUrl, uri)),
      );
      documents = documentFilters.filterEmptyDocuments(documents);

      let meetings = yield all(
        meetingUri?.map((uri) => call(getMeetingUrl, uri)),
      );
      meetings = documentFilters.filterEmptyDocuments(meetings);

      deadline.referencesPerType = normalizeObjectOfReferences({
        document: documents,
        meeting: meetings,
      });
    }

    yield put(addGeneralDeadlineInformation(deadline));
  } catch (error) {
    console.error(`init deadline form failed - ${error}`);
  }
}

function* handleAddDeadlineReferences(action) {
  try {
    // Shouldn't come here
    throw new Error("handleAddDeadlineReferences");

    // const deadline = yield select(selectors.getDeadline);
    // const documentToAdd = normalizeDocument(action.payload);
    // // THIS SHOULD BE FIXED

    // deadline.references = [...deadline.references, documentToAdd];

    // const response = yield call(updateDeadline, deadline, false);

    // if (response.status === 200) {
    //   yield put(actions.fetchCurrentDeadlineReferences());
    //   yield put(actions.updateDeadlineSucceeded(response.data));
    // } else {
    //   yield put(actions.updateDeadlineFailed("Failed to update deadline"));
    // }
  } catch (error) {
    yield put(actions.updateDeadlineFailed("Failed to update deadline"));
    console.error(`handleAddDeadlineReferences failed - ${error}`);
  }
}

function* handleSetDeadlineReferences(action) {
  try {
    // Shouldn't come here
    throw new Error("handleAddDeadlineReferences");

    // const deadline = yield select(selectors.getDeadline);

    // const response = yield call(updateDeadline, deadline, false);

    // if (response.status === 200) {
    //   yield put(actions.fetchCurrentDeadlineReferences());
    //   yield put(actions.updateDeadlineSucceeded(response.data));
    // } else {
    //   yield put(actions.updateDeadlineFailed("Failed to update deadline"));
    // }
  } catch (error) {
    yield put(actions.updateDeadlineFailed("Failed to update deadline"));
    console.error(`handleSetDeadlineReferences failed - ${error}`);
  }
}

function* handleRemoveReferenceFromDeadline(action) {
  try {
    const deadline = yield select(selectors.getDeadline);

    // for(const key in deadline.referencesPerType) {
    //   deadline.referencesPerType[key] = deadline.referencesPerType[key].filter((doc) => doc.uri !== action.payload);
    // }
    deadline.referencesPerType = removeReferenceById(
      deadline.referencesPerType,
      action.payload,
    );

    const response = yield call(updateDeadlineUrl, deadline, false);

    if (response.status === 200) {
      yield put(actions.fetchCurrentDeadlineReferences());
      yield put(actions.updateDeadlineSucceeded(response.data));
    } else {
      yield put(actions.updateDeadlineFailed("Failed to update deadline"));
    }
  } catch (error) {
    yield put(actions.updateDeadlineFailed("Failed to update deadline"));
    console.error(`handleRemoveReferenceFromDeadline failed - ${error}`);
  }
}

function* fetchDeadline(action) {
  try {
    const response = yield call(getDeadlineUrl, action.payload);
    const deadlineCommentsStatistics = yield call(
      fetchDeadlineCommentsStatistics,
      response.data.uri,
    );

    if (response && response.status === 200) {
      yield put(
        actions.fetchDeadlineSucceeded({
          deadline: {
            ...response.data,
          },
          commentsStats: deadlineCommentsStatistics.statistics,
        }),
      );
    } else {
      yield put(actions.fetchDeadlineFailed(response.data));
    }
  } catch (error) {
    console.error(`fetch deadline failed - ${error}`);
    yield put(actions.fetchDeadlineFailed(""));
  }
}

function* fetchDeadlineReferences() {
  try {
    yield take(actions.fetchDeadlineSucceeded);
    const deadline = yield select(selectors.getDeadline);

    const documentTargets =
      deadline.referencesRawResponsePerType.document ?? [];
    const meetingTargets = deadline.referencesRawResponsePerType.meeting ?? [];

    const documentsResponse = yield all(
      documentTargets.map((element) => call(getDocumentSafeUrl, element.uri)),
    );
    const documents = documentFilters.filterEmptyDocuments(documentsResponse);

    const meetingsResponse = yield all(
      meetingTargets.map((element) => call(getMeetingUrl, element.uri)),
    );
    const meetings = documentFilters.filterEmptyDocuments(meetingsResponse);

    const referencesPerType = { document: documents, meeting: meetings };

    yield put(actions.fetchDeadlineReferencesSucceeded(referencesPerType));
  } catch (error) {
    console.error(`fetch deadline references failed - ${error}`);
  }
}

function* fetchCurrentDeadlineReferences() {
  try {
    const existingDeadline = yield select(selectors.getDeadline);
    const response = yield call(getDeadlineUrl, existingDeadline.uri);

    if (!response || response.status !== 200) {
      yield put(actions.fetchCurrentDeadlineReferencesFailed());
      return;
    }

    const { references } = response.data;
    const referenceCategorized =
      referenceListToReferenceDataPerType(references);

    const documentsResponse = yield all(
      referenceCategorized.document?.map((element) =>
        call(getDocumentSafeUrl, element.uri),
      ),
    );
    const documents = documentFilters.filterEmptyDocuments(documentsResponse);

    const meetingsResponse = yield all(
      referenceCategorized.meeting?.map((element) =>
        call(getMeetingUrl, element.uri),
      ),
    );
    const meetings = documentFilters.filterEmptyDocuments(meetingsResponse);

    const newReferences = { document: documents, meeting: meetings };

    // const deadline = response.data;
    // // backend -> FE so references is correct
    // let references = yield all(
    //   deadline.references.map((doc) => call(getDocumentSafe, doc.uri)),
    // );

    // references = documentFilters.filterEmptyDocuments(references);

    yield put(actions.fetchCurrentDeadlineReferencesSucceeded(newReferences));
  } catch (error) {
    yield put(actions.fetchCurrentDeadlineReferencesFailed());
    console.error(`fetchCurrentDeadlineReferences failed - ${error}`);
  }
}

// This is related to actual Document entity - upload
function* handleCreateDeadlineFromDocument(action) {
  try {
    const committeeId = yield select(selectedNationalCommittee);
    const selectedDocument = action.payload;
    selectedDocument.committee = handleOtherCommitteeId(
      selectedDocument.committee,
    );

    // TODO: RIGHT NOW WE HAVE SEVERAL ENTITY FOR THE REFERENCES, NEED TO FIX TO HAVE IT ONLY ONE ENTITY.
    yield put(actions.setFormSelectedDeadlineReferences([selectedDocument]));
    yield put(
      actions.setFormSelectedDeadlineReferencesDocuments([selectedDocument]),
    );
    yield put(
      actions.addGeneralDeadlineInformation({
        title: selectedDocument.title,
        committee: selectedDocument.committee,
        date: selectedDocument.dueDate,
      }),
    );

    history.push(
      generateDialogLinkForPage(
        committeeId,
        PAGE_TYPES.DEADLINES,
        DIALOG_TYPES.CREATE_DEADLINE,
      ),
    );
  } catch (error) {
    console.error(`create deadline from selected documents failed - ${error}`);
  }
}

// This is related to actual Document entity - download
function* handleDownloadDeadlineDocuments(action) {
  try {
    const deadlineId = action.payload;
    const uris = yield select(
      selectors.getDeadlineReferredDocumentsUri,
      deadlineId,
    );
    yield put(
      documentActions.downloadZipPackage({
        id: deadlineId,
        packageName: `${deadlineId || "documents"}.zip`,
        uris: uris,
      }),
    );
  } catch (error) {
    console.error(`download deadline documents failed - ${error}`);
  }
}

function* handlePullDeadlinesPageRequest() {
  try {
    const committeeId = yield select(selectedNationalCommittee);
    const memberId = yield select(userSelectors.userId);
    const pagination = yield select(selectors.getPagination);
    const pageToLoad = pagination.pagesLoaded;
    const filters = yield select(getDeadlinesFilters);

    const queryParams = addPageQueryParam(
      buildQueryParams(memberId, filters),
      pageToLoad,
    );
    const response = filters.relatedDocument
      ? yield call(getRelatedDeadlinesUrl, filters.relatedDocument, queryParams)
      : yield call(getDeadlinesUrl, committeeId, queryParams);

    const deadlinesCommentsStatistics = yield call(
      fetchDeadlinesCommentsStatistics,
      response.data.results,
    );

    yield call(fetchDeadlinesVotesStats, response.data.results);

    const pagesToLoad = Math.ceil(
      response.data.total / response.data.pageLength,
    );

    const page = response.data.start / response.data.pageLength + 1;
    const maxPage = Math.ceil(response.data.total / response.data.pageLength);

    if (response.status === 200) {
      yield put(
        actions.pullDeadlinesPageSucceeded({
          deadlinesData: response.data,
          commentsStats: deadlinesCommentsStatistics,
          pagination: {
            pageLoaded: pageToLoad,
            pagesToLoad: pagesToLoad,
            hasMoreToLoad: parseInt(page) < parseInt(maxPage),
            // response.data.results &&
            // response.data.results.length > 0 &&
            // parseInt(response.data.total) >
            //   parseInt(response.data.pageLength),
          },
        }),
      );
    } else {
      yield put(actions.pullDeadlinesPageFailed(response.data.message));
    }
  } catch (error) {
    console.error(`filter Deadlines failed - ${error}`);
  }
}

function* handleCreateDeadline() {
  try {
    const committeeId = yield select(selectedNationalCommittee);
    const deadlineData = yield select(getNewDeadlineFormData);

    const privillegesAction = {
      type: "creating",
      payload: {
        url: `/${transformCommitteeName(committeeId)}/deadlines`,
      },
    };

    yield call(handleCheckUserPrivilleges, privillegesAction);
    const response = yield call(
      createDeadlineUrl,
      committeeId,
      mapToApiModel(deadlineData),
    );
    if (response.status === 200) {
      const succeededResponse = yield put(
        actions.createDeadlineSucceeded({
          deadline: response.data,
        }),
      );
      history.push(
        generateDialogLink(
          DIALOG_TYPES.DEADLINE_DETAILS,
          succeededResponse.payload.deadline.uri,
        ),
      );

      if (deadlineData.notifyOnUpdate) {
        // post notification
        yield call(handleCreateNotificationBySystem, {
          payload: {
            ...deadlineData,
            notify: deadlineData.notifyImmediately,
            type: notificationType.deadline,
            eventType: notificationActionType.add,
            references: [
              {
                type: "Deadline",
                uri: succeededResponse.payload.deadline.uri,
              },
            ],
          },
        });
      }

      // yield call(
      //   waitForNotificationTypedDocumentId,
      //   response.data.uri,
      //   notificationActionType.add,
      // );
    } else {
      yield put(actions.createDeadlineFailed("Failed to create deadline"));
    }
  } catch (error) {
    console.error(`create deadline failed - ${error}`);
  }
}

function* handleUpdateDeadline(action) {
  try {
    const committeeId = yield select(selectedNationalCommittee);
    const updatedDeadline = action.payload;
    const privillegesAction = {
      type: action.type,
      payload: {
        url: `/${transformCommitteeName(committeeId)}/deadlines`,
      },
    };

    yield call(handleCheckUserPrivilleges, privillegesAction);

    const response = yield call(
      updateDeadlineUrl,
      updatedDeadline,
      updatedDeadline.notifyOnUpdate,
    );
    if (response.status === 200) {
      yield put(actions.updateDeadlineSucceeded(response.data));
      history.push(
        generateDialogLink(DIALOG_TYPES.DEADLINE_DETAILS, updatedDeadline.uri),
      );

      if (updatedDeadline.notifyOnUpdate) {
        // post notification
        yield call(handleCreateNotificationBySystem, {
          payload: {
            ...updatedDeadline,
            notify: updatedDeadline.notifyImmediately,
            type: notificationType.deadline,
            eventType: notificationActionType.update,
            references: [
              {
                type: notificationType.deadline,
                uri: updatedDeadline.uri,
              },
            ],
          },
        });

        // notification is done on FE
        // yield call(waitForNotificationDocumentId, updatedDeadline.uri);
      }
    } else {
      yield put(actions.updateDeadlineFailed("Failed to update deadline"));
    }
  } catch (error) {
    console.error(`update deadline failed - ${error}`);
  }
}

function* handleDeleteDeadline(action) {
  try {
    const deadlineUri = action.payload;
    const privillegesAction = {
      type: action.type,
    };

    yield call(handleCheckUserPrivilleges, privillegesAction);

    const response = yield call(deleteDeadlineUrl, deadlineUri);
    if (response.status === 200) {
      yield put(actions.deleteDeadlineSucceeded(response.data));
      history.push(generateCloseDialogLink());
    } else {
      yield put(actions.deleteDeadlineFailed("Failed to delete deadline"));
    }
  } catch (error) {
    console.error(`delete deadline failed - ${error}`);
  }
}

function* handleInitAggDeadlines() {
  yield call(handlePullAggDeadlinesPage);
}

function* handlePullAggDeadlinesPage() {
  try {
    const userCommittees = yield select(userSelectors.userCommittees);
    const userCommitteesIds = Object.keys(userCommittees);

    const memberId = yield select(userSelectors.userId);
    const pagination = yield select(selectors.getPagination);
    const pageToLoad = pagination.pagesLoaded;
    const filters = yield select(getDeadlinesFilters);
    const queryModel = addPageQueryParam(
      buildQueryParams(memberId, filters),
      pageToLoad,
    );

    const response = yield call(
      getAggDeadlinesUrl,
      userCommitteesIds,
      queryModel,
    );

    const deadlinesCommentsStatistics = yield call(
      fetchDeadlinesCommentsStatistics,
      response.data.results,
    );
    yield call(fetchDeadlinesVotesStats, response.data.results);

    const pagesToLoad = Math.ceil(
      response.data.total / response.data.pageLength,
    );

    if (response.status === 200) {
      yield put(
        actions.pullDeadlinesPageSucceeded({
          deadlinesData: response.data,
          commentsStats: deadlinesCommentsStatistics,
          pagination: {
            pageLoaded: pageToLoad,
            pagesToLoad: pagesToLoad,
            hasMoreToLoad:
              response.data.results &&
              response.data.results.length > 0 &&
              parseInt(response.data.total) >
                parseInt(response.data.pageLength),
          },
        }),
      );
    } else {
      yield put(actions.pullDeadlinesPageFailed(response.data.message));
    }
  } catch (error) {
    console.error(`filter Deadlines failed - ${error}`);
  }
}

function* handleUpdateIsRegistered(action) {
  const { deadlineUri, isRegistered } = action.payload;

  const response = yield call(updateIsRegisteredUrl, deadlineUri, isRegistered);
  if (response.status === 200) {
    yield put(actions.updateDeadlineSucceeded(response.data));
  } else {
    yield put(actions.updateDeadlineFailed("Failed to update deadline"));
  }
}

export default function* deadlinesSaga() {
  yield takeLatest(actions.initDeadlines, handleInitDeadlines);
  yield takeLatest(
    actions.initDeadlineFormWithGeneralInfo,
    handleInitDeadlineForm,
  );

  yield takeLatest(actions.addDeadlineReferences, handleAddDeadlineReferences);
  yield takeLatest(actions.setDeadlineReferences, handleSetDeadlineReferences);
  yield takeLatest(
    actions.removeReferenceFromDeadline,
    handleRemoveReferenceFromDeadline,
  );
  yield takeLatest(actions.fetchDeadline, fetchDeadline);
  yield takeLatest(actions.fetchDeadlineReferences, fetchDeadlineReferences);
  yield takeLatest(
    actions.fetchCurrentDeadlineReferences,
    fetchCurrentDeadlineReferences,
  );
  yield takeLatest(
    actions.createDeadlineFromReference,
    handleCreateDeadlineFromDocument,
  );
  yield takeLatest(
    actions.downloadDeadlineDocuments,
    handleDownloadDeadlineDocuments,
  );
  yield takeLeading(
    actions.pullDeadlinesPageRequest,
    handlePullDeadlinesPageRequest,
  );
  yield takeLatest(actions.createDeadline, handleCreateDeadline);
  yield takeLatest(actions.updateDeadline, handleUpdateDeadline);
  yield takeLatest(actions.deleteDeadline, handleDeleteDeadline);

  yield takeLatest(actions.initAggDeadlines, handleInitAggDeadlines);
  yield takeLatest(actions.pullAggDeadlinesPage, handlePullAggDeadlinesPage);
  yield takeLatest(actions.updateIsRegistered, handleUpdateIsRegistered);
}
