import history from "../../utils/history";

export const sortingType = {
  date_descending: "date_desc",
  date_ascending: "date_asc",
};

function mapStandardsSortTypeToUrlParam(type) {
  switch (type) {
    case sortingType.date_descending:
      return "date_dsc";
    case sortingType.date_ascending:
      return "date_asc";
    default:
      return "date_dsc";
  }
}

export const buildStandardsQueryParams = () => {
  let queryParams = {};

  const query = new URLSearchParams(history.location.search);

  queryParams.sort = mapStandardsSortTypeToUrlParam(query.get("sortingType"));

  queryParams.pageLength = 30;

  return queryParams;
};
