import React, { Component } from "react";
import Toggler from "../toggler/Toggler";

import "./LanguageDropdown.scss";

class LanguageDropdown extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedOptionId: this.props.initOptionId || this.options[0].id,
    };
  }

  selectOption = (optionId) => {
    this.setState(
      () => ({
        selectedOptionId: optionId,
      }),
      () => this.props.onOptionChange(this.state.selectedOptionId),
    );
  };

  render() {
    return (
      <div className={"dropdown"}>
        <Toggler calculatePosition={false} clickHandler={() => {}}>
          <button
            className="toggler__button dropdown__button"
            onClick={this.handleClick}
          >
            <span className="dropdown__selected-text">
              {
                this.props.options.find(
                  (item) => item.id == this.state.selectedOptionId,
                ).displaySelected
              }
            </span>
            <span className="icon icon-arrow-right" aria-hidden="true"></span>
          </button>

          <div className="toggler__content dropdown__panel panel panel--border">
            <ul className="list">
              {this.props.options.map((option) => {
                return (
                  <DropdownPanelItem
                    key={option.id}
                    selectOption={(id) => this.selectOption(id)}
                    {...option}
                  >
                    <span className="button button--link">{option.label}</span>
                  </DropdownPanelItem>
                );
              })}
            </ul>
          </div>
        </Toggler>
      </div>
    );
  }
}

const DropdownPanelItem = (props) => {
  return (
    <li
      className={"list__element"}
      key={props.id}
      onClick={(e) => props.selectOption(props.id)}
      id={props.id}
    >
      <span className="button button--link">{props.label}</span>
    </li>
  );
};

export default LanguageDropdown;
