import React, { Component } from "react";
import {
  turnOnCommittees,
  turnOffCommittees,
} from "../../../state/user/actions";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import "./CommitteeSettingBlock.scss";
import ToggleSwitch from "../../common/toggle-switch/ToggleSwitch";
import EmailNotificationSettings from "./EmailNotificationSettings";

class CommitteeSettingBlock extends Component {
  isChecked = (insideCommitteeId) => {
    const { turnedOffCommittees } = this.props;

    return !turnedOffCommittees.includes(insideCommitteeId);
  };

  onChangeToggle = (insideCommitteeId) => {
    const {
      committeeId,
      turnOffCommittee,
      turnOnCommittee,
      turnedOffCommittees,
    } = this.props;

    if (!this.isChecked(insideCommitteeId)) {
      turnOnCommittee({
        selectedCommittee: committeeId,
        turnedOffCommittees: turnedOffCommittees.filter(
          (element) => element !== insideCommitteeId,
        ),
      });
    } else {
      turnOffCommittee({
        selectedCommittee: committeeId,
        turnedOffCommittees: turnedOffCommittees.concat(insideCommitteeId),
      });
    }
  };

  render() {
    const { committeeId, insideCommittees } = this.props;

    return (
      <div className="group committee-setting">
        <span className="headline-3 u-text-bold">{committeeId}</span>
        <br />
        <EmailNotificationSettings committeeId={committeeId} />
        <ul className="list">
          {insideCommittees.map((insideCommittee, index) => {
            return (
              <li className="list-element" key={index}>
                <span className="list-element-name">
                  <span className="field-label">{insideCommittee.name}</span>
                  <p className="field-description">
                    {insideCommittee.description}
                  </p>
                </span>
                <ToggleSwitch
                  checked={this.isChecked(insideCommittee.name)}
                  onChange={() => this.onChangeToggle(insideCommittee.name)}
                />
              </li>
            );
          })}
        </ul>
      </div>
    );
  }
}

const mapStateToProps = {};

const mapDispatchToProps = {
  turnOffCommittee: turnOffCommittees,
  turnOnCommittee: turnOnCommittees,
};

export default compose(
  withTranslation("common"),
  connect(null, mapDispatchToProps),
)(CommitteeSettingBlock);
