import React, { Component, Fragment } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { selectedNationalCommittee } from "../../../state/national-committees/selectors";
import { withTranslation } from "react-i18next";
import { setPageTitle } from "../../../utils/pageTitle";
import { Helmet } from "react-helmet";
import EmailNotificationSettings from "./EmailNotificationSettings";
import CommitteeSettings from "./CommitteeSettings";
import { Route, Switch } from "react-router-dom";

class Settings extends Component {
  render() {
    const { t, selectedNationalCommittee } = this.props;

    return (
      <Fragment>
        <Helmet>
          <title>{setPageTitle(["Settings"], selectedNationalCommittee)}</title>
        </Helmet>
        <header className="layout__title">
          <h2 className="headline-2">{t("navigation.page.settings")}</h2>
        </header>

        <div className="layout__content panel panel--border">
          <div className="group group--first group--underlined">
            <span className="headline-2 u-text-bold">
              {t("settings.emailFrequency.label.emailSettings")}
            </span>
            <span className="u-text-light">
              {t("settings.emailFrequency.label.info")}
            </span>
          </div>
          <CommitteeSettings />
          {/* TODO: PLAN 2 */}
          {/* <Switch>
            <Route
              path="/user/settings/committee"
              component={CommitteeSettings}
            />
            <Route
              path="/user/settings/email"
              component={EmailNotificationSettings}
            />
          </Switch> */}
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  selectedNationalCommittee: selectedNationalCommittee(state),
});

// const mapDispatchToProps = {
// };

export default compose(
  withTranslation("common"),
  connect(mapStateToProps, null),
)(Settings);
