import { takeEvery, call, select, put } from "redux-saga/effects";
import { selectedNationalCommittee } from "../state/national-committees/selectors";
import * as actions from "../state/standards/actions";
import { getPublishedStandards } from "../api/resources/standards";
import { buildStandardsQueryParams } from "../services/standards/url";

function* fetchPublishedStandards() {
  try {
    const committeeId = yield select(selectedNationalCommittee);

    const queryParams = buildStandardsQueryParams();
    const response = yield call(
      getPublishedStandards,
      committeeId,
      queryParams,
    );
    if (response && response.status === 200) {
      yield put(
        actions.getPublishedStandardsSucceeded({
          committee: committeeId,
          data: response.data,
        }),
      );
    } else {
      yield put(
        actions.getPublishedStandardsFailed(
          "Failed to fetch published standards",
        ),
      );
    }
  } catch (error) {
    console.error(`fetch published standards failed - ${error}`);
  }
}

export default function* standardsSaga() {
  yield takeEvery(actions.GET_PUBLISHED_STANDARDS, fetchPublishedStandards);
}
