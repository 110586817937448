import { api } from "../api";
import { addQueryParams, getProxyUrl } from "../utils";
import { transformCommitteeName } from "../../utils/transform";
import {
  mapDeadlineReferencesToPatchQuery,
  mapDeadlineToPatchQuery,
  mapToAggDeadlinesQuery,
} from "../mappers/deadline";
import toIsoDateTime from "../../utils/toIsoDateTime";

export function getDeadlinesUrl(committeeId, params) {
  let proxyUrl = getProxyUrl(
    `/Deadlines/deadlines/${transformCommitteeName(committeeId)}`,
  );
  proxyUrl = addQueryParams(proxyUrl, params);

  return api.get(proxyUrl);
}

export function getRelatedDeadlinesUrl(documentId, params) {
  let proxyUrl = getProxyUrl(`/Deadlines/document/${documentId}`);
  proxyUrl = addQueryParams(proxyUrl, params);

  return api.get(proxyUrl);
}

export function getAggDeadlinesUrl(committeeIds, params) {
  const proxyUrl = getProxyUrl("/Deadlines");
  const queryModel = mapToAggDeadlinesQuery(committeeIds, params);
  return api.post(proxyUrl, JSON.stringify(queryModel));
}

export function getDeadlineUrl(uri) {
  const proxyUrl = getProxyUrl(`/Deadlines/deadline/${uri}`);
  return api.get(proxyUrl);
}

export function updateDeadlineUrl(deadline, notify) {
  const deadlineToUpdate = {
    ...deadline,
    date: toIsoDateTime(deadline.date),
  };
  let proxyUrl = getProxyUrl(`/Deadlines/deadline/${deadlineToUpdate.uri}`);
  proxyUrl = addQueryParams(
    proxyUrl,
    mapDeadlineToPatchQuery(deadlineToUpdate, notify),
  );
  const docs = mapDeadlineReferencesToPatchQuery(deadlineToUpdate);
  return api.patch(proxyUrl, JSON.stringify(docs));
}

export function deleteDeadlineUrl(deadlineUri) {
  const proxyUrl = getProxyUrl(`/Deadlines/deadline/${deadlineUri}`);
  return api.delete(proxyUrl);
}

export function createDeadlineUrl(committeeId, data) {
  const proxyUrl = getProxyUrl(
    `/Deadlines/${transformCommitteeName(committeeId)}`,
  );
  const actionDueDate = toIsoDateTime(data.actionDueDate);

  return api.post(proxyUrl, { ...data, actionDueDate });
}

export function updateIsRegisteredUrl(deadlineUri, isRegistered) {
  const proxyUrl = getProxyUrl(
    `/Deadlines/deadline/registered/${deadlineUri}/${isRegistered}`,
  );
  return api.patch(proxyUrl);
}
