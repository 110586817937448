import React, { Component, Fragment } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";

import { uploadSelectedDocuments } from "../../../state/document/actions";
import { documentUploadType } from "../../../utils/constants";

import { isDocumentLinkValid } from "../../../utils/validation/documentLinkValidator";
import FormMessageBox, { messageType } from "../form/message-box/MessageBox";
import classNames from "classnames";

class DocumentsLinks extends Component {
  state = {
    rows: [""],
    errorMessages: [],
    duringValidation: false,
  };

  isInvalid = (index) => {
    return (
      (this.state.rows[index].length || this.state.duringValidation) &&
      !isDocumentLinkValid(this.state.rows[index])
    );
  };

  onAddRow = (e) => {
    e.preventDefault();
    this.setState((state) => {
      return {
        rows: [...state.rows, ""],
      };
    });
  };

  onRemoveRow = (e, rowIndex) => {
    e.preventDefault();
    this.setState((state) => {
      return {
        rows: state.rows.filter((_, i) => i !== rowIndex),
      };
    });
  };

  onUpdateRow = (index, value) => {
    const v = value;
    this.setState((state) => {
      let newRows = [...state.rows];
      newRows[index] = v;
      return {
        rows: newRows,
      };
    });
  };

  onAddLinkedDocuments = (e) => {
    e.preventDefault();
    this.validateRows();
  };

  validateRows = () => {
    const rowsBeforeValidation = this.getRowsToValidate();
    const validatedRows = rowsBeforeValidation.filter((row) =>
      isDocumentLinkValid(row),
    );
    const isValidated = validatedRows.length === rowsBeforeValidation.length;
    this.setState({ rows: rowsBeforeValidation }, () => {
      this.afterRowValidation(isValidated);
    });
  };

  afterRowValidation = (isValidated) => {
    if (isValidated) {
      this.addLinks();
      this.props.hideLinkDocuments();
    } else {
      this.showErrorMessage();
    }
    this.setState({ duringValidation: !isValidated });
  };

  getRowsToValidate = () => {
    const nonEmptyRows = this.state.rows.filter((r) => r.length !== 0);
    if (nonEmptyRows.length === 0) {
      nonEmptyRows.push("");
    }
    return nonEmptyRows;
  };

  addLinks = () => {
    this.props.uploadSelectedDocuments({
      documents: this.state.rows,
      uploadType: documentUploadType.link,
      callback: this.props.callback,
    });
  };

  showErrorMessage = () => {
    this.setState({
      errorMessages: ["documents.linked.validation.linkFormatError"],
    });
  };

  renderRemoveButton = (index) => {
    const { t } = this.props;

    return (
      <button
        className="button button--link"
        onClick={(e) => this.onRemoveRow(e, index)}
      >
        {t("documents.linked.button.removeRow")}
      </button>
    );
  };

  renderRow = (index, row, withRemoveButton) => {
    return (
      <div key={index} className="group group--field group__element">
        <input
          type="text"
          className={classNames("input input--short", {
            "input--validation_error": this.isInvalid(index),
          })}
          value={row}
          onChange={(event) => this.onUpdateRow(index, event.target.value)}
        />
        {withRemoveButton ? this.renderRemoveButton(index) : null}
      </div>
    );
  };

  render() {
    const { t, hideLinkDocuments } = this.props;
    const { rows, errorMessages } = this.state;

    const withRemoveButton = rows.length > 1;

    return (
      <Fragment>
        <div className="buttons group group--first group--underlined u-align-right">
          <button className="button button--small" onClick={hideLinkDocuments}>
            {t("general.button.cancel")}
          </button>
          <button
            className="button button--primary button--small"
            onClick={this.onAddLinkedDocuments}
          >
            {t("documents.linked.button.addLinkedDocuments")}
          </button>
        </div>
        <FormMessageBox
          messages={errorMessages}
          messageType={messageType.error}
        />
        <div className="group group--row">
          {rows.map((row, index) =>
            this.renderRow(index, row, withRemoveButton),
          )}
        </div>
        <button className="button" onClick={this.onAddRow}>
          {t("documents.linked.button.addLink")}
        </button>
      </Fragment>
    );
  }
}

const mapDispatchToProps = {
  uploadSelectedDocuments,
};

export default compose(
  withTranslation("common"),
  connect(null, mapDispatchToProps),
)(DocumentsLinks);
