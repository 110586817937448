import { handleActions, combineActions } from "redux-actions";
import * as actions from "./actions";
import { normalizeIndex } from "./normalizers";

const initialState = {
  isFetching: false,
  data: {
    document: null,
    deadline: null,
    meeting: null,
    stages: null,
    comment: null,
  },
};

const actionHandlers = {
  [combineActions(actions.fetchAllValues)]: (state) => ({
    ...state,
    isFetching: true,
  }),
  [actions.fetchingDocumentFilterValuesSucceeded]: (
    state,
    { payload: { actions, types, committees, avaiableTypes } },
  ) => ({
    ...state,
    data: {
      ...state.data,
      document: {
        actions,
        types,
        committees,
        avaiableTypes: avaiableTypes,
        actionsIndex: normalizeIndex(actions),
        typesIndex: normalizeIndex(types),
        committeesIndex: normalizeIndex(committees),
      },
    },
  }),

  [actions.fetchingDeadlineFilterValuesSucceeded]: (
    state,
    { payload: { months, types, committees } },
  ) => ({
    ...state,
    data: {
      ...state.data,
      deadline: {
        months,
        types,
        committees,
      },
    },
  }),

  [actions.fetchingMeetingFilterValuesSucceeded]: (
    state,
    { payload: { months, committees } },
  ) => ({
    ...state,
    data: {
      ...state.data,
      meeting: {
        months,
        committees,
      },
    },
  }),

  [actions.getStageCodesSucceeded]: (state, { payload }) => ({
    ...state,
    data: {
      ...state.data,
      stages: payload,
    },
  }),

  [actions.getCommentTypesSucceeded]: (state, { payload }) => ({
    ...state,
    data: {
      ...state.data,
      comment: {
        types: payload,
      },
    },
  }),

  [actions.clearMeetingsFilterValues]: (state) => ({
    ...state,
    data: {
      ...state.data,
      meeting: null,
    },
  }),
  [actions.fetchAllValuesFinished]: (state) => ({
    ...state,
    isFetching: false,
  }),
};

export default handleActions(actionHandlers, initialState);
