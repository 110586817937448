import { createAction } from "redux-actions";

const FILTER_SEARCH_REQUEST = "SEARCH/filter_search_request";
const FILTER_SEARCH_SUCCEEDED = "SEARCH/filter_search_succeeded";
const FILTER_SEARCH_FAILED = "SEARCH/filter_search_failed";

const FETCH_SEARCH_FILTERS_REQUEST = "SEARCH/fetch_filters_request";
const FETCH_SEARCH_FILTERS_SUCCEEDED = "SEARCH/fetch_filters_succeeded";

const INIT_SEARCH = "SEARCH/init_search";

export const filterSearchRequest = createAction(FILTER_SEARCH_REQUEST);
export const filterSearchSucceeded = createAction(FILTER_SEARCH_SUCCEEDED);
export const filterSearchFailed = createAction(FILTER_SEARCH_FAILED);

export const fetchSearchFiltersRequest = createAction(
  FETCH_SEARCH_FILTERS_REQUEST,
);
export const fetchSearchFiltersSucceeded = createAction(
  FETCH_SEARCH_FILTERS_SUCCEEDED,
);

const SELECT_SEARCH_COMMITTEE = "FILTER/SEARCH/select_search_committee";
const SELECT_SEARCH_ACTION = "FILTER/SEARCH/select_search_action";
const SELECT_SEARCH_TYPE = "FILTER/SEARCH/select_search_type";
const SET_SEARCH_TITLE = "FILTER/SEARCH/set_search_title";
const RESET_SEARCH_FILTERS = "FILTER/SEARCH/reset_search_filters";
const ADD_SEARCH_FILTERS_TO_URL = "FILTERS/SEARCH/add_to_url_search";
const SET_SEARCH_IS_FILTERED = "FILTER/SEARCH/set_search_is_filtered";

export const selectSearchCommittee = createAction(SELECT_SEARCH_COMMITTEE);
export const selectSearchAction = createAction(SELECT_SEARCH_ACTION);
export const selectSearchType = createAction(SELECT_SEARCH_TYPE);
export const setSearchTitle = createAction(SET_SEARCH_TITLE);
export const resetSearchFilters = createAction(RESET_SEARCH_FILTERS);
export const addSearchFiltersToUrl = createAction(ADD_SEARCH_FILTERS_TO_URL);
export const setSearchIsFiltered = createAction(SET_SEARCH_IS_FILTERED);
export const initSearch = createAction(INIT_SEARCH);

const SET_SEARCH_CURRENT_PAGE = "PAGINATION/SEARCH/set_current_page";
export const setCurrentPage = createAction(SET_SEARCH_CURRENT_PAGE);

const SET_SEARCH_SORTING_TYPE = "SORTING/SEARCH/set_type";
export const setSortingType = createAction(SET_SEARCH_SORTING_TYPE);

const SET_SEARCH_MODAL_STATE = "MODAL/SEARCH/set_state";
const RESET_SEARCH_MODAL_STATE = "MODAL/SEARCH/reset_state";

export const setModalState = createAction(SET_SEARCH_MODAL_STATE);
export const resetModalState = createAction(RESET_SEARCH_MODAL_STATE);

const GET_SEARCH_SUGGESTIONS = "SEARCH/get_search_suggestions";
const GET_SEARCH_SUGGESTIONS_SUCCEEDED =
  "SEARCH/get_search_suggestions_succeeded";
const GET_SEARCH_SUGGESTIONS_FAILED = "SEARCH/get_search_suggestions_failed";
export const getSearchSuggestions = createAction(GET_SEARCH_SUGGESTIONS);
export const getSearchSuggestionsSucceeded = createAction(
  GET_SEARCH_SUGGESTIONS_SUCCEEDED,
);
export const getSearchSuggestionsFailed = createAction(
  GET_SEARCH_SUGGESTIONS_FAILED,
);

const INIT_SEARCH_DOCUMENTS = "SEARCH/init_documents";
export const initSearchDocuments = createAction(INIT_SEARCH_DOCUMENTS);
