import React, { Component } from "react";
import ToggleSwitch from "../toggle-switch/ToggleSwitch";

class FilterOption extends Component {
  render() {
    const { name, label, state, onChange } = this.props;
    return (
      <div className="group group--small group--underlined notification-filter-option">
        <ToggleSwitch checked={!!state} onChange={onChange} />
        <label htmlFor={name} className="inline-checkbox">
          {/* <input
            type="checkbox"
            name={name}
            id={name}
            className="inline-checkbox__input"
            value=""
            checked={!!state}
            onChange={onChange}
          />
          <span
            className="inline-checkbox__input--off icon icon-radio-off"
            aria-hidden={true}
          ></span>
          <span
            className="inline-checkbox__input--on icon icon-radio-on"
            aria-hidden={true}
          ></span> */}
          <span>{label}</span>
        </label>
      </div>
    );
  }
}

export default FilterOption;
