import React from "react";
import { urlValidator } from "../../../../../utils/validation/urlValidator";
import { UrlParser } from "../../../../../utils/urlParser";

export default ({ date, location }) => {
  const isLocationUrl = urlValidator(location);

  return (
    <div className="card__content">
      <div className="card__description">
        <p>{date}</p>
        <p>
          {isLocationUrl ? (
            <a href={UrlParser.addHTTP(location)}>
              {UrlParser.shortenUrl(location)}
            </a>
          ) : (
            location
          )}
        </p>
      </div>
    </div>
  );
};
