import { handleActions, combineActions } from "redux-actions";
import * as actions from "./actions";
import { normalizeDocuments } from "../document/normalizers";
import { normalizeSuggestions } from "./normalizers";

const initialState = {
  isFetching: false,
  isFetched: false,
  data: {
    results: null,
    pagination: {
      currentPage: 1,
    },
  },
  suggestions: [],
  lastSearched: null,
  filters: {
    types: null,
    actions: null,
    committees: null,
    title: "",
    documentsAreFiltered: false,
  },
  sortingType: null,
  modal: {
    isOpen: false,
    contentType: null,
    content: null,
  },
};

const actionHandlers = {
  [combineActions(actions.initSearch, actions.filterSearchRequest)]: (
    state,
  ) => ({
    ...state,
    isFetching: true,
    isFetched: false,
  }),
  [actions.filterSearchSucceeded]: (state, { payload }) => ({
    ...state,
    isFetching: false,
    isFetched: true,
    data: {
      pagination: {
        total: payload.total,
        pageSize: payload.pageLength,
        currentPage: Math.ceil(payload.start / 10),
      },
      results: normalizeDocuments(payload),
    },
  }),
  [actions.filterSearchFailed]: (state) => ({
    ...state,
    isFetching: false,
    isFetched: true,
    data: {
      pagination: {
        total: 0,
        pageSize: 0,
        currentPage: 0,
      },
      results: null,
    },
  }),
  [actions.fetchSearchFiltersSucceeded]: (state, { payload }) => ({
    ...state,
    filters: {
      ...state.filters,
      ...payload,
    },
  }),
  [actions.selectSearchAction]: (state, { payload }) => ({
    ...state,
    filters: {
      ...state.filters,
      actions: {
        ...state.filters.actions,
        selected: payload.value,
      },
    },
  }),
  [actions.selectSearchType]: (state, { payload }) => ({
    ...state,
    filters: {
      ...state.filters,
      types: {
        ...state.filters.types,
        selected: payload.value,
      },
    },
  }),
  [actions.setSearchIsFiltered]: (state, { payload }) => ({
    ...state,
    filters: {
      ...state.filters,
      documentsAreFiltered: payload.value,
    },
  }),
  [actions.selectSearchCommittee]: (state, { payload }) => ({
    ...state,
    filters: {
      ...state.filters,
      committees: {
        ...state.filters.committees,
        selected: payload.value,
      },
    },
  }),
  [actions.setSearchTitle]: (state, { payload }) => ({
    ...state,
    filters: {
      ...state.filters,
      title: payload.value,
    },
  }),
  [actions.resetSearchFilters]: (state) => ({
    ...state,
    filters: {
      ...state.filters,
      title: "",
      actions: {
        ...state.filters.actions,
        selected: null,
      },
      types: {
        ...state.filters.types,
        selected: null,
      },
      committees: {
        ...state.filters.committees,
        selected: null,
      },
    },
  }),
  [actions.setSortingType]: (state, { payload }) => ({
    ...state,
    sortingType: payload.value,
  }),
  [actions.setCurrentPage]: (state, { payload }) => ({
    ...state,
    data: {
      ...state.data,
      pagination: {
        ...state.data.pagination,
        currentPage: payload.value,
      },
    },
  }),
  [actions.setModalState]: (state, { payload }) => ({
    ...state,
    modal: {
      ...state.modal,
      ...payload,
    },
  }),
  [actions.resetModalState]: (state) => ({
    ...state,
    modal: {
      isOpen: false,
      contentType: null,
      content: null,
    },
  }),
  [actions.getSearchSuggestions]: (state) => ({
    ...state,
  }),
  [actions.getSearchSuggestionsSucceeded]: (state, { payload }) => ({
    ...state,
    suggestions: normalizeSuggestions(payload),
  }),
  [actions.getSearchSuggestionsFailed]: (state) => ({
    ...state,
    suggestions: [],
  }),
};

export default handleActions(actionHandlers, initialState);
