import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withTranslation } from "react-i18next";

import { selectedNationalCommittee } from "../../../../../state/national-committees/selectors";
import { getDeadline } from "../../../../../state/deadlines/selectors";
import { transformCommitteeName } from "../../../../../utils/transform";

import CommentForm from "../../components/comment-form/CommentForm";
import Loader from "../../../../common/loader/Loader";
import { fetchDeadline } from "../../../../../state/deadlines/actions";
import { createComment } from "../../../../../state/comments/actions";
import { userDetails } from "../../../../../state/user/selectors";
import { setPageTitle } from "../../../../../utils/pageTitle";
import { Helmet } from "react-helmet";

class CreateComment extends Component {
  componentDidMount() {
    if (typeof this.props.deadline === "undefined") {
      this.props.fetchDeadline(this.props.context.resource);
    }
  }

  replaceSpansWithGuidPlaceholders(htmlString) {
    // Create a temporary container element to hold the HTML
    const container = document.createElement("div");
    container.innerHTML = htmlString;

    // Find all span elements with the class 'comment__label-box'
    const spans = container.querySelectorAll("span.comment__label-box");

    // Iterate through each span and replace it with the placeholder
    spans.forEach((span) => {
      const guid = span.getAttribute("data-guid");
      if (guid) {
        // Create the placeholder text
        const placeholder = `{{${guid}}}`;

        // Replace the span element with the placeholder
        const spanParent = span.parentNode;
        spanParent.replaceChild(document.createTextNode(placeholder), span);
      }
    });

    // Return the updated HTML as a string
    return container.innerHTML;
  }

  handleSave = (data) => {
    const guidComment = this.replaceSpansWithGuidPlaceholders(data.comment);
    const guidProposal = this.replaceSpansWithGuidPlaceholders(data.proposal);

    this.props.createComment({
      comment: guidComment,
      proposal: guidProposal,
      line: data.line,
      clause: data.clause,
      paragraph: data.paragraph,
      type: data.selected,
      deadlineUri: this.props.deadline.uri,
      committee: transformCommitteeName(this.props.selectedNationalCommittee),
      subcommittee: this.props.deadline.committee,
      memberId: this.props.user.id,
      author: this.props.user.name,
      attachments: data.attachments,
    });
  };

  render() {
    const { deadline, selectedNationalCommittee } = this.props;
    const { t } = this.props;

    return deadline ? (
      <Fragment>
        <Helmet>
          <title>
            {setPageTitle(
              ["Deadlines", deadline.title, "Create comment"],
              selectedNationalCommittee,
            )}
          </title>
        </Helmet>
        <header className="layout__title">
          <h2 className="headline-2">
            {t("comments.create.header")} {deadline.title}
          </h2>
        </header>
        <div className="layout__content panel">
          {this.props.deadline.uri && (
            <CommentForm
              deadlineUri={this.props.deadline.uri}
              onSave={this.handleSave}
            />
          )}
        </div>
      </Fragment>
    ) : (
      <Loader />
    );
  }
}

const mapDispatchToProps = {
  fetchDeadline,
  createComment,
};

const mapStateToProps = (state) => ({
  user: userDetails(state),
  deadline: getDeadline(state),
  selectedNationalCommittee: selectedNationalCommittee(state),
});

export default compose(
  withTranslation("common"),
  connect(mapStateToProps, mapDispatchToProps),
)(CreateComment);
