import React, {
  Component,
  createRef,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
} from "react";
import { useTranslation, withTranslation } from "react-i18next";
import { connect, useDispatch, useSelector } from "react-redux";
import { compose } from "redux";
import { ReferenceDocumentsList } from "./ReferenceDocumentsList";
import { ReferenceMeetingList } from "./ReferenceMeetingList";
import { ReferenceDeadlineList } from "./ReferenceDeadlineList";
import "./ReferencePage.scss";
import { REFERENCE_TAB } from "../../../../../state/references/reducer";
import { getSelectedReferenceTab } from "../../../../../state/references/selectors";
import { emptyReferenceTab } from "../../../../../state/references/actions";

export const ReferencePage = forwardRef((props, ref) => {
  const dispatch = useDispatch();
  const selectedReferenceTab = useSelector(getSelectedReferenceTab);
  const documentRef = useRef();
  const meetingRef = useRef();
  const deadlineRef = useRef();

  useImperativeHandle(ref, () => ({
    onClick() {
      onSaveAction();
    },
  }));

  const onSelectInit = () => {
    dispatch(emptyReferenceTab());
  };

  const isDocumentSelected = () => {
    return selectedReferenceTab === REFERENCE_TAB.DOCUMENTS;
  };

  const isMeetingSelected = () => {
    return selectedReferenceTab === REFERENCE_TAB.MEETINGS;
  };

  const isDeadlineSelected = () => {
    return selectedReferenceTab === REFERENCE_TAB.DEADLINES;
  };

  const onSaveAction = () => {
    const refMap = {
      [REFERENCE_TAB.DOCUMENTS]: documentRef,
      [REFERENCE_TAB.MEETINGS]: meetingRef,
      [REFERENCE_TAB.DEADLINES]: deadlineRef,
    };

    const currentRef = refMap[selectedReferenceTab];
    if (currentRef && currentRef.current) {
      currentRef.current.onSelect();
    }
  };

  return (
    <div className="tab-content">
      {isDocumentSelected() && (
        <ReferenceDocumentsList
          ref={documentRef}
          onAddDocuments={props.onAddDocuments}
          referenceDocumentList={props.referencesPerType?.document ?? []}
          isModal
          onSelect={onSelectInit}
        />
      )}
      {isMeetingSelected() && (
        <ReferenceMeetingList
          ref={meetingRef}
          onSetMeeting={props.onSetMeeting}
          selectedMeetings={props.referencesPerType?.meeting ?? []}
          isModal
          onSelect={onSelectInit}
        />
      )}
      {isDeadlineSelected() && (
        <ReferenceDeadlineList
          ref={deadlineRef}
          onSetDeadline={props.onSetDeadline}
          selectedDeadlines={props.referencesPerType?.deadline ?? []}
          isModal
          onSelect={onSelectInit}
        />
      )}
    </div>
  );
});
