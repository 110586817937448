import React, { Component, Fragment, createRef } from "react";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";

import NotificationGeneralInfoTab from "../../components/notification-detail/NotificationGeneralInfoTab";

import { withTranslation } from "react-i18next";
import {
  addGeneralNotificationInformation,
  setSelectedNotificationDocuments,
  clearNotificationForm,
  setSelectedNotificationDeadlines,
  setSelectedNotificationMeetings,
} from "../../../../../state/notifications/actions";
import { selectedNationalCommittee } from "../../../../../state/national-committees/selectors";
import { scrollToElement, scrollTop } from "../../../../../utils/scroll";
import PageNotFound from "../../../404/PageNotFound";
import { setPageTitle } from "../../../../../utils/pageTitle";
import { Helmet } from "react-helmet";
import classNames from "classnames";
import ReferenceComponent from "../../components/reference/ReferenceComponent";
import {
  emptyReferenceTab,
  setReferenceTab,
} from "../../../../../state/references/actions";
import { getSelectedReferenceTab } from "../../../../../state/references/selectors";
import { REFERENCE_TAB } from "../../../../../state/references/reducer";
import { PollRow } from "../../components/poll/PollRow";
import { PollPage } from "../../components/poll/PollPage";
import { ReferencePage } from "../../components/reference/ReferencePage";

class NotificationForm extends Component {
  constructor(props) {
    super(props);
    this.pollRef = createRef();
    this.referencesRef = createRef();
    this.state = {
      validation: {
        title: {
          isValid: true,
        },
      },
      formSubmitted: false,
      pollTab: false,
    };
  }

  componentDidMount() {
    this.props.emptyReferenceTab();
    this.props.onMount();
    scrollTop();
  }

  componentWillUnmount() {
    this.props.clearNotificationForm();
  }

  setNotificationData = (object) => {
    this.props.addGeneralNotificationInformation(object);
  };

  onUpdateDocument = (document) => {
    const updatedDocuments = this.props.formData.referencesPerType.document.map(
      (d) => {
        if (d.url === document.url) {
          return document;
        }
        return d;
      },
    );

    this.props.setSelectedNotificationDocuments(updatedDocuments);
  };

  onRemoveDocument = (id) => {
    this.props.setSelectedNotificationDocuments(
      this.props.formData.referencesPerType.document.filter(
        (d) => d.url !== id,
      ),
    );
  };

  onAddDocuments = (documents) => {
    this.props.setSelectedNotificationDocuments(documents);
  };

  onRemoveDeadline = (id) => {
    this.props.setSelectedNotificationDeadlines(
      this.props.formData.referencesPerType.deadline.filter(
        (d) => d.uri !== id,
      ),
    );
  };

  onSetDeadline = (deadlines) => {
    this.props.setSelectedNotificationDeadlines(deadlines);
  };

  onRemoveMeeting = (id) => {
    this.props.setSelectedNotificationMeetings(
      this.props.formData.referencesPerType.meeting.filter((d) => d.uri !== id),
    );
  };

  onSetMeeting = (meetings) => {
    this.props.setSelectedNotificationMeetings(meetings);
  };

  setNotifyMembers = () => {
    const state = { notifyMembers: !this.props.formData.notifyMembers };
    this.setNotificationData(state);
  };

  isTitleEmpty = () => {
    return this.props.formData.title.trim() === "";
  };

  onEditorSubmitAction = (e) => {
    const { setReferenceTab } = this.props;
    e.preventDefault();

    if (this.currentContent === "poll" && !this.pollRef.current?.onAddPoll())
      return;

    if (this.isTitleEmpty()) {
      setReferenceTab(REFERENCE_TAB.NONE);
      return this.setState(
        {
          pollTab: false,
          validation: {
            title: {
              isValid: false,
            },
          },
        },
        () => scrollToElement(".validation-message__error"),
      );
    }
    // this.props.editorSubmitAction(formData.uri);
    this.props.editorSubmitAction();
    this.setState({ formSubmitted: true });
  };

  renderNotifyRadio() {
    const { t, formData } = this.props;

    return (
      <div className="group">
        <label htmlFor="notification-email" className="inline-checkbox">
          <input
            className="inline-checkbox__input"
            type="checkbox"
            name="notification-email"
            value=""
            id="notification-email"
            checked={formData.notifyMembers}
            onChange={this.setNotifyMembers}
          />
          <span className="inline-checkbox__input--off icon icon-radio-off"></span>
          <span className="inline-checkbox__input--on icon icon-radio-on"></span>
          <span>{t("notifications.detail.notifyMembers")}</span>
        </label>
      </div>
    );
  }

  get footerSecondaryButton() {
    const { t, setReferenceTab, editorCancelUrl } = this.props;

    switch (this.currentContent) {
      case "poll":
        return (
          <button onClick={this.onTogglePoll} className="button">
            {t("general.button.cancel")}
          </button>
        );
      case "reference":
        return (
          <button
            onClick={() => setReferenceTab(REFERENCE_TAB.NONE)}
            className="button"
          >
            {t("general.button.cancel")}
          </button>
        );
      default:
        return (
          <NavLink to={editorCancelUrl} className="button">
            {t("general.button.cancel")}
          </NavLink>
        );
    }
  }

  get footerPrimaryButtonDisable() {
    switch (this.currentContent) {
      case "general":
        return this.state.formSubmitted;
      default:
        return false;
    }
  }

  get footerPrimaryAction() {
    switch (this.currentContent) {
      case "poll":
        return () => this.pollRef.current?.onAddPoll();
      case "reference":
        return () => this.referencesRef.current.onClick();
      default:
        return this.onEditorSubmitAction;
    }
  }

  get footerPrimaryButtonText() {
    const { t } = this.props;

    switch (this.currentContent) {
      case "poll":
        return t("notifications.poll.button.addPoll");
      case "reference":
        return t("general.button.addSelected");
      default:
        return t("general.button.save");
    }
  }

  get footerSecondaryButtonText() {
    const { t } = this.props;

    switch (this.currentContent) {
      case "poll":
        return t("notifications.poll.button.addPoll");
      case "reference":
        return t("general.button.addSelected");
      default:
        return t("general.button.save");
    }
  }

  renderFooter() {
    return (
      <>
        <div className="group group--btn u-align-right">
          {this.footerSecondaryButton}
          <button
            className={classNames("button button--primary", {
              "button--awaiting button--disabled": this.state.formSubmitted,
            })}
            onClick={this.footerPrimaryAction}
            disabled={this.footerPrimaryButtonDisable}
          >
            {this.footerPrimaryButtonText}
          </button>
        </div>
      </>
    );
  }

  onTogglePoll = () => {
    this.setState({ pollTab: !this.state.pollTab });
  };

  renderGeneralTab() {
    const { formData } = this.props;
    const { validation } = this.state;

    return (
      <>
        <NotificationGeneralInfoTab
          data={formData}
          setData={this.setNotificationData}
          validation={validation}
          // handleSubmit={this.props.editorSubmitAction}
          // cancelNavLinkUrl={this.props.editorCancelUrl}
        />
        <PollRow poll={formData.poll} onAddPoll={this.onTogglePoll} />
        <ReferenceComponent
          isModal
          referencesPerType={formData.referencesPerType}
          // document
          onUpdateDocument={this.onUpdateDocument}
          onRemoveDocument={this.onRemoveDocument}
          // meeting
          onRemoveMeeting={this.onRemoveMeeting}
          // deadline
          onRemoveDeadline={this.onRemoveDeadline}
        />
        {this.renderNotifyRadio()}
      </>
    );
  }

  renderReferenceTab() {
    const { formData } = this.props;

    return (
      <ReferencePage
        ref={this.referencesRef}
        // Documents
        onUpdateDocument={this.onUpdateDocument}
        onRemoveDocument={this.onRemoveDocument}
        onAddDocuments={this.onAddDocuments}
        // Deadline
        onRemoveDeadline={this.onRemoveDeadline}
        onSetDeadline={this.onSetDeadline}
        referencesPerType={formData.referencesPerType}
        // meeting
        onRemoveMeeting={this.onRemoveMeeting}
        onSetMeeting={this.onSetMeeting}
      />
    );
  }

  renderPollTab() {
    const { formData } = this.props;

    return (
      <PollPage
        ref={this.pollRef}
        poll={formData.poll}
        onClose={this.onTogglePoll}
        onAdd={this.setNotificationData}
      />
    );
  }

  get isGeneralTab() {
    return (
      this.props.getSelectedReferenceTab === REFERENCE_TAB.NONE &&
      !this.state.pollTab
    );
  }

  get isReferenceTabSelected() {
    return (
      REFERENCE_TAB.NONE !== this.props.getSelectedReferenceTab &&
      !this.state.pollTab
    );
  }

  get currentContent() {
    return this.state.pollTab
      ? "poll"
      : this.isGeneralTab
        ? "general"
        : "reference";
  }

  renderContent() {
    switch (this.currentContent) {
      case "general":
        return this.renderGeneralTab();
      case "poll":
        return this.renderPollTab();
      case "reference":
        return this.renderReferenceTab();
      default:
        return <PageNotFound />;
    }
  }

  render() {
    const { t, selectedNationalCommittee, formData, isCreate } = this.props;

    return (
      <Fragment>
        <Helmet>
          <title>
            {setPageTitle(
              isCreate
                ? ["Notifications", "Create new"]
                : ["Notifications", this.props.formData.title, "Edit"],
              selectedNationalCommittee,
            )}
          </title>
        </Helmet>
        <header className="layout__title">
          <h2 className="headline-2">
            {isCreate
              ? t("notifications.detail.new.title")
              : t("notifications.detail.edit.title")}
          </h2>
        </header>
        <div className="layout__content panel detail">
          {this.renderContent()}
        </div>
        <div className="modal__footer">{this.renderFooter()}</div>
      </Fragment>
    );
  }
}

const mapDispatchToProps = {
  setSelectedNotificationDocuments,
  setSelectedNotificationDeadlines,
  setSelectedNotificationMeetings,
  addGeneralNotificationInformation,
  clearNotificationForm,
  emptyReferenceTab,
  setReferenceTab,
};

const mapStateToProps = (state) => ({
  selectedNationalCommittee: selectedNationalCommittee(state),
  getSelectedReferenceTab: getSelectedReferenceTab(state),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation("common"),
)(NotificationForm);
