import React, { Component } from "react";
import { Redirect, withRouter, Switch, Route } from "react-router-dom";
import { connect } from "react-redux";
import Loader from "./components/common/loader/Loader";
import SideMenu from "./components/common/side-menu/SideMenu.js";
import TopBar from "./components/common/top-bar/TopBar.js";
import Logo from "./components/common/logo/Logo.js";
import { selectedNationalCommittee } from "./state/national-committees/selectors";
import { isMobile } from "react-device-detect";
import { userDetails } from "./state/user/selectors";
import { transformCommitteeName } from "./utils/transform";
import { fetchUserDataRequest } from "./state/user/actions";
import { fetchAllValues } from "./state/values/actions";
import { getCommitteeMembers } from "./state/members/actions";
import ConsultantMenu from "./components/common/consultant-menu/ConsultantMenu";
import WithPrivileges from "./components/common/with-privileges/WithPrivileges";
import ReadonlyGuard from "./components/common/readonly-guard/ReadonlyGuard";
import UserHintDialog from "./components/common/user-tips-dialog/UserHintDialog";
import GlobalDialogs from "./components/common/global-dialogs/GlobalDialogs";

import {
  initializeApplication,
  changeNationalCommittee,
} from "./state/app/actions";
import { isApplicationInitialized } from "./state/app/selectors";
// import Search from "./components/pages/search/Search";

import Home from "./components/Home";
import AggHome from "./components/AggHome";
import User, {
  UserPageLinks,
} from "./components/pages/user-account/UserAccount";
import LayoutPanelLeftFooter from "./components/common/side-menu/LayoutPanelLeftFooter";
import SlideModalRouter from "./components/common/slide-modal-router/SlideModalRouter";
import { getHeaderTitle } from "./state/header/selectors";
import { Header } from "./components/common/layout/Header";
import { NotifyToaster } from "./components/common/notify-modal/NotifyToaster";
import NavigationList from "./components/common/side-menu/UserAccountTopNavigation";

//TODO REMOVE __ENABLE_TOPBAR_SEARCH variable
window.__ENABLE_TOPBAR_SEARCH = false;

const matchMediaValue = window.matchMedia("only screen and (min-width: 769px)");

class App extends Component {
  constructor(props) {
    super(props);

    props.initializeApplication();

    this.state = {
      menuOpened: false,
    };
  }

  componentDidMount() {
    window.addEventListener("resize", this.resizeEventThrottler, false);
    isMobile && document.body.classList.add("is-mobile");
  }

  resizeEventListener = () => {
    this.matchMedia = matchMediaValue;
    this.state.menuOpened && this.setState({ menuOpened: false });
  };

  resizeEventThrottler = () => {
    if (!this.eventTimeout) {
      this.eventTimeout = setTimeout(() => {
        this.eventTimeout = null;
        this.resizeEventListener();
      }, 100);
    }
  };

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeEventThrottler, false);
  }

  componentDidUpdate() {
    this.matchMedia = matchMediaValue;
  }

  setClassList = () => {
    const list = [];
    list.push("layout");
    return list.join(" ");
  };

  changeMenuState = () => {
    if (this.matchMedia.matches) {
      this.setState({
        menuOpened: false,
      });
      return;
    }

    this.setState({
      menuOpened: !this.state.menuOpened,
    });
  };

  handleMenuClick = (selectedElement) => {
    this.props.changeNationalCommittee(selectedElement);
    this.changeMenuState();
  };

  setHeadingTitle = () => {
    const { selectedNationalCommittee, location, user } = this.props;
    return location.pathname.includes("/user")
      ? user.name
      : selectedNationalCommittee;
  };

  render() {
    const { menuOpened } = this.state;
    const { selectedNationalCommittee, getHeaderTitle } = this.props;

    return this.props.isApplicationInitialized ? (
      this.state.redirect ? (
        <Redirect
          to={`${transformCommitteeName(selectedNationalCommittee)}/notifications`}
        />
      ) : (
        <div
          className={
            !menuOpened
              ? this.setClassList()
              : this.setClassList() + " menu--opened"
          }
        >
          <div className="layout__panel layout__panel--left">
            <div>
              <Logo
                selectedNationalCommittee={transformCommitteeName(
                  selectedNationalCommittee,
                )}
              />
            </div>
            <SideMenu onClickAction={this.handleMenuClick} />
            <LayoutPanelLeftFooter
              buttonClickHandler={this.changeMenuState}
              headingTitleFunction={this.setHeadingTitle}
            />
          </div>

          {/* Used only for small width */}
          <TopBar
            buttonClickHandler={this.changeMenuState}
            headingTitleFunction={this.setHeadingTitle}
          />

          <Header heading={getHeaderTitle}>
            <Switch>
              <Route path="/user">
                <NavigationList links={UserPageLinks} />
              </Route>
            </Switch>
          </Header>

          <div className="layout__panel layout__panel--right">
            <Switch>
              <Route path="/user" component={User} />
              <Route path="/all" component={AggHome} />
              {/* <Route path="/search" component={Search} /> */}
              <Route path="/" component={Home} />
            </Switch>
          </div>
          <WithPrivileges>
            <ReadonlyGuard>
              <ConsultantMenu />
            </ReadonlyGuard>
          </WithPrivileges>
          <UserHintDialog />
          <GlobalDialogs />
          <SlideModalRouter />
          <NotifyToaster />
        </div>
      )
    ) : (
      <Loader />
    );
  }
}

const mapDispatchToProps = {
  initializeApplication,
  fetchUserData: fetchUserDataRequest,
  fetchAllValues: fetchAllValues,
  getCommitteeMembers: getCommitteeMembers,
  changeNationalCommittee: changeNationalCommittee,
};

const mapStateToProps = (state) => ({
  isApplicationInitialized: isApplicationInitialized(state),
  user: userDetails(state),
  selectedNationalCommittee: selectedNationalCommittee(state),
  getHeaderTitle: getHeaderTitle(state),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
