import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";
import Loader from "../../../../common/loader/Loader";
import types from "../../../../common/modal-wrapper/contentTypes";
import DocumentList from "../../../../common/document-list/List";
import { Link } from "react-router-dom";
import { isConsultant } from "../../../../../state/user/selectors";
import { getModalDetails } from "../../../../../state/document/selectors";
import {
  resetModalState,
  setModalState,
} from "../../../../../state/document/actions";
import DeleteModal from "../../../documents/components/modal-content/DeleteModal";
import DownloadZipButton from "../../../../common/download-zip-button/DownloadZipButton";
import { REFERENCE_TAB } from "../../../../../state/references/reducer";

class ReferenceDocumentsRow extends Component {
  state = {
    addDocumentActionType: null,
  };

  fetchUpdatedDocument = (document) => {
    this.props.onUpdateDocument(document);
  };

  removeDocument = (id) => {
    const { onRemoveDocument, resetModalState } = this.props;
    onRemoveDocument(id);
    resetModalState();
  };

  showModalToRemoveDocumentFromList = (element) =>
    this.props.setModalState({
      isOpen: true,
      contentType: types.REMOVE_FILE_FROM_DOCUMENT_LIST,
      content: element,
    });

  renderRemoveDocumentFromListModal = () => {
    const { modal, t, resetModalState } = this.props;

    return (
      <DeleteModal
        id={modal.content.url}
        title={t("notifications.dialog.delete.title", {
          itemName: modal.content.filename,
        })}
        message={t("notifications.dialog.delete.message", {
          itemName: modal.content.filename,
        })}
        submitHandler={this.removeDocument}
        cancelHandler={resetModalState}
        deleteButtonText={t("general.button.remove")}
      />
    );
  };

  renderListView = () => {
    const {
      referenceDocumentList,
      isConsultant,
      manualSort,
      t,
      downloadDisabled,
    } = this.props;

    return (
      <>
        <div className="group group--first">
          <DocumentList
            disableSort
            onDocumentEdited={this.fetchUpdatedDocument}
            onDocumentDeleted={this.props.onRemoveDocument}
            additionalModals={{
              [types.REMOVE_FILE_FROM_DOCUMENT_LIST]:
                this.renderRemoveDocumentFromListModal,
            }}
            rowActions={{
              [t("documents.action.removeFromNotification")]:
                this.showModalToRemoveDocumentFromList,
            }}
            list={referenceDocumentList}
            enableSelection={false}
            showActionBox={true}
            hideDueDate={!isConsultant}
            showExtendedActions={this.props.showExtendedActions}
            manualSort={manualSort}
            hideNotificationOption={true}
            downloadDisabled={downloadDisabled}
          />
        </div>
      </>
    );
  };

  renderView = () => {
    const { referenceDocumentList } = this.props;

    return (
      <>
        {referenceDocumentList &&
          !!referenceDocumentList.length &&
          this.renderListView()}
      </>
    );
  };

  renderButton = () => {
    const { t, moveToLink, onSelect, isModal } = this.props;
    const selectFromDocuments = t(
      "notifications.detail.new.selectFromDocuments",
    );

    return (
      <>
        {isModal ? (
          <button
            className="button button--small button--secondary"
            onClick={(e) => {
              onSelect(REFERENCE_TAB.DOCUMENTS);
            }}
          >
            {selectFromDocuments}
          </button>
        ) : (
          <Link
            className="button button--small button--secondary"
            to={moveToLink}
          >
            {selectFromDocuments}
          </Link>
        )}
      </>
    );
  };

  render() {
    const { t, disableEdit, referenceDocumentList } = this.props;

    if (disableEdit && !referenceDocumentList?.length) return null;

    return (
      <>
        <div className="gray-box">
          <div className="group group--small group__flex detail__group group--underlined">
            <span className="headline-4 u-text-bold" id="documents">
              {t("documents.header.name")}
            </span>
            {/* buttons */}
            <>
              {disableEdit ? (
                <DownloadZipButton
                  onClick={this.props.downloadDocuments.onClick}
                  isAwaiting={this.props.downloadDocuments.isAwaiting}
                  isDisabled={this.props.downloadDocuments.isDisabled}
                />
              ) : (
                this.renderButton()
              )}
            </>
          </div>
          {this.props.isLoading ? <Loader type="content" /> : this.renderView()}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  modal: getModalDetails(state),
  // documents: getDocuments(state),
  isConsultant: isConsultant(state),
});

const mapDispatchToProps = {
  setModalState,
  resetModalState,
};

export default compose(
  withTranslation("common"),
  connect(mapStateToProps, mapDispatchToProps),
)(ReferenceDocumentsRow);
