import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import {
  addGeneralMeetingInformation,
  createMeeting,
} from "../../../../../state/meetings/actions";
import { initDocuments } from "../../../../../state/document/actions";
import { getNewMeetingFormData } from "../../../../../state/meetings/selectors";
import { getMeetingCommitteeFilterSelectItems } from "../../../../../state/values/selectors";
import { generateCloseDialogLink } from "../../../../../utils/dynamicLink";
import MeetingForm from "../meeting-form/MeetingForm";

class CreateMeeting extends Component {
  onMount = () => {
    this.props.initDocuments();
    if (this.props.committees && this.props.committees.length > 0) {
      this.props.addGeneralMeetingInformation({
        committee: this.props.committees[0].value,
      });
    }
  };

  get editorSubmitAction() {
    return this.props.createMeeting;
  }

  get editorCancelAction() {
    return generateCloseDialogLink();
  }

  setMeetingData = (object) => {
    // preset the meeting time to current time + 30 minutes
    if (object.fromDate && !this.props.formData.fromDate) {
      const currentDate = new Date();

      const currentHours = currentDate.getHours();
      const currentMinutes = currentDate.getMinutes();
      const extraMinutes = 60 - (currentMinutes % 30);

      let newDate = object.fromDate;
      newDate.setHours(currentHours);
      newDate.setMinutes(currentMinutes);
      newDate = new Date(newDate.getTime() + extraMinutes * 60000);
      object.fromDate = newDate;
    }

    this.props.addGeneralMeetingInformation(object);
  };

  render() {
    const { t, formData } = this.props;
    const detailTitle = t("meetings.detail.new.title");

    return (
      <MeetingForm
        isCreate
        formData={formData}
        onMount={this.onMount}
        editorSubmitAction={this.editorSubmitAction}
        editorCancelAction={this.editorCancelAction}
        setMeetingData={this.setMeetingData}
        detailTitle={detailTitle}
      />
    );
  }
}

const mapDispatchToProps = {
  addGeneralMeetingInformation,
  createMeeting,
  initDocuments,
};

const mapStateToProps = (state) => ({
  formData: getNewMeetingFormData(state),
  committees: getMeetingCommitteeFilterSelectItems(state),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation("common"),
)(CreateMeeting);
