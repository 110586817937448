import {
  takeEvery,
  all,
  call,
  put,
  takeLatest,
  select,
} from "redux-saga/effects";
import { NotificationsRepository } from "../services/notifications/notificationsRepository";
import * as actions from "../state/documentSummary/actions";
import * as selectors from "../state/documentSummary/selectors";
import * as documentActions from "../state/document/actions";
import * as documentsApi from "../api/resources/document";

import { SummaryFilesDownloadId } from "../utils/constants";
import { documentFilters } from "../utils/documentUtils";

function* pullDocuments(uris) {
  var documentResponses = yield all(
    uris.map((d) => call(documentsApi.getDocumentSafeUrl, d.uri)),
  );
  return documentResponses.filter((d) => d != null).map((d) => d.data);
}

function* handleGenerateDocumentsSummary({ payload }) {
  const repository = new NotificationsRepository();
  const ids = payload;

  if (!ids) {
    yield put(actions.generateDocumentsSummaryFailed());
    return;
  }

  try {
    const details = yield all(
      ids.map((i) => repository.pullInternalDocumentSummation(i)),
    );
    const uris = details
      .map((d) => d.documents.map((d) => d.uri))
      .reduce((acc, cur) => [...acc, ...cur], []);
    const uniqueUris = [...new Set(uris)];
    const documentResponses = yield all(
      uniqueUris.map((uri) => call(documentsApi.getDocumentSafeUrl, uri)),
    );

    // var documents = documentResponses.filter(d => d != null).map(d => d.data);
    let documents = documentFilters.filterEmptyDocuments(documentResponses);
    documents = documents.map((d) => d.data);

    yield put(actions.generateDocumentsSummarySucceeded(documents));
  } catch (err) {
    console.error(err);
    yield put(actions.generateDocumentsSummaryFailed());
  }
}

function* handleDownloadDocumentsSummaryFiles() {
  const documentsUris = yield select(selectors.getFilesUrls);
  yield put(
    documentActions.downloadZipPackage({
      id: SummaryFilesDownloadId,
      packageName: "documents.zip",
      uris: documentsUris,
    }),
  );
}

export default function* documentSummationSaga() {
  yield takeEvery(
    actions.generateDocumentsSummary,
    handleGenerateDocumentsSummary,
  );
  yield takeLatest(
    actions.downloadDocumentsSummaryFiles,
    handleDownloadDocumentsSummaryFiles,
  );
}
