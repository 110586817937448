export function normalizeCommittees(committees, previousCommittees) {
  const newCommittees = committees.reduce((acc, committee) => {
    acc[committee.committee] = {
      id: committee.committee,
    };

    return acc;
  }, {});

  return {
    ...newCommittees,
    // ...previousCommittees,
  };
}

export function normalizeAssociatedCommittees(parentCommittee, state) {
  let internationalCommittees = [];

  parentCommittee.organizationUnits.forEach((unit) => {
    unit.subCommittees.forEach((subCommittee) => {
      internationalCommittees.push({
        name: subCommittee.id,
        description: subCommittee.title,
        parent: unit.committee.id,
      });
    });
    unit.workGroups.forEach((subCommittee) => {
      internationalCommittees.push({
        name: subCommittee.id,
        description: subCommittee.title,
        parent: unit.committee.id,
      });
    });

    internationalCommittees.push({
      name: unit.committee.id,
      description: unit.committee.title,
      parent: null,
    });
  });

  return {
    ...state,
    [parentCommittee.committee.id]: {
      ...state[parentCommittee.committee.id],
      title: parentCommittee.committee.title,
      internationalCommittees: internationalCommittees,
    },
  };
}

export function normalizeCommitteesList(committees) {
  return committees.map((current) => {
    let previous = {
      name: current.name,
      value: current.name,
      description: current.description,
    };

    return previous;
  });
}
