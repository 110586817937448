import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withTranslation } from "react-i18next";

import Stepper from "../../../../common/stepper/Stepper";
import Voter from "../../../../common/voter/Voter";
import VerticalSpacer from "../../../../common/layout/VerticalSpacer";
import OfficialVote from "../../../../common/official-vote/OfficialVote";

import {
  downloadDeadlineDocuments,
  removeReferenceFromDeadline,
  updateDeadlineReferenceInfo,
} from "../../../../../state/deadlines/actions";
import * as votesActions from "../../../../../state/votes/actions";

import { isDownloadingFile } from "../../../../../state/document/selectors";
import {
  voteDetails,
  voteStats,
  officialVote,
  isUpdatingVote,
} from "../../../../../state/votes/selectors";
import { getStages } from "../../../../../state/values/selectors";

import {
  hasDeadlinePassed,
  isFetchingDeadlines,
} from "../../../../../state/deadlines/selectors";
import {
  isConsultant,
  userId,
  isMemberInCurrentCommittee,
} from "../../../../../state/user/selectors";

import { transformUri } from "../../../../../utils/transform";

import "./DeadlineDetailsTab.scss";
import ConfirmationModal from "../../../../common/confirmation-modal/ConfirmationModal";
import { applicationPushGlobalDialog } from "../../../../../state/app/actions";
import ReferenceComponent from "../../../notifications/components/reference/ReferenceComponent";
import { REFERENCE_TAB } from "../../../../../state/references/reducer";

class DeadlineDetailsTab extends Component {
  constructor() {
    super();
    this.state = {
      notificationsOn: false,
    };
  }

  componentDidMount() {
    this.props.initDeadlineVotes(this.props.uri);
  }

  handleCheckboxChange = () => {
    this.setState({
      notificationsOn: !this.state.notificationsOn,
    });
  };

  downloadAllFiles = () => {
    this.props.downloadDeadlineDocuments(transformUri(this.props.uri));
  };

  get hasDownloadableDocuments() {
    return this.props.references.filter((reference) => !reference.isLink)
      .length;
  }

  onOverrideOfficialVote = (vote) => {
    this.props.overrideOfficialVote({
      uri: this.props.uri,
      vote,
    });
  };

  onChangeVote = (vote) => {
    const { t, date } = this.props;

    const deadlineDate = new Date(date);
    deadlineDate.setDate(deadlineDate.getDate() - 14);

    if (new Date() < deadlineDate) return;

    this.props.applicationPushGlobalDialog({
      component: ConfirmationModal,
      modalSettings: {
        allowEscape: true,
        closeButton: true,
      },
      props: {
        title: t("deadlines.details.lateVoteTitle"),
        message: t("deadlines.details.lateVoteDescription"),
        disableSubmitButton: true,
        disableSlide: true,
      },
      disableSlide: true,
    });
  };

  onUpdateDocument = (document) => {
    this.props.updateDeadlineReferenceInfo(document);
  };

  onRemoveReferenceFromDeadline = (id) => {
    this.props.removeReferenceFromDeadline(id);
  };

  render() {
    const {
      stage,
      uri,
      title,
      description,
      committee,
      stages,
      officialVote,
      enableVoting,
      hasDeadlinePassed,
      isConsultant,
      referencesPerType,
      closedForComments,
    } = this.props;

    return (
      <div className="tab-content deadline-details">
        {description && (
          <div className="group deadline-details__description group--underlined">
            <div className="detail__description">
              <p dangerouslySetInnerHTML={{ __html: description }}></p>
            </div>
          </div>
        )}

        <Stepper
          stage={stage}
          stages={stages}
          titleTranslationBase="deadlines.details.stages"
        />

        {enableVoting ? (
          <div className="group group--first group--underlined deadline-details__votes">
            <Voter
              deadlineUri={uri}
              deadlineStage={stage}
              headline={"Votes " + committee + " - " + title}
              disableVoting={hasDeadlinePassed}
              votes={this.props.votes}
              statistics={this.props.votesStatistics}
              userID={this.props.userID}
              isMemberInCurrentCommittee={this.props.isMemberInCurrentCommittee}
              createVote={this.props.createVote}
              updateVote={this.props.updateVote}
              removeVote={this.props.removeVote}
              onChangeVote={this.onChangeVote}
              updateInProgress={this.props.isUpdatingVote}
              committee={this.props.committee}
            />
            {(hasDeadlinePassed || isConsultant) && (
              <OfficialVote
                vote={officialVote}
                deadlineStage={stage}
                onOverrideOfficialVote={this.onOverrideOfficialVote}
              />
            )}
            <VerticalSpacer type="medium" />
          </div>
        ) : null}

        <ReferenceComponent
          disableRows={[REFERENCE_TAB.DEADLINES]}
          onUpdateDocument={this.onUpdateDocument}
          onRemoveDocument={this.onRemoveReferenceFromDeadline}
          onRemoveMeeting={this.onRemoveReferenceFromDeadline}
          // for download zip file
          downloadDocuments={{
            onClick: this.downloadAllFiles,
            isAwaiting: this.props.isDownloadingZipPackage,
            isDisabled:
              this.props.isProcessingRequest ||
              referencesPerType.document.length === 0,
          }}
          referencesPerType={referencesPerType}
          // formData={{referencesPerType}}
          disableEdit
        />
      </div>
    );
  }
}

const mapStateToProps = (state, props) => ({
  stages: getStages(state),
  hasDeadlinePassed: hasDeadlinePassed(state),

  userID: userId(state),
  isMemberInCurrentCommittee: isMemberInCurrentCommittee(state),
  isConsultant: isConsultant(state),

  votes: voteDetails(state),
  votesStatistics: voteStats(state),
  officialVote: officialVote(state),
  isUpdatingVote: isUpdatingVote(state),
  isProcessingRequest: isFetchingDeadlines(state),

  isDownloadingZipPackage: isDownloadingFile(state, transformUri(props.uri)),
});

const mapDispatchToProps = {
  initDeadlineVotes: votesActions.initDeadlineVotes,
  overrideOfficialVote: votesActions.overrideOfficialVote,
  createVote: votesActions.createVote,
  updateVote: votesActions.updateVote,
  removeVote: votesActions.removeVote,
  downloadDeadlineDocuments,
  applicationPushGlobalDialog,
  updateDeadlineReferenceInfo,
  removeReferenceFromDeadline,
};

export default compose(
  withTranslation("common"),
  connect(mapStateToProps, mapDispatchToProps),
)(DeadlineDetailsTab);
