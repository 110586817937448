import * as notificationsApi from "../../api/resources/notifications";
import * as deadlinesApi from "../../api/resources/deadlines";
import * as meetingsApi from "../../api/resources/meetings";
import * as commentsApi from "../../api/resources/comments";
import * as membersApi from "../../api/resources/members";
import * as userApi from "../../api/resources/user";
import * as votesApi from "../../api/resources/votes";
import * as documentsApi from "../../api/resources/document";

import { buildQueryParams } from "./url";
import { notificationType } from "../../utils/constants";
import { transformCommitteeName } from "../../utils/transform";
import { isRequestSuccessful } from "../../api/utils";

import * as votesActions from "../../state/votes/actions";

export class NotificationsRepository {
  constructor() {
    this.cache = {};
  }

  async pullAllPages(pullFnc, pageSize, maxPages) {
    var data = [];
    for (var i = 1; i <= maxPages; i++) {
      const page = await pullFnc(i, pageSize);

      data = [...data, ...page.data];

      if (page.data.length === 0 || page.data.length < pageSize) return data;
    }

    return data;
  }

  pullNotifications = (committeeId, type, since, till, page, pageSize) => {
    const queryParams = {
      nationalCommittee: transformCommitteeName(committeeId),
      type,
      since,
      till,
      page,
      pageSize,
    };

    return notificationsApi.queryNotifications(queryParams);
  };

  pullUserNotificationsPage = (committeeId, filters, page) => {
    const queryParams = buildQueryParams(committeeId, page, filters);

    if (filters.onlyUnseen) {
      return notificationsApi.getUserUnseenNotifications(queryParams);
    } else {
      return notificationsApi.getUserNotifications(queryParams);
    }
  };

  pullNotificationDetails = (notification, callbackAction) => {
    const notificationId = notification.uri;
    const contentId = notification.document_uri;

    if (notification.type === notificationType.meeting) {
      return this.pullGenericNotificationDetails(
        meetingsApi.getMeetingUrl,
        notificationId,
        contentId,
      );
    } else if (notification.type === notificationType.document) {
      return this.pullGenericNotificationDetails(
        documentsApi.getDocumentUrl,
        notificationId,
        contentId,
      );
    } else if (notification.type === notificationType.documentSummation) {
      return this.pullGenericNotificationDetails(
        notificationsApi.getNotification,
        notificationId,
        notificationId,
      );
    } else if (notification.type === notificationType.deadline) {
      return this.pullGenericNotificationDetails(
        deadlinesApi.getDeadlineUrl,
        notificationId,
        contentId,
      );
    } else if (notification.type === notificationType.comment) {
      return this.pullCommentNotificationDetails(notificationId, contentId);
    } else if (notification.type === notificationType.user) {
      return this.pullUserNotificationDetails(notificationId);
    } else if (notification.type === notificationType.member) {
      return this.pullMemberNotificationDetails(notificationId, contentId);
    } else if (notification.type === notificationType.vote) {
      return this.pullVoteNotificationDetails(notificationId, contentId);
    } else if (
      notification.type === notificationType.internalDocumentSummation
    ) {
      return this.pullInternalDocumentSummation(notificationId);
    } else if (notification.type === notificationType.internalVoteSummation) {
      return this.pullVoteOrCommentSummaryInternalNotificaion(
        notificationId,
        contentId,
        callbackAction,
      );
    } else if (
      notification.type === notificationType.internalCommentsSummation
    ) {
      return this.pullVoteOrCommentSummaryInternalNotificaion(
        notificationId,
        contentId,
        callbackAction,
      );
    }

    console.error(`Unsupported notification type: '${notification.type}', 
            notificationId: '${notificationId}', contentId: '${contentId}'`);
    return Promise.resolve(this.errorResult(notificationId));
  };

  errorResult(notificationId) {
    return { notificationId, failed: true };
  }

  makeSureIsSuccessful(response) {
    if (isRequestSuccessful(response)) {
      return response;
    }

    throw response;
  }

  async pullGenericNotificationDetails(apiFnc, notificationId, contentId) {
    try {
      const response = await this.cachedRequest(contentId, apiFnc);
      return { notificationId, ...response.data };
    } catch (error) {
      console.error(`Error while pulling '${apiFnc.name}'
                notification details (notification id: '${notificationId}', content id: '${contentId}')`);
      console.error(error);
      return this.errorResult(notificationId);
    }
  }

  async pullCommentNotificationDetails(notificationId, contentId) {
    try {
      const notification = await this.cachedRequest(
        notificationId,
        notificationsApi.getNotification,
      );

      const deadlineNotification = await this.cachedRequest(
        contentId,
        deadlinesApi.getDeadlineUrl,
      );

      const deadlineComments = await this.cachedRequest(
        contentId,
        commentsApi.getDeadlineCommentsStatistics,
      );

      return {
        notificationId,
        notification: notification.data,
        deadline: {
          ...deadlineNotification.data,
          comments: deadlineComments.data.numberOfComments,
        },
      };
    } catch (error) {
      console.error(`Error while pulling 'getComment' 
                notification details (notification id: ${notificationId}, content id: ${contentId})`);
      console.error(error);
      return this.errorResult(notificationId);
    }
  }

  async pullUserNotificationDetails(notificationId) {
    try {
      const notification = await this.cachedRequest(
        notificationId,
        notificationsApi.getNotification,
      );
      const user = await this.cachedRequest(
        notification.data.memberId,
        userApi.getUserById,
      );

      return {
        notificationId,
        ...notification.data,
        member: { ...user.data },
      };
    } catch (error) {
      console.error(`Error while pulling 'getNotification'
                notification details (notification id: ${notificationId})`);
      console.error(error);
      return this.errorResult(notificationId);
    }
  }

  async pullMemberNotificationDetails(notificationId, contentId) {
    try {
      const notification = await this.cachedRequest(
        contentId,
        membersApi.getMemberNotification,
      );
      //TODO remove this tmp. code after Niclas fixes
      const dsMember = await this.cachedRequest(
        notification.data.id,
        membersApi.getDsMemberById,
      );
      const user = await this.cachedRequest(
        dsMember.data.email,
        userApi.getUserByName,
      );

      return {
        notificationId,
        ...user.data,
        date: notification.data.memberSince,
        udvalg: notification.data.udvalg,
      };
    } catch (error) {
      console.error(
        `Error while pulling member notification details (id: ${notificationId})`,
      );
      console.error(error);
      return this.errorResult(notificationId);
    }
  }

  async pullVoteNotificationDetails(notificationId, contentId) {
    try {
      const notification = await this.cachedRequest(
        notificationId,
        notificationsApi.getNotification,
      );
      const deadline = await this.cachedRequest(
        contentId,
        deadlinesApi.getDeadlineUrl,
      );
      const deadlineComments = await this.cachedRequest(
        contentId,
        commentsApi.getDeadlineCommentsStatistics,
      );
      const user = await this.cachedRequest(
        notification.data.memberId,
        userApi.getUserById,
      );

      return {
        notificationId,
        deadline: {
          ...deadline.data,
          comments: deadlineComments.data.numberOfComments,
        },
        user: user.data,
        comments: deadlineComments.data,
      };
    } catch (error) {
      console.error(`getting vote notification details failed - ${error}`);
      return this.errorResult(notificationId);
    }
  }

  async pullInternalDocumentSummation(notificationId) {
    try {
      const notification = await this.cachedRequest(
        notificationId,
        notificationsApi.getInternalNotification,
      );

      return {
        notificationId,
        ...notification.data,
      };
    } catch (error) {
      console.error(
        `getting internal-document-summation notification details failed - ${error}`,
      );
      return this.errorResult(notificationId);
    }
  }

  async pullVoteOrCommentSummaryInternalNotificaion(
    notificationId,
    contentId,
    callbackAction,
  ) {
    try {
      const notification = await this.cachedRequest(
        notificationId,
        notificationsApi.getInternalNotification,
      );
      const deadline = await this.cachedRequest(
        contentId,
        deadlinesApi.getDeadlineUrl,
      );
      const deadlineComments = await this.cachedRequest(
        contentId,
        commentsApi.getDeadlineCommentsStatistics,
      );

      const votes =
        deadline && deadline.data.enableVoting
          ? await this.cachedRequest(contentId, votesApi.getVotes)
          : null;

      if (votes && votes.data) {
        callbackAction(votesActions.getAllVotesSucceeded(votes.data));
      }

      return {
        notificationId,
        ...notification.data,
        deadline: {
          ...deadline.data,
          comments: deadlineComments.data.numberOfComments,
        },
      };
    } catch (error) {
      console.error(`getting internal notification details failed - ${error}`);
      return this.errorResult(notificationId);
    }
  }

  cachedRequest = async (key, requestFnc) => {
    const pref = requestFnc.name;
    const prefKey = `${pref}_${key}`;

    if (this.cache[prefKey]) {
      return this.makeSureIsSuccessful(await this.cache[prefKey]);
    }

    this.cache[prefKey] = requestFnc(key);
    return this.makeSureIsSuccessful(await this.cache[prefKey]);
  };
}
