import { api } from "../api";
import { transformCommitteeName } from "../../utils/transform";

export function getUser() {
  return api.get("/api/kundeportal/users/current");
}

export function getUserById(userId) {
  return api.get(`/api/kundeportal/users?userId=${userId}`);
}

export function getUserByName(name) {
  return api.get(`/api/kundeportal/users?name=${name}`);
}

export function saveUserDetails(userDetails) {
  return api.post("/api/users/current", userDetails);
}

export function changeUserCredentials(userCredentials) {
  return api.post("/api/users/current/password", userCredentials);
}

export function getUserSettings(nationalCommittee) {
  return api.get(
    `/api/users/current/settings?nationalCommittee=${transformCommitteeName(
      nationalCommittee,
    )}`,
  );
}

export function turnOffCommittees(nationalCommittee, turnOffCommittees) {
  return api.post(
    `/api/users/current/settings/turnedOffCommittees?nationalCommittee=${transformCommitteeName(
      nationalCommittee,
    )}`,
    turnOffCommittees,
  );
}

export function turnOffCommittee(nationalCommittee, turnOffCommittee) {
  return api.post(
    `/api/users/current/settings/turnedOffCommittee?nationalCommittee=${transformCommitteeName(
      nationalCommittee,
    )}&turnedOffCommittee=${transformCommitteeName(turnOffCommittee)}`,
  );
}

export function turnOnCommittee(nationalCommittee, turnOffCommittee) {
  return api.delete(
    `/api/users/current/settings/turnedOffCommittee?nationalCommittee=${transformCommitteeName(
      nationalCommittee,
    )}&turnedOffCommittee=${transformCommitteeName(turnOffCommittee)}`,
  );
}

export function setUserEmailSetting(nationalCommittee, emailSetting) {
  return api.post(
    `/api/users/current/settings/emails?nationalCommittee=${transformCommitteeName(
      nationalCommittee,
    )}`,
    JSON.stringify(emailSetting),
  );
}

export function saveUserProfilePhoto(photo) {
  return api.post("/api/users/current/profilePhoto", photo);
}

export function deleteUserProfilePhoto() {
  return api.delete("/api/users/current/profilePhoto");
}

export function getUserLastSearches() {
  return api.get("/api/users/current/searchHistory");
}

export function setUserLastSearch(entry) {
  return api.post("/api/users/current/searchHistory", JSON.stringify(entry));
}

export function setUserLocale(locale) {
  return api.post("/api/kundeportal/current/locale", JSON.stringify(locale));
}

export function setUserActiveState(userId, state) {
  return api.post(`api/users/active?id=${userId}`, JSON.stringify(state));
}

export function acceptGdpr(version) {
  return api.post("/api/users/current/accept_gdpr", JSON.stringify(version));
}
