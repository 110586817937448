import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { compose } from "redux";
import {
  DIALOG_TYPES,
  PAGE_TYPES,
  generateLink,
} from "../../../../../utils/dynamicLink";
import { Link } from "react-router-dom";
import { selectedNationalCommittee } from "../../../../../state/national-committees/selectors";
import { connect } from "react-redux";

class NotificationTable extends Component {
  onClickLink = (e) => {
    const { closeRelatedContentModal } = this.props;

    closeRelatedContentModal && closeRelatedContentModal();
  };

  generateNotificationDetailLink = (uri) => {
    const { selectedNationalCommittee } = this.props;

    return `${generateLink(
      selectedNationalCommittee,
      PAGE_TYPES.NOTIFICATION,
    )}/${uri.replace(".xml", "")}/details`;
  };

  renderRow = (notification, idx) => {
    const url = this.generateNotificationDetailLink(notification.uri);

    return (
      <tr
        className="notifications-table__row"
        key={`tr-${idx}`}
        title={notification.locationLong}
      >
        <td className="committee">{notification.committeeReference}</td>
        <td className="committeeName">
          {notification.committeeReferenceTitle}
        </td>
        <td className="type">{notification.type}</td>
        <td className="title">
          {/* need to add to close this when the link has been clicked */}
          <Link to={`/${url}`} onClick={this.onClickLink}>
            {notification.title}
          </Link>
        </td>
        <td className="description">{notification.description}</td>
      </tr>
    );
  };

  render() {
    const { notifications } = this.props;

    return (
      <div className="table-wrapper">
        <div className="table-content">
          <table className="table">
            <thead className="notifications-table">
              <tr>
                <th className="committee">Committee</th>
                <th className="committeeName">Committee Name</th>
                <th className="type">Type</th>
                <th className="title">Title</th>
                <th className="description last">Description</th>
              </tr>
            </thead>
            <tbody className="notifications-table">
              {notifications.map(this.renderRow)}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  selectedNationalCommittee: selectedNationalCommittee(state),
});

// const mapDispatchToProps = {
// };

export default compose(
  withTranslation("common"),
  connect(mapStateToProps, null),
)(NotificationTable);
