import { referenceListToReferenceDataPerType } from "../../utils/references";
import {
  normalizeDate,
  reduceCommitteeName,
  removeWrongCharacters,
} from "../../utils/transform";

export const normalizeDeadlines = ({ deadlinesData, commentsStats }) => {
  const deadlines = deadlinesData.results;
  const pageStart = deadlinesData.start;
  return deadlines.reduce((previous, current) => {
    previous[current.uri] = normalizeDeadline({
      deadline: current,
      commentsStats: commentsStats[current.uri],
    });
    previous[current.uri].pageStart = pageStart;
    return previous;
  }, {});
};

export const normalizeDeadline = ({ deadline, commentsStats = {} }) => {
  return {
    uri: deadline.uri,
    id: deadline.id,
    date: normalizeDate(deadline.actionDueDate),
    title: removeWrongCharacters(deadline.title),
    description: deadline.description,
    action: deadline.action,
    stage: deadline.stage,
    enableCommenting: deadline.enableCommenting,
    enableVoting: deadline.enableVoting,
    committee: deadline.committeeReference,
    closedForComments: deadline.closedForComments,
    committeeName: reduceCommitteeName(
      deadline.committeeReference,
      deadline.committeeReferenceTitle,
    ),
    references: deadline.references,
    referencesRawResponsePerType: referenceListToReferenceDataPerType(
      deadline.references,
    ),
    referencesPerType: {
      document: [],
      meeting: [],
      deadline: [],
    },
    comments: commentsStats ? commentsStats.numberOfComments : 0,
    isRegistered: deadline.isRegistered,
  };
};

export const updateDeadlineOnList = (deadlines, newData) => {
  const oldDeadline = deadlines[newData.uri];
  if (!oldDeadline) {
    return deadlines;
  }

  var newDeadline = normalizeDeadline({
    deadline: newData,
    commentsStats: { numberOfComments: oldDeadline.comments },
  });

  return { ...deadlines, [newData.uri]: newDeadline };
};

export const addCommnetToActiveDeadlineOnList = (
  deadlines,
  deadlineId,
  count,
) => {
  let deadline = deadlines[deadlineId];
  if (!deadline) {
    return deadlines;
  }

  deadline.comments += count;
  if (deadline.comments < 0) deadline.comments = 0;
  return { ...deadlines, [deadlineId]: deadline };
};

export const emptyDeadline = () => ({
  uri: "",
  id: "",
  date: null,
  title: "",
  description: "",
  action: "",
  stage: "",
  enableCommenting: false,
  enableVoting: false,
  closedForComments: false,
  committee: "",
  committeeName: "",
  referencesRawResponsePerType: {
    document: [],
    meeting: [],
    deadline: [],
  },
  referencesPerType: {
    document: [],
    meeting: [],
    deadline: [],
  },
  references: [],
  comments: [],
});
