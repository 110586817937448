import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import "./Member.scss";

import ModalWrapper from "../../../../common/modal-wrapper/ModalWrapper";
import ConfirmationModal from "../../../../common/confirmation-modal/ConfirmationModal";

class DeactivationConfirmationModal extends Component {
  render() {
    const { t, showModal, onCancel, onSubmit, itemUri, type, name } =
      this.props;
    if (type === "activation") {
      return (
        <ModalWrapper isOpen={showModal} requestCloseHandler={onCancel}>
          <ConfirmationModal
            id={itemUri}
            title={t("member.dialogs.confirmation.activateTitle")}
            message={t("member.dialogs.confirmation.activateBody", { name })}
            submitHandler={onSubmit}
            cancelAction={onCancel}
            submitLabel={t("member.dialogs.confirmation.activateSubmit")}
            disableSlide
          />
        </ModalWrapper>
      );
    } else {
      return (
        <ModalWrapper isOpen={showModal} requestCloseHandler={onCancel}>
          <ConfirmationModal
            id={itemUri}
            title={t("member.dialogs.confirmation.deactivateTitle")}
            message={t("member.dialogs.confirmation.deactivateBody", { name })}
            submitHandler={onSubmit}
            cancelAction={onCancel}
            submitLabel={t("member.dialogs.confirmation.deactivateSubmit")}
            disableSlide
          />
        </ModalWrapper>
      );
    }
  }
}

export default withTranslation("common")(DeactivationConfirmationModal);
