import { createSelector } from "reselect";
import { TIMEFRAME } from "../../api/utils";

const deadlines = (state) => state.deadlines;

export const isFetchingDeadlines = (state) =>
  state.deadlines.isProcessingRequest;
export const isProcessingReferences = (state) =>
  state.deadlines.isProcessingReferences;
export const isDeletingDeadline = (state) => state.deadlines.isDeletingDeadline;

export const getDeadline = createSelector([deadlines], (deadlines) => {
  return deadlines.deadline;
});

const deadlineOrderShowPast = (a, b) => (a.date > b.date ? -1 : 1);
const deadlineOrderShowUpcoming = (a, b) => (a.date > b.date ? 1 : -1);

const deadlineOrder = (timeframe) => {
  if (timeframe === TIMEFRAME.PAST) return deadlineOrderShowPast;
  return deadlineOrderShowUpcoming;
};

export const getRawDeadlines = createSelector([deadlines], (deadlines) => {
  return Object.values(deadlines.data);
});

export const getDeadlines = createSelector([deadlines], (deadlines) => {
  return (
    Object.values(deadlines.data)
      // .sort(deadlineOrder(deadlines.filters.timeframe))
      .reduce((previous, current) => {
        const date = new Date(
          current.date.getYear() + 1900,
          current.date.getMonth(),
        );

        previous[date]
          ? previous[date].push(current)
          : (previous[date] = [current]);

        return previous;
      }, {})
  );
});

export const getDeadlineUri = createSelector([getDeadline], (deadline) =>
  deadline ? deadline.uri : null,
);
export const getDeadlineReferredDocumentsUri = createSelector(
  [getDeadline],
  (deadline) =>
    deadline.referencesRawResponsePerType.document.map((doc) => doc.uri),
);
export const getDeadlineReferences = createSelector(
  [getDeadline],
  (deadline) => deadline.references,
);
export const getDeadlinesFilters = createSelector(
  [deadlines],
  (deadlines) => deadlines.filters,
);
export const hasDeadlinePassed = createSelector([getDeadline], (deadline) => {
  let currentDate = new Date();
  currentDate.setHours(0, 0, 0, 0); //set 00:00
  return deadline.date < currentDate;
});
export const hasMoreDeadlinesToLoad = createSelector(
  [deadlines],
  (deadlines) => deadlines.pagination.hasMoreToLoad,
);

export const getNewDeadlineFormData = (state) => state.deadlines.form;

export const getPagination = (state) => state.deadlines.pagination;
