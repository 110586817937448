import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import ActionBox from "../action-box/ActionBox";
import Toggler from "../toggler/Toggler";
import { withTranslation } from "react-i18next";
import { isCommitteeAlreadyTurnedOff } from "../../../state/user/selectors";
import { turnOnOffCommittee } from "../../../state/user/actions";
import ReadonlyGuard from "../../common/readonly-guard/ReadonlyGuard";

class CardMenu extends Component {
  turnOffCommitteeClick = () => {
    const { committee, turnOnOffCommittee, callbackAction } = this.props;
    turnOnOffCommittee({ committee, callbackAction });
  };

  renderAction(action, index) {
    return (
      <li className="list__element" key={index}>
        <button
          className="button button--link actions-list__button"
          onClick={action.callback}
        >
          {action.label}
        </button>
      </li>
    );
  }

  render() {
    const { committee, t, isCommitteeAlreadyTurnedOff, customActions } =
      this.props;

    return (
      <div className="card__menu">
        <Toggler
          calculatePosition={true}
          clickHandler={() => this.setState({ actionBoxOpened: false })}
        >
          <ReadonlyGuard>
            <ActionBox>
              <li className="list__element">
                <button
                  className="button button--link actions-list__button"
                  onClick={this.turnOffCommitteeClick}
                >
                  {isCommitteeAlreadyTurnedOff
                    ? t("card.turnOn", { committee })
                    : t("card.turnOff", { committee })}
                </button>
              </li>
              {customActions && customActions.map(this.renderAction)}
            </ActionBox>
          </ReadonlyGuard>
        </Toggler>
      </div>
    );
  }
}

const mapDispatchToProps = {
  turnOnOffCommittee,
};

const mapStateToProps = (state, props) => ({
  isCommitteeAlreadyTurnedOff: isCommitteeAlreadyTurnedOff(
    state,
    props.committee,
  ),
});

export default compose(
  withTranslation("common"),
  connect(mapStateToProps, mapDispatchToProps),
)(CardMenu);
