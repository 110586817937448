import { api } from "./../api";
import { addQueryParams, getProxyUrl } from "../utils";

export function getSearch(params) {
  let proxyUrl = getProxyUrl("/Documents/documents/search");
  proxyUrl = addQueryParams(proxyUrl, params);
  return api.get(proxyUrl);
}

export function getSuggestions(params) {
  let proxyUrl = getProxyUrl("/Documents/documents/suggest");
  proxyUrl = addQueryParams(proxyUrl, params);
  return api.get(proxyUrl);
}
