import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { compose } from "redux";
import classNames from "classnames";

class FileInput extends Component {
  state = {
    focused: false,
  };

  resetInput(e) {
    e.target.value = null;
  }

  render() {
    const {
      onChangeHandler,
      isInline,
      isPrimaryButton,
      isSecondaryButton,
      isSmallButton,
      inputOptions,
      buttonText,
      isDisabled,
      id,
    } = this.props;
    const { focused } = this.state;

    return (
      <div
        className={classNames("input-file", {
          "input-file--inline": !!isInline,
        })}
      >
        <label
          htmlFor={id}
          className={classNames(
            "input-file__button button",
            { "button--small": !!isSmallButton },
            { "button--primary": !!isPrimaryButton },
            { "button--secondary": !!isSecondaryButton },
            { "button--disabled": isDisabled },
            { focused: focused },
          )}
        >
          {buttonText}
          <input
            type="file"
            {...inputOptions}
            id={id}
            onChange={onChangeHandler}
            onClick={this.resetInput}
            onBlur={() => this.setState({ focused: false })}
            onFocus={() => this.setState({ focused: true })}
          />
        </label>
      </div>
    );
  }
}

export default compose(withTranslation("common"))(FileInput);
