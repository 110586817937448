import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import NotificationBar from "../notification-bar/NotificationBar";
import NotificationCardHeader from "../notification-card-header/NotificationCardHeader";
import CardContent from "../../../../common/card/CardContent";
import CardAttachments from "../../../../common/card/CardAttachments";
import Loader from "../../../../common/loader/Loader";
import { initNotifications } from "../../../../../state/notifications/actions";
import { selectedNationalCommittee } from "../../../../../state/national-committees/selectors";
import {
  generateDialogLink,
  DIALOG_TYPES,
} from "../../../../../utils/dynamicLink";

import { notificationActionType } from "../../../../../utils/constants";

class DeadlineNotification extends Component {
  get notificationTitle() {
    const { t } = this.props;
    const { action } = this.props.data;

    switch (action) {
      case notificationActionType.add:
        return t("common:notifications.types.deadline.add");
      case notificationActionType.reminder:
        return t("common:notifications.types.deadline.reminder");
      case notificationActionType.update:
        return t("common:notifications.types.deadline.update");
      case notificationActionType.weekReminder:
        return t("common:notifications.types.deadline.weekReminder");
      default:
        return t("common:notifications.types.deadline.update");
    }
  }

  render() {
    const {
      initNotifications,
      data: {
        eventDate,
        description,
        messages,
        comments,
        contentId,
        details,
        notificationId,
      },
    } = this.props;

    const deadlineDetailsUrl = generateDialogLink(
      DIALOG_TYPES.DEADLINE_DETAILS,
      contentId,
    );

    return details ? (
      <Fragment key={notificationId}>
        <NotificationBar
          title={this.notificationTitle}
          date={eventDate}
          notificationMenu={{
            committee: details.committee,
            notificationId: notificationId,
          }}
        />

        <NotificationCardHeader
          date={details.date}
          committee={details.committee}
          committeeName={details.committeeName}
          title={details.title}
          uri={deadlineDetailsUrl}
          notificationId={notificationId}
          callbackAction={initNotifications}
        />

        <CardContent description={description} />

        <CardAttachments
          uri={deadlineDetailsUrl}
          references={details.references && details.references.length}
          comments={comments && comments.length}
          messages={messages && messages.length}
        />
      </Fragment>
    ) : (
      <Loader />
    );
  }
}

const mapStateToProps = (state) => ({
  selectedNationalCommittee: selectedNationalCommittee(state),
});

const mapDispatchToProps = {
  initNotifications,
};

export default compose(
  withTranslation("common"),
  connect(mapStateToProps, mapDispatchToProps),
)(DeadlineNotification);
