import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import classNames from "classnames";

import SelectFilter from "../../../../../components/common/select/SelectFilter";

class CopyToCommittee extends Component {
  state = {
    selectedCommittee: null,
    isAwaiting: false,
  };

  get targetCommittees() {
    const { targetCommittees } = this.props;

    return Object.keys(targetCommittees).map((key, index) => ({
      id: index,
      value: key,
      label: key,
    }));
  }

  copyDocumentToCommittee = () => {
    this.setState({
      isAwaiting: true,
    });

    this.props.onCopy({
      document: this.props.document,
      targetCommittee: this.state.selectedCommittee,
    });
  };

  render() {
    const { t, onClose } = this.props;

    return (
      <div className="modal__form">
        <div className="group group--first group--full-underlined">
          <h3 className="headline-4">
            {t("documents.dialog.copyToCommittee.title")}
          </h3>
        </div>
        <div className="group group--last-underlined">
          <SelectFilter
            id="committeeFilter"
            label={t("documents.dialog.copyToCommittee.label.selectCommittee")}
            labelOnlyForScreenReaders={false}
            items={this.targetCommittees}
            selectedItem={this.state.selectedCommittee}
            onChange={(committeeId) => {
              this.setState({ selectedCommittee: committeeId });
            }}
          />
        </div>
        <div className="modal__footer group group--btn u-align-right">
          <button className="button" onClick={onClose}>
            {t("general.button.cancel")}
          </button>
          <button
            className={classNames("button button--primary", {
              "button--disabled": !this.state.selectedCommittee,
              "button--awaiting button--disabled": this.state.isAwaiting,
            })}
            onClick={this.copyDocumentToCommittee}
            disabled={!this.state.selectedCommittee || this.state.isAwaiting}
          >
            {t("documents.dialog.copyToCommittee.label.copy")}
          </button>
        </div>
      </div>
    );
  }
}

export default withTranslation("common")(CopyToCommittee);
