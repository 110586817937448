import React, { Component, Fragment } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";
import { Helmet } from "react-helmet";
import UrlPattern from "url-pattern";

import PanelContainer from "../../../../common/layout/PanelContainer";
import {
  SlimTabsSwitcher,
  SlimTab,
} from "../../../../common/slim-tabs/SlimTabs";
import Sidebar from "../../components/sidebar/Sidebar";
import {
  getMeetings,
  isProcessingMeetings,
  getMeetingsFilters,
} from "../../../../../state/meetings/selectors";

import "./MeetingTab.scss";
import history from "../../../../../utils/history";
import {
  initMeetings,
  setMeetingsFilterValue,
  resetMeetingsFilters,
} from "../../../../../state/meetings/actions";

import { selectedNationalCommittee } from "../../../../../state/national-committees/selectors";
import { setPageTitle } from "../../../../../utils/pageTitle";

import MeetingList from "./MeetingList";
import { TIMEFRAME } from "../../../../../api/utils";

export const SHOW_UPCOMING_MEETINGS = "_show_upcoming";
export const SHOW_PAST_MEETINGS = "_show_past";

class MeetingTab extends Component {
  parser = new UrlPattern("/:committeeId/meetings(/:meetingId)*");
  state = {
    isDeleteModalOpen: false,
    meetingToDelete: {},
    previouseCommittee: "",
    lastOppendMeetingId: "",
    markedAsRemoved: "",
  };

  getCommitteeFromLocation(location) {
    const result = this.parser.match(location.pathname);
    return result.committeeId;
  }

  // getMeetingId(location) {
  //     const result = this.parser.match(location.pathname);
  //     return result.meetingId;
  // }

  componentDidMount() {
    this.setState({
      previouseCommittee: this.getCommitteeFromLocation(window.location),
    });

    this.unlisten = history.listen((location) => {
      const matchResult = this.parser.match(location.pathname);
      if (!matchResult) return;

      if (this.state.previouseCommittee !== matchResult.committeeId) {
        this.setState({ previouseCommittee: matchResult.committeeId }, () => {
          this.props.resetMeetingsFilters();
          this.props.initMeetings();
        });
      } else {
        if (matchResult.lastOppendMeetingId) {
          this.setState({
            lastOppendMeetingId: matchResult.lastOppendMeetingId,
          });
        }
      }
    });

    this.props.resetMeetingsFilters();
    this.props.initMeetings();
  }

  componentWillUnmount() {
    this.unlisten();
  }

  handleUpcomingFilter = (tab) => {
    this.props.setMeetingsFilterValue({
      timeframe: tab === SHOW_PAST_MEETINGS ? TIMEFRAME.PAST : TIMEFRAME.FUTURE,
      month: null,
    });
    this.props.initMeetings();
  };

  render() {
    const { t, selectedNationalCommittee, filters, isProcessingMeetings } =
      this.props;

    return (
      <Fragment>
        <Helmet>
          <title>{setPageTitle(["Meetings"], selectedNationalCommittee)}</title>
        </Helmet>
        <PanelContainer>
          <header className="layout__title">
            <h2 className="headline-2">{t("meetings.header.name")}</h2>
          </header>
          <div className="layout__content">
            <SlimTabsSwitcher
              onTab={this.handleUpcomingFilter}
              defaultTab={SHOW_UPCOMING_MEETINGS}
              selectedTab={
                filters.timeframe === TIMEFRAME.PAST
                  ? SHOW_PAST_MEETINGS
                  : SHOW_UPCOMING_MEETINGS
              }
              enabled={!isProcessingMeetings}
            >
              <SlimTab
                label={t("meetings.sidebar.showUpcoming")}
                name={SHOW_UPCOMING_MEETINGS}
              />
              <SlimTab
                label={t("meetings.sidebar.showPast")}
                name={SHOW_PAST_MEETINGS}
              />
            </SlimTabsSwitcher>
          </div>
          <div className="layout__content layout__content--with-cards meetings">
            <MeetingList meetings={this.props.meetings} />
            <div className="layout__sidebar deadlines__sidebar">
              <Sidebar />
            </div>
          </div>
        </PanelContainer>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    meetings: getMeetings(state),
    filters: getMeetingsFilters(state),
    isProcessingMeetings: isProcessingMeetings(state),
    selectedNationalCommittee: selectedNationalCommittee(state),
  };
};

const mapDispatchToProps = {
  initMeetings,
  setMeetingsFilterValue,
  resetMeetingsFilters,
};

export default compose(
  withTranslation("common"),
  connect(mapStateToProps, mapDispatchToProps),
)(MeetingTab);
