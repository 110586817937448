import { createSelector } from "reselect";

const search = (state) => state.search;
const documents = (state) => state.search.data.results;
const pagination = (state) => state.search.data.pagination;
export const getSearchFilters = (state) => state.search.filters;
export const getModalDetails = (state) => state.search.modal;
export const getModalData = (state) => state.search.modal.content;

export const getCurrentPage = (state) =>
  parseInt(state.search.data.pagination.currentPage);

export const numberOfPages = createSelector([pagination], (pagination) => {
  return Math.floor(
    (parseInt(pagination.total) + parseInt(pagination.pageSize) - 1) /
      parseInt(pagination.pageSize),
  );
});

export const getTotalNumber = (state) =>
  isNaN(parseInt(state.search.data.pagination.total))
    ? 0
    : parseInt(state.search.data.pagination.total);

export const isProcessingDocuments = (state) => state.search.isFetching;

//todo: change selectedNationalCommittee
export const getDocuments = createSelector([documents], (documents) => {
  if (documents) {
    return typeof documents.Search !== "undefined"
      ? documents.Search
      : documents.length
        ? documents
        : [];
  }
  return [];
});

export const getSortingType = (state) => state.search.sortingType;
export const searchSuggestions = createSelector(
  search,
  (search) => search.suggestions,
);
