import React, { Component } from "react";
import { withTranslation } from "react-i18next";

import Row from "./Row";
import Header from "./Header";
import history from "../../../../../utils/history";
import {
  isConsultant,
  isMemberInCurrentCommittee,
  userDetails,
} from "../../../../../state/user/selectors";
import { compose } from "redux";
import { connect } from "react-redux";
import { deleteComment } from "../../../../../state/comments/actions";
import ModalWrapper from "../../../../common/modal-wrapper/ModalWrapper";
import DeleteModal from "../../../documents/components/modal-content/DeleteModal";

import {
  generateDialogLink,
  DIALOG_TYPES,
} from "../../../../../utils/dynamicLink";

class CommentList extends Component {
  state = {
    isDeleteModalOpen: false,
    comment: null,
  };

  editHandler = (obj) => {
    history.push(
      generateDialogLink(
        DIALOG_TYPES.EDIT_COMMENT,
        obj.uri,
        this.props.deadlineId,
      ),
    );
  };

  deleteHandler = (obj) => {
    this.setState({
      isDeleteModalOpen: true,
      comment: obj,
    });
  };
  onCancelDelete = () => {
    this.setState({
      isDeleteModalOpen: false,
      comment: null,
    });
  };
  onSubmitDelete = () => {
    this.props.deleteComment(this.state.comment);
    this.setState({
      isDeleteModalOpen: false,
      comment: null,
    });
  };

  memberCommentActions = {
    [this.props.t("comments.action.editComment")]: this.editHandler,
    [this.props.t("comments.action.deleteComment")]: this.deleteHandler,
  };

  consultantCommentActions = {
    [this.props.t("comments.action.editComment")]: this.editHandler,
    [this.props.t("comments.action.deleteComment")]: this.deleteHandler,
  };

  defaultCommentActions = null;

  getCommentActions = (user) => {
    if (!this.props.isMemberInCurrentCommittee) {
      if (user.authorId === this.props.user.id) {
        return this.memberCommentActions;
      }

      return this.defaultCommentActions;
    }

    if (this.props.isConsultant) {
      return this.consultantCommentActions;
    }
    //TODO: make some sense for this condition
    if (user.authorId === this.props.user.id) {
      return this.memberCommentActions;
    }

    return this.defaultCommentActions;
  };

  renderRow = (comment, index) => {
    const {
      comments,
      enableSelection,
      showActionBox,
      onSelectItem,
      changeExpanded,
    } = this.props;

    return (
      <Row
        key={index}
        comment={{
          ...comment,
          isLast: index === comments.length - 1 && comments.length > 1,
        }}
        commentActions={this.getCommentActions}
        clickHandler={() => changeExpanded(comment, index)}
        enableSelection={enableSelection}
        showActionBox={showActionBox}
        onSelectItem={onSelectItem}
      />
    );
  };

  render() {
    const {
      enableSelection,
      showActionBox,
      sortingType,
      onSortChange,
      expandAllHandler,
    } = this.props;

    return (
      <div className="table-wrapper">
        <div className="table-content">
          <table className="table comments-table">
            <Header
              expandAllHandler={expandAllHandler}
              enableSelection={enableSelection}
              onSortChange={onSortChange}
              sortingType={sortingType}
              showActionBox={showActionBox}
            />
            <tbody>
              {this.props.comments.map((comment, index) =>
                this.renderRow(comment, index),
              )}
            </tbody>
          </table>
        </div>
        <ModalWrapper
          isOpen={this.state.isDeleteModalOpen}
          requestCloseHandler={() =>
            this.setState({ isDeleteModalOpen: false })
          }
          disableSlide
        >
          <DeleteModal
            title={this.props.t("comments.modal.delete.title")}
            message={this.props.t("comments.modal.delete.message")}
            submitHandler={this.onSubmitDelete}
            cancelHandler={this.onCancelDelete}
          />
        </ModalWrapper>
      </div>
    );
  }
}

const mapDispatchToProps = {
  deleteComment,
};

const mapStateToProps = (state) => ({
  user: userDetails(state),
  isConsultant: isConsultant(state),
  isMemberInCurrentCommittee: isMemberInCurrentCommittee(state),
});

export default compose(
  withTranslation("common"),
  connect(mapStateToProps, mapDispatchToProps),
)(CommentList);
