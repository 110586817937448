import { runSaga } from "../utils/saga";
import { userDetailsSaga } from "./user";
import appSaga from "./app";
import { nationalCommitteesSaga } from "./nationalCommittees";
import membersSaga from "./members";
import settingsSaga from "./settings";
import documentSaga from "./document";
import deadlinesSaga from "./deadlines";
import votesSaga from "./votes";
import notificationsSaga from "./notifications";
import valuesSaga from "./values";
import meetingsSaga from "./meetings";
import searchSaga from "./search";
import commentsSaga from "./comments";
import committeesSaga from "./committees";
import standardsSaga from "./standards";
import documentsSummarySaga from "./documentsSummary";
import diagnosticsSaga from "./diagnostics";

export const runSagas = () => {
  runSaga(userDetailsSaga);
  runSaga(appSaga);
  runSaga(nationalCommitteesSaga);
  runSaga(membersSaga);
  runSaga(settingsSaga);
  runSaga(documentSaga);
  runSaga(deadlinesSaga);
  runSaga(votesSaga);
  runSaga(notificationsSaga);
  runSaga(meetingsSaga);
  runSaga(valuesSaga);
  runSaga(searchSaga);
  runSaga(commentsSaga);
  runSaga(committeesSaga);
  runSaga(standardsSaga);
  runSaga(documentsSummarySaga);
  runSaga(diagnosticsSaga);
};
