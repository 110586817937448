import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

import Sticky from "react-stickynode";
import SelectFilter from "../../../../common/select/SelectFilter";
import FilterOption from "../../../../common/sidebar-filter/FilterOption";
import SidebarFilter from "../../../../common/sidebar-filter/SidebarFilter";

import {
  initNotifications,
  setFilterValue,
  resetNotificationsFilters,
} from "../../../../../state/notifications/actions";
import { getNotificationsFilters } from "../../../../../state/notifications/selectors";
import { getDocumentCommitteeFilterSelectItems } from "../../../../../state/values/selectors";
import OnlyMember from "../../../../common/only-member/OnlyMember";
import { notificationFilterTypes } from "../../../../../utils/constants";

import "./Sidebar.scss";
import { Link } from "react-router-dom";

class Sidebar extends Component {
  state = {
    committee: null,
  };

  componentDidMount() {
    this.setState({
      committee: this.props.filters.committee,
      type: this.props.filters.type,
    });
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.filters.committee !== this.props.filters.committee ||
      prevProps.filters.type !== this.props.filters.type
    ) {
      this.setState({
        ...this.state,
        committee: this.props.filters.committee,
        type: this.props.filters.type,
      });
    }
  }

  submitHandler = (e) => {
    e.preventDefault();

    this.props.setFilterValue({
      committee: this.state.committee,
      type: this.state.type,
    });

    this.props.initNotifications();
  };

  resetFilters = (e) => {
    e.preventDefault();
    this.props.resetNotificationsFilters();
    this.props.initNotifications();
  };

  selectCommittee = (committee) => {
    this.setState({
      ...this.state,
      committee,
    });
  };

  selectNotificationType = (type) => {
    this.setState({
      ...this.state,
      type,
    });
  };

  getNotificationFilterTypes() {
    const { t } = this.props;
    return notificationFilterTypes.map((f) => ({
      ...f,
      label: t(f.label),
    }));
  }

  onMarkNotificationsAsSeen = () => {
    this.props.markAllNotificationsAsSeen();
  };

  onUnseenFilterChange = () => {
    this.props.setFilterValue({ onlyUnseen: !this.props.filters.onlyUnseen });
    this.props.initNotifications();
  };

  render() {
    const {
      t,
      committees,
      markAllNotificationsInProgress,
      filters: { committee, type, onlyUnseen },
    } = this.props;

    return (
      <Sticky top={100}>
        <form className="sidebar-filter__form">
          <OnlyMember>
            <FilterOption
              label={t("notifications.sidebar.onlyUnseen")}
              onChange={this.onUnseenFilterChange}
              state={onlyUnseen}
            />
          </OnlyMember>

          <div className="group group--small group--underlined">
            <h4>{t("filter.button.filter")}</h4>
            <SidebarFilter
              isFiltered={committee || type}
              submitHandler={this.submitHandler}
              resetFilters={this.resetFilters}
            >
              {committees && (
                <div className="group group--small">
                  <SelectFilter
                    items={committees}
                    placeholder={t("filter.placeholder.allCommittees")}
                    label={t("filter.label.chooseCommittee")}
                    labelOnlyForScreenReaders={true}
                    selectedItem={this.state.committee}
                    onChange={this.selectCommittee}
                  />
                </div>
              )}
              <div className="group group--small">
                <SelectFilter
                  items={this.getNotificationFilterTypes()}
                  placeholder="All types"
                  label="choose type"
                  labelOnlyForScreenReaders={true}
                  selectedItem={this.state.type}
                  onChange={this.selectNotificationType}
                />
              </div>
            </SidebarFilter>
          </div>
        </form>
        <OnlyMember>
          <div className="group group--small">
            <Link to="/user/settings" className="button button--link">
              {/* <button
              onClick={this.onMarkNotificationsAsSeen}
              className={classNames(
                "button button--link button--no-border sidebar__markAll",
                {
                  "button--awaiting": markAllNotificationsInProgress,
                  "button--link-disabled": markAllNotificationsInProgress,
                  "sidebar__markAll--ready": !markAllNotificationsInProgress,
                },
              )}
            > */}
              <span className="icon icon-settings" />
              <span className="ml-10">{t("Notification settings")}</span>
            </Link>
          </div>
        </OnlyMember>
      </Sticky>
    );
  }
}

const mapStateToProps = (state) => ({
  filters: getNotificationsFilters(state),
  committees: getDocumentCommitteeFilterSelectItems(state),
});

const mapDispatchToProps = {
  initNotifications,
  setFilterValue,
  resetNotificationsFilters,
};

export default compose(
  withTranslation("common"),
  connect(mapStateToProps, mapDispatchToProps),
)(Sidebar);
