import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import DateThumbnail from "../../../../common/date-thumbnail/DateThumbnail";
import NotificationCardMenu from "../notification-card-menu/NotificationCardMenu";

class NotificationCardHeader extends Component {
  render() {
    const {
      committee,
      committeeName,
      date,
      uri,
      title,
      notificationId,
      callbackAction,
    } = this.props;

    return (
      <div className="card__header">
        <DateThumbnail type="regular" date={date} />
        <div className="card__headings">
          <span className="card__heading-1">
            <span className="u-text-bold">{committee}</span>
            {committeeName && ` - ${committeeName}`}
          </span>
          <Link to={uri} className="card__heading-2" data-link-type="open">
            {title}
          </Link>
        </div>
        {/* <NotificationCardMenu
          committee={committee}
          notificationId={notificationId}
          callbackAction={callbackAction}
        /> */}
      </div>
    );
  }
}

export default withRouter(withTranslation("common")(NotificationCardHeader));
