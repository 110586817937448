import React, { Component, Fragment, createRef } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import MeetingGeneralInfoEditor from "../../components/meeting-detail/MeetingGeneralInfoEditor";
import {
  clearMeetingForm,
  setFormSelectedMeetingDocuments,
  setFormSelectedMeetingReferencesDeadlines,
} from "../../../../../state/meetings/actions";
import { selectedNationalCommittee } from "../../../../../state/national-committees/selectors";
import { setPageTitle } from "../../../../../utils/pageTitle";
import Loader from "../../../../common/loader/Loader";
import { Helmet } from "react-helmet";
import { ReferencePage } from "../../../notifications/components/reference/ReferencePage";
import { scrollToElement } from "../../../../../utils/scroll";
import { NavLink } from "react-router-dom";
import classNames from "classnames";
import "../../../../common/form/form.scss";
import { REFERENCE_TAB } from "../../../../../state/references/reducer";
import {
  emptyReferenceTab,
  setReferenceTab,
} from "../../../../../state/references/actions";
import ReferenceComponent from "../../../notifications/components/reference/ReferenceComponent";
import { getSelectedReferenceTab } from "../../../../../state/references/selectors";

const validationInitialState = {
  committee: { isValid: true },
  title: { isValid: true },
  locationShort: { isValid: true },
  fromDate: { isValid: true },
  toDate: { isValid: true },
};

class MeetingForm extends Component {
  constructor(props) {
    super(props);
    this.referencesRef = createRef();
    this.state = {
      validation: { ...validationInitialState },
      formSubmitted: false,
    };
  }

  componentDidMount() {
    this.props.emptyReferenceTab();
    this.props.onMount();
  }

  componentWillUnmount() {
    this.props.clearMeetingForm();
  }

  // onChangeTab = (selected) => {
  //   this.setState({ selectedTab: selected });
  // };

  get isGeneralTab() {
    return this.props.getSelectedReferenceTab === REFERENCE_TAB.NONE;
  }

  get cancelNavLinkUrl() {
    return this.props.editorCancelAction;
  }

  onUpdateDocument = (document) => {
    const updatedDocuments = this.props.formData.referencesPerType.document.map(
      (d) => {
        if (d.url === document.url) {
          return document;
        }
        return d;
      },
    );

    this.props.setFormSelectedMeetingDocuments(updatedDocuments);
  };

  onAddDocuments = (documents) => {
    this.props.setFormSelectedMeetingDocuments(documents);
  };

  onRemoveDocument = (id) => {
    this.props.setFormSelectedMeetingDocuments(
      this.props.formData.referencesPerType.document.filter(
        (d) => d.url !== id,
      ),
    );
  };

  onSetDeadline = (deadlines) => {
    this.props.setFormSelectedMeetingReferencesDeadlines(deadlines);
  };

  onRemoveDeadline = (id) => {
    // TODO: check if this url is correct ID!
    this.props.setFormSelectedMeetingReferencesDeadlines(
      this.props.formData.referencesPerType.deadline.filter(
        (d) => d.uri !== id,
      ),
    );
  };

  validate = () => {
    const { title, committee, locationShort, fromDate, toDate } =
      this.props.formData;

    let validation = { ...validationInitialState };
    let isValid = true;

    if (committee === "") {
      validation.committee.isValid = false;
      isValid = false;
    }
    if (title.trim() === "") {
      validation.title.isValid = false;
      isValid = false;
    }
    if (locationShort.trim() === "") {
      validation.locationShort.isValid = false;
      isValid = false;
    }

    if (fromDate === "") {
      validation.fromDate.isValid = false;
      isValid = false;
    }

    if (toDate === "") {
      validation.toDate.isValid = false;
      isValid = false;
    }

    if (!isValid) {
      // this.onChangeTab("general");
      this.setState(
        {
          validation,
        },
        () => scrollToElement(".validation-message__error"),
      );
    }
    return isValid;
  };

  onEditorSubmitAction = (e) => {
    const { setReferenceTab } = this.props;

    e.preventDefault();
    if (!this.validate()) {
      setReferenceTab(REFERENCE_TAB.NONE);
      return;
    }
    this.props.editorSubmitAction(this.props.formData);
    this.setState({ formSubmitted: true });
  };

  setIsParticipationEnabled = (value) => {
    this.props.setMeetingData({
      isParticipationEnabled: !this.props.formData.isParticipationEnabled,
    });
  };

  setNotifyOnUpdate = (value) => {
    this.props.setMeetingData({
      notifyOnUpdate: !this.props.formData.notifyOnUpdate,
      notifyImmediately: this.props.formData.notifyOnUpdate
        ? false
        : this.props.formData.notifyImmediately,
    });
  };

  setNotifyImmediately = (value) => {
    this.props.setMeetingData({
      notifyImmediately: !this.props.formData.notifyImmediately,
    });
  };

  renderRadioButtons() {
    const { t, formData } = this.props;

    return (
      <div className="group">
        <div className="group group--field group--underlined">
          <div className="checkbox__group">
            <label
              htmlFor="is-participantation-enabled"
              className={classNames("inline-checkbox")}
            >
              <input
                className="inline-checkbox__input"
                type="checkbox"
                name="is-participantation-enabled"
                checked={formData.isParticipationEnabled}
                id="is-participantation-enabled"
                onChange={this.setIsParticipationEnabled}
              />
              <span className="inline-checkbox__input--off icon icon-radio-off"></span>
              <span className="inline-checkbox__input--on icon icon-radio-on"></span>
              <span className="u-text-medium-dark">
                {t("meetings.participants.radioLabel")}
              </span>
            </label>
          </div>
        </div>
        <div className="group">
          <div className="group group--field">
            <div className="checkbox__group">
              <label
                htmlFor="update-on-notify"
                className={classNames("inline-checkbox", {
                  "inline-checkbox--disabled": formData.past,
                })}
              >
                <input
                  className="inline-checkbox__input"
                  type="checkbox"
                  name="update-on-notify"
                  checked={formData.notifyOnUpdate}
                  id="update-on-notify"
                  onChange={this.setNotifyOnUpdate}
                />
                <span className="inline-checkbox__input--off icon icon-radio-off"></span>
                <span className="inline-checkbox__input--on icon icon-radio-on"></span>
                <span className="u-text-medium-dark">
                  {t("meetings.detail.edit.notifyMembers")}
                </span>
              </label>
              <label
                htmlFor="update-on-notify-immediately"
                className={classNames("inline-checkbox", {
                  "inline-checkbox--disabled": formData.past,
                })}
              >
                <input
                  disabled={!formData.notifyOnUpdate}
                  className="inline-checkbox__input"
                  type="checkbox"
                  name="update-on-notify-immediately"
                  checked={formData.notifyImmediately}
                  id="update-on-notify-immediately"
                  onChange={this.setNotifyImmediately}
                />
                <span
                  className={`inline-checkbox__input--off icon icon-radio-off ${!formData.notifyOnUpdate && "checkbox-disabled"}`}
                ></span>{" "}
                <span className="inline-checkbox__input--on icon icon-radio-on"></span>
                <span className="u-text-medium-dark">
                  {t("notifications.detail.notifyMembers")}
                </span>
              </label>
            </div>
          </div>
        </div>
      </div>
    );
  }

  get footerPrimaryAction() {
    return this.isGeneralTab
      ? this.onEditorSubmitAction
      : () => this.referencesRef.current.onClick();
  }

  get footerSecondaryButton() {
    const { t, setReferenceTab } = this.props;

    return this.isGeneralTab ? (
      <NavLink to={this.cancelNavLinkUrl} className="button">
        {t("general.button.cancel")}
      </NavLink>
    ) : (
      <button
        onClick={() => setReferenceTab(REFERENCE_TAB.NONE)}
        className="button"
      >
        {t("general.button.cancel")}
      </button>
    );
  }

  get footerPrimaryButtonDisable() {
    return this.isGeneralTab ? this.state.formSubmitted : false;
  }

  get footerPrimaryButtonText() {
    const { t } = this.props;

    return this.isGeneralTab
      ? t("general.button.save")
      : t("general.button.addSelected");
  }

  renderFooter() {
    return (
      <div className="group group--btn">
        <div className="u-align-right">
          {/*todo: set correct back link - from window history? */}
          {this.footerSecondaryButton}
          {/*todo: disable button after click (during sending request;
                    to avoid sending form multiple times)*/}
          <button
            className={classNames("button button--primary", {
              "button--awaiting button--disabled": this.state.formSubmitted,
            })}
            onClick={this.footerPrimaryAction}
            disabled={this.footerPrimaryButtonDisable}
          >
            {this.footerPrimaryButtonText}
          </button>
        </div>
      </div>
    );
  }

  renderContent() {
    const { t, selectedNationalCommittee, detailTitle } = this.props;

    return (
      <Fragment>
        <Helmet>
          <title>
            {setPageTitle(
              [
                "Meetings",
                this.props.isCreate ? "Create new" : this.props.formData.title,
              ],
              selectedNationalCommittee,
            )}
          </title>
        </Helmet>
        <header className="layout__title">
          <h2 className="headline-2">{detailTitle}</h2>
        </header>
        {/* <div className="layout__content">
          <SlimTabsSwitcher
            enabled
            // useInternalState
            selectedTab={this.state.selectedTab}
            onTab={this.onChangeTab}
            defaultTab="general"
          >
            <SlimTab label={t("notifications.detail.general")} name="general" />
            <SlimTab label={t("references.name")} name="references" />
          </SlimTabsSwitcher>
        </div> */}
        <div className="layout__content panel detail">
          {this.isGeneralTab
            ? this.renderGeneralTab()
            : this.renderReferenceTab()}
        </div>
        <div className="modal__footer">{this.renderFooter()}</div>
      </Fragment>
    );
  }

  renderGeneralTab() {
    const { formData } = this.props;

    return (
      <>
        <MeetingGeneralInfoEditor
          data={formData}
          setData={this.props.setMeetingData}
          validation={this.state.validation}
          // handleSubmit={this.editorSubmitAction}
          // cancelNavLinkUrl={this.cancelNavLinkUrl}
          // showNotifyMembers
        />
        <ReferenceComponent
          isModal
          disableRows={[REFERENCE_TAB.MEETINGS]}
          // Documents
          onUpdateDocument={this.onUpdateDocument}
          onRemoveDocument={this.onRemoveDocument}
          onAddDocuments={this.onAddDocuments}
          // Deadline
          onRemoveDeadline={this.onRemoveDeadline}
          onSetDeadline={this.onSetDeadline}
          referencesPerType={formData.referencesPerType}
        />
        {this.renderRadioButtons()}
      </>
    );
  }

  renderReferenceTab() {
    return (
      <ReferencePage
        ref={this.referencesRef}
        // Documents
        onUpdateDocument={this.onUpdateDocument}
        onRemoveDocument={this.onRemoveDocument}
        onAddDocuments={this.onAddDocuments}
        // Deadline
        onRemoveDeadline={this.onRemoveDeadline}
        onSetDeadline={this.onSetDeadline}
        referencesPerType={this.props.formData.referencesPerType}
      />
    );
  }

  render() {
    if (this.props.isCreate) {
      return this.renderContent();
    } else {
      return this.props.formData.uri ? (
        this.renderContent()
      ) : (
        <Loader type="content" />
      );
    }
  }
}

const mapDispatchToProps = {
  clearMeetingForm,
  setFormSelectedMeetingDocuments,
  setFormSelectedMeetingReferencesDeadlines,
  emptyReferenceTab,
  setReferenceTab,
};

const mapStateToProps = (state) => ({
  selectedNationalCommittee: selectedNationalCommittee(state),
  getSelectedReferenceTab: getSelectedReferenceTab(state),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation("common"),
)(MeetingForm);
