import { api } from "../api";
import { transformCommitteeName } from "../../utils/transform";
import { addQueryParams, getProxyUrl } from "../utils";

export function getPublishedStandards(committeeId, params) {
  let proxyUrl = getProxyUrl(
    `/api/Standards/${transformCommitteeName(committeeId)}`,
  );
  proxyUrl = addQueryParams(proxyUrl, params);
  return api.get(proxyUrl);
}
