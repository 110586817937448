import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";
import ReactDropzone from "react-dropzone";
import classNames from "classnames";

import DocumentList from "../document-list/List";

import DownloadZipButton from "../download-zip-button/DownloadZipButton";
import { documentUploadType } from "../../../utils/constants";
import {
  getDocuments,
  getModalDetails,
} from "../../../state/document/selectors";
import * as valuesSelectors from "../../../state/values/selectors";
import {
  cancelRemainingFilesToUpload,
  editDocumentData,
  resetModalState,
  setModalState,
  skipFileToUpload,
  uploadFile,
} from "../../../state/document/actions";
import DocumentsLinks from "../document-links/DocumentsLinks";
import DocumentSelector from "./DocumentSelector";
import FileInput from "../form/file-input/FileInput";
import types from "../modal-wrapper/contentTypes";
import DeleteModal from "../../pages/documents/components/modal-content/DeleteModal";
import ModalWrapper from "../modal-wrapper/ModalWrapper";
import CreateDocument from "../../pages/documents/components/modal-content/CreateDocument";
import WithPrivileges from "../../common/with-privileges/WithPrivileges";
import ReadonlyGuard from "../../common/readonly-guard/ReadonlyGuard";
import Loader from "../../common/loader/Loader";
import { isConsultant } from "../../../state/user/selectors";

export const addDocumentActionType = {
  upload: "upload",
  documents: "documents",
  linkToFile: "link",
};

class DocumentsTab extends Component {
  state = {
    addDocumentActionType: null,
    selectedDocuments: [],
  };

  removeDocumentFromList = (id) => {
    const { removeDocumentFromList, resetModalState } = this.props;
    removeDocumentFromList(id);
    resetModalState();
  };

  showModalToremoveDocumentFromList = (element) =>
    this.props.setModalState({
      isOpen: true,
      contentType: types.REMOVE_FILE_FROM_LIST,
      content: element,
    });

  onDocumentSelectorCancelClick = () =>
    this.setState({ addDocumentActionType: null });

  onHideLinkDocuments = () => this.setState({ addDocumentActionType: null });
  onSelectDocuments = (selectedDocuments) =>
    this.setState({ selectedDocuments });

  get showSetEmptyButton() {
    return (
      this.props.formDocuments &&
      this.props.formDocuments.length &&
      this.state.selectedDocuments.length === 0
    );
  }

  renderButtons = (areSmall, hideDefaultButtons) => {
    const { t, isLoading } = this.props;

    if (this.state.addDocumentActionType === addDocumentActionType.documents) {
      return (
        <div className="documents__buttons">
          <button
            className={classNames("button button--small", {
              "button--disabled": isLoading,
            })}
            onClick={this.onDocumentSelectorCancelClick}
          >
            {t("general.button.cancel")}
          </button>

          <button
            className={classNames("button button--small button--primary", {
              "button--disabled": isLoading,
            })}
            onClick={this.onAddSelectedDocumentsHandler}
          >
            {!this.showSetEmptyButton
              ? t("general.button.addSelected")
              : t("general.button.setEmptyList")}
          </button>
        </div>
      );
    }

    if (hideDefaultButtons) return null;

    return (
      <div className="documents__buttons">
        <WithPrivileges>
          <ReadonlyGuard>
            <FileInput
              className="button"
              onChangeHandler={(event) => {
                this.setState({
                  addDocumentActionType: addDocumentActionType.upload,
                });
                this.props.uploadSelectedDocuments({
                  documents: event.target.files,
                  uploadType: documentUploadType.file,
                  callback: this.props.addUploadedDocuments,
                  notifyOnUpdate: false,
                });
              }}
              isInline={true}
              isPrimaryButton={true}
              isSmallButton={!!areSmall}
              isDisabled={isLoading}
              buttonText={t("general.button.upload")}
              id="uploadDocuments"
              inputOptions={{
                multiple: true,
              }}
            />
            <button
              type="button"
              className={classNames("button button--primary", {
                "button--small": areSmall,
                "button--disabled": isLoading,
              })}
              onClick={() => {
                this.setState({
                  addDocumentActionType: addDocumentActionType.documents,
                });
              }}
            >
              {t("notifications.detail.new.selectFromDocuments")}
            </button>
          </ReadonlyGuard>
        </WithPrivileges>

        {/* <button
                className={classNames("button button--primary", {"button--small" : areSmall})}
                onClick={() => {
                    this.setState({addDocumentActionType: addDocumentActionType.linkToFile})
                }}
            >{t("notifications.detail.new.linkToFile")}</button> */}

        {!!this.props.showDownloadAllFiles && (
          <DownloadZipButton
            onClick={this.props.downloadAllFiles}
            isAwaiting={this.props.isDownloadingAllFiles}
            isDisabled={this.props.isLoading}
          />
        )}
      </div>
    );
  };

  renderUploadView = () => {
    const {
      t,
      modal,
      filters,
      uploadFile,
      editDocumentData,
      skipFile,
      cancelRemainingFilesToUpload,
      formDocuments,
    } = this.props;

    return (
      <>
        <div className="group u-align-center">
          <ReactDropzone
            onDrop={(acceptedFiles) => {
              this.setState({
                addDocumentActionType: addDocumentActionType.upload,
              });
              this.props.uploadSelectedDocuments({
                documents: acceptedFiles,
                uploadType: documentUploadType.file,
                callback: this.props.addUploadedDocuments,
                notifyOnUpdate: false,
              });
            }}
          >
            {({ getRootProps, getInputProps }) => (
              <div {...getRootProps({ className: "dropzone" })}>
                <input {...getInputProps()} />
                {!(formDocuments && formDocuments.length) && (
                  <span className="headline-4 u-text-bold">
                    {t("notifications.detail.new.noDocuments")}
                  </span>
                )}
                <span>{t("notifications.detail.new.dragAndDrop")}</span>
              </div>
            )}
          </ReactDropzone>
        </div>

        {!(formDocuments && formDocuments.length) && (
          <>
            <span className="dropzone-separator">OR</span>
            <div className="group group--btn u-align-center detail__btns">
              {this.renderButtons(false)}
            </div>

            <ModalWrapper
              contentType={modal.contentType}
              isOpen={modal.isOpen}
              requestCloseHandler={modal.requestCloseHandler}
              disableSlide
            >
              {
                <CreateDocument
                  document={modal.content}
                  filters={filters}
                  editDataHandler={editDocumentData}
                  submitHandler={uploadFile}
                  skipHandler={skipFile}
                  cancelRemainingHandler={cancelRemainingFilesToUpload}
                  hideNotificationOption={true}
                />
              }
            </ModalWrapper>
          </>
        )}
      </>
    );
  };

  renderRemoveDocumentFromListModal = () => (
    <DeleteModal
      id={this.props.modal.content.url}
      title={this.props.t("notifications.dialog.delete.title", {
        itemName: this.props.modal.content.filename,
      })}
      message={this.props.t("notifications.dialog.delete.message", {
        itemName: this.props.modal.content.filename,
      })}
      submitHandler={this.removeDocumentFromList}
      cancelHandler={this.props.resetModalState}
      deleteButtonText={this.props.t("general.button.remove")}
    />
  );

  renderListView = () => {
    const {
      formDocuments,
      isConsultant,
      removeFromLabel,
      manualSort,
      t,
      downloadDisabled,
    } = this.props;

    return (
      <>
        <div className="group group--first">
          <DocumentList
            onDocumentEdited={this.props.onDocumentEdited}
            onDocumentDeleted={this.props.removeDocumentFromList}
            additionalModals={{
              [types.REMOVE_FILE_FROM_LIST]:
                this.renderRemoveDocumentFromListModal,
            }}
            rowActions={{
              [t(removeFromLabel)]: this.showModalToremoveDocumentFromList,
            }}
            list={formDocuments}
            enableSelection={false}
            showActionBox={true}
            hideDueDate={!isConsultant}
            showExtendedActions={this.props.showExtendedActions}
            manualSort={manualSort}
            hideNotificationOption={true}
            downloadDisabled={downloadDisabled}
          />
        </div>
      </>
    );
  };

  onAddSelectedDocumentsHandler = () => {
    this.props.addSelectedDocuments(this.state.selectedDocuments);
    this.setState({ addDocumentActionType: null, selectedDocuments: [] });
  };

  renderView = () => {
    const { documents, formDocuments, uploadDocumentsCallback } = this.props;

    switch (this.state.addDocumentActionType) {
      case addDocumentActionType.documents:
        return (
          <DocumentSelector
            documents={documents}
            onSelectDocuments={this.onSelectDocuments}
            selectedDocuments={this.props.formDocuments}
          />
        );

      case addDocumentActionType.linkToFile:
        return (
          <DocumentsLinks
            hideLinkDocuments={this.onHideLinkDocuments}
            callback={uploadDocumentsCallback}
          />
        );
      default:
        return (
          <>
            {formDocuments && !!formDocuments.length && this.renderListView()}
            <WithPrivileges>
              <ReadonlyGuard>{this.renderUploadView()}</ReadonlyGuard>
            </WithPrivileges>
          </>
        );
    }
  };

  render() {
    const { t, formDocuments } = this.props;
    return (
      <div className="tab-content">
        <form className="detail__form">
          <div className="group group--small group__flex detail__group group--underlined">
            <span className="headline-4 u-text-bold" id="documents">
              {t("deadlines.details.documents")}
            </span>
            {this.renderButtons(true, !formDocuments || !formDocuments.length)}
          </div>

          {this.props.isLoading ? <Loader type="content" /> : this.renderView()}
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  modal: getModalDetails(state),
  filters: valuesSelectors.getDocumentValues(state),
  documents: getDocuments(state),
  isConsultant: isConsultant(state),
});

const mapDispatchToProps = {
  skipFile: skipFileToUpload,
  uploadFile,
  editDocumentData,
  setModalState,
  resetModalState,
  cancelRemainingFilesToUpload,
};

export default compose(
  withTranslation("common"),
  connect(mapStateToProps, mapDispatchToProps),
)(DocumentsTab);
