import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import expandIcon from "../../../assets/icons/expand_icon.svg";

const isHtmlString = (str) => {
  let htmlTagRegex = /<[a-z][\s\S]*>/i;
  return str && str.match(htmlTagRegex);
};

const MAX_HEIGHT = 70;

const FileDescription = ({ description, title, isDisabled, downloadUrl }) => {
  const [height, setHeight] = useState(0);
  const [isExpanded, setIsExpanded] = useState(false);
  const { t } = useTranslation("common");
  const ref = useRef(null);

  const showExpandButton = height >= MAX_HEIGHT;

  const descriptionClassName = `filedescription${
    isExpanded ? " filedescription--expanded" : ""
  }${showExpandButton ? " filedescription--isBig" : ""} text-small`;

  useEffect(() => {
    setHeight(ref.current.clientHeight);
  }, []);

  return (
    <>
      <div className="filename-header">
        {!isDisabled && (
          <a
            href={downloadUrl}
            className="filename text-small"
            aria-label={`${t("documents.list.content.openFile")} ${title}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {title}
          </a>
        )}
        {showExpandButton && (
          <button
            className="step__expand-btn"
            onClick={(e) => {
              e.preventDefault();
              setIsExpanded((prevState) => !prevState);
            }}
          >
            <img
              className={`step__expand-icon${isExpanded ? " open" : ""}`}
              src={expandIcon}
              alt=""
            />
          </button>
        )}
      </div>
      <div ref={ref} className="filedescription-wrapper">
        {isHtmlString(description) ? (
          <div
            dangerouslySetInnerHTML={{ __html: description }}
            className={descriptionClassName}
          ></div>
        ) : (
          <div className={descriptionClassName}>
            <span>{description}</span>
          </div>
        )}
      </div>
    </>
  );
};

export default FileDescription;
