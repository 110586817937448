import React from "react";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import classNames from "classnames";
import "./DateThumbnail.scss";
import { connect } from "react-redux";
import { getAppLocale } from "../../../state/user/selectors";

class DateThumbnail extends React.Component {
  state = {
    day: "",
    month: "",
    year: "",
    showYear: false,
  };

  componentDidMount() {
    this.props.date && this.setDate();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.date !== this.props.date) {
      this.setDate();
    }
  }

  setDate() {
    const { t, date } = this.props;
    const day = date.getDate();
    const month = t(`date.months.${date.getMonth()}`)
      .substring(0, 3)
      .toUpperCase();
    const year = date.getFullYear();
    const showYear = new Date().getFullYear() !== year;
    this.setState({ day, month, year, showYear });
  }

  getClassNames = () => {
    const { showYear } = this.state;
    const { type } = this.props;
    if (type === "big") {
      return classNames("date-thumbnail", "date-thumbnail--big", {
        "date-thumbnail--big--extended": showYear,
      });
    }

    return classNames("date-thumbnail", {
      "date-thumbnail--extended": showYear,
    });
  };

  render() {
    const { day, month, year, showYear } = this.state;

    return (
      <div className={this.getClassNames()}>
        <span className="date-thumbnail__number">{day}</span>
        <span className="date-thumbnail__month">{month}</span>
        {showYear && <span className="date-thumbnail__year">{year}</span>}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  locale: getAppLocale(state),
});

export default compose(
  withTranslation("common"),
  connect(mapStateToProps),
)(DateThumbnail);
