import history from "../../utils/history";

export const sortingType = {
  lineNumber_descending: "lineNumber_desc",
  lineNumber_ascending: "lineNumber_asc",
  clause_descending: "clause_desc",
  clause_ascending: "clause_asc",
  paragraph_descending: "paragraph_desc",
  paragraph_ascending: "paragraph_asc",
  type_descending: "type_desc",
  type_ascending: "type_asc",
  author_descending: "author_desc",
  author_ascending: "author_asc",
  date_descending: "date_desc",
  date_ascending: "date_asc",
};

function mapSortTypeToUrlParam(type) {
  switch (type) {
    case sortingType.lineNumber_descending:
      return "comment_lineNumber_dsc";
    case sortingType.lineNumber_ascending:
      return "comment_lineNumber_asc";
    case sortingType.clause_descending:
      return "comment_clause_dsc";
    case sortingType.clause_ascending:
      return "comment_clause_asc";
    case sortingType.paragraph_descending:
      return "comment_paragraph_dsc";
    case sortingType.paragraph_ascending:
      return "comment_paragraph_asc";
    case sortingType.type_descending:
      return "comment_type_dsc";
    case sortingType.type_ascending:
      return "comment_type_asc";
    case sortingType.author_descending:
      return "comment_author_dsc";
    case sortingType.author_ascending:
      return "comment_author_asc";
    case sortingType.date_descending:
      return "comment_date_dsc";
    case sortingType.date_ascending:
      return "comment_date_asc";
    default:
      return "comment_date_dsc";
  }
}

export const buildQueryParams = () => {
  let queryParams = {};

  const query = new URLSearchParams(history.location.search);

  if (query.has("line")) {
    queryParams.documentAction = query.get("line");
  }

  if (query.has("clause")) {
    queryParams.documentType = query.get("clause");
  }

  if (query.has("element")) {
    queryParams.committeeReference = query.get("element");
  }

  if (query.has("type")) {
    queryParams.documentTitle = query.get("type");
  }

  if (query.has("author")) {
    queryParams.documentTitle = query.get("author");
  }

  queryParams.sort = mapSortTypeToUrlParam(query.get("sortingType"));

  if (query.has("currentPage")) {
    queryParams.start = (query.get("currentPage") - 1) * 10 + 1;
  }

  return queryParams;
};
