import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import ActionBox from "../../../../common/action-box/ActionBox";
import Toggler from "../../../../common/toggler/Toggler";
import { withTranslation } from "react-i18next";
import WithPrivileges from "../../../../common/with-privileges/WithPrivileges";
import {
  isCommitteeAlreadyTurnedOff,
  isConsultant,
} from "../../../../../state/user/selectors";
import {
  pinNotification,
  unpinNotification,
  deleteNotification,
} from "../../../../../state/notifications/actions";
import {
  isNotificationInstantSent,
  isNotificationPinned,
} from "../../../../../state/notifications/selectors";
import { turnOnOffCommittee } from "../../../../../state/user/actions";

import NotificationDeleteModal from "../delete-modal/NotificationDeleteModal";
import { sendInstantEmail } from "../../../../../state/notifications/actions";
import { INSTANT_EMAIL_PARAM_TYPE } from "../../../../../state/notifications/reducer";
import { selectedNationalCommittee } from "../../../../../state/national-committees/selectors";
import ModalWrapper from "../../../../common/modal-wrapper/ModalWrapper";
import ConfirmationModal from "../../../../common/confirmation-modal/ConfirmationModal";

class NotificationCardMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showDeleteModal: false,
      showModal: false,
      modalContent: {
        title: "",
        message: "",
        submitLabel: "",
        submitHandler: () => {},
        cancelHandler: () => {},
      },
    };
  }

  get enableTurnOffCommittee() {
    const { committee, selectedNationalCommittee } = this.props;
    return committee !== selectedNationalCommittee;
  }

  turnOffNotificationOnCommitteeAction = () => {
    const { committee, turnOnOffCommittee, callbackAction } = this.props;
    turnOnOffCommittee({ committee, callbackAction });
  };

  modalCancelHandler = () => {
    this.setState({ showModal: false });
  };

  turnOffNotificationOnCommitteeClick = () => {
    const { committee, t } = this.props;

    this.setState({
      showModal: true,
      modalContent: {
        title: t("notifications.dialog.turnOffCommittee.title", {
          committee: committee,
        }),
        message: t("notifications.dialog.turnOffCommittee.message"),
        submitLabel: t("notifications.dialog.turnOffCommittee.submit"),
        submitHandler: this.turnOffNotificationOnCommitteeAction,
        cancelHandler: this.modalCancelHandler,
      },
    });
  };

  pinNotification = () => {
    const { pinNotification, notificationId } = this.props;
    pinNotification(notificationId);
  };

  unpinNotification = () => {
    const { unpinNotification, notificationId } = this.props;
    unpinNotification(notificationId);
  };

  deleteNotification = () => {
    this.setState({ showDeleteModal: true });
  };

  renderActiveCustomMenu = (action, index) => {
    return (
      <li className="list__element" key={`ca_${index}`}>
        <button
          className="button button--link actions-list__button"
          onClick={action.callback}
        >
          {action.label}
        </button>
      </li>
    );
  };

  renderDisabledCustomMenu = (action, index) => {
    return (
      <li className="list__element" key={`ca_${index}`}>
        <span className="button button--link actions-list__button actions-list__disabled">
          {action.label}
        </span>
      </li>
    );
  };

  get sendInstantEmailAction() {
    const { isInstantSent, t } = this.props;

    return {
      label: t("consultant.action.immediatelyNotify"),
      disabled: isInstantSent,
      callback: this.sendInstantEmailActionModal,
    };
  }

  sendInstantEmailActionHandler = () => {
    const { sendInstantEmail, notificationId, t } = this.props;
    sendInstantEmail({
      type: INSTANT_EMAIL_PARAM_TYPE.NOTIFICATION_ID,
      param: notificationId,
    });
  };

  sendInstantEmailActionModal = () => {
    const { t } = this.props;

    this.setState({
      showModal: true,
      modalContent: {
        title: t("notifications.dialog.sendInstantEmail.title"),
        message: t("notifications.dialog.sendInstantEmail.message"),
        submitLabel: t("notifications.dialog.sendInstantEmail.submit"),
        submitHandler: this.sendInstantEmailActionHandler,
        cancelHandler: this.modalCancelHandler,
      },
    });
  };

  get newCustomActions() {
    const { customActions, isConsultant } = this.props;
    const actionArray = customActions ? [...customActions] : [];

    if (isConsultant) {
      actionArray.push(this.sendInstantEmailAction);
    }

    return actionArray;
  }

  render() {
    const {
      committee,
      t,
      isPinned,
      isCommitteeAlreadyTurnedOff,
      hideDeleteAction,
    } = this.props;

    return (
      <div className="card__menu">
        <Toggler
          calculatePosition={true}
          clickHandler={() => this.setState({ actionBoxOpened: false })}
        >
          <ActionBox>
            <WithPrivileges>
              {isPinned ? (
                <li className="list__element">
                  <button
                    className="button button--link actions-list__button"
                    onClick={this.unpinNotification}
                  >
                    {t("card.unpin")}
                  </button>
                </li>
              ) : (
                <li className="list__element">
                  <button
                    className="button button--link actions-list__button"
                    onClick={this.pinNotification}
                  >
                    {t("card.pin")}
                  </button>
                </li>
              )}
            </WithPrivileges>
            {this.newCustomActions &&
              this.newCustomActions.map((action, index) =>
                action.disabled
                  ? this.renderDisabledCustomMenu(action, index)
                  : this.renderActiveCustomMenu(action, index),
              )}
            {!hideDeleteAction && (
              <WithPrivileges>
                <li className="list__element">
                  <button
                    className="button button--link actions-list__button"
                    onClick={this.deleteNotification}
                  >
                    {t("card.delete")}
                  </button>
                </li>
              </WithPrivileges>
            )}
            {/* This is blocking with committeeID of the notification which we decided to remove */}
            {this.enableTurnOffCommittee && (
              <li className="list__element">
                <button
                  className="button button--link actions-list__button"
                  onClick={this.turnOffNotificationOnCommitteeClick}
                >
                  {isCommitteeAlreadyTurnedOff
                    ? t("card.turnOn", { committee })
                    : t("card.turnOff", { committee })}
                </button>
              </li>
            )}
          </ActionBox>
        </Toggler>
        {this.state.showDeleteModal && (
          <NotificationDeleteModal
            showModal={true}
            onCancel={() => this.setState({ showDeleteModal: false })}
            onSubmit={this.props.deleteNotification}
            itemUri={this.props.notificationId}
          />
        )}
        {this.state.showModal && (
          <ModalWrapper
            isOpen={true}
            requestCloseHandler={this.state.modalContent.cancelHandler}
            disableSlide
          >
            <ConfirmationModal
              title={this.state.modalContent.title}
              message={this.state.modalContent.message}
              submitHandler={this.state.modalContent.submitHandler}
              cancelAction={this.state.modalContent.cancelHandler}
              submitLabel={this.state.modalContent.submitLabel}
            />
          </ModalWrapper>
        )}
      </div>
    );
  }
}

const mapDispatchToProps = {
  pinNotification,
  unpinNotification,
  deleteNotification,
  turnOnOffCommittee,
  sendInstantEmail,
};

const mapStateToProps = (state, props) => ({
  isCommitteeAlreadyTurnedOff: isCommitteeAlreadyTurnedOff(
    state,
    props.committee,
  ),
  isPinned: isNotificationPinned(state, props.notificationId),
  isInstantSent: isNotificationInstantSent(state, props.notificationId),
  isConsultant: isConsultant(state),
  selectedNationalCommittee: selectedNationalCommittee(state),
});

export default compose(
  withTranslation("common"),
  connect(mapStateToProps, mapDispatchToProps),
)(NotificationCardMenu);
