import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";

import Autosuggest from "react-autosuggest";
import classNames from "classnames";

import "./Search.scss";
import history from "../../../utils/history";
import OutsideClick from "../outside-click/OutsideClick";

import { recentSearches } from "../../../state/user/selectors";
import { updateRecentSearches } from "../../../state/user/actions";
import { getSearchSuggestions } from "../../../state/search/actions";
import { searchSuggestions } from "../../../state/search/selectors";

class Search extends Component {
  state = {
    value: "",
    opened: false,
    opening: false,
  };

  constructor(props) {
    super(props);
    this.openingTimeout = null;
  }

  componentDidMount() {
    this.updateSearchValue();
    this.unlisten = history.listen((location) => {
      if (location.pathname === "/search") {
        this.updateSearchValue();
      }
    });
  }

  componentWillUnmount() {
    this.unlisten();
  }

  updateSearchValue = () => {
    const query = new URLSearchParams(history.location.search);
    if (query.has("title") && this.state.value !== query.get("title")) {
      this.setState({ value: query.get("title") });
    }
  };

  getSuggestions = (value) => {
    const inputLength = value.length;
    return inputLength === 0
      ? this.props.recentSearches
      : inputLength >= 3
        ? this.props.searchSuggestions
        : [];
  };

  getSuggestionValue = (suggestion) => suggestion.name;

  renderSuggestion = (suggestion) => <div>{suggestion.name}</div>;

  onChange = (event, { newValue }) => {
    this.setState({
      value: newValue,
    });
  };

  // Autosuggest will call this function every time you need to update suggestions.
  // You already implemented this logic above, so just use it.
  onSuggestionsFetchRequested = ({ value }) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    //get suggestions from api
    inputLength &&
      inputLength >= 3 &&
      this.props.getSearchSuggestions(inputValue);
  };

  onSuggestionSelected = (event, { suggestion, suggestionValue }) => {
    clearTimeout(this.openingTimeout);
    this.setState(
      {
        value: suggestionValue,
      },
      history.push(`/search?title=${encodeURIComponent(suggestionValue)}`, {
        searching: true,
      }),
    );
    if (this.state.opened) {
      this.setState({ opened: false, opening: false });
      this.autosuggestInput.blur();
    }
    this.props.updateRecentSearches(suggestionValue);
  };

  handleButtonClick = (e) => {
    e.preventDefault();
    clearTimeout(this.openingTimeout);
    if (this.autosuggestInput === document.activeElement) {
      history.push(`/search?title=${encodeURIComponent(this.state.value)}`, {
        searching: true,
      });
      this.props.updateRecentSearches(this.state.value);
    }
    if (this.state.opened) {
      this.setState({ opened: false, opening: false });
      this.autosuggestInput.blur();
    } else {
      this.setState({ opening: true, opened: false });
      this.openingTimeout = setTimeout(() => {
        this.setState({ opened: true, opening: false });
        this.autosuggestInput.focus();
      }, 500);
    }
  };

  storeInputReference = (autosuggest) => {
    if (autosuggest !== null) {
      this.autosuggestInput = autosuggest.input;
    }
  };

  onClickOutside = () => {
    if (this.state.opened) {
      this.setState({ opened: false, opening: false });
    }
  };

  render() {
    const { value, opened, opening } = this.state;
    const { t } = this.props;

    // Autosuggest will pass through all these props to the input.
    const inputProps = {
      className: "search-box__input",
      placeholder: t("search.placeholder"),
      value,
      onChange: this.onChange,
    };

    return (
      <OutsideClick changeState={this.onClickOutside}>
        <div
          className={classNames(
            "search-box",
            { "search-box--opening": opening },
            { "search-box--opened": opened },
          )}
        >
          <form className="search-box__content" onSubmit={this.handleSubmit}>
            <button
              className="search-box__button"
              onClick={this.handleButtonClick}
            >
              <span className="icon icon-search" aria-hidden="true"></span>
              <span className="visuallyhidden">{t("search.openSearch")}</span>
            </button>
            <Autosuggest
              suggestions={this.getSuggestions(this.state.value)}
              onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
              onSuggestionSelected={this.onSuggestionSelected}
              getSuggestionValue={this.getSuggestionValue}
              renderSuggestion={this.renderSuggestion}
              inputProps={inputProps}
              alwaysRenderSuggestions={true}
              ref={this.storeInputReference}
            />
          </form>
        </div>
      </OutsideClick>
    );
  }
}

const mapDispatchToProps = {
  updateRecentSearches,
  getSearchSuggestions,
};
const mapStateToProps = (state) => ({
  recentSearches: recentSearches(state),
  searchSuggestions: searchSuggestions(state),
});

export default compose(
  withTranslation("common"),
  connect(mapStateToProps, mapDispatchToProps),
)(Search);
