import { handleActions } from "redux-actions";
import { clearNotifyToasterContent, setNotifyToasterContent } from "./actions";

export const NOTIFY_TOASTER_TYPE = {
  INFO: "info",
  ERROR: "error",
  WARNING: "warning",
  SUCCESS: "success",
};

const initialState = {
  title: "",
  message: "",
  type: "info",
};

const actionHandlers = {
  [setNotifyToasterContent]: (state, { payload }) => ({
    ...state,
    title: payload.title,
    message: payload.message,
    type: payload.type,
  }),
  [clearNotifyToasterContent]: (state) => ({
    ...state,
    title: "",
    message: "",
    type: "info",
  }),
};

export default handleActions(actionHandlers, initialState);
