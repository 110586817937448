import "core-js/es/";
import "core-js/features/url";
import "core-js/features/url-search-params";
import "core-js/features/string/repeat";
import "react-app-polyfill/ie11";
import React from "react";
import ReactDOM from "react-dom";
import { Router, Route, Switch } from "react-router-dom";
import "./index.css";
import "./styles/app.scss";
import Root from "./Root";
import App from "./App";
import Modal from "react-modal";
import history from "./utils/history";
import Auth from "./components/common/auth/Auth";
import SignIn from "./components/common/auth/SignIn";
import SilentRefresh from "./components/common/auth/SilentRefresh";
import CookieDeclaration from "./components/common/cookiebot/CookieDeclaration";
import GdprScreen from "./components/pages/gdpr/GdprScreen";

import CreateApplicationInsights from "./applicationiIsightsTracking";
import AnalyticsTracking from "./services/analytics-tracking/analytics-tracking";
import initializeHotjarTracking from "./services/hotjar-tracking/hotjar-tracking";

Modal.setAppElement("#root");

AnalyticsTracking.initialize();

initializeHotjarTracking();

if (process.env.REACT_APP_INSTRUMENTATION_KEY) {
  CreateApplicationInsights();
}
class TrackedRoot extends React.Component {
  render() {
    return (
      <Root>
        <Router history={history}>
          <Switch>
            <Route path="/silent" component={SilentRefresh} />
            <Route path="/signin" component={SignIn} />
            <Route path="/cookie-policy" component={CookieDeclaration} />
            <Route path="/gdpr" component={Auth(GdprScreen)} />
            <Route path="/" component={Auth(App)} />
          </Switch>
        </Router>
      </Root>
    );
  }
}

ReactDOM.render(<TrackedRoot />, document.getElementById("root"));
