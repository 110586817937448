export const findUserVote = (votes, deadlineUri, userId) => {
  if (votes == null && typeof votes == "undefined") {
    return;
  }

  const deadlineVotes = votes[deadlineUri];
  if (deadlineVotes == null && typeof deadlineVotes == "undefined") return;

  const userVote = deadlineVotes.find((d) => d.userID == userId);
  if (userVote == null && typeof userVote == "undefined") return;

  return userVote.vote.value;
};
