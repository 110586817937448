import React, { Component, Fragment } from "react";
import { InfiniteWaypointScroller } from "../../../../common/infinite-scroll-wrapper/InfiniteScrollWrapper";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import {
  hasMoreMeetingsToLoad,
  isProcessingMeetings,
} from "../../../../../state/meetings/selectors";
import Loader from "../../../../common/loader/Loader";
import MeetingCard from "../../components/meeting-card/MeetingCard";
import {
  initMeetings,
  pullMeetingsPageRequest,
  deleteMeeting,
} from "../../../../../state/meetings/actions";
import {
  DIALOG_TYPES,
  generateDialogLink,
} from "../../../../../utils/dynamicLink";
import history from "../../../../../utils/history";
import { isConsultant } from "../../../../../state/user/selectors";
import { selectedNationalCommittee } from "../../../../../state/national-committees/selectors";
import MeetingDeleteModal from "../../components/delete-modal/MeetingDeleteModal";
import MeetingTable from "./MeetingTable";
import { createNotificationFromMeeting } from "../../../../../state/notifications/actions";

class MeetingList extends Component {
  state = {
    isDeleteModalOpen: false,
    meetingToDelete: {},
    markedAsRemoved: "",
  };

  loadMoreMeetings = (page) => {
    this.props.pullMeetingsPageRequest({ page });
  };

  getCardActions = (data) => {
    const { t, isConsultant } = this.props;
    if (!isConsultant) {
      return [];
    }

    const editMeetingActionCallback = () => {
      const link = generateDialogLink(DIALOG_TYPES.EDIT_MEETING, data.uri);
      history.push(link);
    };

    const createNotificationFromMeeting = () => {
      this.props.createNotificationFromMeeting(data);
    };

    const deleteMeetingActionCallback = () => {
      this.setState({ isDeleteModalOpen: true, meetingToDelete: data });
    };

    return [
      {
        label: t("meetings.cards.editMeeting"),
        callback: editMeetingActionCallback,
      },
      {
        label: t("meetings.cards.createNotification"),
        callback: createNotificationFromMeeting,
      },
      {
        label: t("meetings.cards.deleteMeeting"),
        callback: deleteMeetingActionCallback,
      },
    ];
  };

  renderCard = (data, index) => {
    return (
      <MeetingCard
        key={index}
        meeting={data}
        markedAsRemoved={data.uri === this.state.markedAsRemoved}
        customActions={this.getCardActions(data)}
        callbackAction={initMeetings}
      />
    );
  };

  renderTable = (meetings) => {
    return (
      <MeetingTable
        useCheckbox={this.props.useCheckbox}
        onClickCheckbox={this.props.onClickCheckbox}
        meetings={meetings}
        selectedMeetings={this.props.selectedMeetings}
      />
    );
  };

  renderItems() {
    const {
      t,
      meetings,
      isProcessingMeetings,
      hasMoreMeetingsToLoad,
      useTable,
    } = this.props;

    if (
      !hasMoreMeetingsToLoad &&
      !isProcessingMeetings &&
      (!Object.keys(meetings) || !Object.keys(meetings).length)
    ) {
      return (
        <p key="nothing_to_show" className="headline-4">
          {t("general.content.nothingToShow")}
        </p>
      );
    }

    return Object.keys(meetings).map((month, index) => {
      return (
        <Fragment key={index}>
          <span className="layout__subtitle">{month}</span>
          {useTable
            ? this.renderTable(meetings[month])
            : meetings[month].map(this.renderCard)}
        </Fragment>
      );
    });
  }

  onCancelDelete = () => {
    this.setState({ isDeleteModalOpen: false, meetingToDelete: {} });
  };

  onSubmitDelete = (uri) => {
    this.props.deleteMeeting(uri);
    this.setState({
      isDeleteModalOpen: false,
      meetingToDelete: {},
      markedAsRemoved: uri,
    });
  };

  render() {
    return (
      <>
        <div className="layout__cards">
          {/* <InfiniteScrollWrapper */}
          <InfiniteWaypointScroller
            pageStart={0}
            initialLoad={false}
            loadMore={this.loadMoreMeetings}
            hasMore={this.props.hasMoreMeetingsToLoad}
            loader={<Loader key="infinity_scroll_loader" type="content" />}
            // isModal={this.props.isModal}
          >
            {this.renderItems()}
          </InfiniteWaypointScroller>
        </div>
        <MeetingDeleteModal
          showModal={this.state.isDeleteModalOpen}
          onCancel={this.onCancelDelete}
          onSubmit={this.onSubmitDelete}
          itemUri={this.state.meetingToDelete.uri}
          itemTitle={this.state.meetingToDelete.title}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  isProcessingMeetings: isProcessingMeetings(state),
  selectedNationalCommittee: selectedNationalCommittee(state),
  hasMoreMeetingsToLoad: hasMoreMeetingsToLoad(state),
  isConsultant: isConsultant(state),
});

const mapDispatchToProps = {
  initMeetings,
  deleteMeeting,
  pullMeetingsPageRequest,
  createNotificationFromMeeting,
};

export default compose(
  withTranslation("common"),
  connect(mapStateToProps, mapDispatchToProps),
)(MeetingList);
