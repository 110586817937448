import React, {
  useState,
  useEffect,
  useCallback,
  forwardRef,
  useImperativeHandle,
} from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import classNames from "classnames";
import Sidebar from "../../../deadlines/components/sidebar/Sidebar";
import {
  initDeadlines,
  resetDeadlinesFilters,
  setDeadlinesFilterValue,
} from "../../../../../state/deadlines/actions";
import {
  getDeadlines,
  getDeadlinesFilters,
  isFetchingDeadlines,
} from "../../../../../state/deadlines/selectors";
import DeadlineList from "../../../deadlines/pages/deadline-list/DeadlineList";
import { TIMEFRAME } from "../../../../../api/utils";
import {
  SlimTab,
  SlimTabsSwitcher,
} from "../../../../common/slim-tabs/SlimTabs";
import {
  SHOW_PAST_DEADLINES,
  SHOW_UPCOMING_DEADLINES,
} from "../../../deadlines/pages/deadline-list/DeadlineTab";

export const ReferenceDeadlineList = forwardRef((props, ref) => {
  const { t } = useTranslation("common");
  const dispatch = useDispatch();
  const history = useHistory();

  const deadlines = useSelector(getDeadlines);
  const filters = useSelector(getDeadlinesFilters);
  const isFetching = useSelector(isFetchingDeadlines);

  const [selectedDeadlines, setSelectedDeadlines] = useState([]);

  useEffect(() => {
    setSelectedDeadlines([...(props.selectedDeadlines ?? [])]);
    dispatch(resetDeadlinesFilters());
    dispatch(setDeadlinesFilterValue({ timeframe: TIMEFRAME.FUTURE }));
    dispatch(initDeadlines());
  }, [dispatch, props.selectedDeadlines]);

  useImperativeHandle(ref, () => ({
    onSelect() {
      onAddSelectedDeadlinesHandler();
    },
  }));

  const onClickCheckbox = (element, isChecked) => {
    if (isChecked) {
      setSelectedDeadlines((prevState) => [...prevState, element]);
    } else {
      setSelectedDeadlines((prevState) =>
        prevState.filter((el) => el.uri !== element.uri),
      );
    }
  };

  const showSetEmptyButton = useCallback(selectedDeadlines.length === 0, [
    selectedDeadlines,
  ]);

  const onLeave = useCallback(() => {
    if (props.isModal) {
      props.onSelect();
    } else {
      history.push(props.basePath);
    }
  }, [props, history]);

  const onDeadlineselectorCancelClick = () => {
    onLeave();
  };

  const onAddSelectedDeadlinesHandler = () => {
    props.onSetDeadline(selectedDeadlines);
    onLeave();
  };

  const renderButtons = () => (
    <div className="documents__buttons">
      <button
        className={classNames("button button--small", {
          "button--disabled": isFetching,
        })}
        onClick={onDeadlineselectorCancelClick}
      >
        {t("general.button.cancel")}
      </button>

      <button
        className={classNames("button button--small button--primary", {
          "button--disabled": isFetching,
        })}
        onClick={onAddSelectedDeadlinesHandler}
      >
        {!showSetEmptyButton
          ? t("general.button.addSelected")
          : t("references.deadline.setEmptyList")}
      </button>
    </div>
  );

  const handleDeadlinesUpcomingFilter = (selected) => {
    dispatch(
      setDeadlinesFilterValue({
        timeframe:
          selected === SHOW_PAST_DEADLINES ? TIMEFRAME.PAST : TIMEFRAME.FUTURE,
        month: null,
      }),
    );
    dispatch(initDeadlines());
  };

  return (
    <div className="tab-content references">
      <div className="detail__form">
        <div className="group group--small group__flex detail__group group--underlined">
          <span className="headline-4 u-text-bold" id="documents">
            {t("deadlines.header.name")}
          </span>
          {!props.isModal && renderButtons()}
        </div>
        <div className="layout__content layout__content--with-cards deadlines">
          <div className="layout__sidebar">
            <Sidebar disableSticky />
          </div>
          <div className="layout__content">
            <SlimTabsSwitcher
              onTab={handleDeadlinesUpcomingFilter}
              defaultTab={SHOW_UPCOMING_DEADLINES}
              selectedTab={
                filters.timeframe === TIMEFRAME.PAST
                  ? SHOW_PAST_DEADLINES
                  : SHOW_UPCOMING_DEADLINES
              }
              enabled={!isFetching}
            >
              <SlimTab
                label={t("deadlines.sidebar.showUpcoming")}
                name={SHOW_UPCOMING_DEADLINES}
              />
              <SlimTab
                label={t("deadlines.sidebar.showPast")}
                name={SHOW_PAST_DEADLINES}
              />
            </SlimTabsSwitcher>
          </div>
          <div className="layout__cards">
            <DeadlineList
              deadlines={deadlines}
              useTable
              useCheckbox
              selectedDeadlines={props.selectedDeadlines}
              isModal={props.isModal}
              onClickCheckbox={onClickCheckbox}
            />
          </div>
        </div>
      </div>
    </div>
  );
});
