import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { compose } from "redux";

class ActionButtons extends Component {
  render() {
    const {
      t,
      onCreateNewNotification,
      onCreateNewMeeting,
      onCreateNewDeadline,
    } = this.props;

    return (
      <div className="documents__buttons group group--first group--underlined u-align-right">
        <button
          className="button button--primary button--small"
          onClick={onCreateNewNotification}
        >
          {t("documents.event.newNotification")}
        </button>
        <button
          className="button button--primary button--small"
          onClick={onCreateNewDeadline}
        >
          {t("documents.event.newDeadline")}
        </button>
        <button
          className="button button--primary button--small"
          onClick={onCreateNewMeeting}
        >
          {t("documents.event.newMeeting")}
        </button>
      </div>
    );
  }
}

export default compose(withTranslation("common"))(ActionButtons);
