import { takeEvery, select, all, call, put } from "redux-saga/effects";
import i18next from "i18next";

import * as valueActions from "../state/values/actions";
import { selectedNationalCommittee } from "../state/national-committees/selectors";
import {
  getDeadlineCommittees,
  getDeadlineMonths,
  getDeadlineTypes,
  getDocumentActions,
  getDocumentCommittees,
  getDocumentTypes,
  getMeetingCommittees,
  getMeetingMonths,
  getStages,
  getCommentTypes,
} from "../api/resources/values";
import { mapValueModel } from "../api/mappers/values";
import { getMeetingFilters } from "../state/values/selectors";
import { getStore } from "../utils/state";

import { otherCommitteeId } from "../utils/constants";

function filterDocumentTypes(typesMap, supportedOrgs) {
  return typesMap
    .filter((t) => supportedOrgs.findIndex((st) => st === t.org) !== -1)
    .reduce((acc, cur) => {
      if (acc.findIndex((st) => st.value == cur.value) === -1) {
        return [...acc, cur];
      }
      return acc;
    }, []);
}

function* handleFetchingDocumentFilterValues() {
  try {
    const committeeId = yield select(selectedNationalCommittee);

    const [actions, types, committees] = yield all([
      call(getDocumentActions, committeeId),
      call(getDocumentTypes, committeeId),
      call(getDocumentCommittees, committeeId),
    ]);

    const actionsMap = mapValueModel(actions.data);
    const typesMap = mapValueModel(types.data);
    let committeesMap = mapValueModel(committees.data);
    const supportedOrgs = committeesMap
      .map((c) => c.value.split("/"))
      .reduce((acc, cur) => [...acc, ...cur], []);

    const avaiableTypes = filterDocumentTypes(typesMap, supportedOrgs);

    committeesMap.splice(1, 0, {
      id: -2,
      value: otherCommitteeId,
      label: i18next.t("general.defaults.otherCommittee", { ns: "common" }),
    });

    if (actions && types && committees) {
      yield put(
        valueActions.fetchingDocumentFilterValuesSucceeded({
          actions: actionsMap,
          types: typesMap,
          committees: committeesMap,
          avaiableTypes: avaiableTypes,
        }),
      );
    } else {
      yield put(
        valueActions.fetchingDocumentFilterValuesFailed(
          "Failed to fetch document filter values",
        ),
      );
    }
  } catch (error) {
    console.error(`fetching document filter values failed - ${error}`);
  }
}

function* handleFetchingDeadlineFilterValues() {
  try {
    const committeeId = yield select(selectedNationalCommittee);

    const [months, types, committees] = yield all([
      call(getDeadlineMonths, committeeId),
      call(getDeadlineTypes, committeeId),
      call(getDeadlineCommittees, committeeId),
    ]);

    if (months && types && committees) {
      const locale = getStore().getState().user.data.kundeportal.locale;

      const translatedMonths = months.data.map((month) => {
        const dateObj = new Date(month.value);

        let localeDateStr = dateObj.toLocaleDateString(locale, {
          month: "long",
          year: "numeric",
        });
        localeDateStr =
          localeDateStr.charAt(0).toUpperCase() + localeDateStr.slice(1);

        return {
          label: localeDateStr,
          value: month.value,
          date: dateObj,
        };
      });

      yield put(
        valueActions.fetchingDeadlineFilterValuesSucceeded({
          months: translatedMonths,
          types: types.data,
          committees: committees.data,
        }),
      );
    } else {
      yield put(
        valueActions.fetchingDeadlineFilterValuesFailed(
          "Failed to fetch deadline filter values",
        ),
      );
    }
  } catch (error) {
    console.error(`fetching deadline filter values failed - ${error}`);
  }
}

function* handleFetchingMeetingFilterValues() {
  try {
    const meetingFilters = yield select(getMeetingFilters);

    if (meetingFilters) {
      return;
    }
    const committeeId = yield select(selectedNationalCommittee);

    const [committees, months] = yield all([
      call(getMeetingCommittees, committeeId),
      call(getMeetingMonths, committeeId),
    ]);

    if (committees && months) {
      const locale = getStore().getState().user.data.kundeportal.locale;
      const translatedMonths = months.data.map((month) => {
        const dateObj = new Date(month.value);
        let newDateStr = dateObj.toLocaleDateString(locale, {
          month: "long",
          year: "numeric",
        });
        newDateStr = newDateStr.charAt(0).toUpperCase() + newDateStr.slice(1);
        return {
          label: newDateStr,
          value: newDateStr,
          date: dateObj,
        };
      });

      yield put(
        valueActions.fetchingMeetingFilterValuesSucceeded({
          committees: committees.data,
          months: translatedMonths,
        }),
      );
    } else {
      yield put(
        valueActions.fetchingMeetingFilterValuesFailed(
          "Failed to fetch meeting filter values",
        ),
      );
    }
  } catch (error) {
    console.error(`fetching meeting filter values failed - ${error}`);
  }
}

function* handleFetchingStageCodes() {
  try {
    const stages = yield call(getStages);

    if (stages.status === 200) {
      yield put(valueActions.getStageCodesSucceeded(stages.data));
    } else {
      yield put(valueActions.getStageCodesFailed());
    }
  } catch (error) {
    console.error(`fetching stage code values failed - ${error}`);
  }
}

function* handleFetchingCommentTypes() {
  try {
    const types = yield call(getCommentTypes);

    if (types.status === 200) {
      yield put(valueActions.getCommentTypesSucceeded(types.data));
    } else {
      yield put(valueActions.getCommentTypesFailed());
    }
  } catch (error) {
    console.error(`fetching comment types failed - ${error}`);
  }
}

function* handleFetchAllValues() {
  yield call(handleFetchingDocumentFilterValues);
  yield call(handleFetchingCommentTypes);
  yield call(handleFetchingStageCodes);
  yield call(handleFetchingMeetingFilterValues);
  yield call(handleFetchingDeadlineFilterValues);

  yield put(valueActions.fetchAllValuesFinished());
}

export default function* valuesSaga() {
  yield takeEvery(valueActions.fetchAllValues, handleFetchAllValues);
}
