import { combineActions, handleActions } from "redux-actions";
import * as actions from "./actions";
import {
  normalizeMeeting,
  normalizeMeetingsPage,
  normalizeMeetingGeneralInfo,
  updateMeetingOnList,
} from "./normalizers";

import {
  updateEditedDocument,
  normalizeDocument,
} from "../document/normalizers";
import { normalizeObjectOfReferences } from "../references/normalizers";
import { TIMEFRAME } from "../../api/utils";

const initialForm = {
  committee: "",
  title: "",
  fromDate: "",
  toDate: "",
  locationShort: "",
  locationLong: "",
  referencesRawResponsePerType: {
    document: [],
    meeting: [],
    deadline: [],
  },
  referencesPerType: {
    document: [],
    meeting: [],
    deadline: [],
  },
  references: [],
  participants: [],
  isParticipationEnabled: false,
  notifyOnUpdate: true,
  notifyImmediately: false,
};

const initialState = {
  isProcessingRequest: false,
  isProcessingMeetings: false,
  isProcessedMeetings: false,
  isProcessingDocuments: false,
  pagination: {
    pagesLoaded: 0,
    pagesToLoad: 0,
    hasMoreToLoad: true,
  },
  data: {},
  meeting: null,
  form: initialForm,
  filters: {
    committee: null,
    month: null,
    timeframe: TIMEFRAME.FUTURE,
  },
};

const actionHandlers = {
  [combineActions(
    actions.fetchMeeting,
    actions.updateMeeting,
    actions.deleteMeeting,
    actions.createMeeting,
  )]: (state) => ({
    ...state,
    isProcessingRequest: true,
  }),
  [combineActions(
    actions.fetchMeetingReferences,
    actions.setMeetingDocuments,
    actions.addMeetingDocuments,
  )]: (state) => ({
    ...state,
    isProcessingDocuments: true,
  }),
  [combineActions(actions.initMeetings)]: (state) => ({
    ...state,
    isProcessingRequest: true,
    isProcessingMeetings: true,
    isProcessedMeetings: false,
    pagination: {
      pagesLoaded: 0,
      pagesToLoad: 0,
      hasMoreToLoad: true,
    },
    data: {},
  }),
  [actions.pullMeetingsPageRequest]: (state) => ({
    ...state,
    isProcessingMeetings: true,
    isProcessedMeetings: false,
    isProcessingRequest: true,
  }),
  [actions.pullMeetingsPageSucceeded]: (state, { payload }) => ({
    ...state,
    isProcessingMeetings: false,
    isProcessedMeetings: true,
    isProcessingRequest: false,
    data: { ...state.data, ...normalizeMeetingsPage(payload) },
    pagination: {
      pagesLoaded: payload.pagination.pageLoaded + 1,
      pagesToLoad: payload.pagination.pagesToLoad,
      hasMoreToLoad: payload.pagination.hasMoreToLoad,
    },
  }),
  [actions.fetchMeetingSucceeded]: (state, { payload }) => ({
    ...state,
    isProcessingRequest: false,
    meeting: normalizeMeeting(payload),
  }),
  [actions.createMeetingSucceeded]: (state, { payload }) => {
    const newMeeting = normalizeMeeting(payload);
    let newState = {
      ...state,
      isProcessingRequest: false,
      meeting: normalizeMeeting(payload),
    };

    if (
      state.filters.timeframe === TIMEFRAME.PAST &&
      newMeeting.fromDate < new Date()
    ) {
      newState.data = { ...state.data, [newMeeting.uri]: newMeeting };
    }

    if (
      state.filters.timeframe === TIMEFRAME.FUTURE &&
      newMeeting.fromDate > new Date()
    ) {
      newState.data = { ...state.data, [newMeeting.uri]: newMeeting };
    }

    return newState;
  },
  [actions.fetchMeetingReferencesSucceeded]: (state, { payload }) => ({
    ...state,
    isProcessingDocuments: false,
    meeting: {
      ...state.meeting,
      referencesPerType: normalizeObjectOfReferences(payload),
    },
  }),
  [actions.fetchCurrentMeetingDocuments]: (state) => ({
    ...state,
    isProcessingDocuments: true,
  }),
  [actions.fetchCurrentMeetingDocumentsSucceeded]: (state, { payload }) => ({
    ...state,
    isProcessingDocuments: false,
    meeting: {
      ...state.meeting,
      referencesPerType: normalizeObjectOfReferences(payload),
    },
  }),
  [actions.fetchCurrentMeetingDocumentsFailed]: (state) => ({
    ...state,
    isProcessingDocuments: false,
  }),
  [actions.fetchMeetingReferencesFailed]: (state) => ({
    ...state,
    isProcessingDocuments: false,
    meeting: {
      ...state.meeting,
      references: [],
    },
  }),
  [actions.clearMeeting]: (state) => ({
    ...state,
    meeting: null,
  }),
  [actions.setMeetingsFilterValue]: (state, { payload }) => ({
    ...state,
    filters: {
      ...state.filters,
      ...payload,
    },
  }),
  [actions.resetMeetingsFilters]: (state) => ({
    ...state,
    filters: {
      committee: null,
      month: null,
      timeframe: TIMEFRAME.FUTURE,
    },
  }),
  [combineActions(
    actions.updateMeetingFailed,
    actions.deleteMeetingFailed,
    actions.createMeetingFailed,
  )]: (state) => ({
    ...state,
    isFetching: false,
  }),
  [actions.updateMeetingSucceeded]: (state, { payload }) => ({
    ...state,
    isFetching: false,
    data: updateMeetingOnList(state.data, payload),
  }),
  [actions.deleteMeetingSucceeded]: (state, { payload }) => {
    const { [payload]: toRemove, ...newdata } = state.data;
    return {
      ...state,
      isFetching: false,
      data: newdata,
    };
  },
  [actions.setFormSelectedMeetingDocuments]: (state, { payload }) => ({
    ...state,
    form: {
      ...state.form,
      referencesPerType: {
        ...state.form.referencesPerType,
        document: [...payload],
      },
    },
  }),
  [actions.setFormSelectedMeetingReferencesDeadlines]: (
    state,
    { payload },
  ) => ({
    ...state,
    form: {
      ...state.form,
      referencesPerType: {
        ...state.form.referencesPerType,
        deadline: [...payload],
      },
    },
  }),
  [actions.addFormSelectedMeetingDocuments]: (state, { payload }) => ({
    ...state,
    form: {
      ...state.form,
      references: [...state.form.references, normalizeDocument(payload)],
    },
  }),
  [actions.addGeneralMeetingInformation]: (state, { payload }) => ({
    ...state,
    form: normalizeMeetingGeneralInfo(state.form, payload),
  }),

  [actions.initMeetingFormWithDocuments]: (state, { payload }) => ({
    ...state,
    form: {
      ...state.form,
      referencesPerType: normalizeObjectOfReferences(payload),
    },
  }),

  [actions.clearMeetingForm]: (state) => ({
    ...state,
    form: initialForm,
  }),
  [actions.fetchUpdatedMeetingDocumentSucceeded]: (state, { payload }) => ({
    ...state,
    form: {
      ...state.form,
      references: updateEditedDocument(state.form.references, payload),
    },
  }),
  [actions.updateMeetingDocumentInfo]: (state, { payload }) => ({
    ...state,
    meeting: {
      ...state.meeting,
      references: state.meeting.references.map((d) =>
        d.url !== payload.url ? d : payload,
      ),
    },
  }),
  [actions.addParticipantSucceeded]: (state, { payload }) => ({
    ...state,
    meeting: {
      ...state.meeting,
      participants: [...state.meeting.participants, payload],
    },
  }),
  [actions.updateParticipantSucceeded]: (state, { payload }) => ({
    ...state,
    meeting: {
      ...state.meeting,
      participants: state.meeting.participants.map((p) =>
        p.id === payload.id ? payload : p,
      ),
    },
  }),
};

export default handleActions(actionHandlers, initialState);
