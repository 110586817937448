import React, { useState } from "react";
import className from "classnames";
import { useTranslation } from "react-i18next";

import Avatar from "../../../../common/avatar/Avatar";
import WithPrivileges from "../../../../common/with-privileges/WithPrivileges";
import { formatDateTime } from "../../../../../utils/transform";
import "./Member.scss";

import DeactivationConfirmationModal from "./DeactivationConfirmationModal";

export default ({
  name,
  photo,
  email,
  work,
  linkedIn,
  phone,
  dslist,
  isConsultant,
  isChairman,
  isProfessional,
  memberSince,
  lastActivityDate,
  isActive,
  changeActiveState,
}) => {
  const { t } = useTranslation("common");
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [waitingForState, setWaitingForState] = useState(isActive);

  return (
    <div className="panel panel--border member">
      <div className="panel__group member__main-info">
        <Avatar profileImage={photo} name={name} />
        <div className="member__contact">
          <h2 className="member__name">
            {name}
            {isChairman ? ` (${t("member.chairman")})` : null}
          </h2>
          <span className="member__company">{work}</span>
          <a href={`mailto:${email}`}>{email}</a>
          <a href={`tel:${phone}`}>{phone}</a>
        </div>
        {linkedIn ? (
          <a
            href={linkedIn}
            target="_blank"
            rel="noopener noreferrer"
            title="Linkedin profile"
            className="member__linkedin"
          >
            <span
              className="icon icon-linkedin icon--large"
              aria-hidden="true"
            ></span>
          </a>
        ) : null}
      </div>
      <span className="panel__separator"></span>
      <div className="member__additional-info">
        <div className="member__additional-info-left">
          <div className="panel__group--small">
            {isConsultant ? (
              <div className="member__role">{t("member.consultant")}</div>
            ) : isProfessional ? (
              <div className="member__role">{t("member.professional")}</div>
            ) : (
              <div className="member__ds-list">
                {dslist && dslist.length ? dslist.join(", ") : ""}
              </div>
            )}
            <WithPrivileges>
              {lastActivityDate && (
                <div className="member__last-activity">
                  {t("member.activity.label", {
                    date: formatDateTime(new Date(lastActivityDate)),
                  })}
                </div>
              )}
            </WithPrivileges>
          </div>
        </div>
        {!isConsultant && (
          <WithPrivileges>
            <div
              className="member__additional-info-right"
              onClick={() => {
                setShowConfirmation(true);
              }}
            >
              <button
                className={className(
                  "member__activity-state button button--link button--no-border",
                  {
                    "member__activity-state-active": isActive,
                    "member__activity-state-inactive": !isActive,
                    "member__activity-state-wait": isActive !== waitingForState,
                    "button--awaiting": isActive !== waitingForState,
                  },
                )}
              >
                {isActive
                  ? t("member.activity.isActive")
                  : t("member.activity.isInactive")}
              </button>
            </div>
            <DeactivationConfirmationModal
              showModal={showConfirmation}
              name={name}
              type={isActive ? "deactivation" : "activation"}
              onSubmit={() => {
                setWaitingForState(!isActive);
                changeActiveState();
                setShowConfirmation(false);
              }}
              onCancel={() => {
                setShowConfirmation(false);
              }}
            />
          </WithPrivileges>
        )}
      </div>
    </div>
  );
};
