import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";

import Table from "./components/table/Table.js";
import AboutConfirmationModal from "./components/about-confirmation-modal/AboutConfirmationModal.js";

import {
  getCommitteeTitleProvider,
  getSelectedNationalCommitteeTitle,
  selectedNationalCommittee,
} from "../../../state/national-committees/selectors";
import { isConsultant } from "./../../../state/user/selectors";

import "./About.scss";
import {
  getInfoAboutCommittee,
  addInfoAboutCommittee,
  updateCommitteeDescription,
  AddDocumentToInfoAboutCommittee,
  AddSpecialDocumentToInfoAboutCommittee,
  AddFileToDocument,
  AddFileToSpecialDocument,
  DeleteDocumentFromInfoAboutCommittee,
  DeleteSpecialDocumentFromInfoAboutCommittee,
  UpdateDocumentInInfoAboutCommittee,
  UpdateSpecialDocumentToInfoAboutCommittee,
} from "../../../api/resources/about.js";
import ModalWrapper from "../../common/modal-wrapper/ModalWrapper.js";

const About = ({ committeeId, committeeTitle, isConsultant }) => {
  const { t } = useTranslation("common");
  const [isEditing, setIsEditing] = useState(false);
  const [isEditingGeneral, setIsEditingGeneral] = useState(false);
  const [committeeInfo, setCommitteeInfo] = useState({});
  const [description, setDescription] = useState("");
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const handleEditorChange = (e) => {
    setDescription(e.target.value);
  };

  const onSave = async () => {
    await updateCommitteeDescription(committeeId, description);
    setIsEditing(false);
    setIsEditingGeneral(false);
  };

  const handleConfirmation = () => {
    setShowConfirmationModal(false);
    setIsEditingGeneral(true);
  };

  const getCommitteeInfo = async (id) => {
    try {
      const res = await getInfoAboutCommittee(id);
      setCommitteeInfo(res.data);
      setDescription(res.data.description);
      return res.data;
    } catch (err) {
      addCommitteeInfo(id);
    }
  };

  const addCommitteeInfo = async (id) => {
    try {
      const res = await addInfoAboutCommittee(id);
      setCommitteeInfo(res.data);
    } catch (err) {
      console.error(err);
    }
  };

  const uploadFile = async ({ id, file, isSpecial }) => {
    try {
      const data = new FormData();
      data.append("file", file);
      const res = isSpecial
        ? await AddFileToSpecialDocument(id, data)
        : await AddFileToDocument(id, data);
      return res;
    } catch (error) {
      console.error(`upload file failed - ${error}`);
    }

    return null;
  };

  const handleAddDocument = async ({
    title,
    description,
    file,
    isSpecial,
    cancelHandler,
  }) => {
    const res = isSpecial
      ? await AddSpecialDocumentToInfoAboutCommittee(committeeId, {
          title,
          description,
        })
      : await AddDocumentToInfoAboutCommittee(committeeId, {
          title,
          description,
        });
    if (res.status === 200) {
      const id = res.data;

      await uploadFile({ id, file, isSpecial });
      cancelHandler();
      getCommitteeInfo(committeeId);
    }
  };

  const handleDeleteDocument = async (id, isSpecial) => {
    if (isSpecial) {
      await DeleteSpecialDocumentFromInfoAboutCommittee(committeeId, { id });
    } else {
      await DeleteDocumentFromInfoAboutCommittee(committeeId, { id });
    }
    getCommitteeInfo(committeeId);
  };

  const handleUpdateDocument = async ({
    title,
    description,
    id,
    isSpecial,
  }) => {
    if (isSpecial) {
      await UpdateSpecialDocumentToInfoAboutCommittee(committeeId, {
        title,
        description,
        id,
      });
    } else {
      await UpdateDocumentInInfoAboutCommittee(committeeId, {
        title,
        description,
        id,
      });
    }
    getCommitteeInfo(committeeId);
  };

  useEffect(() => {
    if (committeeId) getCommitteeInfo(committeeId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [committeeId]);

  useEffect(() => {
    if (!description && committeeInfo.description) {
      setDescription(committeeInfo.description);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [committeeInfo]);

  return (
    <div className="about-page">
      <header className="layout__title horizontal">
        <h2 className="headline-2">
          {t("about.header.name", {
            name: committeeTitle,
            number: committeeId,
          })}
        </h2>

        {isConsultant && (
          <>
            {!isEditing ? (
              <button
                onClick={() => setIsEditing(true)}
                className="button button--secondary button--small"
              >
                {t("general.button.edit")}
              </button>
            ) : (
              <button
                onClick={onSave}
                className="button button--primary button--small"
              >
                {t("general.button.save")}
              </button>
            )}
          </>
        )}
      </header>

      <div className="layout__content panel panel--border about-docs-panel">
        <div className="layout__content editable-full-width">
          {isEditing ? (
            <>
              <textarea
                type="text"
                name="committee-description"
                id="committee-description"
                className="textarea"
                value={description}
                onChange={handleEditorChange}
              />
            </>
          ) : (
            <>
              <p>{description}</p>
            </>
          )}
        </div>
      </div>
      <div className="layout__title general-documents-header">
        <h3 className="headline-4">{t("about.documents.standardization")}</h3>
        {isEditing && !isEditingGeneral && (
          <button
            onClick={() => setShowConfirmationModal(true)}
            className="button button--secondary button--small"
          >
            {t("general.button.edit")}
          </button>
        )}
      </div>
      <Table
        list={committeeInfo.documents || []}
        isEditing={isEditingGeneral}
        committeeName={committeeId}
        onDocumentAdd={handleAddDocument}
        onDocumentDelete={handleDeleteDocument}
        onDocumentUpdate={handleUpdateDocument}
      />
      <div className="layout__title">
        <h3 className="headline-4">
          {t("about.documents.specificFor", { number: committeeId })}
        </h3>
      </div>
      <Table
        list={committeeInfo.specialDocuments || []}
        isEditing={isEditing}
        committeeName={committeeId}
        onDocumentAdd={handleAddDocument}
        onDocumentDelete={handleDeleteDocument}
        onDocumentUpdate={handleUpdateDocument}
        isSpecial
      />
      {showConfirmationModal && (
        <ModalWrapper
          isOpen={true}
          requestCloseHandler={() => setShowConfirmationModal(false)}
          disableSlide
        >
          <AboutConfirmationModal
            cancelHandler={() => setShowConfirmationModal(false)}
            submitHandler={handleConfirmation}
            confirmText="Continue"
          />
        </ModalWrapper>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  committeeId: selectedNationalCommittee(state),
  committeeTitle: getSelectedNationalCommitteeTitle(state),
  committeeTitleProvider: getCommitteeTitleProvider(state),
  isConsultant: isConsultant(state),
});

export default connect(mapStateToProps, null)(About);
