import { api } from "./../api";
import { addQueryParams, getProxyUrl } from "../utils";
import { transformCommitteeName } from "../../utils/transform";
import { mapDocumentToPatchQuery } from "../mappers/document";

export function getDocumentsUrl(committeeId, params) {
  let proxyUrl = getProxyUrl(
    `/Documents/documents/${transformCommitteeName(committeeId)}`,
  );
  proxyUrl = addQueryParams(proxyUrl, params);
  return api.get(proxyUrl);
}

export function getDocumentUrl(uri) {
  let proxyUrl = getProxyUrl(`/Documents/document/${uri}`);
  return api.get(proxyUrl);
}

export function getDocumentSafeUrl(uri) {
  let getPromise = getDocumentUrl(uri);
  return new Promise((resolve) => {
    getPromise.then(resolve).catch((error) => {
      console.error(`getDocumentSafe error: '${uri}': ${error}`);
      resolve(null);
    });
  });
}

export function deleteDocumentUrl(documentUrl) {
  const proxyUrl = getProxyUrl(`/Documents/document/${encodeURI(documentUrl)}`);
  return api.delete(proxyUrl);
}

export function updateDocumentUrl(document) {
  let proxyUrl = getProxyUrl(`/Documents/document/${encodeURI(document.url)}`);
  proxyUrl = addQueryParams(proxyUrl, mapDocumentToPatchQuery(document));
  return api.patch(proxyUrl, null);
}

export function setDocumentMetadataUrl(committeeId, params, metadata) {
  let proxyUrl = getProxyUrl(
    `/Documents/document/${transformCommitteeName(committeeId)}`,
  );
  proxyUrl = addQueryParams(proxyUrl, params);
  return api.post(proxyUrl, metadata);
}

export function uploadDocumentUrl(data) {
  const proxyUrl = getProxyUrl("/Document");
  // return api.put(proxyUrl, {data});
  return api.post(proxyUrl, data);
}

export function downloadZipUrl(uris) {
  return api.post(
    "/api/files/zipFiles",
    { uris },
    { responseType: "arraybuffer" },
  );
}

export function getRelatedReferencesUrl(documentId) {
  const proxyUrl = getProxyUrl(`/References/${documentId}`);

  return api.get(proxyUrl);
}
