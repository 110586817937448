import { api } from "../api";
import { addQueryParams, getProxyUrl } from "../utils";

export function getVotes(deadlineId) {
  const proxyUrl = getProxyUrl(`/Votes/votes/${deadlineId}`);
  return api.get(proxyUrl);
}

export function getVotesStatistics(deadlineId) {
  const proxyUrl = getProxyUrl(`/Votes/statistics/${deadlineId}`);
  return api.get(proxyUrl);
}

export function createVote(data) {
  const proxyUrl = getProxyUrl("/Votes/vote");
  return api.post(proxyUrl, data);
}

export function updateVote(vote) {
  let proxyUrl = getProxyUrl(`/Votes/vote/${vote.uri}`);
  proxyUrl = addQueryParams(proxyUrl, { vote: vote.vote });
  return api.patch(proxyUrl, null);
}

export function removeVote(voteUri) {
  let proxyUrl = getProxyUrl(`/Votes/vote/${voteUri}`);
  return api.delete(proxyUrl, null);
}

export function getVoteOverride(deadlineId) {
  return api.get(
    `/api/votes/official_vote?deadline=${encodeURIComponent(deadlineId)}`,
  );
}

export function updateVoteOverride(deadlineId, vote) {
  return api.post(
    `/api/votes/official_vote?deadline=${encodeURIComponent(deadlineId)}`,
    `"${vote}"`,
  );
}
