import { combineActions, handleActions } from "redux-actions";
import * as actions from "./actions";
import * as commentsActions from "../comments/actions";

import {
  normalizeDeadline,
  normalizeDeadlines,
  emptyDeadline,
  updateDeadlineOnList,
  addCommnetToActiveDeadlineOnList,
} from "./normalizer";

import {
  normalizeDocument,
  normalizeListOfDocuments,
  removeReferenceById,
  updateEditedDocument,
} from "../document/normalizers";
import { normalizeObjectOfReferences } from "../references/normalizers";
import { TIMEFRAME } from "../../api/utils";

const initialForm = {
  committee: "",
  title: "",
  description: "",
  date: "",
  action: "",
  stage: "",
  enableVoting: true,
  enableCommenting: true,
  closedForComments: false,
  references: [],
  notifyOnUpdate: true,
  notifyImmediately: false,
};

const initialState = {
  isProcessingRequest: false,
  isDeletingDeadline: false,
  isProcessingReferences: false,
  pagination: {
    pagesLoaded: 0,
    pagesToLoad: 0,
    hasMoreToLoad: true,
  },
  data: {},
  sortingType: null,
  selectedUri: null,
  form: initialForm,
  filters: {
    title: "",
    committee: null,
    month: null,
    type: null,
    stage: null,
    timeframe: TIMEFRAME.FUTURE,
  },
};

const actionHandlers = {
  [actions.fetchDeadline]: (state) => ({
    ...state,
    isProcessingRequest: true,
    deadline: emptyDeadline(),
  }),
  [combineActions(actions.initDeadlines, actions.initAggDeadlines)]: (
    state,
  ) => ({
    ...state,
    isProcessingRequest: true,
    pagination: {
      pagesLoaded: 0,
      pagesToLoad: 0,
      hasMoreToLoad: true,
    },
    data: {},
  }),
  [actions.updateDeadline]: (state) => ({
    ...state,
    isProcessingRequest: true,
  }),
  [combineActions(
    actions.fetchDeadlineReferences,
    actions.setDeadlineReferences,
    actions.addDeadlineReferences,
  )]: (state) => ({
    ...state,
    isProcessingReferences: true,
  }),
  [combineActions(actions.deleteDeadline)]: (state) => ({
    ...state,
    isDeletingDeadline: true,
  }),
  [actions.fetchDeadlineSucceeded]: (state, { payload }) => ({
    ...state,
    isProcessingRequest: false,
    deadline: normalizeDeadline(payload),
  }),
  [actions.createDeadlineSucceeded]: (state, { payload }) => {
    const newDeadline = normalizeDeadline(payload);
    let newState = {
      ...state,
      isProcessingRequest: false,
      deadline: newDeadline,
    };

    if (state.filters.timeframe === TIMEFRAME.FUTURE) {
      newState.data = { ...state.data, [newDeadline.uri]: newDeadline };
    }

    return newState;
  },
  [actions.fetchDeadlineFailed]: (state, { payload }) => ({
    ...state,
    isProcessingRequest: false,
  }),
  [actions.fetchDeadlineReferencesSucceeded]: (state, { payload }) => {
    // TODO: have 3 different types of references
    const referencesPerType = normalizeObjectOfReferences(payload);

    return {
      ...state,
      isProcessingReferences: false,
      deadline: {
        ...state.deadline,
        references: referencesPerType.document,
        referencesPerType,
      },
    };
  },
  [actions.fetchDeadlineReferencesFailed]: (state) => ({
    ...state,
    isProcessingReferences: false,
    deadline: {
      ...state.deadline,
      references: [],
    },
  }),
  [actions.fetchCurrentDeadlineReferences]: (state) => ({
    ...state,
    isProcessingReferences: true,
  }),
  [actions.fetchCurrentDeadlineReferencesSucceeded]: (state, { payload }) => ({
    ...state,
    isProcessingReferences: false,
    deadline: {
      ...state.deadline,
      referencesPerType: normalizeObjectOfReferences(payload),
    },
  }),
  [actions.fetchCurrentDeadlineReferencesFailed]: (state) => ({
    ...state,
    isProcessingReferences: false,
  }),
  [actions.pullDeadlinesPageRequest]: (state) => ({
    ...state,
    isProcessingRequest: true,
  }),
  [actions.pullDeadlinesPageSucceeded]: (state, { payload }) => ({
    ...state,
    isProcessingRequest: false,
    data: { ...state.data, ...normalizeDeadlines(payload) },
    pagination: {
      pagesLoaded: payload.pagination.pageLoaded + 1,
      pagesToLoad: payload.pagination.pagesToLoad,
      hasMoreToLoad: payload.pagination.hasMoreToLoad,
    },
  }),
  [actions.setDeadlineData]: (state, { payload }) => ({
    ...state,
    deadline: payload,
  }),
  [actions.setDeadlinesFilterValue]: (state, { payload }) => ({
    ...state,
    filters: {
      ...state.filters,
      ...payload,
    },
  }),
  [actions.resetDeadlinesFilters]: (state) => ({
    ...state,
    filters: {
      committee: null,
      month: null,
      type: null,
      title: "",
      timeframe: TIMEFRAME.FUTURE,
    },
  }),
  [actions.addGeneralDeadlineInformation]: (state, { payload }) => ({
    ...state,
    form: {
      ...state.form,
      ...payload,
    },
  }),
  [actions.deleteDeadlineFailed]: (state) => ({
    ...state,
    isDeletingDeadline: false,
  }),
  [actions.deleteDeadlineSucceeded]: (state, { payload }) => {
    const { [payload]: toRemove, ...newdata } = state.data;
    return {
      ...state,
      isDeletingDeadline: false,
      data: newdata,
    };
  },
  [actions.setFormSelectedDeadlineReferences]: (state, { payload }) => {
    return {
      ...state,
      form: {
        ...state.form,
        references: [...payload],
      },
    };
  },
  [actions.setFormSelectedDeadlineReferencesMeetings]: (state, { payload }) => {
    return {
      ...state,
      form: {
        ...state.form,
        referencesPerType: {
          ...state.form.referencesPerType,
          meeting: [...payload],
        },
      },
    };
  },
  [actions.setFormSelectedDeadlineReferencesDocuments]: (
    state,
    { payload },
  ) => {
    return {
      ...state,
      form: {
        ...state.form,
        referencesPerType: {
          ...state.form.referencesPerType,
          document: [...payload],
        },
      },
    };
  },
  [actions.addFormDeadlineReferences]: (state, { payload }) => ({
    ...state,
    form: {
      ...state.form,
      references: [...state.form.references, normalizeDocument(payload)],
    },
  }),
  [actions.fetchUpdatedDeadlineReferenceSucceeded]: (state, { payload }) => ({
    ...state,
    form: {
      ...state.form,
      references: updateEditedDocument(state.form.references, payload),
    },
  }),
  [actions.removeReferenceFromDeadline]: (state, { payload }) => {
    return {
      ...state,
      form: {
        ...state.form,
        referencesPerType: removeReferenceById(
          state.deadline.referencesPerType,
          payload,
        ),
      },
    };
  },
  [actions.clearDeadlineForm]: (state) => ({
    ...state,
    form: initialForm,
  }),
  [actions.clearDeadline]: (state) => ({
    ...state,
    deadline: null,
  }),
  [actions.updateDeadlineReferenceInfo]: (state, { payload }) => ({
    ...state,
    deadline: {
      ...state.deadline,
      references: state.deadline.references.map((d) =>
        d.url !== payload.url ? d : payload,
      ),
    },
  }),
  [actions.updateDeadlineSucceeded]: (state, { payload }) => ({
    ...state,
    isProcessingRequest: false,
    data: updateDeadlineOnList(state.data, payload),
  }),
  [commentsActions.createCommentSucceeded]: (state, { payload }) => ({
    ...state,
    data: addCommnetToActiveDeadlineOnList(state.data, payload.deadlineUri, 1),
  }),
  [commentsActions.deleteCommentSucceeded]: (state, { payload }) => ({
    ...state,
    data: addCommnetToActiveDeadlineOnList(state.data, payload.deadlineUri, -1),
  }),
};

export default handleActions(actionHandlers, initialState);
