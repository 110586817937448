import React, { Component } from "react";
import classNames from "classnames";

class Radio extends Component {
  render() {
    // Radiobutton does not necessarily expect to receive an "element".
    // Added ternary operator to handle other use cases.
    const { element, disabled, isAwaiting } = this.props;
    // Please change if there is a better way to handle each radio button to have a different value
    const randomNumber = Math.random() * 10;
    const inputType = this.props.inputType || "radio";
    if (isAwaiting) {
      return (
        <span
          className={classNames("inline-checkbox__input--awaiting", {
            "inline-checkbox--disabled": disabled,
          })}
          aria-hidden="true"
        ></span>
      );
    }
    return element ? (
      <label htmlFor={`document${element.id}`} className="inline-checkbox">
        <input
          id={`document${element.id}`}
          className="inline-checkbox__input"
          type={inputType}
          name={`document[${element.id}]`}
          value={element.id}
          onClick={() => {}}
          disabled={disabled || false}
        />
        <span
          className={classNames(
            "inline-checkbox__input--off icon icon-radio-off",
            { "inline-checkbox--disabled": disabled },
          )}
          aria-hidden="true"
        ></span>
        <span
          className={classNames(
            "inline-checkbox__input--on icon icon-radio-on",
            { "inline-checkbox--disabled": disabled },
          )}
          aria-hidden="true"
        ></span>
        <span className="field-label visuallyhidden">{element.name}</span>
      </label>
    ) : (
      <label
        htmlFor={`radio-input-${randomNumber}`}
        className="inline-checkbox"
      >
        <input
          id={`radio-input-${randomNumber}`}
          className="inline-checkbox__input"
          type={inputType}
          name={`radio-input[${randomNumber}]`}
          value={`radio-input-${randomNumber}`}
          onChange={this.props.onChange}
          checked={this.props.checked}
          disabled={disabled || false}
        />
        <span
          className={classNames(
            "inline-checkbox__input--off icon icon-radio-off",
            { "inline-checkbox--disabled": disabled },
          )}
          aria-hidden="true"
        ></span>
        <span
          className={classNames(
            "inline-checkbox__input--on icon icon-radio-on",
            { "inline-checkbox--disabled": disabled },
          )}
          aria-hidden="true"
        ></span>
      </label>
    );
  }
}

export default Radio;
