import { transformCommitteeName } from "./transform";
import { isIE } from "./browserDetection";

export const PAGE_TYPES = {
  DEADLINES: "deadlines",
  MEETING: "meetings",
  NOTIFICATION: "notifications",
  COMMENT: "comments",
  NOTIFICATIONS_DEADLINE: "notifications/deadline",
};

// TODO: Need to add reference modals here?
export const DIALOG_TYPES = {
  CREATE_DEADLINE_COMMENT: "CreateDeadlineComment",
  EDIT_COMMENT: "EditComment",
  CREATE_DEADLINE: "CreateDeadline",
  EDIT_DEADLINE: "EditDeadline",
  MEETING_DETAILS: "MeetingDetails",
  DEADLINE_DETAILS: "DeadlineDetails",
  EDIT_MEETING: "EditMeeting",
  CREATE_MEETING: "CreateMeeting",
  // RELATED_CONTENT: "RelatedContent",
  CREATE_NOTIFICATION: "CreateNotification",
  EDIT_NOTIFICATION: "EditNotification",
  NOTIFICATION_DETAILS: "NotificationDetails",

  SEARCH_COMMITTEE: "SearchCommittee",
};

export const generateDialogLinkForPage = (
  nationalCommittee,
  pageType,
  dialogType,
  id,
  parent,
  option,
) => {
  let url = `/${transformCommitteeName(
    nationalCommittee,
  )}/${pageType}?show_dialog=${dialogType}`;
  if (id) url += `&resource=${id}`;
  if (parent) url += `&parent=${parent}`;
  if (option) url += `&option=${option}`;
  return url;
};

export const generateDialogLink = (dialogType, id, parent, option) => {
  let url = `?show_dialog=${dialogType}`;
  if (id) url += `&resource=${id}`;
  if (parent) url += `&parent=${parent}`;
  if (option) url += `&option=${option}`;
  return url;
};

export const generateCloseDialogLink = () => {
  return "?";
};

export const generateNotificationDeadlineLink = (
  nationalCommittee,
  actionType,
  id,
) => {
  if (!id && !actionType) {
    return `/${transformCommitteeName(nationalCommittee)}/${
      PAGE_TYPES.NOTIFICATIONS_DEADLINE
    }`;
  }
  if (id) {
    return `/${transformCommitteeName(nationalCommittee)}/${
      PAGE_TYPES.NOTIFICATIONS_DEADLINE
    }/${id}/${actionType}`;
  }
  return `/${transformCommitteeName(nationalCommittee)}/${
    PAGE_TYPES.NOTIFICATIONS_DEADLINE
  }/${actionType}`;
};

export const generateCommentLink = (nationalCommittee, actionType, id) => {
  if (!id && !actionType) {
    return `/${transformCommitteeName(nationalCommittee)}/${
      PAGE_TYPES.DEADLINES
    }`;
  }
  if (id) {
    return `/${transformCommitteeName(nationalCommittee)}/${
      PAGE_TYPES.DEADLINES
    }/${id}/${actionType}`;
  }
  return `/${transformCommitteeName(nationalCommittee)}/${
    PAGE_TYPES.DEADLINES
  }/${actionType}`;
};

export const generateDocumentsSummaryLink = (nationalCommittee, config) => {
  let url = new URL(
    `/${transformCommitteeName(nationalCommittee)}/documentssummary`,
    process.env.REACT_APP_OPENID_REDIRECT_URL,
  );

  const hash = btoa(JSON.stringify(config));
  return `${url.pathname}#${hash}`;
};

export const generateLink = (nationalCommittee, page) => {
  return `${transformCommitteeName(nationalCommittee)}/${page}`;
};

const linkPatterns = [
  {
    rule: () => /^(ISO|CEN).*/gm,
    pattern: "{{committee}} N{{docId}} {{title}}.{{extension}}",
  },
  {
    rule: () => /^(IEC).*/gm,
    pattern: "{{committee}} {{docId}} {{title}}.{{extension}}",
  },
  {
    rule: () => /^(CLC).*/gm,
    pattern: "{{committee}} {{title}}.{{extension}}",
  },
  { rule: () => /.*/gm, pattern: "{{title}}.{{extension}}" },
];

export function getBinaryFileDownloadUri(document) {
  if (!document || !document.references || document.references.length === 0)
    return "";

  var committeeReference = document.metadata.committeeReference || "";

  var pattern = linkPatterns.find(
    (p) => p.rule().exec(committeeReference) != null,
  );

  if (!pattern) return document.references[0].fileName;

  const fileName = pattern.pattern
    .replace(
      "{{committee}}",
      document.metadata.committeeReference
        .replace(/ /gm, "")
        .replace(/\//gm, "-"),
    )
    .replace("{{docId}}", document.metadata.documentID)
    .replace(
      "{{title}}",
      document.metadata.documentTitle.trim().substring(0, 50).trim(),
    )
    .replace("{{extension}}", document.references[0].fileName.split(".").pop());
  // .replace("{{fileName}}", document.documents[0].fileName);

  let safeFileName = fileName.replace(/[/\\;,:]/gm, "_");

  if (safeFileName.endsWith("~pdf")) {
    safeFileName = safeFileName.replace("~pdf", ".pdf");
  }

  const url = new URL(
    "/api/files/download/binary",
    process.env.REACT_APP_OPENID_AUTHORITY,
  );
  url.searchParams.set("fileName", safeFileName);
  url.searchParams.set("uri", document.references[0].uri);
  if (isIE) {
    url.searchParams.set("noinline", true);
  }
  if (document.metadata.organization) {
    url.searchParams.set("org", document.metadata.organization);
  }

  return url.href;
}
