import React, { Component } from "react";
import classNames from "classnames";
import "./Toggler.scss";

class Toggler extends Component {
  state = {
    opened: false,
  };

  componentDidMount() {
    document.addEventListener("mousedown", this.handleOutsideClick);
    if (this.props.calculatePosition) {
      document.addEventListener("scroll", this.setContentPosition);
    }
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleOutsideClick);
    if (this.props.calculatePosition) {
      document.removeEventListener("scroll", this.setContentPosition);
    }
  }

  handleClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const { opened } = this.state;

    this.setState(
      {
        opened: !opened,
      },
      this.setContentPosition,
    );
  };

  handleOutsideClick = (event) => {
    if (this.ref && !this.ref.contains(event.target)) {
      this.setState(
        {
          opened: false,
        },
        this.setContentPosition,
      );
      this.props.clickHandler && this.props.clickHandler();
    }
  };

  setContentPosition = () => {
    if (!this.props.calculatePosition) return;

    if (this.state.opened) {
      const content = this.ref.querySelector(".toggler__content");
      const button = this.ref.querySelector(".toggler__button");
      const rect = button.getBoundingClientRect();
      const contentHeight = content.offsetHeight;
      const clientWidth = document.body.clientWidth;

      if (this.props.isLast) {
        content.setAttribute(
          "style",
          "position: fixed; right:" +
            (clientWidth - rect.right - rect.width + 24) +
            "px; top: " +
            (rect.top - contentHeight) +
            "px",
        );
      } else {
        content.setAttribute(
          "style",
          "position: fixed; right:" +
            (clientWidth - rect.right - rect.width + 24) +
            "px; top: " +
            (rect.top + rect.height) +
            "px",
        );
      }
    }
  };

  setRef = (node) => {
    this.ref = node;
  };

  render() {
    const { children } = this.props;
    const { opened } = this.state;

    return (
      <div
        className={classNames("toggler", { opened: opened })}
        ref={this.setRef}
        onClick={this.handleClick}
      >
        {children}
      </div>
    );
  }
}

export default Toggler;
