import React, { Component } from "react";

import ModalWrapper from "../modal-wrapper/ModalWrapper";
import { dialogSizes } from "../../../utils/constants";
import { generateCloseDialogLink } from "../../../utils/dynamicLink";
import history from "../../../utils/history";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import {
  popModalStack,
  pushModalStack,
} from "../../../state/modal-stack/actions";
import DeadlineDetails from "../../pages/deadlines/pages/deadline-details/DeadlineDetails";
import CreateComment from "../../pages/deadlines/pages/create-comment/CreateComment";
import EditDeadline from "../../pages/deadlines/pages/edit-deadline/EditDeadline";
import MeetingDetails from "../../pages/meetings/pages/meeting-details/MeetingDetails";
import EditMeeting from "../../pages/meetings/pages/edit-meeting/EditMeeting";
import CreateMeeting from "../../pages/meetings/pages/create-meeting/CreateMeeting";
import RelatedContent from "../../pages/related-content/RelatedContent";
import CreateDeadline from "../../pages/deadlines/pages/create-deadline/CreateDeadline";
import EditComment from "../../pages/deadlines/pages/edit-comment/EditComment";
import SearchCommitteeComponent from "../search-committee-component/SearchCommitteeComponent";
import CreateNotification from "../../pages/notifications/pages/create-notification/CreateNotification";
import EditNotification from "../../pages/notifications/pages/edit-notification/EditNotification";
import NotificationDetails from "../../pages/notifications/pages/notification-details/NotificationDetails";
import { ReferencePage } from "../../pages/notifications/components/reference/ReferencePage";

class SlideModalRouter extends Component {
  state = {
    route: {},
  };

  getTitle(dialogName) {
    const { t } = this.props;

    const titleMap = {
      DeadlineDetails: "modalTitle.DeadlineDetails",
      CreateDeadlineComment: "modalTitle.CreateDeadlineComment",
      EditComment: "modalTitle.EditComment",
      CreateDeadline: "modalTitle.CreateDeadline",
      EditDeadline: "modalTitle.EditDeadline",
      MeetingDetails: "modalTitle.MeetingDetails",
      EditMeeting: "modalTitle.EditMeeting",
      CreateMeeting: "modalTitle.CreateMeeting",
      ReferencePage: "modalTitle.ReferencePage",
      // RelatedContent: "modalTitle.RelatedContent",
    };

    return t(titleMap[dialogName] || "");
  }

  componentDidMount() {
    this.unlisten = history.listen((location, action) => {
      if (action === "PUSH" && location.search.includes("show_dialog")) {
        const dialogName = this.state.route.show_dialog;
        this.props.pushModalStack(this.getTitle(dialogName));
      } else {
        this.props.popModalStack();
      }

      this.updateRouteChange(location);
    });

    this.updateRouteChange(window.location);
  }

  componentWillUnmount() {
    this.unlisten();
  }

  getQueryValues(location) {
    let route = {};
    const query = new URLSearchParams(location.search);
    query.forEach((value, key) => {
      route[key] = value;
    });

    return route;
  }

  updateRouteChange = (location) => {
    const route = this.getQueryValues(location);
    this.setState({
      route: route,
    });
  };

  onCloseDialog = () => {
    history.push(generateCloseDialogLink());
  };

  renderModal(component) {
    return (
      <ModalWrapper
        isOpen={true}
        verticalCentered={false}
        sizeType={dialogSizes.big}
        requestCloseHandler={this.onCloseDialog}
        shouldCloseOnOverlayClick={true}
        showCloseButton={true}
        showGoBackButton
      >
        {React.createElement(component, { context: this.state.route })}
      </ModalWrapper>
    );
  }

  render() {
    const routeMap = {
      DeadlineDetails: DeadlineDetails,
      CreateDeadlineComment: CreateComment,
      EditComment: EditComment,
      CreateDeadline: CreateDeadline,
      EditDeadline: EditDeadline,
      MeetingDetails: MeetingDetails,
      EditMeeting: EditMeeting,
      CreateMeeting: CreateMeeting,
      // RelatedContent: RelatedContent,
      SearchCommittee: SearchCommitteeComponent,
      CreateNotification: CreateNotification,
      EditNotification: EditNotification,
      NotificationDetails: NotificationDetails,
      ReferencePage: ReferencePage,
    };

    if ("show_dialog" in this.state.route) {
      const dialogName = this.state.route.show_dialog;
      if (dialogName in routeMap) {
        const component = routeMap[dialogName];
        return this.renderModal(component);
      }
    }
    return null;
  }
}

const mapDispatchToProps = {
  pushModalStack: pushModalStack,
  popModalStack: popModalStack,
};

export default compose(
  connect(null, mapDispatchToProps),
  withTranslation("common"),
)(SlideModalRouter);
