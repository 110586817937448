import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { getMeeting } from "../../../../../state/meetings/selectors";
import { getMembers } from "../../../../../state/members/selectors";
import { MembersList } from "../../../../common/members-list/MembersList";

export const ParticipantsList = ({ closeHandler }) => {
  const { t } = useTranslation("common");
  const { participants } = useSelector(getMeeting);
  const members = useSelector(getMembers);

  const getAttendanceStatus = (status) => {
    switch (status) {
      case 0:
        return t("meetings.participants.attendance.no");
      case 1:
        return t("meetings.participants.attendance.onsite");
      case 2:
        return t("meetings.participants.attendance.online");
      default:
        return "";
    }
  };

  const participantsList = participants
    .map((participant) => {
      const member = members.find(
        (member) => member.userID === participant.userId,
      );

      if (!member) return null;

      return {
        id: participant.id,
        name: member.name,
        email: member.email,
        label: getAttendanceStatus(participant.attending),
        attending: participant.attending,
        work: member.work,
        photo: member.photo,
      };
    })
    .filter((participant) => participant)
    .sort((a, b) => {
      const order = { 1: 1, 2: 2, 0: 3 };
      return order[a.attending] - order[b.attending] || a.id - b.id;
    });

  return (
    <MembersList
      closeHandler={closeHandler}
      title={t("meetings.participants.label")}
      membersList={participantsList}
    />
  );
};
