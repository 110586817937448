export const referencesPerTypeToReferences = (referencesPerType) => {
  const references = [];

  for (const key in referencesPerType) {
    for (const reference of referencesPerType[key]) {
      // TODO: Should filename be different according to the key
      references.push({
        uri: reference.url ?? reference.uri,
        fileName: reference.filename,
        link: reference.link,
        type: key,
      });
    }
  }

  return references;
};

export const referencesPerTypeToPatchQuery = (referencesPerType) => {
  const toEncodedUri = (value) =>
    value !== null ? encodeURIComponent(value) : null;

  const references = [];

  for (const key in referencesPerType) {
    for (const reference of referencesPerType[key]) {
      references.push({
        // TODO: HORRIBLE DESIGN, GET ONE KEY, either URI or URL
        uri: toEncodedUri(reference.uri ?? reference.url),
        fileName: toEncodedUri(reference.filename),
        link: toEncodedUri(reference.link),
        type: toEncodedUri(reference.type),
      });
    }
  }

  return references;
};
