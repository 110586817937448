import { Component } from "react";
import { connect } from "react-redux";
import { isApplicationInReadonlyMode } from "./../../../state/app/selectors";

class ReadonlyGuard extends Component {
  render() {
    const { children, isApplicationInReadonlyMode } = this.props;
    return isApplicationInReadonlyMode ? null : children;
  }
}

const mapStateToProps = (state) => ({
  isApplicationInReadonlyMode: isApplicationInReadonlyMode(state),
});

export default connect(mapStateToProps)(ReadonlyGuard);
