const defaultPageTitle = "Dansk Standard - Udvalgsportal";
const separator = " | ";

export const setPageTitle = (titleParts, committee) => {
  let committeePrefix = "";

  if (committee) {
    const trimmedCommittee = committee.startsWith("DS/")
      ? committee.slice(3)
      : committee;
    committeePrefix = trimmedCommittee + " - ";
  }

  document.title =
    committeePrefix + defaultPageTitle + separator + titleParts.join(separator);
};
