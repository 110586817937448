import * as actions from "../state/committees/actions";
import { put, call } from "redux-saga/effects";
import { getCommittees } from "../api/resources/values";

export function* fetchCommittees() {
  try {
    const response = yield call(getCommittees);

    if (response.status === 200) {
      yield put(actions.getCommitteesSucceeded(response.data));
    }
  } catch (error) {
    console.error(`Fetching committees failed - ${error}`);
  }
}

export default function* committeesSaga() {}
