import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { appLocaleMap as appLocales } from "../../../utils/constants";
import { setAppLocale } from "../../../state/user/actions";
import { getAppLocale } from "../../../state/user/selectors";
import LanguageDropdown from "../Language-dropdown/LanguageDropdown";

class LanguageSwitcher extends Component {
  onOptionChange = (selectedOptionId) => {
    this.props.setAppLocale(selectedOptionId);
  };

  render() {
    return (
      <LanguageDropdown
        options={Object.keys(appLocales).map((key) => {
          return {
            id: key,
            displaySelected: appLocales[key].language.toUpperCase(),
            label: this.props.t(appLocales[key].name),
          };
        })}
        initOptionId={this.props.appLocale}
        onOptionChange={this.onOptionChange}
      />
    );
  }
}

const mapDispatchToProps = {
  setAppLocale,
};

const mapStateToProps = (state) => ({
  appLocale: getAppLocale(state),
});

export default compose(
  withTranslation("common"),
  connect(mapStateToProps, mapDispatchToProps),
)(LanguageSwitcher);
