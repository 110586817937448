import React from "react";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import { formatDateTime } from "../../../../../utils/transform";
import classNames from "classnames";
import { urlValidator } from "../../../../../utils/validation/urlValidator";
import { UrlParser } from "../../../../../utils/urlParser";

class MeetingDetailsTab extends React.Component {
  get isReferenceEmpty() {
    return (
      this.props.referencesPerType?.documents?.length === 0 &&
      this.props.referencesPerType?.meetings?.length === 0 &&
      this.props.referencesPerType?.deadlines?.length === 0
    );
  }

  render() {
    const { t, locationLong, locationShort, fromDate, toDate } = this.props;

    const isLocationUrl = urlValidator(locationShort);

    return (
      <div className="tab-content">
        {/* <div className="group group--first group--underlined u-align-right">
                    <label htmlFor="meeting-email" className="inline-checkbox">
                        <input
                            className="inline-checkbox__input"
                            type="checkbox"
                            name="meeting-email"
                            value=""
                            id="meeting-email"
                            onClick={() => {
                                //TODO
                            }}/>
                        <span className="inline-checkbox__input--off icon icon-radio-off"></span>
                        <span className="inline-checkbox__input--on icon icon-radio-on"></span>
                        <span>{t("meetings.detail.turnOnMeetings")}</span>
                    </label>
                </div> */}
        <div
          className={classNames("group", {
            "group--underlined": !this.isReferenceEmpty,
          })}
        >
          <div className="detail__description">
            <p>
              {t("meetings.detail.date.from")}: {formatDateTime(fromDate)}
            </p>
            <p>
              {t("meetings.detail.date.to")}: {formatDateTime(toDate)}
            </p>
            {locationShort && (
              <p>
                {isLocationUrl ? (
                  <a href={UrlParser.addHTTP(locationShort)}>
                    {UrlParser.shortenUrl(locationShort)}
                  </a>
                ) : (
                  locationShort
                )}
              </p>
            )}
            {locationLong && <br />}
            {locationLong &&
              locationLong.split("\n").map((i, key) => {
                return (
                  <p key={key} dangerouslySetInnerHTML={{ __html: i }}></p>
                );
              })}
          </div>
        </div>
      </div>
    );
  }
}

export default compose(withTranslation("common"))(MeetingDetailsTab);
