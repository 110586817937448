import React, { Fragment, Component } from "react";
import { NavLink, withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import {
  generateDialogLink,
  DIALOG_TYPES,
} from "../../../../../utils/dynamicLink";

class CommentReminder extends Component {
  render() {
    const { t, itemName, closeHandler } = this.props;
    return (
      <Fragment>
        <div className="group group--first group--underlined">
          <h3 className="headline-4">
            {t("deadlines.details.CommentReminderHeadline", { itemName })}
          </h3>
        </div>
        <div className="group group--last-underlined">
          <p>{t("deadlines.details.CommentReminderMessage")}</p>
          <br />
          <NavLink
            to={`${generateDialogLink(
              DIALOG_TYPES.CREATE_DEADLINE_COMMENT,
              this.props.deadlineUri,
            )}`}
            className="button button--link"
          >
            {t("deadlines.details.CommentReminderNewCommentLink")}
          </NavLink>
        </div>
        <div className="group group--btn u-align-right">
          <button className="button button--primary" onClick={closeHandler}>
            {t("deadlines.details.closeModalButton")}
          </button>
        </div>
      </Fragment>
    );
  }
}

export default withRouter(withTranslation("common")(CommentReminder));
