import React, { useEffect, useState } from "react";
import "./PollComponent.scss";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { userDetails } from "../../../../../state/user/selectors";
import {
  deleteVoteNotificationPoll,
  voteNotificationPoll,
} from "../../../../../state/notifications/actions";
import OnlyMember from "../../../../common/only-member/OnlyMember";
import classNames from "classnames";
import WithPrivileges from "../../../../common/with-privileges/WithPrivileges";
import { ChangeOfficalPollModal } from "./ChangeOfficalPollModal";
import ModalWrapper from "../../../../common/modal-wrapper/ModalWrapper";
import { PollVoterList } from "./PollVoterList";

export const PollComponent = ({ poll }) => {
  const {
    options,
    votes,
    isMultiVote,
    deadline: orgDeadline,
    enableDeadline,
  } = poll;

  const { t } = useTranslation("common");
  const dispatch = useDispatch();
  const userId = useSelector(userDetails)?.id;
  const isDeadlinePassed = new Date(orgDeadline) < new Date();
  const [selectedOptions, setSelectedOptions] = useState([]);

  const [showPollVoterList, setShowPollVoterList] = useState(false);
  const [selectedOptionId, setSelectedOptionId] = useState(null);
  const [showChangeOfficialPollModal, setShowChangeOfficialPollModal] =
    useState(false);

  useEffect(() => {
    if (votes) {
      const userVotes = votes.filter((vote) => vote.userId === userId);
      setSelectedOptions(
        userVotes.map((vote) => ({
          pollOptionId: vote.pollOptionId,
          pollVoteId: vote.id,
        })),
      );
    }
  }, [votes, userId]);

  const calculateBar = (optionId) => {
    const optionVotes = votes.filter(
      (vote) => vote.pollOptionId === optionId,
    ).length;
    const totalVotes = votes.length || 1;
    return optionVotes / totalVotes;
  };

  const handleOptionChange = (optionId, selectedOption) => {
    if (selectedOption) {
      dispatch(
        deleteVoteNotificationPoll({ pollVoteId: selectedOption.pollVoteId }),
      );
    } else {
      if (!isMultiVote && selectedOptions.length > 0) {
        dispatch(
          deleteVoteNotificationPoll({
            pollVoteId: selectedOptions[0].pollVoteId,
          }),
        );
        dispatch(
          voteNotificationPoll({
            pollOptionId: optionId,
          }),
        );
      } else {
        dispatch(
          voteNotificationPoll({
            pollOptionId: optionId,
          }),
        );
      }
    }
  };

  const showPollVoterListHandler = (optionId) => {
    setSelectedOptionId(optionId);
    setShowPollVoterList(true);
  };

  const renderOptions = () => {
    return options.map((option) => {
      const selectedOption = selectedOptions.find(
        (selectedOption) => selectedOption.pollOptionId === option.id,
      );

      const percentage = calculateBar(option.id);

      return (
        <div key={option.id} className="voting-bar__wrapper">
          {(!enableDeadline || (enableDeadline && !isDeadlinePassed)) && (
            <OnlyMember>
              <label>
                <input
                  className="radio-input"
                  type="checkbox"
                  name={`poll-option-${option.id}`}
                  checked={!!selectedOption}
                  onChange={() => handleOptionChange(option.id, selectedOption)}
                />
              </label>
            </OnlyMember>
          )}
          <div className="voting-bar">
            <span>{option.value}</span>
            <div
              className="voting-bar__votes yes"
              style={{ transform: `scaleX(${percentage})` }}
            ></div>
            <div className="voting-bar__vote-count">
              <p className="poll-percentage">
                <span>{`${(percentage * 100).toFixed(2)}%`}</span>
                <button
                  className={classNames(
                    "poll-count",
                    "button",
                    "button--link",
                    "button--no-border",
                  )}
                  disabled={option.count === 0}
                  onClick={() =>
                    option.count > 0
                      ? showPollVoterListHandler(option.id)
                      : null
                  }
                >{`${option.count} vote${option.count > 1 ? "s" : ""}`}</button>
              </p>
            </div>
          </div>
        </div>
      );
    });
  };

  const renderFooter = () => {
    return (
      <div className="official-vote">
        <div className="official-vote__bar">
          <span className="official-vote__label">
            {t("notifications.poll.mainLabel", {
              vote: poll.result,
            })}
          </span>
          <WithPrivileges>
            <button
              className="official-vote-button official-vote__link button button--link button--no-border"
              onClick={() => setShowChangeOfficialPollModal(true)}
            >
              {t("notifications.poll.button.changeResult")}
            </button>
          </WithPrivileges>
        </div>
        <WithPrivileges>
          <span className="official-vote__note">
            {t("deadlines.details.officialVote.note")}
          </span>
        </WithPrivileges>
      </div>
    );
  };

  return (
    <>
      <div className={classNames("group", "group--underlined")}>
        <div className="voter">
          <h3>{poll.title}</h3>
          {renderOptions()}
        </div>
        {renderFooter()}
      </div>
      {showPollVoterList && (
        <ModalWrapper
          isOpen={showPollVoterList}
          requestCloseHandler={() => setShowPollVoterList(false)}
          disableSlide
        >
          <PollVoterList
            closeHandler={() => setShowPollVoterList(false)}
            selectedOptionId={selectedOptionId}
          />
        </ModalWrapper>
      )}
      {showChangeOfficialPollModal && (
        <ModalWrapper
          isOpen={showChangeOfficialPollModal}
          requestCloseHandler={() => setShowChangeOfficialPollModal(false)}
          disableSlide
        >
          <ChangeOfficalPollModal
            onClose={() => setShowChangeOfficialPollModal(false)}
          />
        </ModalWrapper>
      )}
    </>
  );
};
