import { combineActions, handleActions } from "redux-actions";
import * as actions from "./actions";
import { markAllNotificationsAsSeenSucceeded } from "../notifications/actions";
import { messageType } from "../../components/common/form/message-box/MessageBox";
import {
  normalizeEmailSetting,
  normalizeRecentSearches,
  normalizeTurnOffCommittees,
  normalizeUserData,
  normalizeTurnOffCommittee,
  normalizeTurnOnCommittee,
  normalizeUserSettings,
} from "./normalizers";

const initialState = {
  isFetching: false,
  isFetched: false,
  data: {
    settings: {},
    recentSearches: null,
  },
  apiResponse: {
    message: null,
    type: null,
  },
  updateInProgress: false,
  updatePhotoInProgress: false,
};

const actionHandlers = {
  [actions.getUserDetailsSucceeded]: (state, { payload }) => ({
    ...state,
    isFetching: false,
    isFetched: true,
    data: {
      ...state.data,
      ...normalizeUserData(payload.data),
    },
  }),

  [actions.getUserDetailsFailed]: (state) => ({
    ...state,
    isFetching: false,
    isFetched: true,
  }),
  [actions.updateUserProfile]: (state, { payload }) => ({
    ...state,
    updateInProgress: true,
  }),
  [actions.updateUserProfileSucceeded]: (state, { payload }) => ({
    ...state,
    isFetching: false,
    isFetched: true,
    data: {
      ...state.data,
      ...payload,
    },
    apiResponse: {
      type: messageType.success,
      message: ["userProfile.messages.userDetailsUpdated"],
    },
    updateInProgress: false,
  }),

  [actions.updateUserProfileFailed]: (state, { payload }) => ({
    ...state,
    isFetching: false,
    isFetched: true,
    apiResponse: {
      type: messageType.error,
      message: payload,
    },
    updateInProgress: false,
  }),
  [actions.updateUserPhoto]: (state) => ({
    ...state,
    updatePhotoInProgress: true,
  }),
  [actions.updateUserPhotoSucceeded]: (state, { payload }) => ({
    ...state,
    isFetching: false,
    isFetched: true,
    data: {
      ...state.data,
      ...payload,
    },
    apiResponse: {
      type: messageType.success,
      message: ["userProfile.messages.userPhotoUpdated"],
    },
    updatePhotoInProgress: false,
  }),
  [actions.updateUserPhotoFailed]: (state, { payload }) => ({
    ...state,
    isFetching: false,
    isFetched: true,
    apiResponse: {
      type: messageType.error,
      message: payload,
    },
    updatePhotoInProgress: false,
  }),
  [actions.updateUserCredentials]: (state) => ({
    ...state,
    isFetching: true,
    isFetched: false,
  }),

  [actions.updateUserCredentialsSucceeded]: (state, { payload }) => ({
    ...state,
    isFetching: false,
    isFetched: true,
    apiResponse: {
      type: messageType.success,
      message: payload,
    },
  }),

  [actions.updateUserCredentialsFailed]: (state, { payload }) => ({
    ...state,
    isFetching: false,
    isFetched: true,
    apiResponse: {
      type: messageType.error,
      message: payload,
    },
  }),

  [actions.resetUserApiResponse]: (state) => ({
    ...state,
    apiResponse: {
      type: null,
      message: null,
    },
  }),

  [actions.fetchUserSettings]: (state) => ({
    ...state,
    isFetching: true,
    isFetched: false,
  }),

  [actions.fetchUserSettingsSucceeded]: (state, { payload }) => ({
    ...state,
    isFetching: false,
    isFetched: true,
    data: {
      ...state.data,
      settings: {
        ...state.data.settings,
        ...normalizeUserSettings(payload),
      },
    },
    apiResponse: {
      type: null,
      message: null,
    },
  }),

  [actions.fetchUserSettingsFailed]: (state, { payload }) => ({
    ...state,
    isFetching: false,
    isFetched: true,
    apiResponse: {
      type: messageType.error,
      message: payload,
    },
  }),

  [actions.updateTurnOffCommittees]: (state, { payload }) => ({
    ...state,
    data: {
      ...state.data,
      settings: normalizeTurnOffCommittees(payload, state),
    },
  }),

  [actions.turnOffCommitteeSucceeded]: (state, { payload }) => ({
    ...state,
    data: {
      ...state.data,
      settings: normalizeTurnOffCommittee(payload, state),
    },
  }),

  [actions.turnOnCommitteeSucceeded]: (state, { payload }) => ({
    ...state,
    data: {
      ...state.data,
      settings: normalizeTurnOnCommittee(payload, state),
    },
  }),

  [actions.updateUserEmailSettingAllCommitteesSucceeded]: (
    state,
    { payload },
  ) => ({
    ...state,
    data: {
      ...state.data,
      settings: normalizeEmailSetting(payload, state),
    },
  }),

  [combineActions(actions.fetchRecentSearches, actions.updateRecentSearches)]: (
    state,
  ) => ({
    ...state,
    isFetching: false,
    isFetched: true,
  }),

  [combineActions(
    actions.fetchRecentSearchesSucceeded,
    actions.updateRecentSearchesSucceeded,
  )]: (state, { payload }) => ({
    ...state,
    isFetching: false,
    isFetched: true,
    data: {
      ...state.data,
      recentSearches: normalizeRecentSearches(payload.history),
    },
  }),

  [combineActions(
    actions.fetchRecentSearchesFailed,
    actions.updateRecentSearchesFailed,
  )]: (state) => ({
    ...state,
    isFetching: false,
    isFetched: true,
  }),

  [actions.decreaseNumberOfUnseenNotifications]: (state, { payload }) => ({
    ...state,
    data: {
      ...state.data,
      role: {
        ...state.data.role,
        [payload]: {
          ...state.data.role[payload],
          unseenNotifications: state.data.role[payload].unseenNotifications - 1,
        },
      },
    },
  }),
  [markAllNotificationsAsSeenSucceeded]: (state, { payload }) => ({
    ...state,
    data: {
      ...state.data,
      role: {
        ...state.data.role,
        [payload]: {
          ...state.data.role[payload],
          unseenNotifications: 0,
        },
      },
    },
  }),
};
export default handleActions(actionHandlers, initialState);
