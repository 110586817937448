import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { withTranslation } from "react-i18next";

class CardAttachments extends Component {
  render() {
    const { references, comments, messages, children, uri, t } = this.props;

    return references || comments || messages || children ? (
      <div className="card__attachments">
        {references && references > 0 ? (
          <HashLink
            to={`${uri}#documents`}
            className="card__file button button--link"
            data-link-type="documents"
          >
            <span
              className="icon icon-file icon--large"
              aria-hidden="true"
            ></span>
            {references === 1
              ? t("card.referenceCounter")
              : t("card.referencesCounter", { count: references })}
          </HashLink>
        ) : null}
        {comments && comments > 0 ? (
          <Link
            to={`${uri}&option=deadline_comments`}
            className="card__comments button button--link"
            data-link-type="comments"
          >
            <span
              className="icon icon-comment icon--large"
              aria-hidden="true"
            ></span>
            {comments === 1
              ? t("card.commentCounter")
              : t("card.commentsCounter", { count: comments })}
          </Link>
        ) : null}
        {/* { messages && messages > 0 ?
            <Link
                to={`${uri}/messages`}
                className="card__messages button button--link"
            >
                <span className="icon icon-message icon--large" aria-hidden="true"></span>
                {messages === 1 ? t("card.messageCounter") : t("card.messagesCounter", {count: messages})}

            </Link> : null
            } */}
        {this.props.children}
      </div>
    ) : null;
  }
}

export default withRouter(withTranslation("common")(CardAttachments));
