import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";
import { getMeetings } from "../../../../../state/meetings/selectors";
import { formatDateTime } from "../../../../../utils/transform";
import Checkbox from "../../../../common/form/checkbox/Checkbox";
import Toggler from "../../../../common/toggler/Toggler";
import ActionBox from "../../../../common/action-box/ActionBox";
import { getModalDetails } from "../../../../../state/document/selectors";
import ModalWrapper from "../../../../common/modal-wrapper/ModalWrapper";
import contentTypes from "../../../../common/modal-wrapper/contentTypes";
import {
  DIALOG_TYPES,
  generateDialogLink,
} from "../../../../../utils/dynamicLink";
import { Link } from "react-router-dom";
import { setModalState } from "../../../../../state/document/actions";

class MeetingTable extends Component {
  basicModals = {};

  renderActionBox = (element) => {
    const { rowActions } = this.props;

    return (
      <td className="last">
        <Toggler
          calculatePosition={true}
          clickHandler={() => this.setState({ actionBoxOpened: false })}
          isLast={element.isLast}
        >
          <ActionBox>
            {Object.keys(rowActions).map((name, index) => {
              return (
                <li key={index} className="list__element">
                  <button
                    className="button button--link actions-list__button"
                    onClick={() => rowActions[name](element)}
                  >
                    {name}
                  </button>
                </li>
              );
            })}
          </ActionBox>
        </Toggler>
      </td>
    );
  };

  renderRow = (meeting, idx) => {
    const { selectedMeetings, useCheckbox, useActionBox } = this.props;

    const meetingDetailsUrl = generateDialogLink(
      DIALOG_TYPES.MEETING_DETAILS,
      meeting.uri,
    );

    const newMeeting = {
      ...meeting,
      url: meeting.uri,
      selected: !!selectedMeetings?.some((el) => el.uri === meeting.uri),
    };

    return (
      <tr
        className="meetings-table__row"
        key={`tr-${idx}`}
        title={meeting.locationLong}
      >
        {useCheckbox && (
          <td className="checkbox">
            <Checkbox
              element={newMeeting}
              onClick={this.props.onClickCheckbox}
            />
          </td>
        )}
        <td className="committee">{meeting.committee}</td>
        <td className="committeeName">{meeting.committeeName}</td>
        <td className="title">
          <Link to={meetingDetailsUrl}>{meeting.title}</Link>
        </td>
        {/* <td>{el.locationLong}</td> */}
        {/* <td className="fromToDate">{`${formatDateTime(meeting.fromDate)} ~ ${formatDateTime(meeting.toDate)}`}</td> */}
        <td className="from">{formatDateTime(meeting.fromDate)}</td>
        <td className="to">{formatDateTime(meeting.toDate)}</td>
        <td className="location">{meeting.locationShort}</td>
        {useActionBox && this.renderActionBox(meeting)}
      </tr>
    );
  };

  renderModal = () => {
    const {
      additionalModals,
      modal: { contentType },
    } = this.props;

    if (additionalModals) {
      Object.keys(additionalModals).forEach(
        (key) => (this.basicModals[key] = additionalModals[key]),
      );
    }

    if (this.basicModals[contentType]) {
      return this.basicModals[contentType]();
    }
  };

  requestCloseHandler = () => {
    this.props.setModalState({ isOpen: false });
  };

  render() {
    const { meetings, useCheckbox, useActionBox } = this.props;

    return (
      <div className="table-wrapper">
        <div className="table-content">
          <table className="table">
            <thead className="meetings-table">
              <tr>
                {useCheckbox && <th className="checkbox" />}
                <th className="committee">Committee</th>
                <th className="committeeName">Committee Name</th>
                <th className="title">Title</th>
                {/* <th>Description</th> */}
                <th className="FromDate">From</th>
                <th className="ToDate">To</th>
                <th className="location">Location</th>
                {useActionBox && <th className="last" />}
              </tr>
            </thead>
            <tbody className="meetings-table">
              {meetings.map(this.renderRow)}
            </tbody>
          </table>
        </div>
        {this.props.modal.contentType ===
          contentTypes.REMOVE_FILE_FROM_MEETING_LIST && (
          <ModalWrapper
            contentType={this.props.modal.contentType}
            isOpen={this.props.modal.isOpen}
            requestCloseHandler={this.requestCloseHandler}
            disableSlide
          >
            {this.renderModal()}
          </ModalWrapper>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  // meetings: getMeetings(state),
  modal: getModalDetails(state),
});

const mapDispatchToProps = {
  setModalState,
};

export default compose(
  withTranslation("common"),
  connect(mapStateToProps, mapDispatchToProps),
)(MeetingTable);
