import React from "react";
import "./Loader.scss";
import classNames from "classnames";

export default ({ type }) => {
  const loaderClassName = classNames("loader", {
    "loader--content": type && type === "content",
    "loader--inline": type && type === "inline",
  });

  return <div className={loaderClassName}></div>;
};
