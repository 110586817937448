import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import Editor from "../../../../common/editor/Editor";
import { selectedNationalCommittee } from "../../../../../state/national-committees/selectors";
import Tooltip from "../../../../common/tooltip/Tooltip";
import { Link } from "react-router-dom";
import {
  generateDialogLink,
  DIALOG_TYPES,
} from "../../../../../utils/dynamicLink";
import { getCommentTypeSelectItems } from "../../../../../state/values/selectors";
import Loader from "../../../../common/loader/Loader";
import classNames from "classnames";
import FileInput from "../../../../common/form/file-input/FileInput";

import { scrollToElement } from "../../../../../utils/scroll";

import "./CommentForm.scss";
import { getComments } from "../../../../../state/comments/selectors";
import { fetchComments } from "../../../../../state/comments/actions";
import { getDateString } from "../../../../../utils/transform";

const htmlToPlainText = (html) => {
  const tempElement = document.createElement("div");
  tempElement.innerHTML = html;
  return tempElement.textContent || tempElement.innerText;
};

const makeSummary = (comment) => {
  let tempText = `ID:${comment.referenceId}, ${comment.clause}, ${comment.author}`;
  if (tempText.length > 40) {
    tempText = tempText.slice(0, 36) + " ...";
  }
  return tempText;
};

const getCommentPreview = (comment) => {
  return `
  <div class="preview-container">
    <div class="preview-header">
        <span class="header-item">${comment.referenceId}</span>
        <span class="header-item">${comment.line}</span>
        <span class="header-item">${comment.clause}</span>
        <span class="header-item">${comment.paragraph}</span>
        <span class="header-item">${comment.type}</span>
        <span class="header-item">${comment.author}</span>
        <span class="header-item">${getDateString(new Date(comment.updateDate))}</span>
    </div>
    <div class="preview-body">
        <div class="preview-row">
            <div class="preview-label">Comment</div>
            <div class="preview-content">${htmlToPlainText(comment.comment)}</div>
        </div>
        <div class="preview-row">
            <div class="preview-label">Proposed change</div>
            <div class="preview-content">${htmlToPlainText(comment.proposal)}</div>
        </div>
    </div>
  </div>
  `;
};

const setEditorSetupFn = (text, paramComments) => {
  return (editor) => {
    function keyupEvent() {
      const content = editor.getContent({ format: "raw" }); // Use 'raw' to keep HTML structure
      let updatedContent = content;
      let hasReplacement = false;
      let lastChangedSpanId = null;

      comments.forEach((comment) => {
        const regex = new RegExp(`\\{\\{${comment.referenceId}\\}\\}`, "g");
        if (regex.test(content)) {
          updatedContent = updatedContent.replace(
            regex,
            `<span id="comment_${comment.referenceId}" data-guid="${comment.guid}" contenteditable="false" class='comment__label-box'>@ID:${comment.referenceId}</span>`,
          );
          hasReplacement = true;
          lastChangedSpanId = `comment_${comment.referenceId}`;
        }
      });

      if (hasReplacement) {
        const bookmark = editor.selection.getBookmark(2, true);
        editor.setContent(updatedContent, { format: "raw" }); // Use 'raw' to preserve HTML structure
        editor.selection.moveToBookmark(bookmark);

        if (lastChangedSpanId) {
          // Identify the last changed span by its ID
          const lastChangedSpan = editor
            .getDoc()
            .getElementById(lastChangedSpanId);

          if (lastChangedSpan) {
            // Create a range and set the cursor right after the last changed span
            const range = editor.getDoc().createRange();
            range.setStartAfter(lastChangedSpan);
            range.setEndAfter(lastChangedSpan);

            // Move the cursor to the new range
            const sel = editor.selection.getSel();
            sel.removeAllRanges();
            sel.addRange(range);

            // Ensure the editor scrolls to the cursor position
            editor.selection.scrollIntoView();
          }
        }
      }
    }

    const comments = paramComments;
    if (comments && comments.length > 0) {
      // Set up the menu button for references
      editor.ui.registry.addMenuButton("refer_comment", {
        text,
        fetch: function (callback) {
          var items = comments.map((el) => ({
            type: "menuitem",
            text: makeSummary(el),
            onAction: function () {
              editor.insertContent(`{{${el.referenceId}}}`);
              keyupEvent();
            },
          }));
          callback(items);
        },
      });

      // Customizing the mce-offscreen-selection div
      editor.on("NodeChange", function (e) {
        let offscreenDivs = document.getElementsByClassName(
          "tox-edit-area__iframe",
        );
        if (offscreenDivs.length === 0) return;
        for (let i = 0; i < offscreenDivs.length; i++) {
          const offscreenDiv = offscreenDivs[
            i
          ].contentWindow.document.querySelectorAll(".mce-offscreen-selection");
          if (!offscreenDiv) continue;
          offscreenDiv.forEach((div) => {
            comments.forEach((comment) => {
              if (div.textContent.includes(`@ID:${comment.referenceId}`)) {
                div.innerHTML = getCommentPreview(comment);
              }
            });
          });
        }
      });

      editor.on("GetContent", function (e) {
        e.content = e.content.replace(/data-mce-bogus="all"/g, "");
      });

      editor.on("keyup", keyupEvent);
      editor.on("init", keyupEvent);
    }
  };
};

class CommentForm extends Component {
  supportedAttachments = [".jpg", ".jpeg", ".png", ".gif", ".bmp"];
  state = {
    setup: setEditorSetupFn(
      this.props.t("comments.buttons.findComment"),
      this.props.comments,
    ),

    comment: this.props.data ? this.props.data.comment : "",
    proposal: this.props.data ? this.props.data.proposal : "",
    line: this.props.data ? this.props.data.line : "",
    clause: this.props.data ? this.props.data.clause : "",
    paragraph: this.props.data ? this.props.data.paragraph : "",
    selected: this.props.data
      ? this.props.data.type
      : this.props.types[0].value,
    attachments: this.props.data ? this.props.data.attachments : [],
    attachmentsToRemoveFromServer: [],

    validation: {
      comment: { isValid: true },
      proposal: { isValid: true },
      line: { isValid: true },
      paragraph: { isValid: true },
      clause: { isValid: true },
      selected: { isValid: true },
      attachments: { isValid: true },
    },
    formSubmitted: false,
  };

  validate = () => {
    const { comment, proposal, clause, selected } = this.state;

    let validation = {
      comment: { isValid: true },
      proposal: { isValid: true },
      line: { isValid: true },
      paragraph: { isValid: true },
      clause: { isValid: true },
      selected: { isValid: true },
      attachments: { isValid: true },
    };
    let isValid = true;

    if (comment.trim() === "") {
      validation.comment.isValid = false;
      isValid = false;
    }
    if (proposal.trim() === "") {
      validation.proposal.isValid = false;
      isValid = false;
    }
    if (clause.trim() === "") {
      validation.clause.isValid = false;
      isValid = false;
    }

    if (selected.trim() === "") {
      validation.selected.isValid = false;
      isValid = false;
    }

    if (this.hasUnsupportedAttachments()) {
      validation.attachments.isValid = false;
      isValid = false;
    }

    if (!isValid) {
      this.setState(
        {
          validation,
        },
        () => scrollToElement(".validation-message__error"),
      );
    }
    return isValid;
  };

  componentDidMount() {
    if (!this.props.comments || this.props.comments.length === 0) {
      this.props.fetchComments(this.props.deadlineUri);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.types !== this.props.types) {
      this.setState({
        selected: this.state.selected || this.props.types[0].value,
      });
    }
    if (prevProps.data !== this.props.data) {
      this.setState({
        comment: this.props.data.comment || this.state.comment,
        proposal: this.props.data.proposal || this.state.proposal,
        line: this.props.data.line || this.state.line,
        clause: this.props.data.clause || this.state.clause,
        paragraph: this.props.data.paragraph || this.state.paragraph,
        selected:
          (this.props.data ? this.props.data.type : false) ||
          (this.props.types && this.props.types.length
            ? this.props.types[0].value
            : null),
      });
    }

    if (prevProps.comments !== this.props.comments) {
      this.setState({
        setup: setEditorSetupFn(
          this.props.t("comments.buttons.findComment"),
          this.props.comments,
        ),
        refreshKey: Math.random(),
      });
    }
  }

  handleEditorCommentChange = (comment) => {
    this.setState({ comment });
  };

  handleEditorProposeChange = (proposal) => {
    this.setState({ proposal });
  };

  generateSelectOptions = (select) => {
    return select.map((option, index) => {
      return (
        <option value={option.value} key={index}>
          {option.name}
        </option>
      );
    });
  };

  handleSave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (!this.validate()) {
      return;
    }
    this.props.onSave(this.state);
    this.setState({ formSubmitted: true });
  };

  showValidationMessage = (field) => {
    return !this.state.validation[field].isValid;
  };

  isRowValid = () => {
    return (
      !this.showValidationMessage("line") &&
      !this.showValidationMessage("clause") &&
      !this.showValidationMessage("selected")
    );
  };

  isFieldValid = (field) => {
    const { t } = this.props;

    return this.showValidationMessage(field) ? (
      <span className="validation-message__error">
        {t("general.validation.notEmpty")}
      </span>
    ) : this.isRowValid() ? null : (
      <span className="validation-message__error empty">&nbsp;</span>
    );
  };

  addAttachments = (event) => {
    this.setState({
      attachments: [...this.state.attachments, ...event.target.files],
    });
  };

  isAttachmentSupported = (name) => {
    return !!this.supportedAttachments.find((ext) =>
      name.toLowerCase().endsWith(ext),
    );
  };

  hasUnsupportedAttachments = () => {
    return !!this.state.attachments.find(
      (a) => !this.isAttachmentSupported(a.name),
    );
  };

  removeAttachment = (attachment) => {
    let newState = {
      attachments: this.state.attachments.filter(
        (file) => file.name !== attachment.name,
      ),
    };

    // file that has been uploaded
    if (attachment.uri) {
      newState.attachmentsToRemoveFromServer = [
        ...this.state.attachmentsToRemoveFromServer,
        attachment,
      ];
    }

    this.setState(newState);
  };

  renderAttachments = () => {
    const { t } = this.props;

    return (
      this.state.attachments.length > 0 && (
        <ul className="list list--links">
          {this.state.attachments.map((file, index) => (
            <li key={`${file.name}_${index}`}>
              <span className="icon icon-paperclip"></span>
              <span
                className={classNames({
                  unsupportedAttachment: !this.isAttachmentSupported(file.name),
                })}
              >
                {file.name}
              </span>
              <span> - </span>
              <button href="#" onClick={() => this.removeAttachment(file)}>
                {t("comments.links.removeAttachment")}
              </button>
            </li>
          ))}
        </ul>
      )
    );
  };

  render() {
    const { t } = this.props;

    return (
      <form>
        <div className="group group--full-underlined group--first group--comments">
          <div className="group__flex group__flex-grow">
            <div>
              <div className="label-row">
                <label htmlFor="lineNumber" className="label">
                  {t("comments.create.lineNumberrLabel")}
                </label>
                <Tooltip
                  label={t("comments.help.helpTitle")}
                  content={t("comments.help.lineNumber")}
                />
              </div>
              <input
                className="input"
                name="lineNumber"
                id="lineNumber"
                placeholder="E.g. 25"
                value={this.state.line}
                onChange={(e) => {
                  if (/[^0-9]/.test(e.target.value)) return;
                  this.setState({ line: e.target.value });
                }}
              />
              {this.isFieldValid("line")}
            </div>
            <div>
              <div className="label-row">
                <label htmlFor="clause" className="label">
                  {t("comments.create.clauseLabel")}
                </label>
                <Tooltip
                  label={t("comments.help.helpTitle")}
                  content={t("comments.help.clauseSubclause")}
                />
              </div>
              <input
                type="text"
                className="input"
                name="clause"
                id="clause"
                placeholder="E.g. 3.1"
                value={this.state.clause}
                onChange={(e) => {
                  this.setState({
                    clause: e.target.value.replace(/[?{}]/g, ""),
                  });
                }}
              />
              {this.isFieldValid("clause")}
            </div>
            <div>
              <div className="label-row">
                <label htmlFor="paragraph" className="label">
                  {t("comments.create.paragraphLabel")}
                </label>
                <Tooltip
                  label={t("comments.help.helpTitle")}
                  content={t("comments.help.paragraph")}
                />
              </div>
              <input
                type="text"
                className="input"
                name="paragraph"
                id="paragraph"
                placeholder="E.g. Figure 1"
                value={this.state.paragraph}
                onChange={(e) => this.setState({ paragraph: e.target.value })}
              />
              {this.isFieldValid("paragraph")}
            </div>
            <div>
              <div className="label-row">
                <label htmlFor="typeOfComment" className="label">
                  {t("comments.create.typeLabel")}
                </label>
                <Tooltip
                  label={t("comments.help.helpTitle")}
                  content={t("comments.help.commentType")}
                />
              </div>
              {this.props.types && this.props.types.length ? (
                <div className="select">
                  <select
                    id="typeOfComment"
                    onChange={(e) =>
                      this.setState({ selected: e.target.value })
                    }
                    value={
                      this.state.selected
                        ? this.state.selected
                        : this.props.types[0].value
                    }
                  >
                    {this.generateSelectOptions(this.props.types)}
                  </select>
                  <span className="select__text">
                    {this.state.selected
                      ? this.state.selected
                      : this.props.types[0].value}
                  </span>
                  <span className="icon icon-arrow-right" aria-hidden="true" />
                </div>
              ) : (
                <Loader type="inline" />
              )}
              {this.isFieldValid("selected")}
            </div>
          </div>
        </div>
        <div className="group group--full-underlined">
          <div className="label-row">
            <label htmlFor="typeOfComment" className="label">
              {t("comments.create.commentLabel")}
            </label>
            <Tooltip
              label={t("comments.help.helpTitle")}
              content={t("comments.help.comment")}
            />
          </div>
          <Editor
            value={this.state.comment}
            onEditorChange={this.handleEditorCommentChange}
            setup={this.state.setup}
            refreshKey={this.state.refreshKey}
          />
          {this.showValidationMessage("comment") && (
            <span className="validation-message__error">
              {t("general.validation.notEmpty")}
            </span>
          )}
        </div>
        <div className="group group--full-underlined">
          <div className="label-row">
            <label htmlFor="typeOfComment" className="label">
              {t("comments.create.proposeLabel")}
            </label>
            <Tooltip
              label={t("comments.help.helpTitle")}
              content={t("comments.help.proposedChange")}
            />
          </div>
          <Editor
            value={this.state.proposal}
            onEditorChange={this.handleEditorProposeChange}
            setup={this.state.setup}
            refreshKey={this.state.refreshKey}
          />
          {this.showValidationMessage("proposal") && (
            <span className="validation-message__error">
              {t("general.validation.notEmpty")}
            </span>
          )}
        </div>
        {this.renderAttachments()}
        <div>
          {this.hasUnsupportedAttachments() && (
            <span className="validation-message__error">
              {t("comments.create.unsupportedAttachments")}
            </span>
          )}
        </div>
        <div className="group group--full-underlined">
          <FileInput
            id="attachFilesToComment"
            onChangeHandler={this.addAttachments}
            buttonText={t("general.button.attachImage")}
            inputOptions={{
              multiple: true,
              accept: this.supportedAttachments.join(","),
            }}
          />
        </div>
        <div className="group group--btn u-align-right">
          <Link
            className="button"
            to={generateDialogLink(
              DIALOG_TYPES.DEADLINE_DETAILS,
              this.props.deadlineUri,
              null,
              "deadline_comments",
            )}
          >
            {t("comments.create.buttons.cancel")}
          </Link>
          <button
            className={classNames("button button--primary", {
              "button--disabled, button--awaiting": this.state.formSubmitted,
            })}
            onClick={(e) => this.handleSave(e)}
            disabled={this.state.formSubmitted}
          >
            {t("comments.create.buttons.save")}
          </button>
        </div>
      </form>
    );
  }
}

const mapDispatchToProps = {
  fetchComments,
};

const mapStateToProps = (state) => ({
  comments: getComments(state),
  selectedNationalCommittee: selectedNationalCommittee(state),
  types: getCommentTypeSelectItems(state),
});

export default compose(
  withTranslation("common"),
  connect(mapStateToProps, mapDispatchToProps),
)(CommentForm);
