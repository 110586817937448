import React, { Component } from "react";
import PropTypes from "prop-types";
import "./ToggleSwitch.scss";

class ToggleSwitch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isChecked: props.checked || false,
    };
  }

  handleToggle = () => {
    const { onChange } = this.props;
    const { isChecked } = this.state;

    this.setState({ isChecked: !isChecked }, () => {
      if (onChange) {
        onChange(this.state.isChecked);
      }
    });
  };

  render() {
    const { isChecked } = this.state;

    return (
      <div
        className={`toggle-switch ${isChecked ? "on" : "off"}`}
        onClick={this.handleToggle}
      >
        <div className="switch-handle" />
      </div>
    );
  }
}

ToggleSwitch.propTypes = {
  checked: PropTypes.bool,
  onChange: PropTypes.func,
};

export default ToggleSwitch;
