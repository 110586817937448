import React, { Component, Fragment } from "react";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import Cropper from "react-cropper";
import "canvas-toBlob";

import "../Profile.scss";
import "../../../../../../styles/avatar.scss";
import FileInput from "../../../../../common/form/file-input/FileInput";
import Avatar from "../../../../../common/avatar/Avatar";

const IMAGE_SIZE_LIMIT = 1024 * 1024 * 10;

class ProfileImage extends Component {
  state = {
    editMode: false,
    imageData: null,
    croppedImage: null,
    croppedBlob: null,
  };

  isVisible() {
    return this.props.photo || this.props.userName;
  }

  hasUploadedPhoto() {
    return this.props.photo || this.state.editMode;
  }

  cropImage() {
    try {
      let dataUrl = this.refs.cropper
        .getCroppedCanvas({
          width: 150,
          height: 150,
          imageSmoothingQuality: "medium",
        })
        .toDataURL();
      this.refs.cropper
        .getCroppedCanvas({
          width: 150,
          height: 150,
          imageSmoothingQuality: "medium",
        })
        .toBlob((blob) => {
          this.setState({
            croppedImage: dataUrl,
            croppedBlob: blob,
          });
        });
    } catch (err) {
      console.error(err);
    }
  }

  applyCropping = () => {
    const { croppedImage, croppedBlob } = this.state;
    this.props.onImageUpdated(croppedImage, croppedBlob);
    this.setState({
      editMode: false,
      imageData: null,
      croppedImage: null,
      croppedBlob: null,
    });
  };

  abortImageCrop = () => {
    this.setState({
      editMode: false,
      imageData: null,
      croppedImage: null,
      croppedBlob: null,
    });
  };

  validateFile(file) {
    this.props.updateMessageListState([
      {
        message: "userProfile.validation.imageFileToLarge",
        validate: (f) => f.size <= IMAGE_SIZE_LIMIT,
        value: file,
      },
      {
        message: "userProfile.validation.notImageFile",
        validate: (f) => f.type.startsWith("image"),
        value: file,
      },
    ]);

    return file.size <= IMAGE_SIZE_LIMIT && file.type.startsWith("image");
  }

  onChangePhoto = (event) => {
    const file = event.target.files[0];
    if (!this.validateFile(file)) return;

    const reader = new FileReader();
    reader.onload = () => {
      let dataURL = reader.result.replace(
        ";base64",
        `;name=${file.name};base64`,
      );
      this.setState({
        imageData: dataURL,
        editMode: true,
      });
    };
    reader.readAsDataURL(file);
  };

  onRemovePhoto = () => {
    this.props.onRemoveImage();
  };

  renderPhoto() {
    const { photo, userName } = this.props;
    return <Avatar profileImage={photo} name={userName} />;
  }

  renderPhotoEditor() {
    return (
      <Cropper
        ref="cropper"
        src={this.state.imageData}
        className="user__crop"
        // Cropper.js options
        aspectRatio={1.0 / 1.0}
        guides={false}
        crop={this.cropImage.bind(this)}
      />
    );
  }

  renderButtons = () => {
    const { t } = this.props;
    return (
      <div className="user-avatar__buttons">
        {!this.state.editMode && (
          <FileInput
            onChangeHandler={this.onChangePhoto}
            isInline={false}
            isSmallButton={true}
            isPrimaryButton={false}
            buttonText={
              this.hasUploadedPhoto()
                ? t("userProfile.profilePhoto.changePhoto")
                : t("userProfile.profilePhoto.uploadPhoto")
            }
            id="avatar"
            inputOptions={{
              name: "avatar",
              accept: ".jpeg,.jpg,.png,.bmp,.gif,.tiff",
            }}
          />
        )}
        {this.state.editMode && (
          <Fragment>
            <button
              className="button button--primary button--small"
              onClick={() => this.applyCropping()}
            >
              {t("userProfile.profilePhoto.setPhoto")}
            </button>
            <button
              className="button button--small"
              onClick={() => {
                this.abortImageCrop();
              }}
            >
              {t("general.button.cancel")}
            </button>
          </Fragment>
        )}
        {!this.state.editMode && this.hasUploadedPhoto() && (
          <button
            className="button button--small"
            onClick={() => {
              this.onRemovePhoto();
            }}
          >
            {t("userProfile.profilePhoto.deletePhoto")}
          </button>
        )}
      </div>
    );
  };

  render() {
    return this.isVisible() ? (
      <div className="u-row-medium">
        {this.state.editMode ? this.renderPhotoEditor() : this.renderPhoto()}
        {this.renderButtons()}
      </div>
    ) : null;
  }
}

export default compose(withTranslation("common"))(ProfileImage);
