import { sortingType } from "./url";

export const mapSortTypeToObj = (type) => {
  switch (type) {
    case sortingType.lineNumber_descending:
      return { name: "line", type: "desc", method: (elem) => elem };
    case sortingType.lineNumber_ascending:
      return { name: "line", type: "asc", method: (elem) => elem };
    case sortingType.clause_descending:
      return { name: "clause", type: "desc", method: (elem) => elem };
    case sortingType.clause_ascending:
      return { name: "clause", type: "asc", method: (elem) => elem };
    case sortingType.paragraph_descending:
      return { name: "paragraph", type: "desc", method: (elem) => elem };
    case sortingType.paragraph_ascending:
      return { name: "paragraph", type: "asc", method: (elem) => elem };
    case sortingType.type_descending:
      return { name: "type", type: "desc", method: (elem) => elem };
    case sortingType.type_ascending:
      return { name: "type", type: "asc", method: (elem) => elem };
    case sortingType.author_descending:
      return { name: "author", type: "desc", method: (elem) => elem };
    case sortingType.author_ascending:
      return { name: "author", type: "asc", method: (elem) => elem };
    case sortingType.date_descending:
      return { name: "updateDate", type: "desc", method: (elem) => elem };
    case sortingType.date_ascending:
      return { name: "updateDate", type: "asc", method: (elem) => elem };
    default:
      return { name: "updateDate", type: "desc", method: (elem) => elem };
  }
};

export const sortComments = (a, b, sortingType) => {
  const sortSettings = mapSortTypeToObj(sortingType);

  if (sortSettings.type === "asc") {
    if (
      sortSettings.method(a[sortSettings.name]) <
      sortSettings.method(b[sortSettings.name])
    )
      return -1;
    if (
      sortSettings.method(a[sortSettings.name]) >
      sortSettings.method(b[sortSettings.name])
    )
      return 1;
    return 0;
  } else {
    if (
      sortSettings.method(a[sortSettings.name]) >
      sortSettings.method(b[sortSettings.name])
    )
      return -1;
    if (
      sortSettings.method(a[sortSettings.name]) <
      sortSettings.method(b[sortSettings.name])
    )
      return 1;
    return 0;
  }
};
