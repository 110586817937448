import { handleActions } from "redux-actions";
import { normalizeBroadcastInformation } from "./normalizers";
import * as actions from "./actions";

const initialState = {
  broadcastInformation: {},
  documentDiagnositcs: {},
};

const actionHandlers = {
  [actions.pullNotificationBroadcastInfoSucceeded]: (state, { payload }) => ({
    ...state,
    broadcastInformation: {
      ...state.broadcastInformation,
      [payload.id]: normalizeBroadcastInformation(payload),
    },
  }),
  [actions.updateDocumentWithDiagnosticsData]: (state, { payload }) => ({
    ...state,
    documentDiagnositcs: {
      ...state.documentDiagnositcs,
      [payload.metadata.notificationUri]: payload,
    },
  }),
};

export default handleActions(actionHandlers, initialState);
