import React, { Component } from "react";
import TimeAgo from "react-timeago";
import { getTimeAgoFormatter } from "../../../../../utils/timeago";
import NotificationCardMenu from "../notification-card-menu/NotificationCardMenu";
import { compose } from "redux";
import { connect } from "react-redux";
import { initNotifications } from "../../../../../state/notifications/actions";

class NotificationBar extends Component {
  render() {
    const { title, date, children, notificationMenu } = this.props;
    return (
      <div className="notification-card__bar">
        {title && <span>{title}</span>}
        {children}
        {date && (
          <span className="date text-small">
            <TimeAgo date={date} formatter={getTimeAgoFormatter()}></TimeAgo>
          </span>
        )}
        {notificationMenu && (
          <NotificationCardMenu
            isPinned={true}
            committee={notificationMenu.committee}
            notificationId={notificationMenu.notificationId}
            customActions={notificationMenu.customActions}
            callbackAction={this.props.initNotifications}
          />
        )}
      </div>
    );
  }
}

const mapDispatchToProps = {
  initNotifications,
};

export default compose(connect(null, mapDispatchToProps))(NotificationBar);
