import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import {
  initNotificationFormWithGeneralInfo,
  updateNotification,
} from "../../../../../state/notifications/actions";
import { getNewNotificationFormData } from "../../../../../state/notifications/selectors";
import {
  DIALOG_TYPES,
  PAGE_TYPES,
  generateDialogLinkForPage,
} from "../../../../../utils/dynamicLink";
import { selectedNationalCommittee } from "../../../../../state/national-committees/selectors";
import NotificationForm from "../notification-form/NotificationForm";

class EditNotification extends Component {
  onMount = () => {
    const uri = this.props.context.resource;
    this.props.initNotificationFormWithGeneralInfo(uri);
  };

  get editorCancelUrl() {
    const { selectedNationalCommittee, formData } = this.props;
    return generateDialogLinkForPage(
      selectedNationalCommittee,
      PAGE_TYPES.NOTIFICATION,
      DIALOG_TYPES.NOTIFICATION_DETAILS,
      formData.uri,
    );
  }

  get editorSubmitAction() {
    return this.props.updateNotification;
  }

  render() {
    const { formData, selectedNationalCommittee } = this.props;

    return (
      <NotificationForm
        onMount={this.onMount}
        formData={formData}
        editorSubmitAction={this.editorSubmitAction}
        editorCancelUrl={this.editorCancelUrl}
      />
    );
  }
}

const mapDispatchToProps = {
  updateNotification,
  initNotificationFormWithGeneralInfo,
};

const mapStateToProps = (state) => ({
  formData: getNewNotificationFormData(state),
  selectedNationalCommittee: selectedNationalCommittee(state),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  // withTranslation("common"),
)(EditNotification);
