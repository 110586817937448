import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import classNames from "classnames";
import SelectFilter from "../../../../../components/common/select/SelectFilter";
import { validate } from "../../../../../services/documents/validation";
import DatePicker from "react-datepicker";
import { addHours } from "date-fns";
import "react-datepicker/dist/react-datepicker.css";
import "../../../../../styles/date-picker.scss";
import OptionalLabel from "../../../../common/optional-label/OptionalLabel";

class CreateDocument extends Component {
  state = {
    validation: {
      filename: { isValid: true },
      title: { isValid: true },
      committee: { isValid: true },
      id: { isValid: true },
      type: { isValid: true },
      action: { isValid: true },
    },
    formSubmitted: false,
  };

  handleSubmit = () => {
    const formValidation = validate(this.props.document);
    if (!formValidation.isValid) {
      return this.setState({ validation: formValidation.validation });
    }
    this.props.submitHandler(this.props.document);
    this.setState({ formSubmitted: true });
  };

  showValidationMessage = (field) => {
    const formField =
      this.props.document[field] && this.props.document[field].trim();
    if (field === "id") {
      return (
        !this.state.validation[field].isValid &&
        !Number.isInteger(parseInt(formField)) &&
        !!formField
      );
    } else {
      return !this.state.validation[field].isValid && !formField;
    }
  };

  render() {
    const { t } = this.props;
    //todo: list should be get from selector, not props
    const {
      filename,
      title,
      description,
      type,
      committee,
      action,
      dueDate,
      notifyOnUpdate,
      notifyImmediately,
      id,
      count,
      currentDocument,
    } = this.props.document;
    const {
      cancelRemainingHandler,
      skipHandler,
      filters,
      editDataHandler,
      hideNotificationOption,
    } = this.props;
    return (
      <div className="modal__form">
        <div className="group group--first group--full-underlined">
          <h3 className="headline-4">
            {t("documents.dialog.create.title", { currentDocument, count })}
          </h3>
        </div>
        <div className="group group--underlined">
          <label className="field-label" htmlFor="fileName">
            {t("documents.dialog.create.label.fileName")}
          </label>
          <input
            id="fileName"
            type="text"
            className={classNames(
              "input",
              {
                "input--validation_error":
                  this.showValidationMessage("filename"),
              },
              "input--disabled",
            )}
            value={filename ? filename : ""}
            // onChange={(event) => {
            //     editDataHandler({filename: event.target.value})
            // }}
            readOnly={true}
          />
          {this.showValidationMessage("filename") && (
            <span className="validation-message__error">
              {t("general.validation.notEmpty")}
            </span>
          )}
        </div>

        <div className="group group--underlined">
          <label className="field-label" htmlFor="title">
            {t("documents.dialog.create.label.title")}
          </label>
          <input
            id="title"
            type="text"
            className={classNames("input", {
              "input--validation_error": this.showValidationMessage("title"),
            })}
            value={title ? title : ""}
            onChange={(event) => {
              editDataHandler({ title: event.target.value });
            }}
          />
          {this.showValidationMessage("title") && (
            <span className="validation-message__error">
              {t("general.validation.notEmpty")}
            </span>
          )}
        </div>

        {/* Document description */}
        <div className="group group--underlined">
          <label className="field-label" htmlFor="description">
            {t("documents.dialog.create.label.description")}
            <OptionalLabel />
          </label>
          <input
            id="description"
            type="text"
            className={"input"}
            value={description ? description : ""}
            onChange={(event) => {
              editDataHandler({ description: event.target.value });
            }}
          />
        </div>
        <div className="group group--underlined">
          <SelectFilter
            id="committeeFilter"
            label={t("documents.dialog.create.label.committee")}
            labelOnlyForScreenReaders={false}
            items={filters.committees}
            selectedItem={committee}
            onChange={(committeeId) => {
              editDataHandler({ committee: committeeId });
            }}
          />
        </div>

        <div className="group group--underlined">
          <label className="field-label" htmlFor="documentId">
            {t("documents.dialog.create.label.documentId")}
            <OptionalLabel />
          </label>
          <input
            id="documentId"
            type="text"
            className={classNames("input", {
              "input--validation_error": this.showValidationMessage("id"),
            })}
            value={id ? id : ""}
            onChange={(event) => {
              editDataHandler({ id: event.target.value });
            }}
          />

          {this.showValidationMessage("id") && (
            <span className="validation-message__error">
              {t("general.validation.isNumber")}
            </span>
          )}
        </div>

        <div className="group group--underlined">
          <SelectFilter
            id="documentTypeFilter"
            label={t("documents.dialog.create.label.documentType")}
            labelOnlyForScreenReaders={false}
            items={filters.avaiableTypes}
            selectedItem={type}
            onChange={(typeId) => {
              editDataHandler({ type: typeId });
            }}
            customClasses={classNames({
              "input--validation_error": this.showValidationMessage("type"),
            })}
          />
          {this.showValidationMessage("type") && (
            <span className="validation-message__error">
              {t("general.validation.isSelected")}
            </span>
          )}
        </div>

        <div className="group group--underlined">
          <SelectFilter
            id="actionFilter"
            label={t("documents.dialog.create.label.action")}
            labelOnlyForScreenReaders={false}
            items={filters.actions}
            selectedItem={action}
            onChange={(actionId) => {
              editDataHandler({ action: actionId });
            }}
            customClasses={classNames({
              "input--validation_error": this.showValidationMessage("action"),
            })}
          />
          {this.showValidationMessage("action") && (
            <span className="validation-message__error">
              {t("general.validation.isSelected")}
            </span>
          )}
        </div>

        <div className="group group--underlined">
          <label className="field-label">
            {t("documents.dialog.create.label.actionDueDate")}
            <OptionalLabel />
          </label>
          <div className="datepicker datepicker--width-fix">
            <DatePicker
              className="input"
              name="documentDueDate"
              id="documentDueDate"
              selected={dueDate}
              onChange={(date) => {
                editDataHandler({ dueDate: addHours(date, 2) });
              }}
              dateFormat="yyyy-MM-dd"
              minDate={new Date()}
            />
          </div>
        </div>
        {!hideNotificationOption && (
          <div className="group group--last-underlined">
            <div className="checkbox__group">
              <label htmlFor="notificationSetting" className="inline-checkbox">
                <input
                  className="inline-checkbox__input"
                  type="checkbox"
                  name="document"
                  checked={notifyOnUpdate}
                  id="notificationSetting"
                  onChange={(event) => {
                    if (!event.target.checked && notifyImmediately) {
                      editDataHandler({ notifyImmediately: false });
                    }
                    editDataHandler({ notifyOnUpdate: event.target.checked });
                  }}
                />
                <span className="inline-checkbox__input--off icon icon-radio-off"></span>
                <span className="inline-checkbox__input--on icon icon-radio-on"></span>
                <span className="u-text-medium-dark">
                  {t("documents.dialog.edit.label.notifyMembers")}
                </span>
              </label>
              <label
                htmlFor="update-on-notify-immediately"
                className={classNames("inline-checkbox")}
              >
                <input
                  disabled={!notifyOnUpdate}
                  className="inline-checkbox__input"
                  type="checkbox"
                  name="update-on-notify-immediately"
                  checked={notifyImmediately ?? false}
                  id="update-on-notify-immediately"
                  onChange={(event) => {
                    editDataHandler({
                      notifyImmediately: event.target.checked,
                    });
                  }}
                />
                <span
                  className={`inline-checkbox__input--off icon icon-radio-off ${!notifyOnUpdate && "checkbox-disabled"}`}
                ></span>
                <span className="inline-checkbox__input--on icon icon-radio-on"></span>
                <span className="u-text-medium-dark">
                  {t("notifications.detail.notifyMembers")}
                </span>
              </label>
            </div>
          </div>
        )}

        <div className="modal__footer group group--btn u-align-right">
          <button className="button" onClick={skipHandler}>
            {" "}
            {t("general.button.cancel")}
          </button>
          {count > 1 && currentDocument !== count && (
            <button className="button" onClick={cancelRemainingHandler}>
              {" "}
              {t("general.button.cancelRemaining")}
            </button>
          )}
          <button
            className={classNames("button button--primary", {
              "button--awaiting button--disabled": this.state.formSubmitted,
            })}
            onClick={this.handleSubmit}
            disabled={this.state.formSubmitted}
          >
            {t("general.button.save")}
          </button>
        </div>
      </div>
    );
  }
}
export default withTranslation("common")(CreateDocument);
