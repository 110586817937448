import { createSelector } from "reselect";
import { getDeadlineUri, getDeadline } from "../deadlines/selectors";
import { officialVoteCalculator } from "../../services/votes/officialVoteCalculator";
import { voteType, reviewStageValue } from "../../utils/constants";

export const votes = (state) => state.votes.data;
const votesOverrides = (state) => state.votes.overrides;

export const voteStatistics = (state) => state.votes.statistics;
export const isUpdatingVote = (state) => state.votes.updateVoteInProgress;

export const voteDetails = createSelector(
  [votes, getDeadlineUri],
  (votes, uri) => (votes[uri] ? votes[uri] : []),
);

export const voteStats = createSelector(
  [voteStatistics, getDeadlineUri],
  (voteStatistics, uri) => voteStatistics[uri],
);

export const officialVote = createSelector(
  [voteStatistics, votesOverrides, getDeadline],
  (voteStatistics, votesOverrides, deadline) => {
    if (votesOverrides[deadline.uri] && votesOverrides[deadline.uri].vote) {
      return voteType[votesOverrides[deadline.uri].vote.toLowerCase()];
    }

    return officialVoteCalculator(
      voteStatistics[deadline.uri],
      deadline.stage === reviewStageValue,
    );
  },
);
