export class UrlParser {
  static addHTTP = (url) => {
    if (url.startsWith("http")) {
      return url;
    }
    return `http://${url}`;
  };

  static shortenUrl = (url) => {
    if (url.length <= 16) {
      return url;
    }
    return `${url.slice(0, 42)}...`;
  };
}
