import userReducer from "./user/reducer";
import nationalCommitteesReducer from "./national-committees/reducer";
import membersReducer from "./members/reducer";
import settingsReducer from "./settings/reducer";
import documentReducer from "./document/reducer";
import deadlinesReducer from "./deadlines/reducer";
import votesReducer from "./votes/reducer";
import notificationsReducer from "./notifications/reducer";
import meetingsReducer from "./meetings/reducer";
import appReducer from "./app/reducer";
import valuesReducer from "./values/reducer";
import searchReducer from "./search/reducer";
import commentsReducer from "./comments/reducer";
import committeesReducer from "./committees/reducer";
import standardsReducer from "./standards/reducer";
import documentSummaryReducer from "./documentSummary/reducer";
import diagnosticsReducer from "./diagnostics/reducer";
import modalStack from "./modal-stack/reducer";
import header from "./header/reducer";
import references from "./references/reducer";
import notifyToasterContent from "./notify-toaster-content/reducer";

export const getRootReducers = () => ({
  user: userReducer,
  nationalCommittees: nationalCommitteesReducer,
  members: membersReducer,
  settings: settingsReducer,
  documents: documentReducer,
  deadlines: deadlinesReducer,
  votes: votesReducer,
  notifications: notificationsReducer,
  meetings: meetingsReducer,
  values: valuesReducer,
  search: searchReducer,
  app: appReducer,
  comments: commentsReducer,
  committees: committeesReducer,
  standards: standardsReducer,
  documentsSummary: documentSummaryReducer,
  diagnostics: diagnosticsReducer,
  modalStack: modalStack,
  header: header,
  references: references,
  notifyToasterContent: notifyToasterContent,
});
