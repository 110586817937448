export const getReferneceType = (type) => {
  switch (type) {
    case "Document":
      return "document";
    case "Meeting":
      return "meeting";
    case "Deadline":
      return "deadline";
    case "Notification":
      return "notification";
    default:
      return type ?? "empty";
  }
};

export const referenceListToReferenceDataPerType = (references) => {
  const newReferences = {};

  references.forEach((reference) => {
    let tempReference;

    switch (reference.type) {
      case "Document":
        tempReference = getReferenceRes(reference);
        break;
      case "Notification":
        tempReference = getReferenceRes(reference);
        break;
      case "Deadline":
        tempReference = getReferenceRes(reference);
        break;
      case "Meeting":
        tempReference = getReferenceRes(reference);
        break;
      // case "Binary": tempReference = handleDocument(reference); break;
      // case "Comment": tempReference = handleDocument(reference); break;
      // case "Vote": tempReference = handleDocument(reference); break;
      default:
        tempReference = getReferenceRes(reference);
        break;
    }
    // const tempReference = {
    //   uri: reference.url,
    //   fileName: reference.filename,
    //   link: reference.link,
    //   type: reference.type,
    // };

    if (!newReferences[tempReference.type])
      newReferences[tempReference.type] = [];

    newReferences[tempReference.type].push(tempReference);
  });

  return newReferences;
};

const getReferenceRes = (reference) => {
  return {
    uri: reference.uri ?? reference.url,
    fileName: reference.fileName,
    link: reference.link,
    type: getReferneceType(reference.type),
    realType: reference.type,
  };
};

// export const referenceObjectToArray = (references) => {
//   const newReferences = [];

//   for(const key in references){
//     const reference = references[key];

//     newReferences.push({
//       uri: reference.url,
//       fileName: reference.filename,
//       link: reference.link,
//       type: key,
//     })

//     // switch(key){
//     //   // documents
//     //   case "binary":
//     //     newReferences.push({
//     //       uri: reference.url,
//     //       fileName: reference.filename,
//     //       link: reference.link,
//     //       type: reference.type,
//     //     })
//     //     break;
//     //   case "meeting":
//     //   case "deadline":
//     // }
//   }

//   return newReferences;
// }
