import React, { Component } from "react";
import "./SideMenu.scss";
import SearchBar from "../search-bar/SearchBar";
import { isConsultant } from "../../../state/user/selectors";
import { connect } from "react-redux";
import { Link, Route, Switch } from "react-router-dom";
import Navigation from "../layout/Navigation";
import {
  DIALOG_TYPES,
  generateDialogLink,
  generateLink,
} from "../../../utils/dynamicLink";
import { selectedNationalCommittee } from "../../../state/national-committees/selectors";
import { UserPageLinks } from "../../pages/user-account/UserAccount";
import { AggPageNavigationLinks } from "../../AggHome";
import { DefaultPageNavigationLinks } from "../../Home";

class SideMenu extends Component {
  getLinks = () => {
    const { selectedNationalCommittee } = this.props;

    return DefaultPageNavigationLinks.map((link) => {
      return {
        ...link,
        url: generateLink(selectedNationalCommittee, link.subRoute),
      };
    });
  };

  state = {
    searchPhrase: null,
  };

  // onSearch = (searchPhrase) => {
  //   this.setState({
  //     searchPhrase: searchPhrase,
  //   });
  // };

  // onClear = () => {
  //   this.setState({
  //     searchPhrase: null,
  //   });
  // };

  sideMenuTitle = (title) => {
    return (
      <>
        <span className="side-menu__committee">{title}</span>
        <span className="icon icon-arrow-right" aria-hidden="true"></span>
      </>
    );
  };

  render() {
    const { onClickAction, selectedNationalCommittee } = this.props;
    const searchCommitteeUrl = generateDialogLink(
      DIALOG_TYPES.SEARCH_COMMITTEE,
    );
    const links = this.getLinks();
    const ALL_COMMITTEES = "All Committees";

    return (
      <div className="layout__side-menu">
        <div className="u-full-width">
          {/* {isConsultant && <SearchBar onSearch={this.onSearch} onClear={this.onClear}/>} */}
          <Link className="side-menu__committee-div" to={searchCommitteeUrl}>
            <Switch>
              <Route path="/all">{this.sideMenuTitle(ALL_COMMITTEES)}</Route>
              <Route path="/user">
                {selectedNationalCommittee
                  ? this.sideMenuTitle(selectedNationalCommittee)
                  : this.sideMenuTitle(ALL_COMMITTEES)}
              </Route>
              <Route path="/">
                {this.sideMenuTitle(selectedNationalCommittee)}
              </Route>
              {/* <Route
                path="/"
                component={(props) => <Navigation {...props} links={links} />}
              /> */}
            </Switch>
          </Link>
          <Switch>
            <Route
              path="/all"
              component={(props) => (
                <Navigation {...props} links={AggPageNavigationLinks} />
              )}
            />
            <Route
              path="/user"
              component={(props) =>
                selectedNationalCommittee ? (
                  <Navigation {...props} links={links} />
                ) : (
                  <Navigation {...props} links={AggPageNavigationLinks} />
                )
              }
            />
            <Route
              path="/"
              component={(props) => <Navigation {...props} links={links} />}
            />
          </Switch>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isConsultant: isConsultant(state),
  selectedNationalCommittee: selectedNationalCommittee(state),
});

export default connect(mapStateToProps, null)(SideMenu);
