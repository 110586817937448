import React, { Fragment } from "react";
import { withTranslation } from "react-i18next";
import Loader from "../../../../common/loader/Loader";
import CommentList from "../comment-list/List";
import { Link } from "react-router-dom";
import { sortComments } from "../../../../../services/comments/sort";
import { connect } from "react-redux";
import { compose } from "redux";
import { getMembers } from "../../../../../state/members/selectors";
import { userDetails, isConsultant } from "../../../../../state/user/selectors";
import { isProcessingCommentsRequest } from "../../../../../state/comments/selectors";
import { selectedNationalCommittee } from "../../../../../state/national-committees/selectors";
import { exportCommentsToWord } from "../../../../../state/comments/actions";
import OnlyMember from "../../../../common/only-member/OnlyMember";
import { TooltipWrapper } from "../../../../common/tooltip/Tooltip";
import {
  generateDialogLink,
  DIALOG_TYPES,
} from "../../../../../utils/dynamicLink";
import history from "../../../../../utils/history";
import ConfirmationModal from "../../../../common/confirmation-modal/ConfirmationModal";
import { applicationPushGlobalDialog } from "../../../../../state/app/actions";

class DeadlineCommentsTab extends React.Component {
  state = {
    comments: [],
    allExpanded: false,
    showModal: false,
  };

  componentDidMount() {
    const { comments } = this.props;
    if (comments && comments.length) {
      this.initializeComments();
    }
  }

  componentDidUpdate(prevProps) {
    const { comments, members } = this.props;
    if (prevProps.comments !== comments) {
      this.initializeComments();
    } else if (comments && prevProps.members !== members) {
      this.initializeComments();
    }
  }

  initializeComments = () => {
    this.setState({
      comments: this.props.comments.map((element) => {
        element.expanded = false;
        element.isSelected = false;
        let author = this.props.members.find(
          (member) => element.authorId === member.userID,
        );
        element.author = author ? author.name : "";
        return element;
      }),
    });
  };

  expandAllHandler = () => {
    this.setState({
      comments: this.state.comments.map((element) => {
        element.expanded = !this.state.allExpanded;
        return element;
      }),
      allExpanded: !this.state.allExpanded,
    });
  };

  onSortChange = (sortingType) => {
    this.setState({
      sortingType,
      comments: this.state.comments.sort((a, b) =>
        sortComments(a, b, sortingType),
      ),
    });
  };

  changeExpanded = (comment, index) => {
    const comments = this.state.comments;
    comments[index].expanded = !comments[index].expanded;

    this.setState({
      comments: [...comments],
    });
  };

  exportCommentsToWord = () => {
    this.props.exportCommentsToWord(this.props.uri);
  };

  directToCreateComment = () => {
    history.push(
      generateDialogLink(DIALOG_TYPES.CREATE_DEADLINE_COMMENT, this.props.uri),
    );
  };

  onAddComment = () => {
    const { t, date } = this.props;

    const deadlineDate = new Date(date);
    deadlineDate.setDate(deadlineDate.getDate() - 14);

    if (new Date() < deadlineDate) {
      this.directToCreateComment();
      return;
    }

    this.props.applicationPushGlobalDialog({
      component: ConfirmationModal,
      modalSettings: {
        allowEscape: true,
        closeButton: true,
      },
      props: {
        title: t("deadlines.details.lateCommentTitle"),
        message: t("deadlines.details.lateCommentDescription"),
        submitLabel: t("comments.buttons.newComment"),
        submitHandler: this.directToCreateComment,
        disableSlide: true,
      },
      disableSlide: true,
    });
  };

  render() {
    const { comments, allExpanded, sortingType } = this.state;
    const {
      t,
      isProcessingCommentsRequest,
      isDeadlineDatePassed,
      closedForComments,
      isConsultant,
    } = this.props;
    const disableCommenting = isDeadlineDatePassed && !isConsultant;

    return (
      <div className="tab-content">
        {comments && !isProcessingCommentsRequest ? (
          <Fragment>
            <div className="group group--first group--underlined buttons--medium-up u-align-right">
              {/* <button
                onClick={this.expandAllHandler}
                className="button button--small button--secondary"
              >
                {allExpanded
                  ? t("comments.buttons.collapseAll")
                  : t("comments.buttons.expandAll")}
              </button> */}
              <button
                className="button button--small button--secondary"
                onClick={this.exportCommentsToWord}
              >
                {t("comments.buttons.exportToWord")}
              </button>
              <OnlyMember allowConsultant>
                {disableCommenting || closedForComments ? (
                  <TooltipWrapper
                    extraClass="u-align-center"
                    content={
                      closedForComments
                        ? t("comments.buttons.disabledNewCommentToolTipBlock")
                        : t("comments.buttons.disabledNewCommentToolTip")
                    }
                  >
                    <span className="button button--small button--primary button--disabled">
                      {t("comments.buttons.newComment")}
                    </span>
                  </TooltipWrapper>
                ) : (
                  <button
                    className="button button--small button--primary"
                    onClick={this.onAddComment}
                  >
                    {t("comments.buttons.newComment")}
                  </button>
                )}
              </OnlyMember>
            </div>
            {comments && comments.length ? (
              <CommentList
                deadlineId={this.props.uri}
                comments={comments}
                manualSort={true}
                enableSelection={false}
                showActionBox={true}
                sortingType={sortingType}
                onSortChange={this.onSortChange}
                changeExpanded={this.changeExpanded}
                expandAllHandler={this.expandAllHandler}
              />
            ) : (
              <p>{t("comments.table.labels.noComments")}</p>
            )}
          </Fragment>
        ) : (
          <Loader type="content" />
        )}
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  members: getMembers(state),
  isProcessingCommentsRequest: isProcessingCommentsRequest(state),
  selectedNationalCommittee: selectedNationalCommittee(state),
  isConsultant: isConsultant(state),
});

const mapDispatchToProps = {
  exportCommentsToWord,
  applicationPushGlobalDialog,
};

export default compose(
  withTranslation("common"),
  connect(mapStateToProps, mapDispatchToProps),
)(DeadlineCommentsTab);
