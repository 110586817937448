import { handleActions } from "redux-actions";

import { getCommitteesSucceeded } from "./actions";
import { normalizeCommittees } from "./normalizers";

const intialState = {
  isFetching: false,
  isFetched: true,
  data: {},
};

const actionsHandler = {
  [getCommitteesSucceeded]: (state, { payload }) => ({
    isFetching: false,
    isFetched: true,
    data: normalizeCommittees(payload),
  }),
};

export default handleActions(actionsHandler, intialState);
