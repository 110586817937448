import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import Filters from "../../../../common/filters/Filters";
import {
  addFiltersToUrl,
  resetFilters,
  setDocumentsAreFiltered,
  setFilterValue,
} from "../../../../../state/document/actions";

import * as documentSelectors from "../../../../../state/document/selectors";
import * as valuesSelectors from "../../../../../state/values/selectors";
import { isConsultant } from "./../../../../../state/user/selectors";

class DocumentFilters extends Component {
  render() {
    return (
      <Filters
        //  init={this.props.initDocuments} //maybe it is needed
        isRefernece={this.props.isRefernece}
        addFiltersToUrl={this.props.addFiltersToUrl}
        resetFilters={this.props.resetFilters}
        filters={this.props.filters}
        selectedFilters={this.props.selectedFilters}
        selectFilterValue={this.props.setFilterValue}
        disabled={this.props.isProcessingDocuments}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  filters: valuesSelectors.getDocumentValues(state),
  selectedFilters: documentSelectors.getSelectedFilters(state),
  isProcessingDocuments: documentSelectors.isProcessingDocuments(state),
  isConsultant: isConsultant(state),
});

const mapDispatchToProps = {
  setFilterValue,
  addFiltersToUrl,
  resetFilters,
  setDocumentsAreFiltered,
};

export default compose(
  withTranslation("common"),
  connect(mapStateToProps, mapDispatchToProps),
)(DocumentFilters);
