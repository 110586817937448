export function normalizeUserSettings(settings) {
  return {
    [settings.nationalCommittee]: {
      emailSetting: settings.emailSetting,
      turnedOffCommittees: settings.turnedOffCommittees,
    },
  };
}

export function normalizeTurnOffCommittees(data, state) {
  return {
    ...state.data.settings,
    [data.selectedCommittee]: {
      ...state.data.settings[data.selectedCommittee],
      turnedOffCommittees: data.turnedOffCommittees,
    },
  };
}

export function normalizeTurnOffCommittee(data, state) {
  return {
    ...state.data.settings,
    [data.selectedCommittee]: {
      ...state.data.settings[data.selectedCommittee],
      turnedOffCommittees: [
        ...state.data.settings[data.selectedCommittee].turnedOffCommittees,
        data.turnedOffCommittee,
      ],
    },
  };
}

export function normalizeTurnOnCommittee(data, state) {
  return {
    ...state.data.settings,
    [data.selectedCommittee]: {
      ...state.data.settings[data.selectedCommittee],
      turnedOffCommittees: state.data.settings[
        data.selectedCommittee
      ].turnedOffCommittees.filter(
        (committee) => committee !== data.turnedOffCommittee,
      ),
    },
  };
}

export function normalizeEmailSetting(data, state) {
  return {
    ...state.data.settings,
    [data.selectedCommittee.id]: {
      ...state.data.settings[data.selectedCommittee.id],
      emailSetting: data.emailSetting,
    },
  };
}

export function normalizeUserData(data) {
  return {
    ...data,
    role: data.role.reduce((previous, current) => {
      previous[current.committee] = {
        name: current.role,
        memberSince: current.memberSince,
        unseenNotifications: current.unseenNotifications,
      };
      return previous;
    }, {}),
  };
}

export function normalizeRecentSearches(data) {
  return data.map((element) => ({ name: element }));
}
