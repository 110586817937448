import { createAction } from "redux-actions";

const INIT_NOTIFICATIONS = "NOTIFICATIONS/init_notifications";

const FETCH_NOTIFICATION = "NOTIFICATIONS/fetch_notification_data";
const FETCH_NOTIFICATION_SUCCEEDED =
  "NOTIFICATIONS/fetch_notification_succeeded";
const FETCH_NOTIFICATION_FAILED = "NOTIFICATIONS/fetch_notification_failed";

const FETCH_NOTIFICATION_REFERENCES =
  "NOTIFICATIONS/fetch_notification_references";
const FETCH_NOTIFICATION_REFERENCES_SUCCEEDED =
  "NOTIFICATIONS/fetch_notification_references_succeeded";
const FETCH_NOTIFICATION_REFERENCES_FAILED =
  "NOTIFICATIONS/fetch_notification_references_failed";

const FETCH_NOTIFICATION_DOCUMENTS =
  "NOTIFICATIONS/fetch_notification_documents";
const FETCH_NOTIFICATION_DOCUMENTS_SUCCEEDED =
  "NOTIFICATIONS/fetch_notification_documents_succeeded";
const FETCH_NOTIFICATION_DOCUMENTS_FAILED =
  "NOTIFICATIONS/fetch_notification_documents_failed";

const FETCH_UPDATED_NOTIFICATION_DOCUMENT =
  "NOTIFICATION_FORM/fetch_updated_document";
const FETCH_UPDATED_NOTIFICATION_DOCUMENT_SUCCEEDED =
  "NOTIFICATION_FORM/fetch_updated_document_succeeded";
const FETCH_UPDATED_NOTIFICATION_DOCUMENT_FAILED =
  "NOTIFICATION_FORM/fetch_updated_document_failed";

const REMOVE_REFERENCE_FROM_NOTIFICATION =
  "NOTIFICATION/remove_reference_from_notification";
const REMOVE_REFERENCE_FROM_NOTIFICATION_SUCCEEDED =
  "NOTIFICATION/remove_reference_from_notification_succeeded";

const UPDATE_NOTIFICATION = "NOTIFICATIONS/update_notification";
const UPDATE_NOTIFICATION_SUCCEEDED =
  "NOTIFICATIONS/update_notification_succeeded";
const UPDATE_NOTIFICATION_FAILED = "NOTIFICATIONS/update_notification_failed";

const DELETE_NOTIFICATION = "NOTIFICATIONS/delete_notification";
const DELETE_NOTIFICATION_SUCCEEDED =
  "NOTIFICATIONS/delete_notification_succeeded";
const DELETE_NOTIFICATION_FAILED = "NOTIFICATIONS/delete_notification_failed";

const CREATE_NOTIFICATION = "NOTIFICATIONS/create_notification";
const CREATE_NOTIFICATION_SUCCEEDED =
  "NOTIFICATIONS/create_notification_succeeded";
const CREATE_NOTIFICATION_FAILED = "NOTIFICATIONS/create_notification_failed";

const SET_SELECTED_NOTIFICATION_DOCUMENTS =
  "NOTIFICATION_FORM/set_selected_documents";
const SET_SELECTED_NOTIFICATION_DEADLINES =
  "NOTIFICATION_FORM/set_selected_deadlines";
const SET_SELECTED_NOTIFICATION_MEETINGS =
  "NOTIFICATION_FORM/set_selected_meetings";

const ADD_UPLOADED_NOTIFICATION_DOCUMENTS =
  "NOTIFICATION_FORM/add_uploaded_documents";
const ADD_GENERAL_NOTIFICATION_INFORMATION =
  "NOTIFICATION_FORM/add_general_info";

const INIT_NOTIFICATION_FORM_WITH_GENERAL_INFO_DATA =
  "NOTIFICATION_FORM/init_with_general_info_data";
const INIT_NOTIFICATION_FORM_WITH_DOCUMENTS =
  "NOTIFICATION_FORM/init_with_documents";
const CLEAR_NOTIFICATION_FORM = "NOTIFICATION_FORM/clear";

const CREATE_NOTIFICATION_FROM_DOCUMENT = "NOTIFICATION/create_from_document";
const CREATE_NOTIFICATION_FROM_MEETING = "NOTIFICATION/create_from_meeting";
const CREATE_NOTIFICATION_FROM_DEADLINE = "NOTIFICATION/create_from_deadline";
const DOWNLOAD_NOTIFICATION_DOCUMENTS =
  "NOTIFICATION_FORM/download_notification_documents";
const REMOVE_NOTIFICATION_DOCUMENT =
  "NOTIFICATION_FORM/remove_notification_document";
const UPDATE_NOTIFICATION_DOCUMENT_INFO =
  "NOTIFICATION_FORM/update_notification_document_info";

const SET_FILTER_VALUE = "FILTER/set_filter_values";

const RESET_NOTIFICATIONS_FILTERS = "FILTER/reset_notifications_filters";

const PULL_NEXT_NOTIFICATIONS_PAGE =
  "NOTIFICATIONS/pull_next_notifications_page";
const PULL_NOTIFICATIONS_PAGE_SUCCEEDED =
  "NOTIFICATIONS/pull_notifications_page_succeeded";
const PULL_NOTIFICATIONS_PAGE_FAILED =
  "NOTIFICATIONS/pull_notifications_page_failed";

export const initNotifications = createAction(INIT_NOTIFICATIONS);

export const fetchNotification = createAction(FETCH_NOTIFICATION);
export const fetchNotificationSucceeded = createAction(
  FETCH_NOTIFICATION_SUCCEEDED,
);
export const fetchNotificationFailed = createAction(FETCH_NOTIFICATION_FAILED);

export const fetchNotificationReferences = createAction(
  FETCH_NOTIFICATION_REFERENCES,
);
export const fetchNotificationReferencesSucceeded = createAction(
  FETCH_NOTIFICATION_REFERENCES_SUCCEEDED,
);
export const fetchNotificationReferencesFailed = createAction(
  FETCH_NOTIFICATION_REFERENCES_FAILED,
);

export const fetchNotificationDocuments = createAction(
  FETCH_NOTIFICATION_DOCUMENTS,
);
export const fetchNotificationDocumentsSucceeded = createAction(
  FETCH_NOTIFICATION_DOCUMENTS_SUCCEEDED,
);
export const fetchNotificationDocumentsFailed = createAction(
  FETCH_NOTIFICATION_DOCUMENTS_FAILED,
);

export const fetchUpdatedNotificationDocument = createAction(
  FETCH_UPDATED_NOTIFICATION_DOCUMENT,
);
export const fetchUpdatedNotificationDocumentSucceeded = createAction(
  FETCH_UPDATED_NOTIFICATION_DOCUMENT_SUCCEEDED,
);
export const fetchUpdatedNotificationDocumentFailed = createAction(
  FETCH_UPDATED_NOTIFICATION_DOCUMENT_FAILED,
);

export const removeReferenceFromNotification = createAction(
  REMOVE_REFERENCE_FROM_NOTIFICATION,
);
export const removeReferenceFromNotificationSucceeded = createAction(
  REMOVE_REFERENCE_FROM_NOTIFICATION_SUCCEEDED,
);

export const updateNotification = createAction(UPDATE_NOTIFICATION);
export const updateNotificationSucceeded = createAction(
  UPDATE_NOTIFICATION_SUCCEEDED,
);
export const updateNotificationFailed = createAction(
  UPDATE_NOTIFICATION_FAILED,
);

export const deleteNotification = createAction(DELETE_NOTIFICATION);
export const deleteNotificationSucceeded = createAction(
  DELETE_NOTIFICATION_SUCCEEDED,
);
export const deleteNotificationFailed = createAction(
  DELETE_NOTIFICATION_FAILED,
);

export const createNotification = createAction(CREATE_NOTIFICATION);
export const createNotificationSucceeded = createAction(
  CREATE_NOTIFICATION_SUCCEEDED,
);
export const createNotificationFailed = createAction(
  CREATE_NOTIFICATION_FAILED,
);

export const createNotificationFromDocument = createAction(
  CREATE_NOTIFICATION_FROM_DOCUMENT,
);

export const createNotificationFromMeeting = createAction(
  CREATE_NOTIFICATION_FROM_MEETING,
);

export const createNotificationFromDeadline = createAction(
  CREATE_NOTIFICATION_FROM_DEADLINE,
);

export const setSelectedNotificationDocuments = createAction(
  SET_SELECTED_NOTIFICATION_DOCUMENTS,
);
export const setSelectedNotificationDeadlines = createAction(
  SET_SELECTED_NOTIFICATION_DEADLINES,
);
export const setSelectedNotificationMeetings = createAction(
  SET_SELECTED_NOTIFICATION_MEETINGS,
);
export const addUploadedNotificationDocuments = createAction(
  ADD_UPLOADED_NOTIFICATION_DOCUMENTS,
);
export const addGeneralNotificationInformation = createAction(
  ADD_GENERAL_NOTIFICATION_INFORMATION,
);

export const initNotificationFormWithGeneralInfo = createAction(
  INIT_NOTIFICATION_FORM_WITH_GENERAL_INFO_DATA,
);
export const initNotificationFormWithDocuments = createAction(
  INIT_NOTIFICATION_FORM_WITH_DOCUMENTS,
);
export const clearNotificationForm = createAction(CLEAR_NOTIFICATION_FORM);

export const downloadNotificationDocuments = createAction(
  DOWNLOAD_NOTIFICATION_DOCUMENTS,
);
export const removeDocumentFromNotification = createAction(
  REMOVE_NOTIFICATION_DOCUMENT,
);
export const updateNotificationDocumentInfo = createAction(
  UPDATE_NOTIFICATION_DOCUMENT_INFO,
);

export const setFilterValue = createAction(SET_FILTER_VALUE);
export const resetNotificationsFilters = createAction(
  RESET_NOTIFICATIONS_FILTERS,
);

export const pullNextNotificationsPage = createAction(
  PULL_NEXT_NOTIFICATIONS_PAGE,
);
export const pullNotificationsPageSucceeded = createAction(
  PULL_NOTIFICATIONS_PAGE_SUCCEEDED,
);
export const pullNotificationsPageFailed = createAction(
  PULL_NOTIFICATIONS_PAGE_FAILED,
);

const GET_NOTIFICATION_DETAILS_FAILED =
  "NOTIFICATIONS/get_notification_details_failed";
const GET_NOTIFICATION_DETAILS_SUCCEEDED =
  "NOTIFICATIONS/get_notification_details_succeeded";

export const getNotificationDetailsSucceeded = createAction(
  GET_NOTIFICATION_DETAILS_SUCCEEDED,
);
export const getNotificationDetailsFailed = createAction(
  GET_NOTIFICATION_DETAILS_FAILED,
);

export const clearNotificationList = createAction("CLEAR_NOTIFICATION");

const PIN_NOTIFICATION = "NOTIFICATIONS/pin_notification";
const UNPIN_NOTIFICATION = "NOTIFICATIONS/unpin_notification";
const PIN_NOTIFICATION_SCUCCEEDED = "NOTIFICATIONS/pin_notification_succceeded";
const UNPIN_NOTIFICATION_SUCCEEDED =
  "NOTIFICATIONS/unpin_notification_succeeded";

export const pinNotification = createAction(PIN_NOTIFICATION);
export const unpinNotification = createAction(UNPIN_NOTIFICATION);
export const pinNotificationSucceeded = createAction(
  PIN_NOTIFICATION_SCUCCEEDED,
);
export const unpinNotificationSucceeded = createAction(
  UNPIN_NOTIFICATION_SUCCEEDED,
);

const MARK_NOTIFICATIONS_AS_SEEN = "NOTIFICATIONS/mark_notifications_as_seen";
const MARK_NOTIFICATIONS_AS_SEEN_SUCCEEDED =
  "NOTIFICATIONS/mark_notifications_as_seen_succeeded";

const MARK_ALL_NOTIFICATIONS_AS_SEEN =
  "NOTIFICATIONS/mark_all_notifications_as_seen";
const MARK_ALL_NOTIFICATIONS_AS_SEEN_SUCCEEDED =
  "NOTIFICATIONS/mark_all_notifications_as_seen_succeeded";
const MARK_ALL_NOTIFICATIONS_AS_SEEN_FAILED =
  "NOTIFICATIONS/mark_all_notifications_as_seen_failed";

export const markNotificationsAsSeen = createAction(MARK_NOTIFICATIONS_AS_SEEN);
export const markNotificationsAsSeenSucceeded = createAction(
  MARK_NOTIFICATIONS_AS_SEEN_SUCCEEDED,
);

export const markAllNotificationsAsSeen = createAction(
  MARK_ALL_NOTIFICATIONS_AS_SEEN,
);
export const markAllNotificationsAsSeenSucceeded = createAction(
  MARK_ALL_NOTIFICATIONS_AS_SEEN_SUCCEEDED,
);
export const markAllNotificationsAsSeenFailed = createAction(
  MARK_ALL_NOTIFICATIONS_AS_SEEN_FAILED,
);

const SEND_INSTANT_EMAIL = "USER/send_instant_email";
const SEND_INSTANT_EMAIL_SUCCEEDED = "USER/send_instant_email_succeeded";
const SEND_INSTANT_EMAIL_FAILED = "USER/send_instant_email_failed";

const VOTE_NOTIFICATION_POLL = "NOTIFICATIONS/vote_notification_poll";
const VOTE_NOTIFICATION_POLL_SUCCEEDED =
  "NOTIFICATIONS/vote_notification_poll_succeeded";
const VOTE_NOTIFICATION_POLL_FAILED =
  "NOTIFICATIONS/vote_notification_poll_failed";

const DELETE_VOTE_NOTIFICATION_POLL =
  "NOTIFICATIONS/delete_vote_notification_poll";
const DELETE_VOTE_NOTIFICATION_POLL_SUCCEEDED =
  "NOTIFICATIONS/delete_vote_notification_poll_succeeded";
const DELETE_VOTE_NOTIFICATION_POLL_FAILED =
  "NOTIFICATIONS/delete_vote_notification_poll_failed";

export const sendInstantEmail = createAction(SEND_INSTANT_EMAIL);
export const sendInstantEmailSucceeded = createAction(
  SEND_INSTANT_EMAIL_SUCCEEDED,
);
export const sendInstantEmailFailed = createAction(SEND_INSTANT_EMAIL_FAILED);

export const voteNotificationPoll = createAction(VOTE_NOTIFICATION_POLL);
export const voteNotificationPollSucceeded = createAction(
  VOTE_NOTIFICATION_POLL_SUCCEEDED,
);
export const voteNotificationPollFailed = createAction(
  VOTE_NOTIFICATION_POLL_FAILED,
);

export const deleteVoteNotificationPoll = createAction(
  DELETE_VOTE_NOTIFICATION_POLL,
);
export const deleteVoteNotificationPollSucceeded = createAction(
  DELETE_VOTE_NOTIFICATION_POLL_SUCCEEDED,
);
export const deleteVoteNotificationPollFailed = createAction(
  DELETE_VOTE_NOTIFICATION_POLL_FAILED,
);

const UPDATE_POLL = "NOTIFICATIONS/update_poll";
const UPDATE_POLL_SUCCEEDED = "NOTIFICATIONS/update_poll_succeeded";
const UPDATE_POLL_FAILED = "NOTIFICATIONS/update_poll_failed";

export const updatePoll = createAction(UPDATE_POLL);
export const updatePollSucceeded = createAction(UPDATE_POLL_SUCCEEDED);
export const updatePollFailed = createAction(UPDATE_POLL_FAILED);
