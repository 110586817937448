import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withTranslation } from "react-i18next";

import ModalWrapper from "../modal-wrapper/ModalWrapper";
import * as applicationActions from "../../../state/app/actions";
import * as applicationSelectors from "../../../state/app/selectors";

import DeadlineFromDocumentHint from "./DeadlineFromDocumentHint";
import DeadlinesListHint from "./DeadlinesListHint";
import { applicationUserHints } from "../../../utils/constants";

class UserHintDialog extends Component {
  state = {
    disableCurrentHint: false,
  };

  componentDidMount() {
    this.setState({ disableCurrentHint: false });
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.applicationUserHintState.messageToShow !==
      prevProps.applicationUserHintState.messageToShow
    ) {
      this.setState({ disableCurrentHint: false });
    }
  }

  onModalClose = () => {
    this.props.applicationCloseUserHint({
      disable: this.state.disableCurrentHint,
    });
  };

  renderModalContent() {
    switch (this.props.applicationUserHintState.messageToShow) {
      case applicationUserHints.createDeadlineFromDocument:
        return <DeadlineFromDocumentHint />;
      case applicationUserHints.listDeadlines:
        return <DeadlinesListHint />;
      default:
        return null;
    }
  }

  render() {
    return (
      <ModalWrapper
        isOpen={this.props.applicationUserHintState.messageToShow !== null}
        requestCloseHandler={this.onModalClose}
        disableSlide
      >
        <>
          {this.renderModalContent()}

          <div className="group group--btn">
            <div className="group group--field u-float-left">
              <div className="checkbox__group">
                <label htmlFor="disable-user-hint" className="inline-checkbox">
                  <input
                    className="inline-checkbox__input"
                    type="checkbox"
                    name="disable-user-hint"
                    checked={this.state.disableCurrentHint}
                    id="disable-user-hint"
                    onChange={() =>
                      this.setState({
                        disableCurrentHint: !this.state.disableCurrentHint,
                      })
                    }
                  />
                  <span className="inline-checkbox__input--off icon icon-radio-off"></span>
                  <span className="inline-checkbox__input--on icon icon-radio-on"></span>
                  <span className="u-text-medium-dark">
                    {this.props.t("userHints.general.disableHint")}
                  </span>
                </label>
              </div>
            </div>
            <div className="u-align-right">
              <button
                className="button button--primary"
                onClick={this.onModalClose}
              >
                {this.props.t("general.button.continue")}
              </button>
            </div>
          </div>
        </>
      </ModalWrapper>
    );
  }
}

const mapDispatchToProps = {
  applicationCloseUserHint: applicationActions.applicationCloseUserHint,
};

const mapStateToProps = (state) => ({
  applicationUserHintState:
    applicationSelectors.applicationUserHintState(state),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation("common"),
)(UserHintDialog);
