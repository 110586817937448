import React from "react";
import { Link } from "react-router-dom";

export default ({ selectedNationalCommittee }) => {
  return (
    <Link
      to={selectedNationalCommittee ? "/" + selectedNationalCommittee : "/"}
      className="layout__logo"
      dangerouslySetInnerHTML={{
        __html:
          '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" ' +
          'width="76px" height="36px" viewBox="0 0 76 38" version="1.1">\n' +
          '<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">\n' +
          '    <g transform="translate(-32.000000, -16.000000)" fill="#ffffff" fill-rule="nonzero">\n' +
          '        <g transform="translate(32.000000, 16.000000)">\n' +
          '            <g id="logo" transform="translate(0.647059, 0.000000)">\n' +
          '                <path fill="#ffffff" d="M52.8289474,0.846868091 L52.8289474,6.31893884 ' +
          "C51.1184211,5.47207074 48.9473684,5.01606485 46.8421053,5.40692704 C46.3157895,5.47207074 " +
          "45.7236842,5.92807664 45.6578947,6.51436993 C45.6578947,7.03551953 45.7894737,7.62181282 " +
          "46.25,7.81724392 C48.6184211,8.72925571 51.6447368,9.05497421 52.9605263,11.4652911 " +
          "C54.2763158,14.0058954 54.1447368,17.7842299 52.5,20.1945468 C49.6052632,23.9077377 " +
          "43.8157895,22.9957259 39.8026316,21.888283 L39.8026316,16.0904937 C41.9078947,17.1979366 " +
          "44.5394737,18.1099484 47.1052632,17.1327929 C47.7631579,16.8722181 47.8947368,16.0904937 " +
          "47.7631579,15.4390567 C46.6447368,14.0710391 44.6052632,14.2013265 43.0921053,13.3544584 " +
          "C40.5921053,12.3121592 39.5394737,9.7064112 39.6710526,7.03551953 C39.6052632,4.69034635 " +
          "40.4605263,2.34517318 42.5657895,1.10744289 C45.7236842,-0.390862196 49.5394737,-0.130287399 " +
          "52.8289474,0.846868091 L52.8289474,0.846868091 Z M33.5526316,0.651436993 C38.5526316,2.60574797 " +
          "38.2236842,8.46868091 38.1578947,13.0938836 C37.8947368,16.5464996 36.9078947,20.1945468 " +
          "33.5526316,21.7579956 C29.9342105,22.8002948 25.4605263,22.3442889 21.8421053,22.3442889 " +
          "L21.7105263,22.3442889 L21.7105263,0.260574797 L21.9078947,0.260574797 C25.8552632,0.390862196 " +
          "29.8684211,-0.0651436993 33.5526316,0.651436993 Z M27.8947368,17.0025055 C28.8157895,17.0025055 " +
          "29.7368421,17.1327929 30.3947368,16.7419307 C31.3815789,16.2859248 31.6447368,15.2436256 " +
          "31.8421053,14.2664702 C32.1052632,11.7258659 32.2368421,9.18526161 31.4473684,6.70980103 " +
          'C30.9210526,5.27663965 29.1447368,5.47207074 27.8947368,5.53721444 L27.8947368,17.0025055 Z" ' +
          'id="Shape" />\n' +
          '                <path fill="#ffffff" d="M0,0.325718497 C0,0.325718497 3.88157895,3.64804716 ' +
          "8.02631579,0.325718497 C8.02631579,0.325718497 11.9736842,3.64804716 16.0526316,0.325718497 " +
          "L16.0526316,5.21149595 C16.0526316,5.21149595 12.7631579,8.46868091 8.02631579,5.27663965 " +
          "C8.02631579,5.27663965 4.53947368,8.46868091 0.0657894737,5.21149595 L0,0.325718497 Z M0,8.46868091 " +
          "C0,8.46868091 3.88157895,11.7910096 8.02631579,8.46868091 C8.02631579,8.46868091 " +
          "11.9736842,11.7910096 16.0526316,8.46868091 L16.0526316,13.3544584 C16.0526316,13.3544584 " +
          "12.7631579,16.6116433 8.02631579,13.4196021 C8.02631579,13.4196021 4.53947368,16.6116433 " +
          "0.0657894737,13.3544584 L0,8.46868091 Z M0,16.5464996 C0,16.5464996 3.88157895,19.8688283 " +
          "8.02631579,16.5464996 C8.02631579,16.5464996 11.9736842,19.8688283 16.0526316,16.5464996 " +
          "L16.0526316,21.4322771 C16.0526316,21.4322771 12.7631579,24.689462 8.02631579,21.4974208 " +
          "C8.02631579,21.4974208 4.53947368,24.689462 0.0657894737,21.4322771 L0,16.5464996 Z " +
          "M58.9473684,0.260574797 C58.9473684,0.260574797 62.8289474,3.58290346 66.9736842,0.260574797 " +
          "C66.9736842,0.260574797 70.9210526,3.58290346 75,0.260574797 L75,5.14635225 C75,5.14635225 " +
          "71.7105263,8.40353721 66.9736842,5.21149595 C66.9736842,5.21149595 63.4868421,8.40353721 " +
          "59.0131579,5.14635225 L58.9473684,0.260574797 Z M58.9473684,8.46868091 C58.9473684,8.46868091 " +
          "62.8289474,11.7910096 66.9736842,8.46868091 C66.9736842,8.46868091 70.9210526,11.7910096 " +
          "75,8.46868091 L75,13.3544584 C75,13.3544584 71.7105263,16.6116433 66.9736842,13.4196021 " +
          "C66.9736842,13.4196021 63.4868421,16.6116433 58.9473684,13.3544584 L58.9473684,8.46868091 " +
          "Z M58.9473684,16.5464996 C58.9473684,16.5464996 62.8289474,19.8688283 66.9736842,16.5464996 " +
          "C66.9736842,16.5464996 70.9210526,19.8688283 75,16.5464996 L75,21.4322771 C75,21.4322771 " +
          "71.7105263,24.689462 66.9736842,21.4974208 C66.9736842,21.4974208 63.4868421,24.689462 " +
          '58.9473684,21.4322771 L58.9473684,16.5464996 Z" id="Shape" />\n' +
          '                <g fill="#ffffff" transform="translate(0.000000, 31.920413)">\n' +
          '                <path d="M43.6743421,4.68383198 L42.1611842,4.68383198 L41.8980263,5.72613117 ' +
          "L40.6480263,5.72613117 L42.2927632,0.644922623 L43.5427632,0.644922623 L45.1875,5.72613117 " +
          "L43.9375,5.72613117 L43.6743421,4.68383198 L43.6743421,4.68383198 Z M42.8848684,1.88265291 " +
          "C42.8848684,1.88265291 42.7532895,2.40380251 42.6875,2.729521 L42.4243421,3.70667649 " +
          "L43.4111842,3.70667649 L43.1480263,2.729521 C43.0164474,2.40380251 42.9506579,1.88265291 " +
          "42.8848684,1.88265291 L42.8848684,1.88265291 Z M46.8322368,0.644922623 L48.0164474,0.644922623 " +
          "L49.3322368,2.9900958 C49.5296053,3.3158143 49.7269737,3.83696389 49.7269737,3.83696389 " +
          "C49.7269737,3.83696389 49.6611842,3.3158143 49.6611842,2.9900958 L49.6611842,0.644922623 " +
          "L50.8453947,0.644922623 L50.8453947,5.72613117 L49.6611842,5.72613117 L48.2796053,3.380958 " +
          "C48.0822368,3.0552395 47.8848684,2.5340899 47.8848684,2.5340899 C47.8848684,2.5340899 " +
          "47.9506579,3.0552395 47.9506579,3.380958 L47.9506579,5.72613117 L46.7664474,5.72613117 " +
          "L46.7664474,0.644922623 L46.8322368,0.644922623 Z M11.8322368,0.644922623 L13.0164474,0.644922623 " +
          "L14.3322368,2.9900958 C14.5296053,3.3158143 14.7269737,3.83696389 14.7269737,3.83696389 " +
          "C14.7269737,3.83696389 14.6611842,3.3158143 14.6611842,2.9900958 L14.6611842,0.644922623 " +
          "L15.8453947,0.644922623 L15.8453947,5.72613117 L14.6611842,5.72613117 L13.2796053,3.380958 " +
          "C13.0822368,3.0552395 12.8848684,2.5340899 12.8848684,2.5340899 C12.8848684,2.5340899 " +
          "12.9506579,3.0552395 12.9506579,3.380958 L12.9506579,5.72613117 L11.7664474,5.72613117 " +
          "L11.7664474,0.644922623 L11.8322368,0.644922623 Z M53.2138158,0.644922623 L54.9243421,0.644922623 " +
          "C56.4375,0.644922623 57.4243421,1.55693441 57.4243421,3.1855269 C57.4243421,4.74897568 " +
          "56.4375,5.72613117 54.9243421,5.72613117 L53.2138158,5.72613117 L53.2138158,0.644922623 Z " +
          "M54.8585526,4.61868828 C55.6480263,4.61868828 56.1743421,4.09753869 56.1743421,3.1203832 " +
          "C56.1743421,2.14322771 55.6480263,1.68722181 54.8585526,1.68722181 L54.3980263,1.68722181 " +
          "L54.3980263,4.61868828 L54.8585526,4.61868828 Z M61.8322368,4.68383198 L60.3190789,4.68383198 " +
          "L60.0559211,5.72613117 L58.8059211,5.72613117 L60.4506579,0.644922623 L61.7006579,0.644922623 " +
          "L63.3453947,5.72613117 L62.0953947,5.72613117 L61.8322368,4.68383198 L61.8322368,4.68383198 Z " +
          "M61.1085526,1.88265291 C61.1085526,1.88265291 60.9769737,2.40380251 60.9111842,2.729521 " +
          "L60.6480263,3.70667649 L61.6348684,3.70667649 L61.3717105,2.729521 C61.2401316,2.40380251 " +
          "61.1085526,1.88265291 61.1085526,1.88265291 L61.1085526,1.88265291 Z M8.80592105,4.68383198 " +
          "L7.29276316,4.68383198 L7.02960526,5.72613117 L5.77960526,5.72613117 L7.42434211,0.644922623 " +
          "L8.67434211,0.644922623 L10.3190789,5.72613117 L9.06907895,5.72613117 L8.80592105,4.68383198 " +
          "L8.80592105,4.68383198 Z M8.08223684,1.88265291 C8.08223684,1.88265291 7.95065789,2.40380251 " +
          "7.88486842,2.729521 L7.62171053,3.70667649 L8.60855263,3.70667649 L8.34539474,2.729521 " +
          "C8.21381579,2.40380251 8.08223684,1.88265291 8.08223684,1.88265291 L8.08223684,1.88265291 Z " +
          "M65.0559211,0.644922623 L66.7664474,0.644922623 C67.2927632,0.644922623 67.4901316,0.710066323 " +
          "67.6875,0.775210022 C68.2138158,0.97064112 68.6085526,1.49179071 68.6085526,2.27351511 " +
          "C68.6085526,2.7946647 68.3453947,3.380958 67.8848684,3.64153279 C67.8848684,3.64153279 " +
          "67.9506579,3.70667649 68.0822368,3.96725129 L69.0032895,5.72613117 L67.6875,5.72613117 " +
          "L66.8322368,4.03239499 L66.3059211,4.03239499 L66.3059211,5.72613117 L65.1217105,5.72613117 " +
          "L65.1217105,0.644922623 L65.0559211,0.644922623 L65.0559211,0.644922623 Z M66.7664474,2.9249521 " +
          "C67.1611842,2.9249521 67.3585526,2.729521 67.3585526,2.27351511 C67.3585526,1.88265291 " +
          "67.2269737,1.68722181 66.6348684,1.68722181 L66.2401316,1.68722181 L66.2401316,2.9249521 " +
          "L66.7664474,2.9249521 L66.7664474,2.9249521 Z M70.7796053,0.644922623 L72.4901316,0.644922623 " +
          "C74.0032895,0.644922623 74.9901316,1.55693441 74.9901316,3.1855269 C74.9901316,4.74897568 " +
          "74.0032895,5.72613117 72.4901316,5.72613117 L70.7796053,5.72613117 L70.7796053,0.644922623 Z " +
          "M72.4243421,4.61868828 C73.2138158,4.61868828 73.7401316,4.09753869 73.7401316,3.1203832 " +
          "C73.7401316,2.14322771 73.2138158,1.68722181 72.4243421,1.68722181 L71.9638158,1.68722181 " +
          'L71.9638158,4.61868828 L72.4243421,4.61868828 Z" id="Shape" />\n' +
          '                <path d="M25.6578947,0.651436993 L24.5394737,2.67089167 L23.9473684,2.67089167 ' +
          "L23.9473684,0.651436993 L22.5657895,0.651436993 L22.5657895,5.79778924 L23.9473684,5.79778924 " +
          "L23.9473684,3.71319086 L24.5394737,3.71319086 L25.7236842,5.79778924 L27.2368421,5.79778924 " +
          "L25.6578947,3.12689757 L27.1710526,0.651436993 L25.6578947,0.651436993 Z M31.3815789,4.10405306 " +
          "C31.3815789,4.10405306 32.0394737,4.62520265 32.6973684,4.62520265 C32.9605263,4.62520265 " +
          "33.2894737,4.56005895 33.2894737,4.23434046 C33.2894737,3.64804716 30.7236842,3.64804716 " +
          "30.7236842,2.14974208 C30.7236842,1.23773029 31.5789474,0.651436993 32.6973684,0.651436993 " +
          "C33.8815789,0.651436993 34.5394737,1.23773029 34.5394737,1.23773029 L33.9473684,2.21488578 " +
          "C33.9473684,2.21488578 33.3552632,1.75887988 32.6973684,1.75887988 C32.4342105,1.75887988 " +
          "32.1052632,1.88916728 32.1052632,2.14974208 C32.1052632,2.80117907 34.6710526,2.67089167 " +
          "34.6710526,4.23434046 C34.6710526,5.08120855 33.9473684,5.79778924 32.6973684,5.79778924 " +
          "C31.3815789,5.79778924 30.6578947,5.08120855 30.6578947,5.08120855 L31.3815789,4.10405306 " +
          "L31.3815789,4.10405306 Z M17.8289474,4.10405306 C17.8289474,4.10405306 18.4868421,4.62520265 " +
          "19.1447368,4.62520265 C19.4078947,4.62520265 19.7368421,4.56005895 19.7368421,4.23434046 " +
          "C19.7368421,3.64804716 17.1710526,3.64804716 17.1710526,2.14974208 C17.1710526,1.23773029 " +
          "18.0263158,0.651436993 19.1447368,0.651436993 C20.3289474,0.651436993 20.9868421,1.23773029 " +
          "20.9868421,1.23773029 L20.3947368,2.21488578 C20.3947368,2.21488578 19.8026316,1.75887988 " +
          "19.1447368,1.75887988 C18.8815789,1.75887988 18.5526316,1.88916728 18.5526316,2.14974208 " +
          "C18.5526316,2.80117907 21.1184211,2.67089167 21.1184211,4.23434046 C21.1184211,5.08120855 " +
          "20.3947368,5.79778924 19.1447368,5.79778924 C17.8289474,5.79778924 17.1052632,5.08120855 " +
          "17.1052632,5.08120855 L17.8289474,4.10405306 L17.8289474,4.10405306 Z M35.8552632,0.651436993 " +
          "L35.8552632,1.75887988 L37.5,1.75887988 L37.5,5.79778924 L38.8157895,5.79778924 " +
          'L38.8157895,1.75887988 L40.4605263,1.75887988 L40.4605263,0.651436993 L35.8552632,0.651436993 Z" ' +
          'id="Shape" />\n' +
          '                <path d="M0.592105263,0.651436993 L2.30263158,0.651436993 C3.81578947,0.651436993 ' +
          "4.80263158,1.56344878 4.80263158,3.19204127 C4.80263158,4.75549005 3.81578947,5.73264554 " +
          "2.30263158,5.73264554 L0.592105263,5.73264554 L0.592105263,0.651436993 Z M2.23684211,4.62520265 " +
          "C3.02631579,4.62520265 3.55263158,4.10405306 3.55263158,3.12689757 C3.55263158,2.14974208 " +
          "3.02631579,1.69373618 2.23684211,1.69373618 L1.77631579,1.69373618 L1.77631579,4.62520265 " +
          'L2.23684211,4.62520265 Z" id="Shape" />\n' +
          "            </g>\n" +
          "        </g>\n" +
          "    </g>\n" +
          "</g>\n" +
          "</g>\n" +
          "</svg>",
      }}
    ></Link>
  );
};
