import React from "react";
import classNames from "classnames";
import { withTranslation } from "react-i18next";

import Card from "../../../../common/card/Card";
import CardHeader from "../../../../common/card/CardHeader";
import CardContent from "../../components/card-content/CardContent";
import CardAttachments from "../../../../common/card/CardAttachments";

import { formatEventDate, transformUri } from "../../../../../utils/transform";
import {
  generateDialogLink,
  DIALOG_TYPES,
} from "../../../../../utils/dynamicLink";

class MeetingCard extends React.Component {
  get referencesSize() {
    const { meeting } = this.props;
    const documentSize =
      meeting.referencesRawResponsePerType.document?.length ?? 0;
    const deadlineSize =
      meeting.referencesRawResponsePerType.deadline?.length ?? 0;
    return documentSize + deadlineSize;
  }

  render() {
    const { meeting, markedAsRemoved, customActions, callbackAction } =
      this.props;
    const date = new Date(meeting.fromDate);
    const meetingDetailsUrl = generateDialogLink(
      DIALOG_TYPES.MEETING_DETAILS,
      meeting.uri,
    );

    return (
      <Card
        date={date}
        withOffset={true}
        className={classNames("meeting-card", {
          // "card__highlight": transformedUri === this.state.lastOppendMeetingId,
          card__inactive: markedAsRemoved,
        })}
      >
        <CardHeader
          id={meeting.id}
          uri={meetingDetailsUrl}
          date={date}
          title={meeting.title}
          committee={meeting.committee}
          committeeName={meeting.committeeName}
          callbackAction={callbackAction}
          customActions={customActions}
        />
        <CardContent
          date={formatEventDate(meeting.fromDate)}
          location={meeting.locationShort}
        />
        <CardAttachments
          uri={meetingDetailsUrl}
          references={this.referencesSize}
          comments={meeting.comments && meeting.comments.length}
        />
      </Card>
    );
  }
}

export default withTranslation("common")(MeetingCard);
