import { Component } from "react";
import { connect } from "react-redux";
import { isConsultantInAnyCommittee } from "./../../../state/user/selectors";

class WithPrivileges extends Component {
  render() {
    const { children, isConsultantInAnyCommittee } = this.props;
    return isConsultantInAnyCommittee ? children : null;
  }
}

const mapStateToProps = (state) => ({
  isConsultantInAnyCommittee: isConsultantInAnyCommittee(state),
});

export default connect(mapStateToProps)(WithPrivileges);
