import { handleActions } from "redux-actions";
import * as actions from "./actions";

const initialState = {
  modalStack: [],
};

const actionHandlers = {
  [actions.emptyModalStack]: (state, action) => ({
    ...state,
    modalStack: [],
  }),
  [actions.pushModalStack]: (state, action) => ({
    ...state,
    modalStack: [...state.modalStack, action.payload],
  }),
  [actions.popModalStack]: (state, action) => ({
    ...state,
    modalStack: state.modalStack.slice(0, -1),
  }),
};

export default handleActions(actionHandlers, initialState);
