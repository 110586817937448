import { createAction } from "redux-actions";

const FETCH_VALUES = "VALUES/fetch_values";
export const FETCH_VALUES_FINISHED = "VALUES/fetch_values_finished";

const FETCHING_DOCUMENT_FILTER_VALUES_SUCCEEDED =
  "VALUES/fetching_document_filters_succeeded";
const FETCHING_DOCUMENT_FILTER_VALUES_FAILED =
  "VALUES/fetching_document_filters_failed";

const FETCHING_DEADLINE_FILTER_VALUES_FAILED =
  "VALUES/fetching_deadline_filters_failed";
const FETCHING_DEADLINE_FILTER_VALUES_SUCCEEDED =
  "VALUES/fetching_deadline_filters_succeeded";

const FETCHING_MEETING_FILTER_VALUES_FAILED =
  "VALUES/fetching_meeting_filters_failed";
const FETCHING_MEETING_FILTER_VALUES_SUCCEEDED =
  "VALUES/fetching_meeting_filters_succeeded";

export const fetchAllValues = createAction(FETCH_VALUES);
export const fetchAllValuesFinished = createAction(FETCH_VALUES_FINISHED);

export const fetchingDocumentFilterValuesSucceeded = createAction(
  FETCHING_DOCUMENT_FILTER_VALUES_SUCCEEDED,
);
export const fetchingDocumentFilterValuesFailed = createAction(
  FETCHING_DOCUMENT_FILTER_VALUES_FAILED,
);

export const fetchingDeadlineFilterValuesSucceeded = createAction(
  FETCHING_DEADLINE_FILTER_VALUES_SUCCEEDED,
);
export const fetchingDeadlineFilterValuesFailed = createAction(
  FETCHING_DEADLINE_FILTER_VALUES_FAILED,
);

export const fetchingMeetingFilterValuesSucceeded = createAction(
  FETCHING_MEETING_FILTER_VALUES_SUCCEEDED,
);
export const fetchingMeetingFilterValuesFailed = createAction(
  FETCHING_MEETING_FILTER_VALUES_FAILED,
);

const GET_STAGE_CODES_SUCCEEDED = "VALUE/get_stage_codes_succeeded";
const GET_STAGE_CODES_FAILED = "VALUE/get_stage_codes_failed";

export const getStageCodesSucceeded = createAction(GET_STAGE_CODES_SUCCEEDED);
export const getStageCodesFailed = createAction(GET_STAGE_CODES_FAILED);

const GET_COMMENT_TYPES_SUCCEEDED = "VALUE/get_comment_types_succeeded";
const GET_COMMENT_TYPES_FAILED = "VALUE/get_comment_types_failed";

export const getCommentTypesSucceeded = createAction(
  GET_COMMENT_TYPES_SUCCEEDED,
);
export const getCommentTypesFailed = createAction(GET_COMMENT_TYPES_FAILED);

const CLEAR_MEETINGS_FILTER_VALUES = "VALUE/CLEAR_MEETINGS_FILTER_VALUES";
export const clearMeetingsFilterValues = createAction(
  CLEAR_MEETINGS_FILTER_VALUES,
);
