import { takeEvery, call, put, select, all } from "redux-saga/effects";
import {
  getUserCommittees,
  getAssociatedCommittees,
  saveLastCommittee,
} from "../api/resources/committees";
import * as actions from "../state/national-committees/actions";
import {
  nationalCommittees,
  selectedNationalCommittee,
  userNationalCommittees,
} from "../state/national-committees/selectors";
import { fetchUserSettings } from "./user";

// import AnalyticsTtracking from "../services/analytics-tracking/analytics-tracking";

export function* fetchNationalCommittees() {
  try {
    const results = yield call(getUserCommittees);

    if (results.status === 200) {
      yield put(actions.getNationalCommitteesSucceeded(results.data));
    } else {
      yield put(
        actions.getNationalCommitteesFailed(
          "Getting user national committees failed",
        ),
      );
    }
  } catch (error) {
    console.error(`fetch national committees failed - ${error}`);
  }
}

export function* fetchAssociatedCommitteesByCommitteeId(action) {
  try {
    const { committeeId } = action.payload;
    const response = yield call(getAssociatedCommittees, committeeId);

    if (response.status === 200) {
      yield put(actions.getAssociatedCommitteesSucceeded(response.data));
    } else {
      yield put(actions.getAssociatedCommitteesFailed(response.data.message));
    }
  } catch (error) {
    console.error(`fetch associated committee by committee failed - ${error}`);
  }
}

export function* fetchAssociatedCommittees() {
  try {
    const parentCommitteeIds = (yield select(userNationalCommittees)).map(
      (committee) => committee.id,
    );

    if (parentCommitteeIds) {
      const responseList = yield all(
        parentCommitteeIds.map((parentCommitteeId) =>
          call(getAssociatedCommittees, parentCommitteeId),
        ),
      );

      for (const response of responseList) {
        if (response.status === 200) {
          yield put(actions.getAssociatedCommitteesSucceeded(response.data));
        } else {
          yield put(
            actions.getAssociatedCommitteesFailed(response.data.message),
          );
        }
      }
    }

    // const parentCommittee = yield select(selectedNationalCommittee);
    // if (parentCommittee) {
    //   // here

    //   const result = yield call(getAssociatedCommittees, parentCommittee);

    //   if (result.status === 200) {
    //     yield put(actions.getAssociatedCommitteesSucceeded(result.data));
    //   } else {
    //     yield put(actions.getAssociatedCommitteesFailed(result.data.message));
    //   }
    // }
  } catch (error) {
    console.error(`fetch associated committee failed - ${error}`);
  }
}

// I think this is not used
export function* fetchCommitteesList() {
  try {
    const parentCommittee = yield select(selectedNationalCommittee);
    if (parentCommittee) {
      // or here
      const result = yield call(getAssociatedCommittees, parentCommittee);
      if (result.status === 200) {
        yield put(actions.getCommitteesListSucceeded(result.data));
      } else {
        yield put(actions.getCommitteesListFailed(result.data.message));
      }
    }
  } catch (error) {
    console.error(`fetch committee list failed - ${error}`);
  }
}

export function* setSelectedNationalCommittee(action) {
  try {
    const response = yield call(saveLastCommittee, action.payload);

    if (response.status === 200) {
      //todo:: create separate action for saving lastOpenedCommittee
      //yield put(updateUserDetailsSucceeded({kundeportal: {lastOpenedCommittee: action.payload }}));
    } else {
      yield put(actions.saveLastCommitteeFailed(response.data.message));
    }
  } catch (error) {
    console.error(`set selected national committee failed - ${error}`);
    yield put(actions.saveLastCommitteeFailed(error.message));
  }
}

export function* nationalCommitteesSaga() {
  yield takeEvery(actions.GET_ASSOCIATED_COMMITTEES, fetchAssociatedCommittees);
  yield takeEvery(actions.GET_COMMITTEES_LIST, fetchCommitteesList);
  yield takeEvery(
    actions.SET_SELECTED_NATIONAL_COMMITTEE,
    setSelectedNationalCommittee,
  );
  yield takeEvery(actions.SET_LAST_COMMITTEE_SUCCEEDED, fetchUserSettings);
  yield takeEvery(
    actions.GET_ASSOCIATED_COMMITTEES_BY_COMMITTEE_ID,
    fetchAssociatedCommitteesByCommitteeId,
  );
}
