import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { rawUserNationalCommittees } from "../../../state/national-committees/selectors";
import { userSettings } from "../../../state/user/selectors";
import { withTranslation } from "react-i18next";
import CommitteeSettingBlock from "./CommitteeSettingBlock";

class CommitteeSettings extends Component {
  generateCommitteeSettingBlock = (committeeId) => {
    const { rawUserNationalCommittees, userSettings } = this.props;

    const disabledInsideCommittees = userSettings[committeeId]
      ? userSettings[committeeId].turnedOffCommittees
      : [];
    const insideCommittees =
      rawUserNationalCommittees[committeeId]["internationalCommittees"] ?? [];

    return (
      <CommitteeSettingBlock
        key={committeeId}
        committeeId={committeeId}
        insideCommittees={insideCommittees}
        turnedOffCommittees={disabledInsideCommittees}
      />
    );
  };

  render() {
    const { rawUserNationalCommittees } = this.props;

    const userNationalCommitteeKeys = Object.keys(
      rawUserNationalCommittees,
    ).sort();

    return (
      <div>
        {userNationalCommitteeKeys.map(this.generateCommitteeSettingBlock)}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  rawUserNationalCommittees: rawUserNationalCommittees(state),
  userSettings: userSettings(state),
});

const mapDispatchToProps = {};

export default compose(
  withTranslation("common"),
  connect(mapStateToProps, mapDispatchToProps),
)(CommitteeSettings);
