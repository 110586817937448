import React, { Component, Fragment } from "react";
import { Switch, Redirect } from "react-router-dom";
import { compose } from "redux";
import { connect } from "react-redux";

import MeetingsTab from "./pages/meeting-tab/MeetingTab";
import { resetFiltersForm } from "../../../state/document/actions";
import { generateDialogLink, DIALOG_TYPES } from "../../../utils/dynamicLink";

class Meetings extends Component {
  componentWillUnmount() {
    this.props.resetFiltersForm();
  }

  render() {
    return (
      <Fragment>
        <MeetingsTab />
        <Switch>
          <Redirect
            from={`${this.props.match.path}/:meetingUri/details*`}
            to={`${this.props.match.path}/${generateDialogLink(
              DIALOG_TYPES.MEETING_DETAILS,
              ":meetingUri",
            )}.xml`}
          />
        </Switch>
      </Fragment>
    );
  }
}

const mapDispatchToProps = {
  resetFiltersForm,
};

export default compose(connect(null, mapDispatchToProps))(Meetings);
