import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import SortBox from "../sort-box/SortBox";
import classNames from "classnames";
import { sortingType } from "../../../services/documents/url";
import { sortBoxHidingDelay } from "../../../utils/constants";
import WithPrivileges from "../with-privileges/WithPrivileges";
import ReadonlyGuard from "../readonly-guard/ReadonlyGuard";
import { DOCUMENT_TABLE_COLUMNS, isColumnDisabled } from "./List";

const sortBoxElements = {
  date: [
    {
      name: "documents.sort.date.ascending",
      value: sortingType.date_ascending,
    },
    {
      name: "documents.sort.date.descending",
      value: sortingType.date_descending,
    },
  ],
  dueDate: [
    {
      name: "documents.sort.dueDate.ascending",
      value: sortingType.due_date_ascending,
    },
    {
      name: "documents.sort.dueDate.descending",
      value: sortingType.due_date_descending,
    },
  ],
  documentId: [
    {
      name: "documents.sort.documentId.ascending",
      value: sortingType.document_id_ascenting,
    },
    {
      name: "documents.sort.documentId.descending",
      value: sortingType.document_id_descending,
    },
  ],
};

const Header = (props) => {
  const [openSortBox, setOpenSortBox] = useState("");
  const {
    t,
    disabledColumns,
    disableSort,
    sortingType: currentSortingType,
    onSortChange,
  } = props;

  const toggleSortBox = (boxName) => (e) => {
    e.preventDefault();
    setOpenSortBox((prevState) => (prevState === boxName ? "" : boxName));
  };

  const closeSortBoxes = () => setOpenSortBox("");

  const handleSortChange = (sortingType) => {
    setTimeout(closeSortBoxes, sortBoxHidingDelay);
    onSortChange(sortingType);
  };

  const getSortableButtonClassNames = (sortableElements) => {
    const descendingSortingTypes = [
      sortingType.due_date_descending,
      sortingType.date_descending,
      sortingType.document_id_descending,
    ];

    const ascendingSortingTypes = [
      sortingType.due_date_ascending,
      sortingType.date_ascending,
      sortingType.document_id_ascenting,
    ];

    return classNames("button button--link button--no-border sortable", {
      "sortable-dsc":
        sortableElements.some(
          (element) => element.value === currentSortingType,
        ) && descendingSortingTypes.includes(currentSortingType),
      "sortable-asc":
        sortableElements.some(
          (element) => element.value === currentSortingType,
        ) && ascendingSortingTypes.includes(currentSortingType),
    });
  };

  const renderColumn = (
    columnName,
    sortBoxKey,
    translateColumnName,
    className,
    notSortable = false,
  ) => {
    if (isColumnDisabled(disabledColumns, DOCUMENT_TABLE_COLUMNS[columnName]))
      return null;

    const isSortDisabled = disableSort || notSortable;
    const sortBoxIsOpen = openSortBox === sortBoxKey;
    const elements = sortBoxElements[sortBoxKey];

    const text = t(`documents.list.header.${translateColumnName}`);

    return (
      <th className={className}>
        {isSortDisabled ? (
          text
        ) : (
          <>
            <button
              className={getSortableButtonClassNames(elements)}
              onClick={toggleSortBox(sortBoxKey)}
            >
              {text}
            </button>
            <SortBox
              elements={elements}
              onSelectionChange={handleSortChange}
              selected={currentSortingType}
              onClickOutside={closeSortBoxes}
              isVisible={sortBoxIsOpen}
            />
          </>
        )}
      </th>
    );
  };

  return (
    <tr className="table__header documents-table__header">
      {props.enableSelection && (
        <WithPrivileges>
          <th className="id"></th>
        </WithPrivileges>
      )}
      {renderColumn("COMMITTEE", "documentId", "committee_id", "committee")}
      {renderColumn("TITLE", "", "title_fileName", "name", true)}
      {renderColumn("ACTION_TYPE", "", "action_type", "action", true)}
      {props.showConnections && (
        <th className="related-content">
          {t("documents.list.header.relatedContent")}
        </th>
      )}
      {renderColumn("DUE_DATE", "dueDate", "dueDate", "date")}
      {renderColumn("DATE", "date", "date", "date")}
      {props.showActionBox && (
        <WithPrivileges>
          <ReadonlyGuard>
            <th className="last"></th>
          </ReadonlyGuard>
        </WithPrivileges>
      )}
    </tr>
  );
};

export default withTranslation("common")(Header);
