import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import className from "classnames";
import { reviewStageValue, voteType } from "../../../../utils/constants";

class VoteAttachment extends Component {
  render() {
    const { stage, votes, userVote, message } = this.props;

    if (!votes) return null;

    const markVote = (type) => ({ "card__votes--mark": userVote === type });

    if (stage === reviewStageValue) {
      return (
        <div className="card__votes">
          {message && <span>{message}</span>}
          <div
            className={className(
              "card__votes-num card__votes--confirm",
              markVote(voteType.confirm),
            )}
          >
            <p>{votes.confirm}</p>
          </div>
          <div
            className={className(
              "card__votes-num card__votes--revise",
              markVote(voteType.revise),
            )}
          >
            <p>{votes.revise}</p>
          </div>
          <div
            className={className(
              "card__votes-num card__votes--withdraw",
              markVote(voteType.withdraw),
            )}
          >
            <p>{votes.withdraw}</p>
          </div>
          <div
            className={className(
              "card__votes-num card__votes--abstain",
              markVote(voteType.abstain),
            )}
          >
            <p>{votes.abstain}</p>
          </div>
        </div>
      );
    }

    return (
      <div className="card__votes">
        {message && <span>{message}</span>}
        <div
          className={className(
            "card__votes-num card__votes--yes",
            markVote(voteType.yes),
          )}
        >
          <p>{votes.yes}</p>
        </div>
        <div
          className={className(
            "card__votes-num card__votes--no",
            markVote(voteType.no),
          )}
        >
          <p>{votes.no}</p>
        </div>
        <div
          className={className(
            "card__votes-num card__votes--abstain",
            markVote(voteType.abstain),
          )}
        >
          <p>{votes.abstain}</p>
        </div>
      </div>
    );
  }
}

export default withRouter(withTranslation("common")(VoteAttachment));
