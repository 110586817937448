export function normalizeStandards(result) {
  return result.data.reduce((previous, current) => {
    const standard = {
      committee: current.committee,
      date: current.date,
      title: current.title,
      link: current.link,
    };

    previous[result.committee]
      ? previous[result.committee].push(standard)
      : (previous[result.committee] = [standard]);

    return previous;
  }, {});
}
