import { createSelector } from "reselect";
import { selectedNationalCommittee } from "../national-committees/selectors";
import { roleType, defaultLocale, appLocaleMap } from "../../utils/constants";

const user = (state) => state.user;
export const userSettings = (state) => state.user.data.settings;

export const userDetails = createSelector(user, (user) => user.data);
export const userCommittees = createSelector(
  userDetails,
  (userDetails) => userDetails.role,
);
export const userId = createSelector(user, (user) => user.data.id);
export const userFullName = createSelector(user, (user) => user.data.name);
export const apiResponse = createSelector(user, (user) => user.apiResponse);
export const lastOpenedCommittee = createSelector(
  user,
  (user) => user.data.kundeportal.lastOpenedCommittee,
);
export const updateInProgress = createSelector(
  user,
  (user) => user.updateInProgress,
);
export const updatePhotoInProgress = createSelector(
  user,
  (user) => user.updatePhotoInProgress,
);
export const getUserSetting = createSelector([userSettings], (userSettings) => {
  return userSettings;
});

const consultantRoles = [roleType.consultant, roleType.professional];

export const isConsultant = createSelector([userDetails], (userDetails) => {
  if (!userDetails.role) {
    return false;
  }

  const committees = Object.keys(userDetails.role);
  return committees.some((committee) =>
    consultantRoles.includes(userDetails.role[committee].name),
  );
});

export const isConsultantInCurrentCommittee = createSelector(
  [userDetails, selectedNationalCommittee],
  (userDetails, selectedNationalCommittee) => {
    if (!userDetails.role || !userDetails.role[selectedNationalCommittee]) {
      return false;
    }

    const currentCommitteeRole =
      userDetails.role[selectedNationalCommittee].name;

    return consultantRoles.includes(currentCommitteeRole);
  },
);

export const isConsultantInAnyCommittee = createSelector(
  [userDetails],
  (userDetails) => {
    if (!userDetails.role) {
      return false;
    }

    return (
      Object.values(userDetails.role).findIndex((role) =>
        consultantRoles.includes(role.name),
      ) !== -1
    );
  },
);

export const isMemberInCurrentCommittee = createSelector(
  [userDetails, selectedNationalCommittee],
  (userDetails, selectedNationalCommittee) => {
    if (!userDetails.role || !userDetails.role[selectedNationalCommittee]) {
      return false;
    }

    return true;
  },
);

const committeeToTurnOff = (state, committee) => committee;

export const getTurnedOffCommittees = createSelector(
  [userSettings, selectedNationalCommittee],
  (userSettings, selectedNationalCommittee) => {
    const settings = userSettings[selectedNationalCommittee];
    return settings ? settings.turnedOffCommittees : [];
  },
);

export const getUserEmailSettingForAllCommittees = createSelector(
  [userSettings],
  (userSettings) => {
    const settings = {};
    const majorSetting = {
      key: "",
      cnt: 0,
    };

    for (let committee in userSettings) {
      settings[userSettings[committee].emailSetting] = settings[
        userSettings[committee].emailSetting
      ]
        ? settings[userSettings[committee].emailSetting] + 1
        : 1;

      if (majorSetting.cnt < settings[userSettings[committee].emailSetting]) {
        majorSetting.key = userSettings[committee].emailSetting;
        majorSetting.cnt = settings[userSettings[committee].emailSetting];
      }
    }

    // This will be always shown as updating email setting one by one
    // const keys = Object.keys(settings);
    // if (keys.length > 1) debugger;
    // console.error("User has different email settings for different committees");

    // get the only value in the set
    return majorSetting.key;
  },
);

export const getUserEmailSettingForCurrentCommittee = createSelector(
  [userSettings, selectedNationalCommittee],
  (userSettings, selectedNationalCommittee) => {
    const settings = userSettings[selectedNationalCommittee];
    return settings ? settings.emailSetting : "";
  },
);

export const isCommitteeAlreadyTurnedOff = createSelector(
  [getTurnedOffCommittees, committeeToTurnOff],
  (turnOffCommittees, committeeToTurnOff) => {
    return turnOffCommittees.includes(committeeToTurnOff);
  },
);

export const getTunedOffCommitteesCount = createSelector(
  [getTurnedOffCommittees],
  (turnOffCommittees) => (!!turnOffCommittees ? turnOffCommittees.length : 0),
);

export const recentSearches = createSelector(
  user,
  (user) => user.data.recentSearches,
);

export const getAppLocale = createSelector(user, (user) => {
  if (!user || !user.data || !user.data.kundeportal) {
    return defaultLocale;
  }

  if (appLocaleMap.hasOwnProperty(user.data.kundeportal.locale)) {
    return user.data.kundeportal.locale || defaultLocale;
  }
  return defaultLocale;
});

export const getAppLanguage = createSelector(getAppLocale, (locale) => {
  if (appLocaleMap.hasOwnProperty(locale)) return appLocaleMap[locale].language;

  return appLocaleMap[defaultLocale].language;
});
