import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { compose } from "redux";
import "./OptionalLabel.scss";

class OptionalLabel extends Component {
  render() {
    const { t } = this.props;

    return <span className="optional-label">{t("general.form.optional")}</span>;
  }
}

export default compose(withTranslation("common"))(OptionalLabel);
