import React from "react";
import { useTranslation } from "react-i18next";
import "./Poll.scss";
import { formatDateTime } from "../../../../../utils/transform";
import { isObjectEmpty } from "../../../../../utils/validation/objectValidator";

export const PollRow = ({ poll, onAddPoll }) => {
  const { t } = useTranslation("common");

  const renderPollOptions = () => {
    return (
      <ul className="poll-list">
        {poll.options.map((option, index) => (
          <li
            className="poll-list__element"
            key={index}
          >{`Option ${index + 1}: ${option.value}`}</li>
        ))}
      </ul>
    );
  };

  const renderPollFooter = () => {
    const strings = [];
    if (poll.isMultiVote) {
      strings.push(`/${t("allow multiple votes")}`);
    }
    if (poll.deadline) {
      strings.push(
        `/${t("deadline before")} ${formatDateTime(new Date(poll.deadline))}`,
      );
    }

    return <span className="poll-footer">{strings.join(" ")}</span>;
  };

  return (
    <div className="group group--underlined poll">
      <div className="header">
        <label className="field-label" style={{ marginBottom: 14 }}>
          {t("notifications.poll.title")}
        </label>
        <button
          className="button button--small button--secondary"
          onClick={onAddPoll}
        >
          {poll && !isObjectEmpty(poll)
            ? t("notifications.poll.button.editPoll")
            : t("notifications.poll.button.addPoll")}
        </button>
      </div>
      {!!poll && (
        <div className="gray-box">
          <div className="group group--small group__flex detail__group group--underlined">
            <span className="headline-4 u-text-bold" id="documents">
              {poll.title}
            </span>
          </div>
          {renderPollOptions()}
          {renderPollFooter()}
        </div>
      )}
    </div>
  );
};
