import React from "react";
import { useTranslation } from "react-i18next";
import "./MembersList.scss";
import Avatar from "../avatar/Avatar";

export const MembersList = ({ closeHandler, title, membersList }) => {
  const { t } = useTranslation("common");

  const emails = membersList.map((member) => member.email).join(";");

  return (
    <div className="members-container">
      <div className="members-header">
        <h2>{title}</h2>

        <a
          className="button button--link button--no-border send-mail-btn"
          href={`mailto:${emails}`}
        >
          {t("member.sendEmailLabel")}
        </a>
      </div>
      <ul className="members-list">
        {membersList.map((member, index) => (
          <li key={index} className="member-item">
            <div className="member-info">
              <div className="member-avatar">
                <Avatar
                  profileImage={member.photo}
                  name={member.name}
                  isSmall={true}
                />
              </div>
              <div className="member-details">
                <span className="member-name">{member.name}</span>
                <span className="member-email">{member.email}</span>
              </div>
            </div>
            <span className="member-status">{member.label}</span>
          </li>
        ))}
      </ul>
      <button className="close-btn" onClick={closeHandler}>
        {t("general.button.close")}
      </button>
    </div>
  );
};
