import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { NavLink, withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import FileDownload from "js-file-download";

import MeetingDetailsTab from "../../components/meeting-detail/MeetingDetailsTab";
import DateThumbnail from "../../../../common/date-thumbnail/DateThumbnail";

import {
  SlimTabsSwitcher,
  SlimTab,
} from "../../../../common/slim-tabs/SlimTabs";
import {
  clearMeeting,
  deleteMeeting,
  downloadMeetingDocuments,
  fetchMeeting,
  fetchMeetingReferences,
  removeReferenceFromMeeting,
  updateMeetingDocumentInfo,
} from "../../../../../state/meetings/actions";
import PageNotFound from "../../../404/PageNotFound";
import Loader from "../../../../common/loader/Loader";
import WithPrivileges from "../../../../common/with-privileges/WithPrivileges";
import { selectedNationalCommittee } from "../../../../../state/national-committees/selectors";
import {
  generateDialogLink,
  DIALOG_TYPES,
  generateCloseDialogLink,
  generateDialogLinkForPage,
  PAGE_TYPES,
} from "../../../../../utils/dynamicLink";

import {
  getMeeting,
  isProcessingMeeting,
} from "../../../../../state/meetings/selectors";
import { setPageTitle } from "../../../../../utils/pageTitle";
import { Helmet } from "react-helmet";
import { initDocuments } from "../../../../../state/document/actions";
import MeetingDeleteModal from "../../components/delete-modal/MeetingDeleteModal";

import { createCalendarEvent } from "../../../../../utils/calendarEvent";
import { ReferencePage } from "../../../notifications/components/reference/ReferencePage";
import { REFERENCE_TAB } from "../../../../../state/references/reducer";
import ReferenceComponent from "../../../notifications/components/reference/ReferenceComponent";
import ActionBox from "../../../../common/action-box/ActionBox";
import Toggler from "../../../../common/toggler/Toggler";
import { ParticipationBar } from "../../components/participation-bar/ParticipationBar";

// const TAB = {
//   GENERAL_TAB: "_general",
//   REFERENCE_TAB: "_reference",
// };

class MeetingDetails extends Component {
  state = {
    isDeleteModalOpen: false,
    // selectedTab: TAB.GENERAL_TAB,
  };

  constructor(props) {
    super(props);

    const uri = this.props.context.resource;
    this.props.initDocuments();
    this.props.fetchMeeting(uri);
    this.props.fetchMeetingReferences();
  }

  componentWillUnmount() {
    this.props.clearMeeting();
  }

  onDeleteClick = () => {
    this.setState({ isDeleteModalOpen: true });
  };
  onCancelDelete = () => {
    this.setState({ isDeleteModalOpen: false });
  };
  onSubmitDelete = (uri) => {
    this.props.deleteMeeting(uri);
    this.setState({ isDeleteModalOpen: false });
  };

  createEventBody = ({
    committeeName,
    description,
    references,
    referencesPerType,
    url,
    linkText,
    documentsText,
  }) => {
    const { selectedNationalCommittee, t } = this.props;

    const header = `<h2>DS Meeting: ${committeeName}</h2>`;
    const body = `<p>${description}</p>`;
    const deadlineHeader = `<h4>${t("deadlines.header.name")}:</h4>`;
    const docsHeader = `<h4>${documentsText}:</h4>`;
    const domain = window.location.hostname;

    const deadlinesList = referencesPerType?.deadline
      ?.map((doc) => {
        const uri = generateDialogLinkForPage(
          selectedNationalCommittee,
          PAGE_TYPES.DEADLINES,
          DIALOG_TYPES.DEADLINE_DETAILS,
          doc.uri,
        );
        return `<li><a href="https://${domain}${uri}">${doc.title}</a></li>`;
      })
      .join("");

    const deadliens = deadlinesList
      ? [deadlineHeader, `<ul>${deadlinesList}</ul>`].join("")
      : null;

    const documentsList = referencesPerType?.document
      ?.map(
        (doc) => `<li><a href="${doc.downloadUrl}">${doc.filename}</a></li>`,
      )
      .join("");

    const docs = documentsList
      ? [docsHeader, `<ul>${documentsList}</ul>`].join("")
      : null;
    const link = `<h3><a href="${url}">${linkText}</a></h3>`;

    const eventBody = [header, body, deadliens, docs, link].join("");

    return eventBody;
  };

  handleExportToCalender = () => {
    const { meeting, t, selectedNationalCommittee } = this.props;

    const {
      title,
      committeeName,
      fromDate,
      toDate,
      references,
      referencesPerType,
      locationLong,
      locationShort,
    } = meeting;

    const linkText = t("meetings.detail.linkToMeeting");
    const documentsText = t("documents.header.name");

    const url = window.location.href;
    const fileTitle = `DS Meeting: ${selectedNationalCommittee} - ${title}`;

    const eventBody = this.createEventBody({
      committeeName,
      description: locationLong,
      references,
      referencesPerType,
      linkText,
      documentsText,
      url,
    });

    const data = {
      title: fileTitle,
      html: eventBody,
      startDate: fromDate,
      endDate: toDate,
      location: locationShort,
      url,
    };

    const eventFile = createCalendarEvent(data);

    FileDownload(eventFile, `${fileTitle}.ics`);
  };

  // onChangeTab = (selected) => {
  //   this.setState({ selectedTab: selected });
  // };

  renderHeader() {
    const { t, meeting, isProcessingMeeting, selectedNationalCommittee } =
      this.props;
    return (
      <>
        <Helmet>
          <title>
            {setPageTitle(
              ["Meetings", meeting.title],
              selectedNationalCommittee,
            )}
          </title>
        </Helmet>
        <header className="layout__title-wrapper">
          <div className="layout__title-group layout__title-group--extended group group--inline">
            <DateThumbnail type="big" date={meeting.fromDate} />
            <div className="group group--inline">
              <h2 className="headline-4">
                {meeting.committee}
                {meeting.committeeName.length ? (
                  <span className="u-text-normal">
                    {" "}
                    - {meeting.committeeName}
                  </span>
                ) : null}
              </h2>
              <h3 className="headline-3">{meeting.title}</h3>
            </div>
          </div>
          <WithPrivileges>
            <div className="layout__title-group group group--btn group--inline">
              <Toggler>
                <ActionBox>
                  <li className="list__element">
                    <button
                      className="button button--medium button--link actions-list__button"
                      onClick={() => this.handleExportToCalender()}
                    >
                      {t("general.button.calendarExport")}
                    </button>
                  </li>
                  <li className="list__element">
                    <NavLink
                      to={generateDialogLink(
                        DIALOG_TYPES.EDIT_MEETING,
                        meeting.uri,
                      )}
                      className="button button--medium button--link actions-list__button"
                    >
                      {t("general.button.edit")}
                    </NavLink>
                  </li>
                  <li className="list__element">
                    <button
                      className="button button--medium button--link actions-list__button"
                      onClick={this.onDeleteClick}
                    >
                      {t("general.button.delete")}
                    </button>
                  </li>
                </ActionBox>
              </Toggler>
            </div>
          </WithPrivileges>
        </header>
        {/* <div className="layout__content">
          <SlimTabsSwitcher
            enabled={!isProcessingMeeting}
            useInternalState
            defaultTab={TAB.GENERAL_TAB}
            onTab={this.onChangeTab}
          >
            <SlimTab
              name={TAB.GENERAL_TAB}
              label={t("meetings.detail.details")}
            />
            <SlimTab name={TAB.REFERENCE_TAB} label={t("references.name")} />
          </SlimTabsSwitcher>
        </div> */}
      </>
    );
  }

  onUpdateDocument = (document) => {
    this.props.updateMeetingDocumentInfo(document);
  };

  onRemoveReferenceById = (id) => {
    this.props.removeReferenceFromMeeting(id);
  };

  downloadAllFiles = () => {
    this.props.downloadMeetingDocuments(this.props.meeting.uri);
  };

  renderContent() {
    const { meeting } = this.props;

    return (
      <div className="layout__content panel detail">
        {meeting.isParticipationEnabled && <ParticipationBar />}
        <MeetingDetailsTab {...meeting} />
        <ReferenceComponent
          disableRows={[REFERENCE_TAB.MEETINGS]}
          onUpdateDocument={this.onUpdateDocument}
          onRemoveDocument={this.onRemoveReferenceById}
          onRemoveDeadline={this.onRemoveReferenceById}
          // for download zip file
          downloadDocuments={{
            onClick: this.downloadAllFiles,
            // isAwaiting: this.props.isDownloadingZipPackage,
            isAwaiting: false,
            isDisabled: meeting.referencesPerType?.document?.length === 0,
          }}
          referencesPerType={meeting.referencesPerType}
          disableEdit
        />
      </div>
    );
  }

  render() {
    const { meeting, isProcessingMeeting } = this.props;
    const meetingLoaded = !!meeting && !!meeting.uri;

    if (!meetingLoaded) {
      if (!isProcessingMeeting) return <PageNotFound />;
      return <Loader type="content" />;
    }

    return (
      <>
        {this.renderHeader()}
        {this.renderContent()}
        {isProcessingMeeting && <Loader type="content" />}
        <MeetingDeleteModal
          showModal={this.state.isDeleteModalOpen}
          onCancel={this.onCancelDelete}
          onSubmit={this.onSubmitDelete}
          itemUri={meeting.uri}
          itemTitle={meeting.title}
        />
      </>
    );
  }
}

const mapDispatchToProps = {
  deleteMeeting,
  fetchMeeting,
  fetchMeetingReferences,
  clearMeeting,
  initDocuments,
  updateMeetingDocumentInfo,
  removeReferenceFromMeeting,
  downloadMeetingDocuments,
};

const mapStateToProps = (state) => ({
  meeting: getMeeting(state),
  isProcessingMeeting: isProcessingMeeting(state),
  selectedNationalCommittee: selectedNationalCommittee(state),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation("common"),
  withRouter,
)(MeetingDetails);
