import React from "react";
import { withTranslation } from "react-i18next";
import OutsideClick from "../outside-click/OutsideClick";
import classNames from "classnames";

const SortBox = ({
  t,
  elements,
  onSelectionChange,
  selected,
  onClickOutside,
  isVisible,
}) => {
  return isVisible ? (
    <OutsideClick changeState={onClickOutside}>
      <div className="sort-dropdown panel panel--border">
        <h3 className="sort-dropdown__title">
          {t("documents.sort.label.order")}
        </h3>
        {elements.map((element, index) => {
          return (
            <div
              key={index}
              className={classNames("group", {
                "group--underlined": index !== elements.length - 1,
              })}
            >
              <label htmlFor={element.value} className="inline-checkbox">
                <input
                  type="radio"
                  name={element.value}
                  id={element.value}
                  className="inline-checkbox__input"
                  value={element.value}
                  checked={element.value === selected ? "checked" : ""}
                  onChange={(event) => {
                    onSelectionChange(event.target.value);
                  }}
                />
                <span className="inline-checkbox__input--off icon icon-radio-off"></span>
                <span className="inline-checkbox__input--on icon icon-radio-on"></span>
                <span>{t(element.name)}</span>
              </label>
            </div>
          );
        })}
      </div>
    </OutsideClick>
  ) : null;
};

export default withTranslation("common")(SortBox);
