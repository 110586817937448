import React, { Component } from "react";
import DeadlineForm from "../deadline-form/DeadlineForm";
import {
  updateDeadline,
  initDeadlineFormWithGeneralInfo,
} from "../../../../../state/deadlines/actions";
import {
  DIALOG_TYPES,
  generateDialogLink,
} from "../../../../../utils/dynamicLink";
import { compose } from "redux";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { getNewDeadlineFormData } from "../../../../../state/deadlines/selectors";

class EditDeadline extends Component {
  onMount = () => {
    const uri = this.props.context.resource;
    this.props.initDeadlineFormWithGeneralInfo(uri);
  };

  get editorSubmitAction() {
    return this.props.updateDeadline;
  }

  get editorCancelAction() {
    return generateDialogLink(
      DIALOG_TYPES.DEADLINE_DETAILS,
      this.props.formData.uri,
    );
  }

  render() {
    const { t, formData } = this.props;
    const detailTitle = t("deadlines.details.edit.title");

    return (
      <DeadlineForm
        formData={formData}
        onMount={this.onMount}
        editorSubmitAction={this.editorSubmitAction}
        editorCancelAction={this.editorCancelAction}
        detailTitle={detailTitle}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  formData: getNewDeadlineFormData(state),
});

const mapDispatchToProps = {
  initDeadlineFormWithGeneralInfo,
  updateDeadline,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation("common"),
)(EditDeadline);
