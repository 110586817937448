import { createSelector } from "reselect";

export const documentsSummary = (state) => state.documentsSummary;

export const getFilesUrls = createSelector(
  [documentsSummary],
  (documentsSummary) => {
    return Object.values(documentsSummary.details).reduce(
      (acc, cur) => [...acc, ...cur.map((c) => c.url)],
      [],
    );
  },
);
