export function normalizeBroadcastInformation(data) {
  function cvtDate(epoch) {
    if (!epoch) return null;

    var date = new Date(0);
    date.setUTCSeconds(epoch / 1000);
    return date;
  }

  return {
    isInstant: data.isInstant,
    instantBroadcastDate: cvtDate(data.instantBroadcastDate),
    dailyBroadcastDate: cvtDate(data.dailyBroadcastDate),
    weeklyBroadcastDate: cvtDate(data.weeklyBroadcastDate),
  };
}
