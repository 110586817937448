import React, { Component, Fragment, createRef } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import DeadlineGeneralInfoEditor from "../../components/deadline-details/DeadlineGeneralInfoEditor";
import {
  addGeneralDeadlineInformation,
  setFormSelectedDeadlineReferences,
  clearDeadlineForm,
  setFormSelectedDeadlineReferencesMeetings,
  setFormSelectedDeadlineReferencesDocuments,
} from "../../../../../state/deadlines/actions";
import { selectedNationalCommittee } from "../../../../../state/national-committees/selectors";
import { setPageTitle } from "../../../../../utils/pageTitle";
import { Helmet } from "react-helmet";
import { ReferencePage } from "../../../notifications/components/reference/ReferencePage";
import Loader from "../../../../common/loader/Loader";
import { isFetchingDeadlines } from "../../../../../state/deadlines/selectors";
import { NavLink } from "react-router-dom";
import classNames from "classnames";
import { scrollToElement } from "../../../../../utils/scroll";
import "../../../../common/form/form.scss";
import { REFERENCE_TAB } from "../../../../../state/references/reducer";
import { getSelectedReferenceTab } from "../../../../../state/references/selectors";
import {
  emptyReferenceTab,
  setReferenceTab,
} from "../../../../../state/references/actions";

// const TAB = {
//   GENERAL_TAB: "_general",
//   REFERENCE_TAB: "_reference",
// };

class DeadlineForm extends Component {
  constructor(props) {
    super(props);
    this.referencesRef = createRef();
    this.slimTabRef = createRef();
    this.state = {
      validation: {
        committee: { isValid: true },
        date: { isValid: true },
        title: { isValid: true },
      },
      formSubmitted: false,
    };
  }

  componentDidMount() {
    this.props.emptyReferenceTab();
    this.props.onMount();
  }

  componentWillUnmount() {
    this.props.clearDeadlineForm();
  }

  setDeadlineData = (object) => {
    this.props.addGeneralDeadlineInformation(object);
  };

  // onChangeTab = (selected) => {
  //   this.setState({ selectedTab: selected });
  // };

  get isGeneralTab() {
    return this.props.getSelectedReferenceTab === REFERENCE_TAB.NONE;
  }

  onUpdateDocument = (document) => {
    const updatedDocuments = this.props.formData.referencesPerType.document.map(
      (d) => {
        if (d.url === document.url) {
          return document;
        }
        return d;
      },
    );

    this.props.setFormSelectedDeadlineReferencesDocuments(updatedDocuments);
  };

  onAddDocuments = (documents) => {
    this.props.setFormSelectedDeadlineReferencesDocuments(documents);
  };

  onRemoveDocument = (id) => {
    this.props.setFormSelectedDeadlineReferencesDocuments(
      this.props.formData.referencesPerType.document.filter(
        (d) => d.url !== id,
      ),
    );
  };

  onSetMeeting = (meetings) => {
    this.props.setFormSelectedDeadlineReferencesMeetings(meetings);
  };

  onRemoveMeeting = (id) => {
    this.props.setFormSelectedDeadlineReferencesMeetings(
      this.props.formData.referencesPerType.meeting.filter((d) => d.uri !== id),
    );
  };

  onEditorSubmitAction = (e) => {
    const { setReferenceTab } = this.props;

    e.preventDefault();
    if (!this.validate()) {
      setReferenceTab(REFERENCE_TAB.NONE);
      return;
    }
    this.props.editorSubmitAction(this.props.formData);
    this.setState({ formSubmitted: true });
  };

  get editorCancelAction() {
    return this.props.editorCancelAction;
  }

  validate = () => {
    const { committee, date, title } = this.props.formData;

    const validation = {
      committee: { isValid: true },
      date: { isValid: true },
      title: { isValid: true },
    };
    let isValid = true;

    if (!committee) {
      validation.committee.isValid = false;
      isValid = false;
    }

    if (!date) {
      validation.date.isValid = false;
      isValid = false;
    }

    if (!title) {
      validation.title.isValid = false;
      isValid = false;
    }

    if (!isValid) {
      this.setState(
        {
          validation,
        },
        () => scrollToElement(".validation-message__error"),
      );
    }
    return isValid;
  };

  renderGeneralTab() {
    return (
      <>
        <DeadlineGeneralInfoEditor
          data={this.props.formData}
          setData={this.setDeadlineData}
          validation={this.state.validation}
          // handleSubmit={this.props.editorSubmitAction}
          // cancelNavLinkUrl={this.props.editorCancelAction}
          // showNotifyMembers
        />
      </>
    );
  }

  // Start from here
  renderReferenceTab() {
    return (
      <ReferencePage
        ref={this.referencesRef}
        // Documents
        onUpdateDocument={this.onUpdateDocument}
        onRemoveDocument={this.onRemoveDocument}
        onAddDocuments={this.onAddDocuments}
        // Meetings
        onRemoveMeeting={this.onRemoveMeeting}
        onSetMeeting={this.onSetMeeting}
        referencesPerType={this.props.formData.referencesPerType}
      />
    );
  }

  renderTabContents() {
    return this.isGeneralTab
      ? this.renderGeneralTab()
      : this.renderReferenceTab();
  }

  get footerPrimaryAction() {
    return this.isGeneralTab
      ? this.onEditorSubmitAction
      : () => this.referencesRef.current.onClick();
  }

  get footerSecondaryButton() {
    const { t, setReferenceTab } = this.props;

    return this.isGeneralTab ? (
      <NavLink to={this.editorCancelAction} className="button">
        {t("general.button.cancel")}
      </NavLink>
    ) : (
      <button
        onClick={() => setReferenceTab(REFERENCE_TAB.NONE)}
        className="button"
      >
        {t("general.button.cancel")}
      </button>
    );
  }

  get footerPrimaryButtonDisable() {
    return this.isGeneralTab ? this.state.formSubmitted : false;
  }

  get footerPrimaryButtonText() {
    const { t } = this.props;

    return this.isGeneralTab
      ? t("general.button.save")
      : t("general.button.addSelected");
  }

  renderFooter = () => {
    const { t, editorCancelAction } = this.props;

    return (
      <div className="group group--btn">
        <div className="u-align-right">
          {/*todo: set correct back link - from window history? */}
          {this.footerSecondaryButton}
          {/*todo: disable button after click (during sending request;
                    to avoid sending form multiple times)*/}
          <button
            className={classNames("button button--primary", {
              "button--awaiting button--disabled": this.state.formSubmitted,
            })}
            onClick={this.footerPrimaryAction}
            disabled={this.footerPrimaryButtonDisable}
          >
            {this.footerPrimaryButtonText}
          </button>
        </div>
      </div>
    );
  };

  renderTab() {
    const { t, selectedNationalCommittee, isCreate, formData, detailTitle } =
      this.props;

    return (
      <Fragment>
        <Helmet>
          <title>
            {setPageTitle(
              ["Deadlines", isCreate ? "Create new" : formData.description],
              selectedNationalCommittee,
            )}
          </title>
        </Helmet>
        <header className="layout__title">
          <h2 className="headline-2">{detailTitle}</h2>
        </header>
        <div className="layout__content panel detail">
          {this.renderTabContents()}
        </div>
        <div className="modal__footer">{this.renderFooter()}</div>
      </Fragment>
    );
  }

  render() {
    const { isFetchingDeadlines, formData, isCreate } = this.props;
    const isDataFetched = !isFetchingDeadlines && formData.uri;

    if (isCreate) {
      return this.renderTab();
    } else {
      return isDataFetched ? this.renderTab() : <Loader type="content" />;
    }
  }
}

const mapDispatchToProps = {
  clearDeadlineForm,
  setFormSelectedDeadlineReferences,
  addGeneralDeadlineInformation,
  setFormSelectedDeadlineReferencesDocuments,
  setFormSelectedDeadlineReferencesMeetings,
  emptyReferenceTab,
  setReferenceTab,
};

const mapStateToProps = (state) => ({
  // formData: getNewDeadlineFormData(state),
  selectedNationalCommittee: selectedNationalCommittee(state),
  isFetchingDeadlines: isFetchingDeadlines(state),
  getSelectedReferenceTab: getSelectedReferenceTab(state),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation("common"),
)(DeadlineForm);
