import React, {
  useState,
  useCallback,
  useImperativeHandle,
  forwardRef,
} from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import classNames from "classnames";

import {
  getDocuments,
  getModalDetails,
} from "../../../../../state/document/selectors";
import {
  resetModalState,
  setModalState,
  uploadSelectedDocuments,
} from "../../../../../state/document/actions";
import DocumentSelector from "../../../../common/documents-tab/DocumentSelector";
import Loader from "../../../../common/loader/Loader";
import WithPrivileges from "../../../../common/with-privileges/WithPrivileges";
import ReadonlyGuard from "../../../../common/readonly-guard/ReadonlyGuard";
import FileInput from "../../../../common/form/file-input/FileInput";
import { documentUploadType } from "../../../../../utils/constants";

export const DocumentActionType = {
  upload: "upload",
  documents: "documents",
  linkToFile: "link",
};

export const ReferenceDocumentsList = forwardRef((props, ref) => {
  const { t } = useTranslation("common");
  const dispatch = useDispatch();
  const history = useHistory();

  const modal = useSelector(getModalDetails);
  const documents = useSelector(getDocuments);

  const [addDocumentActionType, setAddDocumentActionType] = useState(null);
  const [selectedDocuments, setSelectedDocuments] = useState([]);

  useImperativeHandle(ref, () => ({
    onSelect() {
      onAddSelectedDocumentsHandler();
    },
  }));

  const onLeave = useCallback(() => {
    if (props.isModal) {
      props.onSelect();
    } else {
      history.push(props.basePath);
    }
  }, [props, history]);

  const onAddSelectedDocumentsHandler = () => {
    props.onAddDocuments(selectedDocuments);
    setAddDocumentActionType(null);
    setSelectedDocuments([]);
    onLeave();
  };

  const onDocumentSelectorCancelClick = () => {
    setAddDocumentActionType(null);
    onLeave();
  };

  const onSelectDocumentsCallback = (documents) => {
    setSelectedDocuments(documents);
  };

  const showSetEmptyButton = useCallback(selectedDocuments.length === 0, [
    selectedDocuments,
  ]);

  const renderUploadButton = () => {
    const { isLoading } = props;

    return (
      <WithPrivileges>
        <ReadonlyGuard>
          <FileInput
            onChangeHandler={(event) => {
              setAddDocumentActionType(DocumentActionType.upload);
              dispatch(
                uploadSelectedDocuments({
                  documents: event.target.files,
                  uploadType: documentUploadType.file,
                  notifyOnUpdate: false,
                }),
              );
            }}
            isInline
            isSecondaryButton
            isSmallButton
            isDisabled={isLoading}
            buttonText={t("general.button.upload")}
            id="uploadDocuments"
            inputOptions={{
              multiple: true,
            }}
          />
        </ReadonlyGuard>
      </WithPrivileges>
    );
  };

  const renderButtons = () => {
    const { isLoading } = props;

    return (
      <div className="documents__buttons">
        {!props.isModal && (
          <button
            className={classNames("button button--small", {
              "button--disabled": isLoading,
            })}
            onClick={onDocumentSelectorCancelClick}
          >
            {t("general.button.cancel")}
          </button>
        )}
        {renderUploadButton()}
        {!props.isModal && (
          <button
            className={classNames("button button--small button--primary", {
              "button--disabled": isLoading,
            })}
            onClick={onAddSelectedDocumentsHandler}
          >
            {!showSetEmptyButton
              ? t("general.button.addSelected")
              : t("general.button.setEmptyList")}
          </button>
        )}
      </div>
    );
  };

  const renderView = () => {
    const { referenceDocumentList } = props;

    return (
      <DocumentSelector
        isRefernece
        documents={documents}
        onSelectDocuments={onSelectDocumentsCallback}
        selectedDocuments={referenceDocumentList}
      />
    );
  };

  const { isLoading } = props;
  return (
    <div className="tab-content references">
      <div className="detail__form">
        <div className="group group--small group__flex detail__group group--underlined">
          <span className="headline-4 u-text-bold" id="documents">
            {t("deadlines.details.documents")}
          </span>
          {renderButtons()}
        </div>

        {isLoading ? <Loader type="content" /> : renderView()}
      </div>
    </div>
  );
});
