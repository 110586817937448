import React, { Fragment, Component } from "react";
import { withTranslation } from "react-i18next";
import classNames from "classnames";

class ConfirmationModal extends Component {
  state = { awaiting: false };

  onSubmit = () => {
    if (!this.state.awaiting) {
      this.props.submitHandler(this.props.id);
      this.setState({ awaiting: true });
      this.onClose();
    }
  };

  onClose = () => {
    this.props.cancelAction && this.props.cancelAction(this.props.id);
    this.props.cancelHandler && this.props.cancelHandler(this.props.id);
  };

  render() {
    const { t, title, message, cancelLabel, disableSubmitButton, submitLabel } =
      this.props;

    return (
      <Fragment>
        <div className="group group--first group--full-underlined">
          <h3 className="headline-4">{title}</h3>
        </div>
        <div className="group group--last-underlined">
          <p>{message}</p>
        </div>
        <div className="group group--btn u-align-right">
          <button className="button" onClick={this.onClose}>
            {cancelLabel || t("general.button.cancel")}
          </button>
          {!disableSubmitButton && (
            <button
              className={classNames("button button--primary", {
                "button--disabled button--awaiting": this.state.awaiting,
              })}
              onClick={this.onSubmit}
            >
              {submitLabel || t("general.button.confirm")}
            </button>
          )}
        </div>
      </Fragment>
    );
  }
}

export default withTranslation("common")(ConfirmationModal);
