import { handleActions, combineActions } from "redux-actions";
import * as actions from "./actions";
import {
  normalizeDocuments,
  mergeDocuments,
  updateRelatedResources,
} from "./normalizers";

const initialState = {
  isFetching: false,
  isFetched: false,
  data: {
    results: null,
    pagination: {
      pagesLoaded: 0,
      pagesToLoad: 0,
    },
  },
  filters: {
    type: null,
    action: null,
    committee: null,
    title: "",
    documentsAreFiltered: false,
  },
  sortingType: null,
  modal: {
    isOpen: false,
    contentType: null,
    content: null,
  },
  filesCurrentlyDownloaded: [],
};

const actionHandlers = {
  [combineActions(actions.initDocuments, actions.reloadDocuments)]: (
    state,
  ) => ({
    ...state,
    isFetching: true,
    isFetched: false,
    data: {
      pagination: {
        pagesLoaded: 0,
        pagesToLoad: 0,
      },
      results: null,
    },
  }),
  [actions.pullNextDocumentsPageRequest]: (state) => ({
    ...state,
    isFetching: true,
    isFetched: false,
  }),
  [actions.pullDocumentsPageSucceeded]: (state, { payload }) => ({
    ...state,
    isFetching: false,
    isFetched: true,
    data: {
      results: mergeDocuments(state, payload),
      pagination: {
        pagesLoaded: state.data.pagination.pagesLoaded + 1,
        pagesToLoad: payload.pagesToLoad,
      },
    },
  }),
  [actions.fetchRelatedResourcesFinished]: (state, { payload }) => ({
    ...state,
    data: {
      ...state.data,
      results: updateRelatedResources(state, payload),
    },
  }),
  [actions.filterDocumentsSucceeded]: (state, { payload }) => ({
    ...state,
    isFetching: false,
    isFetched: true,
    data: {
      results: normalizeDocuments(payload),
    },
  }),
  [actions.deleteDocumentSucceeded]: (state) => ({
    ...state,
    isFetching: true,
    isFetched: false,
  }),
  [actions.setDocumentsAreFiltered]: (state, { payload }) => ({
    ...state,
    filters: {
      ...state.filters,
      documentsAreFiltered: payload.value,
    },
  }),
  [actions.setFilterValue]: (state, { payload }) => ({
    ...state,
    filters: {
      ...state.filters,
      ...payload,
    },
  }),

  [actions.resetFilters]: (state) => ({
    ...state,
    filters: {
      ...state.filters,
      title: "",
      action: null,
      type: null,
      committee: null,
    },
  }),
  [actions.resetFiltersForm]: (state) => ({
    ...state,
    filters: {
      ...state.filters,
      title: "",
    },
  }),
  [actions.setSortingType]: (state, { payload }) => ({
    ...state,
    sortingType: payload.value,
  }),
  [actions.editDocumentData]: (state, { payload }) => ({
    ...state,
    modal: {
      ...state.modal,
      content: {
        ...state.modal.content,
        ...payload,
      },
    },
  }),
  [actions.setModalState]: (state, { payload }) => ({
    ...state,
    modal: {
      ...state.modal,
      ...payload,
    },
  }),
  [actions.resetModalState]: (state) => ({
    ...state,
    modal: {
      isOpen: false,
      contentType: null,
      content: null,
    },
  }),
  [actions.downloadZipPackage]: (state, { payload }) => ({
    ...state,
    filesCurrentlyDownloaded: [...state.filesCurrentlyDownloaded, payload.id],
  }),
  [actions.downloadZipPackageFinished]: (state, { payload }) => ({
    ...state,
    filesCurrentlyDownloaded: state.filesCurrentlyDownloaded.filter(
      (d) => d !== payload,
    ),
  }),
};

export default handleActions(actionHandlers, initialState);
