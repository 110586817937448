import { addQueryParams, getProxyUrl } from "../utils";
import { api } from "./../api";
import { transformCommitteeName } from "../../utils/transform";
import {
  mapMeetingDocumentsToPatchQuery,
  mapMeetingToPatchQuery,
} from "../mappers/meeting";

const toIsoDateTime = (date) =>
  new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString();

export function getMeetingsUrl(committeeId, params) {
  let proxyUrl = getProxyUrl(
    `/Meetings/meetings/${transformCommitteeName(committeeId)}`,
  );
  proxyUrl = addQueryParams(proxyUrl, params);
  return api.get(proxyUrl);
}

export function getMeetingUrl(meetingUri) {
  const proxyUrl = getProxyUrl(`/Meetings/meeting/${meetingUri}`);
  return api.get(proxyUrl);
}

export function updateMeetingUrl(meeting, notify) {
  let proxyUrl = getProxyUrl(`/Meetings/meeting/${meeting.uri}`);
  proxyUrl = addQueryParams(proxyUrl, mapMeetingToPatchQuery(meeting, notify));
  let docs = mapMeetingDocumentsToPatchQuery(meeting);
  return api.patch(proxyUrl, JSON.stringify(docs));
}

export function deleteMeetingUrl(meetingUri) {
  const proxyUrl = getProxyUrl(`/Meetings/meeting/${meetingUri}`);
  return api.delete(proxyUrl);
}

export function createMeetingUrl(committeeId, data) {
  const proxyUrl = getProxyUrl(
    `/Meetings/meeting/${transformCommitteeName(committeeId)}`,
  );
  const fromDate = toIsoDateTime(data.fromDate);
  const toDate = toIsoDateTime(data.toDate);

  return api.post(proxyUrl, { ...data, fromDate, toDate });
}

export function addParticipantUrl(meetingUri, userId, attending) {
  const proxyUrl = getProxyUrl(
    `/Meetings/participant/${meetingUri}/${userId}/${attending}`,
  );

  return api.post(proxyUrl);
}

export function updateParticipantUrl(participantId, attending) {
  const proxyUrl = getProxyUrl(
    `/Meetings/participant/${participantId}/${attending}`,
  );

  return api.patch(proxyUrl);
}

export function getParticipantsUrl(meetingUri) {
  const proxyUrl = getProxyUrl(`/Meetings/participants/${meetingUri}`);

  return api.get(proxyUrl);
}
