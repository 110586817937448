import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";
import { format } from "date-fns/fp";

import { documentDiagnositcs } from "../../../state/diagnostics/selectors";

import "./DocumentRowDiagnostics.scss";

class DocumentRowDiagnostics extends Component {
  state = {
    showDiagnostics: false,
  };

  onShowMoreClicked = () => {
    this.setState({ showDiagnostics: true });
  };

  onShowLessClicked = () => {
    this.setState({ showDiagnostics: false });
  };

  renderMore() {
    var diagnosticsInfo = {
      appState: this.props.element,
      apiState: this.props.documentDiagnositcs[this.props.element.url],
    };

    return <pre>{JSON.stringify(diagnosticsInfo, null, 2)}</pre>;
  }

  renderNotificationTime() {
    if (
      this.props.documentDiagnositcs[this.props.element.url] &&
      this.props.documentDiagnositcs[this.props.element.url].metadata
    ) {
      const timeStamp =
        this.props.documentDiagnositcs[this.props.element.url].metadata
          .notificationTimestamp;
      return (
        <span>
          Notification timestamp:&nbsp;
          <b>
            {timeStamp
              ? format("E MMM d yyyy HH:mm:ss zzz", new Date(timeStamp))
              : "never"}
          </b>
          &nbsp;
        </span>
      );
    }
    return null;
  }

  render() {
    return (
      <tr>
        <td colSpan="8">
          <div className="document-row-diagnostics">
            {this.renderNotificationTime()}(
            <button onClick={this.onShowMoreClicked}>more</button>|
            <button onClick={this.onShowLessClicked}>less</button>)
            {this.state.showDiagnostics && this.renderMore()}
          </div>
        </td>
      </tr>
    );
  }
}

const mapDispatchToProps = {};

const mapStateToProps = (state) => ({
  documentDiagnositcs: documentDiagnositcs(state),
});

export default compose(
  withTranslation("common"),
  connect(mapStateToProps, mapDispatchToProps),
)(DocumentRowDiagnostics);
