import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { initNotifications } from "../../../../../state/notifications/actions";
import LegacyDocumentNotificationCard from "./LegacyDocumentNotificationCard";
import Loader from "../../../../common/loader/Loader";

class LegacyDocumentNotification extends PureComponent {
  render() {
    const { details } = this.props.data;
    return details ? (
      <LegacyDocumentNotificationCard {...this.props.data} {...this.props} />
    ) : (
      <Loader />
    );
  }
}

const mapDispatchToProsp = {
  initNotifications,
};

export default connect(null, mapDispatchToProsp)(LegacyDocumentNotification);
