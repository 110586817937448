import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { Link } from "react-router-dom";
import classNames from "classnames";
import i18next from "i18next";
import { Trans } from "react-i18next";

import i18Init from "../../../utils/translations.js";

import { accpetGdpr } from "../../../state/user/actions";
import { getAppLanguage } from "../../../state/user/selectors";

import ModalWrapper from "../../common/modal-wrapper/ModalWrapper";

class GdprScreen extends React.Component {
  state = { translationsInitialized: false, awaiting: false };
  componentDidMount() {
    i18Init("da").then(() => {
      this.setState({ translationsInitialized: true });
    });
  }

  onAcceptGdpr = () => {
    this.setState({ awaiting: true }, () => {
      this.props.accpetGdpr();
    });
  };

  render() {
    if (!this.state.translationsInitialized) {
      return null;
    }

    const link = i18next.t("gdpr.link", { ns: "common" });

    return (
      <ModalWrapper
        isOpen={true}
        requestCloseHandler={() => this.onModalClose()}
        showCloseButton={false}
        shouldCloseOnOverlayClick={false}
        disableSlide
      >
        <div className="modal__form">
          <div className="group group--first group--underlined">
            <h3 className="headline-4">
              {i18next.t("gdpr.header", { ns: "common" })}
            </h3>
          </div>
          <div className="group group--underlined">
            <Trans
              i18nKey="common:gdpr.policy"
              i18n={i18next}
              values={{ link: link }}
            >
              <p></p>
              <br />
              <p></p>
              <br />
              <p></p>
              <br />
              <p>
                <a href={link} target="_blank" rel="noopener"></a>
              </p>
            </Trans>
          </div>
          <div className="group group--btn u-align-right">
            <button
              className={classNames("button button--primary", {
                "button--disabled button--awaiting": this.state.awaiting,
              })}
              onClick={this.onAcceptGdpr}
            >
              {i18next.t("gdpr.accept", { ns: "common" })}
            </button>
          </div>
        </div>
      </ModalWrapper>
    );
  }
}

const mapDispatchToProps = {
  accpetGdpr,
};

const mapStateToProps = (state) => ({
  appLanguage: getAppLanguage(state),
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  GdprScreen,
);
