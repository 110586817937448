import { normalizeDeadline } from "../deadlines/normalizer";
import { normalizeDocument } from "../document/normalizers";
import { normalizeMeeting } from "../meetings/normalizers";
/*
This response is once normalized with referenceListToReferenceDataPerType.

response : {
  document: []: documentEl,
  meeting: []: meetingEl,
  deadline: []: deadlineEl,
}

*El: {
  data: {}
}
*/
export function normalizeObjectOfReferences(responses) {
  // need to find out if references[0] type is worth using.
  const document =
    responses.document
      ?.filter((d) => !!d)
      .map((documentEl) => normalizeDocument(documentEl.data)) ?? [];

  const meeting =
    responses.meeting
      ?.filter((d) => !!d)
      .map((meetingEl) => normalizeMeeting(meetingEl.data)) ?? [];

  const deadline =
    responses.deadline
      ?.filter((d) => !!d)
      .map((deadlineEl) => normalizeDeadline({ deadline: deadlineEl.data })) ??
    [];

  return {
    document,
    meeting,
    deadline,
  };
}
