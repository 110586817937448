import { createSelector } from "reselect";

export const app = (state) => state.app;

export const isApplicationInitialized = createSelector(
  app,
  (app) => app.isInitialized,
);
export const applicationUserHintState = createSelector(
  app,
  (app) => app.userHint,
);
export const appGlobalDialogsStack = new createSelector(
  app,
  (app) => app.globalDialogs,
);
export const isApplicationInReadonlyMode = createSelector(
  app,
  (app) => app.readonlyMode,
);
