import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import Radio from "../../common/form/radio/Radio";
import ModalWrapper from "../../common/modal-wrapper/ModalWrapper";
import RelatedVotes from "../../pages/deadlines/components/modal-content/RelatedVotes";
import { voteLabelMap } from "../../../utils/constants";

class VotingBar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showVotesModal: false,
    };
  }

  showVotesModal = () => {
    this.setState({
      showVotesModal: true,
    });
  };

  closeVotesModal = () => {
    this.setState({
      showVotesModal: false,
    });
  };
  render() {
    const { t } = this.props;

    // Need a negative amount to make it work with transform instead of width (better performance)
    const transformAdjustedPercentage = this.props.calcPercentage * 0.01;
    // Trim .00 if the percentages are a whole number to beautify the number (open for suggestions of a better way)
    const adjustedPercentage =
      this.props.calcPercentage - Math.floor(this.props.calcPercentage) !== 0
        ? this.props.calcPercentage
        : this.props.calcPercentage
            .toString()
            .substring(0, this.props.calcPercentage.toString().indexOf("."));

    return (
      <div className="voting-bar__wrapper">
        {this.props.hideVoting || (
          <Radio
            checked={this.props.checked}
            onChange={this.props.onChange}
            disabled={this.props.disabled}
            inputType="checkbox"
            isAwaiting={this.props.isAwaiting}
          />
        )}
        <div className="voting-bar">
          <span>{t(voteLabelMap[this.props.voteType])}</span>
          <div
            className={"voting-bar__votes " + this.props.className}
            style={{ transform: `scaleX(${transformAdjustedPercentage})` }}
          />
          <div className="voting-bar__vote-count">
            <p>
              {adjustedPercentage}%{/* Don't show vote count if 0 */}
              {this.props.countVotes !== 0 && (
                <span onClick={this.showVotesModal}>
                  {this.props.countVotes}{" "}
                  {this.props.countVotes === 1 ? "vote" : "votes"}
                </span>
              )}
            </p>
          </div>
        </div>
        <ModalWrapper
          isOpen={this.state.showVotesModal}
          requestCloseHandler={() => this.setState({ showVotesModal: false })}
          disableSlide
        >
          <RelatedVotes
            allVotes={this.props.allVotes}
            headline={this.props.headline}
            deadlineStage={this.props.deadlineStage}
            closeHandler={this.closeVotesModal}
          />
        </ModalWrapper>
      </div>
    );
  }
}

export default withTranslation("common")(VotingBar);
