import React, { Component, Fragment } from "react";
import { withTranslation } from "react-i18next";
import { compose } from "redux";
import { connect } from "react-redux";
import FormMessageBox, {
  messageType,
} from "../../../../common/form/message-box/MessageBox";
import PanelContainer from "../../../../common/layout/PanelContainer";
import { apiResponse, userDetails } from "../../../../../state/user/selectors";
import {
  updateUserCredentials,
  resetUserApiResponse,
} from "../../../../../state/user/actions";
import validateLength from "../../../../../utils/validation/passwordLengthValidator";
import validateEqual from "../../../../../utils/validation/equalValidator";
import { setPageTitle } from "../../../../../utils/pageTitle";
import { Helmet } from "react-helmet";

class UserCredentials extends Component {
  componentWillUnmount() {
    this.props.resetUserApiResponse();
  }

  state = {
    currentPassword: "",
    newPassword: "",
    newPassword2: "",
    messages: [],
  };

  clearForm = () => {
    this.form && this.form.reset();
  };

  validateFields = () => {
    const { currentPassword, newPassword, newPassword2 } = this.state;
    const currentPasswordValid = validateLength(currentPassword, 8);
    const newPasswordValid =
      validateLength(newPassword, 8) &&
      validateEqual([newPassword, newPassword2]);

    return currentPasswordValid && newPasswordValid;
  };

  updateMessageList = (messages, message, validate, value) => {
    let results = [...messages];

    // remove message from list if validation passed
    if (messages.some((msg) => msg === message) && validate(value)) {
      results = results.filter((msg) => msg !== message);
    }

    // add message to list if validation failed and there is no meesage like this
    if (!messages.some((msg) => msg === message) && !validate(value)) {
      results.push(message);
    }

    return results;
  };

  changeUserPassword = (e) => {
    e.preventDefault();
    if (this.validateFields()) {
      const { currentPassword, newPassword } = this.state;

      this.props.updateUserCredentials({ currentPassword, newPassword });

      this.setState({
        currentPassword: "",
        newPassword: "",
        newPassword2: "",
        messages: [],
      });

      this.clearForm();
    } else {
      const { currentPassword, newPassword, newPassword2, messages } =
        this.state;
      let newMessages = this.updateMessageList(
        messages,
        "userProfile.validation.passwordLength",
        validateLength,
        currentPassword,
      );
      newMessages = this.updateMessageList(
        newMessages,
        "userProfile.validation.newPasswordLength",
        validateLength,
        newPassword,
      );
      newMessages = this.updateMessageList(
        newMessages,
        "userProfile.validation.passwordEqual",
        validateEqual,
        [newPassword, newPassword2],
      );

      this.setState({
        messages: newMessages,
      });
    }
  };

  render() {
    const { messages } = this.state;
    const { t, apiResponse } = this.props;
    return (
      <Fragment>
        <Helmet>
          <title>{setPageTitle(["User Profile", "Credentials"])}</title>
        </Helmet>
        <PanelContainer>
          <header className="layout__title">
            <h2 className="headline-2">{t("userCredentials.header.name")}</h2>
          </header>
          <div className="layout__content panel panel--border">
            <form ref={(el) => (this.form = el)}>
              <FormMessageBox
                messages={messages}
                messageType={messageType.error}
              />
              {!messages.length && (
                <FormMessageBox
                  messages={apiResponse.message ? [apiResponse.message] : []}
                  messageType={apiResponse.type}
                />
              )}
              <div className="group group--first group--underlined">
                <div className="label">
                  <label className="label__wrapper" htmlFor="password">
                    {t("general.form.password")}
                  </label>
                </div>
                <input
                  type="password"
                  className="input input--short"
                  name="password"
                  id="password"
                  onChange={(e) =>
                    this.setState({ currentPassword: e.target.value })
                  }
                />
              </div>
              <div className="group">
                <div className="label">
                  <label className="label__wrapper" htmlFor="newPassword">
                    {t("general.form.newPassword")}
                  </label>
                </div>
                <input
                  type="password"
                  className="input input--short"
                  name="newPassword"
                  id="newPassword"
                  onChange={(e) =>
                    this.setState({ newPassword: e.target.value })
                  }
                />
                <p className="additional-label">
                  {t("general.form.min8Chars")}
                </p>
              </div>
              <div className="group group--last-underlined">
                <div className="label">
                  <label className="label__wrapper" htmlFor="newPassword2">
                    {t("general.form.newPasswordAgain")}
                  </label>
                </div>
                <input
                  type="password"
                  className="input input--short"
                  name="newPassword2"
                  id="newPassword2"
                  onChange={(e) =>
                    this.setState({ newPassword2: e.target.value })
                  }
                />
                <p className="additional-label">
                  {t("general.form.min8Chars")}
                </p>
              </div>
              <div className="group group--btn u-align-right">
                <button
                  className="button button--primary"
                  onClick={this.changeUserPassword}
                >
                  {t("general.button.save")}
                </button>
              </div>
            </form>
          </div>
        </PanelContainer>
      </Fragment>
    );
  }
}

const mapDispatchToProps = {
  updateUserCredentials,
  resetUserApiResponse,
};

const mapStateToProps = (state) => ({
  user: userDetails(state),
  apiResponse: apiResponse(state),
});

export default compose(
  withTranslation("common"),
  connect(mapStateToProps, mapDispatchToProps),
)(UserCredentials);
