import { TIMEFRAME } from "../../api/utils";
import { comparisonOperator, eventSortType } from "../../utils/constants";

//TODO refactor this file, it is same as src/services/deadlines
const getLocalDayStartDate = () => {
  return new Date(new Date().toDateString()).toISOString();
};

export const buildQueryParams = (memberId, filters) => {
  let queryParams = {
    sort: eventSortType.asc,
  };

  if (filters.timeframe === TIMEFRAME.PAST) {
    queryParams.sort = eventSortType.desc;
    queryParams.fromDate = comparisonOperator.lt + getLocalDayStartDate();
  } else if (filters.timeframe === TIMEFRAME.FUTURE) {
    queryParams.sort = eventSortType.asc;
    queryParams.fromDate = comparisonOperator.ge + getLocalDayStartDate();
  }

  if (filters.month) {
    let monthStart = new Date(filters.month);
    let nextMonth = new Date(filters.month);
    nextMonth.setMonth(nextMonth.getMonth() + 1);

    queryParams.fromDate = [
      comparisonOperator.ge + monthStart.toISOString(),
      comparisonOperator.lt + nextMonth.toISOString(),
    ];
  }

  if (filters.committee) {
    queryParams.committeeReference = filters.committee;
  }

  queryParams.memberId = memberId;
  queryParams.pageLength = 10;

  return queryParams;
};
