import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { getNotification } from "../../../../../state/notifications/selectors";
import { getMembers } from "../../../../../state/members/selectors";
import { MembersList } from "../../../../common/members-list/MembersList";

export const PollVoterList = ({ closeHandler, selectedOptionId }) => {
  const { t } = useTranslation("common");
  const { poll } = useSelector(getNotification);
  const members = useSelector(getMembers);

  const getPollOptionValue = (pollOptionId) => {
    const option = poll.options.find(
      (option) => option.id === pollOptionId,
    )?.value;
    return option || "";
  };

  const voterList = poll.votes
    ?.filter((vote) => vote.pollOptionId === selectedOptionId)
    .map((vote) => {
      const member = members.find((member) => member.userID === vote.userId);

      if (!member) return null;

      return {
        id: vote.id,
        pollOptionId: vote.pollOptionId,
        name: member.name,
        email: member.email,
        label: getPollOptionValue(vote.pollOptionId),
        work: member.work,
        photo: member.photo,
      };
    })
    .filter((participant) => participant)
    .sort((a, b) => {
      return a.pollOptionId - b.pollOptionId || a.id - b.id;
    });

  return (
    <MembersList
      closeHandler={closeHandler}
      title={t("notifications.poll.memberListHeader")}
      membersList={voterList}
    />
  );
};
