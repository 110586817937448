import React, { Component } from "react";
import DeadlineSummaryNotification from "../common/DeadlineSummaryNotification";

export default class VoteSummationNotification extends Component {
  render() {
    return (
      <DeadlineSummaryNotification
        {...this.props}
        headers={{
          unknownMembers:
            "common:notifications.types.voteSummation.unknownMembers",
          oneMember: "common:notifications.types.voteSummation.oneMember",
          twoMembers: "common:notifications.types.voteSummation.twoMembers",
          moreMembers: "common:notifications.types.voteSummation.moreMembers",
          onlyYou: "common:notifications.types.voteSummation.onlyYou",
          youAndOneMember:
            "common:notifications.types.voteSummation.youAndOneMember",
          youAndTwoMembers:
            "common:notifications.types.voteSummation.youAndTwoMembers",
          youAndMoreMembers:
            "common:notifications.types.voteSummation.youAndMoreMembers",
        }}
      />
    );
  }
}
