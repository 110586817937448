import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";
import types from "../../../../common/modal-wrapper/contentTypes";
import { Link } from "react-router-dom";
import { getModalDetails } from "../../../../../state/document/selectors";
import {
  resetModalState,
  setModalState,
} from "../../../../../state/document/actions";
import DeleteModal from "../../../documents/components/modal-content/DeleteModal";
import ModalWrapper from "../../../../common/modal-wrapper/ModalWrapper";
import DeadlineTable from "../../../deadlines/pages/deadline-list/DeadlineTable";
import { REFERENCE_TAB } from "../../../../../state/references/reducer";

class ReferenceDeadlineRow extends Component {
  removeDeadline = (id) => {
    const { onRemoveDeadline, resetModalState } = this.props;
    onRemoveDeadline(id);
    resetModalState();
  };

  renderRemoveDeadlineFromListModal = () => {
    const { modal, t, resetModalState } = this.props;

    return (
      <DeleteModal
        id={modal.content.uri}
        title={t("references.deadline.modal.delete.title", {
          itemName: modal.content.title,
        })}
        message={t("references.deadline.modal.delete.message", {
          itemName: modal.content.title,
        })}
        submitHandler={this.removeDeadline}
        cancelHandler={resetModalState}
        deleteButtonText={t("general.button.remove")}
      />
    );
  };

  showModalToRemoveDeadlineFromList = (element) => {
    return this.props.setModalState({
      isOpen: true,
      contentType: types.REMOVE_FILE_FROM_DEADLINE_LIST,
      content: element,
    });
  };

  renderListView = () => {
    const { t, selectedDeadlines } = this.props;

    if (!selectedDeadlines?.length) return null;

    return (
      <>
        <div className="group group--first">
          <DeadlineTable
            useActionBox
            deadlines={selectedDeadlines}
            additionalModals={{
              [types.REMOVE_FILE_FROM_DEADLINE_LIST]:
                this.renderRemoveDeadlineFromListModal,
            }}
            rowActions={{
              [t("documents.action.removeFromNotification")]:
                this.showModalToRemoveDeadlineFromList,
            }}
          />
        </div>
      </>
    );
  };

  renderButton = () => {
    const { t, moveToLink, onSelect, isModal } = this.props;
    const selectFromDeadlines = t("references.deadline.selectFromList");

    return isModal ? (
      <button
        className="button button--small button--secondary"
        onClick={(e) => {
          onSelect(REFERENCE_TAB.DEADLINES);
        }}
      >
        {selectFromDeadlines}
      </button>
    ) : (
      <Link className="button button--small button--secondary" to={moveToLink}>
        {selectFromDeadlines}
      </Link>
    );
  };

  render() {
    const { t, disableEdit, selectedDeadlines } = this.props;

    if (disableEdit && !selectedDeadlines?.length) return null;

    return (
      <>
        <div className="gray-box">
          <div className="group group--small group__flex detail__group group--underlined">
            <span className="headline-4 u-text-bold" id="documents">
              {t("deadlines.header.name")}
            </span>
            {!disableEdit && this.renderButton()}
          </div>
          {this.renderListView()}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  modal: getModalDetails(state),
});

const mapDispatchToProps = {
  setModalState,
  resetModalState,
};

export default compose(
  withTranslation("common"),
  connect(mapStateToProps, mapDispatchToProps),
)(ReferenceDeadlineRow);
