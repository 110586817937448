import React, { PureComponent, Fragment } from "react";
import classNames from "classnames";
import { connect } from "react-redux";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import { formatWithOptions } from "date-fns/fp";
import { enUS, da } from "date-fns/esm/locale";

import NotificationBar from "../notification-bar/NotificationBar";
import NotificationCardMenu from "../notification-card-menu/NotificationCardMenu";
import DocumentSummaryCardAttachments from "./DocumentSummaryCardAttachments";

import * as CommitteesSelectors from "../../../../../state/national-committees/selectors";
import { initNotifications } from "../../../../../state/notifications/actions";

import * as userSelectors from "../../../../../state/user/selectors";

//Internal notification
class DocumentSummationNotification extends PureComponent {
  dateLocale = {
    "en-US": enUS,
    "da-DK": da,
  };

  formatAggDate(date) {
    try {
      const formater = formatWithOptions(
        { locale: this.dateLocale[this.props.appLocale] || enUS },
        "EEEE d MMMM yyyy",
      );

      return formater(new Date(date));
    } catch (err) {
      console.error(err, date);
    }
  }

  render() {
    const {
      t,
      data: { notificationId, details, fetched },
    } = this.props;
    if (!fetched) return null;

    return (
      <Fragment key={notificationId}>
        <NotificationBar
          title={t("common:notifications.types.documentSummation.add", {
            date: this.formatAggDate(details.aggDate),
          })}
          notificationMenu={{
            committee: details.committee,
            notificationId: notificationId,
          }}
          // date={eventDate}
        />

        <div
          className={classNames(
            "notification-card__group",
            "notification-card__group--first",
          )}
        >
          <div className="card__header notification-card__header">
            <div className="card__headings">
              <span className="card__heading-1">
                <span className="u-text-bold">
                  {details.committee || t("general.defaults.otherCommittee")}
                </span>
                {details.committee && (
                  <>
                    &nbsp;-{" "}
                    {this.props.committeeTitleProvider(details.committee)}
                  </>
                )}
              </span>
            </div>
            {/* <NotificationCardMenu
              committee={details.committee}
              callbackAction={initNotifications}
              notificationId={notificationId}
            /> */}
          </div>
          <DocumentSummaryCardAttachments
            // notificationId={notificationId}
            // committee={details.committee}
            documents={details.documents}
          />
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  committeeTitleProvider: CommitteesSelectors.getCommitteeTitleProvider(state),
  appLocale: userSelectors.getAppLocale(state),
});

const mapDispatchToProsp = {
  initNotifications,
};

export default compose(
  withTranslation("common"),
  connect(mapStateToProps, mapDispatchToProsp),
)(DocumentSummationNotification);
