export const sortBoxHidingDelay = 500;

export const roleType = {
  member: "Medlem",
  consultant: "Ansvarlig",
  chairman: "Formand",
  professional: "Faglig",
};

export const documentUploadType = {
  file: "file",
  link: "link",
};

export const notificationType = {
  user: "Notification",
  member: "Member",
  deadline: "Deadline",
  meeting: "Meeting",
  document: "Document",
  documentSummation: "Document Summation",
  comment: "Comment",
  vote: "Vote",

  internalDocumentSummation: "internal_document_summation",
  internalVoteSummation: "internal_vote_summation",
  internalCommentsSummation: "internal_comment_summation",
};

export const notificationActionType = {
  add: "Add",
  reminder: "Reminder",
  update: "Update",
  weekReminder: "WeekReminder",
  aggregate: "internal_aggregate",
};

export const notificationFilterTypes = [
  {
    value: notificationType.user,
    label: "notifications.sidebar.typeFilters.user",
  },
  {
    value: `${notificationType.internalDocumentSummation},${notificationType.documentSummation}`,
    label: "notifications.sidebar.typeFilters.internalDocumentSummation",
  },
  {
    value: notificationType.meeting,
    label: "notifications.sidebar.typeFilters.meeting",
  },
  {
    value: notificationType.deadline,
    label: "notifications.sidebar.typeFilters.deadline",
  },
  {
    value: `${notificationType.comment},${notificationType.internalCommentsSummation}`,
    label: "notifications.sidebar.typeFilters.comment",
  },
  {
    value: `${notificationType.vote},${notificationType.internalVoteSummation}`,
    label: "notifications.sidebar.typeFilters.vote",
  },
  {
    value: notificationType.member,
    label: "notifications.sidebar.typeFilters.member",
  },
];

export const defaultExcerptLength = 204;

export const defaultLocale = "en-US";
export const appLocaleMap = {
  "en-US": { language: "en", name: "language.english" },
  "da-DK": { language: "da", name: "language.danish" },
};

export const eventSortType = {
  asc: "from_date_asc",
  desc: "from_date_dsc",
};

export const deadlineSortType = {
  asc: "deadline_asc",
  desc: "deadline_dsc",
};

export const comparisonOperator = {
  gt: "GT ",
  ge: "GE ",
  lt: "LT ",
  le: "LE ",
  ne: "NE ",
};

export const voteType = {
  abstain: 0,
  yes: 1,
  no: 2,
  confirm: 3,
  revise: 4,
  withdraw: 5,
};

export const emptyVotes = {
  abstain: 0,
  yes: 0,
  no: 0,
  confirm: 0,
  revise: 0,
  withdraw: 0,
};

export const voteLabelMap = {
  [voteType.abstain]: "deadlines.details.votes.abstain",
  [voteType.yes]: "deadlines.details.votes.yes",
  [voteType.no]: "deadlines.details.votes.no",
  [voteType.confirm]: "deadlines.details.votes.confirm",
  [voteType.revise]: "deadlines.details.votes.revise",
  [voteType.withdraw]: "deadlines.details.votes.withdraw",
};

export const regularVotes = [voteType.yes, voteType.no, voteType.abstain];
export const reviewVotes = [
  voteType.confirm,
  voteType.revise,
  voteType.withdraw,
  voteType.abstain,
];
export const reviewStageValue = "90";

export const voteAction = "VOTE";
export const otherCommitteeId = "__other_committee__";

export const applicationUserHints = {
  createDeadlineFromDocument: 1000,
  listDeadlines: 1100,
};

export const dialogSizes = {
  wide: "wide",
  big: "big",
  fixedHeight: "fixed-height",
};

export const gdprVersion = "1.1";
export const SummaryFilesDownloadId = "summary_download";

export const allNcsId = "all";
export const nonCommitteePages = ["all", "search", "user"];
