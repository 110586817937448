import React from "react";
import { Component } from "react";
import { Helmet } from "react-helmet";
import { compose } from "redux";
import { connect } from "react-redux";
import CommitteeList from "../side-menu/CommitteeList";
import { changeNationalCommittee } from "../../../state/app/actions";
import { isConsultant } from "../../../state/user/selectors";

class SearchCommitteeComponent extends Component {
  state = {
    searchPhrase: "",
  };

  handleInputChange = (e) => {
    this.setState({
      searchPhrase: e.target.value,
    });
  };

  handleMenuClick = (selectedElement) => {
    this.props.changeNationalCommittee(selectedElement);
    // this.changeMenuState();
  };

  render() {
    const { isConsultant } = this.props;

    return (
      <>
        <Helmet>
          <title>Search Committees</title>
        </Helmet>
        <header className="layout__title">
          <h2 className="headline-2">{"S-Udvalg"}</h2>
        </header>
        <div className="layout__content">
          {isConsultant && (
            <input
              type="text"
              className="input"
              placeholder="Search Committees"
              onChange={this.handleInputChange}
            />
          )}
          <div className="layout__content panel detail">
            <CommitteeList
              searchPhrase={this.state.searchPhrase}
              // onItemClick={onClickAction}
              onItemClick={this.handleMenuClick}
            />
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  isConsultant: isConsultant(state),
});

const mapDispatchToProps = {
  changeNationalCommittee,
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  SearchCommitteeComponent,
);
