import React, { Component } from "react";
import { withTranslation } from "react-i18next";

import ModalWrapper from "../../../../common/modal-wrapper/ModalWrapper";
import DeleteModal from "../../../documents/components/modal-content/DeleteModal";

class MeetingDeleteModal extends Component {
  render() {
    const { t, showModal, onCancel, onSubmit, itemUri, itemTitle } = this.props;
    return (
      <ModalWrapper
        isOpen={showModal}
        requestCloseHandler={onCancel}
        disableSlide
      >
        <DeleteModal
          id={itemUri}
          title={t("meetings.dialog.delete.meetingTitle", {
            itemName: itemTitle,
          })}
          message={t("meetings.dialog.delete.meetingMessage", {
            itemName: itemTitle,
          })}
          submitHandler={onSubmit}
          cancelHandler={onCancel}
        />
      </ModalWrapper>
    );
  }
}

export default withTranslation("common")(MeetingDeleteModal);
