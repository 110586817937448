import React, { Component, Fragment } from "react";
import { Helmet } from "react-helmet";
import { compose } from "redux";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { setPageTitle } from "../../../utils/pageTitle";
import { findUserVote } from "../../../services/votes/userVote";

import { InfiniteScrollWrapper } from "../../common/infinite-scroll-wrapper/InfiniteScrollWrapper";
import Loader from "../../common/loader/Loader";
import PanelContainer from "../../common/layout/PanelContainer";
import { SlimTabsSwitcher, SlimTab } from "../../common/slim-tabs/SlimTabs";
import { voteStatistics, votes } from "../../../state/votes/selectors";
import { getDeadlineStagesSelectItems } from "../../../state/values/selectors";
import { userId } from "../../../state/user/selectors";

import {
  getDeadlines,
  isFetchingDeadlines,
  hasMoreDeadlinesToLoad,
  getDeadlinesFilters,
} from "../../../state/deadlines/selectors";

import {
  initAggDeadlines,
  pullAggDeadlinesPage,
  resetDeadlinesFilters,
  setDeadlinesFilterValue,
} from "../../../state/deadlines/actions";

import DeadlineCard from "./../deadlines/components/deadline-card/DeadlineCard";
import { TIMEFRAME } from "../../../api/utils";

const SHOW_UPCOMING_DEADLINES = "_show_upcoming";
const SHOW_PAST_DEADLINES = "_show_past";

class AggDeadlines extends Component {
  componentDidMount() {
    this.props.resetDeadlinesFilters();
    this.props.initAggDeadlines();
  }

  handleDeadlinesUpcomingFilter = (selected) => {
    this.props.setDeadlinesFilterValue({
      timeframe:
        selected === SHOW_PAST_DEADLINES ? TIMEFRAME.PAST : TIMEFRAME.FUTURE,
      month: null,
    });
    this.props.initAggDeadlines();
  };

  loadMoreItems = () => {
    this.props.pullAggDeadlinesPage();
  };

  getStageLabel(stages, value) {
    var stage = value ? stages.find((s) => s.value === `${value}`) : null;
    return stage ? stage.label : null;
  }

  renderItems() {
    const { t, i18n, deadlines, voteStatistics } = this.props;
    const { hasMoreDeadlinesToLoad, isFetchingDeadlines, userId, votes } =
      this.props;
    const stages = this.props.deadlineStages;

    if (
      !hasMoreDeadlinesToLoad &&
      !isFetchingDeadlines &&
      (!Object.keys(deadlines) || !Object.keys(deadlines).length)
    ) {
      return (
        <p
          key="nothing_to_show"
          className="headline-4 deadlines__nothing-to-show"
        >
          {t("general.content.nothingToShow")}
        </p>
      );
    }

    return Object.keys(deadlines).map((group, index) => {
      const groupHeading = new Date(group)
        .toLocaleDateString(i18n.language, { month: "long", year: "numeric" })
        .toUpperCase();
      return (
        <Fragment key={index}>
          <span className="card__title">{groupHeading}</span>
          {deadlines[group].map((deadline, i) => (
            <DeadlineCard
              key={i}
              active={true}
              deadline={deadline}
              votes={voteStatistics[deadline.uri]}
              userVote={findUserVote(votes, deadline.uri, userId)}
              stage={this.getStageLabel(stages, deadline.stage)}
              callbackAction={initAggDeadlines}
              customActions={[]}
            />
          ))}
        </Fragment>
      );
    });
  }

  render() {
    const { t, filters, isFetchingDeadlines } = this.props;
    const items = this.renderItems();
    return (
      <>
        <Helmet>
          <title>{setPageTitle(["All Deadlines"])}</title>
        </Helmet>
        <PanelContainer>
          <header className="layout__title">
            <h2 className="headline-2">{t("deadlines.header.name")}</h2>
          </header>
          <div className="layout__content">
            <div className="info-top">
              <span>{t("deadlines.pageInfo")}</span>
            </div>
            <SlimTabsSwitcher
              onTab={this.handleDeadlinesUpcomingFilter}
              defaultTab={SHOW_UPCOMING_DEADLINES}
              selectedTab={
                filters.timeframe === TIMEFRAME.PAST
                  ? SHOW_PAST_DEADLINES
                  : SHOW_UPCOMING_DEADLINES
              }
              enabled={!isFetchingDeadlines}
            >
              <SlimTab
                label={t("deadlines.sidebar.showUpcoming")}
                name={SHOW_UPCOMING_DEADLINES}
              />
              <SlimTab
                label={t("deadlines.sidebar.showPast")}
                name={SHOW_PAST_DEADLINES}
              />
            </SlimTabsSwitcher>
          </div>
          <div className="layout__content layout__content--with-cards deadlines">
            <div className="layout__cards">
              <InfiniteScrollWrapper
                pageStart={0}
                initialLoad={false}
                loadMore={this.loadMoreItems}
                hasMore={this.props.hasMoreDeadlinesToLoad}
                loader={<Loader key="infinity_scroll_loader" type="content" />}
              >
                {items}
              </InfiniteScrollWrapper>
            </div>
          </div>
        </PanelContainer>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  isFetchingDeadlines: isFetchingDeadlines(state),
  hasMoreDeadlinesToLoad: hasMoreDeadlinesToLoad(state),
  filters: getDeadlinesFilters(state),
  deadlines: getDeadlines(state),
  votes: votes(state),
  voteStatistics: voteStatistics(state),
  deadlineStages: getDeadlineStagesSelectItems(state),
  userId: userId(state),
});

const mapDispatchToProps = {
  initAggDeadlines,
  pullAggDeadlinesPage,
  resetDeadlinesFilters,
  setDeadlinesFilterValue,
};

export default compose(
  withTranslation("common"),
  connect(mapStateToProps, mapDispatchToProps),
)(AggDeadlines);
