import React from "react";

import userManager from "../../../services/auth/openIdClient";
import AnalyticsTracking from "../../../services/analytics-tracking/analytics-tracking";

const Auth = (WrappedComponent) =>
  class extends React.Component {
    constructor() {
      super();
      this.state = { isAuthenticated: false };
    }

    componentDidMount() {
      userManager.getUser().then((user) => {
        if (!user) {
          userManager.signinRedirect({
            state: { redirectUrl: window.location.href },
          });
        } else if (user.expired) {
          userManager
            .signinSilent()
            .then((user) => this.setState({ isAuthenticated: !!user }))
            .catch(() =>
              userManager.signinRedirect({
                state: { redirectUrl: window.location.href },
              }),
            );
        } else {
          this.setState({ isAuthenticated: !!user });
          AnalyticsTracking.setUser(user.profile.sub);
        }
      });
    }

    render() {
      return this.state.isAuthenticated ? (
        <WrappedComponent {...this.props} />
      ) : null;
    }
  };

export default Auth;
