import React from "react";
import classNames from "classnames";

export default ({
  isPinned,
  children,
  className,
  withOffset,
  innerRef,
  resourceType,
  resourceId,
}) => {
  return (
    <div
      ref={innerRef}
      className={classNames(
        "card",
        {
          [className]: className,
        },
        { "card--with-offset": withOffset },
      )}
      data-notification-type={resourceType}
      data-resource-id={resourceId}
      id={resourceId}
    >
      {isPinned && (
        <div className="card__ribbon">
          <span className="icon icon-ribbon" aria-hidden="true"></span>
        </div>
      )}

      {children}
    </div>
  );
};
