import {
  voteType,
  voteLabelMap,
  regularVotes,
  reviewVotes,
} from "../../utils/constants";

export function normalizeVotes(data) {
  let object = data.reduce((obj, item) => {
    if (typeof obj[item.deadlineUri] === "undefined")
      obj[item.deadlineUri] = [];
    obj[item.deadlineUri].push(normalizeVote(item));
    return obj;
  }, {});

  return object;
}

export function normalizeVote(vote) {
  return {
    userID: vote.memberId,
    vote: transformVote(
      typeof vote.memberVote !== "undefined" ? vote.memberVote : vote.vote,
    ),
    uri: vote.uri,
    deadlineUri: vote.deadlineUri,
  };
}

function transformVote(vote) {
  return {
    value: vote,
    label: voteLabelMap[vote],
  };
}

export function transformVotesToStatistics(data) {
  let statistics = {};
  for (let [key, value] of Object.entries(data)) {
    statistics[key] = transformDeadlineVotesToStatistics(value);
  }

  return statistics;
}

export function transformDeadlineVotesToStatistics(data) {
  let stats = Object.keys(voteType).reduce(
    (acc, ck) => ({
      ...acc,
      [ck]: data.filter((vote) => filterVotes(vote, voteType[ck])).length,
    }),
    {},
  );

  stats.total = data.filter((v) => regularVotes.includes(v.vote.value)).length;
  stats.totalReview = data.filter((v) =>
    reviewVotes.includes(v.vote.value),
  ).length;

  return stats;
}

export function removeDeadlineVotesFromStatistics(state, data) {
  const newStats = transformDeadlineVotesToStatistics(
    state.data[data.deadlineUri].filter((d) => d.uri !== data.uri),
  );

  // if(newStats.total === 0){
  //     const outStats = {
  //         ...state.statistics
  //     };

  //     delete outStats[data.deadlineUri];
  //     return outStats;
  // }

  return {
    ...state.statistics,
    [data.deadlineUri]: newStats,
  };
}

function filterVotes(data, value) {
  return data.memberVote === value || (data.vote && data.vote.value === value);
}

export function normalizeOverride(override) {
  return {
    vote: override.vote,
  };
}
