import { api } from "../api";
import { transformCommitteeName } from "../../utils/transform";

export function getInfoAboutCommittee(committeeName) {
  return api.get(
    `/api/InformationAboutCommittees/GetInfoAboutCommittee?committeeName=${transformCommitteeName(
      committeeName,
    )}`,
  );
}

export function addInfoAboutCommittee(committeeName) {
  return api.post(
    `/api/InformationAboutCommittees/AddInfoAboutCommittee?committeeName=${transformCommitteeName(
      committeeName,
    )}`,
  );
}

export function updateCommitteeDescription(committeeName, description) {
  return api.post(
    `/api/InformationAboutCommittees/UpdateCommitteeDescription?committeeName=${transformCommitteeName(
      committeeName,
    )}&description=${description}`,
  );
}

export function AddDocumentToInfoAboutCommittee(committeeName, formData) {
  return api.post(
    `/api/InformationAboutCommittees/AddDocumentToInfoAboutCommittee?committeeName=${transformCommitteeName(
      committeeName,
    )}`,
    formData,
  );
}

export function AddSpecialDocumentToInfoAboutCommittee(
  committeeName,
  formData,
) {
  return api.post(
    `/api/InformationAboutCommittees/AddSpecialDocumentToInfoAboutCommittee?committeeName=${transformCommitteeName(
      committeeName,
    )}`,
    formData,
  );
}

export function UpdateDocumentInInfoAboutCommittee(committeeName, formData) {
  return api.post(
    `/api/InformationAboutCommittees/UpdateDocumentInInfoAboutCommittee?committeeName=${transformCommitteeName(
      committeeName,
    )}`,
    formData,
  );
}

export function UpdateSpecialDocumentToInfoAboutCommittee(
  committeeName,
  formData,
) {
  return api.post(
    `/api/InformationAboutCommittees/UpdateSpecialDocumentInInfoAboutCommittee?committeeName=${transformCommitteeName(
      committeeName,
    )}`,
    formData,
  );
}

export function AddFileToDocument(id, formData) {
  return api.post(
    `/api/InformationAboutCommittees/AddFileToDocument?id=${id}`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    },
  );
}

export function AddFileToSpecialDocument(id, formData) {
  return api.post(
    `/api/InformationAboutCommittees/AddFileToSpecialDocumentDocument?id=${id}`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    },
  );
}

export function DeleteDocumentFromInfoAboutCommittee(committeeName, formData) {
  return api.post(
    `/api/InformationAboutCommittees/DeleteDocumentFromInfoAboutCommittee?committeeName=${transformCommitteeName(
      committeeName,
    )}`,
    formData,
  );
}

export function DeleteSpecialDocumentFromInfoAboutCommittee(
  committeeName,
  formData,
) {
  return api.post(
    `/api/InformationAboutCommittees/DeleteSpecialDocumentFromInfoAboutCommittee?committeeName=${transformCommitteeName(
      committeeName,
    )}`,
    formData,
  );
}
