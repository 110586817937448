import React, { Component } from "react";
import className from "classnames";
import { withTranslation } from "react-i18next";
import { Link, NavLink } from "react-router-dom";
import { isMobile } from "react-device-detect";

class Navigation extends Component {
  constructor(props) {
    super(props);

    this.navRef = React.createRef();
  }

  createLink = (link) => {
    const { t, match } = this.props;
    let urlPart = match.path !== "/" ? match.path + "/" : "/";
    urlPart = urlPart.replace("/user", "");

    return (
      <NavLink
        to={`${urlPart}${link.url}`}
        className={"side-menu__title"}
        activeClassName="active"
      >
        {t(link.name)}
        {/* TODO: Notification counters
            {element.counter ? (
                        <span className="side-menu__counter">{this.setCounter(element.counter)}</span>
                    ) : null} */}
      </NavLink>
    );
  };

  createNavElement = (link, index) => {
    return (
      <li
        key={index}
        className={className("side-menu__link", {
          "navigation--inactive": link.disabled,
        })}
      >
        {this.createLink(link, index + 1)}
      </li>
    );
  };

  scrollToNavLink = () => {
    if (!isMobile || !this.navRef.current) return;
    setTimeout(() => {
      const activeElement = this.navRef.current.querySelector(".active");
      this.navRef.current.scrollLeft = activeElement.offsetLeft;
    }, 150);
  };

  // side menu search need to be developed if needed
  sideMenuSearch = () => {
    return (
      <li className="side-menu__search">
        <div>
          <input placeholder="Search in selection" />
          <button
            className="search-box__button"
            onClick={this.handleButtonClick}
          >
            <span
              className="icon icon-sidebar-search"
              aria-hidden="true"
            ></span>
          </button>
        </div>
      </li>
    );
  };

  render() {
    const { links } = this.props;

    isMobile && this.scrollToNavLink();

    return (
      <ul className="side-menu list" ref={this.navRef}>
        {links.map(this.createNavElement)}
        {/* {this.sideMenuSearch()} */}
      </ul>
    );
  }
}

export default withTranslation("common")(Navigation);
