import {
  takeLatest,
  takeEvery,
  call,
  select,
  put,
  take,
  all,
} from "redux-saga/effects";
import * as actions from "../state/meetings/actions";
import { selectedNationalCommittee } from "../state/national-committees/selectors";
import {
  getMeetingsUrl,
  getMeetingUrl,
  updateMeetingUrl,
  deleteMeetingUrl,
  createMeetingUrl,
  addParticipantUrl,
  updateParticipantUrl,
} from "../api/resources/meetings";
import { getDocumentSafeUrl } from "../api/resources/document";
import {
  waitForNotificationDocumentId,
  waitForNotificationTypedDocumentId,
} from "../api/resources/notifications";
import { buildQueryParams } from "../services/meetings/url";
import { addPageQueryParam } from "../api/utils";
import history from "../utils/history";
import * as selectors from "../state/meetings/selectors";
import {
  addGeneralMeetingInformation,
  initMeetingFormWithDocuments,
} from "../state/meetings/actions";
import { initDocuments } from "../state/document/actions";
import * as documentActions from "../state/document/actions";
import { getNewMeetingFormData } from "../state/meetings/selectors";
import { mapToApiModel } from "../api/mappers/meeting";
import { transformCommitteeName } from "../utils/transform";
import {
  generateCloseDialogLink,
  generateDialogLink,
  DIALOG_TYPES,
  generateDialogLinkForPage,
  PAGE_TYPES,
} from "../utils/dynamicLink";
import { normalizeMeeting } from "../state/meetings/normalizers";
import { handleCheckUserPrivilleges } from "./user";
import * as userSelectors from "../state/user/selectors";
import {
  normalizeDocument,
  removeReferenceById,
} from "../state/document/normalizers.js";
import { notificationActionType, notificationType } from "../utils/constants";
import { documentFilters } from "../utils/documentUtils";
import { normalizeObjectOfReferences } from "../state/references/normalizers";
import { getDeadlineUrl } from "../api/resources/deadlines";
import { referenceListToReferenceDataPerType } from "../utils/references";
import { handleCreateNotificationBySystem } from "./notifications";

function* handleInitMeetings() {
  yield call(handlePullMeetingsPageRequest);
}

// function* handleResetMeetingsFilters() {
//     // yield call(addToUrlMeetings);
// }

function* handleAddMeetingDocuments(action) {
  try {
    throw new Error("handleAddMeetingDocuments is not used");

    // const meeting = yield select(selectors.getMeeting);
    // const documentToAdd = normalizeDocument(action.payload)
    // meeting.references = [...meeting.references,  documentToAdd];

    // const response = yield call(updateMeeting, meeting, false);

    // if (response.status === 200) {
    //     yield put(actions.fetchCurrentMeetingDocuments());
    //     yield put(actions.updateMeetingSucceeded(response.data));
    // } else {
    //     yield put(actions.updateMeetingFailed("Failed to update meeting"));
    // }
  } catch (error) {
    yield put(actions.updateMeetingFailed("Failed to update meeting"));
    console.error(`handleAddMeetingDocuments failed - ${error}`);
  }
}

function* handleSetMeetingDocuments(action) {
  try {
    throw new Error("handleSetMeetingDocuments is not used");
    // const meeting = yield select(selectors.getMeeting);
    // meeting.references = action.payload;

    // const response = yield call(updateMeeting, meeting, false);

    // if (response.status === 200) {
    //     yield put(actions.fetchCurrentMeetingDocuments());
    //     yield put(actions.updateMeetingSucceeded(response.data));
    // } else {
    //     yield put(actions.updateMeetingFailed("Failed to update meeting"));
    // }
  } catch (error) {
    yield put(actions.updateMeetingFailed("Failed to update meeting"));
    console.error(`handleSetMeetingDocuments failed - ${error}`);
  }
}

function* handleRemoveReferenceFromMeeting(action) {
  try {
    const meeting = yield select(selectors.getMeeting);
    // meeting.references = meeting.references.filter(doc => doc.url !== action.payload);

    // for(const key in meeting.referencesPerType) {
    //   meeting.referencesPerType[key] = meeting.referencesPerType[key].filter((doc) => doc.uri !== action.payload);
    // }
    meeting.referencesPerType = removeReferenceById(
      meeting.referencesPerType,
      action.payload,
    );

    const response = yield call(updateMeetingUrl, meeting, false);

    if (response.status === 200) {
      yield put(actions.fetchCurrentMeetingDocuments());
      yield put(actions.updateMeetingSucceeded(response.data));
    } else {
      yield put(actions.updateMeetingFailed("Failed to update meeting"));
    }
  } catch (error) {
    yield put(actions.updateMeetingFailed("Failed to update meeting"));
    console.error(`handleRemoveReferenceFromMeeting failed - ${error}`);
  }
}

function* handlePullMeetingsPageRequest() {
  try {
    const committeeId = yield select(selectedNationalCommittee);
    const memberId = yield select(userSelectors.userId);
    const pagination = yield select(selectors.getPagination);
    const filters = yield select(selectors.getMeetingsFilters);
    const pageToLoad = pagination.pagesLoaded;

    const queryParams = addPageQueryParam(
      buildQueryParams(memberId, filters),
      pageToLoad,
    );
    const response = yield call(getMeetingsUrl, committeeId, queryParams);
    const pagesToLoad = Math.ceil(
      response.data.total / response.data.pageLength,
    );

    const page = response.data.start / response.data.pageLength + 1;
    const maxPage = Math.ceil(response.data.total / response.data.pageLength);

    if (response.status === 200) {
      yield put(
        actions.pullMeetingsPageSucceeded({
          ...response.data,
          pagination: {
            pageLoaded: pageToLoad,
            pagesToLoad: pagesToLoad,
            hasMoreToLoad: parseInt(page) < parseInt(maxPage),

            // response.data.results
            // && response.data.results.length > 0
            // && parseInt(response.data.total) > parseInt(response.data.pageLength)
          },
        }),
      );
    } else {
      yield put(actions.pullMeetingsPageFailed(response.data.message));
    }
  } catch (error) {
    console.error(`filter meetings failed - ${error}`);
  }
}

function* fetchMeeting(action) {
  try {
    const response = yield call(getMeetingUrl, action.payload);

    if (response && response.status === 200) {
      yield put(actions.fetchMeetingSucceeded(response.data));
    } else {
      yield put(actions.fetchMeetingFailed(response.data));
    }
  } catch (error) {
    console.error(`fetch meeting failed - ${error}`);
  }
}

function* fetchCurrentMeetingDocuments() {
  try {
    const existingMeeting = yield select(selectors.getMeeting);
    const response = yield call(getMeetingUrl, existingMeeting.uri);

    if (!response || response.status !== 200) {
      yield put(actions.fetchCurrentMeetingDocumentsFailed());
      return;
    }

    const { references } = response.data;
    const referenceCategorized =
      referenceListToReferenceDataPerType(references);

    const documentRequestLists = referenceCategorized.document?.map((element) =>
      call(getDocumentSafeUrl, element.uri),
    );
    const documentsResponse = documentRequestLists
      ? yield all(documentRequestLists)
      : [];
    const document = documentFilters.filterEmptyDocuments(documentsResponse);

    const deadlineRequestLists = referenceCategorized.deadline?.map((element) =>
      call(getDeadlineUrl, element.uri),
    );
    const deadlinesResponse = deadlineRequestLists
      ? yield all(deadlineRequestLists)
      : [];
    const deadline = documentFilters.filterEmptyDocuments(deadlinesResponse);

    const newReferences = { document, deadline };

    // let references = yield all(meeting.references.map(doc => call(getDocumentSafe,doc.uri)));
    // references = references.filter(d => d !== null && d.status === 200);

    yield put(actions.fetchCurrentMeetingDocumentsSucceeded(newReferences));
  } catch (error) {
    yield put(actions.fetchCurrentMeetingDocumentsFailed());
    console.error(`fetchCurrentMeetingDocuments - ${error}`);
  }
}

function* fetchMeetingReferences() {
  try {
    yield take(actions.fetchMeetingSucceeded);
    const meeting = yield select(selectors.getMeeting);

    const documentTargets = meeting.referencesRawResponsePerType.document ?? [];
    const deadlineTargets = meeting.referencesRawResponsePerType.deadline ?? [];

    const documentsResponse = yield all(
      documentTargets.map((element) => call(getDocumentSafeUrl, element.uri)),
    );
    const document = documentFilters.filterEmptyDocuments(documentsResponse);

    const deadlinesResponse = yield all(
      deadlineTargets.map((element) => call(getDeadlineUrl, element.uri)),
    );
    const deadline = documentFilters.filterEmptyDocuments(deadlinesResponse);

    const referencesPerType = { document, deadline };

    yield put(actions.fetchMeetingReferencesSucceeded(referencesPerType));
  } catch (error) {
    yield put(actions.fetchMeetingReferencesFailed([]));
    console.error(`fetch meeting documents failed - ${error}`);
  }
}

function* handleUpdateMeeting(action) {
  try {
    const committeeId = yield select(selectedNationalCommittee);
    const updatedMeeting = action.payload;

    const privillegesAction = {
      type: action.type,
      payload: {
        url: `/${transformCommitteeName(committeeId)}/meetings`,
      },
    };

    yield call(handleCheckUserPrivilleges, privillegesAction);

    const response = yield call(
      updateMeetingUrl,
      updatedMeeting,
      updatedMeeting.notifyOnUpdate,
    );
    if (response.status === 200) {
      yield put(actions.updateMeetingSucceeded(response.data));
      history.push(
        generateDialogLink(DIALOG_TYPES.MEETING_DETAILS, updatedMeeting.uri),
      );

      if (updatedMeeting.notifyOnUpdate) {
        // Post notification
        yield call(handleCreateNotificationBySystem, {
          payload: {
            ...updatedMeeting,
            description: updatedMeeting.longLocation,
            notify: updatedMeeting.notifyImmediately,
            type: notificationType.meeting,
            eventType: notificationActionType.update,
            references: [
              {
                type: notificationType.meeting,
                uri: updatedMeeting.uri,
              },
            ],
          },
        });

        // notification is done on FE
        // yield call(waitForNotificationDocumentId, updatedMeeting.uri);
      }
    } else {
      yield put(actions.updateMeetingFailed("Failed to update meeting"));
    }
  } catch (error) {
    console.error(`update meeting failed - ${error}`);
  }
}

function* handleDeletingMeeting(action) {
  try {
    const meetingUri = action.payload;
    const privillegesAction = {
      type: action.type,
    };

    yield call(handleCheckUserPrivilleges, privillegesAction);

    const response = yield call(deleteMeetingUrl, meetingUri);
    if (response.status === 200) {
      yield put(actions.deleteMeetingSucceeded(response.data));
      history.push(generateCloseDialogLink());
    } else {
      yield put(actions.deleteMeetingFailed("Failed to delete meeting"));
    }
  } catch (error) {
    console.error(`delete meeting failed - ${error}`);
  }
}

function* handleCreateMeeting() {
  try {
    const committeeId = yield select(selectedNationalCommittee);
    const meetingData = yield select(getNewMeetingFormData);

    const privillegesAction = {
      type: "creating",
      payload: {
        url: `/${transformCommitteeName(committeeId)}/meetings`,
      },
    };

    yield call(handleCheckUserPrivilleges, privillegesAction);

    const response = yield call(
      createMeetingUrl,
      committeeId,
      mapToApiModel(meetingData),
    );
    if (response.status === 200) {
      const succeededResponse = yield put(
        actions.createMeetingSucceeded(response.data),
      );
      history.push(
        generateDialogLink(
          DIALOG_TYPES.MEETING_DETAILS,
          succeededResponse.payload.uri,
        ),
      );

      if (meetingData.notifyOnUpdate) {
        // post notification
        yield call(handleCreateNotificationBySystem, {
          payload: {
            ...meetingData,
            description: meetingData.longLocation,
            notify: meetingData.notifyImmediately,
            type: notificationType.meeting,
            eventType: notificationActionType.add,
            references: [
              {
                type: notificationType.meeting,
                uri: succeededResponse.payload.uri,
              },
            ],
          },
        });

        // What does this do???
        // yield call(
        //   waitForNotificationTypedDocumentId,
        //   response.data.uri,
        //   notificationActionType.add,
        // );
      }
    } else {
      yield put(actions.createMeetingFailed("Failed to create meeting"));
    }
  } catch (error) {
    console.error(`create meeting failed - ${error}`);
  }
}

function* handleInitMeetingForm(action) {
  try {
    yield put(initDocuments());

    const meetingUri = action.payload;
    let meeting = yield select(selectors.getMeeting);

    if (!meeting) {
      let meetingData = yield call(getMeetingUrl, meetingUri);
      meeting = normalizeMeeting(meetingData.data);

      // -------------

      const documentUri = [
        ...(meeting.referencesRawResponsePerType.document?.map(
          (doc) => doc.uri,
        ) ?? []),
      ];
      const deadlineUri = [
        ...(meeting.referencesRawResponsePerType.deadline?.map(
          (doc) => doc.uri,
        ) ?? []),
      ];

      const documentsResponse = yield all(
        documentUri.map((uri) => call(getDocumentSafeUrl, uri)),
      );
      const document = documentFilters.filterEmptyDocuments(documentsResponse);

      const deadlinesResponse = yield all(
        deadlineUri.map((uri) => call(getDeadlineUrl, uri)),
      );
      const deadline = documentFilters.filterEmptyDocuments(deadlinesResponse);

      meeting.referencesPerType = normalizeObjectOfReferences({
        document,
        deadline,
      });

      // -------------

      yield put(addGeneralMeetingInformation(meeting));

      // let references = yield all(meeting.referencesUri.map(uri => call(getDocumentSafe, uri)));
      // references = references.filter(d => d !== null && d.status === 200);

      // yield put(initMeetingFormWithDocuments(references));
    }
  } catch (error) {
    console.error(`init meeting form failed - ${error}`);
  }
}

function* handleCreateMeetingFromDocument(action) {
  try {
    const committeeId = yield select(selectedNationalCommittee);
    const selectedDocument = action.payload;
    yield put(actions.setFormSelectedMeetingDocuments([selectedDocument]));

    yield put(
      actions.addGeneralMeetingInformation({
        title: selectedDocument.title,
        committee: selectedDocument.committee,
      }),
    );

    history.push(
      generateDialogLinkForPage(
        committeeId,
        PAGE_TYPES.MEETING,
        DIALOG_TYPES.CREATE_MEETING,
      ),
    );
  } catch (error) {
    console.error(`create meeting from selected documents failed - ${error}`);
  }
}

function* handleDownloadMeetingDocuments(action) {
  try {
    const meetingId = action.payload;
    let uris = yield select(selectors.getMeetingDocumentsUri, meetingId);
    yield put(
      documentActions.downloadZipPackage({
        id: meetingId,
        packageName: `${meetingId || "documents"}.zip`,
        uris: uris,
      }),
    );
  } catch (error) {
    console.error(`download meeting documents failed - ${error}`);
  }
}

function* handleAddParticipant(action) {
  try {
    const { meetingUri, userId, attending } = action.payload;
    const result = yield call(addParticipantUrl, meetingUri, userId, attending);

    if (result.status === 200) {
      yield put(actions.addParticipantSucceeded(result.data));
    } else {
      yield put(actions.addParticipantFailed(result.data));
    }
  } catch (error) {
    console.error(`add participant failed - ${error}`);
  }
}

function* handleUpdateParticipant(action) {
  try {
    const { participantId, attending } = action.payload;
    const result = yield call(updateParticipantUrl, participantId, attending);

    if (result.status === 200) {
      yield put(actions.updateParticipantSucceeded(result.data));
    } else {
      yield put(actions.updateParticipantFailed(result.data));
    }
  } catch (error) {
    console.error(`update participant failed - ${error}`);
  }
}

export default function* meetingsSaga() {
  yield takeLatest(actions.initMeetings, handleInitMeetings);
  yield takeLatest(
    actions.initMeetingFormWithGeneralInfo,
    handleInitMeetingForm,
  );

  yield takeLatest(actions.addMeetingDocuments, handleAddMeetingDocuments);
  yield takeLatest(actions.setMeetingDocuments, handleSetMeetingDocuments);
  yield takeLatest(
    actions.removeReferenceFromMeeting,
    handleRemoveReferenceFromMeeting,
  );
  yield takeLatest(actions.fetchMeeting, fetchMeeting);
  yield takeLatest(
    actions.fetchCurrentMeetingDocuments,
    fetchCurrentMeetingDocuments,
  );

  yield takeEvery(
    actions.pullMeetingsPageRequest,
    handlePullMeetingsPageRequest,
  );

  yield takeLatest(actions.updateMeeting, handleUpdateMeeting);
  yield takeLatest(actions.deleteMeeting, handleDeletingMeeting);
  yield takeLatest(actions.createMeeting, handleCreateMeeting);

  yield takeLatest(
    actions.createMeetingFromDocument,
    handleCreateMeetingFromDocument,
  );
  yield takeLatest(
    actions.downloadMeetingDocuments,
    handleDownloadMeetingDocuments,
  );
  yield takeLatest(actions.fetchMeetingReferences, fetchMeetingReferences);
  yield takeLatest(actions.addParticipant, handleAddParticipant);
  yield takeLatest(actions.updateParticipant, handleUpdateParticipant);
}
