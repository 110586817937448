import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";
import types from "../../../../common/modal-wrapper/contentTypes";
import { Link } from "react-router-dom";
import { getModalDetails } from "../../../../../state/document/selectors";
import {
  resetModalState,
  setModalState,
} from "../../../../../state/document/actions";
import DeleteModal from "../../../documents/components/modal-content/DeleteModal";
import MeetingTable from "../../../meetings/pages/meeting-tab/MeetingTable";
import ModalWrapper from "../../../../common/modal-wrapper/ModalWrapper";
import { REFERENCE_TAB } from "../../../../../state/references/reducer";

class ReferenceMeetingRow extends Component {
  removeMeeting = (id) => {
    const { onRemoveMeeting, resetModalState } = this.props;
    onRemoveMeeting(id);
    resetModalState();
  };

  renderRemoveMeetingFromListModal = () => {
    const { modal, t, resetModalState } = this.props;

    return (
      <DeleteModal
        id={modal.content.uri}
        title={t("references.meeting.modal.delete.title", {
          itemName: modal.content.title,
        })}
        message={t("references.meeting.modal.delete.message", {
          itemName: modal.content.title,
        })}
        submitHandler={this.removeMeeting}
        cancelHandler={resetModalState}
        deleteButtonText={t("general.button.remove")}
      />
    );
  };

  showModalToRemoveMeetingFromList = (element) => {
    return this.props.setModalState({
      isOpen: true,
      contentType: types.REMOVE_FILE_FROM_MEETING_LIST,
      content: element,
    });
  };

  renderListView = () => {
    const { t, selectedMeetings } = this.props;

    if (!selectedMeetings?.length) return null;

    return (
      <>
        <div className="group group--first">
          <MeetingTable
            useActionBox
            meetings={this.props.selectedMeetings}
            additionalModals={{
              [types.REMOVE_FILE_FROM_MEETING_LIST]:
                this.renderRemoveMeetingFromListModal,
            }}
            // additionalModals={[this.renderRemoveDocumentFromListModal]}
            rowActions={{
              [t("documents.action.removeFromNotification")]:
                this.showModalToRemoveMeetingFromList,
            }}
          />
        </div>
      </>
    );
  };

  renderButton = () => {
    const { t, moveToLink, onSelect, isModal } = this.props;
    const selectFromMeetings = t("references.meeting.selectFromList");

    return isModal ? (
      <button
        className="button button--small button--secondary"
        onClick={(e) => {
          onSelect(REFERENCE_TAB.MEETINGS);
        }}
      >
        {selectFromMeetings}
      </button>
    ) : (
      <Link className="button button--small button--secondary" to={moveToLink}>
        {selectFromMeetings}
      </Link>
    );
  };

  render() {
    const { t, disableEdit, selectedMeetings } = this.props;

    if (disableEdit && !selectedMeetings?.length) return null;

    return (
      <>
        <div className="gray-box">
          <div className="group group--small group__flex detail__group group--underlined">
            <span className="headline-4 u-text-bold" id="documents">
              {t("meetings.header.name")}
            </span>
            {!disableEdit && this.renderButton()}
          </div>
          {this.renderListView()}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  modal: getModalDetails(state),
});

const mapDispatchToProps = {
  setModalState,
  resetModalState,
};

export default compose(
  withTranslation("common"),
  connect(mapStateToProps, mapDispatchToProps),
)(ReferenceMeetingRow);
