import React, { Component } from "react";

class List extends Component {
  renderRow = (element, index) => {
    const { handleElementClick } = this.props;
    return element.children && element.children.length ? (
      <button
        key={index}
        className="list__element list__pointer-cursor"
        onClick={() => handleElementClick(element)}
      >
        <div className="name">{element.name}</div>
        <div className="label">{element.description}</div>
        <div className="last">
          {element.children && (
            <span className="counter">{element.children.length}</span>
          )}
          <span
            className="icon icon-arrow-right icon--large"
            aria-hidden="true"
          ></span>
        </div>
      </button>
    ) : (
      <div key={index} className="list__element">
        <div className="name">{element.name}</div>
        <div className="label">{element.description}</div>
        <div className="last empty"></div>
      </div>
    );
  };

  render() {
    const { list } = this.props;

    return <div className="list list--dark">{list.map(this.renderRow)}</div>;
  }
}

export default List;
