import { getBinaryFileDownloadUri } from "../../utils/dynamicLink";

export const normalizeComments = (data) => {
  if (data.length === 0) {
    return {};
  }

  const sortedData = data.sort(
    (a, b) => new Date(a.createdDate) - new Date(b.createdDate),
  );
  const reducedData = sortedData.reduce((previous, current, idx) => {
    const comment = normalizeComment(current, idx + 1);

    typeof previous[current.deadlineUri] !== "undefined"
      ? previous[current.deadlineUri].push(comment)
      : (previous[current.deadlineUri] = [comment]);

    return previous;
  }, {});

  reducedData[data[0].deadlineUri] = reducedData[data[0].deadlineUri].sort(
    (a, b) =>
      a.clause - b.clause || new Date(a.updateDate) - new Date(b.updateDate),
  );
  return reducedData;
};

export const normalizeComment = (data, idx) => {
  return {
    id: data.referenceId,
    referenceId: idx,
    guid: data.guid,
    uri: data.uri,
    committee: data.udvalg,
    subcommittee: data.deadlineCommitteeReference,
    line: data.line,
    clause: data.clause,
    paragraph: data.paragraph,
    type: data.type,
    comment: data.comment,
    proposal: data.proposedChange,
    createdDate: data.createdDate,
    updateDate: data.lastModifiedDate,
    eventDate: data.eventDate,
    author: data.author,
    authorId: data.memberId,
    attachments: data.references.map((reference) => ({
      name: reference.fileName,
      uri: reference.uri,
      downloadUrl: getBinaryFileDownloadUri(reference),
    })),
  };
};
