import { createAction } from "redux-actions";

export const GET_EMAILS_FREQUENCY = "SETTINGS/get_emails_frequency";
export const GET_EMAILS_FREQUENCY_SUCCEEDED =
  "SETTINGS/get_emails_frequency_succeeded";
export const GET_EMAILS_FREQUENCY_FAILED =
  "SETTINGS/get_emails_frequency_failed";

export const getEmailsFrequency = createAction(GET_EMAILS_FREQUENCY);
export const getEmailsFrequencySucceeded = createAction(
  GET_EMAILS_FREQUENCY_SUCCEEDED,
);
export const getEmailsFrequencyFailed = createAction(
  GET_EMAILS_FREQUENCY_FAILED,
);
