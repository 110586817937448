import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";
import { formatDate } from "../../../../../utils/transform";
import Checkbox from "../../../../common/form/checkbox/Checkbox";
import Toggler from "../../../../common/toggler/Toggler";
import ActionBox from "../../../../common/action-box/ActionBox";
import ModalWrapper from "../../../../common/modal-wrapper/ModalWrapper";
import contentTypes from "../../../../common/modal-wrapper/contentTypes";
import { getModalDetails } from "../../../../../state/document/selectors";
import {
  DIALOG_TYPES,
  generateDialogLink,
} from "../../../../../utils/dynamicLink";
import { Link } from "react-router-dom";
import { setModalState } from "../../../../../state/document/actions";

class DeadlineTable extends Component {
  basicModals = {};

  renderActionBox = (element) => {
    const { rowActions } = this.props;

    return (
      <td className="last">
        <Toggler
          calculatePosition={true}
          clickHandler={() => this.setState({ actionBoxOpened: false })}
          isLast={element.isLast}
        >
          <ActionBox>
            {Object.keys(rowActions).map((name, index) => {
              return (
                <li key={index} className="list__element">
                  <button
                    className="button button--link actions-list__button"
                    onClick={() => rowActions[name](element)}
                  >
                    {name}
                  </button>
                </li>
              );
            })}
          </ActionBox>
        </Toggler>
      </td>
    );
  };

  renderRow = (deadline, idx) => {
    const { selectedDeadlines, useCheckbox, useActionBox } = this.props;

    const deadlineDetailsUrl = generateDialogLink(
      DIALOG_TYPES.DEADLINE_DETAILS,
      deadline.uri,
    );

    const newDeadline = {
      ...deadline,
      url: deadline.uri,
      selected: !!selectedDeadlines?.some((el) => el.uri === deadline.uri),
    };

    return (
      <tr
        className="deadlines-table__row"
        key={`tr-${idx}`}
        title={deadline.description}
      >
        {useCheckbox && (
          <td className="checkbox">
            <Checkbox
              element={newDeadline}
              onClick={this.props.onClickCheckbox}
            />
          </td>
        )}
        <td className="committee">{deadline.committee}</td>
        <td className="committeeName">{deadline.committeeName}</td>
        <td className="title">
          <Link to={deadlineDetailsUrl}>{deadline.title}</Link>
        </td>
        <td className="date">{formatDate(deadline.date)}</td>
        {useActionBox && this.renderActionBox(deadline)}
      </tr>
    );
  };

  renderModal = () => {
    const {
      additionalModals,
      modal: { contentType },
    } = this.props;

    if (additionalModals) {
      Object.keys(additionalModals).forEach(
        (key) => (this.basicModals[key] = additionalModals[key]),
      );
    }

    if (this.basicModals[contentType]) {
      return this.basicModals[contentType]();
    }
  };

  requestCloseHandler = () => {
    this.props.setModalState({ isOpen: false });
  };

  render() {
    const { deadlines, useCheckbox, useActionBox } = this.props;

    return (
      <div className="table-wrapper">
        <div className="table-content">
          <table className="table">
            <thead className="deadlines-table">
              <tr>
                {useCheckbox && <th className="checkbox" />}
                <th className="committee">Committee</th>
                <th className="committeeName">Committee Name</th>
                <th className="title">Title</th>
                <th className="date">Date</th>
                {useActionBox && <th className="last" />}
              </tr>
            </thead>
            <tbody className="deadlines-table">
              {deadlines.map(this.renderRow)}
            </tbody>
          </table>
        </div>
        {this.props.modal.contentType ===
          contentTypes.REMOVE_FILE_FROM_DEADLINE_LIST && (
          <ModalWrapper
            contentType={this.props.modal.contentType}
            isOpen={this.props.modal.isOpen}
            requestCloseHandler={this.requestCloseHandler}
            disableSlide
          >
            {this.renderModal()}
          </ModalWrapper>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  // Deadlines: getDeadlines(state),
  // modal: state.modal,
  modal: getModalDetails(state),
});

const mapDispatchToProps = {
  setModalState,
};

export default compose(
  withTranslation("common"),
  connect(mapStateToProps, mapDispatchToProps),
)(DeadlineTable);
