import { normalizeDocument } from "../document/normalizers";
import { makeOtherCommitteeIdIfEmpty } from "../../utils/transform";

function normalizeDetails(documents) {
  return documents.reduce((acc, cur) => {
    const committeeReference = makeOtherCommitteeIdIfEmpty(cur.committee);
    acc[committeeReference] = acc[committeeReference] || [];
    acc[committeeReference].push(cur);
    return acc;
  }, {});
}

export function normalizeDocumentSummary(data) {
  const normalizedDocuments = data.map(normalizeDocument);
  return normalizeDetails(normalizedDocuments);
}

export function updateDocument(details, data) {
  let documents = Object.keys(details)
    .map((k) => details[k])
    .reduce((acc, cur) => [...acc, ...cur], []);
  documents = documents.filter((d) => d.url !== data.url);
  documents = [...documents, data];
  return normalizeDetails(documents);
}
