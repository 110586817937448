import React, { Component } from "react";
import Linkify from "react-linkify";
import { compose } from "redux";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import classNames from "classnames";
import { downloadNotificationDocuments } from "../../../../../state/notifications/actions";
import { isDownloadingFile } from "../../../../../state/document/selectors";
import { targetBlankDecorator } from "../../../../../utils/LinkifyDecorator";

class NotificationDetailsTab extends Component {
  state = {
    referencesCounter: null,
  };

  componentDidUpdate(prevProps) {
    if (prevProps.references !== this.props.references) {
      this.setState({
        referencesCounter: this.props.references.filter(
          (document) => !document.isLink,
        ).length,
      });
    }
  }

  downloadAllFiles = () => {
    //TODO - this will change when we switch to real notification
    this.props.downloadNotificationDocuments(this.props.id);
  };

  render() {
    const { t, description, references } = this.props;
    const { referencesCounter } = this.state;
    return (
      <div className="tab-content">
        {/* <div className="group group--first group--underlined u-align-right">
                    <label htmlFor="notification-email" className="inline-checkbox">
                        <input
                            className="inline-checkbox__input"
                            type="checkbox"
                            name="notification-email"
                            value=""
                            id="notification-email"
                            onClick={() => {
                                //TODO
                            }}/>
                        <span className="inline-checkbox__input--off icon icon-radio-off" aria-hidden={true}></span>
                        <span className="inline-checkbox__input--on icon icon-radio-on" aria-hidden={true}></span>
                        <span>{t("notifications.detail.turnOnNotifications")}</span>
                    </label>
                </div> */}
        <div className={classNames("group", "group--underlined")}>
          <div className="detail__description">
            <Linkify componentDecorator={targetBlankDecorator}>
              <div dangerouslySetInnerHTML={{ __html: description }}></div>
            </Linkify>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isDownloadingZipPackage: isDownloadingFile(state, "345"),
});

const mapDispatchToProps = {
  downloadNotificationDocuments,
};

export default compose(
  withTranslation("common"),
  connect(mapStateToProps, mapDispatchToProps),
)(NotificationDetailsTab);
