import React, { Component } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import PanelContainer from "./common/layout/PanelContainer";
import { Content } from "./common/layout/Content";
import Notifications from "./pages/notifications/Notifications";
import Deadlines from "./pages/deadlines/Deadlines";
import Meetings from "./pages/meetings/Meetings";
import Documents from "./pages/documents/Documents";
import Committees from "./pages/committees/Committees";
import Members from "./pages/members/Members";
import About from "./pages/about/About";
import { connect } from "react-redux";
import {
  selectedNationalCommittee,
  getSelectedNationalCommitteeTitle,
} from "../state/national-committees/selectors";
import { transformCommitteeName } from "../utils/transform";
import { generateLink } from "../utils/dynamicLink";
import PageNotFound from "./pages/404/PageNotFound";
import PublishedStandards from "./pages/published-standards/ExternalPublishedStandards";
import { setHeaderTitle } from "../state/header/actions";

export const DefaultPageNavigationLinks = [
  {
    name: "navigation.page.notifications",
    subRoute: "notifications",
    component: Notifications,
  },
  {
    name: "navigation.page.deadlines",
    subRoute: "deadlines",
    component: Deadlines,
  },
  {
    name: "navigation.page.meetings",
    subRoute: "meetings",
    component: Meetings,
  },
  {
    name: "navigation.page.documents",
    subRoute: "documents",
    component: Documents,
  },
  {
    name: "navigation.page.publishedStandards",
    subRoute: "standards",
    component: PublishedStandards,
  },
  {
    name: "navigation.page.internationalCommittees",
    subRoute: "committees",
    component: Committees,
  },
  {
    name: "navigation.page.members",
    subRoute: "members",
    component: Members,
  },
  { name: "navigation.page.about", subRoute: "about", component: About },
];
class Home extends Component {
  refreshHeaderTitle = () => {
    const { selectedNationalCommittee, setHeaderTitle } = this.props;
    const title = this.getNationalCommitteeTitle();

    setHeaderTitle(title ? title : selectedNationalCommittee);
  };

  componentDidMount() {
    this.refreshHeaderTitle();
  }

  componentDidUpdate(prevProps) {
    this.refreshHeaderTitle();
  }

  getLinks = () => {
    const { selectedNationalCommittee } = this.props;

    return [...DefaultPageNavigationLinks].map((link) => {
      return {
        ...link,
        url: generateLink(selectedNationalCommittee, link.subRoute),
      };
    });
  };

  getNationalCommitteeTitle() {
    const { selectedNationalCommittee, selectedNationalCommitteeTitle } =
      this.props;

    return selectedNationalCommitteeTitle
      ? selectedNationalCommittee + " - " + selectedNationalCommitteeTitle
      : "";
  }

  render() {
    const { selectedNationalCommittee } = this.props;
    const links = this.getLinks();
    const defaultLink = links[0];

    return (
      <PanelContainer>
        <Content>
          <Switch>
            {links.map((link, index) => (
              <Route
                key={index}
                path={`/${link.url}`}
                component={link.component}
              />
            ))}
            <Route
              key="default"
              path={`/${transformCommitteeName(selectedNationalCommittee)}`}
            >
              <Redirect to={{ pathname: defaultLink.url }} />
            </Route>
            <Route path="*" component={PageNotFound} />
          </Switch>
        </Content>
      </PanelContainer>
    );
  }
}

const mapDispatchToProps = {
  setHeaderTitle,
};

const mapStateToProps = (state) => ({
  selectedNationalCommittee: selectedNationalCommittee(state),
  selectedNationalCommitteeTitle: getSelectedNationalCommitteeTitle(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(Home);
