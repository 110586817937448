const apiUrl = {
  ds: "ds_api",
};

export function getProxyUrl(url) {
  return `/proxy/${apiUrl.ds}${url}`;
}

export function getQueryParams(params) {
  return Object.keys(params).reduce((query, name) => {
    let paramValue = params[name];
    if (paramValue === null) {
      return query;
    }
    if (Array.isArray(paramValue)) {
      return paramValue.reduce((acc, val) => {
        return val !== null ? `${acc}&${name}=${val}` : acc;
      }, query);
    } else {
      return `${query}&${name}=${paramValue}`;
    }
  }, "?");
}

export function addQueryParams(url, params) {
  if (!params) {
    return url;
  }

  return `${url}${getQueryParams(params)}`;
}

export const addPageQueryParam = (query, page) => {
  if (query.pageLength) {
    query.start = query.pageLength * page + 1;
  } else {
    console.error("pageLength missing in query, page attribute not set");
  }
  return query;
};

export function isRequestSuccessful(response) {
  return response && response.status >= 200 && response.status <= 299;
}

export const TIMEFRAME = {
  PAST: "past",
  FUTURE: "future",
  ALL: "all",
};
