import React, { useEffect, useState } from "react";
import "./ParticipationBar.scss";
import { useDispatch, useSelector } from "react-redux";
import { ParticipantsList } from "./ParticipantsList";
import { useTranslation } from "react-i18next";
import { getMeeting } from "../../../../../state/meetings/selectors";
import {
  isMemberInCurrentCommittee,
  userDetails,
} from "../../../../../state/user/selectors";
import {
  addParticipant,
  updateParticipant,
} from "../../../../../state/meetings/actions";
import classNames from "classnames";
import ModalWrapper from "../../../../common/modal-wrapper/ModalWrapper";
import OnlyMember from "../../../../common/only-member/OnlyMember";

export const ParticipationBar = (props) => {
  const { t } = useTranslation("common");

  const dispatch = useDispatch();

  const meeting = useSelector(getMeeting);
  const user = useSelector(userDetails);

  const [attendance, setAttendance] = useState(undefined);
  const [participantsCount, setParticipantsCount] = useState(0);
  const [participantId, setParticipantId] = useState(null);
  const [showParticipantsList, setShowParticipantsList] = useState(false);
  const [disableAttendance, setDisableAttendance] = useState(false);
  const [hideAttendance, setHideAttendance] = useState(false);

  const _isMemberInCurrentCommittee = useSelector(isMemberInCurrentCommittee);

  useEffect(() => {
    if (meeting?.participants?.length > 0) {
      const userId = user?.id;

      meeting.participants.forEach((participant) => {
        if (participant.userId === userId) {
          setAttendance(participant.attending);
          setParticipantId(participant.id);
        }
      });

      setParticipantsCount(meeting.participants.length);
    }
  }, [meeting, user]);

  useEffect(() => {
    setDisableAttendance(!_isMemberInCurrentCommittee);
  }, [_isMemberInCurrentCommittee]);

  useEffect(() => {
    const today = new Date();
    const meetingDate = new Date(meeting?.fromDate);
    if (today > meetingDate) {
      setHideAttendance(true);
    }
  }, [meeting]);

  const handleChange = (event) => {
    const attending = Number(event.target.value);
    if (participantId) {
      dispatch(
        updateParticipant({
          participantId: participantId,
          attending,
        }),
      );
    } else {
      dispatch(
        addParticipant({
          meetingUri: meeting.uri,
          userId: user.id,
          attending,
        }),
      );
    }
    setAttendance(Number(event.target.value));
  };

  return (
    <OnlyMember allowConsultant>
      <div className="participant-bar">
        {!hideAttendance && (
          <div className="participant-bar__options">
            <label
              className={classNames("radio-option", {
                "radio-option--disabled": disableAttendance,
              })}
            >
              <input
                className="radio-input"
                type="radio"
                disabled={disableAttendance}
                value={1}
                checked={attendance === 1}
                onChange={handleChange}
              />
              <span className="radio-label">
                {t("meetings.participants.attendance.onsite")}
              </span>
            </label>
            <label
              className={classNames("radio-option", {
                "radio-option--disabled": disableAttendance,
              })}
            >
              <input
                className="radio-input"
                type="radio"
                disabled={disableAttendance}
                value={2}
                checked={attendance === 2}
                onChange={handleChange}
              />
              <span className="radio-label">
                {t("meetings.participants.attendance.online")}
              </span>
            </label>
            <label
              className={classNames("radio-option", {
                "radio-option--disabled": disableAttendance,
              })}
            >
              <input
                className="radio-input"
                type="radio"
                disabled={disableAttendance}
                value={0}
                checked={attendance === 0}
                onChange={handleChange}
              />
              <span className="radio-label">
                {t("meetings.participants.attendance.no")}
              </span>
            </label>
          </div>
        )}
        <button
          disabled={participantsCount === 0}
          className="participants-count button button--link button--no-border"
          onClick={() => setShowParticipantsList(true)}
        >
          {t("meetings.participants.participants", {
            count: participantsCount,
          })}
        </button>
      </div>

      <ModalWrapper
        isOpen={showParticipantsList}
        requestCloseHandler={() => setShowParticipantsList(false)}
        disableSlide
      >
        <ParticipantsList closeHandler={() => setShowParticipantsList(false)} />
      </ModalWrapper>
    </OnlyMember>
  );
};
