import React, { Fragment, createRef } from "react";
import { Waypoint } from "react-waypoint";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";

import Card from "../../../../common/card/Card";
import CardMark from "../../../../common/card/CardMark";
import Sidebar from "../../components/sidebar/Sidebar";
import DeadlineNotification from "../../components/deadline-notification/DeadlineNotification";
import MeetingNotification from "../../components/meeting-notification/MeetingNotification";
import MemberNotification from "../../components/member-notification/MemberNotification";
import UserNotification from "../../components/user-notification/UserNotification";
import CommentNotification from "../../components/comment-notification/CommentNotification";
import VoteNotification from "../../components/vote-notification/VoteNotification";
import DocumentSummationNotification from "../../components/document-notification/DocumentSummationNotification";

import LegacyDocumentNotification from "../../components/document-notification/LegacyDocumentNotification";
import VoteSummationNotification from "../../components/vote-notification/VoteSummationNotification";
import CommentSummaryNotification from "../../components/comment-notification/CommentSummaryNotification";

import {
  clearNotificationList,
  initNotifications,
  markAllNotificationsAsSeen,
  markNotificationsAsSeen,
  pullNextNotificationsPage,
  resetNotificationsFilters,
} from "../../../../../state/notifications/actions";
import {
  getNotifications,
  hasMoreNotificationsToLoad,
  isProcessingRequest,
  markAllNotificationsAsSeenRequestProcessing,
} from "../../../../../state/notifications/selectors";

import "./NotificationsList.scss";

import UrlPattern from "url-pattern";
import {
  selectedNationalCommittee,
  getInternationalCommitteesCount,
} from "../../../../../state/national-committees/selectors";
import {
  getTunedOffCommitteesCount,
  userCommittees,
} from "../../../../../state/user/selectors";

import Loader from "../../../../common/loader/Loader";

import { setPageTitle } from "../../../../../utils/pageTitle";
import { notificationType } from "../../../../../utils/constants";
import { generateLink } from "../../../../../utils/dynamicLink";
import history from "../../../../../utils/history";

import { Helmet } from "react-helmet";
import { isViewingOwnCommittee, notificationsExist } from "./selectors";

import NotificationDiagnostics from "../../../../common/diagnostics/NotificationDiagnostics";
import DiagnosticsGuard from "../../../../common/diagnostics/DiagnosticsGuard";
import OnlyMember from "../../../../common/only-member/OnlyMember";
import { InfiniteWaypointScroller } from "../../../../common/infinite-scroll-wrapper/InfiniteScrollWrapper";
import { NavLink } from "react-router-dom";
import classNames from "classnames";

class NotificationsList extends React.Component {
  parser = new UrlPattern("/:committeeId/:segment(/:subsegment)*");

  infinityLoaderRef = createRef();
  state = {
    markedAsSeenNotifications: new Set(),
    previouseCommittee: "",
  };

  componentDidMount() {
    const result = this.parser.match(window.location.pathname);
    this.setState({ previouseCommittee: result.committeeId });

    this.unlisten = history.listen((location) => {
      const matchResult = this.parser.match(location.pathname);
      if (
        matchResult &&
        this.state.previouseCommittee !== matchResult.committeeId
      ) {
        this.setState({ previouseCommittee: matchResult.committeeId }, () => {
          this.props.resetNotificationsFilters();
          this.props.initNotifications();
        });
      }
    });

    this.props.resetNotificationsFilters();
    this.props.initNotifications();
  }

  componentWillUnmount() {
    this.unlisten();
    this.props.clearNotificationList();
  }

  loadMoreItems = () => {
    this.props.pullNextNotificationsPage();
  };

  renderSingleNotification = (data) => {
    switch (data.type) {
      case notificationType.deadline:
        return <DeadlineNotification data={data} />;
      case notificationType.meeting:
        return <MeetingNotification data={data} />;
      case notificationType.member:
        return <MemberNotification data={data} />;
      case notificationType.user:
        return <UserNotification data={data} />;
      case notificationType.comment:
        return <CommentNotification data={data} />;
      case notificationType.vote:
        return <VoteNotification data={data} />;
      case notificationType.documentSummation:
        return <LegacyDocumentNotification data={data} />;
      case notificationType.internalDocumentSummation:
        return <DocumentSummationNotification data={data} />;
      case notificationType.internalVoteSummation:
        return <VoteSummationNotification data={data} />;
      case notificationType.internalCommentsSummation:
        return <CommentSummaryNotification data={data} />;

      default:
        return;
    }
  };

  onWaypointEnter = (eventData, notification) => {
    const { currentPosition, event } = eventData;
    let markedAsSeenNotifications = new Set([
      ...this.state.markedAsSeenNotifications,
    ]);

    if (!notification.markedAsSeen && !event) {
      markedAsSeenNotifications.add(notification.notificationId);
      this.setState({ markedAsSeenNotifications });
      return;
    }

    if (currentPosition === Waypoint.inside) {
      if (notification.markedAsSeen) {
        if (markedAsSeenNotifications.size === 0) {
          return;
        }
      } else {
        markedAsSeenNotifications.add(notification.notificationId);
      }

      if (markedAsSeenNotifications.size > 0) {
        this.props.markNotificationsAsSeen(markedAsSeenNotifications);
        markedAsSeenNotifications.clear();
        this.setState({ markedAsSeenNotifications });
      }
    }
  };

  renderCard = (data, index) => {
    return (
      <Card
        key={index}
        isPinned={data.pinned}
        withOffset={[
          notificationType.deadline,
          notificationType.meeting,
          notificationType.comment,
          notificationType.vote,
          notificationType.internalCommentsSummation,
          notificationType.internalVoteSummation,
        ].includes(data.type)}
        className="notification-card"
        resourceType={data.type}
        resourceId={data.contentId || data.notificationId}
      >
        <DiagnosticsGuard>
          <NotificationDiagnostics notification={data} />
        </DiagnosticsGuard>

        <OnlyMember>{!data.seen && <CardMark />}</OnlyMember>

        {this.renderSingleNotification(data)}
      </Card>
    );
  };

  renderWaypoint = (data, index) => {
    const { isViewingOwnCommittee } = this.props;

    if (data.failedFetch) return null;

    return (
      <Fragment key={data.notificationId}>
        {isViewingOwnCommittee ? (
          <Waypoint
            key={data.notificationId}
            onEnter={(eventData) => this.onWaypointEnter(eventData, data)}
            scrollableAncestor={window}
          >
            {this.renderCard(data, index)}
          </Waypoint>
        ) : (
          this.renderCard(data, index)
        )}
      </Fragment>
    );
  };

  renderInternalCommitteeInfo = () => {
    const { t, tunedOffCommitteesCount, internationalCommitteesCount } =
      this.props;
    const enabledInternalCommitteesCount = Math.max(
      0,
      internationalCommitteesCount - tunedOffCommitteesCount,
    );

    if (tunedOffCommitteesCount === 0 || !this.props.notificationsExist) {
      return null;
    }

    return (
      <div className="layout__content">
        <span>
          {`${t("notifications.feed.turnedOnCommittees", {
            count: enabledInternalCommitteesCount,
          })} 
                ${t("notifications.feed.turnedOffCommittees", {
                  count: tunedOffCommitteesCount,
                })} `}
          (
          <NavLink
            to={`/${generateLink(
              this.props.selectedNationalCommittee,
              "settings",
            )}`}
          >
            {t("notifications.feed.editCommitteeSettings")}
          </NavLink>
          )
        </span>
      </div>
    );
  };

  get getMonday() {
    const d = new Date();
    const day = d.getDay(),
      diff = d.getDate() - day + (day == 0 ? -6 : 1);
    return new Date(d.setDate(diff));
  }

  renderNotifications() {
    const { notifications, t } = this.props;
    const thisWeekNotifications = [];
    const previousNotifications = [];

    notifications.forEach((notification) => {
      if (
        this.getMonday <=
        (notification.details?.notificationDate ?? notification.eventDate)
      ) {
        thisWeekNotifications.push(notification);
      } else {
        previousNotifications.push(notification);
      }
    });

    return (
      <>
        {thisWeekNotifications.length > 0 && (
          <>
            <h4>{t("notifications.feed.thisWeek")}</h4>
            {thisWeekNotifications.map(this.renderWaypoint)}
          </>
        )}
        {previousNotifications.length > 0 && (
          <>
            <h4>{t("notifications.feed.previously")}</h4>
            {previousNotifications.map(this.renderWaypoint)}
          </>
        )}
      </>
    );
  }

  renderMarkAsAllReadButton = () => {
    const {
      markAllNotificationsAsSeen,
      markAllNotificationsInProgress,
      t,
      selectedNationalCommittee,
      userCommittees,
    } = this.props;

    if (userCommittees[selectedNationalCommittee]?.unseenNotifications === 0)
      return null;

    return (
      <OnlyMember>
        <div className="group group--small mark-all-as-seen-div">
          <button
            onClick={markAllNotificationsAsSeen}
            className={classNames(
              "button button--link button--no-border sidebar__markAll",
              {
                "button--awaiting": markAllNotificationsInProgress,
                "button--link-disabled": markAllNotificationsInProgress,
                "sidebar__markAll--ready": !markAllNotificationsInProgress,
              },
            )}
          >
            {t("filter.label.markAllAsSeen")}
          </button>
        </div>
      </OnlyMember>
    );
  };

  render() {
    const {
      t,
      notificationsExist,
      selectedNationalCommittee,
      hasMoreNotificationsToLoad,
      isProcessingRequest,
    } = this.props;

    return (
      <Fragment>
        <Helmet>
          <title>
            {setPageTitle(["Notifications"], selectedNationalCommittee)}
          </title>
        </Helmet>
        <header className="layout__title">
          <h2 className="headline-2">{t("notifications.header.name")}</h2>
        </header>

        <>{this.renderMarkAsAllReadButton()}</>

        <div className="layout__content layout__content--with-cards notifications">
          <div className="layout__cards">
            <InfiniteWaypointScroller
              loadMore={this.loadMoreItems}
              hasMore={hasMoreNotificationsToLoad}
              isLoading={isProcessingRequest}
              loader={<Loader key="infinity_scroll_loader" type="content" />}
            >
              {this.renderInternalCommitteeInfo()}

              {!notificationsExist && !hasMoreNotificationsToLoad && (
                <p className="headline-4">
                  {t("general.content.nothingToShow")}
                </p>
              )}

              {this.renderNotifications()}
              {/* {notifications.map(this.renderWaypoint)} */}
            </InfiniteWaypointScroller>
          </div>
          <div className="layout__sidebar deadlines__sidebar">
            <Sidebar />
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  isViewingOwnCommittee: isViewingOwnCommittee(state),
  selectedNationalCommittee: selectedNationalCommittee(state),
  isProcessingRequest: isProcessingRequest(state),
  internationalCommitteesCount: getInternationalCommitteesCount(state),
  notifications: getNotifications(state),
  notificationsExist: notificationsExist(state),
  hasMoreNotificationsToLoad: hasMoreNotificationsToLoad(state),
  tunedOffCommitteesCount: getTunedOffCommitteesCount(state),
  markAllNotificationsInProgress:
    markAllNotificationsAsSeenRequestProcessing(state),
  userCommittees: userCommittees(state),
});

const mapDispatchToProps = {
  initNotifications,
  clearNotificationList,
  markNotificationsAsSeen,
  pullNextNotificationsPage,
  resetNotificationsFilters,
  markAllNotificationsAsSeen,
};

export default compose(
  withTranslation("common"),
  connect(mapStateToProps, mapDispatchToProps),
)(NotificationsList);
