import React, { Component } from "react";
import classNames from "classnames";

class Tooltip extends Component {
  render() {
    const { label, content } = this.props;

    if (!label || !content) return null;

    return (
      <div className="tooltip">
        <span className="icon icon-question-mark tooltip__icon"></span>
        <div className="panel panel--border tooltip__content">
          <span className="label">{label}</span>
          <div>{content}</div>
        </div>
      </div>
    );
  }
}

export class TooltipWrapper extends Component {
  render() {
    const { label, content, extraClass, children } = this.props;

    return (
      <div className={classNames("tooltip", extraClass)}>
        {children}
        <div className="panel panel--border tooltip__content">
          {label && <span className="label">{label}</span>}
          {content && <div>{content}</div>}
        </div>
      </div>
    );
  }
}

export default Tooltip;
