import React, { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import FileInput from "../../../../common/form/file-input/FileInput";

const AddModal = (props) => {
  const { cancelHandler, submitHandler, id, isSpecial } = props;
  const { t } = useTranslation("common");

  const [isAwaiting, setIsAwaiting] = useState(false);
  const [inputTitleValue, setInputTitleValue] = useState("");
  const [inputDescriptionValue, setInputDescriptionValue] = useState("");
  const [file, setFile] = useState({});

  const isDisabled =
    inputTitleValue.length < 1 ||
    inputDescriptionValue.length < 1 ||
    !file.name;

  const handleSubmit = () => {
    if (!isAwaiting) {
      submitHandler({
        id,
        title: inputTitleValue,
        description: inputDescriptionValue,
        file,
        isSpecial,
        cancelHandler,
      });
      setIsAwaiting(true);
    }
  };

  return (
    <Fragment>
      <div className="group group--first group--full-underlined">
        <h3 className="headline-4">{t("general.button.addDocument")}</h3>
      </div>
      <div
        className="group group--last-underlined"
        style={{ marginBottom: 10 }}
      >
        <div className="input-wrapper" style={{ marginBottom: 10 }}>
          <label htmlFor="document-description" className="visuallyhidden">
            {t("about.modals.documentTitle")}
          </label>
          <input
            type="text"
            className="input"
            name="document-add-title"
            id="document-add-title"
            value={inputTitleValue}
            placeholder={t("about.modals.documentTitle")}
            onChange={(e) => setInputTitleValue(e.target.value)}
          />
        </div>
        <textarea
          type="text"
          name="document-add-description"
          id="document-add-description"
          className="textarea"
          style={{ marginBottom: 10 }}
          value={inputDescriptionValue}
          placeholder={t("about.modals.documentDescription")}
          onChange={(e) => setInputDescriptionValue(e.target.value)}
        />
        <FileInput
          onChangeHandler={(e) => setFile(e.target.files[0])}
          isInline={true}
          isPrimaryButton={true}
          isSmallButton={true}
          isDisabled={false}
          buttonText={t("general.button.upload")}
          id="upload-document"
        />
      </div>
      <div className="group group--btn u-align-right">
        <button className="button" onClick={() => cancelHandler(id)}>
          {t("general.button.cancel")}
        </button>
        <button
          className={classNames("button button--primary", {
            "button--disabled": isDisabled,
            "button--disabled button--awaiting": isAwaiting,
          })}
          onClick={handleSubmit}
          disabled={isDisabled}
        >
          {t("about.modals.addNewDocument")}
        </button>
      </div>
    </Fragment>
  );
};

export default AddModal;
