import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";
import { format } from "date-fns/fp";

import { broadcastInformation } from "../../../state/diagnostics/selectors";

import "./NotificationDiagnostics.scss";

class NotificationDiagnostics extends Component {
  state = {
    showMore: false,
  };

  onMoreClicked = () => {
    this.setState({ showMore: true });
  };

  onLessClicked = () => {
    this.setState({ showMore: false });
  };

  renderMore() {
    const { notification } = this.props;
    return <pre> {JSON.stringify(notification, null, 2)} </pre>;
  }

  renderDate(date) {
    if (!date) return "never";
    return format("E MMM d yyyy HH:mm:ss zzz", date);
  }

  render() {
    const { broadcastInformation, notification } = this.props;

    const information = broadcastInformation[notification.notificationId];
    return (
      <>
        <div className="notification-diagnostics">
          <b>
            DIAGNOSTICS INFO: (
            <button onClick={this.onMoreClicked}>more</button>|
            <button onClick={this.onLessClicked}>less</button>)
          </b>
          <span className="notification-diagnostics__indent-1">
            URI: <b>{notification.notificationId}</b>
          </span>

          {information && (
            <>
              <span className="notification-diagnostics__indent-1">
                Is Instant:
                <b>{information.isInstant.toString()}</b>
                <br />
              </span>
              <span className="notification-diagnostics__indent-1">
                Send dates:
              </span>
              <span className="notification-diagnostics__indent-2">
                Instant:
                <b>{this.renderDate(information.instantBroadcastDate)}</b>
              </span>
              <span className="notification-diagnostics__indent-2">
                Daily:
                <b>{this.renderDate(information.dailyBroadcastDate)}</b>
              </span>
              <span className="notification-diagnostics__indent-2">
                Weekly:
                <b>{this.renderDate(information.weeklyBroadcastDate)}</b>
              </span>
            </>
          )}
          {this.state.showMore && this.renderMore()}
        </div>
      </>
    );
  }
}

const mapDispatchToProps = {};

const mapStateToProps = (state) => ({
  broadcastInformation: broadcastInformation(state),
});

export default compose(
  withTranslation("common"),
  connect(mapStateToProps, mapDispatchToProps),
)(NotificationDiagnostics);
