import { handleActions } from "redux-actions";
import * as actions from "./actions";
import {
  normalizeAssociatedCommittees,
  normalizeCommittees,
  normalizeCommitteesList,
} from "./normalizer";
import { messageType } from "../../components/common/form/message-box/MessageBox";

const initialState = {
  isFetching: false,
  isFetched: false,
  data: {},
  selectedCommittee: null,
  apiResponse: {
    message: null,
    type: null,
  },
};

const actionHandlers = {
  [actions.getNationalCommittees]: (state) => ({
    ...state,
    isFetching: true,
    isFetched: false,
  }),
  [actions.getNationalCommitteesSucceeded]: (state, { payload }) => ({
    ...state,
    isFetching: false,
    isFetched: true,
    data: normalizeCommittees(payload.role, state.data),
  }),
  [actions.getNationalCommitteesFailed]: (state, { payload }) => ({
    ...state,
    isFetching: false,
    isFetched: true,
    apiResponse: {
      type: messageType.error,
      message: payload,
    },
  }),
  [actions.setSelectedNationalCommittee]: (state, { payload }) => ({
    ...state,
    selectedCommittee: payload.toUpperCase(),
  }),
  [actions.getAssociatedCommittees]: (state) => ({
    ...state,
    isFetching: true,
    isFetched: false,
  }),
  [actions.getAssociatedCommitteesSucceeded]: (state, { payload }) => ({
    ...state,
    isFetching: false,
    isFetched: true,
    data: normalizeAssociatedCommittees(payload.result, state.data),
  }),
  [actions.getAssociatedCommitteesFailed]: (state, { payload }) => ({
    ...state,
    isFetching: false,
    isFetched: true,
    apiResponse: {
      type: messageType.error,
      message: payload,
    },
  }),
  [actions.getCommitteesList]: (state) => ({
    ...state,
    isFetching: true,
    isFetched: false,
  }),
  [actions.getCommitteesListSucceeded]: (state, { payload }) => ({
    ...state,
    isFetching: false,
    isFetched: true,
    data: normalizeCommitteesList(payload.result, state.data),
  }),
  [actions.getCommitteesListFailed]: (state, { payload }) => ({
    ...state,
    isFetching: false,
    isFetched: true,
    apiResponse: {
      type: messageType.error,
      message: payload,
    },
  }),
};

export default handleActions(actionHandlers, initialState);
