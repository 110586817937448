import { createAction } from "redux-actions";

const SET_NOTIFY_TOASTER_CONTENT =
  "NOTIFY_TOASTER_CONTENT/set_notify_toaster_content";
const CLEAR_NOTIFY_TOASTER_CONTENT =
  "NOTIFY_TOASTER_CONTENT/clear_notify_toaster_content";

export const setNotifyToasterContent = createAction(SET_NOTIFY_TOASTER_CONTENT);
export const clearNotifyToasterContent = createAction(
  CLEAR_NOTIFY_TOASTER_CONTENT,
);
