export function loadLocalObject(name) {
  const itemValue = window.localStorage.getItem(name);
  try {
    return JSON.parse(itemValue) || {};
  } catch (err) {
    console.error(err);
    window.localStorage.setItem(name, JSON.stringify({}));
    return {};
  }
}

export function storeLocalObject(name, obj) {
  window.localStorage.setItem(name, JSON.stringify(obj));
}
