import { sortingType } from "./url";

export const mapSortTypeToObj = (type) => {
  switch (type) {
    case sortingType.date_descending:
      return { name: "date", type: "desc", method: (elem) => elem };
    case sortingType.date_ascending:
      return { name: "date", type: "asc", method: (elem) => elem };
    case sortingType.due_date_descending:
      return { name: "dueDate", type: "desc", method: (elem) => elem };
    case sortingType.due_date_ascending:
      return { name: "dueDate", type: "asc", method: (elem) => elem };
    case sortingType.document_id_descending:
      return { name: "id", type: "desc", method: (elem) => parseInt(elem) };
    case sortingType.document_id_ascenting:
      return { name: "id", type: "asc", method: (elem) => parseInt(elem) };
    default:
      return { name: "date", type: "desc", method: (elem) => elem };
  }
};

export const sortDocuments = (a, b, sortingType) => {
  const sortSettings = mapSortTypeToObj(sortingType);

  if (sortSettings.type === "asc") {
    if (
      sortSettings.method(a[sortSettings.name]) <
      sortSettings.method(b[sortSettings.name])
    )
      return -1;
    if (
      sortSettings.method(a[sortSettings.name]) >
      sortSettings.method(b[sortSettings.name])
    )
      return 1;
    return 0;
  } else {
    if (
      sortSettings.method(a[sortSettings.name]) >
      sortSettings.method(b[sortSettings.name])
    )
      return -1;
    if (
      sortSettings.method(a[sortSettings.name]) <
      sortSettings.method(b[sortSettings.name])
    )
      return 1;
    return 0;
  }
};
