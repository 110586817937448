import React, { Fragment, PureComponent } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { formatWithOptions } from "date-fns/fp";
import { enUS, da } from "date-fns/esm/locale";
import { Link } from "react-router-dom";
import classNames from "classnames";
import { withTranslation } from "react-i18next";
import NotificationBar from "../notification-bar/NotificationBar";
import NotificationCardMenu from "../notification-card-menu/NotificationCardMenu";

import * as userSelectors from "../../../../../state/user/selectors";
import { selectedNationalCommittee } from "../../../../../state/national-committees/selectors";

import { transformCommitteeName } from "../../../../../utils/transform";

class LegacyDocumentNotificationCard extends PureComponent {
  dateLocale = {
    "en-US": enUS,
    "da-DK": da,
  };

  get notificationTitle() {
    const { t, eventDate } = this.props;

    var sinceDate = new Date(eventDate);
    sinceDate.setDate(sinceDate.getDate() - 1);

    const formater = formatWithOptions(
      { locale: this.dateLocale[this.props.appLocale] || enUS },
      "EEEE d MMMM yyyy",
    );

    return t("common:notifications.types.documentSummation.add", {
      date: formater(new Date(sinceDate)),
    });
  }

  generateDocumentsLink = (nationalCommittee, committee) => {
    let url = new URL(
      `/${transformCommitteeName(nationalCommittee)}/documents`,
      process.env.REACT_APP_OPENID_REDIRECT_URL,
    );
    url.searchParams.set("committee", committee);
    url.searchParams.set("sortingType", "date_desc");
    return url.pathname + url.search;
  };

  renderCardAttachments(committee, documents) {
    const { t, selectedNationalCommittee } = this.props;

    return documents ? (
      <div className="card__attachments">
        {documents && (
          <Link
            to={this.generateDocumentsLink(
              selectedNationalCommittee,
              committee,
            )}
            className="card__file button button--link"
          >
            <span
              className="icon icon-file icon--large"
              aria-hidden="true"
            ></span>
            {documents === 1
              ? t("card.documentCounter")
              : t("card.documentsCounter", { count: documents })}
          </Link>
        )}
      </div>
    ) : null;
  }

  render() {
    const { t, details, notificationId, initNotifications } = this.props;

    return (
      <Fragment key={notificationId}>
        <NotificationBar
          title={this.notificationTitle}
          notificationMenu={{
            committee: details.committee,
            notificationId: notificationId,
          }}
        />

        <div
          className={classNames(
            "notification-card__group",
            "notification-card__group--first",
          )}
        >
          <div className="card__header notification-card__header">
            <div className="card__headings">
              <span className="card__heading-1">
                <span className="u-text-bold">
                  {details.committee || t("general.defaults.otherCommittee")}
                </span>
                {details.committeeName && <>&nbsp;- {details.committeeName}</>}
              </span>
            </div>
            {/* <NotificationCardMenu
              committee={details.committee}
              callbackAction={initNotifications}
              notificationId={notificationId}
            /> */}
          </div>

          {this.renderCardAttachments(
            details.committee,
            details.documents && details.documents.length,
          )}
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  selectedNationalCommittee: selectedNationalCommittee(state),
  appLocale: userSelectors.getAppLocale(state),
});

export default compose(
  withTranslation("common"),
  connect(mapStateToProps, null),
)(LegacyDocumentNotificationCard);
