import classNames from "classnames";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import ReactDatePicker from "react-datepicker";
import { useTranslation } from "react-i18next";

export const PollPage = forwardRef((props, ref) => {
  const { t } = useTranslation("common");
  const { onClose, onAdd, poll } = props;

  const [title, setTitle] = useState("");
  const [showValidationMessage, setShowValidationMessage] = useState(false);
  const [options, setOptions] = useState([{ value: "" }, { value: "" }]);
  const [isMultiVote, setIsMultiVote] = useState(false);
  const [enableDeadline, setEnableDeadline] = useState(false);
  const [deadline, setDeadlineValue] = useState(undefined);

  useImperativeHandle(ref, () => ({
    isPollValid: () => isPollValid(),
    onAddPoll: () => onAddPoll(),
  }));

  useEffect(() => {
    if (poll) {
      setTitle(poll.title);
      setOptions(
        poll.options.map((option) => ({
          ...option,
        })),
      );
      setIsMultiVote(poll.isMultiVote);
      setEnableDeadline(!!poll.deadline);
      setDeadlineValue(poll.deadline ? new Date(poll.deadline) : undefined);
    }
  }, [poll]);

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };

  const handleOptionChange = (index, e) => {
    const newOptions = [...options];
    newOptions[index].value = e.target.value;
    setOptions(newOptions);
  };

  const addOption = () => {
    setOptions([...options, { value: "" }]);
  };

  const deleteOption = (index) => {
    setOptions(options.filter((_, i) => i !== index));
  };

  const handleIsMultiVoteChange = (e) => {
    setIsMultiVote(e.target.checked);
  };

  const handleSetDeadlineChange = (e) => {
    setEnableDeadline(e.target.checked);
    if (!e.target.checked) {
      setDeadlineValue("");
    }
  };

  const handleDeadlineChange = (value) => {
    setDeadlineValue(value);
  };

  const isPollValid = () => {
    if (
      !title ||
      options.filter((option) => option.value).length < 2 ||
      (enableDeadline && !deadline)
    ) {
      setShowValidationMessage(true);
      return false;
    }
    return true;
  };

  const onAddPoll = () => {
    if (!isPollValid()) return false;

    const poll = {
      title,
      options,
      isMultiVote,
    };

    if (enableDeadline) {
      poll.deadline = deadline;
    }

    onAdd({ poll });
    onClose();

    return true;
  };

  return (
    <div className="tab-content poll">
      <div className="detail__form">
        <div className="group group--small group__flex detail__group">
          <span className="headline-4 u-text-bold" id="documents">
            {t("notifications.poll.title")}
          </span>
        </div>

        <div className="group group--first group--underlined">
          <label className="field-label" htmlFor="poll-title">
            {t("Question or Topic")}
          </label>
          <input
            className={classNames("input", {
              "input--validation_error": showValidationMessage && !title,
            })}
            type="text"
            value={title}
            onChange={handleTitleChange}
          />
          {showValidationMessage && !title && (
            <span className="validation-message__error">
              {t("general.validation.notEmpty")}
            </span>
          )}
        </div>

        <div className="group group--underlined poll-options">
          {options.map((option, index) => (
            <div key={index} className="group poll-option">
              <label className="field-label" htmlFor="poll-title">
                {t("Option")} {index + 1}
                {index >= 2 && (
                  <button
                    className="remove-button icon icon-cross-circle"
                    onClick={() => deleteOption(index)}
                  ></button>
                )}
              </label>
              <input
                className={classNames("input", {
                  "input--validation_error":
                    showValidationMessage && !option.value,
                })}
                type="text"
                value={option.value}
                onChange={(e) => handleOptionChange(index, e)}
              />
              {showValidationMessage && !option.value && index < 2 && (
                <span className="validation-message__error">
                  {t("general.validation.notEmpty")}
                </span>
              )}
            </div>
          ))}

          <button
            className="button button--small button--secondary"
            onClick={addOption}
          >
            Add Option
          </button>
        </div>

        <div className="group">
          <div className="group group--field">
            <div className="checkbox__group">
              <label className="inline-checkbox">
                <input
                  className="inline-checkbox__input"
                  type="checkbox"
                  checked={isMultiVote}
                  onChange={handleIsMultiVoteChange}
                />
                <span className="inline-checkbox__input--off icon icon-radio-off"></span>
                <span className="inline-checkbox__input--on icon icon-radio-on"></span>
                <span>{t("Enable Multiple Vote")}</span>
              </label>
              <label className="inline-checkbox">
                <input
                  className="inline-checkbox__input"
                  type="checkbox"
                  checked={enableDeadline}
                  onChange={handleSetDeadlineChange}
                />
                <span className="inline-checkbox__input--off icon icon-radio-off"></span>
                <span className="inline-checkbox__input--on icon icon-radio-on"></span>
                <span>{t("Set Deadline")}</span>
              </label>
              {enableDeadline && (
                <div className="datepicker">
                  <ReactDatePicker
                    className={classNames("input", "input-date", {
                      "input--validation_error":
                        showValidationMessage && !deadline,
                    })}
                    name="poll-deadline"
                    id="poll-deadline"
                    placeholderText="YYYY/MM/DD"
                    dateFormat="yyyy/MM/dd"
                    selected={deadline}
                    onChange={handleDeadlineChange}
                    autoComplete="off"
                  />
                  {showValidationMessage && !deadline && (
                    <span className="validation-message__error">
                      {t("general.validation.notEmpty")}
                    </span>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});
