import React from "react";
import { getAcronym } from "../../../utils/transform";
import classNames from "classnames";

export default ({ profileImage, name, isSmall }) => {
  return (
    <div className={classNames("avatar", { "avatar--small": isSmall })}>
      {profileImage ? (
        <img src={profileImage} alt={name} className="avatar__img" />
      ) : (
        <div className="avatar__placeholder">
          <span className="avatar__acronym">{getAcronym(name)}</span>
        </div>
      )}
    </div>
  );
};
