import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

import * as appActions from "../../../state/app/actions";
import * as documentSummaryActions from "../../../state/documentSummary/actions";
import * as documentActions from "../../../state/document/actions";
import * as documentsSummarySelectors from "../../../state/documentSummary/selectors";
import * as committeesSelectors from "../../../state/national-committees/selectors";
import * as documentSelectors from "../../../state/document/selectors";

import { SummaryFilesDownloadId } from "../../../utils/constants";

import DocumentsSummary from "./DocumentsSummary";
import DownloadZipButton from "../../common/download-zip-button/DownloadZipButton";

class DocumentsSummaryModal extends Component {
  componentDidMount() {
    this.props.initDocuments();
    this.props.pullNextDocumentsPageRequest();
    this.props.generateDocumentsSummary(this.props.ids);
  }

  getDocumentsCount = (isLoading) => {
    if (
      isLoading ||
      !this.props.documentsSummary ||
      !this.props.documentsSummary.details
    )
      return this.props.documentsCount;

    return Object.values(this.props.documentsSummary.details).reduce(
      (acc, cur) => acc + cur.length,
      0,
    );
  };

  closeHandler = () => {
    const { id } = this.props;
    this.props.closeDialog(id);
  };

  downloadFiles = () => {
    this.props.downloadDocumentsSummaryFiles();
  };

  render() {
    const { t, documentsSummary } = this.props;
    const isLoading = documentsSummary.isFetching || !documentsSummary.details;

    return (
      <>
        <header className="layout__title-wrapper panel">
          <h2 className="headline-2">
            {t("documentSummary.newDocuments", {
              count: this.getDocumentsCount(isLoading),
            })}
          </h2>
          <div className="group group--inline u-align-right detail__btns">
            <DownloadZipButton
              isDisabled={isLoading}
              isAwaiting={this.props.isDownloadingFile}
              onClick={this.downloadFiles}
            />
          </div>
        </header>

        <DocumentsSummary />

        <div className="modal__footer group group--btn u-align-right">
          <button className="button" onClick={this.closeHandler}>
            {t("general.button.close")}
          </button>
        </div>
      </>
    );
  }
}

const mapDispatchToProps = {
  initDocuments: documentActions.initDocuments,
  pullNextDocumentsPageRequest: documentActions.pullNextDocumentsPageRequest,
  generateDocumentsSummary: documentSummaryActions.generateDocumentsSummary,
  downloadDocumentsSummaryFiles:
    documentSummaryActions.downloadDocumentsSummaryFiles,
  closeDialog: appActions.applicationCloseGlobalDialog,
};

const mapStateToProps = (state) => ({
  selectedNationalCommittee:
    committeesSelectors.selectedNationalCommittee(state),
  commiteeTitleProvider: committeesSelectors.getCommitteeTitleProvider(state),
  documentsSummary: documentsSummarySelectors.documentsSummary(state),
  isDownloadingFile: documentSelectors.isDownloadingFile(
    state,
    SummaryFilesDownloadId,
  ),
});

export default compose(
  withTranslation("common"),
  connect(mapStateToProps, mapDispatchToProps),
)(DocumentsSummaryModal);
