import { takeEvery, call, put } from "redux-saga/effects";
import * as actions from "../state/settings/actions";
import { getEmailsFrequency } from "../api/resources/settings";

export function* fetchEmailsFrequency() {
  try {
    const response = yield call(getEmailsFrequency);

    if (response.status === 200) {
      yield put(actions.getEmailsFrequencySucceeded(response.data));
    } else {
      yield put(actions.getEmailsFrequencyFailed(response.data.message));
    }
  } catch (error) {
    console.error(`fetch email frequency failed - ${error}`);
  }
}

export default function* settingsSaga() {
  yield takeEvery(actions.GET_EMAILS_FREQUENCY, fetchEmailsFrequency);
}
