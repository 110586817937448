import React from "react";
import { Editor } from "@tinymce/tinymce-react";

import "tinymce";
import "tinymce/themes/silver";
import "tinymce/themes/mobile";
import "../../../assets/tinyskins/ui/oxide-custom/skin.css";
import "../../../assets/tinyskins/ui/oxide-custom/skin.mobile.css";
import "../../../assets/tinyskins/ui/oxide-custom/content.css";
import "../../../assets/tinyskins/ui/oxide-custom/content.mobile.css";
import "tinymce/plugins/link";
import "tinymce/plugins/image";
import "tinymce/plugins/lists";
import "tinymce/plugins/charmap";
import "tinymce/plugins/anchor";
import "tinymce/plugins/pagebreak";
import "tinymce/plugins/spellchecker";
import "tinymce/plugins/code";
import "tinymce/plugins/fullscreen";
import "tinymce/plugins/table";
import "tinymce/plugins/paste";
import "tinymce/icons/default";

const TinyMceEditor = ({
  value,
  onEditorChange,
  setup,
  refreshKey,
  ...rest
}) => {
  return (
    <Editor
      key={refreshKey}
      value={value}
      onEditorChange={onEditorChange}
      init={{
        setup: setup,
        content_css: "/editor.css",
        plugins:
          "paste link image lists charmap spellchecker code fullscreen " +
          "table pagebreak anchor",
        menubar: false,
        toolbar:
          "refer_comment | cut copy paste pastetext | undo redo | spellchecker | link unlink anchor | " +
          "image table pagebreak charmap | fullscreen | code | bold italic strikethrough | " +
          "removeformat | numlist bullist | outdent indent | blockquote | fontsizeselect | " +
          "formatselect | help",
        min_height: 250,
        statusbar: false,
        branding: false,
        mobile: {
          plugins: [
            "paste",
            "link",
            "image",
            "lists",
            "charmap",
            "spellchecker",
            "code",
            "fullscreen",
            "table",
            "pagebreak",
            "anchor",
          ],
          toolbar: [
            "cut",
            "copy",
            "paste",
            "pastetext",
            "undo",
            "redo",
            "spellchecker",
            "link",
            "unlink",
            "anchor",
            "image",
            "table",
            "pagebreak",
            "charmap",
            "fullscreen",
            "code",
            "bold",
            "italic",
            "strikethrough",
            "removeformat",
            "numlist",
            "bullist",
            "outdent",
            "indent",
            "blockquote",
            "fontsizeselect",
            "formatselect",
            "help",
          ],
        },
      }}
      {...rest}
    />
  );
};

export default TinyMceEditor;
