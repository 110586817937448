import React, { Component } from "react";
import DeadlineSummaryNotification from "../common/DeadlineSummaryNotification";

export default class CommentSummaryNotification extends Component {
  render() {
    return (
      <DeadlineSummaryNotification
        {...this.props}
        headers={{
          unknownMembers:
            "common:notifications.types.commnetsSummation.unknownMembers",
          oneMember: "common:notifications.types.commnetsSummation.oneMember",
          twoMembers: "common:notifications.types.commnetsSummation.twoMembers",
          moreMembers:
            "common:notifications.types.commnetsSummation.moreMembers",

          onlyYou: "common:notifications.types.commnetsSummation.onlyYou",
          youAndOneMember:
            "common:notifications.types.commnetsSummation.youAndOneMember",
          youAndTwoMembers:
            "common:notifications.types.commnetsSummation.youAndTwoMembers",
          youAndMoreMembers:
            "common:notifications.types.commnetsSummation.youAndMoreMembers",
        }}
      />
    );
  }
}
