import React, { Component, Fragment } from "react";
import { withTranslation } from "react-i18next";
import SortBox from "../../../../common/sort-box/SortBox";
import classNames from "classnames";
import { sortingType } from "../../../../../services/comments/url";
import { sortBoxHidingDelay } from "../../../../../utils/constants";

const lineNumberSortBoxElements = [
  {
    name: "comments.sort.lineNumber.ascending",
    value: sortingType.lineNumber_ascending,
  },
  {
    name: "comments.sort.lineNumber.descending",
    value: sortingType.lineNumber_descending,
  },
];

const clauseSortBoxElements = [
  {
    name: "comments.sort.clause.ascending",
    value: sortingType.clause_ascending,
  },
  {
    name: "comments.sort.clause.descending",
    value: sortingType.clause_descending,
  },
];
const paragraphSortBoxElements = [
  {
    name: "comments.sort.paragraph.ascending",
    value: sortingType.paragraph_ascending,
  },
  {
    name: "comments.sort.paragraph.descending",
    value: sortingType.paragraph_descending,
  },
];
const typeSortBoxElements = [
  {
    name: "comments.sort.type.ascending",
    value: sortingType.type_ascending,
  },
  {
    name: "comments.sort.type.descending",
    value: sortingType.type_descending,
  },
];
const authorSortBoxElements = [
  {
    name: "comments.sort.author.ascending",
    value: sortingType.author_ascending,
  },
  {
    name: "comments.sort.author.descending",
    value: sortingType.author_descending,
  },
];
const dateSortBoxElements = [
  {
    name: "comments.sort.date.ascending",
    value: sortingType.date_ascending,
  },
  {
    name: "comments.sort.date.descending",
    value: sortingType.date_descending,
  },
];

const descendingSortingTypes = [
  sortingType.lineNumber_descending,
  sortingType.clause_descending,
  sortingType.paragraph_descending,
  sortingType.type_descending,
  sortingType.author_descending,
  sortingType.date_descending,
];

const ascendingSortingTypes = [
  sortingType.lineNumber_ascending,
  sortingType.clause_ascending,
  sortingType.paragraph_ascending,
  sortingType.type_ascending,
  sortingType.author_ascending,
  sortingType.date_ascending,
];

class Header extends Component {
  state = {
    lineNumberSortBox: false,
    clauseSortBox: false,
    paragraphSortBox: false,
    typeSortBox: false,
    authorSortBox: false,
    dateSortBox: false,
  };

  closeSortBoxes = () => {
    this.setState({
      lineNumberSortBox: false,
      clauseSortBox: false,
      paragraphSortBox: false,
      typeSortBox: false,
      authorSortBox: false,
      dateSortBox: false,
    });
  };
  toggleLineNumberSortBox = () => {
    this.setState({
      lineNumberSortBox: !this.state.lineNumberSortBox,
      clauseSortBox: false,
      paragraphSortBox: false,
      typeSortBox: false,
      authorSortBox: false,
      dateSortBox: false,
    });
  };
  toggleClauseSortBox = () => {
    this.setState({
      lineNumberSortBox: false,
      clauseSortBox: !this.state.clauseSortBox,
      paragraphSortBox: false,
      typeSortBox: false,
      authorSortBox: false,
      dateSortBox: false,
    });
  };
  toggleParagraphSortBox = () => {
    this.setState({
      lineNumberSortBox: false,
      clauseSortBox: false,
      paragraphSortBox: !this.state.paragraphSortBox,
      typeSortBox: false,
      authorSortBox: false,
      dateSortBox: false,
    });
  };
  toggleTypeSortBox = () => {
    this.setState({
      lineNumberSortBox: false,
      clauseSortBox: false,
      paragraphSortBox: false,
      typeSortBox: !this.state.typeSortBox,
      authorSortBox: false,
      dateSortBox: false,
    });
  };
  toggleAuthorSortBox = () => {
    this.setState({
      lineNumberSortBox: false,
      clauseSortBox: false,
      paragraphSortBox: false,
      typeSortBox: false,
      authorSortBox: !this.state.authorSortBox,
      dateSortBox: false,
    });
  };
  toggleDateSortBox = () => {
    this.setState({
      lineNumberSortBox: false,
      clauseSortBox: false,
      paragraphSortBox: false,
      typeSortBox: false,
      authorSortBox: false,
      dateSortBox: !this.state.dateSortBox,
    });
  };

  handleSortChange = (sortingType) => {
    setTimeout(this.closeSortBoxes, sortBoxHidingDelay);
    this.props.onSortChange(sortingType);
  };

  getSortableButtonClassNames = (sortableElements) => {
    return classNames(
      "button button--link button--no-border sortable",
      {
        "sortable-dsc":
          sortableElements.some(
            (element) => element.value === this.props.sortingType,
          ) &&
          descendingSortingTypes.some(
            (descendingSortingType) =>
              descendingSortingType === this.props.sortingType,
          ),
      },
      {
        "sortable-asc":
          sortableElements.some(
            (element) => element.value === this.props.sortingType,
          ) &&
          ascendingSortingTypes.some(
            (ascendingSortingType) =>
              ascendingSortingType === this.props.sortingType,
          ),
      },
    );
  };

  render() {
    const { t, enableSelection, expandAllHandler } = this.props;
    const {
      lineNumberSortBox,
      clauseSortBox,
      paragraphSortBox,
      typeSortBox,
      authorSortBox,
      dateSortBox,
    } = this.state;

    return (
      <thead>
        <tr className="table__header">
          {enableSelection && <th className="id" />}
          <th className="showAll">
            <button
              className={this.getSortableButtonClassNames([])}
              onClick={expandAllHandler}
            >
              {t("comments.buttons.showAll")}
            </button>
          </th>
          <th className="commentId">{t("comments.table.header.commentId")}</th>
          <th className="line">
            <button
              className={this.getSortableButtonClassNames(
                lineNumberSortBoxElements,
              )}
              onClick={this.toggleLineNumberSortBox}
            >
              {t("comments.table.header.lineNumber")}
            </button>
            <SortBox
              elements={lineNumberSortBoxElements}
              onSelectionChange={this.handleSortChange}
              selected={this.props.sortingType}
              onClickOutside={this.closeSortBoxes}
              isVisible={lineNumberSortBox}
            />
          </th>
          <th className="clause">
            <button
              className={this.getSortableButtonClassNames(
                clauseSortBoxElements,
              )}
              onClick={this.toggleClauseSortBox}
            >
              {t("comments.table.header.clause")}
            </button>
            <SortBox
              elements={clauseSortBoxElements}
              onSelectionChange={this.handleSortChange}
              selected={this.props.sortingType}
              onClickOutside={this.closeSortBoxes}
              isVisible={clauseSortBox}
            />
          </th>
          <th className="element">
            <button
              className={this.getSortableButtonClassNames(
                paragraphSortBoxElements,
              )}
              onClick={this.toggleParagraphSortBox}
            >
              {t("comments.table.header.paragraph")}
            </button>
            <SortBox
              elements={paragraphSortBoxElements}
              onSelectionChange={this.handleSortChange}
              selected={this.props.sortingType}
              onClickOutside={this.closeSortBoxes}
              isVisible={paragraphSortBox}
            />
          </th>
          <th className="type">
            <button
              className={this.getSortableButtonClassNames(typeSortBoxElements)}
              onClick={this.toggleTypeSortBox}
            >
              {t("comments.table.header.type")}
            </button>
            <SortBox
              elements={typeSortBoxElements}
              onSelectionChange={this.handleSortChange}
              selected={this.props.sortingType}
              onClickOutside={this.closeSortBoxes}
              isVisible={typeSortBox}
            />
          </th>
          <th className="author">
            <button
              className={this.getSortableButtonClassNames(
                authorSortBoxElements,
              )}
              onClick={this.toggleAuthorSortBox}
            >
              {t("comments.table.header.author")}
            </button>
            <SortBox
              elements={authorSortBoxElements}
              onSelectionChange={this.handleSortChange}
              selected={this.props.sortingType}
              onClickOutside={this.closeSortBoxes}
              isVisible={authorSortBox}
            />
          </th>
          <th className="date">
            <button
              className={this.getSortableButtonClassNames(dateSortBoxElements)}
              onClick={this.toggleDateSortBox}
            >
              {t("comments.table.header.date")}
            </button>
            <SortBox
              elements={dateSortBoxElements}
              onSelectionChange={this.handleSortChange}
              selected={this.props.sortingType}
              onClickOutside={this.closeSortBoxes}
              isVisible={dateSortBox}
            />
          </th>
          {!enableSelection && (
            <Fragment>
              <th className="tags" />
              <th className="options" />
            </Fragment>
          )}
        </tr>
      </thead>
    );
  }
}

export default withTranslation("common")(Header);
