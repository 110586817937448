import { UserManager } from "oidc-client";

const authEndpointConfig = {
  authority: process.env.REACT_APP_OPENID_AUTHORITY,
  client_id: process.env.REACT_APP_OPENID_CLIENT_ID,
  redirect_uri: process.env.REACT_APP_OPENID_REDIRECT_URL,
  scope: "openid profile",
  response_type: "id_token token",
  silent_redirect_uri: process.env.REACT_APP_OPENID_SILENT_REDIRECT_URL,
  post_logout_redirect_uri: process.env.REACT_APP_OPENID_LOGOUT_REDIRECT_URL,
  clockSkew: 60 * 30, //30m
};

function createUserManager() {
  return new UserManager(authEndpointConfig);
}

const userManager = createUserManager();

export default userManager;
