import React, { Component } from "react";
import { withTranslation } from "react-i18next";

import ModalWrapper from "../../../../common/modal-wrapper/ModalWrapper";
import DeleteModal from "../../../documents/components/modal-content/DeleteModal";

class NotificationDeleteModal extends Component {
  render() {
    const { t, showModal, onCancel, onSubmit, itemUri, itemTitle } = this.props;
    const title = itemTitle
      ? t("general.dialog.delete.title", { itemName: itemTitle })
      : t("notifications.dialog.deleteGeneric.title");

    const message = itemTitle
      ? t("general.dialog.delete.message", { itemName: itemTitle })
      : t("notifications.dialog.deleteGeneric.message");

    return (
      <ModalWrapper
        isOpen={showModal}
        requestCloseHandler={onCancel}
        shouldCloseOnOverlayClick={false}
        disableSlide
      >
        <DeleteModal
          title={title}
          message={message}
          submitHandler={onSubmit}
          cancelHandler={onCancel}
          id={itemUri}
        />
      </ModalWrapper>
    );
  }
}

export default withTranslation("common")(NotificationDeleteModal);
