import { takeEvery, call, put, select, all } from "redux-saga/effects";
import * as actions from "../state/user/actions";
import { setSelectedNationalCommittee } from "../state/national-committees/actions";
import {
  getUser,
  saveUserDetails,
  saveUserProfilePhoto,
  deleteUserProfilePhoto,
  changeUserCredentials,
  getUserSettings,
  turnOffCommittees,
  setUserEmailSetting,
  turnOffCommittee,
  turnOnCommittee,
  getUserLastSearches,
  setUserLastSearch,
  setUserLocale,
  acceptGdpr,
} from "../api/resources/user";
import { GetErrorMessage } from "../api/errorCodes";
import { isRequestSuccessful } from "../api/utils";
import {
  selectedNationalCommittee,
  userNationalCommittees,
} from "../state/national-committees/selectors";
import {
  getUserEmailSettingForAllCommittees,
  isCommitteeAlreadyTurnedOff,
  isConsultant,
  lastOpenedCommittee,
  recentSearches,
} from "../state/user/selectors";
import UrlPattern from "url-pattern";
import {
  transformCommitteeName,
  reverseTransformCommitteeName,
} from "../utils/transform";
import history from "../utils/history";
import {
  fetchAssociatedCommittees,
  fetchNationalCommittees,
} from "./nationalCommittees";
import { fetchEmailsFrequency } from "./settings";
import {
  nonCommitteePages,
  defaultLocale,
  gdprVersion,
} from "../utils/constants";
import userManager from "../services/auth/openIdClient";

export function* fetchUserDetails() {
  try {
    const response = yield call(getUser);

    if (response.status === 200) {
      yield put(actions.getUserDetailsSucceeded(response));
    }
  } catch (error) {
    console.warn(`Error during fetching user details: ${error}`);
    userManager.signoutRedirect();
  }
}

export function* fetchUserSettings() {
  try {
    const parser = new UrlPattern("/:committeeId(/:segment*)");
    const result = parser.match(window.location.pathname);

    const nationalCommittees = yield select(userNationalCommittees);
    const hasCommitteeInUrl =
      result && !nonCommitteePages.includes(result.committeeId);
    const isNonCommitteePage =
      result && nonCommitteePages.includes(result.committeeId);

    // get the whole possible committees
    // const selectedCommittees = []

    let selectedCommittee = hasCommitteeInUrl
      ? reverseTransformCommitteeName(result.committeeId)
      : yield select(lastOpenedCommittee);

    if (!selectedCommittee) {
      selectedCommittee = nationalCommittees[0].id;
    }

    yield put(setSelectedNationalCommittee(selectedCommittee));

    const searches = yield select(recentSearches);

    if (!searches) {
      yield call(fetchRecentSearches);
    }

    const emailSettings = yield select(getUserEmailSettingForAllCommittees);

    // This will only run on init
    if (!emailSettings) {
      const responseList = yield all(
        nationalCommittees.map((committee) =>
          call(getUserSettings, committee.id),
        ),
      );

      // // This is to sync all the notification settings together
      // const emailSettings = responseList.map(
      //   (response) => response.data.emailSetting,
      // );
      // const countMap = {};
      // emailSettings.forEach((emailSetting) => {
      //   countMap[emailSetting] = (countMap[emailSetting] || 0) + 1;
      // });
      // let mostFrequentString = null;
      // let maxCount = 0;
      // // Find the string with the highest count
      // for (const [str, count] of Object.entries(countMap)) {
      //   if (count > maxCount) {
      //     mostFrequentString = str;
      //     maxCount = count;
      //   }
      // }
      // yield put(
      //   actions.updateUserEmailSettingAllCommittees(mostFrequentString),
      // );

      for (const response of responseList) {
        if (response.status === 200) {
          yield put(actions.fetchUserSettingsSucceeded(response.data));
        } else {
          yield put(actions.fetchUserSettingsFailed(response.data.message));
        }
      }
    }

    if (isNonCommitteePage) return;

    if (!hasCommitteeInUrl) {
      history.push(`/${transformCommitteeName(selectedCommittee)}`);
    }
  } catch (error) {
    console.warn("Error during fetching user settings");
  }
}

function* updateUserPhoto(action) {
  const photo = action.payload;

  try {
    if (photo) {
      let profileImage = null;
      if (photo.data) {
        const response = yield call(saveUserProfilePhoto, photo.data);
        if (response.status !== 200) {
          throw response.message;
        }
        profileImage = response.data.profileImage;
      } else {
        const response = yield call(deleteUserProfilePhoto);
        if (response.status !== 200) {
          throw response.message;
        }
        profileImage = response.data.profileImage;
      }

      yield put(actions.updateUserPhotoSucceeded({ profileImage }));
    }
  } catch (error) {
    console.error(error);
    yield put(actions.updateUserPhotoFailed(error.message));
  }
}

function* updateUserProfile(action) {
  const userDetails = action.payload;

  try {
    if (userDetails) {
      const response = yield call(saveUserDetails, userDetails);
      if (response.status !== 200) throw response.message;

      yield put(actions.updateUserProfileSucceeded(userDetails));
    }
  } catch (error) {
    console.error(error);
    yield put(actions.updateUserProfileFailed(error.message));
  }
}

function* updateUserCredentials(action) {
  try {
    const response = yield call(changeUserCredentials, action.payload);
    if (response.status === 200) {
      yield put(
        actions.updateUserCredentialsSucceeded(
          "userCredentials.validation.passwordChanged",
        ),
      );
    } else {
      yield put(actions.updateUserCredentialsFailed(response.data.errorCode));
    }
  } catch (error) {
    if (error.response && error.response.data) {
      yield put(
        actions.updateUserCredentialsFailed(
          GetErrorMessage(error.response.data.errorCode),
        ),
      );
    } else {
      yield put(actions.updateUserCredentialsFailed(error.message));
    }
  }
}

function* updateTurnOffCommittees(action) {
  try {
    // const selectedCommittee = yield select(selectedNationalCommittee);
    // const turnedOffCommittees = action.payload;

    const { selectedCommittee, turnedOffCommittees } = action.payload;

    const response = yield call(
      turnOffCommittees,
      selectedCommittee,
      turnedOffCommittees,
    );

    if (response.status === 200) {
      yield put(
        actions.updateTurnOffCommittees({
          selectedCommittee,
          turnedOffCommittees,
        }),
      );
    }
  } catch (error) {
    console.error(`update turn off committees failed - ${error}`);
  }
}

function* updateUserEmailSetting(action) {
  try {
    const { selectedCommittee, emailSetting } = action.payload;
    const lowerCaseEmailSetting = emailSetting.toLowerCase();

    const response = yield call(
      setUserEmailSetting,
      selectedCommittee,
      lowerCaseEmailSetting,
    );

    if (response.status === 200) {
      yield put(
        actions.updateUserEmailSettingAllCommitteesSucceeded({
          selectedCommittee: {
            id: selectedCommittee,
          },
          emailSetting: emailSetting.toUpperCase(),
        }),
      );
    }
  } catch (error) {
    console.error(`update email setting failed - ${error}`);
  }
}

function* updateUserEmailSettingAllCommittees(action) {
  try {
    const nationalCommittees = yield select(userNationalCommittees);
    // const selectedCommittee = yield select(selectedNationalCommittee);
    const emailSetting = action.payload.toUpperCase();

    const responseList = yield all(
      nationalCommittees.map((committee) =>
        call(setUserEmailSetting, committee.id, emailSetting),
      ),
    );

    for (const idx in responseList) {
      const response = responseList[idx];
      if (response.status === 200) {
        yield put(
          actions.updateUserEmailSettingAllCommitteesSucceeded({
            selectedCommittee: nationalCommittees[idx],
            emailSetting,
          }),
        );
      }
    }
  } catch (error) {
    console.error(`update email setting failed - ${error}`);
  }
}

function* handleTurnOffCommittee(action) {
  try {
    const selectedCommittee = yield select(selectedNationalCommittee);
    const turnedOffCommittee = action.payload;
    const response = yield call(
      turnOffCommittee,
      selectedCommittee,
      turnedOffCommittee,
    );

    if (response.status === 200) {
      yield put(
        actions.turnOffCommitteeSucceeded({
          selectedCommittee,
          turnedOffCommittee,
        }),
      );
    }
  } catch (error) {
    console.warn(error);
  }
}

function* handleTurnOnCommittee(action) {
  try {
    const selectedCommittee = yield select(selectedNationalCommittee);
    const turnedOffCommittee = action.payload;
    const response = yield call(
      turnOnCommittee,
      selectedCommittee,
      turnedOffCommittee,
    );

    if (response.status === 200) {
      yield put(
        actions.turnOnCommitteeSucceeded({
          selectedCommittee,
          turnedOffCommittee,
        }),
      );
    }
  } catch (error) {
    console.warn(error);
  }
}

export function* fetchUserData() {
  yield call(fetchNationalCommittees);
  yield call(fetchUserSettings);
  yield call(fetchAssociatedCommittees);
  yield call(fetchEmailsFrequency);
}

function* fetchRecentSearches() {
  const response = yield call(getUserLastSearches);

  if (response.status === 200) {
    yield put(actions.fetchRecentSearchesSucceeded(response.data));
  } else {
    yield put(actions.fetchRecentSearchesFailed(response.data));
  }
}

export function* updateRecentSearches(action) {
  const entry = action.payload;
  try {
    if (entry) {
      const response = yield call(setUserLastSearch, entry);
      if (response.status === 200) yield call(fetchRecentSearches);
      else throw response.message;
    }
  } catch (error) {
    yield put(actions.updateRecentSearchesFailed(error.message));
  }
}

export function* handleCheckUserPrivilleges(action) {
  const consultant = yield select(isConsultant);
  if (!consultant) {
    action.payload && action.payload.url && history.push(action.payload.url);
    throw new Error("You don't have permission to do this action.");
  }
}

export function* handleTurnOnOffCommittee(action) {
  const { committee, callbackAction } = action.payload;

  const areNotificationsCommitteeAlreadyTurnedOff = yield select(
    isCommitteeAlreadyTurnedOff,
    committee,
  );
  areNotificationsCommitteeAlreadyTurnedOff
    ? yield call(handleTurnOnCommittee, { payload: committee })
    : yield call(handleTurnOffCommittee, { payload: committee });

  if (callbackAction) {
    yield put(callbackAction());
  }
}

function* handleSetAppLocale(action) {
  const languageToSet = action.payload || defaultLocale;
  yield call(setUserLocale, languageToSet);
  window.location.reload(false);
}

function* handleFetchUserDetails({ payload }) {
  //validate gdpr
  if (!payload.data.gdprAccepted || payload.data.gdprVersion !== gdprVersion) {
    window.location = "/gdpr";
  }
}

function* handleAcceptGdpr() {
  const results = yield call(acceptGdpr, gdprVersion);
  if (isRequestSuccessful(results)) {
    window.location = "/";
  }
}

export function* userDetailsSaga() {
  yield takeEvery(actions.FETCH_USER_DETAILS_REQUESTED, fetchUserDetails);
  yield takeEvery(actions.FETCH_USER_DATA_REQUESTED, fetchUserData);
  yield takeEvery(actions.UPDATE_USER_PROFILE, updateUserProfile);
  yield takeEvery(actions.UPDATE_USER_PHOTO, updateUserPhoto);
  yield takeEvery(actions.UPDATE_USER_CREDENTIALS, updateUserCredentials);
  yield takeEvery(
    [actions.TURN_OFF_COMMITTEES, actions.TURN_ON_COMMITTEES],
    updateTurnOffCommittees,
  );
  yield takeEvery(actions.TURN_OFF_COMMITTEE, handleTurnOffCommittee);
  yield takeEvery(actions.TURN_ON_COMMITTEE, handleTurnOnCommittee);
  yield takeEvery(
    actions.UPDATE_USER_EMAIL_SETTING_ALL_COMMITTEES,
    updateUserEmailSettingAllCommittees,
  );
  yield takeEvery(actions.updateUserEmailSetting, updateUserEmailSetting);
  yield takeEvery(actions.FETCH_RECENT_SEARCHES, fetchRecentSearches);
  yield takeEvery(actions.UPDATE_RECENT_SEARCHES, updateRecentSearches);
  yield takeEvery(actions.CHECK_USER_PRIVILLEGES, handleCheckUserPrivilleges);
  yield takeEvery(actions.TURN_ON_OFF_COMMITTEE, handleTurnOnOffCommittee);
  yield takeEvery(actions.SET_APP_LOCALE, handleSetAppLocale);
  yield takeEvery(actions.FETCH_USER_DETAILS_SUCCEEDED, handleFetchUserDetails);
  yield takeEvery(actions.ACCPET_GDPR, handleAcceptGdpr);
}
