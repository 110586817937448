import React, { Component } from "react";
import { withTranslation } from "react-i18next";

import "./Documents.scss";

import PanelContainer from "../../common/layout/PanelContainer";
import CommitteeDocuments from "./components/committee-documents/CommitteeDocuments";
import DocumentsLinks from "../../../components/common/document-links/DocumentsLinks";

import { compose } from "redux";

class Documents extends Component {
  state = { isLinkingDocuments: false };

  onLinkDocuments = () => {
    this.setState({ isLinkingDocuments: true });
  };

  hideLinkDocuments = () => {
    this.setState({ isLinkingDocuments: false });
  };

  render() {
    const { t } = this.props;
    return (
      <PanelContainer>
        <header className="layout__title">
          <h2 className="headline-2">{t("documents.header.name")}</h2>
        </header>
        <div className="layout__content panel panel--border documents">
          {this.state.isLinkingDocuments ? (
            <DocumentsLinks hideLinkDocuments={this.hideLinkDocuments} />
          ) : (
            <CommitteeDocuments handleLinkDocuments={this.onLinkDocuments} />
          )}
        </div>
      </PanelContainer>
    );
  }
}

export default compose(withTranslation("common"))(Documents);
