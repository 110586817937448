import React, { Component } from "react";
import "./ToolbarTool.scss";

class ToolbarTool extends Component {
  onClick() {
    if (this.props.onClick) {
      this.props.onClick();
    } else if (this.props.link) {
      var win = window.open(this.props.link, "_blank");
      win.focus();
    }
  }

  render() {
    return (
      <div className="toolbar-tool">
        <button onClick={() => this.onClick()}>
          <span
            className={`icon icon-${this.props.icon}`}
            aria-hidden="true"
          ></span>
        </button>
      </div>
    );
  }
}

export default ToolbarTool;
