import React, { Component, Fragment } from "react";
import { withTranslation } from "react-i18next";
import NotificationBar from "../notification-bar/NotificationBar";
import Avatar from "../../../../common/avatar/Avatar";
import Loader from "../../../../common/loader/Loader";
import { notificationActionType } from "../../../../../utils/constants";

class MemberNotification extends Component {
  get notificationTitle() {
    const { t } = this.props;
    const { action, details } = this.props.data;

    switch (action) {
      case notificationActionType.add:
        return t("common:notifications.types.member.add", {
          committee: details.udvalg ? details.udvalg : "",
        });
      case notificationActionType.update:
        return t("common:notifications.types.member.update");
      default:
        return t("common:notifications.types.member.update");
    }
  }

  render() {
    const { details, eventDate, notificationId } = this.props.data;

    return details ? (
      <Fragment key={notificationId}>
        <NotificationBar
          title={this.notificationTitle}
          date={eventDate}
          notificationMenu={{
            committee: details.committee,
            notificationId: notificationId,
          }}
        />
        <div className="card__content member notification-card__member">
          <div className="member__main-info">
            <Avatar name={details.name} profileImage={details.profileImage} />
            <div className="member__contact notification-card__member-contact">
              <span className="member__name">{details.name}</span>
              <span className="member__company">{details.company}</span>
              <a href={"mailto:" + details.email}>{details.email}</a>
            </div>
          </div>
        </div>
      </Fragment>
    ) : (
      <Loader />
    );
  }
}

export default withTranslation("common")(MemberNotification);
