import { handleActions } from "redux-actions";
import * as actions from "./actions";

const initialState = {
  isInitialized: false,
  userHint: {
    messageToShow: null,
  },
  globalDialogs: [],
  readonlyMode: false,
};

const actionHandlers = {
  [actions.initializeApplication]: (state) => ({
    ...state,
    isInitialized: false,
  }),
  [actions.applicationInitialized]: (state) => ({
    ...state,
    isInitialized: true,
  }),
  [actions.applicationSetUserHintState]: (state, { payload }) => ({
    ...state,
    userHint: {
      ...payload,
    },
  }),
  [actions.applicationPushGlobalDialog]: (state, { payload }) => ({
    ...state,
    globalDialogs: [...state.globalDialogs, payload],
  }),
  [actions.applicationCloseGlobalDialog]: (state, { payload }) => ({
    ...state,
    globalDialogs: state.globalDialogs.filter((d) => d.id !== payload),
  }),
  [actions.applicationSetReadonlyMode]: (state, { payload }) => ({
    ...state,
    readonlyMode: payload,
  }),
};
export default handleActions(actionHandlers, initialState);
