import React, { Fragment, Component } from "react";
import { withTranslation } from "react-i18next";
import classNames from "classnames";

class DeleteModal extends Component {
  state = { awaiting: false };

  onSubmit = () => {
    if (!this.state.awaiting) {
      this.props.submitHandler(this.props.id);
      this.setState({ awaiting: true });
    }
  };

  render() {
    const { t, title, message, cancelHandler, deleteButtonText } = this.props;

    return (
      <Fragment>
        <div className="group group--first group--full-underlined">
          <h3 className="headline-4">{title}</h3>
        </div>
        <div className="group group--last-underlined">
          <p>{message}</p>
        </div>
        <div className="group group--btn u-align-right">
          <button
            className="button"
            onClick={() => cancelHandler(this.props.id)}
          >
            {t("general.button.cancel")}
          </button>
          <button
            className={classNames("button button--primary", {
              "button--disabled button--awaiting": this.state.awaiting,
            })}
            onClick={this.onSubmit}
          >
            {deleteButtonText || t("general.button.delete")}
          </button>
        </div>
      </Fragment>
    );
  }
}

export default withTranslation("common")(DeleteModal);
