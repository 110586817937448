import React from "react";
import classNames from "classnames";

export default ({ type }) => {
  type = type || "small";
  return (
    <div
      className={classNames(
        "layout__vertical-spacer",
        `layout__vertical-spacer--${type}`,
      )}
    ></div>
  );
};
