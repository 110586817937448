import { takeEvery, call, select, put, delay } from "redux-saga/effects";
import { getCommitteeMembers } from "../api/resources/members";
import { setUserActiveState } from "../api/resources/user";
import { isRequestSuccessful } from "../api/utils";
import { selectedNationalCommittee } from "../state/national-committees/selectors";
import * as actions from "../state/members/actions";

export function* fetchCommitteeMembers() {
  try {
    const committeeId = yield select(selectedNationalCommittee);
    const response = yield call(getCommitteeMembers, committeeId);
    if (isRequestSuccessful(response)) {
      yield put(actions.getCommitteeMembersSucceeded(response.data));
    } else {
      yield put(
        actions.getCommitteeMembersFailed("Failed to fetch committee members"),
      );
    }
  } catch (error) {
    console.error(`fetch committee members failed - ${error}`);
  }
}

function* setMemberActiveState({ payload }) {
  try {
    yield delay(1000);
    const result = yield call(
      setUserActiveState,
      payload.userId,
      payload.state,
    );
    if (isRequestSuccessful(result)) {
      yield put(
        actions.setMemberActiveStateSucceeded({
          userId: payload.userId,
          state: payload.state,
        }),
      );
    } else {
      yield put(actions.setMemberActiveStateFailed({ userId: payload.userId }));
    }
  } catch (error) {
    console.error(`set member active state failed - ${error}`);
  }
}

export default function* membersSaga() {
  yield takeEvery(actions.GET_COMMITTEE_MEMBERS, fetchCommitteeMembers);
  yield takeEvery(actions.SET_MEMBER_ACTIVE_STATE, setMemberActiveState);
}
