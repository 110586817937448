import { createAction } from "redux-actions";

const FETCH_COMMENTS = "COMMENTS/fetch_comments";
const FETCH_COMMENTS_SUCCEEDED = "COMMENTS/fetch_comments_succeeded";
const FETCH_COMMENTS_FAILED = "COMMENTS/fetch_comments_failed";

const FETCH_COMMENT = "COMMENTS/fetch_comment";
const FETCH_COMMENT_SUCCEEDED = "COMMENTS/fetch_comment_succeeded";
const FETCH_COMMENT_FAILED = "COMMENTS/fetch_comment_failed";

const CREATE_COMMENT = "COMMENTS/create_comment";
const CREATE_COMMENT_SUCCEEDED = "COMMENTS/create_comment_succeeded";
const CREATE_COMMENT_FAILED = "COMMENTS/create_comment_failed";

const UPDATE_COMMENT = "COMMENTS/update_comment";
const UPDATE_COMMENT_SUCCEEDED = "COMMENTS/update_comment_succeeded";
const UPDATE_COMMENT_FAILED = "COMMENTS/update_comment_failed";

const DELETE_COMMENT = "COMMENTS/delete_comment";
export const DELETE_COMMENT_SUCCEEDED = "COMMENTS/delete_comment_succeeded";
const DELETE_COMMENT_FAILED = "COMMENTS/delete_comment_failed";

const ATTACH_FILES_TO_COMMENT_SUCCEEDED =
  "COMMENTS/attach_file_to_comment_succeeded";
const ATTACH_FILES_TO_COMMENT_FAILED = "COMMENTS/attach_file_to_comment_failed";

export const fetchComments = createAction(FETCH_COMMENTS);
export const fetchCommentsSucceeded = createAction(FETCH_COMMENTS_SUCCEEDED);
export const fetchCommentsFailed = createAction(FETCH_COMMENTS_FAILED);

export const fetchComment = createAction(FETCH_COMMENT);
export const fetchCommentSucceeded = createAction(FETCH_COMMENT_SUCCEEDED);
export const fetchCommentFailed = createAction(FETCH_COMMENT_FAILED);

export const createComment = createAction(CREATE_COMMENT);
export const createCommentSucceeded = createAction(CREATE_COMMENT_SUCCEEDED);
export const createCommentFailed = createAction(CREATE_COMMENT_FAILED);

export const updateComment = createAction(UPDATE_COMMENT);
export const updateCommentSucceeded = createAction(UPDATE_COMMENT_SUCCEEDED);
export const updateCommentFailed = createAction(UPDATE_COMMENT_FAILED);

export const deleteComment = createAction(DELETE_COMMENT);
export const deleteCommentSucceeded = createAction(DELETE_COMMENT_SUCCEEDED);
export const deleteCommentFailed = createAction(DELETE_COMMENT_FAILED);

const EXPORT_COMMENTS_TO_WORD = "COMMENTS/export_to_word";
export const exportCommentsToWord = createAction(EXPORT_COMMENTS_TO_WORD);

export const attachFilesToCommentSucceeded = createAction(
  ATTACH_FILES_TO_COMMENT_SUCCEEDED,
);
export const attachFilesToCommentFailed = createAction(
  ATTACH_FILES_TO_COMMENT_FAILED,
);
