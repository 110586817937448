import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

import Sticky from "react-stickynode";
import SidebarFilter from "../../../../common/sidebar-filter/SidebarFilter";
import SelectFilter from "../../../../common/select/SelectFilter";
import { dateSort, monthFilter } from "../../../../../utils/transform";
import { getMeetingsFilters } from "../../../../../state/meetings/selectors";
import {
  initMeetings,
  setMeetingsFilterValue,
  resetMeetingsFilters,
} from "../../../../../state/meetings/actions";
import {
  getMeetingCommitteeFilterSelectItems,
  getMeetingMonthFilterSelectItems,
} from "../../../../../state/values/selectors";
import { TIMEFRAME } from "../../../../../api/utils";

class Sidebar extends Component {
  state = {
    committee: null,
    month: null,
  };

  constructor(props) {
    super(props);
    this.currentDate = new Date();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.filters !== this.props.filters) {
      this.setState({
        committee: this.props.filters.committee,
        month: this.props.filters.month,
      });
    }
  }

  submitHandler = (e) => {
    e.preventDefault();
    const { committee, month } = this.state;
    const {
      filters: { timeframe },
      showAll,
    } = this.props;
    this.props.setMeetingsFilterValue({
      committee,
      month,
      timeframe: showAll ? TIMEFRAME.ALL : timeframe,
    });

    this.props.initMeetings();
  };

  resetFilters = (e) => {
    e.preventDefault();
    this.props.resetMeetingsFilters();

    if (this.props.showAll) {
      this.props.setMeetingsFilterValue({
        timeframe: TIMEFRAME.ALL,
      });
    }

    this.props.initMeetings();
  };

  selectCommittee = (committee) => {
    if (
      this.props.committees.some((selectItem) => selectItem.value === committee)
    ) {
      this.setState({
        committee,
      });
    } else {
      this.setState({
        committee: null,
      });
    }
  };

  selectMonth = (month) => {
    if (this.props.months.some((selectItem) => selectItem.value === month)) {
      this.setState({
        month,
      });
    } else {
      this.setState({
        month: null,
      });
    }
  };

  renderContent() {
    const { committee, month } = this.state;
    const { t, committees, months, filters } = this.props;
    const { currentDate } = this;

    return (
      <form className="sidebar-filter__form">
        <SidebarFilter
          isFiltered={filters.committee || filters.month}
          submitHandler={this.submitHandler}
          resetFilters={this.resetFilters}
        >
          {committees && (
            <div className="group group--small group--underlined">
              <SelectFilter
                items={committees}
                placeholder={t("filter.placeholder.allCommittees")}
                label={t("filter.label.chooseCommittee")}
                labelOnlyForScreenReaders={true}
                selectedItem={committee}
                onChange={this.selectCommittee}
              />
            </div>
          )}
          {months && (
            <div className="group group--small group--underlined">
              <SelectFilter
                items={months
                  .filter((obj) =>
                    monthFilter(obj, filters.timeframe, currentDate),
                  )
                  .sort((a, b) =>
                    dateSort(
                      a.date,
                      b.date,
                      filters.timeframe === TIMEFRAME.PAST ? "desc" : "asc",
                    ),
                  )}
                placeholder={t("filter.placeholder.allMonths")}
                label={t("filter.label.chooseMonth")}
                labelOnlyForScreenReaders={true}
                selectedItem={month}
                onChange={this.selectMonth}
              />
            </div>
          )}
        </SidebarFilter>
      </form>
    );
  }

  render() {
    const { disableSticky } = this.props;

    return (
      <>
        {disableSticky ? (
          <>{this.renderContent()}</>
        ) : (
          <Sticky top={100}>{this.renderContent()}</Sticky>
        )}
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  filters: getMeetingsFilters(state),
  committees: getMeetingCommitteeFilterSelectItems(state),
  months: getMeetingMonthFilterSelectItems(state),
});
const mapDispatchToProps = {
  initMeetings,
  setMeetingsFilterValue,
  resetMeetingsFilters,
};
export default compose(
  withTranslation("common"),
  connect(mapStateToProps, mapDispatchToProps),
)(Sidebar);
