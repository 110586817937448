import React, { Component } from "react";
import classNames from "classnames";
import { withTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { scrollToElement } from "../../../../../utils/scroll";

import Editor from "../../../../common/editor/Editor";
import OptionalLabel from "../../../../common/optional-label/OptionalLabel";

class NotificationGeneralInfoTab extends Component {
  setTitle = (e) => {
    this.props.setData({ title: e.target.value });
  };

  setDescription = (data) => {
    this.props.setData({ description: data });
  };

  setNotifyMembers = () => {
    const state = { notifyMembers: !this.state.notifyMembers };
    this.setState(state);
    this.props.setData(state);
  };

  get showValidationMessage() {
    return (
      !this.props.validation.title.isValid && !this.props.data.title.trim()
    );
  }

  render() {
    const {
      data: { title, description, notifyMembers },
      t,
      cancelNavLinkUrl,
    } = this.props;
    //const {isValid} = this.state.validation.title;

    return (
      <div className="tab-content">
        <form>
          <div className="group group--first group--underlined">
            <label className="field-label" htmlFor="notification-title">
              {t("notifications.detail.new.titleLabel")}
            </label>
            <input
              type="text"
              name="notification-title"
              id="notification-title"
              className={classNames("input", {
                "input--validation_error": this.showValidationMessage,
              })}
              value={title}
              onChange={this.setTitle}
            />
            {this.showValidationMessage && (
              <span className="validation-message__error">
                {t("general.validation.notEmpty")}
              </span>
            )}
          </div>
          <div className="group group--underlined">
            <label
              className="field-label"
              htmlFor="notification-description"
              style={{ marginBottom: 14 }}
            >
              {t("notifications.detail.new.descriptionLabel")}
              <OptionalLabel />
            </label>
            <Editor value={description} onEditorChange={this.setDescription} />
          </div>

          {/* <div className="group group--last-underlined">
            <label htmlFor="notification-email" className="inline-checkbox">
              <input
                className="inline-checkbox__input"
                type="checkbox"
                name="notification-email"
                value=""
                id="notification-email"
                checked={notifyMembers}
                onChange={this.setNotifyMembers}
              />
              <span className="inline-checkbox__input--off icon icon-radio-off"></span>
              <span className="inline-checkbox__input--on icon icon-radio-on"></span>
              <span>{t("notifications.detail.notifyMembers")}</span>
            </label>
          </div>
          <div className="group group--btn u-align-right">
            <NavLink to={cancelNavLinkUrl} className="button">
              {t("general.button.cancel")}
            </NavLink>
            <button
              className={classNames("button button--primary", {
                "button--awaiting button--disabled": this.state.formSubmitted,
              })}
              onClick={this.handleSubmit}
              disabled={this.state.formSubmitted}
            >
              {t("general.button.save")}
            </button>
          </div> */}
        </form>
      </div>
    );
  }
}

export default withTranslation("common")(NotificationGeneralInfoTab);
