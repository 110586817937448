export const validate = (document) => {
  let isValid = true;
  let validation = {
    filename: { isValid: true },
    title: { isValid: true },
    id: { isValid: true },
    type: { isValid: true },
    action: { isValid: true },
  };
  const { filename, title, type, action, id } = document;

  if (filename.trim() === "") {
    validation.filename.isValid = false;
    isValid = false;
  }

  if (title.trim() === "") {
    validation.title.isValid = false;
    isValid = false;
  }

  if (type === "") {
    validation.type.isValid = false;
    isValid = false;
  }

  if (action === "") {
    validation.action.isValid = false;
    isValid = false;
  }

  if (!!id && !Number.isInteger(parseInt(id))) {
    validation.id.isValid = false;
    isValid = false;
  }

  return {
    isValid,
    validation,
  };
};
