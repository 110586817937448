import React, { Component } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { compose } from "redux";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

import PanelContainer from "./common/layout/PanelContainer";
import { Content } from "./common/layout/Content";
import AggDeadlines from "./pages/aggregated/AggDeadlines";
import About from "./pages/about/About";
import { setHeaderTitle } from "../state/header/actions";

// TODO: Is it necessary to have disabled links?
export const AggPageNavigationLinks = [
  {
    name: "navigation.page.notifications",
    url: "notifications",
    component: null,
    disabled: true,
  },
  {
    name: "navigation.page.deadlines",
    url: "deadlines",
    component: AggDeadlines,
    disabled: false,
  },
  {
    name: "navigation.page.meetings",
    url: "meetings",
    component: null,
    disabled: true,
  },
  {
    name: "navigation.page.documents",
    url: "documents",
    component: null,
    disabled: true,
  },
  {
    name: "navigation.page.publishedStandards",
    url: "standards",
    component: null,
    disabled: true,
  },
  {
    name: "navigation.page.internationalCommittees",
    url: "committees",
    component: null,
    disabled: true,
  },
  {
    name: "navigation.page.members",
    url: "members",
    component: null,
    disabled: true,
  },
  {
    name: "navigation.page.about",
    subRoute: "about",
    component: About,
    disabled: true,
  },
  // {
  //   name: "navigation.page.settings",
  //   url: "settings",
  //   component: null,
  //   disabled: true,
  // },
];

class AggHome extends Component {
  componentDidMount() {
    const { setHeaderTitle, t } = this.props;

    setHeaderTitle(t("filter.placeholder.allCommittees"));
  }

  render() {
    return (
      <PanelContainer>
        <Content>
          <Switch>
            {AggPageNavigationLinks.filter((link) => !link.disabled).map(
              (link, index) => (
                <Route
                  key={index}
                  path={`/all/${link.url}`}
                  component={link.component}
                />
              ),
            )}
            <Route path="*">
              <Redirect to={{ pathname: "/all/deadlines" }} />
            </Route>
          </Switch>
        </Content>
      </PanelContainer>
    );
  }
}

const mapDispatchToProps = {
  setHeaderTitle,
};

export default compose(
  withTranslation("common"),
  connect(null, mapDispatchToProps),
)(AggHome);
