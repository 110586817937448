import React, { Component, Fragment } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";
import DocumentList from "../document-list/List";
import history from "../../../../src/utils/history";
import Loader from "../../../components/common/loader/Loader";
import {
  hasMoreDocumentsToLoad,
  isProcessingDocuments,
} from "../../../state/document/selectors";
import Filters from "../../pages/documents/components/filters/DocumentFilters";
import {
  pullNextDocumentsPageRequest,
  reloadDocuments,
  initDocuments,
} from "../../../state/document/actions";
import { InfiniteWaypointScroller } from "../../common/infinite-scroll-wrapper/InfiniteScrollWrapper";

class DocumentSelector extends Component {
  state = {
    selectedDocuments: this.props.selectedDocuments ?? [],
    listWasFilled: !!this.props.selectedDocuments?.length,
  };

  componentDidMount() {
    this.unlisten = history.listen((location) => {
      this.props.reloadDocuments();
    });

    this.props.initDocuments();
    this.loadMoreItems();
    this.props.onSelectDocuments(this.state.selectedDocuments);
  }

  componentWillUnmount() {
    this.unlisten();
  }

  showSetEmptyButton = () => {
    return (
      this.state.listWasFilled && this.state.selectedDocuments.length === 0
    );
  };

  selectHandler = (document) => {
    if (
      this.state.selectedDocuments.some(
        (selectedDocument) => selectedDocument.url === document.url,
      )
    ) {
      this.setState(
        {
          selectedDocuments: this.state.selectedDocuments.filter(
            (selectedDocument) => selectedDocument.url !== document.url,
          ),
        },
        () => this.props.onSelectDocuments(this.state.selectedDocuments),
      );
    } else {
      this.setState(
        {
          selectedDocuments: [...this.state.selectedDocuments, document],
        },
        () => this.props.onSelectDocuments(this.state.selectedDocuments),
      );
    }
  };

  get documentsWithSelectedKeyValue() {
    return this.props.documents.map((document) => {
      if (
        this.state.selectedDocuments.find((doc) => doc.url === document.url)
      ) {
        document.selected = true;
      } else {
        document.selected = false;
      }
      return document;
    });
  }

  loadMoreItems = () => {
    this.props.pullNextDocumentsPageRequest();
  };

  render() {
    return (
      <Fragment>
        <Filters isRefernece={this.props.isRefernece} />
        <InfiniteWaypointScroller
          loadMore={this.loadMoreItems}
          hasMore={this.props.hasMoreDocumentsToLoad}
          isLoading={this.props.isProcessingDocuments}
          loader={<Loader key="infinity_scroll_loader" type="content" />}
        >
          <DocumentList
            selectHandler={this.selectHandler}
            list={this.documentsWithSelectedKeyValue}
            enableSelection={true}
            showActionBox={true}
          />
        </InfiniteWaypointScroller>
      </Fragment>
    );
  }
}

const mapDispatchToProps = {
  initDocuments,
  reloadDocuments,
  pullNextDocumentsPageRequest,
};

const mapStateToProps = (state) => ({
  hasMoreDocumentsToLoad: hasMoreDocumentsToLoad(state),
  isProcessingDocuments: isProcessingDocuments(state),
});

export default compose(
  withTranslation("common"),
  connect(mapStateToProps, mapDispatchToProps),
)(DocumentSelector);
