import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { compose } from "redux";
import FileInput from "../../../../common/form/file-input/FileInput";

class UploadButtons extends Component {
  render() {
    const { t, createHandler } = this.props;

    return (
      <div className="documents__buttons group group--first group--underlined u-align-right">
        <FileInput
          onChangeHandler={createHandler}
          isInline={true}
          isSmallButton={true}
          isPrimaryButton={true}
          buttonText={t("general.form.uploadDocuments")}
          id="uploadDocuments"
          inputOptions={{
            multiple: true,
          }}
        />
        {/* <button className="button button--primary button--small" onClick={handleLinkDocuments}>
                {t("documents.linked.button.linkToDocuments")}
            </button> */}
      </div>
    );
  }
}

export default compose(withTranslation("common"))(UploadButtons);
