import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withTranslation } from "react-i18next";

import { selectedNationalCommittee } from "../../../../../state/national-committees/selectors";
import { getDeadline } from "../../../../../state/deadlines/selectors";

import { transformCommitteeName } from "../../../../../utils/transform";

import CommentForm from "../../components/comment-form/CommentForm";
import {
  commentGetter,
  getComments,
} from "../../../../../state/comments/selectors";
import { fetchDeadline } from "../../../../../state/deadlines/actions";
import {
  fetchComment,
  updateComment,
} from "../../../../../state/comments/actions";
import Loader from "../../../../common/loader/Loader";
import { userDetails } from "../../../../../state/user/selectors";
import { setPageTitle } from "../../../../../utils/pageTitle";
import { Helmet } from "react-helmet";

class EditComment extends Component {
  componentDidMount() {
    if (typeof this.props.deadline === "undefined") {
      this.props.fetchDeadline(this.props.context.parent);
      this.props.fetchComment(this.props.context.resource);
    }
  }

  get Comment() {
    return this.props.getComment(this.props.context.resource);
  }

  replaceSpansWithGuidPlaceholders(htmlString) {
    // Create a temporary container element to hold the HTML
    const container = document.createElement("div");
    container.innerHTML = htmlString;

    // Find all span elements with the class 'comment__label-box'
    const spans = container.querySelectorAll("span.comment__label-box");

    // Iterate through each span and replace it with the placeholder
    spans.forEach((span) => {
      const guid = span.getAttribute("data-guid");
      if (guid) {
        // Create the placeholder text
        const placeholder = `{{${guid}}}`;

        // Replace the span element with the placeholder
        const spanParent = span.parentNode;
        spanParent.replaceChild(document.createTextNode(placeholder), span);
      }
    });

    // Return the updated HTML as a string
    return container.innerHTML;
  }

  handleSave = (data) => {
    const guidComment = this.replaceSpansWithGuidPlaceholders(data.comment);
    const guidProposal = this.replaceSpansWithGuidPlaceholders(data.proposal);

    this.props.updateComment({
      uri: this.Comment.uri,
      comment: guidComment,
      proposal: guidProposal,
      line: data.line,
      clause: data.clause,
      paragraph: data.paragraph,
      type: data.selected,
      deadlineUri: this.props.deadline.uri,
      committee: transformCommitteeName(this.props.selectedNationalCommittee),
      subcommittee: this.props.deadline.committee,
      memberId: this.props.user.id,
      author: this.props.user.name,
      attachments: data.attachments,
      attachmentsToRemoveFromServer: data.attachmentsToRemoveFromServer,
    });
  };

  replaceGuidWithReferenceId(htmlString) {
    const comments = this.props.comments;

    // Iterate through each comment and replace the {{guid}} placeholders with {{referenceId}}
    comments.forEach((comment) => {
      const guidPlaceholder = `{{${comment.guid}}}`;
      const referenceIdPlaceholder = `{{${comment.referenceId}}}`;

      // Replace all occurrences of the {{guid}} placeholder with the {{referenceId}} placeholder
      htmlString = htmlString.replace(
        new RegExp(guidPlaceholder, "g"),
        referenceIdPlaceholder,
      );
    });

    return htmlString;
  }

  render() {
    const { deadline, selectedNationalCommittee } = this.props;
    const { t } = this.props;

    return deadline ? (
      <Fragment>
        <Helmet>
          <title>
            {setPageTitle(["Deadlines"], selectedNationalCommittee)}
          </title>
        </Helmet>
        <header className="layout__title">
          <h2 className="headline-2">
            {t("comments.edit.header")} {deadline.title}
          </h2>
        </header>
        <div className="layout__content panel">
          {this.props.deadline.uri && (
            <CommentForm
              data={{
                ...this.Comment,
                comment: this.replaceGuidWithReferenceId(this.Comment.comment),
                proposal: this.replaceGuidWithReferenceId(
                  this.Comment.proposal,
                ),
              }}
              deadlineUri={this.props.deadline.uri}
              onSave={this.handleSave}
            />
          )}
        </div>
      </Fragment>
    ) : (
      <Loader />
    );
  }
}

const mapDispatchToProps = {
  fetchDeadline,
  fetchComment,
  updateComment,
};

const mapStateToProps = (state) => ({
  deadline: getDeadline(state),
  getComment: commentGetter(state),
  user: userDetails(state),
  selectedNationalCommittee: selectedNationalCommittee(state),
  comments: getComments(state),
});

export default compose(
  withTranslation("common"),
  connect(mapStateToProps, mapDispatchToProps),
)(EditComment);
