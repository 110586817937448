import React, { useState, useEffect } from "react";

import { CURRENT } from "./Stepper";

import expandIcon from "../../../assets/icons/expand_icon.svg";

const StepperItem = ({
  type,
  code,
  description,
  title,
  isActive,
  onClick,
  chosenStep,
}) => {
  const [isOpen, setIsOpen] = useState("");

  const setToOpen = () => setIsOpen(" ");
  const setToClosed = () => setIsOpen("");

  const handleClick = () => {
    isOpen ? setToClosed() : setToOpen();
  };

  const handleStepClick = (e) => {
    e.stopPropagation();
    if (!isActive) onClick(code);
  };

  useEffect(() => {
    if (type === CURRENT) {
      onClick(code);
    }
  }, [code, onClick, type]);

  useEffect(() => {
    if (chosenStep !== code) setToClosed();
  }, [chosenStep, code]);

  return (
    <li
      className={`step is-${type}${isActive ? " active" : ""}`}
      onClick={handleStepClick}
    >
      <details open={isOpen} onClick={(e) => e.preventDefault()}>
        <summary>
          <div className="step__hero">
            <span className="step__code">{code}</span>
            {isActive && <span className="step__title">{title}</span>}
          </div>
          {isActive && (
            <button className="step__expand-btn" onClick={handleClick}>
              <img
                className={`step__expand-icon${isOpen ? " open" : ""}`}
                src={expandIcon}
                alt=""
              />
            </button>
          )}
        </summary>
        <p className="step__description">{description}</p>
      </details>
    </li>
  );
};

export default StepperItem;
