import { handleActions } from "redux-actions";
import * as actions from "./actions";

export const REFERENCE_TAB = {
  NONE: "none",
  DOCUMENTS: "documents",
  MEETINGS: "meetings",
  DEADLINES: "deadlines",
};

const initialState = {
  selectedReferenceTab: REFERENCE_TAB.NONE,
};

const actionHandlers = {
  [actions.setReferenceTab]: (state, action) => {
    const newSelectedReferenceTab = action.payload;
    // if it's not included in MODAL_REFERENCE_TAB, return
    if (!Object.values(REFERENCE_TAB).includes(newSelectedReferenceTab)) {
      console.error("Invalid reference tab");
      return state;
    }

    return {
      selectedReferenceTab: action.payload,
    };
  },
  [actions.emptyReferenceTab]: (state, action) => ({
    selectedReferenceTab: REFERENCE_TAB.NONE,
  }),
};

export default handleActions(actionHandlers, initialState);
