import React, { Component, Fragment } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

import * as documentSummaryActions from "../../../state/documentSummary/actions";
import * as documentActions from "../../../state/document/actions";
import * as documentSelectors from "../../../state/document/selectors";
import * as documentsSummarySelectors from "../../../state/documentSummary/selectors";
import * as committeesSelectors from "../../../state/national-committees/selectors";
import { otherCommitteeId } from "../../../utils/constants";

import DocumentList from "../../common/document-list/List";
import Loader from "../../common/loader/Loader";

class DocumentsSummary extends Component {
  getSummaryHeader = (committee) => {
    if (committee === otherCommitteeId) {
      return this.props.t("general.defaults.otherCommittee");
    }
    return `${committee} - ${this.props.commiteeTitleProvider(committee)}`;
  };

  onDocumentUpdated = (document) => {
    this.props.updateSummaryDocumentState(document);
  };

  renderSummary = (committee) => {
    const documents = this.props.documentsSummary.details[committee];

    return (
      <Fragment key={committee}>
        <div className="panel">
          <div className="group group--first group--underlined">
            <span className="headline-4 u-text-bold">
              {this.getSummaryHeader(committee)}
            </span>
          </div>
          <div className="group">
            <DocumentList
              list={documents}
              showActionBox={true}
              enableSelection={false}
              manualSort={true}
              onDocumentEdited={this.onDocumentUpdated}
            />
          </div>
        </div>
      </Fragment>
    );
  };

  orderedSummaryCommittees() {
    const { documentsSummary, selectedNationalCommittee } = this.props;
    let committess = Object.keys(documentsSummary.details);

    committess.sort((a, b) => {
      if (a === otherCommitteeId && b !== otherCommitteeId) return 1;

      if (a !== otherCommitteeId && b === otherCommitteeId) return -1;

      if (a === selectedNationalCommittee && b !== selectedNationalCommittee)
        return -1;

      if (a !== selectedNationalCommittee && b === selectedNationalCommittee)
        return 1;

      return a.localeCompare(b);
    });

    return committess;
  }

  anythingToShow() {
    const { documentsSummary } = this.props;
    if (documentsSummary.hasFailed || !documentsSummary.details) return false;

    return Object.keys(documentsSummary.details).length > 0;
  }

  render() {
    const { t, documentsSummary } = this.props;

    if (!documentsSummary.isFetching && !this.anythingToShow()) {
      return (
        <header className="layout__title">
          <h2 className="headline-2">{t("documentSummary.noDocuments")}</h2>
        </header>
      );
    }

    if (documentsSummary.isFetching || !documentsSummary.details)
      return <Loader />;

    return <>{this.orderedSummaryCommittees().map(this.renderSummary)}</>;
  }
}

const mapDispatchToProps = {
  initDocuments: documentActions.initDocuments,
  pullNextDocumentsPageRequest: documentActions.pullNextDocumentsPageRequest,
  generateDocumentsSummary: documentSummaryActions.generateDocumentsSummary,
  updateSummaryDocumentState: documentSummaryActions.updateDocumentState,
};

const mapStateToProps = (state) => ({
  selectedNationalCommittee:
    committeesSelectors.selectedNationalCommittee(state),
  commiteeTitleProvider: committeesSelectors.getCommitteeTitleProvider(state),
  documents: documentSelectors.getDocuments(state),
  documentsSummary: documentsSummarySelectors.documentsSummary(state),
});

export default compose(
  withTranslation("common"),
  connect(mapStateToProps, mapDispatchToProps),
)(DocumentsSummary);
