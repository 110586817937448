import React from "react";
import classNames from "classnames";
import { withTranslation } from "react-i18next";

import Card from "../../../../common/card/Card";
import CardHeader from "../../../../common/card/CardHeader";
import CardContent from "../../../../common/card/CardContent";
import CardAttachments from "../../../../common/card/CardAttachments";
import VotesAttachment from "../../../../common/card/Attachments/VotesAttachment";
import { emptyVotes } from "../../../../../utils/constants";
import {
  generateDialogLink,
  DIALOG_TYPES,
} from "../../../../../utils/dynamicLink";

export const calculateVotableDate = (t, date) => {
  const today = new Date();

  // Is the deadline date in the past?
  if (date < today) return {};

  const d14 = new Date(date);
  d14.setDate(d14.getDate() - 14);

  const month = t(`date.months.${d14.getMonth()}`).replace(/\b\w/g, (char) =>
    char.toUpperCase(),
  );
  const day = d14.getDate();
  const year = d14.getFullYear();
  const showYear = today.getFullYear() !== year;

  return {
    showLabel: true,
    showYear,
    labelYear: year,
    labelMonth: month,
    labelDate: day,
  };
};

class DeadlineCard extends React.Component {
  state = {
    showLabel: false,
    showYear: false,
    labelYear: "",
    labelMonth: "",
    labelDate: "",
  };

  componentDidMount() {
    const {
      t,
      deadline: { date },
    } = this.props;

    this.setState({ ...calculateVotableDate(t, date) });
  }

  render() {
    const {
      t,
      deadline: {
        id,
        uri,
        comments,
        committee,
        committeeName,
        date,
        title,
        description,
        references,
        enableVoting,
        closedForComments,
        isRegistered,
      },
      stage,
      callbackAction,
      userVote,
      customActions,
    } = this.props;

    const { showLabel, showYear, labelYear, labelMonth, labelDate } =
      this.state;

    const votes = this.props.votes || (enableVoting && emptyVotes);
    const dialogUrl = generateDialogLink(DIALOG_TYPES.DEADLINE_DETAILS, uri);
    const hasUserVoted = typeof userVote != "undefined";

    const headerLabelText = showLabel
      ? t("deadlines.details.cards.label", {
          date: labelDate,
          month: labelMonth,
        }) + (showYear ? `<strong>, ${labelYear}</strong>` : "")
      : "";

    return (
      <Card
        withOffset={true}
        className={classNames({
          card__highlight: this.props.highlight,
          card__inactive: !this.props.active,
        })}
      >
        <CardHeader
          committee={committee}
          committeeName={committeeName}
          date={date}
          id={id}
          uri={dialogUrl}
          title={title ? title : t("deadlines.list.unknownTitle")}
          callbackAction={callbackAction}
          customActions={customActions}
          headerLabelText={headerLabelText}
          isDeadlineRegistered={isRegistered}
        />
        {stage && (
          <CardContent
            description={t("deadlines.list.cardStage", { stage: stage })}
          />
        )}

        <CardContent description={description} />
        <CardAttachments
          references={references && references.length}
          comments={comments}
          uri={dialogUrl}
        >
          <VotesAttachment
            stage={this.props.deadline.stage}
            votes={votes}
            userVote={userVote}
            message={
              !hasUserVoted && votes && date > new Date()
                ? t("deadlines.details.votes.haveNotVotedMessage")
                : ""
            }
          />
        </CardAttachments>
      </Card>
    );
  }
}

export default withTranslation("common")(DeadlineCard);
