import { takeEvery, call, put, all } from "redux-saga/effects";
import * as actions from "../state/diagnostics/actions";
import * as notificationActions from "../state/notifications/actions";
import * as documentActions from "../state/document/actions";
import { getBroadcastInformation } from "../api/resources/notifications";

import { isDiagnosticsEnabled } from "../utils/diagnostics";

function* onFilterNotificationsSucceeded(action) {
  if (isDiagnosticsEnabled()) {
    yield all(
      action.payload.results.map((n) =>
        put(actions.pullNotificationBroadcastInfo(n.uri)),
      ),
    );
  }
}

function* pullNotificationBroadcastInfo(action) {
  const notificationId = action.payload;
  const response = yield call(getBroadcastInformation, notificationId);

  if (response.status === 200) {
    yield put(actions.pullNotificationBroadcastInfoSucceeded(response.data));
  } else {
    yield put(actions.pullNotificationBroadcastInfoFailed(notificationId));
  }
}

function* onPullDocumentsPageSucceeded(action) {
  if (isDiagnosticsEnabled()) {
    const documents = action.payload.results;
    yield all(
      documents.map((doc) =>
        put(actions.updateDocumentWithDiagnosticsData(doc)),
      ),
    );
  }
}

export default function* diagnosticsSaga() {
  yield takeEvery(
    documentActions.pullDocumentsPageSucceeded,
    onPullDocumentsPageSucceeded,
  );

  yield takeEvery(
    notificationActions.pullNotificationsPageSucceeded,
    onFilterNotificationsSucceeded,
  );
  yield takeEvery(
    actions.pullNotificationBroadcastInfo,
    pullNotificationBroadcastInfo,
  );
}
