import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import { Trans } from "react-i18next";
import NotificationBar from "../notification-bar/NotificationBar";
import NotificationCardHeader from "../notification-card-header/NotificationCardHeader";
import CardContent from "../../../../common/card/CardContent";
import CardAttachments from "../../../../common/card/CardAttachments";
import Loader from "../../../../common/loader/Loader";
import { initNotifications } from "../../../../../state/notifications/actions";
import { selectedNationalCommittee } from "../../../../../state/national-committees/selectors";
import { getCommitteeMemberById } from "../../../../../state/members/selectors";
import {
  generateDialogLink,
  DIALOG_TYPES,
} from "../../../../../utils/dynamicLink";

import { notificationActionType } from "../../../../../utils/constants";

class CommentNotification extends Component {
  get notificationTitle() {
    const { action, details } = this.props.data;
    const committeeMember = this.props.getCommitteeMemberById(
      details.author,
    ) || { name: "" };

    switch (action) {
      case notificationActionType.add:
        return (
          <Trans
            i18nKey="common:notifications.types.comment.add"
            useDangerouslySetInnerHTML={true}
            values={{ name: committeeMember.name }}
          />
        );
      case notificationActionType.update:
        return (
          <Trans
            i18nKey="common:notifications.types.comment.update"
            useDangerouslySetInnerHTML={true}
            values={{ name: committeeMember.name }}
          />
        );
      default:
        return null;
    }
  }

  render() {
    const {
      data: { description, details, notificationId, eventDate },
      initNotifications,
    } = this.props;
    const commentDetailsUrl = details
      ? generateDialogLink(DIALOG_TYPES.DEADLINE_DETAILS, details.contentId)
      : "";

    return details ? (
      details.status && details.status === 404 ? (
        <div className="card__empty">
          <div>{details.message}</div>
        </div>
      ) : (
        <Fragment key={notificationId}>
          <NotificationBar
            title={this.notificationTitle}
            date={eventDate}
            notificationMenu={{
              committee: details.committee,
              notificationId: notificationId,
            }}
          />

          <NotificationCardHeader
            date={details.date}
            committee={details.committee}
            committeeName={details.committeeName}
            title={details.title}
            uri={commentDetailsUrl}
            notificationId={notificationId}
            callbackAction={initNotifications}
          />

          <CardContent description={description} />

          <CardAttachments
            uri={commentDetailsUrl}
            documents={details.documents && details.documents.length}
            comments={details.comments}
            messages={details.messages && details.messages.length}
          />
        </Fragment>
      )
    ) : (
      <Loader />
    );
  }
}

const mapStateToProps = (state) => ({
  selectedNationalCommittee: selectedNationalCommittee(state),
  getCommitteeMemberById: getCommitteeMemberById(state),
});

const mapDispatchToProps = {
  initNotifications,
};

export default compose(
  withTranslation("common"),
  connect(mapStateToProps, mapDispatchToProps),
)(CommentNotification);
