import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import classNames from "classnames";

class DownloadZipButton extends Component {
  render() {
    const { t, onClick, isAwaiting, isDisabled } = this.props;

    return (
      <button
        className={classNames("button button--secondary", "button--small", {
          "button--awaiting button--disabled": isAwaiting,
          "button--disabled": isDisabled,
        })}
        onClick={onClick}
        type="button"
      >
        {t("notifications.detail.downloadAll")}
      </button>
    );
  }
}

export default withTranslation("common")(DownloadZipButton);
