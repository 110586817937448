import React, { Component } from "react";
import { compose } from "redux";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { userCommitteesLinkList } from "./selectors";
import UrlPattern from "url-pattern";
import { selectedNationalCommittee } from "../../../state/national-committees/selectors";
import { scrollTop } from "../../../utils/scroll";
import { allNcsId } from "../../../utils/constants";
import "./CommitteeList.scss";

class CommitteeList extends Component {
  allNcParser = new UrlPattern(`/${allNcsId}/:segment`);
  userParser = new UrlPattern("/user/:segment");
  searchParser = new UrlPattern("/search");

  state = {
    active: 0,
  };

  componentDidMount() {
    if (
      this.userParser.match(window.location.pathname) &&
      this.state.active !== null
    ) {
      this.setState({
        active: null,
      });
    } else if (
      this.searchParser.match(window.location.pathname) &&
      this.state.active !== null
    ) {
      this.setState({
        active: null,
      });
    } else if (
      this.allNcParser.match(window.location.pathname) &&
      this.state.active !== "all"
    ) {
      this.setState({
        active: allNcsId,
      });
    } else {
      this.setState({
        active: this.props.selectedNationalCommittee,
      });
    }
  }

  componentDidUpdate() {
    if (this.userParser.match(window.location.pathname)) {
      this.state.active !== null &&
        this.setState({
          active: null,
        });
    } else if (this.searchParser.match(window.location.pathname)) {
      this.state.active !== null &&
        this.setState({
          active: null,
        });
    } else if (this.state.active === null) {
      this.setState({
        active: this.props.selectedNationalCommittee,
      });
    }
  }

  setCounter = (value) => {
    return value >= 100 ? "99+" : value;
  };

  setClasses = (name) => {
    return name === this.state.active ? " active" : "";
  };

  onClickHandler = (event, element) => {
    event.preventDefault();
    scrollTop();
    this.setState({
      active: element.name,
    });
    this.props.onItemClick && this.props.onItemClick(element.name);
  };

  createLink = (element, index) => {
    return (
      <li
        key={index}
        className={
          "committee-list__link list__element" + this.setClasses(element.name)
        }
      >
        <Link
          to={element.url}
          onClick={(event) => this.onClickHandler(event, element)}
          className={"committee-list__link__a"}
        >
          <div>
            <span className="committee-list__committee">
              {element.displayName || element.name}
            </span>
            <span className="committee-list__title">{element.title}</span>
            {element.counter ? (
              <span className="committee-list__counter">
                {this.setCounter(element.counter)}
              </span>
            ) : null}
          </div>
          <div>
            <span className="committee-list__admin">
              {element.responsibleNames}
            </span>
            <span className="icon icon-arrow-right" aria-hidden="true" />
          </div>
        </Link>
      </li>
    );
  };

  render() {
    const { t } = this.props;

    return (
      <ul className="committee-list list">
        {/* Removing all committees */}
        {/* {this.createLink(
          {
            displayName: t("navigation.sideBar.allNcs"),
            name: allNcsId,
            url: "/deadlines",
          },
          allNcsId,
        )} */}
        {this.props.userCommitteesLinkList.map(this.createLink)}
      </ul>
    );
  }
}

const mapStateToProps = (state, props) => ({
  userCommitteesLinkList: userCommitteesLinkList(state, props.searchPhrase),
  selectedNationalCommittee: selectedNationalCommittee(state),
});

export default compose(
  connect(mapStateToProps, null),
  withTranslation("common"),
)(CommitteeList);
