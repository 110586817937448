import { Component } from "react";
import { connect } from "react-redux";
import { isMemberInCurrentCommittee } from "./../../../state/user/selectors";
import { isConsultant } from "../../../state/user/selectors";

class OnlyMember extends Component {
  render() {
    const {
      children,
      isMemberInCurrentCommittee,
      allowConsultant,
      isConsultant,
    } = this.props;
    return isMemberInCurrentCommittee || (allowConsultant && isConsultant)
      ? children
      : null;
  }
}

const mapStateToProps = (state) => ({
  isMemberInCurrentCommittee: isMemberInCurrentCommittee(state),
  isConsultant: isConsultant(state),
});

export default connect(mapStateToProps)(OnlyMember);
