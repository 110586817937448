import React, { Component } from "react";
import "./DocumentsDrop.scss";

export default class DocumentsDrop extends Component {
  state = {
    dropActive: false,
  };

  onDragEnter = (e) => {
    e.preventDefault();
    this.setState({ dropActive: true });
  };

  onDragOver = (e) => {
    e.preventDefault();
    this.setState({ dropActive: true });
  };
  onDragLeave = (e) => {
    e.preventDefault();
    this.setState({ dropActive: false });
  };

  onDrop = (e) => {
    e.preventDefault();

    if (e.dataTransfer.files) {
      this.props.onDropFiles(e.dataTransfer.files);
    }
    this.setState({ dropActive: false });
  };

  render() {
    return (
      <div onDragEnter={this.onDragEnter} onDrop={this.onDrop}>
        {this.state.dropActive && (
          <div
            className="documents-drop__wrapper"
            onDragEnter={this.onDragEnter}
            onDragLeave={this.onDragLeave}
            onDragOver={this.onDragOver}
            onDrop={this.onDrop}
          ></div>
        )}
        {this.props.children}
      </div>
    );
  }
}
