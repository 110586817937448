import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import DatePicker from "react-datepicker";
import classNames from "classnames";

import {
  getDeadlineCommitteeFilterSelectItems,
  getDeadlineStagesSelectItems,
  getDeadlineActionsSelectItems,
} from "../../../../../state/values/selectors";

import SelectFilter from "../../../../common/select/SelectFilter";
import Loader from "../../../../common/loader/Loader";
import OptionalLabel from "../../../../common/optional-label/OptionalLabel";
import ReferenceComponent from "../../../notifications/components/reference/ReferenceComponent";
import { REFERENCE_TAB } from "../../../../../state/references/reducer";
import {
  setFormSelectedDeadlineReferencesDocuments,
  setFormSelectedDeadlineReferencesMeetings,
} from "../../../../../state/deadlines/actions";

class DeadlineGeneralInfoEditor extends Component {
  setTitle = (e) => {
    this.props.setData({ title: e.target.value });
  };

  setDescription = (e) => {
    this.props.setData({ description: e.target.value });
  };

  setDueDate = (value) => {
    this.props.setData({ date: value });
  };

  setEnableCommenting = (value) => {
    this.props.setData({ enableCommenting: !this.props.data.enableCommenting });
  };
  setClosedForComments = (value) => {
    this.props.setData({
      closedForComments: !this.props.data.closedForComments,
    });
  };
  setEnableVoting = (value) => {
    this.props.setData({ enableVoting: !this.props.data.enableVoting });
  };

  setNotifyOnUpdate = (value) => {
    this.props.setData({
      notifyOnUpdate: !this.props.data.notifyOnUpdate,
      notifyImmediately: this.props.data.notifyOnUpdate
        ? false
        : this.props.data.notifyImmediately,
    });
  };

  setNotifyImmediately = (value) => {
    this.props.setData({
      notifyImmediately: !this.props.data.notifyImmediately,
    });
  };

  setCommittee = (value, defaultValue) => {
    if (value !== defaultValue) {
      this.props.setData({ committee: value });
    } else {
      this.props.setData({ committee: "" });
    }
  };

  setAction = (value, defaultValue) => {
    if (value !== defaultValue) {
      this.props.setData({ action: value });
    } else {
      this.props.setData({ action: "" });
    }
  };

  setStage = (value, defaultValue) => {
    if (value !== defaultValue) {
      this.props.setData({ stage: value });
    } else {
      this.props.setData({ stage: "" });
    }
  };

  showValidationMessage = (field) => {
    if (field === "date") {
      return !this.props.validation[field].isValid && !this.props.data[field];
    } else {
      return (
        !this.props.validation[field].isValid && !this.props.data[field].trim()
      );
    }
  };

  get deadlineStages() {
    const { stages } = this.props;
    return stages.map((stage) => ({
      ...stage,
      label: `${stage.value} - ${stage.label}`,
    }));
  }

  onUpdateDocument = (document) => {
    const updatedDocuments = this.props.data.referencesPerType.document.map(
      (d) => {
        if (d.url === document.url) {
          return document;
        }
        return d;
      },
    );

    this.props.setFormSelectedDeadlineReferencesDocuments(updatedDocuments);
  };

  onRemoveDocument = (id) => {
    this.props.setFormSelectedDeadlineReferencesDocuments(
      this.props.data.referencesPerType.document.filter((d) => d.url !== id),
    );
  };

  onRemoveMeeting = (id) => {
    this.props.setFormSelectedDeadlineReferencesMeetings(
      this.props.data.referencesPerType.meeting.filter((d) => d.uri !== id),
    );
  };

  render() {
    const {
      data: {
        committee,
        title,
        description,
        stage,
        date,
        enableVoting,
        enableCommenting,
        notifyOnUpdate,
        notifyImmediately,
        closedForComments,
        referencesPerType,
        past,
      },
      t,
      cancelNavLinkUrl,
      committees,
      showNotifyMembers,
      showDocumentsEditor,
    } = this.props;

    const isLoading = !(committees && committees.length);

    if (isLoading) {
      return (
        <div className="tab-content">
          <Loader type="content" />
        </div>
      );
    }

    return (
      <div className="tab-content">
        {/* remote this one  */}
        <div>
          <div className="group group--first group--underlined">
            <SelectFilter
              label={t("deadlines.details.new.committeeLabel")}
              labelOnlyForScreenReaders={false}
              items={committees}
              selectedItem={committee}
              onChange={(value) =>
                this.setCommittee(
                  value,
                  t("deadlines.details.new.committeePlaceholder"),
                )
              }
              customClasses={classNames({
                "input--validation_error":
                  this.showValidationMessage("committee"),
              })}
            />
            {this.showValidationMessage("committee") && (
              <span className="validation-message__error">
                {t("general.validation.notEmpty")}
              </span>
            )}
          </div>
          <div className="group group--underlined">
            <label className="field-label" htmlFor="deadline-title">
              {t("deadlines.details.new.titleLabel")}
            </label>
            <input
              type="text"
              name="deadline-title"
              id="deadline-title"
              // placeholder={t("deadlines.details.new.titlePlaceholder")}
              className={classNames("input", {
                "input--validation_error": this.showValidationMessage("title"),
              })}
              value={title}
              onChange={(value) => this.setTitle(value)}
            />
            {this.showValidationMessage("title") && (
              <span className="validation-message__error">
                {t("general.validation.notEmpty")}
              </span>
            )}
          </div>
          <div className="group group--underlined">
            <label className="field-label" htmlFor="deadline-description">
              {t("deadlines.details.new.descriptionLabel")}
              <OptionalLabel />
            </label>
            <textarea
              type="text"
              name="deadline-description"
              id="deadline-description"
              className="textarea"
              value={description}
              onChange={(value) => this.setDescription(value)}
            />
          </div>
          <div className="group group--underlined">
            <div className="group__flex u-no-padding">
              <div className="group__flex-column">
                <label className="field-label" htmlFor="deadline-due-date">
                  {t("deadlines.details.new.dueDateLabel")}
                </label>
                <div className="datepicker">
                  <DatePicker
                    className={classNames("input", "input--date", {
                      "input--validation_error":
                        this.showValidationMessage("date"),
                    })}
                    name="deadline-due-date"
                    id="deadline-due-date"
                    placeholderText="YYYY/MM/DD"
                    dateFormat="yyyy/MM/dd"
                    selected={date}
                    minDate={new Date()}
                    onChange={this.setDueDate}
                    autoComplete="off"
                  />
                </div>
                {this.showValidationMessage("date") && (
                  <span className="validation-message__error">
                    {t("general.validation.notEmpty")}
                  </span>
                )}
              </div>
            </div>
          </div>
          {/* <div className="group group--underlined">
                        <SelectFilter
                            label={t("deadlines.details.new.actionLabel")}
                            labelOnlyForScreenReaders={false}
                            placeholder={t("deadlines.details.new.actionPlaceholder")}
                            items={actions}
                            selectedItem={action}
                            onChange={value => this.setAction(value, t("deadlines.details.new.actionPlaceholder"))}
                        />
                    </div> */}
          <div className="group group--underlined">
            <SelectFilter
              label={t("deadlines.details.new.stageLabel")}
              optional
              labelOnlyForScreenReaders={false}
              placeholder={t("deadlines.details.new.stagePlaceholder")}
              items={this.deadlineStages}
              selectedItem={stage}
              onChange={(value) =>
                this.setStage(
                  value,
                  t("deadlines.details.new.stagePlaceholder"),
                )
              }
            />
          </div>
          <>
            <ReferenceComponent
              isModal
              referencesPerType={referencesPerType}
              disableRows={[REFERENCE_TAB.DEADLINES]}
              // Documents
              onUpdateDocument={this.onUpdateDocument}
              onRemoveDocument={this.onRemoveDocument}
              // Meetings
              onRemoveMeeting={this.onRemoveMeeting}
            />
          </>
          <div className="group group--underlined">
            <div className="group group--field">
              <div className="checkbox__group">
                <label htmlFor="enable-voting" className="inline-checkbox">
                  <input
                    className="inline-checkbox__input"
                    type="checkbox"
                    name="enable-voting"
                    checked={enableVoting}
                    id="enable-voting"
                    onChange={this.setEnableVoting}
                  />
                  <span className="inline-checkbox__input--off icon icon-radio-off"></span>
                  <span className="inline-checkbox__input--on icon icon-radio-on"></span>
                  <span className="u-text-medium-dark">
                    {t("deadlines.details.new.enableVoting")}
                  </span>
                </label>
              </div>
            </div>
            <div className="group group--field">
              <div className="checkbox__group">
                <label htmlFor="enable-commenting" className="inline-checkbox">
                  <input
                    className="inline-checkbox__input"
                    type="checkbox"
                    name="enable-commenting"
                    checked={enableCommenting}
                    id="enable-commenting"
                    onChange={this.setEnableCommenting}
                  />
                  <span className="inline-checkbox__input--off icon icon-radio-off"></span>
                  <span className="inline-checkbox__input--on icon icon-radio-on"></span>
                  <span className="u-text-medium-dark">
                    {t("deadlines.details.new.enableCommenting")}
                  </span>
                </label>
              </div>
            </div>
            <div className="group group--field">
              <div className="checkbox__group">
                <label htmlFor="allow-new-comments" className="inline-checkbox">
                  <input
                    className="inline-checkbox__input"
                    type="checkbox"
                    name="allow-new-comments"
                    checked={!closedForComments}
                    id="allow-new-comments"
                    onChange={this.setClosedForComments}
                  />
                  <span className="inline-checkbox__input--off icon icon-radio-off"></span>
                  <span className="inline-checkbox__input--on icon icon-radio-on"></span>
                  <span className="u-text-medium-dark">
                    {t("deadlines.details.new.closedForComments")}
                  </span>
                </label>
              </div>
            </div>
          </div>
          <div className="group">
            <div className="group group--field">
              <div className="checkbox__group">
                <label htmlFor="update-on-notify" className="inline-checkbox">
                  <input
                    className="inline-checkbox__input"
                    type="checkbox"
                    name="update-on-notify"
                    checked={notifyOnUpdate}
                    id="update-on-notify"
                    onChange={this.setNotifyOnUpdate}
                  />
                  <span className="inline-checkbox__input--off icon icon-radio-off"></span>
                  <span className="inline-checkbox__input--on icon icon-radio-on"></span>
                  <span className="u-text-medium-dark">
                    {t("deadlines.details.edit.notifyMembers")}
                  </span>
                </label>
                <label
                  htmlFor="update-on-notify-immediately"
                  className={classNames("inline-checkbox", {
                    "inline-checkbox--disabled": past,
                  })}
                >
                  <input
                    disabled={!notifyOnUpdate}
                    className="inline-checkbox__input"
                    type="checkbox"
                    name="update-on-notify-immediately"
                    checked={notifyImmediately}
                    id="update-on-notify-immediately"
                    onChange={this.setNotifyImmediately}
                  />
                  <span
                    className={`inline-checkbox__input--off icon icon-radio-off ${!notifyOnUpdate && "checkbox-disabled"}`}
                  ></span>
                  <span className="inline-checkbox__input--on icon icon-radio-on"></span>
                  <span className="u-text-medium-dark">
                    {t("notifications.detail.notifyMembers")}
                  </span>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = {
  setFormSelectedDeadlineReferencesDocuments,
  setFormSelectedDeadlineReferencesMeetings,
};

const mapStateToProps = (state) => ({
  committees: getDeadlineCommitteeFilterSelectItems(state),
  actions: getDeadlineActionsSelectItems(state),
  stages: getDeadlineStagesSelectItems(state),
});

export default compose(
  withTranslation("common"),
  connect(mapStateToProps, mapDispatchToProps),
)(DeadlineGeneralInfoEditor);
