import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { NavLink } from "react-router-dom";
import { withTranslation } from "react-i18next";
import NotificationDetailsTab from "../../components/notification-detail/NotificationDetailsTab";
import {
  deleteNotification,
  downloadNotificationDocuments,
  fetchNotification,
  removeReferenceFromNotification,
} from "../../../../../state/notifications/actions";
import { getNotification } from "../../../../../state/notifications/selectors";
import {
  DIALOG_TYPES,
  PAGE_TYPES,
  generateDialogLinkForPage,
} from "../../../../../utils/dynamicLink";
import { selectedNationalCommittee } from "../../../../../state/national-committees/selectors";
import WithPrivileges from "../../../../common/with-privileges/WithPrivileges";
import Loader from "../../../../common/loader/Loader";
import { setPageTitle } from "../../../../../utils/pageTitle";
import { Helmet } from "react-helmet";

import NotificationDeleteModal from "../../components/delete-modal/NotificationDeleteModal";
import { isDownloadingFile } from "../../../../../state/document/selectors";
import ReferenceComponent from "../../components/reference/ReferenceComponent";
import Toggler from "../../../../common/toggler/Toggler";
import ActionBox from "../../../../common/action-box/ActionBox";
import { PollComponent } from "../../components/poll/PollComponent";
import { isObjectEmpty } from "../../../../../utils/validation/objectValidator";

class NotificationDetails extends Component {
  state = {
    isDeleteModalOpen: false,
  };

  onDeleteClick = () => {
    this.setState({ isDeleteModalOpen: true });
  };
  onCancelDelete = () => {
    this.setState({ isDeleteModalOpen: false });
  };
  onSubmitDelete = (id) => {
    this.props.deleteNotification(id);
    this.setState({ isDeleteModalOpen: false });
  };

  componentDidMount() {
    const uri = this.props.context.resource;
    this.props.fetchNotification(uri);
  }

  onRemoveReferenceById = (id) => {
    this.props.removeReferenceFromNotification(id);
  };

  downloadAllFiles = () => {
    this.props.downloadNotificationDocuments(this.props.notification.id);
  };

  render() {
    const { t, notification, selectedNationalCommittee, match } = this.props;

    return notification ? (
      <Fragment>
        <Helmet>
          <title>
            {setPageTitle(
              ["Notifications", notification.title],
              selectedNationalCommittee,
            )}
          </title>
        </Helmet>
        <header className="layout__title-wrapper">
          <h2 className="headline-2">{notification.title}</h2>
          <WithPrivileges>
            <div className="layout__title-group group group--btn group--inline">
              <Toggler>
                <ActionBox>
                  <li className="list__element">
                    <NavLink
                      to={generateDialogLinkForPage(
                        selectedNationalCommittee,
                        PAGE_TYPES.NOTIFICATION,
                        DIALOG_TYPES.EDIT_NOTIFICATION,
                        notification.uri,
                      )}
                      className="button button--medium button--link actions-list__button"
                    >
                      {t("general.button.edit")}
                    </NavLink>
                  </li>
                  <li className="list__element">
                    <button
                      className="button button--medium button--link actions-list__button"
                      onClick={this.onDeleteClick}
                    >
                      {t("general.button.delete")}
                    </button>
                  </li>
                </ActionBox>
              </Toggler>
            </div>
          </WithPrivileges>
        </header>
        {/* <div className="layout__content">
          <SlimTabsNavigator enabled={true}>
            <SlimTabLink
              exact
              to={generateNotificationLink(
                selectedNationalCommittee,
                "details",
                transformUri(notification.uri),
              )}
              label={t("notifications.detail.details")}
            /> */}

        {/* This part is for comments on notification */}
        {/* {notification.comments && <NavLink
                                to={generateNotificationLink(
                                    selectedNationalCommittee,
                                    "comments",
                                    transformUri(notification.uri)
                                )}
                                className="tab"
                                activeClassName="tab--selected"
                            >{parseInt(notification.comments) === 1 ?
                                t("card.commentCounter") :
                                t("card.commentsCounter", {count: notification.comments})
                            }</NavLink>}
                            {notification.messages && <NavLink
                                to={generateNotificationLink(
                                    selectedNationalCommittee,
                                    "messages",
                                    transformUri(notification.uri)
                                )}
                                className="tab"
                                activeClassName="tab--selected"
                            >{parseInt(notification.messages) === 1 ?
                                t("card.messageCounter") :
                                t("card.messagesCounter", {count: notification.messages})
                            }</NavLink>} */}

        {/* </SlimTabsNavigator>
        </div> */}
        <div className="layout__content panel detail">
          <>
            <NotificationDetailsTab {...this.props.notification} />
            {!!notification.poll && !isObjectEmpty(notification.poll) && (
              <PollComponent poll={notification.poll} />
            )}
            {notification.references.length > 0 && (
              <ReferenceComponent
                isModal
                disableEdit
                referencesPerType={notification.referencesPerType}
                // document
                onUpdateDocument={this.onUpdateDocument}
                onRemoveDocument={this.onRemoveReferenceById}
                // meeting
                onRemoveMeeting={this.onRemoveReferenceById}
                // for download zip file
                downloadDocuments={{
                  onClick: this.downloadAllFiles,
                  isAwaiting: this.props.isDownloadingZipPackage,
                  isDisabled:
                    this.props.notification.referencesPerType?.document
                      .length === 0,
                }}
                // deadline
                onRemoveDeadline={this.onRemoveReferenceById}
              />
            )}
          </>

          <NotificationDeleteModal
            showModal={this.state.isDeleteModalOpen}
            onCancel={this.onCancelDelete}
            onSubmit={this.onSubmitDelete}
            itemUri={notification.uri}
            itemTitle={notification.title}
          />
        </div>
      </Fragment>
    ) : (
      <Loader />
    );
  }
}

const mapDispatchToProps = {
  deleteNotification,
  fetchNotification,
  removeReferenceFromNotification,
  downloadNotificationDocuments,
};

const mapStateToProps = (state) => {
  return {
    notification: getNotification(state),
    selectedNationalCommittee: selectedNationalCommittee(state),
    isDownloadingZipPackage: isDownloadingFile(state, "345"),
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation("common"),
)(NotificationDetails);
