import { reverseTransformCommitteeName } from "../../utils/transform";
import { roleType } from "../../utils/constants";

export function normalizeMembers(data) {
  return data.users.reduce((previous, current) => {
    const roleInCommittee = current.role.find(
      (role) => role.committee === data.committeeId,
    );
    const member = {
      userID: current.id,
      name: current.name,
      email: current.email,
      phone: current.phone,
      work: current.company,
      photo: current.profileImage,
      linkedIn: current.linkedIn,
      lastActivityDate: current.lastActivityDate || current.lastLoginDate,
      dslist: current.role.map((role) =>
        reverseTransformCommitteeName(role.committee),
      ),
      isConsultant: current.role.some(
        (role) =>
          role.committee === data.committeeId &&
          role.role === roleType.consultant,
      ),
      isChairman: current.role.some(
        (role) =>
          role.committee === data.committeeId &&
          role.role === roleType.chairman,
      ),
      isProfessional: current.role.some(
        (role) =>
          role.committee === data.committeeId &&
          role.role === roleType.professional,
      ),
      memberSince: roleInCommittee ? roleInCommittee.memberSince : "",
      role: current.role,
      isActive: current.isActive,
    };

    previous[data.committeeId]
      ? previous[data.committeeId].push(member)
      : (previous[data.committeeId] = [member]);

    return previous;
  }, {});
}

export function setActiveState(data, userId, state) {
  return Object.keys(data).reduce(
    (acc, cur) => ({
      ...acc,
      [cur]: [...data[cur]].map((m) => ({
        ...m,
        isActive: m.userID === userId ? state : m.isActive,
      })),
    }),
    {},
  );
}
