export const mapCommentToQuery = (comment) => {
  return {
    deadlineUri: comment.deadlineUri,
    deadlineCommitteeReference: comment.subcommittee,
    line: comment.line,
    clause: comment.clause,
    paragraph: comment.paragraph,
    type: comment.type,
    author: comment.author,
    comment: comment.comment,
    proposedChange: comment.proposal,
    udvalg: comment.committee,
    memberId: comment.memberId,
    references: mapAttachments(comment.attachments),
  };
};

export const mapCommentToPatchQuery = (comment) => {
  const toEncodedUri = (value) =>
    value !== null ? encodeURIComponent(value) : null;
  return {
    uri: toEncodedUri(comment.uri ? comment.uri : null),
    line: toEncodedUri(comment.line),
    clause: toEncodedUri(comment.clause),
    paragraph: toEncodedUri(comment.paragraph),
    type: toEncodedUri(comment.type),
  };
};

export const mapCommentElementsToPatchQuery = (comment) => {
  return {
    comment: comment.comment,
    proposedChange: comment.proposal,
    references: mapAttachments(comment.attachments),
  };
};

const mapAttachments = (attachments) =>
  attachments.map((file) => ({ uri: file.uri, fileName: file.name }));
