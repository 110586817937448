import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearNotifyToasterContent } from "../../../state/notify-toaster-content/actions";
import "./NotifyToaster.scss";

export const NotifyToaster = () => {
  const [isVisible, setIsVisible] = useState(false);

  const dispatch = useDispatch();
  const notifyToasterContent = useSelector(
    (state) => state.notifyToasterContent,
  );

  useEffect(() => {
    if (!notifyToasterContent.title && !notifyToasterContent.message) return;

    setIsVisible(true);

    const timeout = setTimeout(() => {
      setIsVisible(false);
      const delayedTimeout = setTimeout(() => {
        dispatch(clearNotifyToasterContent());
        clearTimeout(delayedTimeout);
      }, 500);
    }, 3000);

    return () => {
      clearTimeout(timeout);
    };
  }, [notifyToasterContent.title, notifyToasterContent.message]);

  return (
    <div
      className={`notify-modal ${isVisible ? "visible" : ""} ${notifyToasterContent.type}`}
    >
      <div className="notify-modal__content">
        {/* <h2 className="notify-modal__title">{notifyToasterContent.title}</h2> */}
        <p className="notify-modal__message">{notifyToasterContent.message}</p>
      </div>
    </div>
  );
};
