import React from "react";
import Linkify from "react-linkify";
import { targetBlankDecorator } from "../../../utils/LinkifyDecorator";

export default ({ description }) => {
  return (
    <Linkify componentDecorator={targetBlankDecorator}>
      <div
        className="card__content"
        dangerouslySetInnerHTML={{ __html: description }}
        style={{ marginTop: 14 }}
      ></div>
    </Linkify>
  );
};
