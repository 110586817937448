import React, { Component, Fragment } from "react";
import { withTranslation } from "react-i18next";
import { compose } from "redux";
import { setPageTitle } from "../../../utils/pageTitle";
import { Helmet } from "react-helmet";
import { selectedNationalCommittee } from "../../../state/national-committees/selectors";
import { connect } from "react-redux";

import "./PublishedStandards.scss";

class ExternalPublishedStandards extends Component {
  get standardDistributeLink() {
    const { selectedNationalCommittee } = this.props;
    return (
      process.env.REACT_APP_STANDARD_DISTRIBUTE_URL + selectedNationalCommittee
    );
  }

  render() {
    const { selectedNationalCommittee, t } = this.props;

    return (
      <Fragment>
        <Helmet>
          <title>
            {setPageTitle(["Published Standards"], selectedNationalCommittee)}
          </title>
        </Helmet>
        <header className="layout__title">
          <h2 className="headline-2">{t("publishedStandards.header.name")}</h2>
        </header>
        <div className="layout__content published-standards">
          <p className="published-standards__external_link">
            {t("publishedStandards.externalLink.message")}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={this.standardDistributeLink}
            >
              {t("publishedStandards.externalLink.link")}
            </a>
          </p>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  selectedNationalCommittee: selectedNationalCommittee(state),
});

export default compose(
  withTranslation("common"),
  connect(mapStateToProps, null),
)(ExternalPublishedStandards);
