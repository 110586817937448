import React from "react";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { selectedNationalCommittee } from "../../../state/national-committees/selectors";
import { generateLink } from "../../../utils/dynamicLink";
import classNames from "classnames";
import { NotificationTopButton } from "./NotificationTopButton";
import { userCommittees } from "../../../state/user/selectors";

export const Header = ({ heading, children }) => {
  const history = useHistory();
  const currentCommittee = useSelector(selectedNationalCommittee);
  const currentUserCommittees = useSelector(userCommittees);

  const notificationLink = generateLink(currentCommittee, "notifications");

  const getFormattedHeading = () => {
    const idx = heading.indexOf(" - ");

    if (idx === -1) return <strong>{heading}</strong>;

    return (
      <>
        <strong>{heading.slice(0, idx)}</strong>
        {heading.slice(idx)}
      </>
    );
  };

  return (
    <div className="layout__header u-medium-up">
      <div className="layout__heading">
        <span className="heading-text">{getFormattedHeading()}</span>
        {/* <span className="heading-icon icon icon-arrow-left-blue"></span> */}
        {/* <NotificationTopButton /> */}
      </div>
      {children}
    </div>
  );
};
