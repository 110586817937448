import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";

import DocumentSummaryModal from "../../../../pages/documents-summary/DocumentSummaryModal";
import { selectedNationalCommittee } from "../../../../../state/national-committees/selectors";
import { dialogSizes } from "../../../../../utils/constants";

import * as appActions from "../../../../../state/app/actions";
import DocumentList, {
  DOCUMENT_TABLE_COLUMNS,
} from "../../../../common/document-list/List";

class DocumentSummaryCardAttachments extends Component {
  // getModalParams = (notificationId, documentsCount) => {
  //   return {
  //     ids: [notificationId],
  //     documentsCount: documentsCount,
  //   };
  // };

  // onDocumentSummationDetails = () => {
  //   const { notificationId, documents } = this.props;
  //   this.props.applicationPushGlobalDialog({
  //     id: uuidv4(),
  //     component: DocumentSummaryModal,
  //     modalSettings: {
  //       allowEscape: true,
  //       sizeType: dialogSizes.wide,
  //       closeButton: true,
  //     },
  //     props: this.getModalParams(notificationId, documents.length),
  //   });
  // };

  renderTable() {
    const { documents } = this.props;

    return (
      <DocumentList
        disabledColumns={[
          DOCUMENT_TABLE_COLUMNS.COMMITTEE,
          DOCUMENT_TABLE_COLUMNS.DUE_DATE,
          DOCUMENT_TABLE_COLUMNS.DATE,
        ]}
        disableSort
        list={documents}
        showActionBox={false}
        enableSelection={false}
        manualSort={false}
      />
    );
  }

  render() {
    const { documents, t } = this.props;
    const documentsCount = documents.length;

    if (!documentsCount) return null;

    return this.renderTable();

    // return documentsCount ? (
    //   <div className="card__attachments">
    //     {documentsCount && (
    //       <button
    //         onClick={this.onDocumentSummationDetails}
    //         className="card__file button button--link"
    //         data-link-type="documents"
    //       >
    //         <span
    //           className="icon icon-file icon--large"
    //           aria-hidden="true"
    //         ></span>
    //         {documentsCount === 1
    //           ? t("card.documentCounter")
    //           : t("card.documentsCounter", { count: documentsCount })}
    //       </button>
    //     )}
    //   </div>
    // ) : null;
  }
}

const mapStateToProps = (state) => ({
  selectedNationalCommittee: selectedNationalCommittee(state),
});

const mapDispatchToProsp = {
  applicationPushGlobalDialog: appActions.applicationPushGlobalDialog,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProsp),
  withTranslation("common"),
)(DocumentSummaryCardAttachments);
