import { combineActions, handleActions } from "redux-actions";
import * as actions from "./actions";
import { normalizeComment, normalizeComments } from "./normalizers";

const initialState = {
  isProcessingRequest: false,
  data: {},
};

const actionHandlers = {
  [combineActions(
    actions.fetchComments,
    actions.fetchComment,
    actions.updateComment,
    actions.deleteComment,
    actions.createComment,
  )]: (state) => ({
    ...state,
    isProcessingRequest: true,
  }),
  [actions.fetchCommentsSucceeded]: (state, { payload }) => ({
    ...state,
    isProcessingRequest: false,
    data: {
      ...state.data,
      ...normalizeComments(payload),
    },
  }),
  [actions.fetchCommentSucceeded]: (state, { payload }) => ({
    ...state,
    isProcessingRequest: false,
    data: {
      ...state.data,
      ...normalizeComments([payload]),
    },
  }),
  [actions.updateCommentSucceeded]: (state, { payload }) => ({
    ...state,
    isProcessingRequest: false,
    data: {
      ...state.data,
      [payload.deadlineUri]: state.data[payload.deadlineUri].map((item) =>
        item.uri === payload.uri ? normalizeComment(payload) : item,
      ),
    },
  }),
  [actions.createCommentSucceeded]: (state, { payload }) => {
    let data = state.data;
    if (typeof data[payload.deadlineUri] === "undefined") {
      data = normalizeComments([payload]);
    } else {
      data[payload.deadlineUri] = [
        ...data[payload.deadlineUri],
        normalizeComment(payload),
      ];
    }
    return {
      ...state,
      isProcessingRequest: false,
      data: data,
    };
  },
  [actions.deleteCommentSucceeded]: (state, { payload }) => {
    let data = {};

    for (let [deadlineUri, comments] of Object.entries(state.data)) {
      data[deadlineUri] = comments.filter((comment) => {
        return comment.uri !== payload.uri;
      });
    }

    return {
      ...state,
      isProcessingRequest: false,
      data: data,
    };
  },
  [combineActions(
    actions.fetchCommentsFailed,
    actions.fetchCommentFailed,
    actions.updateCommentFailed,
    actions.createCommentFailed,
    actions.deleteCommentFailed,
  )]: (state) => ({
    ...state,
    isProcessingRequest: false,
  }),
};

export default handleActions(actionHandlers, initialState);
