import React, { Component } from "react";
import classNames from "classnames";
import { withTranslation } from "react-i18next";

export const messageType = {
  error: 1,
  success: 2,
};

class MessageBox extends Component {
  render() {
    const formMessageBoxClass = classNames(
      "form-message-box",
      {
        "form-message-box__errors":
          this.props.messageType === messageType.error,
      },
      {
        "form-message-box__success":
          this.props.messageType === messageType.success,
      },
    );
    const t = this.props.t;

    return (
      this.props.messages &&
      this.props.messages.length > 0 && (
        <div className="group group--message group--underlined">
          <div className={formMessageBoxClass}>
            {this.props.messages.map((message) => (
              <p key={this.props.messages.indexOf(message)}>{t(message)}</p>
            ))}
          </div>
        </div>
      )
    );
  }
}

export default withTranslation("common")(MessageBox);
