import { api } from "../api";
import { transformCommitteeName } from "../../utils/transform";
import { getProxyUrl } from "../utils";

export function getCommitteeMembers(committeeId) {
  return api.get(
    `/api/kundeportal/users?committee=${transformCommitteeName(committeeId)}`,
  );
}

export function getMemberNotification(uri) {
  let proxyUrl = getProxyUrl(`/Member/notification?uri=${uri}`);
  return api.get(proxyUrl);
}

export function getDsMemberById(id) {
  let proxyUrl = getProxyUrl(`/Member/member/${id}`);
  return api.get(proxyUrl);
}

export function getMemberById(id) {
  return api.get(`/api/users?id=${encodeURIComponent(id)}`);
}
