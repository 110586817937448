import TagManager from "react-gtm-module";

export const ResourcesTypes = {
  deadline: "deadline",
  document: "document",
  meeting: "meeting",
};

export default {
  initialize() {
    const tagManagerArgs = {
      gtmId: process.env.REACT_APP_GTM_ID,
    };

    TagManager.initialize(tagManagerArgs);
  },

  setUser: (userId) => {
    window.dataLayer.push({
      event: "user.login",
      userId: userId,
    });
  },

  setUserLanguage: (lang) => {
    window.dataLayer.push({
      event: "user.language",
      language: lang,
    });
  },

  showDeleteDialog: (resourceType, resourceId) => {
    window.dataLayer.push({
      event: "notification.delete",
      notificationType: resourceType,
      notificationId: resourceId,
      action: "prompt",
    });
  },

  closeDeleteDialog: (resourceType, resourceId) => {
    window.dataLayer.push({
      event: "notification.delete",
      notificationType: resourceType,
      notificationId: resourceId,
      action: "cancel",
    });
  },

  deletionConfirmed: (resourceType, resourceId) => {
    window.dataLayer.push({
      event: "notification.delete",
      notificationType: resourceType,
      notificationId: resourceId,
      action: "delete",
    });
  },
};
