import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";
import ReferenceDocumentsRow from "./ReferenceDocumentsRow";
import ReferenceMeetingRow from "./ReferenceMeetingRow";
import "./ReferencePage.scss";
import "./ReferenceComponent.scss";
import ReferenceDeadlineRow from "./ReferenceDeadlineRow";
import { REFERENCE_TAB } from "../../../../../state/references/reducer";
import { setReferenceTab } from "../../../../../state/references/actions";

class ReferenceComponent extends Component {
  isDisabled(tab) {
    const { disableRows } = this.props;
    return disableRows?.includes(tab);
  }

  onSelectTab = (selected) => {
    const { isModal, setReferenceTab } = this.props;

    if (!isModal) return;

    setReferenceTab(selected);
  };

  get isReferencesEmpty() {
    const { referencesPerType } = this.props;
    return (
      referencesPerType?.document?.length === 0 &&
      referencesPerType?.meeting?.length === 0 &&
      referencesPerType?.deadline?.length === 0
    );
  }

  render = () => {
    const { basePath, t, isModal, referencesPerType, disableEdit } = this.props;
    const documentsPath = `${basePath}/references/documents`;
    const meetingsPath = `${basePath}/references/meetings`;
    const deadlinesPath = `${basePath}/references/deadlines`;

    if (disableEdit && this.isReferencesEmpty) return null;

    return (
      <div className="group group--underlined references">
        <label className="field-label" style={{ marginBottom: 14 }}>
          {t("references.title")}
        </label>
        {!this.isDisabled(REFERENCE_TAB.DOCUMENTS) && (
          <ReferenceDocumentsRow
            referenceDocumentList={referencesPerType?.document ?? []}
            onUpdateDocument={this.props.onUpdateDocument}
            onRemoveDocument={this.props.onRemoveDocument}
            disableEdit={disableEdit}
            // for download zip file
            downloadDocuments={this.props.downloadDocuments}
            // downloadDocuments : {
            //   onClick: () => {},
            //   isAwaiting: bool,
            //   isDisabled: bool,
            // }

            isModal={isModal}
            moveToLink={documentsPath}
            onSelect={this.onSelectTab}
          />
        )}
        {!this.isDisabled(REFERENCE_TAB.MEETINGS) && (
          <ReferenceMeetingRow
            onRemoveMeeting={this.props.onRemoveMeeting}
            selectedMeetings={referencesPerType?.meeting ?? []}
            disableEdit={disableEdit}
            isModal={isModal}
            moveToLink={meetingsPath}
            onSelect={this.onSelectTab}
          />
        )}
        {!this.isDisabled(REFERENCE_TAB.DEADLINES) && (
          <ReferenceDeadlineRow
            onRemoveDeadline={this.props.onRemoveDeadline}
            selectedDeadlines={referencesPerType?.deadline ?? []}
            disableEdit={disableEdit}
            isModal={isModal}
            moveToLink={deadlinesPath}
            onSelect={this.onSelectTab}
          />
        )}
      </div>
    );
  };
}

const mapDispatchToProps = {
  setReferenceTab,
};

const mapStateToProps = (state) => ({});

export default compose(
  withTranslation("common"),
  connect(mapStateToProps, mapDispatchToProps),
)(ReferenceComponent);
