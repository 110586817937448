import {
  referencesPerTypeToPatchQuery,
  referencesPerTypeToReferences,
} from "./references";

export function mapToApiModel(meeting) {
  const references = referencesPerTypeToReferences(meeting.referencesPerType);

  return {
    id: meeting.id,
    uri: meeting.uri,
    committeeReference: meeting.committee,
    committeeReferenceTitle: meeting.committeeName,
    title: meeting.title.trim(),
    fromDate: meeting.fromDate,
    toDate: meeting.toDate,
    eventDate: meeting.eventDate,
    createdDate: meeting.createdDate,
    longLocation: meeting.locationLong.trim(),
    shortLocation: meeting.locationShort.trim(),
    notifyMembers: meeting.notifyMembers,
    past: meeting.past,
    references,
    isParticipationEnabled: meeting.isParticipationEnabled,
  };
}

export function mapMeetingToPatchQuery(meeting, notify) {
  // const toEncodedIsoString = (date) => date ? encodeURIComponent(date.toISOString()) : null;
  const toIsoDateTime = (date) =>
    new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString();
  const toEncodedUri = (value) =>
    value !== null ? encodeURIComponent(value) : null;
  return {
    uri: toEncodedUri(meeting.uri ? meeting.uri : null),
    committeeReference: toEncodedUri(meeting.committee),
    title: toEncodedUri(meeting.title),
    from: toIsoDateTime(meeting.fromDate),
    to: toIsoDateTime(meeting.toDate),
    longLocation: toEncodedUri(meeting.locationLong),
    shortLocation: toEncodedUri(meeting.locationShort),
    notify: toEncodedUri(notify),
    isParticipationEnabled: meeting.isParticipationEnabled,
  };
}
export function mapMeetingDocumentsToPatchQuery(meeting) {
  const toEncodedUri = (value) =>
    value !== null ? encodeURIComponent(value) : null;

  const references = referencesPerTypeToPatchQuery(meeting.referencesPerType);

  return references;
}
