import {
  referencesPerTypeToPatchQuery,
  referencesPerTypeToReferences,
} from "./references";

function getPollRequestModel(poll) {
  if (!poll) return null;

  return {
    title: poll.title,
    options: poll.options,
    isMultiVote: poll.isMultiVote,
    deadline: poll.deadline,
  };
}

export function mapToApiModel(notification, memberId, isBySystem) {
  const references = isBySystem
    ? notification.references
    : referencesPerTypeToReferences(notification.referencesPerType);

  const poll = getPollRequestModel(notification.poll);

  return {
    ...notification,
    memberId,
    // notifyMembers is from Notification
    // notify is from Deadline, Meeting
    notify: notification.notifyMembers ?? notification.notify,
    references,
    poll,
  };
}

export function mapNotificationToPatchQuery(notification) {
  const toEncodedUri = (value) =>
    value !== null ? encodeURIComponent(value) : null;

  const query = {
    uri: toEncodedUri(notification.uri ? notification.uri : null),
    title: toEncodedUri(notification.title),
    description: toEncodedUri(notification.description),
  };

  if (notification.notifyMembers) query.notify = notification.notifyMembers;

  return query;
}

export function mapNotificationDocumentsToPatchQuery(notification) {
  const references = referencesPerTypeToPatchQuery(
    notification.referencesPerType,
  );

  return references;
}
