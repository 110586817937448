import {
  takeLatest,
  takeEvery,
  takeLeading,
  call,
  select,
  put,
  all,
  take,
  fork,
  delay,
} from "redux-saga/effects";
import * as actions from "../state/notifications/actions";
import * as documentActions from "../state/document/actions";
import * as notifyToasterContentActions from "../state/notify-toaster-content/actions";
import { selectedNationalCommittee } from "../state/national-committees/selectors";
import {
  updateNotification,
  deleteNotification,
  createNotification,
  getNotification,
  pinNotification,
  unpinNotification,
  markNotificationsAsSeen,
  markAllNotificationAsSeen,
  waitForNotificationId,
  sendInstantEmailWithNotificationIdUrl,
  sendInstantEmailWithCommitteeIdUrl,
  updatePollUrl,
  voteNotificationPollUrl,
  deleteVoteNotificationPollUrl,
} from "../api/resources/notifications";
import i18next from "i18next";

import { isRequestSuccessful } from "../api/utils";

import history from "../utils/history";
import { transformCommitteeName } from "../utils/transform";
import {
  DIALOG_TYPES,
  PAGE_TYPES,
  generateDialogLink,
  generateDialogLinkForPage,
} from "../utils/dynamicLink";

import * as selectors from "../state/notifications/selectors";
import { getNewNotificationFormData } from "../state/notifications/selectors";
import { mapToApiModel } from "../api/mappers/notification";
import { addGeneralNotificationInformation } from "../state/notifications/actions";
import { getDocumentUrl, getDocumentSafeUrl } from "../api/resources/document";
import { initDocuments } from "../state/document/actions";

import * as userSelectors from "../state/user/selectors";
import { decreaseNumberOfUnseenNotifications } from "../state/user/actions";
import { documentFilters } from "../utils/documentUtils";
import { getMeetingUrl } from "../api/resources/meetings";
import { getDeadlineUrl } from "../api/resources/deadlines";
import { referenceListToReferenceDataPerType } from "../utils/references";
import { normalizeObjectOfReferences } from "../state/references/normalizers";
import { removeReferenceById } from "../state/document/normalizers";
import { NOTIFY_TOASTER_TYPE } from "../state/notify-toaster-content/reducer";
import { INSTANT_EMAIL_PARAM_TYPE } from "../state/notifications/reducer";
import { notificationType } from "../utils/constants";
import { isObjectEmpty } from "../utils/validation/objectValidator";

function* handleUpdateNotification() {
  try {
    const notification = yield select(getNewNotificationFormData);
    const poll =
      !notification.poll || isObjectEmpty(notification.poll)
        ? null
        : {
            ...notification.poll,
            notificationUri: notification.uri,
            options: notification.poll?.options.map((option) => ({
              ...option,
              pollNotificationUri: notification.uri,
            })),
            deadline: notification.poll.deadline ?? null,
          };

    if (poll) yield call(handleUpdatePoll, { payload: poll });
    const response = yield call(updateNotification, notification);

    if (response.status === 200) {
      yield call(waitForNotificationId, notification.uri);
      yield put(actions.updateNotificationSucceeded(response.data));
      history.push(
        generateDialogLink(DIALOG_TYPES.NOTIFICATION_DETAILS, notification.uri),
      );
      yield put(actions.resetNotificationsFilters());
      yield put(actions.initNotifications());
    } else {
      yield put(
        actions.updateNotificationFailed("Failed to update notification"),
      );
    }
  } catch (error) {
    console.error(`update notification failed - ${error}`);
  }
}

function* handleDeletingNotification(action) {
  try {
    const committeeId = yield select(selectedNationalCommittee);
    const notificationId = action.payload;

    const response = yield call(deleteNotification, notificationId);

    if (isRequestSuccessful(response)) {
      yield put(actions.deleteNotificationSucceeded(notificationId));
      history.push(`/${transformCommitteeName(committeeId)}/notifications`);
    } else {
      yield put(
        actions.deleteNotificationFailed("Failed to delete notification"),
      );
    }
  } catch (error) {
    console.error(`delete notification failed - ${error}`);
  }
}

export function* handleCreateNotificationBySystem(data) {
  yield call(handleCreateNotification, data, true);
}

export function* handleCreateNotification(data, isBySystem = false) {
  try {
    const committeeId = yield select(selectedNationalCommittee);
    const notificationData = isBySystem
      ? data.payload
      : yield select(selectors.getNewNotificationFormData);
    const memberId = yield select(userSelectors.userId);

    const response = yield call(
      createNotification,
      committeeId,
      mapToApiModel(notificationData, memberId, isBySystem),
    );
    if (response.status === 200) {
      yield put(actions.createNotificationSucceeded(response.data));
      yield call(waitForNotificationId, response.data.uri);

      if (!isBySystem) {
        history.push(`/${transformCommitteeName(committeeId)}/notifications`);
        yield put(actions.resetNotificationsFilters());
        yield put(actions.initNotifications());
      }
    } else {
      yield put(
        actions.createNotificationFailed("Failed to create notification"),
      );
    }
  } catch (error) {
    console.error(`create notification failed - ${error}`);
  }
}

function* handleInitNotificationForm(action) {
  try {
    yield put(initDocuments());

    const notificationId = action.payload;
    const notification = yield call(getNotification, notificationId);

    if (notification.status === 200) {
      yield put(
        addGeneralNotificationInformation({
          uri: notification.data.uri,
          title: notification.data.title,
          description: notification.data.description,
          notifyOnUpdate: notification.data.notifyOnUpdate,
          referencesRawResponsePerType: referenceListToReferenceDataPerType(
            notification.data.references,
          ),
          poll: notification.data.poll,
        }),
      );

      yield call(handleFetchNotificationReferences, REFERENCE_TARGET.FORM);

      // let documents = yield all(
      //   notification.data.references.map((document) =>
      //     call(getDocumentSafe, document.uri),
      //   ),
      // );
      // documents = documents.filter((d) => d !== null && d.status === 200);

      // if (documents) {
      //   yield put(initNotificationFormWithDocuments(documents));
      // }
    }
  } catch (error) {
    console.error(`init notification form failed - ${error}`);
  }
}

function* handleCreateNotificationFromDocument(action) {
  try {
    const selectedDocument = action.payload;
    yield put(actions.setSelectedNotificationDocuments([selectedDocument]));
    yield call(createNotificationFromReferences, selectedDocument);
  } catch (error) {
    console.error(
      `create notification from selected documents failed - ${error}`,
    );
  }
}

function* createNotificationFromReferences(reference) {
  yield put(
    actions.addGeneralNotificationInformation({
      title: reference.title,
    }),
  );
  const committeeId = yield select(selectedNationalCommittee);
  history.push(
    generateDialogLinkForPage(
      committeeId,
      PAGE_TYPES.NOTIFICATION,
      DIALOG_TYPES.CREATE_NOTIFICATION,
    ),
  );
}

function* handleCreateNotificationFromMeeting(action) {
  try {
    const selectedMeeting = action.payload;
    yield put(actions.setSelectedNotificationMeetings([selectedMeeting]));
    yield call(createNotificationFromReferences, selectedMeeting);
  } catch (error) {
    console.error(
      `create notification from selected meeting failed - ${error}`,
    );
  }
}

function* handleCreateNotificationFromDeadline(action) {
  try {
    const selectedDeadline = action.payload;
    yield put(actions.setSelectedNotificationDeadlines([selectedDeadline]));
    yield call(createNotificationFromReferences, selectedDeadline);
  } catch (error) {
    console.error(
      `create notification from selected deadline failed - ${error}`,
    );
  }
}

function* handleDownloadNotificationDocuments(action) {
  try {
    const notificationId = action.payload;
    const documentsUris = yield select(
      selectors.getNotificationDocumentsUri,
      notificationId,
    );
    const notificationUri = yield select(
      selectors.getNotificationUri,
      notificationId,
    );

    yield put(
      documentActions.downloadZipPackage({
        id: notificationId,
        packageName: `${notificationUri || "documents"}.zip`,
        uris: documentsUris,
      }),
    );
  } catch (error) {
    console.error(`download notification documents failed - ${error}`);
  }
}

function* handleFetchUpdatedNotificationDocument(action) {
  try {
    const url = action.payload;

    yield take(documentActions.editDocumentSucceeded);
    const document = yield call(getDocumentUrl, url);
    if (document.status === 200) {
      yield put(
        actions.fetchUpdatedNotificationDocumentSucceeded(document.data),
      );
    } else {
      yield put(
        actions.fetchUpdatedNotificationDocumentFailed(
          "Failed to fetch updated notification",
        ),
      );
    }
  } catch (error) {
    console.error(`fetch updated notification documents failed - ${error}`);
  }
}

function* handleRemoveReferenceFromNotification(action) {
  try {
    const notification = yield select(selectors.getNotification);
    // meeting.references = meeting.references.filter(doc => doc.url !== action.payload);

    // for(const key in notification.referencesPerType) {
    //   notification.referencesPerType[key] =
    //  notification.referencesPerType[key].filter((doc) => doc.uri !== action.payload);
    // }
    notification.referencesPerType = removeReferenceById(
      notification.referencesPerType,
      action.payload,
    );

    const response = yield call(updateNotification, notification);

    if (response.status === 200) {
      // TODO: Do we need this?
      yield call(waitForNotificationId, notification.uri);
      yield put(
        actions.removeReferenceFromNotificationSucceeded(response.data),
      );
      yield call(
        handleFetchNotificationReferences,
        REFERENCE_TARGET.NOTIFICATION,
      );
    } else {
      yield put(
        actions.updateNotificationFailed("Failed to update notification"),
      );
    }
    // const response = yield call(updateMeetingUrl, notification, false);

    // if (response.status === 200) {
    //     yield put(actions.fetchCurrentMeetingDocuments());
    //     yield put(actions.updateMeetingSucceeded(response.data));
    // } else {
    //     yield put(actions.updateMeetingFailed("Failed to update meeting"));
    // }
  } catch (error) {
    yield put(
      actions.updateNotificationFailed(
        "Failed to update Notification References",
      ),
    );
    console.error(`handleRemoveReferenceFromNotification failed - ${error}`);
  }
}

function* handleFetchNotification(action) {
  try {
    const uri = action.payload;
    const notificationResponse = yield call(getNotification, uri);

    if (isRequestSuccessful(notificationResponse)) {
      yield put(actions.fetchNotificationSucceeded(notificationResponse.data));

      yield call(
        handleFetchNotificationReferences,
        REFERENCE_TARGET.NOTIFICATION,
      );
    }
  } catch (error) {
    console.error(`getting notification details failed - ${error}`);
  }
}

const REFERENCE_TARGET = {
  FORM: "form",
  NOTIFICATION: "notification",
};
// target: REFERENCE_TARGET
function* handleFetchNotificationReferences(target) {
  try {
    const isNotification = target === REFERENCE_TARGET.NOTIFICATION;

    // yield take(actions.fetchNotificationSucceeded);
    const notification = yield select(
      isNotification
        ? selectors.getNotification
        : selectors.getNewNotificationFormData,
    );

    const documentTargets =
      notification.referencesRawResponsePerType.document ?? [];
    const meetingTargets =
      notification.referencesRawResponsePerType.meeting ?? [];
    const deadlineTargets =
      notification.referencesRawResponsePerType.deadline ?? [];
    const documentsResponse = yield all(
      documentTargets.map((element) => call(getDocumentSafeUrl, element.uri)),
    );
    const document = documentFilters.filterEmptyDocuments(documentsResponse);

    const meetingsResponse = yield all(
      meetingTargets.map((element) => call(getMeetingUrl, element.uri)),
    );
    const meeting = documentFilters.filterEmptyDocuments(meetingsResponse);

    const deadlinesResponse = yield all(
      deadlineTargets.map((element) => call(getDeadlineUrl, element.uri)),
    );
    const deadline = documentFilters.filterEmptyDocuments(deadlinesResponse);

    const referencesPerType = normalizeObjectOfReferences({
      document,
      meeting,
      deadline,
    });

    const nextAction = isNotification
      ? actions.fetchNotificationReferencesSucceeded
      : actions.addGeneralNotificationInformation;

    yield put(nextAction({ referencesPerType }));
  } catch (error) {
    console.error(`fetch deadline references failed - ${error}`);
  }
}

function* handlePinNotification(action) {
  try {
    const notificationId = action.payload;

    const response = yield call(pinNotification, notificationId);

    if (isRequestSuccessful(response)) {
      yield put(actions.pinNotificationSucceeded(notificationId));
    }
  } catch (error) {
    console.error(error);
  }
}

function* handleUnpinNotification(action) {
  try {
    const notificationId = action.payload;

    const response = yield call(unpinNotification, notificationId);

    if (isRequestSuccessful(response)) {
      yield put(actions.unpinNotificationSucceeded(notificationId));
    }
  } catch (error) {
    console.error(error);
  }
}

function* handleMarkNotificationAsSeen(notification) {
  try {
    const response = yield call(markNotificationsAsSeen, [notification]);
    if (isRequestSuccessful(response)) {
      const committeeId = yield select(selectedNationalCommittee);
      yield put(actions.markNotificationsAsSeenSucceeded(notification));
      yield put(decreaseNumberOfUnseenNotifications(committeeId));
    }
  } catch (error) {
    console.error(error);
  }
}

function* handleMarkNotificationsAsSeen(action) {
  const notifications = action.payload;

  for (let item of notifications) {
    yield fork(handleMarkNotificationAsSeen, item);
  }
}

function* handleMarkAllNotificationsAsSeen() {
  try {
    const committeeId = yield select(selectedNationalCommittee);

    var response = yield call(markAllNotificationAsSeen, committeeId);

    //The operation is to fast, and the loader animation just blinks.
    //adding a small delay will make it feel better
    yield delay(500);
    if (isRequestSuccessful(response)) {
      yield put(actions.markAllNotificationsAsSeenSucceeded(committeeId));
    } else {
      yield put(actions.markAllNotificationsAsSeenFailed());
      console.error("error while marking all notification as seen");
    }
  } catch (error) {
    console.error(error);
    yield put(actions.markAllNotificationsAsSeenFailed());
  }
}

function* fetchDocuments(documents) {
  const documentsResponse = yield all(
    documents.map((document) => call(getDocumentUrl, document.uri)),
  );
  return documentFilters
    .filterEmptyDocuments(documentsResponse)
    .map((d) => d.data);
}

function* handlePullNextNotificationsPage() {
  try {
    const committeeId = yield select(selectedNationalCommittee);
    const filters = yield select(selectors.getNotificationsFilters);
    const repository = yield select(selectors.getNotificationRepository);

    const pagination = yield select(selectors.getPagination);
    const pageToLoad = pagination.pagesLoaded + 1;

    const response = yield repository.pullUserNotificationsPage(
      committeeId,
      filters,
      filters.onlyUnseen ? 1 : pageToLoad,
    );

    const pagesToLoad = Math.ceil(
      response.data.total / response.data.page_size,
    );

    let hasMoreToLoad =
      response.data.results.length < response.data.total &&
      response.data.results &&
      response.data.results.length > 0 &&
      response.data.results.length + pageToLoad * response.data.page_size <
        response.data.total;

    if (!isRequestSuccessful(response)) {
      yield put(actions.pullNotificationsPageFailed());
      console.error(response);
      console.error(
        `Failed to pull notifications page ${response.data.message}`,
      );
      return;
    }

    if (filters.onlyUnseen && response.data.results.length > 0) {
      yield call(
        markNotificationsAsSeen,
        response.data.results.map((n) => n.uri),
      );
    }

    let exportedActions = [];
    const onActionExport = (action) => {
      exportedActions = [...exportedActions, action];
    };

    const details = yield all(
      response.data.results.map((r) =>
        repository.pullNotificationDetails(r, onActionExport),
      ),
    );

    let successfulDetails = details.filter((d) => !!d && !d.failed);
    const failedDetails = details.filter((d) => !!d && d.failed);

    yield all(exportedActions.map((a) => put(a)));

    yield put(
      actions.pullNotificationsPageSucceeded({
        ...response.data,
        pagesToLoad,
        hasMoreToLoad: hasMoreToLoad,
      }),
    );

    const notifications = yield select(selectors.notifications);

    /*
      When notification.type === notificationType.internalDocumentSummation, 
      fetch document details to show it to the notifications.
    */
    const successfulDetailsWithFetchedDocuments = yield all(
      successfulDetails.map(function* (d) {
        if (
          notifications.data[d.id]?.type ===
          notificationType.internalDocumentSummation
        ) {
          d.documents = yield call(fetchDocuments, d.documents);
        }
        return d;
      }),
    );

    yield all(
      successfulDetailsWithFetchedDocuments.map((d) =>
        put(actions.getNotificationDetailsSucceeded(d)),
      ),
    );
    yield all(
      failedDetails.map((d) => put(actions.getNotificationDetailsFailed(d))),
    );
  } catch (error) {
    console.error(error);
    console.error(`Failed to pull notifications page ${error}`);
  }
}

function* initNotifications() {
  try {
    const query = new URLSearchParams(history.location.search);
    yield put(actions.pullNextNotificationsPage());
  } catch (error) {
    console.error(`init notifications filters failed - ${error}`);
  }
}

export function* handleSendInstantEmail(action) {
  const { type, param, disableToast } = action.payload;

  const url =
    type === INSTANT_EMAIL_PARAM_TYPE.NOTIFICATION_ID
      ? sendInstantEmailWithNotificationIdUrl
      : sendInstantEmailWithCommitteeIdUrl;
  const response = yield call(url, param);

  let notifyToasterContent = {
    title: "Email failed",
    message: i18next.t("notifyToaster.error", { ns: "common" }),
    type: NOTIFY_TOASTER_TYPE.ERROR,
  };

  if (response.status === 200) {
    yield put(actions.sendInstantEmailSucceeded(param));

    notifyToasterContent = {
      title: "Email sent",
      message: i18next.t("notifyToaster.success", { ns: "common" }),
      type: NOTIFY_TOASTER_TYPE.SUCCESS,
    };
  }

  if (disableToast) return;

  yield put(
    notifyToasterContentActions.setNotifyToasterContent(notifyToasterContent),
  );
}

export function* handleVoteNotificationPoll(action) {
  const { pollOptionId } = action.payload;
  const userId = yield select(userSelectors.userId);
  const notificationUri = yield select(selectors.getNotificationUri);

  const response = yield call(
    voteNotificationPollUrl,
    notificationUri,
    userId,
    pollOptionId,
  );

  if (response.status === 200) {
    yield call(handleFetchNotification, { payload: notificationUri });
    // yield put(actions.voteNotificationPollSucceeded(response.data));
  } else {
    // yield put(
    //   actions.voteNotificationPollFailed("Failed to vote notification poll"),
    // );
  }
}

export function* handleDeleteVoteNotificationPoll(action) {
  const { pollVoteId } = action.payload;
  const notificationUri = yield select(selectors.getNotificationUri);

  const response = yield call(deleteVoteNotificationPollUrl, pollVoteId);

  if (response.status === 200) {
    yield call(handleFetchNotification, { payload: notificationUri });
    // yield put(actions.deleteVoteNotificationPollSucceeded(response.data));
  } else {
    // yield put(
    //   actions.deleteVoteNotificationPollFailed("Failed to delete vote notification poll"),
    // );
  }
}

export function* handleUpdatePoll(action) {
  const response = yield call(updatePollUrl, action.payload);

  if (response.status === 200) {
    yield put(actions.updatePollSucceeded(response.data));
  } else {
    yield put(actions.updatePollFailed("Failed to update poll"));
  }
}

export default function* notificationsSaga() {
  yield takeLatest(actions.fetchNotification, handleFetchNotification);
  yield takeLatest(actions.initNotifications, initNotifications);
  yield takeLatest(
    actions.fetchNotificationReferences,
    handleFetchNotificationReferences,
  );

  yield takeLeading(
    actions.pullNextNotificationsPage,
    handlePullNextNotificationsPage,
  );

  yield takeLatest(actions.updateNotification, handleUpdateNotification);
  yield takeLatest(actions.deleteNotification, handleDeletingNotification);
  yield takeLatest(actions.createNotification, handleCreateNotification);
  yield takeLatest(
    actions.initNotificationFormWithGeneralInfo,
    handleInitNotificationForm,
  );
  yield takeLatest(
    actions.createNotificationFromDocument,
    handleCreateNotificationFromDocument,
  );
  yield takeLatest(
    actions.createNotificationFromMeeting,
    handleCreateNotificationFromMeeting,
  );
  yield takeLatest(
    actions.createNotificationFromDeadline,
    handleCreateNotificationFromDeadline,
  );
  yield takeLatest(
    actions.fetchUpdatedNotificationDocument,
    handleFetchUpdatedNotificationDocument,
  );
  yield takeEvery(
    actions.removeReferenceFromNotification,
    handleRemoveReferenceFromNotification,
  );
  yield takeEvery(
    actions.downloadNotificationDocuments,
    handleDownloadNotificationDocuments,
  );

  yield takeEvery(actions.pinNotification, handlePinNotification);
  yield takeEvery(actions.unpinNotification, handleUnpinNotification);
  yield takeEvery(
    actions.markNotificationsAsSeen,
    handleMarkNotificationsAsSeen,
  );
  yield takeEvery(
    actions.markAllNotificationsAsSeen,
    handleMarkAllNotificationsAsSeen,
  );
  yield takeEvery(actions.sendInstantEmail, handleSendInstantEmail);
  yield takeEvery(actions.voteNotificationPoll, handleVoteNotificationPoll);
  yield takeEvery(
    actions.deleteVoteNotificationPoll,
    handleDeleteVoteNotificationPoll,
  );
  yield takeEvery(actions.updatePoll, handleUpdatePoll);
}
