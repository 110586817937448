import { takeEvery, call, put, take, select } from "redux-saga/effects";
import { v4 as uuidv4 } from "uuid";
import UrlPattern from "url-pattern";

import * as actions from "../state/app/actions";
import { fetchUserData, fetchUserDetails } from "./user";
import { fetchCommitteeMembers } from "./members";
import { fetchCommittees } from "./committees";
import { initDocuments } from "./document";

import * as valuesActions from "../state/values/actions";
import { getAppLanguage } from "../state/user/selectors";
import { applicationUserHintState } from "../state/app/selectors";
import { loadLocalObject, storeLocalObject } from "../services/local-storage";
import AnalyticsTracking from "../services/analytics-tracking/analytics-tracking";
import initializeTranslations from "../utils/translations";
import { dialogSizes, allNcsId } from "../utils/constants";
import history from "../utils/history";
import { transformCommitteeName } from "../utils/transform";

import * as ncActions from "../state/national-committees/actions";
import * as valueActions from "../state/values/actions";
import * as userActions from "../state/user/actions";
import * as memberActions from "../state/members/actions";

import * as ncSelectors from "../state/national-committees/selectors";
import DocumentSummaryModal from "../components/pages/documents-summary/DocumentSummaryModal";

function* handleInitializingApplication() {
  yield call(fetchUserDetails);
  yield call(fetchCommittees);
  yield call(fetchUserData);

  const appLang = yield select(getAppLanguage);
  initializeTranslations(appLang);
  yield call(AnalyticsTracking.setUserLanguage, appLang);

  yield call(fetchCommitteeMembers);

  yield put(valuesActions.fetchAllValues());
  yield take(valuesActions.FETCH_VALUES_FINISHED);

  yield put(actions.applicationInvokeGlobalDialog());
  yield put(actions.applicationUpdateReadonlyMode());

  yield put(actions.applicationInitialized());
}

function* handleChangeNationalCommittee({ payload }) {
  const committee = payload;
  const currentNaionalCommittee = transformCommitteeName(
    yield select(ncSelectors.selectedNationalCommittee),
  );
  const location = new URL(window.location.href).pathname;
  const segments = location.split("/");
  const transformedCommitteeName = transformCommitteeName(committee);

  function getNewUrl() {
    if (segments.length <= 1) return `/${transformedCommitteeName}`;

    //e.g. is on profile page
    if (segments[1] !== currentNaionalCommittee)
      return `/${transformedCommitteeName}`;

    return `/${transformedCommitteeName}/${segments[2]}`;
  }

  history.push(getNewUrl());

  yield put(ncActions.setSelectedNationalCommittee(committee));
  yield put(valueActions.clearMeetingsFilterValues());
  yield call(initDocuments);
  yield put(userActions.fetchUserDataRequest());
  yield put(valueActions.fetchAllValues());
  yield put(memberActions.getCommitteeMembers());
  yield put(actions.applicationUpdateReadonlyMode());
}

function* handleApplicationTriggerUserHint(action) {
  const { hintId } = action.payload;
  const hints = loadLocalObject("udvalg_hints");
  if (!hints[hintId] || !hints[hintId].disabled) {
    yield put(
      actions.applicationSetUserHintState({
        messageToShow: hintId,
      }),
    );
  }
}

function* handleApplicationCloseUserHint(action) {
  const hintState = yield select(applicationUserHintState);
  if (hintState && hintState.messageToShow) {
    yield put(
      actions.applicationSetUserHintState({
        messageToShow: null,
      }),
    );

    if (action.payload && action.payload.disable) {
      const hints = loadLocalObject("udvalg_hints");
      hints[hintState.messageToShow] = { disabled: true };
      storeLocalObject("udvalg_hints", hints);
    }
  }
}

function* handleApplicationInvokeGlobalDialog() {
  if (!window.location.hash) return;

  try {
    const config = JSON.parse(atob(window.location.hash.substr(1)));

    if (config.action !== "open_dialog") return;

    const dialogConfig = {
      id: uuidv4(),
      component: DocumentSummaryModal,
      modalSettings: {
        allowEscape: true,
        sizeType: dialogSizes.wide,
        closeButton: true,
      },
      props: config.params,
    };

    yield put(actions.applicationPushGlobalDialog(dialogConfig));
  } catch (err) {
    console.error(err);
  }
}

function* handleApplicationUpdateReadonlyMode() {
  const parser = new UrlPattern("/:committeeId(/:segment*)");
  const result = parser.match(window.location.pathname);
  yield put(
    actions.applicationSetReadonlyMode(
      result.committeeId.toLowerCase() === allNcsId,
    ),
  );
}

export default function* appSaga() {
  yield takeEvery(actions.initializeApplication, handleInitializingApplication);
  yield takeEvery(
    actions.changeNationalCommittee,
    handleChangeNationalCommittee,
  );
  yield takeEvery(
    actions.applicationTriggerUserHint,
    handleApplicationTriggerUserHint,
  );
  yield takeEvery(
    actions.applicationCloseUserHint,
    handleApplicationCloseUserHint,
  );
  yield takeEvery(
    actions.applicationInvokeGlobalDialog,
    handleApplicationInvokeGlobalDialog,
  );
  yield takeEvery(
    actions.applicationUpdateReadonlyMode,
    handleApplicationUpdateReadonlyMode,
  );
}
