import { createAction } from "redux-actions";

const INIT_DEADLINES = "DEADLINES/init_deadlines";

export const GET_DEADLINES = "DEADLINES/get_deadlines";
export const GET_DEADLINES_SUCCEEDED = "DEADLINES/get_deadlines_succeeded";
export const GET_DEADLINES_FAILED = "DEADLINES/get_deadlines_failed";
export const SET_DEADLINE = "DEADLINES/set_deadline";
export const SET_SELECTED_URI = "DEADLINES/SET_SELECTED_URI";
export const DOWNLOAD_DEADLINE_DOCUMENTS =
  "DEADLINES/download_deadline_documents";

const FETCH_DEADLINE = "DEADLINES/fetch_deadline";
const FETCH_DEADLINE_SUCCEEDED = "DEADLINES/fetch_deadline_succeeded";
const FETCH_DEADLINE_FAILED = "DEADLINES/fetch_deadline_failed";

const FETCH_UPDATED_DEADLINE_REFERENCE =
  "DEADLINE_FORM/fetch_updated_reference";
const FETCH_UPDATED_DEADLINE_REFERENCE_SUCCEEDED =
  "DEADLINE_FORM/fetch_updated_reference_succeeded";
const FETCH_UPDATED_DEADLINE_REFERENCE_FAILED =
  "DEADLINE_FORM/fetch_updated_reference_failed";

const FETCH_CURRENT_DEADLINE_REFERENCES =
  "DEADLINES/fetch_current_deadline_references";
const FETCH_CURRENT_DEADLINE_REFERENCES_SUCCEEDED =
  "DEADLINES/fetch_current_deadline_references_succeeded";
const FETCH_CURRENT_DEADLINE_REFERENCES_FAILED =
  "DEADLINES/fetch_current_deadline_references_failed";

const REMOVE_DEADLINE_REFERENCE = "DEADLINE_FORM/remove_deadline_document";

const DELETE_DEADLINE = "DEADLINES/delete_deadline";
const DELETE_DEADLINE_SUCCEEDED = "DEADLINES/delete_deadline_succeeded";
const DELETE_DEADLINE_FAILED = "DEADLINES/delete_deadline_failed";

const CREATE_DEADLINE = "DEADLINES/create_deadline";
const CREATE_DEADLINE_SUCCEEDED = "DEADLINES/create_deadline_succeeded";
const CREATE_DEADLINE_FAILED = "DEADLINES/create_deadline_failed";

const UPDATE_DEADLINE = "DEADLINES/update_deadline";
const UPDATE_DEADLINE_SUCCEEDED = "DEADLINES/update_deadline_succeeded";
const UPDATE_DEADLINE_FAILED = "DEADLINES/update_deadline_failed";

export const CREATE_DEADLINE_FROM_REFERENCE = "DEADLINE/create_from_reference";
const SET_FORM_SELECTED_DEADLINE_REFERENCE =
  "DEADLINE_FORM/set_form_selected_references";
const SET_FORM_SELECTED_DEADLINE_REFERENCE_MEETINGS =
  "DEADLINE_FORM/set_form_selected_references_meetings";
const SET_FORM_SELECTED_DEADLINE_REFERENCE_DOCUMENTS =
  "DEADLINE_FORM/set_form_selected_references_documents";
const ADD_FORM_DEADLINE_REFERENCE = "DEADLINE_FORM/add_form_references";
const ADD_GENERAL_DEADLINE_INFORMATION = "DEADLINE_FORM/add_general_info";

const ADD_DEADLINE_REFERENCE = "DEADLINES/add_deadline_references";
const SET_DEADLINE_REFERENCES = "DEADLINES/set_deadline_references";
const UPDATE_DEADLINE_REFERENCE_INFO =
  "DEADLINES/update_deadline_reference_info";

const RESET_DEADLINES_FILTERS = "FILTER/reset_deadlines_filters";

const PULL_DEADLINES_PAGE_REQUEST = "DEADLINES/pull_deadlines_page_request";
const PULL_DEADLINES_PAGE_SUCCEEDED = "DEADLINES/pull_deadlines_page_success";
const PULL_DEADLINES_PAGE_FAILED = "DEADLINES/pull_deadlines_page_failed";

const SET_DEADLINES_FILTER_VALUE = "FILTER/set_deadlines_filter_values";

const GET_DEADLINE_COMMITTEES = "DEADLINES/get_deadline_committees";

const FETCH_DEADLINES_REFERENCES = "DEADLINES/fetch_deadline_references";
const FETCH_DEADLINES_REFERENCES_SUCCEEDED =
  "DEADLINES/fetch_deadline_references_succeeded";
const FETCH_DEADLINES_REFERENCES_FAILED =
  "DEADLINES/fetch_deadline_references_failed";

const CLEAR_DEADLINE = "DEADLINES/clear_deadline";

const INIT_DEADLINE_FORM_WITH_GENERAL_INFO_DATA =
  "DEADLINE_FORM/init_with_general_info_data";
const CLEAR_DEADLINE_FORM = "DEADLINE_FORM/clear";

const INIT_AGG_DEADLINES = "DEADLINES/init_agg_deadlines";
const PULL_AGG_DEADLINES_PAGE = "DEADLINES/pull_agg_deadlines_page";

const UPDATE_IS_REGISTERED = "DEADLINES/update_is_registered";
const UPDATE_IS_REGISTERED_SUCCEEDED =
  "DEADLINES/update_is_registered_succeeded";
const UPDATE_IS_REGISTERED_FAILED = "DEADLINES/update_is_registered_failed";

export const initDeadlines = createAction(INIT_DEADLINES);

export const fetchDeadline = createAction(FETCH_DEADLINE);
export const fetchDeadlineSucceeded = createAction(FETCH_DEADLINE_SUCCEEDED);
export const fetchDeadlineFailed = createAction(FETCH_DEADLINE_FAILED);

export const fetchUpdatedDeadlineReference = createAction(
  FETCH_UPDATED_DEADLINE_REFERENCE,
);
export const fetchUpdatedDeadlineReferenceSucceeded = createAction(
  FETCH_UPDATED_DEADLINE_REFERENCE_SUCCEEDED,
);
export const fetchUpdatedDeadlineReferenceFailed = createAction(
  FETCH_UPDATED_DEADLINE_REFERENCE_FAILED,
);

export const fetchCurrentDeadlineReferences = createAction(
  FETCH_CURRENT_DEADLINE_REFERENCES,
);
export const fetchCurrentDeadlineReferencesSucceeded = createAction(
  FETCH_CURRENT_DEADLINE_REFERENCES_SUCCEEDED,
);
export const fetchCurrentDeadlineReferencesFailed = createAction(
  FETCH_CURRENT_DEADLINE_REFERENCES_FAILED,
);

export const setDeadlineData = createAction(SET_DEADLINE);
export const setSelectedUri = createAction(SET_SELECTED_URI);

export const downloadDeadlineDocuments = createAction(
  DOWNLOAD_DEADLINE_DOCUMENTS,
);
export const removeReferenceFromDeadline = createAction(
  REMOVE_DEADLINE_REFERENCE,
);

export const deleteDeadline = createAction(DELETE_DEADLINE);
export const deleteDeadlineSucceeded = createAction(DELETE_DEADLINE_SUCCEEDED);
export const deleteDeadlineFailed = createAction(DELETE_DEADLINE_FAILED);

export const createDeadline = createAction(CREATE_DEADLINE);
export const createDeadlineSucceeded = createAction(CREATE_DEADLINE_SUCCEEDED);
export const createDeadlineFailed = createAction(CREATE_DEADLINE_FAILED);

export const createDeadlineFromReference = createAction(
  CREATE_DEADLINE_FROM_REFERENCE,
);
export const setFormSelectedDeadlineReferences = createAction(
  SET_FORM_SELECTED_DEADLINE_REFERENCE,
);
export const setFormSelectedDeadlineReferencesMeetings = createAction(
  SET_FORM_SELECTED_DEADLINE_REFERENCE_MEETINGS,
);
export const setFormSelectedDeadlineReferencesDocuments = createAction(
  SET_FORM_SELECTED_DEADLINE_REFERENCE_DOCUMENTS,
);
export const addFormDeadlineReferences = createAction(
  ADD_FORM_DEADLINE_REFERENCE,
);
export const addGeneralDeadlineInformation = createAction(
  ADD_GENERAL_DEADLINE_INFORMATION,
);

export const addDeadlineReferences = createAction(ADD_DEADLINE_REFERENCE);
export const setDeadlineReferences = createAction(SET_DEADLINE_REFERENCES);
export const updateDeadlineReferenceInfo = createAction(
  UPDATE_DEADLINE_REFERENCE_INFO,
);

export const resetDeadlinesFilters = createAction(RESET_DEADLINES_FILTERS);

export const setDeadlinesFilterValue = createAction(SET_DEADLINES_FILTER_VALUE);

export const pullDeadlinesPageRequest = createAction(
  PULL_DEADLINES_PAGE_REQUEST,
);
export const pullDeadlinesPageSucceeded = createAction(
  PULL_DEADLINES_PAGE_SUCCEEDED,
);
export const pullDeadlinesPageFailed = createAction(PULL_DEADLINES_PAGE_FAILED);

export const fetchDeadlineReferences = createAction(FETCH_DEADLINES_REFERENCES);
export const fetchDeadlineReferencesSucceeded = createAction(
  FETCH_DEADLINES_REFERENCES_SUCCEEDED,
);
export const fetchDeadlineReferencesFailed = createAction(
  FETCH_DEADLINES_REFERENCES_FAILED,
);

export const clearDeadline = createAction(CLEAR_DEADLINE);

export const initDeadlineFormWithGeneralInfo = createAction(
  INIT_DEADLINE_FORM_WITH_GENERAL_INFO_DATA,
);
export const clearDeadlineForm = createAction(CLEAR_DEADLINE_FORM);

export const getDeadlineCommittees = createAction(GET_DEADLINE_COMMITTEES);

export const updateDeadline = createAction(UPDATE_DEADLINE);
export const updateDeadlineSucceeded = createAction(UPDATE_DEADLINE_SUCCEEDED);
export const updateDeadlineFailed = createAction(UPDATE_DEADLINE_FAILED);

export const initAggDeadlines = createAction(INIT_AGG_DEADLINES);
export const pullAggDeadlinesPage = createAction(PULL_AGG_DEADLINES_PAGE);

export const updateIsRegistered = createAction(UPDATE_IS_REGISTERED);
export const updateIsRegisteredSucceeded = createAction(
  UPDATE_IS_REGISTERED_SUCCEEDED,
);
export const updateIsRegisteredFailed = createAction(
  UPDATE_IS_REGISTERED_FAILED,
);
