import { createAction } from "redux-actions";

const INITIALIZE_APP = "APP/initialize_application";
const APP_INITIALIZED = "APP/application_initialized";

const CHANGE_NATIONAL_COMMITTEE = "APP/change_national_committee";

const APP_TRIGGER_USER_HINT = "APP/user_hint_trigger";
const APP_CLOSE_USER_HIN = "APP/user_hint_close";
const APP_SET_USER_HIN_STATE = "APP/set_user_hint_state";

const APP_INVOKE_GLOBAL_DIALOG = "APP/invoke_global_dialog";
const APP_PUSH_GLOBAL_DIALOG = "APP/push_global_dialog";
const APP_CLOSE_GLOBAL_DIALOG = "APP/close_global_dialog";

const APP_UPDATE_READONLY_MODE = "APP/update_read_only_mode";
const APP_SET_READONLY_MODE = "APP/set_read_only_mode";

export const initializeApplication = createAction(INITIALIZE_APP);
export const applicationInitialized = createAction(APP_INITIALIZED);

export const changeNationalCommittee = createAction(CHANGE_NATIONAL_COMMITTEE);

export const applicationTriggerUserHint = createAction(APP_TRIGGER_USER_HINT);
export const applicationCloseUserHint = createAction(APP_CLOSE_USER_HIN);
export const applicationSetUserHintState = createAction(APP_SET_USER_HIN_STATE);

export const applicationInvokeGlobalDialog = createAction(
  APP_INVOKE_GLOBAL_DIALOG,
);
export const applicationPushGlobalDialog = createAction(APP_PUSH_GLOBAL_DIALOG);
export const applicationCloseGlobalDialog = createAction(
  APP_CLOSE_GLOBAL_DIALOG,
);

export const applicationUpdateReadonlyMode = createAction(
  APP_UPDATE_READONLY_MODE,
);
export const applicationSetReadonlyMode = createAction(APP_SET_READONLY_MODE);
