import { api } from "../api";
import { getProxyUrl } from "../utils";
import { transformCommitteeName } from "../../utils/transform";

export function getUserCommittees() {
  return api.get("/api/kundeportal/users/current");
}

export function getAssociatedCommittees(committee) {
  const proxyUrl = getProxyUrl(
    `/Committees/${transformCommitteeName(committee)}`,
  );
  return api.get(proxyUrl);
}

export function saveLastCommittee(committee) {
  return api.post(
    "/api/kundeportal/user/current/lastCommittee",
    JSON.stringify(transformCommitteeName(committee)),
  );
}
