import { createSelector } from "reselect";
import { getNotifications } from "../../../../../state/notifications/selectors";
import { selectedNationalCommittee } from "../../../../../state/national-committees/selectors";
import { userDetails } from "../../../../../state/user/selectors";

export const notificationsExist = createSelector(
  [getNotifications],
  (notifications) => notifications.length > 0,
);

export const isViewingOwnCommittee = createSelector(
  [userDetails, selectedNationalCommittee],
  (userDetails, selectedNationalCommittee) =>
    Object.keys(userDetails.role).includes(selectedNationalCommittee),
);
