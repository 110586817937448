import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { voteLabelMap } from "../../../utils/constants";

import ModalWrapper from "../modal-wrapper/ModalWrapper";
import WithPrivileges from "../with-privileges/WithPrivileges";
import OverrideOfficialVote from "../../pages/deadlines/components/modal-content/OverrideOfficialVote";

import "./OfficialVote.scss";

class OfficialVote extends Component {
  state = {
    showOverrideOfficialVoteModal: false,
  };

  getVoteLabel() {
    return voteLabelMap[this.props.vote];
  }

  onOverrideOfficialVoteModal = (e) => {
    e.preventDefault();
    this.setState({ showOverrideOfficialVoteModal: true });
  };

  render() {
    const { t, vote, deadlineStage } = this.props;
    return (
      <div className="official-vote">
        <div className="official-vote__bar">
          <span className="official-vote__label">
            {t("deadlines.details.officialVote.mainLabel", {
              vote: t(this.getVoteLabel()),
            })}
          </span>
          <WithPrivileges>
            <a
              href="#"
              className="official-vote__link  button button--link button--no-border"
              onClick={this.onOverrideOfficialVoteModal}
            >
              {t("deadlines.details.officialVote.overrideLabel")}
            </a>
          </WithPrivileges>
        </div>
        <WithPrivileges>
          <span className="official-vote__note">
            {t("deadlines.details.officialVote.note")}
          </span>
        </WithPrivileges>

        <WithPrivileges>
          <ModalWrapper
            isOpen={this.state.showOverrideOfficialVoteModal}
            requestCloseHandler={() =>
              this.setState({ showOverrideOfficialVoteModal: false })
            }
            disableSlide
          >
            <OverrideOfficialVote
              onClose={() =>
                this.setState({ showOverrideOfficialVoteModal: false })
              }
              vote={vote}
              deadlineStage={deadlineStage}
              onOverrideOfficialVote={this.props.onOverrideOfficialVote}
            />
          </ModalWrapper>
        </WithPrivileges>
      </div>
    );
  }
}

export default withTranslation("common")(OfficialVote);
