import { createSelector } from "reselect";
import { selectedNationalCommittee } from "../national-committees/selectors";

const documents = (state) => state.documents.data.results;
export const getPagination = (state) => state.documents.data.pagination;
export const getSelectedFilters = (state) => state.documents.filters;
export const getModalDetails = (state) => state.documents.modal;
export const getModalData = (state) => state.documents.modal.content;

export const getCurrentlyDownloaded = (state) =>
  state.documents.filesCurrentlyDownloaded;

export const getTotalNumber = (state) =>
  isNaN(parseInt(state.documents.data.pagination.total))
    ? 0
    : parseInt(state.documents.data.pagination.total);

export const isProcessingDocuments = (state) => state.documents.isFetching;

export const getDocuments = createSelector(
  [documents, selectedNationalCommittee],
  (documents, selectedNationalCommittee) => {
    if (documents && selectedNationalCommittee) {
      const nationalComitteeDocuments = documents[selectedNationalCommittee];
      return nationalComitteeDocuments ? nationalComitteeDocuments : [];
    }
    return [];
  },
);

export const isDownloadingFile = (state, downloadId) =>
  getCurrentlyDownloaded(state).includes(downloadId);
export const getSortingType = (state) => state.documents.sortingType;

export const hasMoreDocumentsToLoad = createSelector(
  [getPagination],
  (pagination) =>
    pagination.pagesLoaded < pagination.pagesToLoad ||
    pagination.pagesLoaded === 0,
);
