import { hotjar } from "react-hotjar";

const HJ_ID = 2971416;
const HJ_SV = 6;

const initializeHotjarTracking = () => {
  hotjar.initialize(HJ_ID, HJ_SV);
};

export default initializeHotjarTracking;
