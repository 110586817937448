import i18n from "i18next";
import buildFormatter from "react-timeago/lib/formatters/buildFormatter";
import enStrings from "react-timeago/lib/language-strings/en";
import daStrings from "react-timeago/lib/language-strings/da";

const formatters = {
  da: buildFormatter(daStrings),
  en: buildFormatter(enStrings),
};

export const getTimeAgoFormatter = () => {
  const currentLanguage = i18n.language;
  if (currentLanguage in formatters) {
    return formatters[currentLanguage];
  }
  return formatters["en"];
};
