import React, { Component } from "react";
import { Helmet } from "react-helmet";

export default class CookieDeclaration extends Component {
  componentDidMount() {
    if (process.env.REACT_APP_CBID) {
      const script = document.createElement("script");
      script.src = `https://consent.cookiebot.com/${process.env.REACT_APP_CBID}/cd.js?culture=en`;
      script.async = true;

      document.querySelector("#cb_declaration").appendChild(script);
    }
  }
  render() {
    return <div id="cb_declaration" style={{ margin: "50px" }}></div>;
  }
}
