import React, { Component } from "react";
import "./ActionBox.scss";
import { withTranslation } from "react-i18next";

class ActionBox extends Component {
  render() {
    const { children, t } = this.props;

    return (
      <div className="action-box">
        <button className="toggler__button button button--link">
          <span
            className="icon icon--large icon-3dots"
            aria-hidden="true"
          ></span>
          <span className="visuallyhidden">
            {t("documents.action.seeActions")}
          </span>
        </button>
        <div className="toggler__content action-box__panel panel panel--border">
          <ul className="list actions-list">{children}</ul>
        </div>
      </div>
    );
  }
}

export default withTranslation("common")(ActionBox);
