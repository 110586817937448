import { createSelector } from "reselect";
import { getStore } from "../../utils/state";
import { TIMEFRAME } from "../../api/utils";

const meetings = (state) => state.meetings;

const meetingsOrderShowPast = (a, b) => (a.fromDate > b.fromDate ? -1 : 1);
const meetingsOrderShowUpcoming = (a, b) => (a.fromDate > b.fromDate ? 1 : -1);

const meetingsOrder = (timeframe) => {
  if (timeframe === TIMEFRAME.PAST) return meetingsOrderShowPast;
  return meetingsOrderShowUpcoming;
};

export const getRawMeetings = createSelector([meetings], (meetings) => {
  return Object.values(meetings.data);
});

export const getMeetings = createSelector([meetings], (meetings) => {
  const locale = getStore().getState().user.data.kundeportal.locale;
  return Object.values(meetings.data)
    .sort(meetingsOrder(meetings.filters.timeframe))
    .reduce((previous, current) => {
      const date = new Date(current.fromDate)
        .toLocaleDateString(locale, { month: "long", year: "numeric" })
        .toUpperCase();
      previous[date]
        ? previous[date].push(current)
        : (previous[date] = [current]);

      return previous;
    }, {});
});

export const getMeeting = createSelector(
  [meetings],
  (meetings) => meetings.meeting,
);

export const isProcessingMeeting = (state) =>
  state.meetings.isProcessingRequest;
export const isProcessingMeetings = (state) =>
  state.meetings.isProcessingMeetings;
export const isProcessedMeetings = (state) =>
  state.meetings.isProcessedMeetings;
export const isProcessingDocuments = (state) =>
  state.meetings.isProcessingDocuments;

export const getNewMeetingFormData = (state) => state.meetings.form;

export const getMeetingDocumentsUri = createSelector([getMeeting], (meeting) =>
  meeting.referencesRawResponsePerType.document.map((doc) => doc.uri),
);

export const getMeetingsFilters = createSelector(
  [meetings],
  (meetings) => meetings.filters,
);

export const getMeetingDocuments = createSelector(
  [getMeeting],
  (meeting) => meeting.references,
);

export const hasMoreMeetingsToLoad = createSelector(
  [meetings],
  (meetings) => meetings.pagination.hasMoreToLoad,
);

export const getPagination = (state) => state.meetings.pagination;
