import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import SelectFilter from "../../../components/common/select/SelectFilter";
import { isConsultant } from "./../../../state/user/selectors";
import classNames from "classnames";

import "./Filters.scss";

class Filters extends Component {
  state = {
    isFiltered: this.props.isFiltered,
  };

  componentDidMount() {
    if (this.props.init) {
      this.props.init();
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.filters.documentsAreFiltered !==
      this.props.filters.documentsAreFiltered
    ) {
      this.setState({ isFiltered: this.props.filters.documentsAreFiltered });
    }
  }

  submitHandler = (e) => {
    e.preventDefault();
    this.setState({ isFiltered: true });
    this.props.addFiltersToUrl();
  };

  resetFilters = (e) => {
    e.preventDefault();
    this.setState({ isFiltered: false });
    this.props.resetFilters({ changeFilter: true });
  };

  setDocumentTitle = (event) => {
    this.props.selectFilterValue({
      title: event.target.value,
      changeFilter: true,
    });
  };

  committeeSelected = (committee) => {
    this.props.selectFilterValue({
      committee,
      changeFilter: true,
    });
  };

  selectDocumentAction = (actionId) => {
    this.props.selectFilterValue({
      action: actionId,
      changeFilter: true,
    });
  };

  selectDocumentType = (typeId) => {
    this.props.selectFilterValue({
      type: typeId,
      changeFilter: true,
    });
  };

  handleDocumentSearchKeyDown = (e) => {
    if (e.key === "Enter") {
      this.submitHandler(e);
    }
  };

  render() {
    const { t, isConsultant, disabled, filters, selectedFilters, isRefernece } =
      this.props;
    const { isFiltered } = this.state;

    return (
      <div
        className={classNames("group group--underlined ", {
          "group--first": !isConsultant,
          "group--disabled": disabled,
        })}
      >
        <div className="group__flex filters u-no-padding">
          <div className="group__flex-content">
            <label htmlFor="documentSearch" className="visuallyhidden">
              {t("filter.placeholder.searchTitle")}
            </label>
            <input
              type="text"
              className="input"
              name="documentSearch"
              id="documentSearch"
              placeholder={t("filter.placeholder.searchTitle")}
              value={selectedFilters.title}
              onChange={this.setDocumentTitle}
              onKeyDown={this.handleDocumentSearchKeyDown}
            />
            {filters.actions && filters.types && filters.committees && (
              <div className="group__flex">
                <SelectFilter
                  items={filters.committees}
                  placeholder={t("filter.placeholder.allCommittees")}
                  label={t("filter.label.chooseCommittee")}
                  labelOnlyForScreenReaders={true}
                  selectedItem={selectedFilters.committee}
                  onChange={this.committeeSelected}
                />
                <SelectFilter
                  items={filters.actions}
                  placeholder={t("filter.placeholder.allActions")}
                  label={t("filter.label.chooseAction")}
                  labelOnlyForScreenReaders={true}
                  selectedItem={selectedFilters.action}
                  onChange={this.selectDocumentAction}
                />
                <SelectFilter
                  items={filters.avaiableTypes}
                  placeholder={t("filter.placeholder.allTypes")}
                  label={t("filter.label.chooseType")}
                  labelOnlyForScreenReaders={true}
                  selectedItem={selectedFilters.type}
                  onChange={this.selectDocumentType}
                />
              </div>
            )}
          </div>
          <button
            onClick={this.submitHandler}
            className={`button button--${isRefernece ? "secondary" : "primary"} filters__button`}
          >
            {t("filter.button.filter")}
          </button>
        </div>

        {isFiltered && (
          <button
            className="button button--link documents__buttons-reset"
            onClick={this.resetFilters}
          >
            {t("filter.button.reset")}
          </button>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isConsultant: isConsultant(state),
});

export default compose(
  withTranslation("common"),
  connect(mapStateToProps, null),
)(Filters);
