import React, { Component } from "react";
import { withTranslation } from "react-i18next";

import StepperItem from "./Item";

import "./Stepper.scss";

export const CURRENT = "current";
export const NEXT = "next";
export const PREVIOUS = "previous";

class Stepper extends Component {
  constructor(props) {
    super(props);
    this.state = { chosenStep: -1 };
  }

  stageTitle = (stage) => {
    const { t, titleTranslationBase } = this.props;
    if (titleTranslationBase) {
      return t(`${titleTranslationBase}.${stage.value}`);
    }
    return "";
  };

  handleStepClick = (code) => {
    this.setState({ chosenStep: parseInt(code) });
  };

  renderStage = (stage, type) => {
    const isActive = parseInt(stage.value) === this.state.chosenStep;

    return (
      <StepperItem
        key={stage.value}
        title={stage.label}
        code={stage.value}
        description={this.stageTitle(stage)}
        type={type}
        onClick={this.handleStepClick}
        isActive={isActive}
        chosenStep={this.state.chosenStep}
      />
    );
  };

  renderStages = (step) => {
    const { stage } = this.props;
    const stepValue = parseInt(step.value);
    const stageValue = parseInt(stage);

    const isSmaller = stepValue < stageValue;
    const isBigger = stepValue > stageValue;
    const isEqual = stepValue === stageValue;

    if (isSmaller) {
      return this.renderStage(step, PREVIOUS);
    } else if (isBigger) {
      return this.renderStage(step, NEXT);
    } else if (isEqual) {
      return this.renderStage(step, CURRENT);
    }
  };

  render() {
    const { stages, stage, t } = this.props;

    return stage && stages ? (
      <div className="group group--underlined deadline-details__stepper">
        <p className="u-text-bold">
          {t("deadlines.details.internationalStatusCode")}
        </p>
        <div className="stepper__wrapper">
          <ul className="codes-stepper">{stages.map(this.renderStages)}</ul>
        </div>
      </div>
    ) : null;
  }
}

export default withTranslation("common")(Stepper);
