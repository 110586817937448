import React, { Component } from "react";
import DateThumbnail from "../date-thumbnail/DateThumbnail";
import { Link, withRouter } from "react-router-dom";
import { Trans, withTranslation } from "react-i18next";
import CardMenu from "./CardMenu";
import GrayboxLabel from "../label/GrayboxLabel";

class CardHeader extends Component {
  renderLabel = () => {
    const { headerLabelText, isDeadlineRegistered, t } = this.props;

    return (
      <div className="card__headings__labels">
        {headerLabelText && (
          <GrayboxLabel>
            <span>
              <Trans useDangerouslySetInnerHTML={true}>{headerLabelText}</Trans>
            </span>
          </GrayboxLabel>
        )}
        {isDeadlineRegistered && (
          <GrayboxLabel>
            <span className="icon icon-check" />
            <span>{t("card.registered")}</span>
          </GrayboxLabel>
        )}
      </div>
    );
  };

  render() {
    const {
      committee,
      committeeName,
      date,
      uri,
      title,
      notificationId,
      callbackAction,
      customActions,
    } = this.props;

    return (
      <div className="card__header">
        <DateThumbnail type="regular" date={date} />
        <div className="card__headings">
          {this.renderLabel()}
          <span className="card__heading-1">
            <span className="u-text-bold">{committee}</span>
            {committeeName && ` - ${committeeName}`}
          </span>
          <Link to={uri} className="card__heading-2">
            {title}
          </Link>
        </div>
        <CardMenu
          committee={committee}
          notificationId={notificationId}
          callbackAction={callbackAction}
          customActions={customActions}
        />
      </div>
    );
  }
}

export default withRouter(withTranslation("common")(CardHeader));
