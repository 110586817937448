import { createStore, compose, applyMiddleware } from "redux";
import { combineReducers } from "redux";
import { getRootReducers } from "../state";
import { creteSagaMiddleware } from "./saga";
import { composeWithDevTools } from "redux-devtools-extension";

let store;

const makeRootReducer = (reducers) => combineReducers({ ...reducers });

export default (initialState = {}) => {
  const sagaMiddleware = creteSagaMiddleware();

  const enhancers = composeWithDevTools({});

  store = createStore(
    makeRootReducer(getRootReducers()),
    initialState,
    enhancers(compose(applyMiddleware(sagaMiddleware))),
  );

  return store;
};

export const getStore = () => {
  if (!store) {
    throw new Error("Create a store first.");
  }

  return store;
};
