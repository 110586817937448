export const scrollTop = () => {
  setTimeout(() => {
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
  }, 10);
};

export const scrollWithOffset = (el, offset) => {
  var body = document.body;
  var docEl = document.documentElement;
  var scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;
  const elementPosition = el.getBoundingClientRect().top + scrollTop - offset;
  setTimeout(() => {
    window.scroll({
      top: elementPosition,
      left: 0,
      behavior: "smooth",
    });
  }, 250);
};

export const scrollToElement = (selector) => {
  const elements = document.querySelectorAll(selector);
  if (elements && elements.length > 0) {
    elements[0].scrollIntoView({
      behavior: "smooth",
      block: "center",
      inline: "center",
    });
  }
};
