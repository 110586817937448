import React, { useEffect, useState } from "react";
import classNames from "classnames";
import SelectFilter from "../../../../common/select/SelectFilter";

import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { getPoll } from "../../../../../state/notifications/selectors";
import { updatePoll } from "../../../../../state/notifications/actions";

export const ChangeOfficalPollModal = ({ onClose }) => {
  const { t } = useTranslation("common");
  const dispatch = useDispatch();

  const poll = useSelector(getPoll);
  const options =
    poll?.options.map((option) => ({
      id: option.id,
      value: option.value,
      label: option.value,
    })) ?? [];
  const currentResult = poll.result;

  const [selectedOverrideOptionValue, setSelectedOverrideOptionValue] =
    useState(poll.result);
  const [disableButton, setDisableButton] = useState(
    !selectedOverrideOptionValue ||
      selectedOverrideOptionValue === currentResult,
  );

  const changePollResult = () => {
    dispatch(
      updatePoll({
        ...poll,
        result: selectedOverrideOptionValue,
      }),
    );
    onClose();
  };

  useEffect(() => {
    setDisableButton(
      !selectedOverrideOptionValue ||
        selectedOverrideOptionValue === currentResult,
    );
  }, [selectedOverrideOptionValue, currentResult]);

  return (
    <div className="modal__form">
      <div className="group group--first group--underlined">
        <h3 className="headline-4">
          {t("deadlines.details.officialVote.overrideModal.title")}
        </h3>
      </div>

      <div className="group group--last-underlined">
        <SelectFilter
          id="committeeFilter"
          label={t("deadlines.details.officialVote.overrideModal.label")}
          labelOnlyForScreenReaders={false}
          items={options}
          selectedItem={selectedOverrideOptionValue}
          onChange={(value) => setSelectedOverrideOptionValue(value)}
        />
      </div>

      <div className="modal__footer group group--btn u-align-right">
        <button className="button" onClick={onClose}>
          {t("general.button.cancel")}
        </button>
        <button
          className={classNames("button button--primary", {
            "button--disabled": disableButton,
          })}
          onClick={() => changePollResult()}
          disabled={disableButton}
        >
          {t("deadlines.details.officialVote.overrideModal.submit")}
        </button>
      </div>
    </div>
  );
};
