import React, { Component } from "react";
import { withTranslation } from "react-i18next";

import SelectFilter from "../../../../../components/common/select/SelectFilter";
import DatePicker from "react-datepicker";
import { shouldRenderDocumentId } from "../../../../../utils/documentUtils";

import "react-datepicker/dist/react-datepicker.css";
import "../../../../../styles/date-picker.scss";
import classNames from "classnames";
import { validate } from "../../../../../services/documents/validation";
import OptionalLabel from "../../../../common/optional-label/OptionalLabel";

class EditDocument extends Component {
  state = {
    validation: {
      filename: { isValid: true },
      title: { isValid: true },
      id: { isValid: true },
      type: { isValid: true },
      action: { isValid: true },
    },
    formSubmitted: false,
  };

  handleSubmit = () => {
    const formValidation = validate(this.props.document, this.state.validation);
    if (!formValidation.isValid) {
      return this.setState({ validation: formValidation.validation });
    }
    this.props.submitHandler(this.props.document);
    this.setState({ formSubmitted: true });
  };

  showValidationMessage = (field) => {
    const formField =
      this.props.document[field] && this.props.document[field].trim();
    if (field === "id") {
      return (
        !this.state.validation[field].isValid &&
        !Number.isInteger(parseInt(formField)) &&
        !!formField
      );
    } else {
      return !this.state.validation[field].isValid && !formField;
    }
  };

  render() {
    const { t } = this.props;
    //todo: list should be get from selector, not props
    const { title, description, committee, type, action, dueDate, id } =
      this.props.document;
    const { cancelHandler, filters, editDataHandler } = this.props;

    return (
      <div className="modal__form">
        <div className="group group--first group--full-underlined">
          <h3 className="headline-4">{t("documents.dialog.edit.title")}</h3>
        </div>
        <div className="group group--underlined">
          <label className="field-label">
            {t("documents.dialog.edit.label.title")}
          </label>
          <input
            type="text"
            className={classNames("input", {
              "input--validation_error": this.showValidationMessage("title"),
            })}
            value={title}
            onChange={(event) => {
              editDataHandler({ title: event.target.value });
            }}
          />
          {this.showValidationMessage("title") && (
            <span className="validation-message__error">
              {t("general.validation.notEmpty")}
            </span>
          )}
        </div>

        {/* Document description */}
        <div className="group group--underlined">
          <label className="field-label" htmlFor="description">
            {t("documents.dialog.create.label.description")}
            <OptionalLabel />
          </label>
          <input
            id="description"
            type="text"
            className={"input"}
            value={description ? description : ""}
            onChange={(event) => {
              editDataHandler({ description: event.target.value });
            }}
          />
        </div>

        <div className="group group--underlined">
          <SelectFilter
            id="committeeFilter"
            label={t("documents.dialog.edit.label.committee")}
            labelOnlyForScreenReaders={false}
            items={filters.committees}
            selectedItem={committee}
            onChange={(committeeId) => {
              editDataHandler({ committee: committeeId });
            }}
          />
        </div>

        <div className="group group--underlined">
          <label className="field-label" htmlFor="documentId">
            {t("documents.dialog.edit.label.documentId")}
            <OptionalLabel />
          </label>
          <input
            id="documentId"
            type="text"
            className={classNames("input", {
              "input--validation_error": this.showValidationMessage("id"),
            })}
            value={shouldRenderDocumentId(id) ? id : ""}
            onChange={(event) => {
              editDataHandler({ id: event.target.value });
            }}
          />
          {this.showValidationMessage("id") && (
            <span className="validation-message__error">
              {t("general.validation.isNumber")}
            </span>
          )}
        </div>

        <div className="group group--underlined">
          <SelectFilter
            id="documentTypeFilter"
            label={t("documents.dialog.edit.label.documentType")}
            labelOnlyForScreenReaders={false}
            items={filters.avaiableTypes}
            selectedItem={type}
            onChange={(typeId) => {
              editDataHandler({ type: typeId });
            }}
            customClasses={classNames({
              "input--validation_error": this.showValidationMessage("type"),
            })}
          />
          {this.showValidationMessage("type") && (
            <span className="validation-message__error">
              {t("general.validation.isSelected")}
            </span>
          )}
        </div>

        <div className="group group--underlined">
          <SelectFilter
            id="actionFilter"
            label={t("documents.dialog.edit.label.action")}
            labelOnlyForScreenReaders={false}
            items={filters.actions}
            selectedItem={action}
            onChange={(actionId) => {
              editDataHandler({ action: actionId });
            }}
            customClasses={classNames({
              "input--validation_error": this.showValidationMessage("action"),
            })}
          />
          {this.showValidationMessage("action") && (
            <span className="validation-message__error">
              {t("general.validation.isSelected")}
            </span>
          )}
        </div>

        <div className="group group--underlined">
          <label className="field-label" htmlFor="documentDueDate">
            {t("documents.dialog.edit.label.actionDueDate")}
            <OptionalLabel />
          </label>
          <div className="datepicker datepicker--width-fix">
            <DatePicker
              className="input"
              name="documentDueDate"
              id="documentDueDate"
              selected={dueDate}
              onChange={(date) => {
                editDataHandler({ dueDate: date });
              }}
              dateFormat="yyyy-MM-dd"
              minDate={new Date()}
            />
          </div>
        </div>

        <div className="modal__footer group group--btn u-align-right">
          <button className="button" onClick={cancelHandler}>
            {t("general.button.cancel")}
          </button>
          <button
            className={classNames("button button--primary", {
              "button--awaiting button--disabled": this.state.formSubmitted,
            })}
            onClick={this.handleSubmit}
            disabled={this.state.formSubmitted}
          >
            {t("general.button.save")}
          </button>
        </div>
      </div>
    );
  }
}

export default withTranslation("common")(EditDocument);
