import React, { Component, Fragment } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";

import NotificationsList from "./pages/notifications-list/NotificationsList";
import { resetFiltersForm } from "../../../state/document/actions";
import PageNotFound from "../404/PageNotFound";

import "./Notifications.scss";
import { DIALOG_TYPES, generateDialogLink } from "../../../utils/dynamicLink";

class Notifications extends Component {
  componentWillUnmount() {
    this.props.resetFiltersForm();
  }

  render() {
    return (
      <Fragment>
        <Switch>
          <Redirect
            from={`${this.props.match.path}/:notificationUrl/details*`}
            to={`${this.props.match.path}/${generateDialogLink(
              DIALOG_TYPES.NOTIFICATION_DETAILS,
              ":notificationUrl",
            )}.xml`}
          />
          <Route
            exact
            path={`${this.props.match.path}/`}
            component={NotificationsList}
          />
          <Route path="*" component={PageNotFound} />
        </Switch>
      </Fragment>
    );
  }
}
const mapDispatchToProps = {
  resetFiltersForm,
};
export default compose(connect(null, mapDispatchToProps))(Notifications);
