import React, { Component } from "react";
import DeadlineForm from "../deadline-form/DeadlineForm";
import {
  addGeneralDeadlineInformation,
  createDeadline,
} from "../../../../../state/deadlines/actions";
import { initDocuments } from "../../../../../state/document/actions";
import { applicationTriggerUserHint } from "../../../../../state/app/actions";
import { generateCloseDialogLink } from "../../../../../utils/dynamicLink";
import { compose } from "redux";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { getNewDeadlineFormData } from "../../../../../state/deadlines/selectors";
import { applicationUserHints } from "../../../../../utils/constants";
import { selectedNationalCommittee } from "../../../../../state/national-committees/selectors";

class CreateDeadline extends Component {
  onMount = () => {
    this.props.initDocuments();
    if (this.props.selectedNationalCommittee) {
      this.props.addGeneralDeadlineInformation({
        committee: this.props.selectedNationalCommittee,
      });
    }

    if (
      !this.props.formData ||
      !this.props.formData.references ||
      this.props.formData.references.length === 0
    ) {
      this.props.applicationTriggerUserHint({
        hintId: applicationUserHints.createDeadlineFromDocument,
      });
    }
  };

  get editorSubmitAction() {
    return this.props.createDeadline;
  }

  get editorCancelAction() {
    return generateCloseDialogLink();
  }

  render() {
    const { t, formData } = this.props;
    const detailTitle = t("deadlines.details.new.title");

    return (
      <DeadlineForm
        isCreate
        formData={formData}
        onMount={this.onMount}
        editorSubmitAction={this.editorSubmitAction}
        editorCancelAction={this.editorCancelAction}
        detailTitle={detailTitle}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  formData: getNewDeadlineFormData(state),
  selectedNationalCommittee: selectedNationalCommittee(state),
});

const mapDispatchToProps = {
  initDocuments,
  createDeadline,
  addGeneralDeadlineInformation,
  applicationTriggerUserHint,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation("common"),
)(CreateDeadline);
