import { notificationType } from "../../utils/constants";
import {
  normalizeDate,
  reduceCommitteeName,
  removeWrongCharacters,
} from "../../utils/transform";
import { normalizeDocument } from "../document/normalizers";

export const markNotificationsAsSeen = (notifications) => {
  Object.values(notifications).forEach((obj) => {
    obj.seen = true;
  });
  return notifications;
};

export const setIsInstantSent = (
  notifications,
  notificationId,
  isInstantSent,
) => ({
  ...notifications,
  [notificationId]: {
    ...notifications[notificationId],
    isInstantSent,
  },
});

const normalizeUserNotificationDetails = (details, data) => {
  data.committee = details.committeeReference;
  data.date = normalizeDate(details.lastModifiedDate);
  data.title = details.title;
  data.description = details.description;
  data.documents =
    details.references && details.references.length > 0
      ? details.references
      : null;
  data.references = details.references;
  data.member = details.member;
};

const normalizeMemberNotificationDetails = (details, notification, data) => {
  data.email = details.email;
  data.name = details.name;
  data.company = details.company;
  data.profileImage = details.profileImage;
  data.date = normalizeDate(details.date);
  data.udvalg = details.udvalg;
};

const normalizeMeetingNotificationDetails = (details, notification, data) => {
  normalizeBaseNotificationDetails(details, notification, data);

  data.date = normalizeDate(details.fromDate);
  data.fromDate = normalizeDate(details.fromDate);
  data.toDate = normalizeDate(details.toDate);
  data.locationShort = details.shortLocation;
};

const normalizeBaseNotificationDetails = (details, notification, data) => {
  data.notificationDate = normalizeDate(details.eventDate);
  data.title = removeWrongCharacters(details.title);
  data.committee = details.committeeReference;
  data.committeeName = reduceCommitteeName(
    details.committeeReference,
    details.committeeReferenceTitle,
  );
  data.references = details.references;
};

const normalizeDeadlineNotificationDetails = (details, notification, data) => {
  normalizeBaseNotificationDetails(details, notification, data);

  data.date = normalizeDate(details.actionDueDate);
  data.description = details.description;
};

const normalizeDocumentSummationNotificationDetails = (
  details,
  notification,
  data,
) => {
  normalizeBaseNotificationDetails(details, notification, data);
  data.documents = new Array(details.documentSummation.count);
  data.references = details.references;
};

const normalizeDocumentNotificationDetails = (details, notification, data) => {
  data.committee = details.metadata.committeeReference;
  data.committeeName = reduceCommitteeName(
    details.metadata.committeeReference,
    details.metadata.committeeReferenceTitle,
  );

  data.date = normalizeDate(details.metadata.documentEventDate);
  data.documents = details.documents;
  data.references = details.references;
};

const normalizeVoteNotificationDetails = (details, notification, data) => {
  data.notificationDate = normalizeDate(notification.eventDate);
  data.title = details.deadline.title;
  data.committee = details.deadline.committeeReference;
  data.committeeName = reduceCommitteeName(
    details.deadline.committeeReference,
    details.deadline.committeeReferenceTitle,
  );

  data.documents = details.deadline.documents;
  data.references = details.deadline.references;
  data.date = normalizeDate(details.deadline.actionDueDate);
  data.description = details.deadline.description;
  data.contentId = details.deadline.uri;
  data.comments = details.deadline.comments;
  data.author = details.user.name;

  return data;
};

const normalizeInternalDocumentSummation = (details, notification, data) => {
  data.notificationDate = normalizeDate(notification.eventDate);
  data.committee = details.committee;
  data.documentsCount = details.documents.length;
  data.aggDate = details.aggDate;
  data.documents = details.documents.map(normalizeDocument);
  data.references = details.references;
  // data.documentUris = details.documents.map((d) => d.uri);
};

export const normalizeNotificationDetails = (details, notification) => {
  let data = {};

  switch (notification.type) {
    case notificationType.deadline:
      normalizeDeadlineNotificationDetails(details, notification, data);
      break;
    case notificationType.meeting:
      normalizeMeetingNotificationDetails(details, notification, data);
      break;
    case notificationType.member:
      normalizeMemberNotificationDetails(details, notification, data);
      break;
    case notificationType.user:
      normalizeUserNotificationDetails(details, data);
      break;
    case notificationType.comment:
      normalizeCommentNotificationDetails(details, notification, data);
      break;
    case notificationType.document:
      normalizeDocumentNotificationDetails(details, notification, data);
      break;
    case notificationType.documentSummation:
      normalizeDocumentSummationNotificationDetails(
        details,
        notification,
        data,
      );
      break;
    case notificationType.vote:
      normalizeVoteNotificationDetails(details, notification, data);
      break;
    case notificationType.internalDocumentSummation:
      normalizeInternalDocumentSummation(details, notification, data);
      break;
    case notificationType.internalVoteSummation:
      normalizeInternalDeadlineSummation(details, notification, data);
      break;
    case notificationType.internalCommentsSummation:
      normalizeInternalDeadlineSummation(details, notification, data);
      break;
    default:
      break;
  }

  return data;
};

export const normalizeNotifications = (notifications) => {
  return notifications.reduce(
    (previous, current) => ({
      ...previous,
      ...normalizeNotification(current),
    }),
    {},
  );
};

export const normalizeNotification = (notification) => {
  const contentId = [
    notificationType.user,
    notificationType.documentSummation,
  ].includes(notification.type)
    ? notification.uri
    : notification.document_uri
      ? notification.document_uri
      : null;

  return {
    [notification.uri]: {
      notificationId: notification.uri,
      action: notification.event_type,
      type: notification.type,
      pinned: notification.pinned,
      seen: notification.seen,
      markedAsSeen: notification.seen,
      eventDate: normalizeDate(notification.notification_date),
      contentId,
      isInstantSent: notification.isInstantSent,
    },
  };
};

const normalizeCommentNotificationDetails = (details, notification, data) => {
  data.notificationDate = notification.eventDate;
  data.title = details.deadline.title;
  data.committee = details.deadline.committeeReference;
  data.committeeName = reduceCommitteeName(
    details.deadline.committeeReference,
    details.deadline.committeeReferenceTitle,
  );

  data.documents = details.deadline.documents;
  data.references = details.deadline.references;
  data.date = normalizeDate(details.deadline.actionDueDate);
  data.description = details.deadline.description;
  data.contentId = details.deadline.uri;
  data.comments = details.deadline.comments;
  data.author = details.notification.memberId;
};

const normalizeInternalDeadlineSummation = (details, notification, data) => {
  data.notificationDate = normalizeDate(notification.eventDate);
  data.title = details.deadline.title;
  data.committee = details.committee;
  data.members = details.members;

  data.committeeName = reduceCommitteeName(
    details.deadline.committeeReference,
    details.deadline.committeeReferenceTitle,
  );

  data.documents = details.deadline.documents;
  data.references = details.deadline.references;
  data.date = normalizeDate(details.deadline.actionDueDate);
  data.description = details.deadline.description;
  data.contentId = details.deadline.uri;
  data.comments = details.deadline.comments;
  data.enableVoting = details.deadline.enableVoting;
  data.stage = details.deadline.stage;
  data.closedForComments = details.deadline.closedForComments;
  // data.documentsCount = details.documents.length;
  // data.aggDate = details.aggDate;
};

// deadline updates
const updateNotificationOnDeadlineUpdate = (notification, update) => {
  if (notification.contentId !== update.uri || !notification.details)
    return { ...notification };

  if (notification.type === notificationType.deadline) {
    let newNotification = {
      ...notification,
      details: {
        ...notification.details,
        title: removeWrongCharacters(update.title),
        committee: update.committeeReference,
        committeeName: reduceCommitteeName(
          update.committeeReference,
          update.committeeReferenceTitle,
        ),
        documents: update.documents,
        references: update.references,

        date: normalizeDate(update.actionDueDate),
        description: update.description,
      },
    };
    return newNotification;
  }

  if (
    notification.type === notificationType.internalCommentsSummation ||
    notification.type === notificationType.internalVoteSummation
  ) {
    let newNotification = {
      ...notification,
      details: {
        ...notification.details,
        title: removeWrongCharacters(update.title),
        committee: update.committeeReference,
        committeeName: reduceCommitteeName(
          update.committeeReference,
          update.committeeReferenceTitle,
        ),
        documents: update.documents,
        references: update.references,
        date: normalizeDate(update.actionDueDate),
        description: update.description,
        stage: update.stage,
      },
    };

    return newNotification;
  }

  return { ...notification };
};
export const updateNotificationsOnDeadlineUpdate = (data, update) => {
  const newData = Object.values(data).reduce(
    (acc, cur) => ({
      ...acc,
      [cur.notificationId]: updateNotificationOnDeadlineUpdate(cur, update),
    }),
    {},
  );
  return newData;
};

const addCommnetToDeadlineNotification = (notification, comment) => {
  const { deadlineUri, memberId } = comment;
  if (notification.contentId !== deadlineUri || !notification.details) {
    return { ...notification };
  }

  let updatedNotification = {
    ...notification,
    details: {
      ...notification.details,
      comments: (notification.details.comments || 0) + 1,
    },
  };

  if (notification.type === notificationType.internalCommentsSummation) {
    if (!updatedNotification.details.members)
      updatedNotification.details.members = [memberId];
    else if (!updatedNotification.details.members.includes(memberId)) {
      updatedNotification.details.members = [
        ...updatedNotification.details.members,
        memberId,
      ];
    }
  }

  return updatedNotification;
};

const removeCommnetToDeadlineNotification = (notification, comment) => {
  const { deadlineUri } = comment;
  if (notification.contentId !== deadlineUri || !notification.details) {
    return { ...notification };
  }

  let updatedNotification = {
    ...notification,
    details: {
      ...notification.details,
      comments: (notification.details.comments || 0) - 1,
    },
  };

  return updatedNotification;
};

export const addCommnetToDeadlineNotifications = (data, comment) => {
  const newData = Object.values(data).reduce(
    (acc, cur) => ({
      ...acc,
      [cur.notificationId]: addCommnetToDeadlineNotification(cur, comment),
    }),
    {},
  );
  return newData;
};

export const removeCommnetToDeadlineNotifications = (data, comment) => {
  const newData = Object.values(data).reduce(
    (acc, cur) => ({
      ...acc,
      [cur.notificationId]: removeCommnetToDeadlineNotification(cur, comment),
    }),
    {},
  );
  return newData;
};

const addVoteToDeadlineNotification = (notification, vote) => {
  let updatedNotification = {
    ...notification,
    details: {
      ...notification.details,
    },
  };

  if (
    notification.type === notificationType.internalVoteSummation &&
    notification.contentId === vote.deadlineUri
  ) {
    const memberId = vote.memberId;

    if (!updatedNotification.details.members) {
      updatedNotification.details.members = [memberId];
    } else if (!updatedNotification.details.members.includes(memberId)) {
      updatedNotification.details.members = [
        ...updatedNotification.details.members,
        memberId,
      ];
    }
  }

  return updatedNotification;
};

export const addVoteToDeadlineNotifications = (data, vote) => {
  const newData = Object.values(data).reduce(
    (acc, cur) => ({
      ...acc,
      [cur.notificationId]: addVoteToDeadlineNotification(cur, vote),
    }),
    {},
  );
  return newData;
};

const removeVoteToDeadlineNotification = (notification, vote) => {
  let updatedNotification = {
    ...notification,
    details: {
      ...notification.details,
    },
  };

  if (
    notification.type === notificationType.internalVoteSummation &&
    notification.contentId === vote.deadlineUri
  ) {
    const memberId = vote.memberId;

    if (!updatedNotification.details.members) {
      return updatedNotification;
    } else if (updatedNotification.details.members.includes(memberId)) {
      updatedNotification.details.members =
        updatedNotification.details.members.filter((m) => m !== memberId);
    }
  }

  return updatedNotification;
};

export const removeVoteToDeadlineNotifications = (data, vote) => {
  const newData = Object.values(data).reduce(
    (acc, cur) => ({
      ...acc,
      [cur.notificationId]: removeVoteToDeadlineNotification(cur, vote),
    }),
    {},
  );
  return newData;
};

export const removeNotificationById = (data, id) => {
  return Object.values(data)
    .filter((d) => d.notificationId !== id)
    .reduce(
      (acc, cur) => ({
        ...acc,
        [cur.notificationId]: cur,
      }),
      {},
    );
};

export const removeNotificationsRelatedToResource = (data, contentId) => {
  return Object.values(data)
    .filter((d) => d.contentId !== contentId)
    .reduce(
      (acc, cur) => ({
        ...acc,
        [cur.notificationId]: cur,
      }),
      {},
    );
};

// meeting updates
const updateNotificationOnMeetingUpdate = (notification, update) => {
  if (notification.contentId !== update.uri || !notification.details)
    return { ...notification };

  if (notification.type === notificationType.meeting) {
    let newNotification = {
      ...notification,
      details: {
        ...notification.details,
        title: removeWrongCharacters(update.title),
        committee: update.committeeReference,
        committeeName: reduceCommitteeName(
          update.committeeReference,
          update.committeeReferenceTitle,
        ),
        documents: update.documents,
        date: normalizeDate(update.fromDate),
        fromDate: normalizeDate(update.fromDate),
        toDate: normalizeDate(update.toDate),
        locationShort: update.shortLocation,
      },
    };
    return newNotification;
  }

  return { ...notification };
};
export const updateNotificationsOnMeetingUpdate = (data, update) => {
  const newData = Object.values(data).reduce(
    (acc, cur) => ({
      ...acc,
      [cur.notificationId]: updateNotificationOnMeetingUpdate(cur, update),
    }),
    {},
  );
  return newData;
};
