import React, { Component } from "react";
import userManager from "../../../services/auth/openIdClient";
import { withTranslation } from "react-i18next";
import { Link, withRouter } from "react-router-dom";
import Search from "../search-box/Search";
import LanguageSwitcher from "../language-switcher/LanguageSwitcher";
import ToolbarTool from "../toolbar-link/ToolbarTool";
import WithPrivileges from "../with-privileges/WithPrivileges";
import "./LayoutPanelLeftFooter.scss";

class LayoutPanelLeftFooter extends Component {
  state = {
    userBoxOpened: false,
  };

  componentDidMount() {
    window.addEventListener("click", this.documentClick);
  }

  componentWillUnmount() {
    window.removeEventListener("click", this.documentClick);
  }

  documentClick = (e) => {
    if (
      !(
        e.target.classList.contains("user-box__button") ||
        (e.target.parentElement &&
          e.target.parentElement.classList.contains("user-box__button"))
      )
    ) {
      this.setState({
        userBoxOpened: false,
      });
    }
  };

  onClick = () => {
    this.toggleUserProfile();
    userManager.signoutRedirect();
    userManager.removeUser();
  };

  toggleUserProfile = () => {
    this.setState({
      userBoxOpened: !this.state.userBoxOpened,
    });
  };

  render() {
    const { t, location } = this.props;
    const { userBoxOpened } = this.state;

    const userSettingActive = location.pathname.includes("/user");

    return (
      <div className="layout__panel--left--footer">
        <LanguageSwitcher />

        <div className="footer-right">
          <WithPrivileges>
            <ToolbarTool
              icon="question-mark"
              link={process.env.REACT_APP_BEST_PRACTICE_DOC}
            />
          </WithPrivileges>

          <div className="user-box">
            <button
              className={`user-box__button ${userSettingActive ? "icon--active" : ""}`}
              onClick={this.toggleUserProfile}
            >
              <span
                className="icon icon-profile-icon pointer"
                aria-hidden="true"
              ></span>
              <span className="visuallyhidden">
                {t("navigation.userMenu.userProfile")}
              </span>
            </button>
            {userBoxOpened ? (
              <div className="user-box__dropdown panel panel--border">
                <ul className="list">
                  <li className="list__element">
                    <Link
                      to="/user/profile"
                      onClick={this.toggleUserProfile}
                      className="button button--link"
                    >
                      {t("userProfile.navigation.tab.profile")}
                    </Link>
                  </li>
                  <li className="list__element">
                    <Link
                      to="/user/credentials"
                      onClick={this.toggleUserProfile}
                      className="button button--link"
                    >
                      {t("userProfile.navigation.tab.credentials")}
                    </Link>
                  </li>

                  {/* either this or the other */}
                  <li className="list__element">
                    <Link
                      to="/user/settings"
                      onClick={this.toggleUserProfile}
                      className="button button--link"
                    >
                      {t("userProfile.navigation.tab.settings")}
                    </Link>
                  </li>
                  {/* TODO: PLAN 2 */}
                  {/* <li className="list__element">
                    <Link
                      to="/user/settings"
                      onClick={this.toggleUserProfile}
                      className="button button--link"
                    >
                      {t("Notifications")}
                    </Link>
                  </li>
                  <li className="list__element">
                    <Link
                      to="/user/settings/email"
                      onClick={this.toggleUserProfile}
                      className="button button--link"
                    >
                      {t("Email")}
                    </Link>
                  </li> */}
                  <li className="list__element">
                    <button
                      className="button button--link"
                      onClick={this.onClick}
                    >
                      {t("navigation.userMenu.logout")}
                    </button>
                  </li>
                </ul>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(withTranslation("common")(LayoutPanelLeftFooter));
