import { createAction } from "redux-actions";

const INIT_MEETINGS = "MEETINGS/init_meetings";

const FETCH_UPDATED_MEETING_DOCUMENT = "MEETING_FORM/fetch_updated_document";
const FETCH_UPDATED_MEETING_DOCUMENT_SUCCEEDED =
  "MEETING_FORM/fetch_updated_document_succeeded";
const FETCH_UPDATED_MEETING_DOCUMENT_FAILED =
  "MEETING_FORM/fetch_updated_document_failed";

const FETCH_MEETING = "MEETINGS/fetch_meeting";
const FETCH_MEETING_SUCCEEDED = "MEETINGS/fetch_meeting_succeeded";
const FETCH_MEETING_FAILED = "MEETINGS/fetch_meeting_failed";

const CLEAR_MEETING = "MEETINGS/clear_meeting";

const UPDATE_MEETING = "MEETINGS/update_meeting";
const UPDATE_MEETING_SUCCEEDED = "MEETINGS/update_meeting_succeeded";
const UPDATE_MEETING_FAILED = "MEETINGS/update_meeting_failed";

const FETCH_CURRENT_MEETING_DOCUMENTS =
  "MEETINGS/fetch_current_meeting_documents";
const FETCH_CURRENT_MEETING_DOCUMENTS_SUCCEEDED =
  "MEETINGS/fetch_current_meeting_documents_succeeded";
const FETCH_CURRENT_MEETING_DOCUMENTS_FAILED =
  "MEETINGS/fetch_current_meeting_documents_failed";

const DELETE_MEETING = "MEETINGS/delete_meeting";
const DELETE_MEETING_SUCCEEDED = "MEETINGS/delete_meeting_succeeded";
const DELETE_MEETING_FAILED = "MEETINGS/delete_meeting_failed";

const CREATE_MEETING = "MEETINGS/create_meeting";
const CREATE_MEETING_SUCCEEDED = "MEETINGS/create_meeting_succeeded";
const CREATE_MEETING_FAILED = "MEETINGS/create_meeting_failed";

const ADD_FORM_SELECTED_MEETING_DOCUMENTS =
  "MEETING_FORM/add_form_selected_documents";
const SET_FORM_SELECTED_MEETING_DOCUMENTS =
  "MEETING_FORM/set_form_selected_documents";
const SET_FORM_SELECTED_MEETING_REFERENCE_DEADLINES =
  "MEETING_FORM/set_form_selected_references_deadlines";
const ADD_GENERAL_MEETING_INFORMATION = "MEETING_FORM/add_general_info";

const ADD_MEETING_DOCUMENTS = "MEETINGS/add_meeting_documents";
const SET_MEETING_DOCUMENTS = "MEETINGS/set_meeting_documents";
const UPDATE_MEETING_DOCUMENT_INFO = "MEETINGS/update_meeting_document_info";

const INIT_MEETING_FORM_WITH_GENERAL_INFO_DATA =
  "MEETING_FORM/init_with_general_info_data";
const INIT_MEETING_FORM_WITH_DOCUMENTS = "MEETING_FORM/init_with_documents";
const CLEAR_MEETING_FORM = "MEETING_FORM/clear";

const CREATE_MEETING_FROM_DOCUMENT = "MEETING/create_meeting_from_document";
const DOWNLOAD_MEETING_DOCUMENTS = "MEETING_FORM/download_meeting_documents";
const REMOVE_MEETING_DOCUMENT = "MEETING_FORM/remove_notification_document";

const RESET_MEETINGS_FILTERS = "FILTER/reset_meetings_filters";

const PULL_MEETINGS_PAGE_REQUEST = "MEETINGS/pull_meetings_page_request";
const PULL_MEETINGS_PAGE_SUCCEEDED = "MEETINGS/pull_meetings_page_succeeded";
const PULL_MEETINGS_PAGE_FAILED = "MEETINGS/pull_meetings_page_failed";

const FETCH_MEETING_REFERENCES = "MEETINGS/fetch_meeting_documents";
const FETCH_MEETING_REFERENCES_SUCCEEDED =
  "MEETINGS/fetch_meeting_documents_succeeded";
const FETCH_MEETING_REFERENCESS_FAILED =
  "MEETINGS/fetch_meeting_documents_failed";

const SET_MEETINGS_FILTER_VALUE = "FILTER/set_meetings_filter_values";

const ADD_PARTICIPANT = "MEETINGS/add_participant";
const ADD_PARTICIPANT_SUCCEEDED = "MEETINGS/add_participant_succeeded";
const ADD_PARTICIPANT_FAILED = "MEETINGS/add_participant_failed";

const UPDATE_PARTICIPANT = "MEETINGS/update_participant";
const UPDATE_PARTICIPANT_SUCCEEDED = "MEETINGS/update_participant_succeeded";
const UPDATE_PARTICIPANT_FAILED = "MEETINGS/update_participant_failed";

export const initMeetings = createAction(INIT_MEETINGS);

export const fetchUpdatedMeetingDocument = createAction(
  FETCH_UPDATED_MEETING_DOCUMENT,
);
export const fetchUpdatedMeetingDocumentSucceeded = createAction(
  FETCH_UPDATED_MEETING_DOCUMENT_SUCCEEDED,
);
export const fetchUpdatedMeetingDocumentFailed = createAction(
  FETCH_UPDATED_MEETING_DOCUMENT_FAILED,
);

export const fetchMeeting = createAction(FETCH_MEETING);
export const fetchMeetingSucceeded = createAction(FETCH_MEETING_SUCCEEDED);
export const fetchMeetingFailed = createAction(FETCH_MEETING_FAILED);

export const fetchCurrentMeetingDocuments = createAction(
  FETCH_CURRENT_MEETING_DOCUMENTS,
);
export const fetchCurrentMeetingDocumentsSucceeded = createAction(
  FETCH_CURRENT_MEETING_DOCUMENTS_SUCCEEDED,
);
export const fetchCurrentMeetingDocumentsFailed = createAction(
  FETCH_CURRENT_MEETING_DOCUMENTS_FAILED,
);

export const updateMeeting = createAction(UPDATE_MEETING);
export const updateMeetingSucceeded = createAction(UPDATE_MEETING_SUCCEEDED);
export const updateMeetingFailed = createAction(UPDATE_MEETING_FAILED);

export const deleteMeeting = createAction(DELETE_MEETING);
export const deleteMeetingSucceeded = createAction(DELETE_MEETING_SUCCEEDED);
export const deleteMeetingFailed = createAction(DELETE_MEETING_FAILED);

export const createMeeting = createAction(CREATE_MEETING);
export const createMeetingSucceeded = createAction(CREATE_MEETING_SUCCEEDED);
export const createMeetingFailed = createAction(CREATE_MEETING_FAILED);

export const createMeetingFromDocument = createAction(
  CREATE_MEETING_FROM_DOCUMENT,
);
export const addFormSelectedMeetingDocuments = createAction(
  ADD_FORM_SELECTED_MEETING_DOCUMENTS,
);
export const setFormSelectedMeetingDocuments = createAction(
  SET_FORM_SELECTED_MEETING_DOCUMENTS,
);

export const setFormSelectedMeetingReferencesDeadlines = createAction(
  SET_FORM_SELECTED_MEETING_REFERENCE_DEADLINES,
);

export const addGeneralMeetingInformation = createAction(
  ADD_GENERAL_MEETING_INFORMATION,
);

export const addMeetingDocuments = createAction(ADD_MEETING_DOCUMENTS);
export const setMeetingDocuments = createAction(SET_MEETING_DOCUMENTS);
export const updateMeetingDocumentInfo = createAction(
  UPDATE_MEETING_DOCUMENT_INFO,
);

export const initMeetingFormWithGeneralInfo = createAction(
  INIT_MEETING_FORM_WITH_GENERAL_INFO_DATA,
);
export const initMeetingFormWithDocuments = createAction(
  INIT_MEETING_FORM_WITH_DOCUMENTS,
);
export const clearMeetingForm = createAction(CLEAR_MEETING_FORM);

export const downloadMeetingDocuments = createAction(
  DOWNLOAD_MEETING_DOCUMENTS,
);
export const removeReferenceFromMeeting = createAction(REMOVE_MEETING_DOCUMENT);

export const resetMeetingsFilters = createAction(RESET_MEETINGS_FILTERS);

export const setMeetingsFilterValue = createAction(SET_MEETINGS_FILTER_VALUE);

export const pullMeetingsPageRequest = createAction(PULL_MEETINGS_PAGE_REQUEST);
export const pullMeetingsPageSucceeded = createAction(
  PULL_MEETINGS_PAGE_SUCCEEDED,
);
export const pullMeetingsPageFailed = createAction(PULL_MEETINGS_PAGE_FAILED);

export const clearMeeting = createAction(CLEAR_MEETING);

export const fetchMeetingReferences = createAction(FETCH_MEETING_REFERENCES);
export const fetchMeetingReferencesSucceeded = createAction(
  FETCH_MEETING_REFERENCES_SUCCEEDED,
);
export const fetchMeetingReferencesFailed = createAction(
  FETCH_MEETING_REFERENCESS_FAILED,
);

export const addParticipant = createAction(ADD_PARTICIPANT);
export const addParticipantSucceeded = createAction(ADD_PARTICIPANT_SUCCEEDED);
export const addParticipantFailed = createAction(ADD_PARTICIPANT_FAILED);

export const updateParticipant = createAction(UPDATE_PARTICIPANT);
export const updateParticipantSucceeded = createAction(
  UPDATE_PARTICIPANT_SUCCEEDED,
);
export const updateParticipantFailed = createAction(UPDATE_PARTICIPANT_FAILED);
