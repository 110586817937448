import { ApplicationInsights } from "@microsoft/applicationinsights-web";

export default function CreateApplicationInsights() {
  const appInsights = new ApplicationInsights({
    config: {
      instrumentationKey: process.env.REACT_APP_INSTRUMENTATION_KEY,
      loggingLevelConsole: 0,
      disableAjaxTracking: true,
      disableFetchTracking: true,
    },
  });
  appInsights.loadAppInsights();

  var log_error = console.error.bind(console);
  console.error = (...args) => {
    try {
      if (args.length !== 0) {
        if (typeof args[0] == "string") {
          appInsights.trackTrace({ message: args[0], args: args.slice(1) });
        } else {
          args.forEach((a) => {
            if (a && a.stack && a.message) {
              appInsights.trackException({ exception: a });
            } else {
              appInsights.trackException({ exception: args[0] });
            }
          });
        }
      }
    } catch (error) {
      log_error(error);
      appInsights.trackException({ exception: error });
    }

    log_error(...args);
  };

  return appInsights;
}
