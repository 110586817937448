const ERROR_CODES = {
  INVALID_PASSWORD: "apiErrors.invalidPassword",
};

export function GetErrorMessage(errorCode) {
  if (ERROR_CODES.hasOwnProperty(errorCode)) {
    return ERROR_CODES[errorCode];
  }

  return errorCode;
}
