// import { createSelector } from "reselect";

export const getModalDepth = (state) => state.modalStack.modalStack.length;

export const getPreviousModal = (state) => {
  return state.modalStack.modalStack[state.modalStack.modalStack.length - 1];
};

export const getModalStackHistory = (state) => {
  return state.modalStack.modalStack.slice(1).join(" > ");
};
