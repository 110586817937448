import { TIMEFRAME } from "../api/utils";
import { defaultExcerptLength, otherCommitteeId } from "./constants";
import { getStore } from "./state";

const firstIndexOfAny = (str, chars, offset) => {
  const indices = chars
    .map((c) => str.indexOf(c, offset))
    .filter((i) => i !== -1);
  if (indices) {
    return Math.min(...indices);
  }

  return -1;
};

export const transformCommitteeName = (name) => {
  return name ? name.replace(/\//g, "_") : name;
};

export const reverseTransformCommitteeName = (name) => {
  return name ? name.replace(/_/g, "/") : name;
};

export const getAcronym = (str) => {
  return str
    ? str
        .split(/\s/)
        .reduce((response, word) => (response += word.slice(0, 1)), "")
        .replace("(", "")
        .replace(")", "")
    : "";
};

export const getDateString = (date) => {
  if (!date) {
    return "-";
  }

  var y = date.getFullYear();
  var m = date.getMonth() + 1;
  var d = date.getDate();

  return `${y}-${(m < 10 ? "0" : "") + m}-${(d < 10 ? "0" : "") + d}`;
  // return date ? date.toISOString().slice(0,10) : "-";
};

export const getExcerpt = (text, length = defaultExcerptLength) => {
  if (text && text.length > length) {
    return text.substring(0, length - 1) + "...";
  }
  return text;
};

export const getExcerptWithWholeWords = (
  text,
  length = defaultExcerptLength,
) => {
  if (text && text.length > length) {
    let trimPoint = firstIndexOfAny(text, [" ", "\t", "\n"], length);
    if (trimPoint === -1) {
      return text;
    }

    return text.substring(0, trimPoint).concat(" ...");
  }
  return text;
};

export const normalizeDate = (dateString) => {
  let dateObject = dateString ? new Date(dateString) : null;
  return dateObject instanceof Date && !isNaN(dateObject) ? dateObject : null;
};

export const formatEventDate = (from) => {
  const locale = getStore().getState().user.data.kundeportal.locale;
  const dateFrom = new Date(from);
  const dayFrom = dateFrom.getDate();

  const monthFrom = dateFrom.toLocaleDateString(locale, { month: "long" });
  const timeMinutes = ("0" + dateFrom.getMinutes()).slice(-2);
  const timeHours = ("0" + dateFrom.getHours()).slice(-2);
  const timeFrom = `${timeHours}:${timeMinutes}`;
  const yearFrom = dateFrom.getFullYear();

  return `${dayFrom} ${monthFrom} ${yearFrom} ${timeFrom}`;
};

export const formatDateTime = (date) => {
  const locale = getStore().getState().user.data.kundeportal.locale;
  const day = date.getDate();
  const month = date.toLocaleDateString(locale, { month: "long" });
  const year = date.getFullYear();
  const time = `${date.getHours()}:${("0" + date.getMinutes()).slice(-2)}`;
  return `${day} ${month} ${year}, ${time}`;
};

export const formatDate = (date) => {
  const locale = getStore().getState().user.data.kundeportal.locale;
  const day = date.getDate();
  const month = date.toLocaleDateString(locale, { month: "long" });
  const year = date.getFullYear();
  return `${day} ${month} ${year}`;
};

export const dateSort = (a, b, direction) => {
  if (direction === "desc") {
    if (a > b) return -1;
    if (a < b) return 1;
    return 0;
  }
  //asc
  if (a < b) return -1;
  if (a > b) return 1;
  return 0;
};

export const sortByDate = (a, b, direction) => {
  if (direction === "desc") {
    const aDate = new Date(a.fromDate);
    const bDate = new Date(b.fromDate);

    if (aDate > bDate) return -1;
    if (aDate < bDate) return 1;
    return 0;
  }
  //asc
  const aDate = new Date(a.fromDate);
  const bDate = new Date(b.fromDate);
  if (aDate < bDate) return -1;
  if (aDate > bDate) return 1;
  return 0;
};

export const monthFilter = (obj, timeframe, currentDate) => {
  const currentMonth = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    1,
  );
  const objMonth = new Date(obj.date.getFullYear(), obj.date.getMonth(), 1);
  return timeframe === TIMEFRAME.PAST
    ? currentMonth > objMonth
    : currentMonth <= objMonth;
};

export const transformUri = (uri) => {
  return uri ? uri.replace(".xml", "") : uri;
};

export const reverseTransformUri = (uri) => {
  return uri ? uri + ".xml" : uri;
};

export const reduceCommitteeName = (
  committeeReference,
  committeeReferenceTitle,
) => {
  return committeeReferenceTitle.replace(committeeReference + " -", "").trim();
};

export const makeOtherCommitteeIdIfEmpty = (committee) => {
  return committee || otherCommitteeId;
};

export const handleOtherCommitteeId = (committee) => {
  return committee === otherCommitteeId ? "" : committee;
};

export const trimFileExtension = (filename) => {
  return filename.split(".").slice(0, -1).join(".");
};

export const removeWrongCharacters = (str) => {
  return str
    .replace(/(Â|â€“|â€”|‰¥ |‰¤|â€œ|â€|<br \/>)/gm, "")
    .replace(/<[^>^\s]*>/gm, "");
};

export const ensureIsOfTypeBoolean = (data) => {
  if (typeof data === "string") {
    return data.toLowerCase() === "true";
  }

  return Boolean(data);
};
