import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import DatePicker from "react-datepicker";
import { getMeetingCommitteeFilterSelectItems } from "../../../../../state/values/selectors";
import SelectFilter from "../../../../common/select/SelectFilter";
import classNames from "classnames";
import Loader from "../../../../common/loader/Loader";
import MeetingFormDocumentsEditor from "../meeting-detail/MeetingFormDocumentsEditor";
import Editor from "../../../../common/editor/Editor";
import OptionalLabel from "../../../../common/optional-label/OptionalLabel";

class MeetingGeneralInfoEditor extends Component {
  setTitle = (e) => {
    this.props.setData({ title: e.target.value });
  };

  setCommittee = (value) => {
    this.props.setData({ committee: value });
  };

  setFromDate = (value) => {
    this.props.setData({ fromDate: value });
  };

  setToDate = (value) => {
    this.props.setData({ toDate: value });
  };

  setLocationShort = (e) => {
    this.props.setData({ locationShort: e.target.value });
  };

  setLocationLong = (value) => {
    this.props.setData({ locationLong: value });
  };

  showValidationMessage = (field) => {
    return (
      !this.props.validation[field].isValid &&
      !this.props.data[field] &&
      this.props.data[field].toString().trim() === ""
    );
  };

  render() {
    const {
      data: {
        title,
        committee,
        locationLong,
        locationShort,
        fromDate,
        toDate,
        past,
      },
      t,
      committees,
      showDocumentsEditor,
    } = this.props;

    const isLoading = !(committees && committees.length);

    if (isLoading) {
      return (
        <div className="tab-content">
          <Loader type="content" />
        </div>
      );
    }

    return (
      <div className="tab-content">
        <div>
          <div className="group group--first group--underlined">
            <SelectFilter
              label={t("meetings.detail.new.committeeLabel")}
              labelOnlyForScreenReaders={false}
              items={committees}
              selectedItem={committee}
              onChange={(value) => this.setCommittee(value)}
              customClasses={classNames({
                "input--validation_error":
                  this.showValidationMessage("committee"),
              })}
            />
            {this.showValidationMessage("committee") && (
              <span className="validation-message__error">
                {t("general.validation.notEmpty")}
              </span>
            )}
          </div>
          <div className="group group--underlined">
            <label className="field-label" htmlFor="meeting-title">
              {t("meetings.detail.new.titleLabel")}
            </label>
            <input
              type="text"
              name="meeting-title"
              id="meeting-title"
              placeholder={t("meetings.detail.new.titlePlaceholder")}
              className={classNames("input", {
                "input--validation_error": this.showValidationMessage("title"),
              })}
              value={title}
              onChange={(value) => this.setTitle(value)}
            />
            {this.showValidationMessage("title") && (
              <span className="validation-message__error">
                {t("general.validation.notEmpty")}
              </span>
            )}
          </div>
          <div className="group group--underlined">
            <div className="group__flex u-no-padding">
              <div className="group__flex-column">
                <label className="field-label" htmlFor="meeting-date-from">
                  {t("meetings.detail.new.fromDateLabel")}
                </label>
                <div className="datepicker">
                  <DatePicker
                    className={classNames("input", "input-date", {
                      "input--validation_error":
                        this.showValidationMessage("fromDate"),
                    })}
                    name="meeting-date-from"
                    id="meeting-date-from"
                    placeholderText="YYYY/MM/DD"
                    dateFormat="yyyy/MM/dd"
                    selected={fromDate}
                    onChange={this.setFromDate}
                    autoComplete="off"
                  />
                </div>
                <div className="datepicker">
                  <DatePicker
                    className={classNames("input", "input-time", {
                      "input--validation_error":
                        this.showValidationMessage("fromDate"),
                    })}
                    name="meeting-time-from"
                    id="meeting-time-from"
                    placeholderText="HH:MM"
                    selected={fromDate}
                    onChange={this.setFromDate}
                    showTimeSelect
                    showTimeSelectOnly
                    dateFormat="H:mm"
                    timeFormat="H:mm"
                    timeIntervals={30}
                    autoComplete="off"
                  />
                </div>
                {this.showValidationMessage("fromDate") && (
                  <span className="validation-message__error">
                    {t("general.validation.notEmpty")}
                  </span>
                )}
              </div>
              <div className="group__flex-column">
                <label className="field-label" htmlFor="meeting-date-from">
                  {t("meetings.detail.new.toDateLabel")}
                </label>
                <div className="datepicker">
                  <DatePicker
                    className={classNames("input", "input-date", {
                      "input--validation_error":
                        this.showValidationMessage("toDate"),
                    })}
                    name="meeting-date-to"
                    id="meeting-date-to"
                    placeholderText="YYYY/MM/DD"
                    dateFormat="yyyy/MM/dd"
                    selected={toDate}
                    minDate={fromDate || new Date()}
                    onChange={this.setToDate}
                    autoComplete="off"
                  />
                </div>
                <div className="datepicker">
                  <DatePicker
                    className={classNames("input", "input-time", {
                      "input--validation_error":
                        this.showValidationMessage("toDate"),
                    })}
                    name="meeting-time-to"
                    id="meeting-time-to"
                    placeholderText="HH:MM"
                    selected={toDate}
                    onChange={this.setToDate}
                    showTimeSelect
                    showTimeSelectOnly
                    dateFormat="H:mm"
                    timeIntervals={30}
                    timeFormat="H:mm"
                    autoComplete="off"
                  />
                </div>
                {this.showValidationMessage("toDate") && (
                  <span className="validation-message__error">
                    {t("general.validation.notEmpty")}
                  </span>
                )}
              </div>
            </div>
            {past && (
              <span className="validation-message__error">
                {t("meetings.detail.dateInPast")}
              </span>
            )}
          </div>
          <div className="group group--underlined">
            <label className="field-label" htmlFor="meeting-title">
              {t("meetings.detail.new.shortLocationLabel")}
            </label>
            <input
              type="text"
              name="meeting-short-location"
              id="meeting-short-location"
              className={classNames("input", {
                "input--validation_error":
                  this.showValidationMessage("locationShort"),
              })}
              placeholder={t("meetings.detail.new.shortLocationPlaceholder")}
              value={locationShort}
              onChange={(value) => this.setLocationShort(value)}
            />
            {this.showValidationMessage("locationShort") && (
              <span className="validation-message__error">
                {t("general.validation.notEmpty")}
              </span>
            )}
          </div>
          <div className="group group--underlined">
            <label
              className="field-label"
              htmlFor="meeting-long-location"
              style={{ marginBottom: 14 }}
            >
              {t("meetings.detail.new.longLocationLabel")}
              <OptionalLabel />
            </label>
            <Editor
              name="meeting-long-location"
              id="meeting-long-location"
              value={locationLong}
              onEditorChange={(value) => this.setLocationLong(value)}
            />
          </div>

          {showDocumentsEditor && (
            <div className="group group--last-underlined">
              <MeetingFormDocumentsEditor {...this.props} />
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  committees: getMeetingCommitteeFilterSelectItems(state),
});

export default compose(
  withTranslation("common"),
  connect(mapStateToProps, null),
)(MeetingGeneralInfoEditor);
