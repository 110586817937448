import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import Member from "./components/member/Member";
import {
  getMembers,
  isFetchingMembers,
} from "../../../state/members/selectors";
import { userDetails, isConsultant } from "../../../state/user/selectors";
import Loader from "../../common/loader/Loader";
import { setPageTitle } from "../../../utils/pageTitle";
import { Helmet } from "react-helmet";
import { selectedNationalCommittee } from "../../../state/national-committees/selectors";

import * as membersActions from "../../../state/members/actions";

class Members extends Component {
  sortMembers = (a, b) => {
    //split the names as strings into arrays
    const aName = a.name.split(" ");
    const bName = b.name.split(" ");

    // get the last names by selecting
    // the last element in the name arrays
    // using array.length - 1 since full names
    // may also have a middle name or initial
    const aLastName = aName[aName.length - 1];
    const bLastName = bName[bName.length - 1];

    // compare the names and return either
    // a negative number, positive number
    // or zero.
    if (aLastName < bLastName) return -1;
    if (aLastName > bLastName) return 1;
    return 0;
  };

  sortIsConsultant = (a, b) => {
    if (a.isConsultant) return -1;
    if (b.isConsultant) return 1;
    return 0;
  };

  sortIsChairman = (a, b) => {
    if (a.isChairman) return -1;
    if (b.isChairman) return 1;
    return 0;
  };

  sortIsProfessional = (a, b) => {
    if (a.isProfessional) return -1;
    if (b.isProfessional) return 1;
    return 0;
  };

  get orderedMembers() {
    const { members } = this.props;
    if (!members) {
      return null;
    }

    return members
      .sort(this.sortMembers)
      .sort(this.sortIsChairman)
      .sort(this.sortIsProfessional)
      .sort(this.sortIsConsultant);
  }

  filterActiveMembers(members) {
    if (!members) {
      return null;
    }

    return members.filter((m) => m.isActive);
  }

  onChangeMemberActivState(memberId, newState) {
    this.props.setMemberActiveState({
      userId: memberId,
      state: newState,
    });
  }

  renderMembers(members) {
    const { t, user } = this.props;

    if (!members) {
      return (
        <p key="nothing_to_show" className="headline-4">
          {t("general.content.nothingToShow")}
        </p>
      );
    }

    return members.map((member, index) => {
      if (user && user.email === member.email) {
        return (
          <Member
            key={index}
            index={index}
            name={user.name}
            photo={user.profileImage}
            email={user.email}
            work={user.company}
            linkedIn={user.linkedIn}
            phone={user.phone}
            dslist={member.dslist}
            memberSince={member.memberSince}
            isConsultant={member.isConsultant}
            isChairman={member.isChairman}
            lastActivityDate={member.lastActivityDate}
            isActive={member.isActive}
            changeActiveState={() =>
              this.onChangeMemberActivState(member.userID, !member.isActive)
            }
          />
        );
      }
      return (
        <Member
          key={index}
          index={index}
          changeActiveState={() =>
            this.onChangeMemberActivState(member.userID, !member.isActive)
          }
          {...member}
        />
      );
    });
  }

  renderMailtoLink(members) {
    const { t } = this.props;
    if (!members) return null;
    const addresses = members.map((member) => member.email).join(";");
    return (
      <div className="u-align-right">
        <a
          href={`mailto:${addresses}`}
          className="button button--link sidebar-filter__link"
        >
          {t("member.sendEmailLabel")}
        </a>
      </div>
    );
  }

  render() {
    const { t, isFetchingMembers, selectedNationalCommittee, isConsultant } =
      this.props;
    const members = this.orderedMembers;
    const filteredMembers = this.filterActiveMembers(members);
    if (isFetchingMembers) {
      return <Loader />;
    }

    return (
      <Fragment>
        <Helmet>
          <title>{setPageTitle(["Members"], selectedNationalCommittee)}</title>
        </Helmet>
        <header className="layout__title">
          <h2 className="headline-2">{t("member.header.name")}</h2>
        </header>

        <div className="layout__content">
          {this.renderMailtoLink(filteredMembers)}

          <div className="u-flex-wrap">
            {this.renderMembers(isConsultant ? members : filteredMembers)}
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapDispatchToProps = {
  setMemberActiveState: membersActions.setMemberActiveState,
};

const mapStateToProps = (state) => ({
  members: getMembers(state),
  user: userDetails(state),
  isConsultant: isConsultant(state),
  isFetchingMembers: isFetchingMembers(state),
  selectedNationalCommittee: selectedNationalCommittee(state),
});

export default compose(
  withTranslation("common"),
  connect(mapStateToProps, mapDispatchToProps),
)(Members);
