import React, { Component } from "react";
import { compose } from "redux";
import { withTranslation } from "react-i18next";

import "./RelatedContent.scss";
import { getDeadlineUrl } from "../../../api/resources/deadlines";
import DeadlineTable from "../deadlines/pages/deadline-list/DeadlineTable";
import { normalizeDeadline } from "../../../state/deadlines/normalizer";

class RelatedDeadlineContent extends Component {
  state = {
    fetchedDeadlines: [],
  };

  async fetchRelatedDeadlines() {
    const { relatedDeadlines } = this.props;

    if (!relatedDeadlines?.length) return;

    try {
      const rawResponses = await Promise.all(
        relatedDeadlines.map((deadline) => {
          return getDeadlineUrl(deadline.uri);
        }),
      );

      const responses = rawResponses
        .filter((response) => response.status === 200)
        .map((response) => normalizeDeadline({ deadline: response.data }));

      this.setState({
        fetchedDeadlines: responses,
      });
    } catch (error) {
      console.error(error);
    }
  }

  async componentDidMount() {
    await this.fetchRelatedDeadlines();
  }

  render() {
    const { t, relatedDeadlines } = this.props;

    if (!relatedDeadlines?.length) return null;

    return (
      <div className="related-content">
        <div className="group group--small group__flex detail__group group--underlined">
          <span className="headline-4 u-text-bold" id="documents">
            {t("references.document.relatedDeadlines")}
          </span>
        </div>
        <DeadlineTable deadlines={this.state.fetchedDeadlines} />
      </div>
    );
  }
}

// const mapProps = (state) => ({});

// const mapDispatchToProps = {};

export default compose(
  withTranslation("common"),
  // connect(mapProps, mapDispatchToProps),
)(RelatedDeadlineContent);
