import classNames from "classnames";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";
import { getUserSetting } from "../../../state/user/selectors";
import { updateUserEmailSetting } from "../../../state/user/actions";
import { getEmailFrequencies } from "../../../state/settings/selectors";

class EmailNotificationSettings extends Component {
  state = {
    frequency: null,
  };

  componentDidMount() {
    const { userSetting, committeeId } = this.props;
    if (userSetting[committeeId]) {
      this.setState({
        frequency: userSetting[committeeId].emailSetting.toLowerCase(),
      });
    }
  }

  componentDidUpdate(prevProps) {
    const { userSetting, committeeId } = this.props;

    if (
      userSetting &&
      prevProps.userSetting[committeeId].emailSetting !==
        userSetting[committeeId].emailSetting
    ) {
      this.setState({
        frequency: userSetting[committeeId].emailSetting.toLowerCase(),
      });
    }
  }

  onEmailsFrequencyClick = (element) => {
    const { updateUserEmailSetting, committeeId } = this.props;

    this.setState({ frequency: element }, () => {
      updateUserEmailSetting({
        selectedCommittee: committeeId,
        emailSetting: element,
      });
    });
  };

  generateField = (element, index) => {
    const { frequency } = this.state;
    const { t, committeeId } = this.props;

    return (
      <div key={index} className="group group--field radio">
        <label
          htmlFor={`input_${committeeId}-${element}-${index}`}
          className="inline-checkbox"
        >
          <input
            type="radio"
            name={`frequency[${committeeId}-${index}]`}
            id={`input_${committeeId}-${element}-${index}`}
            value={element}
            checked={frequency === element}
            className="inline-checkbox__input"
            onChange={() => this.onEmailsFrequencyClick(element)}
          />
          <span className="inline-checkbox__input--off icon icon-radio-off"></span>
          <span className="inline-checkbox__input--on icon icon-radio-on"></span>
          <span className="field-label">
            {t(`settings.emailFrequency.${element}.label`)}
          </span>
          <span className="u-text-light">
            {t(`settings.emailFrequency.${element}.description`)}
          </span>
        </label>
      </div>
    );
  };

  render() {
    const { t, emailsFrequencies } = this.props;

    return (
      <div>
        <div className={classNames("group", "group--first", "email-setting")}>
          {emailsFrequencies.map((element, index) =>
            this.generateField(element.toLowerCase(), index),
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  userSetting: getUserSetting(state),
  emailsFrequencies: getEmailFrequencies(state),
});

const mapDispatchToProps = {
  updateUserEmailSetting: updateUserEmailSetting,
};

export default compose(
  withTranslation("common"),
  connect(mapStateToProps, mapDispatchToProps),
)(EmailNotificationSettings);
