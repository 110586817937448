import React, { Component, Fragment } from "react";
import { withTranslation } from "react-i18next";

import "./SidebarFilter.scss";

class SidebarFilter extends Component {
  render() {
    // instead set variables to whatever is returned from the api in the future
    const { t, children, submitHandler, resetFilters, isFiltered } = this.props;

    return (
      <Fragment>
        {children}
        <button
          onClick={submitHandler}
          className="button button--secondary button--full-width"
        >
          {t("filter.button.apply")}
        </button>
        {isFiltered && (
          <button
            onClick={resetFilters}
            className="button button--link button--full-width"
          >
            {t("filter.button.reset")}
          </button>
        )}
      </Fragment>
    );
  }
}

export default withTranslation("common")(SidebarFilter);
