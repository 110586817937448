import { createAction } from "redux-actions";

export const GET_NATIONAL_COMMITTEES =
  "NATIONAL_COMMITTEE/get_national_committees";
export const GET_NATIONAL_COMMITTEES_SUCCEEDED =
  "NATIONAL_COMMITTEE/get_national_committees_succeeded";
export const GET_NATIONAL_COMMITTEES_FAILED =
  "NATIONAL_COMMITTEE/get_national_committees_failed";

export const SET_SELECTED_NATIONAL_COMMITTEE =
  "NATIONAL_COMMITTEE/set_selected_national_committee";
export const SET_SELECTED_NATIONAL_COMMITTEE_SUCCEEDED =
  "NATIONAL_COMMITTEE/set_selected_national_committee_succeeded";
export const SET_SELECTED_NATIONAL_COMMITTEE_FAILED =
  "NATIONAL_COMMITTEE/set_selected_national_committee_failed";
export const GET_ASSOCIATED_COMMITTEES_BY_COMMITTEE_ID =
  "NATIONAL_COMMITTEE/get_associated_committees_by_committee_id";
export const GET_ASSOCIATED_COMMITTEES =
  "NATIONAL_COMMITTEE/get_associated_committees";
export const GET_ASSOCIATED_COMMITTEES_SUCCEEDED =
  "NATIONAL_COMMITTEE/get_accociated_committees_succeeded";
export const GET_ASSOCIATED_COMMITTEES_FAILED =
  "NATIONAL_COMMITTEE/get_associated_committees_failed";

export const GET_COMMITTEES_LIST = "NATIONAL_COMMITTEE/get_committees_list";
export const GET_COMMITTEES_SUCCEEDED_LIST =
  "NATIONAL_COMMITTEE/get_committees_succeeded_list";
export const GET_COMMITTEES_FAILED_LIST =
  "NATIONAL_COMMITTEE/get_committees_failed_list";

export const SET_LAST_COMMITTEE = "NATIONAL_COMMITTEE/set_last_committee";
export const SET_LAST_COMMITTEE_SUCCEEDED =
  "NATIONAL_COMMITTEE/set_last_committee_succeeded";
export const SET_LAST_COMMITTEE_FAILED =
  "NATIONAL_COMMITTEE/set_last_committee_failed";

export const getNationalCommittees = createAction(GET_NATIONAL_COMMITTEES);
export const getNationalCommitteesSucceeded = createAction(
  GET_NATIONAL_COMMITTEES_SUCCEEDED,
);
export const getNationalCommitteesFailed = createAction(
  GET_NATIONAL_COMMITTEES_FAILED,
);

export const setSelectedNationalCommittee = createAction(
  SET_SELECTED_NATIONAL_COMMITTEE,
);
export const setSelectedNationalCommitteeSucceeded = createAction(
  SET_SELECTED_NATIONAL_COMMITTEE_SUCCEEDED,
);
export const setSelectedNationalCommitteeFailed = createAction(
  SET_SELECTED_NATIONAL_COMMITTEE_FAILED,
);

export const getAssociatedCommitteesByCommitteeId = createAction(
  GET_ASSOCIATED_COMMITTEES_BY_COMMITTEE_ID,
);

export const getAssociatedCommittees = createAction(GET_ASSOCIATED_COMMITTEES);
export const getAssociatedCommitteesSucceeded = createAction(
  GET_ASSOCIATED_COMMITTEES_SUCCEEDED,
);
export const getAssociatedCommitteesFailed = createAction(
  GET_ASSOCIATED_COMMITTEES_FAILED,
);

export const saveLastCommitteeSucceeded = createAction(
  SET_LAST_COMMITTEE_SUCCEEDED,
);
export const saveLastCommitteeFailed = createAction(SET_LAST_COMMITTEE_FAILED);

export const getCommitteesList = createAction(GET_COMMITTEES_LIST);
export const getCommitteesListSucceeded = createAction(
  GET_COMMITTEES_SUCCEEDED_LIST,
);
export const getCommitteesListFailed = createAction(GET_COMMITTEES_FAILED_LIST);
