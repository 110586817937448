import { handleActions, combineActions } from "redux-actions";
import * as actions from "./actions";
import {
  normalizeVotes,
  normalizeVote,
  transformVotesToStatistics,
  transformDeadlineVotesToStatistics,
  removeDeadlineVotesFromStatistics,
  normalizeOverride,
} from "./normalizer";

const initialState = {
  isFetching: false,
  isFetched: true,
  data: {},
  overrides: {},
  statistics: {},
  updateVoteInProgress: false,
};

const actionHandlers = {
  [actions.initDeadlineVotes]: (state) => ({
    ...state,
    isFetching: true,
    isFetched: false,
  }),
  [actions.initVotesSucceeded]: (state, { payload }) => {
    const votes = normalizeVotes(payload.votes);
    return {
      ...state,
      isFetching: false,
      isFetched: true,
      data: { ...state.data, ...votes },
      overrides: {
        ...state.overrides,
        [payload.officialVoteOverride.deadlineId]: normalizeOverride(
          payload.officialVoteOverride,
        ),
      },
      statistics: {
        ...state.statistics,
        ...transformVotesToStatistics(votes),
      },
    };
  },
  [actions.getAllVotes]: (state) => ({
    ...state,
    isFetching: true,
    isFetched: false,
  }),

  [actions.getAllVotesSucceeded]: (state, { payload }) => {
    const votes = normalizeVotes(payload);
    return {
      ...state,
      isFetching: false,
      isFetched: true,
      data: { ...state.data, ...votes },
      statistics: {
        ...state.statistics,
        ...transformVotesToStatistics(votes),
      },
    };
  },
  [combineActions(actions.createVote, actions.updateVote, actions.removeVote)]:
    (state) => ({
      ...state,
      updateVoteInProgress: true,
    }),
  [actions.castNewVoteSucceeded]: (state, { payload }) => {
    let data = state.data;
    if (!data[payload.deadlineUri]) data[payload.deadlineUri] = [];
    data[payload.deadlineUri].push(normalizeVote(payload));

    return {
      ...state,
      data: {
        ...data,
      },
      statistics: {
        ...state.statistics,
        [payload.deadlineUri]: transformDeadlineVotesToStatistics(
          data[payload.deadlineUri],
        ),
      },
      updateVoteInProgress: false,
    };
  },

  [actions.updateVoteSucceeded]: (state, { payload }) => {
    return {
      ...state,
      data: {
        ...state.data,
        [payload.deadlineUri]: state.data[payload.deadlineUri].map((item) =>
          item.userID === payload.memberId ? normalizeVote(payload) : item,
        ),
      },
      statistics: {
        ...state.statistics,
        [payload.deadlineUri]: transformDeadlineVotesToStatistics(
          state.data[payload.deadlineUri].map((item) =>
            item.userID === payload.memberId ? normalizeVote(payload) : item,
          ),
        ),
      },
      updateVoteInProgress: false,
    };
  },
  [actions.removeVoteSucceeded]: (state, { payload }) => {
    return {
      ...state,
      data: {
        ...state.data,
        [payload.deadlineUri]: state.data[payload.deadlineUri].filter(
          (d) => d.uri !== payload.uri,
        ),
      },
      statistics: removeDeadlineVotesFromStatistics(state, payload),
      updateVoteInProgress: false,
    };
  },
  [actions.overrideOfficialVoteSucceeded]: (state, { payload }) => ({
    ...state,
    overrides: {
      ...state.overrides,
      [payload.deadlineId]: normalizeOverride(payload),
    },
  }),
  [actions.initVotesMemberNamesSucceeded]: (state, { payload }) => ({
    ...state,
    data: {
      ...state.data,
      [payload.deadlineUri]: state.data[payload.deadlineUri].map((item) =>
        item.userID === payload.userID ? payload : item,
      ),
    },
  }),
};

export default handleActions(actionHandlers, initialState);
