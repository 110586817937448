import React, { Fragment, Component } from "react";
import className from "classnames";
import { connect } from "react-redux";
import { compose } from "redux";
import { v4 as uuidv4 } from "uuid";
import ActionBox from "../action-box/ActionBox";
import Toggler from "../toggler/Toggler";
import { getDateString } from "../../../utils/transform";
import { withTranslation } from "react-i18next";
import Checkbox from "../form/checkbox/Checkbox";
import WithPrivileges from "../with-privileges/WithPrivileges";
import ReadonlyGuard from "../readonly-guard/ReadonlyGuard";
import { shouldRenderDocumentId } from "../../../utils/documentUtils";
import { getDocumentValues } from "../../../state/values/selectors";
import { otherCommitteeId, dialogSizes } from "../../../utils/constants";
import RelatedContent from "../../pages/related-content/RelatedContent";
import * as appActions from "../../../state/app/actions";
import FileDescription from "./FileDescription";
import { DOCUMENT_TABLE_COLUMNS, isColumnDisabled } from "./List";

class Row extends Component {
  constructor(props) {
    super(props);

    this.state = {
      actionBoxOpened: false,
    };

    this.onShowRelatedContent = this.onShowRelatedContent.bind(this);
  }

  get typeLabel() {
    const type = this.props.element.type;

    if (!this.props.documentValues) {
      return type;
    }

    if (this.props.documentValues.typesIndex.hasOwnProperty(type)) {
      return this.props.documentValues.typesIndex[type];
    }

    return type;
  }

  get actionLabel() {
    const action = this.props.element.action;

    if (!this.props.documentValues) {
      return action;
    }

    if (this.props.documentValues.actionsIndex.hasOwnProperty(action)) {
      return this.props.documentValues.actionsIndex[action];
    }

    return action;
  }

  renderDocumentCommittee(element) {
    if (element.committee === otherCommitteeId) {
      return <span>{this.props.t("general.defaults.otherCommittee")}</span>;
    }
    return <span>{element.committee}</span>;
  }

  onShowRelatedContent() {
    const { applicationPushGlobalDialog, applicationCloseGlobalDialog } =
      this.props;

    const modalId = uuidv4();
    const closeModal = () => {
      applicationCloseGlobalDialog(modalId);
    };

    applicationPushGlobalDialog({
      id: modalId,
      component: RelatedContent,
      modalSettings: {
        allowEscape: true,
        sizeType: dialogSizes.wide,
        closeButton: true,
      },
      props: { document: this.props.element, closeModal: closeModal },
    });
  }

  renderActionBox() {
    const { element } = this.props;
    const documentActions = this.props.documentActions();

    return (
      <td className="last">
        <Toggler
          calculatePosition={true}
          clickHandler={() => this.setState({ actionBoxOpened: false })}
          isLast={element.isLast}
        >
          <ActionBox>
            {Object.keys(documentActions).map((name, index) => {
              return (
                <li key={index} className="list__element">
                  <button
                    className="button button--link actions-list__button"
                    onClick={() => documentActions[name](element)}
                  >
                    {name}
                  </button>
                </li>
              );
            })}
          </ActionBox>
        </Toggler>
      </td>
    );
  }

  render() {
    const {
      element,
      selectHandler,
      showActionBox,
      enableSelection,
      disabledColumns,
    } = this.props;
    const { hideDueDate, showConnections, disabled } = this.props;

    /* TODO: check if element.url is a correct URL */
    return (
      <Fragment>
        {enableSelection && (
          <WithPrivileges>
            <td className="id">
              <Checkbox element={element} onClick={selectHandler} />
            </td>
          </WithPrivileges>
        )}
        {!isColumnDisabled(
          disabledColumns,
          DOCUMENT_TABLE_COLUMNS.COMMITTEE,
        ) && (
          <td className="committee">
            {this.renderDocumentCommittee(element)}
            {shouldRenderDocumentId(element.id) && (
              <span className="text-small">{element.id}</span>
            )}
          </td>
        )}
        {!isColumnDisabled(disabledColumns, DOCUMENT_TABLE_COLUMNS.title) && (
          <td className="name fileinfo">
            {disabled && (
              <span className="filename filename--disabled text-small">
                {element.title}
              </span>
            )}
            <FileDescription
              description={element.description}
              isDisabled={disabled}
              downloadUrl={element.downloadUrl}
              title={element.title}
            />
          </td>
        )}
        {!isColumnDisabled(disabledColumns, DOCUMENT_TABLE_COLUMNS.TYPE) && (
          <td className="action">
            <span>{this.actionLabel}</span>
            <span className="type text-small">{this.typeLabel}</span>
          </td>
        )}
        {showConnections && (
          <td>
            {element.relatedContent && (
              <button
                className={className("button button--link button--vcenter", {
                  "button--link-disabled": element.relatedContent.length === 0,
                })}
                onClick={this.onShowRelatedContent}
              >
                <span
                  className="icon icon-reference icon--right-margin"
                  aria-hidden="true"
                ></span>

                {element.relatedContent.length}
              </button>
            )}
          </td>
        )}
        {!isColumnDisabled(disabledColumns, DOCUMENT_TABLE_COLUMNS.DUE_DATE) &&
          !hideDueDate && (
            <td className="date">{getDateString(element.dueDate)}</td>
          )}
        {!isColumnDisabled(disabledColumns, DOCUMENT_TABLE_COLUMNS.DATE) && (
          <td className="date">{getDateString(element.date)}</td>
        )}
        {showActionBox && (
          <WithPrivileges>
            <ReadonlyGuard>{this.renderActionBox()}</ReadonlyGuard>
          </WithPrivileges>
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  documentValues: getDocumentValues(state),
});

const mapDispatchToProps = {
  applicationPushGlobalDialog: appActions.applicationPushGlobalDialog,
  applicationCloseGlobalDialog: appActions.applicationCloseGlobalDialog,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation("common"),
)(Row);
