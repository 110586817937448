import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withTranslation } from "react-i18next";

import ModalWrapper from "../modal-wrapper/ModalWrapper";
import * as applicationActions from "../../../state/app/actions";
import * as applicationSelectors from "../../../state/app/selectors";

import { emptyModalStack } from "../../../state/modal-stack/actions";
import { v4 } from "uuid";
import SearchCommitteeComponent from "../search-committee-component/SearchCommitteeComponent";

class GlobalDialogs extends Component {
  onModalClose(dialog) {
    this.props.emptyModalStack();
    if (dialog.modalSettings.allowEscape)
      this.props.applicationCloseGlobalDialog(dialog.id);
  }

  render() {
    const { globalDialogsStack } = this.props;
    return globalDialogsStack.map((d) => {
      const key = d.id ?? v4();

      return (
        <ModalWrapper
          key={key}
          isOpen={true}
          requestCloseHandler={() => this.onModalClose(d)}
          sizeType={d.modalSettings.sizeType}
          showCloseButton={d.modalSettings.closeButton}
          shouldCloseOnOverlayClick={true}
          disableSlide={d.disableSlide}
          // disableSlide
        >
          {React.createElement(d.component, {
            id: key,
            ...d.props,
          })}
        </ModalWrapper>
      );
    });
  }
}

const mapDispatchToProps = {
  applicationPushGlobalDialog: applicationActions.applicationPushGlobalDialog,
  applicationCloseGlobalDialog: applicationActions.applicationCloseGlobalDialog,

  emptyModalStack: emptyModalStack,
};

const mapStateToProps = (state) => ({
  globalDialogsStack: applicationSelectors.appGlobalDialogsStack(state),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation("common"),
)(GlobalDialogs);
