import { addQueryParams, getProxyUrl } from "../utils";
import { api } from "./../api";
import {
  mapCommentToQuery,
  mapCommentToPatchQuery,
  mapCommentElementsToPatchQuery,
} from "../mappers/comment";
import { transformCommitteeName } from "../../utils/transform";
export function getComments(deadlineUri) {
  let proxyUrl = getProxyUrl(`/Comments/comments/${deadlineUri}`);
  return api.get(proxyUrl);
}

export function getComment(commentUri) {
  const proxyUrl = getProxyUrl(`/Comments/comment/${commentUri}`);
  return api.get(proxyUrl);
}

export function updateComment(comment) {
  let proxyUrl = getProxyUrl(`/Comments/comment/${comment.uri}`);
  proxyUrl = addQueryParams(proxyUrl, mapCommentToPatchQuery(comment));
  let content = mapCommentElementsToPatchQuery(comment);
  return api.patch(proxyUrl, JSON.stringify(content));
}

export function deleteComment(commentUri) {
  const proxyUrl = getProxyUrl(`/Comments/comment/${commentUri}`);
  return api.delete(proxyUrl);
}

export function createComment(committee, data) {
  const proxyUrl = getProxyUrl(
    `/Comments/comment/${committee}/${data.deadlineUri}`,
  );
  return api.post(proxyUrl, mapCommentToQuery(data));
}

export function getDeadlineCommentsStatistics(deadlineUri) {
  const proxyUrl = getProxyUrl(`/Comments/statistics/${deadlineUri}`);
  return api.get(proxyUrl);
}

export function exportCommentsToWord(committee, deadlineUri) {
  const proxyUrl = getProxyUrl(
    `/Comments/export/${transformCommitteeName(committee)}/${deadlineUri}`,
  );
  return api.get(proxyUrl, { responseType: "blob" });
}
