import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withTranslation, Trans } from "react-i18next";

import { selectedNationalCommittee } from "../../../state/national-committees/selectors";

class DeadlineFromDocumentHint extends Component {
  render() {
    return (
      <>
        <div className="group group--first group--underlined">
          <h3 className="headline-4">
            {this.props.t("userHints.deadlinesList.title")}
          </h3>
        </div>
        <div className="group group--underlined">
          <p>
            <Trans i18nKey="common:userHints.deadlinesList.hintMessage">
              Comments and votes should be provided at least 14 days prior to
              the deadline. This ensures time for the committee to assess,
              process and finalize the official comments from Dansk Standard.
              Comments received later than 14 days prior to the deadline are not
              guaranteed to be included in the comments from Dansk Standard.
            </Trans>
          </p>
        </div>
      </>
    );
  }
}

const mapDispatchToProps = {};

const mapStateToProps = (state) => ({
  selectedNationalCommittee: selectedNationalCommittee(state),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation("common"),
)(DeadlineFromDocumentHint);
