import { voteType } from "../../utils/constants";

export function calculateReviewVotes(voteStats) {
  if (!voteStats || voteStats.totalReview === 0) {
    return voteType.abstain;
  }

  const hasAbstaiVotes = voteStats.abstain > 0;
  const hasConfirmVotes = voteStats.confirm > 0;
  const hasReviseVotes = voteStats.revise > 0;
  const hasWithdrawVotes = voteStats.withdraw > 0;

  const hasOnlyAbstaiVotes =
    hasAbstaiVotes && !hasConfirmVotes && !hasReviseVotes && !hasWithdrawVotes;
  const hasOnlyConfirmVotes =
    hasConfirmVotes && !hasReviseVotes && !hasWithdrawVotes;
  const hasOnlyReviseVotes =
    !hasConfirmVotes && hasReviseVotes && !hasWithdrawVotes;
  const hasOnlyWithdrawVotes =
    !hasConfirmVotes && !hasReviseVotes && hasWithdrawVotes;

  if (hasOnlyAbstaiVotes) {
    return voteType.abstain;
  }

  if (hasOnlyConfirmVotes) {
    return voteType.confirm;
  }

  if (hasOnlyReviseVotes) {
    return voteType.revise;
  }

  if (hasOnlyWithdrawVotes) {
    return voteType.withdraw;
  }

  return voteType.abstain;
}

export function calculateRegularVotes(voteStats) {
  if (!voteStats || voteStats.total === 0) {
    return voteType.abstain;
  }

  const isYesInMajority =
    voteStats.yes > voteStats.no && voteStats.yes >= voteStats.abstain;
  const isNoInMajority =
    voteStats.no > voteStats.yes && voteStats.no >= voteStats.abstain;
  const isAbstainInMajority =
    voteStats.abstain > voteStats.no && voteStats.abstain > voteStats.yes;

  if (isAbstainInMajority) {
    if (voteStats.no === 0 && voteStats.yes > 0) {
      return voteType.yes;
    }

    if (voteStats.yes === 0 && voteStats.no > 0) {
      return voteType.no;
    }

    return voteType.abstain;
  }

  if (isYesInMajority) {
    if (voteStats.no === 0) {
      return voteType.yes;
    }

    return voteType.abstain;
  }

  if (isNoInMajority) {
    if (voteStats.yes === 0) {
      return voteType.no;
    }

    return voteType.abstain;
  }

  return voteType.abstain;
}

export function officialVoteCalculator(voteStats, inReview) {
  if (inReview) {
    return calculateReviewVotes(voteStats);
  }

  return calculateRegularVotes(voteStats);
}
