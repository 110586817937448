import React, { Component, Fragment } from "react";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import NotificationBar from "../notification-bar/NotificationBar";
import NotificationCardHeader from "../notification-card-header/NotificationCardHeader";
import CardContent from "../../../meetings/components/card-content/CardContent";
import CardAttachments from "../../../../common/card/CardAttachments";
import { initNotifications } from "../../../../../state/notifications/actions";
import { connect } from "react-redux";
import Loader from "../../../../common/loader/Loader";
import {
  generateDialogLink,
  DIALOG_TYPES,
} from "../../../../../utils/dynamicLink";
import { formatEventDate } from "../../../../../utils/transform";
import { selectedNationalCommittee } from "../../../../../state/national-committees/selectors";

import { notificationActionType } from "../../../../../utils/constants";

class MeetingNotification extends Component {
  get notificationTitle() {
    const { t } = this.props;
    const { action } = this.props.data;

    switch (action) {
      case notificationActionType.add:
        return t("common:notifications.types.meeting.add");
      case notificationActionType.reminder:
        return t("common:notifications.types.meeting.reminder");
      case notificationActionType.update:
        return t("common:notifications.types.meeting.update");
      case notificationActionType.weekReminder:
        return t("common:notifications.types.meeting.weekReminder");
      default:
        return t("common:notifications.types.meeting.update");
    }
  }

  render() {
    const {
      data: {
        messages,
        comments,
        details,
        contentId,
        notificationId,
        eventDate,
      },
      initNotifications,
    } = this.props;

    const meetingDetailsUrl = generateDialogLink(
      DIALOG_TYPES.MEETING_DETAILS,
      contentId,
    );

    return details ? (
      <Fragment key={notificationId}>
        <NotificationBar
          title={this.notificationTitle}
          date={eventDate}
          notificationMenu={{
            committee: details.committee,
            notificationId: notificationId,
          }}
        />

        <NotificationCardHeader
          date={details.date}
          committee={details.committee}
          committeeName={details.committeeName}
          title={details.title}
          uri={meetingDetailsUrl}
          notificationId={notificationId}
          callbackAction={initNotifications}
        />

        <CardContent
          date={formatEventDate(details.fromDate)}
          location={details.locationShort}
        />

        <CardAttachments
          uri={meetingDetailsUrl}
          references={details.references && details.references.length}
          comments={comments && comments.length}
          messages={messages && messages.length}
        />
      </Fragment>
    ) : (
      <Loader />
    );
  }
}

const mapStateToProps = (state) => ({
  selectedNationalCommittee: selectedNationalCommittee(state),
});

const mapDispatchToProps = {
  initNotifications,
};

export default compose(
  withTranslation("common"),
  connect(mapStateToProps, mapDispatchToProps),
)(MeetingNotification);
