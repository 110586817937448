import { createAction } from "redux-actions";

export const GET_COMMITTEE_MEMBERS = "MEMBERS/get_committee_members";
export const GET_COMMITTEE_MEMBERS_SUCCEEDED =
  "MEMBERS/get_committee_members_succeeded";
export const GET_COMMITTEE_MEMBERS_FAILED =
  "MEMBERS/get_committee_members_failed";

export const getCommitteeMembers = createAction(GET_COMMITTEE_MEMBERS);
export const getCommitteeMembersSucceeded = createAction(
  GET_COMMITTEE_MEMBERS_SUCCEEDED,
);
export const getCommitteeMembersFailed = createAction(
  GET_COMMITTEE_MEMBERS_FAILED,
);

export const SET_MEMBER_ACTIVE_STATE = "MEMBERS/set_active_state";
export const SET_MEMBER_ACTIVE_STATE_SUCCEEDED =
  "MEMBERS/set_active_state_succeeded";
export const SET_MEMBER_ACTIVE_STATE_FAILED = "MEMBERS/set_active_state_failed";

export const setMemberActiveState = createAction(SET_MEMBER_ACTIVE_STATE);
export const setMemberActiveStateSucceeded = createAction(
  SET_MEMBER_ACTIVE_STATE_SUCCEEDED,
);
export const setMemberActiveStateFailed = createAction(
  SET_MEMBER_ACTIVE_STATE_FAILED,
);
