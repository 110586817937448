import { handleOtherCommitteeId } from "../../utils/transform";

export function mapToApiMetadataModel(document, uri) {
  const committee = handleOtherCommitteeId(document.committee);

  return {
    nationalCommittees: [
      {
        committeeReference: committee,
        committeeTitle: document.committeeTitle,
        internationalCommitteeMembership: "",
      },
    ],
    metadata: {
      documentId: document.id ? Number.parseInt(document.id) : 0,
      documentTitle: document.title,
      documentDescription: document.description,
      documentType: document.type,
      committeeReference: committee,
      documentAction: document.action,
      documentDeadline: document.dueDate,
      documentMimeType: document.mimeType,
      memberID: document.memberID,
    },
    references: [
      {
        uri,
        fileName: document.filename,
        link: document.link,
      },
    ],
  };
}

export function mapDocumentToPatchQuery(document) {
  const toEncodedIsoString = (date) =>
    date ? encodeURIComponent(date.toISOString()) : null;
  const toEncodedUri = (value) =>
    value !== null ? encodeURIComponent(value) : null;
  const committee = handleOtherCommitteeId(document.committee);

  return {
    fileName: toEncodedUri(document.filename),
    title: toEncodedUri(document.title),
    committeeReference: toEncodedUri(committee),
    documentId: toEncodedUri(document.id ? Number.parseInt(document.id) : 0),
    action: toEncodedUri(document.action),
    actionDueDate: toEncodedIsoString(document.dueDate),
    documentType: toEncodedUri(document.type),
    notify: toEncodedUri(document.notifyOnUpdate),
    description: toEncodedUri(document.description),
  };
}
