import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { Link } from "react-router-dom";
import { withTranslation, Trans } from "react-i18next";

import { selectedNationalCommittee } from "../../../state/national-committees/selectors";
import { generateLink } from "../../../utils/dynamicLink";

class DeadlineFromDocumentHint extends Component {
  render() {
    const { selectedNationalCommittee } = this.props;
    return (
      <>
        <div className="group group--first group--underlined">
          <h3 className="headline-4">
            {this.props.t("userHints.deadlineFromDocument.title")}
          </h3>
        </div>
        <div className="group group--underlined">
          <p>
            <Trans i18nKey="common:userHints.deadlineFromDocument.hintMessage">
              To create a deadline based on a document, go to
              <Link
                to={`/${generateLink(selectedNationalCommittee, "documents")}`}
              >
                Documents
              </Link>
              and upload the document. Once you have uploaded the document, you
              can select the document and press “Create deadline”. Then metadata
              is automatically transferred from the document to the deadline you
              want to create.
            </Trans>
          </p>
        </div>
      </>
    );
  }
}

const mapDispatchToProps = {};

const mapStateToProps = (state) => ({
  selectedNationalCommittee: selectedNationalCommittee(state),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation("common"),
)(DeadlineFromDocumentHint);
