import { TIMEFRAME } from "../../api/utils";
import { comparisonOperator, deadlineSortType } from "../../utils/constants";
import { addHours } from "date-fns";

//TODO refactor this file, it is same as src/services/meetings

export const sortingType = {
  deadline_descending: "deadline_desc",
  deadline_ascending: "deadline_asc",
};

const getCurrentDayStartDate = () => {
  const currentDate = new Date();
  currentDate.setDate(currentDate.getDate() - 1);
  currentDate.setHours(0, 0, 0, 0);
  return currentDate;
};

export const buildQueryParams = (memberId, filters) => {
  const queryParams = {
    sort: deadlineSortType.asc,
  };

  if (filters.timeframe === TIMEFRAME.PAST) {
    queryParams.sort = deadlineSortType.desc;
    queryParams.actionDueDate =
      comparisonOperator.lt +
      addHours(new getCurrentDayStartDate(), 2).toISOString();
  } else if (filters.timeframe === TIMEFRAME.FUTURE) {
    queryParams.sort = deadlineSortType.asc;
    queryParams.actionDueDate =
      comparisonOperator.ge +
      addHours(getCurrentDayStartDate(), 2).toISOString();
  }

  if (filters.month) {
    const monthStart = new Date(filters.month);
    const nextMonth = new Date(filters.month);
    nextMonth.setMonth(nextMonth.getMonth() + 1);

    queryParams.actionDueDate = [
      comparisonOperator.ge + monthStart.toISOString(),
      comparisonOperator.lt + nextMonth.toISOString(),
    ];
  }

  if (filters.committee) {
    queryParams.committeeReference = filters.committee;
  }

  if (filters.type) {
    queryParams.type = filters.ype;
  }

  if (filters.title) {
    queryParams.search = filters.title;
  }

  if (filters.stage) {
    queryParams.stage = filters.stage;
  }

  if (filters.relatedDocument) {
    queryParams.relatedDocument = filters.relatedDocument;
  }

  queryParams.memberId = memberId;
  queryParams.pageLength = 10;

  return queryParams;
};
