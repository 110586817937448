import { getProxyUrl } from "../utils";
import { api } from "../api";
import { transformCommitteeName } from "../../utils/transform";

export function getDocumentActions(committeeId) {
  let proxyUrl = getProxyUrl(
    `/Values/document/action/${transformCommitteeName(committeeId)}`,
  );
  return api.get(proxyUrl);
}

export function getDocumentTypes(committeeId) {
  let proxyUrl = getProxyUrl(
    `/Values/document/type/${transformCommitteeName(committeeId)}`,
  );
  return api.get(proxyUrl);
}

export function getDocumentCommittees(committeeId) {
  let proxyUrl = getProxyUrl(
    `/Values/document/committee/${transformCommitteeName(committeeId)}`,
  );
  return api.get(proxyUrl);
}

export function getDeadlineMonths(committeeId) {
  let proxyUrl = getProxyUrl(
    `/Values/deadline/month/${transformCommitteeName(committeeId)}`,
  );
  return api.get(proxyUrl);
}

export function getDeadlineTypes(committeeId) {
  let proxyUrl = getProxyUrl(
    `/Values/deadline/type/${transformCommitteeName(committeeId)}`,
  );
  return api.get(proxyUrl);
}

export function getDeadlineCommittees(committeeId) {
  let proxyUrl = getProxyUrl(
    `/Values/deadline/committee/${transformCommitteeName(committeeId)}`,
  );
  return api.get(proxyUrl);
}

export function getMeetingCommittees(committeeId) {
  let proxyUrl = getProxyUrl(
    `/Values/meeting/committee/${transformCommitteeName(committeeId)}`,
  );
  return api.get(proxyUrl);
}

export function getMeetingMonths(committeeId) {
  let proxyUrl = getProxyUrl(
    `/Values/meeting/month/${transformCommitteeName(committeeId)}`,
  );
  return api.get(proxyUrl);
}

export function getSearchActions() {
  let proxyUrl = getProxyUrl("/Values/search/action");
  return api.get(proxyUrl);
}

export function getSearchTypes() {
  let proxyUrl = getProxyUrl("/Values/search/type");
  return api.get(proxyUrl);
}

export function getSearchCommittees(committeeId) {
  let proxyUrl = getProxyUrl(`/Values/search/committee/${committeeId}`);
  return api.get(proxyUrl);
}

export function getCommittees() {
  // let proxyUrl = getProxyUrl("/Values/udvalg");
  return api.get("/api/kundeportal/committees");
}

export function getStages() {
  let proxyUrl = getProxyUrl("/Values/stagecode");
  return api.get(proxyUrl);
}

export function getCommentTypes() {
  let proxyUrl = getProxyUrl("/Values/comment/type");
  return api.get(proxyUrl);
}
