import {
  takeLatest,
  takeLeading,
  takeEvery,
  call,
  put,
  select,
  take,
  delay,
  all,
} from "redux-saga/effects";
import * as actions from "./../state/document/actions";

import {
  getDocumentsUrl,
  deleteDocumentUrl,
  updateDocumentUrl,
  uploadDocumentUrl,
  setDocumentMetadataUrl,
  downloadZipUrl,
  getRelatedReferencesUrl,
} from "../api/resources/document";

import { selectedNationalCommittee } from "../state/national-committees/selectors";
import history from "../utils/history";
import * as documentSelectors from "../state/document/selectors";
import {
  buildQueryParams,
  buildSearchQueryParams,
  sortingType,
} from "../services/documents/url";
import types from "../components/common/modal-wrapper/contentTypes";
import { mapToApiMetadataModel } from "../api/mappers/document";
import {
  documentUploadType,
  sortBoxHidingDelay,
  otherCommitteeId,
  notificationType,
  notificationActionType,
} from "../utils/constants";
import {
  makeOtherCommitteeIdIfEmpty,
  handleOtherCommitteeId,
  trimFileExtension,
} from "../utils/transform";

import FileDownload from "js-file-download";
import { addPageQueryParam, isRequestSuccessful } from "../api/utils";

import { getPagination } from "../state/document/selectors";
import * as documentsSelectors from "../state/document/selectors";
import { userId } from "../state/user/selectors";
import {
  handleCreateNotificationBySystem,
  handleSendInstantEmail,
} from "./notifications";
import { INSTANT_EMAIL_PARAM_TYPE } from "../state/notifications/reducer";

function* handleEditDocument(action) {
  try {
    const response = yield call(updateDocumentUrl, action.payload);

    if (response.status === 200) {
      yield put(actions.resetModalState());
      yield put(actions.reloadDocuments());
      yield put(actions.editDocumentSucceeded());
    } else {
      yield put(actions.editDocumentFailed());
    }
  } catch (error) {
    console.error(`edit document failed - ${error}`);
    yield put(actions.editDocumentFailed());
  }
}

function* handlePullNextDocumentsPageRequest() {
  try {
    const committeeId = yield select(selectedNationalCommittee);
    const pagination = yield select(getPagination);
    const pageToLoad = pagination.pagesLoaded;
    const queryParams = addPageQueryParam(buildQueryParams(), pageToLoad);

    const response = yield call(getDocumentsUrl, committeeId, queryParams);
    const pagesToLoad = Math.ceil(
      response.data.total / response.data.pageLength,
    );

    if (response.status === 200) {
      yield all(
        response.data.results.map((r) => put(actions.fetchRelatedResources(r))),
      );
      yield put(
        actions.pullDocumentsPageSucceeded({
          ...response.data,
          pagesToLoad,
        }),
      );
    } else {
      yield put(actions.filterDocumentsFailed(response.data.message));
    }
  } catch (error) {
    console.error(`pull next documents page failed - ${error}`);
  }
}

function* searchFilterDocuments() {
  try {
    yield call(initFilters);

    const committeeId = yield select(selectedNationalCommittee);

    const queryParams = buildSearchQueryParams();
    const response = yield call(getDocumentsUrl, committeeId, queryParams);

    if (response.status === 200) {
      yield put(actions.filterDocumentsSucceeded(response.data));
    } else {
      yield put(actions.filterDocumentsFailed(response.data.message));
    }
  } catch (error) {
    console.error(`search filter documents failed - ${error}`);
  }
}

function* addToUrl() {
  try {
    const selectedFilters = yield select(documentsSelectors.getSelectedFilters);
    const sortingType = yield select(documentSelectors.getSortingType);

    const url = new URLSearchParams(window.location.search.substr(1));

    if (selectedFilters.title) {
      url.set("title", selectedFilters.title);
    } else {
      url.delete("title");
    }

    if (selectedFilters.action) {
      url.set("action", selectedFilters.action);
    } else {
      url.delete("action");
    }

    if (selectedFilters.type) {
      url.set("type", selectedFilters.type);
    } else {
      url.delete("type");
    }

    if (selectedFilters.committee) {
      url.set("committee", handleOtherCommitteeId(selectedFilters.committee));
    } else {
      url.delete("committee");
    }

    if (sortingType) {
      url.set("sortingType", sortingType);
    } else {
      url.delete("sortingType");
    }

    history.push(`${history.location.pathname}?${url.toString()}`);
  } catch (error) {
    console.error(`add to url failed - ${error}`);
  }
}

function* handleSorting(action) {
  try {
    if (action.payload && action.payload.changeSorting) {
      yield call(addToUrl);
      yield call(handlePullNextDocumentsPageRequest);

      yield delay(sortBoxHidingDelay);
      yield call(addToUrl);
    }
  } catch (error) {
    console.error(`sorting failed - ${error}`);
  }
}

function* handleResetFilters() {
  yield call(addToUrl);
  yield call(initFilters);
}

function* handleDeleteDocument(action) {
  try {
    const documentUrl = action.payload;
    const committeeId = yield select(selectedNationalCommittee);

    const response = yield call(deleteDocumentUrl, documentUrl);

    if (response.status === 200) {
      yield put(actions.deleteDocumentSucceeded({ committeeId, documentUrl }));
      yield put(actions.resetModalState());
      yield put(actions.reloadDocuments());
    } else {
      yield put(actions.deleteDocumentFailed());
    }
  } catch (error) {
    yield put(actions.deleteDocumentFailed());
    console.error(`delete document failed - ${error}`);
  }
}

function* initFilters() {
  try {
    const query = new URLSearchParams(history.location.search);

    let initFilter = {
      type: null,
      action: null,
      committee: null,
      title: "",
    };

    if (query.has("type")) {
      initFilter.type = query.get("type");
    }

    if (query.has("action")) {
      initFilter.action = query.get("action");
    }

    if (query.has("committee")) {
      initFilter.committee = makeOtherCommitteeIdIfEmpty(
        query.get("committee"),
      );
    }

    if (query.has("title")) {
      initFilter.title = query.get("title");
    }

    const isFiltered =
      initFilter.type ||
      initFilter.action ||
      initFilter.committee ||
      initFilter.title;
    yield put(actions.setFilterValue(initFilter));
    yield put(actions.setDocumentsAreFiltered({ value: isFiltered }));

    if (query.has("sortingType")) {
      yield put(actions.setSortingType({ value: query.get("sortingType") }));
    } else {
      yield put(actions.setSortingType({ value: sortingType.date_descending }));
    }
  } catch (error) {
    console.error(`init filters failed - ${error}`);
  }
}

export function* initDocuments() {
  yield call(initFilters);
}

function* handleReloadDocuments(action) {
  yield call(initFilters);
  // yield call(handlePullNextDocumentsPageRequest);
  yield put(actions.pullNextDocumentsPageRequest());
}

function* setMetadata(callback, uri = "") {
  try {
    let documentMetadata = yield select(documentSelectors.getModalData);
    const { notifyOnUpdate: notify, notifyImmediately } = documentMetadata;

    const params = {
      notify,
      notifyImmediately,
    };
    const metadataApiModel = mapToApiMetadataModel(documentMetadata, uri);
    const committeeId = yield select(selectedNationalCommittee);

    const response = yield call(
      setDocumentMetadataUrl,
      committeeId,
      params,
      metadataApiModel,
    );

    if (!isRequestSuccessful(response)) throw new Error("setMetadata failed");

    if (callback) yield put(callback(response.data));

    if (notify) {
      const responseMetadata = response.data.metadata;

      yield call(handleCreateNotificationBySystem, {
        payload: {
          title: responseMetadata.documentTitle,
          description: responseMetadata.documentDescription,
          notify: notifyImmediately,
          type: notificationType.document,
          eventType: notificationActionType.add,
          references: [
            {
              type: notificationType.document,
              uri: responseMetadata.notificationUri,
            },
          ],
        },
      });

      if (notifyImmediately) {
        yield call(handleSendInstantEmail, {
          payload: {
            type: INSTANT_EMAIL_PARAM_TYPE.COMMITTEE_ID,
            param: committeeId,
            disableToast: true,
          },
        });
      }
    }
  } catch (error) {
    console.error(`set document metadata failed - ${error}`);
  }
}

export function* uploadFile(file) {
  try {
    const data = new FormData();
    data.append("data", file);
    const uploadDocumentResponse = yield call(uploadDocumentUrl, data);
    if (uploadDocumentResponse.status === 200) {
      return uploadDocumentResponse.data;
    }
  } catch (error) {
    console.error(`upload file failed - ${error}`);
  }

  return null;
}

function* uploadDocumentHandler(document, type, callback) {
  try {
    if (type === documentUploadType.file) {
      const uploadedDocumentUri = yield call(uploadFile, document);

      if (uploadedDocumentUri) {
        yield call(setMetadata, callback, uploadedDocumentUri);
      }
    } else if (type === documentUploadType.link) {
      yield call(setMetadata, callback);
    }

    yield put(actions.resetModalState());
    yield put(actions.reloadDocuments());
  } catch (error) {
    console.error(`upload document failed - ${error}`);
  }
}

function* handleDocumentsUpload(action) {
  try {
    const {
      documents,
      uploadType,
      callback,
      notifyOnUpdate,
      notifyImmediately,
    } = action.payload;

    const numberOfDocuments = documents.length;

    const defaultAction = "INFO";
    const defaultType = "DSF";
    const defaultCommittee = otherCommitteeId;
    // const defaultCommitteeTitle = yield select(getSelectedNationalCommitteeTitle)
    const currentUserId = yield select(userId);
    for (let i = 0; i < numberOfDocuments; i++) {
      const document = documents[i];
      const fileName =
        uploadType === documentUploadType.link
          ? document.split("/").pop()
          : document.name;

      yield put(
        actions.setModalState({
          isOpen: true,
          contentType: types.CREATE_FILE,
          content: {
            filename: fileName,
            action: defaultAction,
            type: defaultType,
            description: "",
            committee: defaultCommittee,
            committeeTitle: "",
            title: trimFileExtension(fileName),
            count: numberOfDocuments,
            notifyOnUpdate: notifyOnUpdate,
            notifyImmediately: notifyImmediately ?? false,
            currentDocument: i + 1,
            link: uploadType === documentUploadType.link ? document : null,
            mimeType:
              uploadType === documentUploadType.file ? document.type : null,
            memberID: currentUserId,
          },
        }),
      );

      const action = yield take([
        actions.uploadFile,
        actions.skipFileToUpload,
        actions.cancelRemainingFilesToUpload,
      ]);

      if (action.type === actions.SKIP_FILE_TO_UPLOAD) {
        yield put(actions.resetModalState());
        continue;
      }

      if (action.type === actions.CANCEL_REMAINING_FILES_TO_UPLOAD) {
        yield put(actions.resetModalState());
        break;
      }

      yield call(uploadDocumentHandler, document, uploadType, callback);
    }
  } catch (error) {
    console.error(`upload documents failed - ${error}`);
  }
}

export function* handleZipDownload(action) {
  try {
    const { id, packageName, uris } = action.payload;
    const response = yield call(downloadZipUrl, uris);

    if (response.status === 200) {
      FileDownload(response.data, packageName);
    } else {
      if (response.data) {
        console.error(`Error while downloading zip package: 
            ${response.data.errorCode}:${response.data.message}:${response.status}`);
      } else {
        console.error(
          `Error while downloading zip package. Code: ${response.status}`,
        );
      }
    }

    yield put(actions.downloadZipPackageFinished(id));
  } catch (error) {
    console.error(`download zip failed - ${error}`);
  }
}

function* handleCopyDocumentToDifferentCommittee(action) {
  try {
  } catch (error) {
    console.error(`copy document to different committee error: ${error}`);
  }
}

function* handleFetchRelatedResources({ payload }) {
  try {
    const documentUri = payload.metadata.notificationUri;
    const response = yield call(getRelatedReferencesUrl, documentUri);

    if (response.status !== 200) {
      throw new Error(
        `getRelatedReferencesUrl failed with status ${response.status}`,
      );
    }

    const references = response.data.references;

    yield put(
      actions.fetchRelatedResourcesFinished({
        documentUri,
        results: references,
      }),
    );
  } catch (error) {
    console.error(`handleFetchRelatedResources failed with: ${error}`);
  }
}

export default function* documentSaga() {
  yield takeLeading(
    actions.pullNextDocumentsPageRequest,
    handlePullNextDocumentsPageRequest,
  );
  yield takeLatest(actions.initDocuments, initDocuments);
  yield takeLatest(actions.reloadDocuments, handleReloadDocuments);

  yield takeLatest(actions.addFiltersToUrl, addToUrl);
  yield takeLatest(actions.searchFilterDocumentsRequest, searchFilterDocuments);
  yield takeLatest(actions.editDocumentRequest, handleEditDocument);
  yield takeLatest(actions.deleteDocumentRequest, handleDeleteDocument);
  yield takeLatest(actions.setSortingType, handleSorting);
  yield takeLatest(actions.resetFilters, handleResetFilters);
  yield takeLatest(actions.uploadSelectedDocuments, handleDocumentsUpload);
  yield takeEvery(actions.downloadZipPackage, handleZipDownload);
  yield takeEvery(
    actions.copyDocumentToDifferentCommittee,
    handleCopyDocumentToDifferentCommittee,
  );
  yield takeEvery(actions.fetchRelatedResources, handleFetchRelatedResources);
}
