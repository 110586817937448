import React, { Component } from "react";

class Checkbox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: props.element.selected || false,
    };
  }

  handleClick = (e) => {
    this.setState(
      {
        checked: e.target.checked,
      },
      () => {
        this.props.onClick &&
          this.props.onClick({ ...this.props.element }, this.state.checked);
      },
    );
  };

  render() {
    const { element } = this.props;

    return (
      <label htmlFor={`document${element.url}`} className="radio__field">
        <input
          className="inline-checkbox__input"
          type="checkbox"
          name="document"
          value={element.url}
          checked={this.state.checked}
          id={`document${element.url}`}
          onChange={this.handleClick}
        />
        <span className="inline-checkbox__input--off icon icon-radio-off"></span>
        <span className="inline-checkbox__input--on icon icon-radio-on"></span>
        <span className="field-label visuallyhidden">{element.title}</span>
      </label>
    );
  }
}

export default Checkbox;
