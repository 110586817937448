import { handleActions } from "redux-actions";
import * as actions from "./actions";

const initialState = {
  title: "",
};

const actionHandlers = {
  [actions.setHeaderTitle]: (state, action) => ({
    ...state,
    title: action.payload,
  }),
};

export default handleActions(actionHandlers, initialState);
