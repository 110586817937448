import { createAction } from "redux-actions";

const FILTER_DOCUMENTS_SUCCEEDED = "DOCUMENT/filter_documents_succeeded";
const FILTER_DOCUMENTS_FAILED = "DOCUMENT/filter_documents_failed";
const SEARCH_FILTER_DOCUMENTS_REQUEST =
  "DOCUMENT/search_filter_documents_request";

const EDIT_DOCUMENT_REQUEST = "DOCUMENT/edit_document_request";
const EDIT_DOCUMENT_SUCCEEDED = "DOCUMENT/edit_document_succeeded";
const EDIT_DOCUMENT_FAILED = "DOCUMENT/edit_document_failed";
const EDIT_DOCUMENT_DATA = "DOCUMENT/edit_document_data";

const INIT_DOCUMENTS = "DOCUMENT/init_documents";
const RELOAD_DOCUMENTS = "DOCUMENT/reload_documents";

const DELETE_DOCUMENT_REQUEST = "DOCUMENT/delete_document_request";
const DELETE_DOCUMENT_SUCCEEDED = "DOCUMENT/delete_document_succeeded";
const DELETE_DOCUMENT_FAILED = "DOCUMENT/delete_document_failed";

const UPLOAD_SELECTED_DOCUMENTS = "DOCUMENT/upload_selected_documents";
const UPLOAD_FILE = "DOCUMENT/upload_file";

const DOWNLOAD_ZIP_PACKAGE = "DOCUMENTS/download_zip_package";
const DOWNLOAD_ZIP_PACKAGE_FINISHED = "DOCUMENTS/download_zip_package_finished";

export const SKIP_FILE_TO_UPLOAD = "DOCUMENT/skip_file_to_upload";
export const CANCEL_REMAINING_FILES_TO_UPLOAD =
  "DOCUMENT/cancel_remaining_files_to_upload";

const PULL_NEXT_DOCUMENTS_PAGE = "DOCUMENT/pull_next_documents_page";
const PULL_DOCUMENTS_PAGE_SUCCEEDED = "DOCUMENT/pull_documents_page_succeeded";
const PULL_DOCUMENTS_PAGE_FAILED = "DOCUMENT/pull_documents_page_failed";

const FETCH_RELATED_RESOURCES = "DOCUMENT/fetch_related_resources";
const FETCH_RELATED_RESOURCES_FINISHED =
  "DOCUMENT/fetch_related_resources_finished";

const COPY_DOCUMENT_TO_DIFFERENT_COMMITTEE =
  "DOCUMENT/copy_document_to_different_committee";

export const pullNextDocumentsPageRequest = createAction(
  PULL_NEXT_DOCUMENTS_PAGE,
);
export const pullDocumentsPageSucceeded = createAction(
  PULL_DOCUMENTS_PAGE_SUCCEEDED,
);
export const pullDocumentsPageFailed = createAction(PULL_DOCUMENTS_PAGE_FAILED);

export const copyDocumentToDifferentCommittee = createAction(
  COPY_DOCUMENT_TO_DIFFERENT_COMMITTEE,
);

export const filterDocumentsSucceeded = createAction(
  FILTER_DOCUMENTS_SUCCEEDED,
);
export const filterDocumentsFailed = createAction(FILTER_DOCUMENTS_FAILED);
export const searchFilterDocumentsRequest = createAction(
  SEARCH_FILTER_DOCUMENTS_REQUEST,
);

export const editDocumentRequest = createAction(EDIT_DOCUMENT_REQUEST);
export const editDocumentSucceeded = createAction(EDIT_DOCUMENT_SUCCEEDED);
export const editDocumentFailed = createAction(EDIT_DOCUMENT_FAILED);

export const initDocuments = createAction(INIT_DOCUMENTS);
export const reloadDocuments = createAction(RELOAD_DOCUMENTS);

export const editDocumentData = createAction(EDIT_DOCUMENT_DATA);

export const deleteDocumentRequest = createAction(DELETE_DOCUMENT_REQUEST);
export const deleteDocumentSucceeded = createAction(DELETE_DOCUMENT_SUCCEEDED);
export const deleteDocumentFailed = createAction(DELETE_DOCUMENT_FAILED);

export const uploadSelectedDocuments = createAction(UPLOAD_SELECTED_DOCUMENTS);
export const uploadFile = createAction(UPLOAD_FILE);
export const skipFileToUpload = createAction(SKIP_FILE_TO_UPLOAD);
export const cancelRemainingFilesToUpload = createAction(
  CANCEL_REMAINING_FILES_TO_UPLOAD,
);

export const downloadZipPackage = createAction(DOWNLOAD_ZIP_PACKAGE);
export const downloadZipPackageFinished = createAction(
  DOWNLOAD_ZIP_PACKAGE_FINISHED,
);

export const fetchRelatedResources = createAction(FETCH_RELATED_RESOURCES);
export const fetchRelatedResourcesFinished = createAction(
  FETCH_RELATED_RESOURCES_FINISHED,
);

const SET_FILTER_VALUE = "FILTER/set_filter_value";

const RESET_FILTERS = "FILTER/reset_filters";
const RESET_FILTERS_FORM = "FILTER/reset_filters_form";
const ADD_FILTERS_TO_URL = "FILTERS/add_to_url";
const SET_DOCUMENTS_ARE_FILTERED = "FILTER/set_documents_are_filtered";

export const setFilterValue = createAction(SET_FILTER_VALUE);
export const resetFilters = createAction(RESET_FILTERS);
export const resetFiltersForm = createAction(RESET_FILTERS_FORM);
export const addFiltersToUrl = createAction(ADD_FILTERS_TO_URL);
export const setDocumentsAreFiltered = createAction(SET_DOCUMENTS_ARE_FILTERED);

const SET_SORTING_TYPE = "SORTING/set_type";
export const setSortingType = createAction(SET_SORTING_TYPE);

const SET_MODAL_STATE = "MODAL/set_state";
const RESET_MODAL_STATE = "MODAL/reset_state";

export const setModalState = createAction(SET_MODAL_STATE);
export const resetModalState = createAction(RESET_MODAL_STATE);
