import React, { Component } from "react";
import className from "classnames";
import { withTranslation } from "react-i18next";
import { NavLink, withRouter } from "react-router-dom";
import { isMobile } from "react-device-detect";

class UserAccountTopNavigation extends Component {
  constructor(props) {
    super(props);

    this.navRef = React.createRef();
  }

  createLink = (link) => {
    const { t, match } = this.props;
    const urlPart = match.path !== "/" ? match.path + "/" : match.path;

    return (
      <NavLink to={`${urlPart}${link.url}`} activeClassName="active">
        {t(link.name)}
      </NavLink>
    );
  };

  createNavElement = (link, index) => {
    return (
      <li
        key={index}
        className={className("navigation__link", {
          "navigation--inactive": link.disabled,
        })}
      >
        {this.createLink(link, index + 1)}
      </li>
    );
  };

  scrollToNavLink = () => {
    if (!isMobile || !this.navRef.current) return;
    setTimeout(() => {
      const activeElement = this.navRef.current.querySelector(".active");
      this.navRef.current.scrollLeft = activeElement.offsetLeft;
    }, 150);
  };

  render() {
    const { links } = this.props;

    isMobile && this.scrollToNavLink();

    return (
      <ul className="navigation" ref={this.navRef}>
        {links.map(this.createNavElement)}
      </ul>
    );
  }
}

export default withRouter(withTranslation("common")(UserAccountTopNavigation));
