import { addQueryParams, getProxyUrl } from "../utils";
import { api } from "./../api";
import { transformCommitteeName } from "../../utils/transform";
import {
  mapNotificationDocumentsToPatchQuery,
  mapNotificationToPatchQuery,
} from "../mappers/notification";

export function queryNotifications(params = null) {
  let url = "/api/notifications/query";
  url = addQueryParams(url, params);
  return api.get(url);
}

export function getUserNotifications(params = null) {
  let url = "/api/notifications/current_user";
  url = addQueryParams(url, params);
  return api.get(url);
}

export function getUserUnseenNotifications(params = null) {
  let url = "/api/notifications/current_user/unseen";
  url = addQueryParams(url, params);
  return api.get(url);
}

export function getInternalNotification(notificationId) {
  let url = `/api/notifications/internal_notification?id=${encodeURIComponent(
    notificationId,
  )}`;
  return api.get(url);
}

export function getNotification(notificationId) {
  const proxyUrl = getProxyUrl(`/Notifications/notification/${notificationId}`);
  return api.get(proxyUrl);
}

export function updateNotification(notification) {
  let proxyUrl = getProxyUrl(`/Notifications/notification/${notification.uri}`);
  proxyUrl = addQueryParams(
    proxyUrl,
    mapNotificationToPatchQuery(notification),
  );
  let docs = mapNotificationDocumentsToPatchQuery(notification);
  return api.patch(proxyUrl, JSON.stringify(docs));
}

export function deleteNotification(notificationUri) {
  const proxyUrl = `/api/notifications/notification?id=${encodeURIComponent(
    notificationUri,
  )}`;
  return api.delete(proxyUrl);
}

export function createNotification(committeeId, data) {
  const proxyUrl = getProxyUrl(
    `/Notifications/notification/${transformCommitteeName(committeeId)}`,
  );
  return api.post(proxyUrl, data);
}

export function pinNotification(notificationId) {
  const url = "/api/notifications/current_user/pin";
  return api.post(url, `"${notificationId}"`);
}

export function unpinNotification(notificationId) {
  const url = "/api/notifications/current_user/pin";
  return api.delete(url, { data: `"${notificationId}"` });
}

export function markNotificationsAsSeen(notificationIds) {
  const url = "/api/notifications/current_user/seen";
  return api.post(url, notificationIds);
}

export function markAllNotificationAsSeen(committee) {
  const url = "/api/notifications/current_user/seen_all";
  return api.post(url, `"${committee}"`);
}

export function getBroadcastInformation(notificationId) {
  const url = `/api/notifications/broadcast_info?id=${encodeURIComponent(
    notificationId,
  )}`;
  return api.get(url);
}

export function waitForNotificationId(notificationId, seconds = 10) {
  const url = `/api/notifications/wait?notificationId=${encodeURIComponent(
    notificationId,
  )}&seconds=${seconds}`;
  return api.post(url);
}

export function waitForNotificationDocumentId(documentId, seconds = 10) {
  const url = `/api/notifications/wait?documentId=${encodeURIComponent(
    documentId,
  )}&seconds=${seconds}`;
  return api.post(url);
}

export function waitForNotificationTypedDocumentId(docId, eType, sec = 10) {
  const url = `/api/notifications/wait?documentId=${encodeURIComponent(
    docId,
  )}&eventType=${eType}&seconds=${sec}`;
  return api.post(url);
}

export function sendInstantEmailWithNotificationIdUrl(notificationId) {
  const url = `/api/notifications/instant_email/notification_id?notificationId=${encodeURIComponent(
    notificationId,
  )}`;

  return api.post(url);
}

export function sendInstantEmailWithCommitteeIdUrl(committeeId) {
  const url = `/api/notifications/instant_email/committee_id?committeeId=${encodeURIComponent(
    committeeId,
  )}`;

  return api.post(url);
}

export function updatePollUrl(poll) {
  const proxyUrl = getProxyUrl("/Notifications/poll");

  return api.patch(proxyUrl, poll);
}

export function voteNotificationPollUrl(notificationUri, userId, pollOptionId) {
  const proxyUrl = getProxyUrl(
    `/Notifications/poll/vote/${notificationUri}/${userId}/${pollOptionId}`,
  );

  return api.post(proxyUrl);
}

export function deleteVoteNotificationPollUrl(pollVoteId) {
  const proxyUrl = getProxyUrl(`/Notifications/poll/vote/${pollVoteId}`);
  return api.delete(proxyUrl);
}
