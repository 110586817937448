import { referenceListToReferenceDataPerType } from "../../utils/references";
import {
  normalizeDate,
  reduceCommitteeName,
  removeWrongCharacters,
} from "../../utils/transform";

export const normalizeMeetingsPage = (payload) => {
  const meetings = payload.results;
  const pageStart = payload.start;

  return meetings.reduce((previous, current) => {
    previous[current.uri] = normalizeMeeting(current);
    previous[current.uri].pageStart = pageStart;
    return previous;
  }, {});
};

export const normalizeMeeting = (meeting) => {
  return {
    id: meeting.id,
    uri: meeting.uri,
    committee: meeting.committeeReference,
    committeeName: reduceCommitteeName(
      meeting.committeeReference,
      meeting.committeeReferenceTitle,
    ),
    title: removeWrongCharacters(meeting.title),
    fromDate: normalizeDate(meeting.fromDate),
    toDate: normalizeDate(meeting.toDate),
    eventDate: normalizeDate(meeting.eventDate),
    createdDate: normalizeDate(meeting.createdDate),
    locationLong: meeting.longLocation,
    locationShort: meeting.shortLocation,
    referencesRawResponsePerType: referenceListToReferenceDataPerType(
      meeting.references,
    ),
    references: meeting.references,
    participants: meeting.participants,
    isParticipationEnabled: meeting.isParticipationEnabled,
  };
};

export const normalizeMeetingGeneralInfo = (previous, updated) => {
  const currentDate = new Date();
  let output = {
    ...previous,
    ...updated,
  };

  if (output.fromDate) {
    const isPastMeeting = output.fromDate < currentDate;
    output.past = isPastMeeting;

    if (output.fromDate > output.toDate)
      output.toDate = new Date(output.fromDate);

    if (output.past) {
      if (!output.eventDate) {
        output.eventDate = output.fromDate;
      }

      if (!output.createdDate) {
        output.createdDate = currentDate;
      }

      output.notifyOnUpdate = false;
    }
  }

  return output;
};

export const updateMeetingOnList = (meetings, newData) => {
  const oldMeeting = meetings[newData.uri];
  if (!oldMeeting) {
    return meetings;
  }

  const newMeeting = normalizeMeeting(newData);
  return { ...meetings, [newData.uri]: newMeeting };
};
