import { createSelector } from "reselect";
import { getDeadlineUri } from "../deadlines/selectors";

const comments = (state) => state.comments.data;

export const getComments = createSelector(
  [comments, getDeadlineUri],
  (comments, getDeadlineUri) => {
    if (comments && getDeadlineUri) {
      return comments[getDeadlineUri];
    }
    return [];
  },
);

export const commentGetter = createSelector(
  [comments, getDeadlineUri],
  (comments, getDeadlineUri) => {
    return (commentUri) => {
      if (
        comments &&
        getDeadlineUri &&
        commentUri &&
        comments[getDeadlineUri]
      ) {
        return comments[getDeadlineUri].filter(
          (comment) => comment.uri === commentUri,
        )[0];
      }
      return null;
    };
  },
);

export const isProcessingCommentsRequest = (state) =>
  state.comments.isProcessingRequest;
