import React, { Component, Fragment } from "react";
import { withTranslation } from "react-i18next";
import { setPageTitle } from "../../../utils/pageTitle";
import { Helmet } from "react-helmet";

class PageNotFound extends Component {
  render() {
    const { t } = this.props;

    return (
      <Fragment>
        <Helmet>
          <title>{setPageTitle(["Page not found"])}</title>
        </Helmet>
        <header className="layout__title">
          <h2 className="headline-2">{t("general.errorPage.header.name")}</h2>
        </header>
        <div className="layout__content">
          <p>{t("general.errorPage.content.notFound")}</p>
        </div>
      </Fragment>
    );
  }
}

export default withTranslation("common")(PageNotFound);
