import React, { Component, Fragment } from "react";
import { withTranslation } from "react-i18next";
import classNames from "classnames";
import "./SearchBar.scss";

class SearchBar extends Component {
  state = {
    value: "",
  };

  handleChange = (e) => {
    this.setState({
      value: e.target.value,
    });
  };

  handleButtonClick = (e) => {
    e.preventDefault();

    this.setState({
      value: "",
    });

    this.props.onClear();
  };

  onSubmit = (e) => {
    e.preventDefault();
    this.props.onSearch(this.state.value);
  };

  render() {
    const { value } = this.state;
    const { t } = this.props;

    return (
      <Fragment>
        <form className="search-bar" onSubmit={this.onSubmit}>
          <input
            className="search-bar__input"
            type="text"
            placeholder={t("navigation.sideBar.searchPlaceholder")}
            onChange={this.handleChange}
            value={this.state.value}
          />
          <button
            className={classNames("search-bar__btn", {
              "search-bar__btn--opened": value,
            })}
            type="button"
            onClick={this.handleButtonClick}
          ></button>
        </form>
      </Fragment>
    );
  }
}

export default withTranslation("common")(SearchBar);
