export const DELETE_FILE = "delete_file";
export const EDIT_FILE = "edit_file";
export const CREATE_FILE = "create_file";
export const REMOVE_FILE_FROM_LIST = "remove_file_from_list";
export const EXPORT_TO_WORD = "export_to_word";
export const COPY_FILE_TO_COMMITTEE = "copy_file_to_committee";
export const REMOVE_FILE_FROM_DOCUMENT_LIST = "remove_file_from_document_list";
export const REMOVE_FILE_FROM_MEETING_LIST = "remove_file_from_meeting_list";
export const REMOVE_FILE_FROM_DEADLINE_LIST = "remove_file_from_deadline_list";

export default {
  DELETE_FILE,
  EDIT_FILE,
  CREATE_FILE,
  COPY_FILE_TO_COMMITTEE,
  REMOVE_FILE_FROM_LIST,
  EXPORT_TO_WORD,

  // references
  REMOVE_FILE_FROM_DOCUMENT_LIST,
  REMOVE_FILE_FROM_MEETING_LIST,
  REMOVE_FILE_FROM_DEADLINE_LIST,
};
