import React, { Component } from "react";
import Select from "../form/select/Select";
import { generateSelectItemsWithDefaultElement } from "../../../utils/form/select";

class SelectFilter extends Component {
  render() {
    const {
      selectedItem,
      placeholder,
      items,
      onChange,
      label,
      labelOnlyForScreenReaders,
      id,
      customClasses,
      optional,
    } = this.props;

    const selectItems = placeholder
      ? generateSelectItemsWithDefaultElement(placeholder, items)
      : items;

    return (
      <Select
        list={selectItems}
        selected={selectedItem}
        placeholder={placeholder}
        optional={optional}
        id={id}
        label={label}
        labelOnlyForScreenReaders={labelOnlyForScreenReaders}
        changeHandler={onChange}
        customClasses={customClasses}
      />
    );
  }
}

export default SelectFilter;
