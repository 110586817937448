import { createSelector } from "reselect";
import { selectedNationalCommittee } from "../national-committees/selectors";

export const members = (state) => state.members.data;

export const getMembers = createSelector(
  [members, selectedNationalCommittee],
  (members, selectedNationalCommittee) => {
    const committeeMembers = members[selectedNationalCommittee];
    return committeeMembers
      ? committeeMembers.filter((member) => member.name)
      : null;
  },
);

export const getCommitteeMemberById = createSelector(
  [members, selectedNationalCommittee],
  (members, selectedNationalCommittee) => {
    return (id) => {
      const committeeMembers = members[selectedNationalCommittee];
      return committeeMembers
        ? committeeMembers.find((member) => member.name && member.userID === id)
        : null;
    };
  },
);
export const isFetchingMembers = (state) => state.members.isFetching;
