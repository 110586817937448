import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";

import Sticky from "react-stickynode";
import SidebarFilter from "../../../../common/sidebar-filter/SidebarFilter";
import { dateSort, monthFilter } from "../../../../../utils/transform";
import SelectFilter from "../../../../common/select/SelectFilter";
import {
  getDeadlineCommitteeFilterSelectItems,
  getDeadlineMonthFilterSelectItems,
  getDeadlineTypeFilterSelectItems,
  getDeadlineStagesSelectItems,
} from "../../../../../state/values/selectors";
import { getDeadlinesFilters } from "../../../../../state/deadlines/selectors";
import {
  initDeadlines,
  setDeadlinesFilterValue,
  resetDeadlinesFilters,
} from "../../../../../state/deadlines/actions";
import { TIMEFRAME } from "../../../../../api/utils";

class Sidebar extends React.Component {
  state = {
    committee: null,
    month: null,
    type: null,
    stage: null,
    title: "",
  };

  constructor(props) {
    super(props);
    this.currentDate = new Date();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.filters !== this.props.filters) {
      this.setState({
        committee: this.props.filters.committee,
        month: this.props.filters.month,
        type: this.props.filters.type,
        stage: this.props.filters.stage,
      });
    }
  }

  submitHandler = (e) => {
    e.preventDefault();
    const { committee, month, type, stage } = this.state;
    const {
      filters: { timeframe, title },
      showAll,
    } = this.props;

    this.props.setDeadlinesFilterValue({
      committee,
      month,
      type,
      stage,
      title,
      timeframe: showAll ? TIMEFRAME.ALL : timeframe,
    });

    this.props.initDeadlines();
  };

  resetFilters = (e) => {
    e.preventDefault();
    this.props.resetDeadlinesFilters();

    if (this.props.showAll) {
      this.props.setDeadlinesFilterValue({
        timeframe: TIMEFRAME.ALL,
      });
    }

    this.props.initDeadlines();
  };

  selectCommittee = (committee) => {
    if (
      this.props.committees.some((selectItem) => selectItem.value === committee)
    ) {
      this.setState({
        committee,
      });
    } else {
      this.setState({
        committee: null,
      });
    }
  };

  selectMonth = (month) => {
    if (this.props.months.some((selectItem) => selectItem.value === month)) {
      this.setState({
        month,
      });
    } else {
      this.setState({
        month: null,
      });
    }
  };

  selectType = (type) => {
    if (this.props.types.some((selectItem) => selectItem.value === type)) {
      this.setState({
        type,
      });
    } else {
      this.setState({
        type: null,
      });
    }
  };

  selectStage = (stage) => {
    if (this.props.stages.some((selectItem) => selectItem.value === stage)) {
      this.setState({
        stage,
      });
    } else {
      this.setState({
        stage: null,
      });
    }
  };

  setTitle = (event) => {
    const { filters } = this.props;
    this.props.setDeadlinesFilterValue({
      ...filters,
      title: event.target.value,
    });
  };

  renderContent() {
    const { committee, month, stage } = this.state;
    const { t, committees, months, stages, filters } = this.props;
    const { currentDate } = this;

    return (
      <form className="sidebar-filter__form">
        <SidebarFilter
          isFiltered={
            filters.committee || filters.month || filters.stage || filters.title
          }
          submitHandler={this.submitHandler}
          resetFilters={this.resetFilters}
        >
          <div className="group group--small group--underlined">
            <label htmlFor="searchTitle" className="visuallyhidden">
              {t("filter.placeholder.search")}
            </label>
            <input
              type="text"
              className="input"
              name="searchTitle"
              id="searchTitle"
              value={filters.title}
              placeholder={t("filter.placeholder.search")}
              onChange={this.setTitle}
            />
          </div>
          {committees && (
            <div className="group group--small group--underlined">
              <SelectFilter
                items={committees}
                placeholder={t("filter.placeholder.allCommittees")}
                label={t("filter.label.chooseCommittee")}
                labelOnlyForScreenReaders={true}
                selectedItem={committee}
                onChange={this.selectCommittee}
              />
            </div>
          )}
          {months && (
            <div className="group group--small group--underlined">
              <SelectFilter
                items={months
                  .filter((obj) =>
                    monthFilter(obj, filters.timeframe, currentDate),
                  )
                  .sort((a, b) =>
                    dateSort(
                      a.date,
                      b.date,
                      filters.timeframe === TIMEFRAME.PAST ? "desc" : "asc",
                    ),
                  )}
                placeholder={t("filter.placeholder.allMonths")}
                label={t("filter.label.chooseMonth")}
                labelOnlyForScreenReaders={true}
                selectedItem={month}
                onChange={this.selectMonth}
              />
            </div>
          )}
          {/* {types && <div className="group group--small group--underlined">
                        <SelectFilter
                            items={types}
                            placeholder={t("filter.placeholder.allTypes")}
                            label={t("filter.label.chooseType")}
                            labelOnlyForScreenReaders={true}
                            selectedItem={type}
                            onChange={this.selectType}
                        />
                    </div>} */}
          {stages && (
            <div className="group group--small group--underlined">
              <SelectFilter
                items={stages}
                placeholder={t("filter.placeholder.allStages")}
                label={t("filter.label.chooseStage")}
                labelOnlyForScreenReaders={true}
                selectedItem={stage}
                onChange={this.selectStage}
              />
            </div>
          )}
        </SidebarFilter>
      </form>
    );
  }

  render() {
    const { disableSticky } = this.props;

    return (
      <>
        {disableSticky ? (
          <>{this.renderContent()}</>
        ) : (
          <Sticky top={100}>{this.renderContent()}</Sticky>
        )}
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  filters: getDeadlinesFilters(state),
  committees: getDeadlineCommitteeFilterSelectItems(state),
  months: getDeadlineMonthFilterSelectItems(state),
  types: getDeadlineTypeFilterSelectItems(state),
  stages: getDeadlineStagesSelectItems(state),
});
const mapDispatchToProps = {
  initDeadlines,
  resetDeadlinesFilters,
  setDeadlinesFilterValue,
};
export default compose(
  withTranslation("common"),
  connect(mapStateToProps, mapDispatchToProps),
)(Sidebar);
