import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import "./RelatedContent.scss";
import { getNotification } from "../../../api/resources/notifications";
import NotificationTable from "../notifications/pages/notifications-list/NotificationTable";

class RelatedNotificationContent extends Component {
  state = {
    fetchedNotifications: [],
  };

  async fetchRelatedNotifications() {
    const { relatedNotifications } = this.props;

    if (!relatedNotifications?.length) return;

    try {
      const rawResponses = await Promise.all(
        relatedNotifications.map((notification) => {
          return getNotification(notification.uri);
        }),
      );

      const responses = rawResponses
        .filter((response) => response.status === 200)
        .map((response) => response.data);

      this.setState({
        fetchedNotifications: responses,
      });
    } catch (error) {
      console.error(error);
    }
  }

  async componentDidMount() {
    await this.fetchRelatedNotifications();
  }

  render() {
    const { t, relatedNotifications, closeModal } = this.props;

    if (!relatedNotifications?.length) return null;

    return (
      <div className="related-content">
        <div className="group group--small group__flex detail__group group--underlined">
          <span className="headline-4 u-text-bold" id="documents">
            {t("references.document.relatedNotifications")}
          </span>
        </div>
        <NotificationTable
          notifications={this.state.fetchedNotifications}
          closeRelatedContentModal={closeModal}
        />
      </div>
    );
  }
}

// const mapStateToProps = (state) => ({
// });

// const mapDispatchToProps = {
// };

export default compose(
  withTranslation("common"),
  // connect(mapStateToProps, mapDispatchToProps),
)(RelatedNotificationContent);
