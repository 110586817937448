import React, { PureComponent, Component, createRef } from "react";
import { Waypoint } from "react-waypoint";
import PropTypes from "prop-types";
import InfiniteScroller from "react-infinite-scroller";

export class InfiniteWaypointScroller extends Component {
  _handleEnter = () => {
    if (!this.props.isLoading) {
      this.props.loadMore();
    }
  };

  render() {
    const loaderClass = this.props.loaderClass || "InfiniteScroll__loader";

    return (
      <>
        {this.props.children}
        {this.props.hasMore && (
          <Waypoint onEnter={this._handleEnter}>
            <div className={loaderClass}>{this.props.loader}</div>
          </Waypoint>
        )}
      </>
    );
  }
}

export class InfiniteScrollWrapper extends PureComponent {
  infiniteScrollRef = createRef();

  scrollToTop = () => {
    const scroll = this.infiniteScrollRef.current.getParentElement(
      this.infiniteScrollRef.current.scrollComponent,
    );
    scroll.scrollTop = 0;
  };

  reset() {
    if (this.infiniteScrollRef && this.infiniteScrollRef.current) {
      this.infiniteScrollRef.current.pageLoaded =
        this.infiniteScrollRef.current.props.pageStart;
      this.scrollToTop();
      this.props.loadMore(this.props.pageStart);
    }
  }

  render() {
    return (
      <InfiniteScroller
        ref={this.infiniteScrollRef}
        key="scroller"
        {...this.props}
      />
    );
  }
}

InfiniteScrollWrapper.defaultProps = {
  pageStart: 1,
};

InfiniteScrollWrapper.propTypes = {
  pageStart: PropTypes.number,
};
