import React, { Component } from "react";
import classNames from "classnames";

import { withTranslation } from "react-i18next";

import SelectFilter from "../../../../../components/common/select/SelectFilter";
import { voteType, reviewStageValue } from "../../../../../utils/constants";

class OverrideOfficialVote extends Component {
  state = {
    selectedOverrideOption: null,
  };

  get overrideOptions() {
    const { t, deadlineStage } = this.props;

    if (deadlineStage === reviewStageValue) {
      return [
        {
          id: 0,
          value: "confirm",
          label: t("deadlines.details.votes.confirm"),
        },
        {
          id: 1,
          value: "revise",
          label: t("deadlines.details.votes.revise"),
        },
        {
          id: 2,
          value: "withdraw",
          label: t("deadlines.details.votes.withdraw"),
        },
        {
          id: 3,
          value: "abstain",
          label: t("deadlines.details.votes.abstain"),
        },
      ];
    }

    return [
      {
        id: 0,
        value: "yes",
        label: t("deadlines.details.votes.yes"),
      },
      {
        id: 1,
        value: "no",
        label: t("deadlines.details.votes.no"),
      },
      {
        id: 2,
        value: "abstain",
        label: t("deadlines.details.votes.abstain"),
      },
    ];
  }

  get defaultOption() {
    const { vote, deadlineStage } = this.props;
    if (deadlineStage === reviewStageValue) {
      switch (vote) {
        case voteType.revise:
          return "revise";
        case voteType.withdraw:
          return "withdraw";
        case voteType.confirm:
          return "confirm";
        case voteType.abstain:
          return "abstain";
        default:
          return "confirm";
      }
    }

    switch (vote) {
      case voteType.yes:
        return "yes";
      case voteType.no:
        return "no";
      case voteType.abstain:
        return "abstain";
      default:
        return "yes";
    }
  }

  onOverrideOfficialVote = () => {
    const selectedOption = this.overrideOptions.filter(
      (o) => o.value === this.state.selectedOverrideOption,
    );
    if (selectedOption) {
      this.props.onOverrideOfficialVote(selectedOption[0].value);
    }

    this.props.onClose();
  };

  render() {
    const { t, onClose } = this.props;

    return (
      <div className="modal__form">
        <div className="group group--first group--underlined">
          <h3 className="headline-4">
            {t("deadlines.details.officialVote.overrideModal.title")}
          </h3>
        </div>

        <div className="group group--last-underlined">
          <SelectFilter
            id="committeeFilter"
            label={t("deadlines.details.officialVote.overrideModal.label")}
            labelOnlyForScreenReaders={false}
            items={this.overrideOptions}
            selectedItem={
              this.state.selectedOverrideOption || this.defaultOption
            }
            onChange={(option) => {
              this.setState({ selectedOverrideOption: option });
            }}
          />
        </div>

        <div className="modal__footer group group--btn u-align-right">
          <button className="button" onClick={onClose}>
            {t("general.button.cancel")}
          </button>
          <button
            className={classNames("button button--primary", {
              "button--disabled": !this.state.selectedOverrideOption,
            })}
            onClick={this.onOverrideOfficialVote}
            disabled={!this.state.selectedOverrideOption}
          >
            {t("deadlines.details.officialVote.overrideModal.submit")}
          </button>
        </div>
      </div>
    );
  }
}

export default withTranslation("common")(OverrideOfficialVote);
