import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { createNotification } from "../../../../../state/notifications/actions";
import { initDocuments } from "../../../../../state/document/actions";
import { getNewNotificationFormData } from "../../../../../state/notifications/selectors";
import { generateCloseDialogLink } from "../../../../../utils/dynamicLink";
import { selectedNationalCommittee } from "../../../../../state/national-committees/selectors";
import NotificationForm from "../notification-form/NotificationForm";

class CreateNotification extends Component {
  onMount = () => {
    this.props.initDocuments();
  };

  get editorCancelUrl() {
    return generateCloseDialogLink();
  }

  get editorSubmitAction() {
    return this.props.createNotification;
  }

  render() {
    const { formData } = this.props;

    return (
      <NotificationForm
        isCreate
        onMount={this.onMount}
        formData={formData}
        editorSubmitAction={this.editorSubmitAction}
        editorCancelUrl={this.editorCancelUrl}
      />
    );
  }
}

const mapDispatchToProps = {
  createNotification,
  initDocuments,
};

const mapStateToProps = (state) => ({
  // TODO: formDocuments is where the documents are stored when it's selected
  formData: getNewNotificationFormData(state),
  selectedNationalCommittee: selectedNationalCommittee(state),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  // withTranslation("common"),
)(CreateNotification);
