import React, { PureComponent, Fragment } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import { Trans } from "react-i18next";

import NotificationBar from "../notification-bar/NotificationBar";
import NotificationCardHeader from "../notification-card-header/NotificationCardHeader";
import CardContent from "../../../../common/card/CardContent";
import CardAttachments from "../../../../common/card/CardAttachments";
import VotesAttachment from "../../../../common/card/Attachments/VotesAttachment";

import { initNotifications } from "../../../../../state/notifications/actions";

import * as votesSelectors from "../../../../../state/votes/selectors";
import { selectedNationalCommittee } from "../../../../../state/national-committees/selectors";
import * as membersSelectors from "../../../../../state/members/selectors";
import * as userSelectors from "../../../../../state/user/selectors";

import {
  generateDialogLink,
  DIALOG_TYPES,
} from "../../../../../utils/dynamicLink";
import { emptyVotes } from "../../../../../utils/constants";
import { findUserVote } from "../../../../../services/votes/userVote";

class DeadlineSummaryNotification extends PureComponent {
  renderTitle = () => {
    const {
      t,
      data: { details },
      committeeMemberById,
      currentUserId,
    } = this.props;

    if (!details || !details.members)
      return t(this.props.headers.unknownMembers);

    const currentUserHasVoted = !!details.members.find(
      (m) => m === currentUserId,
    );

    const members = [...details.members]
      .reverse()
      .slice(0, 2)
      .filter((member) => member !== currentUserId)
      .map((id) => committeeMemberById(id))
      .filter((member) => member)
      .map((member) => member.name);

    if (currentUserHasVoted) {
      if (members.length === 0)
        return (
          <Trans
            i18nKey={this.props.headers.onlyYou}
            useDangerouslySetInnerHTML={true}
          />
        );

      if (members.length === 1)
        return (
          <Trans
            i18nKey={this.props.headers.youAndOneMember}
            useDangerouslySetInnerHTML={true}
            values={{ member: members[0] }}
          />
        );

      if (members.length > 1) {
        if (details.members.length <= 3)
          //you + 2 members
          return (
            <Trans
              i18nKey={this.props.headers.youAndTwoMembers}
              useDangerouslySetInnerHTML={true}
              values={{
                firstMmember: members[0],
                secondMember: members[1],
              }}
            />
          );

        return (
          <Trans
            i18nKey={this.props.headers.youAndMoreMembers}
            useDangerouslySetInnerHTML={true}
            values={{
              firstMmember: members[0],
              secondMember: members[1],
              othersCount: details.members.length - 2,
            }}
          />
        );
      }
    } else {
      if (members.length === 0) return t(this.props.headers.unknownMembers);

      if (members.length === 1)
        return (
          <Trans
            i18nKey={this.props.headers.oneMember}
            useDangerouslySetInnerHTML={true}
            values={{ member: members[0] }}
          />
        );

      if (members.length > 1) {
        if (details.members.length <= 2)
          return (
            <Trans
              i18nKey={this.props.headers.twoMembers}
              useDangerouslySetInnerHTML={true}
              values={{
                firstMmember: members[0],
                secondMember: members[1],
              }}
            />
          );

        return (
          <Trans
            i18nKey={this.props.headers.moreMembers}
            useDangerouslySetInnerHTML={true}
            values={{
              firstMmember: members[0],
              secondMember: members[1],
              othersCount: details.members.length - 2,
            }}
          />
        );
      }
    }
  };

  render() {
    const {
      data: { notificationId, eventDate, details, fetched, description },
      t,
      initNotifications,
      votes,
      currentUserId,
    } = this.props;

    if (!fetched) return null;

    const commentDetailsUrl = details
      ? generateDialogLink(DIALOG_TYPES.DEADLINE_DETAILS, details.contentId)
      : "";
    const votesStats =
      this.props.voteStatistics[details.contentId] ||
      (details.enableVoting && emptyVotes);
    const userVote = findUserVote(votes, details.contentId, currentUserId);
    const hasUserVoted = typeof userVote != "undefined";

    return (
      <Fragment key={notificationId}>
        <NotificationBar
          title={this.renderTitle()}
          date={eventDate}
          notificationMenu={{
            committee: details.committee,
            notificationId: notificationId,
          }}
        />

        <NotificationCardHeader
          date={details.date}
          committee={details.committee}
          committeeName={details.committeeName}
          title={details.title}
          uri={commentDetailsUrl}
          notificationId={notificationId}
          callbackAction={initNotifications}
        />

        <CardContent description={description} />
        <CardAttachments
          uri={commentDetailsUrl}
          documents={details.documents && details.documents.length}
          comments={details.comments}
          messages={details.messages && details.messages.length}
        >
          <VotesAttachment
            stage={details.stage}
            votes={votesStats}
            userVote={userVote}
            message={
              !hasUserVoted && votes && details.date > new Date()
                ? t("deadlines.details.votes.haveNotVotedMessage")
                : ""
            }
          />
        </CardAttachments>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  selectedNationalCommittee: selectedNationalCommittee(state),
  committeeMemberById: membersSelectors.getCommitteeMemberById(state),
  votes: votesSelectors.votes(state),
  voteStatistics: votesSelectors.voteStatistics(state),
  currentUserId: userSelectors.userId(state),
});

const mapDispatchToProps = {
  initNotifications,
};

export default compose(
  withTranslation("common"),
  connect(mapStateToProps, mapDispatchToProps),
)(DeadlineSummaryNotification);
