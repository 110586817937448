import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withTranslation } from "react-i18next";

import Row from "./Row";
import Header from "./Header";
import {
  cancelRemainingFilesToUpload,
  deleteDocumentRequest,
  editDocumentData,
  editDocumentRequest,
  resetModalState,
  setModalState,
  setSortingType,
  skipFileToUpload,
  uploadFile,
  copyDocumentToDifferentCommittee,
} from "../../../state/document/actions";

import * as notificationActions from "../../../state/notifications/actions";
import * as deadlineActions from "../../../state/deadlines/actions";
import * as meetingsActions from "../../../state/meetings/actions";

import { sortDocuments } from "../../../services/documents/sort";

import {
  getModalDetails,
  getSortingType,
} from "../../../state/document/selectors";
import * as valuesSelectors from "../../../state/values/selectors";
import * as ncSelectors from "../../../state/national-committees/selectors";

import types from "../modal-wrapper/contentTypes";
import ModalWrapper from "../modal-wrapper/ModalWrapper";
import CreateDocument from "../../pages/documents/components/modal-content/CreateDocument";
import EditDocument from "../../pages/documents/components/modal-content/EditDocument";
import DeleteModal from "../../pages/documents/components/modal-content/DeleteModal";
import CopyToCommittee from "../../pages/documents/components/modal-content/CopyToCommittee";
import DiagnosticsGuard from "../diagnostics/DiagnosticsGuard";
import DocumentRowDiagnostics from "../diagnostics/DocumentRowDiagnostics";

import AnalyticsTracking, {
  ResourcesTypes,
} from "../../../services/analytics-tracking/analytics-tracking";

export const DOCUMENT_TABLE_COLUMNS = {
  COMMITTEE: "COMMITTEE",
  TITLE: "TITLE",
  TYPE: "TYPE",
  DUE_DATE: "DUE_DATE",
  DATE: "DATE",
};

export const isColumnDisabled = (disabledColumns, columnName) => {
  if (!disabledColumns || !(columnName in DOCUMENT_TABLE_COLUMNS)) return false;

  return disabledColumns.includes(DOCUMENT_TABLE_COLUMNS[columnName]);
};

class DocumentList extends Component {
  state = {
    manualSortingType: null,
  };

  onSortChange = (sortingType) => {
    if (this.props.manualSort) {
      this.setState({
        manualSortingType: sortingType,
      });
    } else {
      this.props.setSortingType({
        changeSorting: true,
        value: sortingType,
      });
    }
  };

  editHandler = (element) => {
    this.props.setModalState({
      isOpen: true,
      contentType: types.EDIT_FILE,
      content: element,
    });
  };

  requestCloseHandler = () => {
    this.props.setModalState({ isOpen: false });
  };

  deleteHandler = (element) => {
    AnalyticsTracking.showDeleteDialog(ResourcesTypes.document, element.url);

    this.props.setModalState({
      isOpen: true,
      contentType: types.DELETE_FILE,
      content: element,
    });
  };

  createNotificationHandler = (element) => {
    this.props.createNotificationFromDocument(element);
  };

  createDeadlineHandler = (element) => {
    this.props.createDeadlineFromReference(element);
  };

  createMeetingHandler = (element) => {
    this.props.createMeetingFromDocument(element);
  };

  copyToCommitteeHandler = (element) =>
    this.props.setModalState({
      isOpen: true,
      contentType: types.COPY_FILE_TO_COMMITTEE,
      content: element,
    });

  editedHandler = (element) => {
    this.props.editDocumentRequest(element);
    const { onDocumentEdited } = this.props;
    if (onDocumentEdited) {
      onDocumentEdited(element);
    }
  };

  deletedHandler = (id) => {
    AnalyticsTracking.deletionConfirmed(ResourcesTypes.document, id);
    this.props.deleteDocumentRequest(id);
    const { onDocumentDeleted } = this.props;
    if (onDocumentDeleted) {
      onDocumentDeleted(id);
    }
  };

  cancleDeleteHandler = (id) => {
    AnalyticsTracking.closeDeleteDialog(ResourcesTypes.document, id);
    this.props.resetModalState();
  };

  renderDeleteModal = () => (
    <DeleteModal
      id={this.props.modal.content.url}
      title={this.props.t("general.dialog.delete.title", {
        itemName: this.props.modal.content.filename,
      })}
      message={this.props.t("general.dialog.delete.message", {
        itemName: this.props.modal.content.filename,
      })}
      submitHandler={this.deletedHandler}
      cancelHandler={this.cancleDeleteHandler}
      showSpinnerOnSubmit={true}
    />
  );

  renderEditModal = () => (
    <EditDocument
      document={this.props.modal.content}
      filters={this.props.filters}
      editDataHandler={this.props.editDocumentData}
      submitHandler={this.editedHandler}
      cancelHandler={this.props.resetModalState}
    />
  );

  renderCreateModal = () => (
    <CreateDocument
      document={this.props.modal.content}
      filters={this.props.filters}
      editDataHandler={this.props.editDocumentData}
      submitHandler={this.props.uploadFile}
      skipHandler={this.props.skipFile}
      cancelRemainingHandler={this.props.cancelRemainingFilesToUpload}
      hideNotificationOption={this.props.hideNotificationOption}
    />
  );

  renderCopyToCommitteeModal = () => (
    <CopyToCommittee
      document={this.props.modal.content}
      targetCommittees={this.props.nationalCommittees}
      onClose={this.props.resetModalState}
      onCopy={this.props.copyDocumentToDifferentCommittee}
    />
  );

  basicModals = {
    [types.DELETE_FILE]: this.renderDeleteModal,
    [types.EDIT_FILE]: this.renderEditModal,
    [types.CREATE_FILE]: this.renderCreateModal,
    [types.COPY_FILE_TO_COMMITTEE]: this.renderCopyToCommitteeModal,
  };

  renderModal = () => {
    const {
      additionalModals,
      modal: { contentType },
    } = this.props;

    if (additionalModals) {
      Object.keys(additionalModals).forEach(
        (key) => (this.basicModals[key] = additionalModals[key]),
      );
    }

    if (this.basicModals[contentType]) {
      return this.basicModals[contentType]();
    }
  };

  get documentActions() {
    return this.props.showExtendedActions
      ? {
          [this.props.t("documents.action.editDocument")]: this.editHandler,
          [this.props.t("documents.action.deleteDocument")]: this.deleteHandler,
          [this.props.t("documents.action.createNotification")]:
            this.createNotificationHandler,
          [this.props.t("documents.action.createDeadline")]:
            this.createDeadlineHandler,
          [this.props.t("documents.action.createMeeting")]:
            this.createMeetingHandler,
          // [this.props.t("documents.action.copyToCommittee")]: this.copyToCommitteeHandler
        }
      : {
          [this.props.t("documents.action.editDocument")]: this.editHandler,
        };
  }

  getDocumentActions = () => {
    const { rowActions } = this.props;
    const documentActions = this.documentActions;
    if (rowActions) {
      Object.keys(rowActions).forEach(
        (key) => (documentActions[key] = rowActions[key]),
      );
    }
    return documentActions;
  };

  renderRow = (element, index) => {
    const {
      selectHandler,
      enableSelection,
      showActionBox,
      list,
      disabledColumns,
    } = this.props;
    const { hideDueDate, downloadDisabled, showConnections } = this.props;

    return (
      <Fragment key={element.url}>
        <DiagnosticsGuard>
          <DocumentRowDiagnostics
            element={{
              ...element,
              isLast: index === list.length - 1 && list.length > 1,
            }}
          />
        </DiagnosticsGuard>
        <tr key={index} className="table__row documents-table__row">
          <Row
            disabledColumns={disabledColumns}
            key={element.url}
            element={{
              ...element,
              isLast: index === list.length - 1 && list.length > 1,
            }}
            selectHandler={selectHandler}
            enableSelection={enableSelection}
            documentActions={this.getDocumentActions}
            showActionBox={showActionBox}
            hideDueDate={hideDueDate}
            showConnections={showConnections}
            disabled={element.accessForbidden || downloadDisabled}
          />
        </tr>
      </Fragment>
    );
  };

  getSortingType = () => {
    const { manualSortingType } = this.state;
    const { sortingType, manualSort } = this.props;

    return manualSort ? manualSortingType : sortingType;
  };

  getDocumentList = () => {
    if (!this.props.manualSort || !this.state.manualSortingType)
      return this.props.list;

    let tmp = [...this.props.list];
    tmp.sort((a, b) => sortDocuments(a, b, this.state.manualSortingType));
    return tmp;
  };

  render() {
    const {
      enableSelection,
      showActionBox,
      hideDueDate,
      showConnections,
      disabledColumns,
      disableSort,
    } = this.props;
    const list = this.getDocumentList();

    return (
      <div className="table-wrapper">
        <div className="table-content">
          <table className="table">
            <thead className="documents-table">
              <Header
                disabledColumns={disabledColumns}
                disableSort={disableSort}
                enableSelection={enableSelection}
                showActionBox={showActionBox}
                onSortChange={this.onSortChange}
                sortingType={this.getSortingType()}
                hideDueDate={hideDueDate}
                showConnections={showConnections}
              />
            </thead>
            <tbody className="documents-table">
              {list.map(this.renderRow)}
            </tbody>
          </table>
          {list.length === 0 ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                minHeight: "80px",
              }}
            >
              <span>No document registered</span>
            </div>
          ) : null}
        </div>
        <ModalWrapper
          contentType={this.props.modal.contentType}
          isOpen={this.props.modal.isOpen}
          requestCloseHandler={this.requestCloseHandler}
          disableSlide
        >
          {this.renderModal()}
        </ModalWrapper>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  sortingType: getSortingType(state),
  modal: getModalDetails(state),
  filters: valuesSelectors.getDocumentValues(state),
  nationalCommittees: ncSelectors.rawUserNationalCommittees(state),
});

const mapDispatchToProps = {
  setSortingType,
  setModalState,
  resetModalState,
  editDocumentData,
  editDocumentRequest,
  deleteDocumentRequest,
  skipFile: skipFileToUpload,
  uploadFile,
  copyDocumentToDifferentCommittee,
  cancelRemainingFilesToUpload,
  createNotificationFromDocument:
    notificationActions.createNotificationFromDocument,
  createDeadlineFromReference: deadlineActions.createDeadlineFromReference,
  createMeetingFromDocument: meetingsActions.createMeetingFromDocument,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation("common"),
)(DocumentList);
