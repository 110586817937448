import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import ModalWrapper from "../../../../../common/modal-wrapper/ModalWrapper";
import DeleteModal from "../../../../documents/components/modal-content/DeleteModal";
import classNames from "classnames";

const dataURItoBlob = (dataURI) => {
  const byteString = window.atob(dataURI);
  const arrayBuffer = new ArrayBuffer(byteString.length);
  const int8Array = new Uint8Array(arrayBuffer);
  for (let i = 0; i < byteString.length; i++) {
    int8Array[i] = byteString.charCodeAt(i);
  }
  const blob = new Blob([int8Array]);
  return blob;
};

const TableRow = ({
  id,
  file,
  fileName,
  title,
  description,
  isEditing,
  isSpecial,
  onDocumentDelete,
  onDocumentUpdate,
}) => {
  const [isDeleting, setIsDeleting] = useState(false);
  const [isEditingRow, setIsEditingRow] = useState(false);
  const [inputTitleValue, setInputTitleValue] = useState(title);
  const [inputDescriptionValue, setInputDescriptionValue] =
    useState(description);

  const { t } = useTranslation("common");

  const isDisabled =
    inputTitleValue.length < 1 || inputDescriptionValue.length < 1;

  const handleDocumentDelete = () => {
    setIsDeleting(false);
    onDocumentDelete(id, isSpecial);
  };

  const handleDocumentUpdate = () => {
    setIsEditingRow(false);
    onDocumentUpdate({
      title: inputTitleValue,
      description: inputDescriptionValue,
      id,
      isSpecial,
    });
  };

  const handleDownload = () => {
    const blob = dataURItoBlob(file);
    const link = document.createElement("a");

    link.href = URL.createObjectURL(blob);
    link.download = fileName;
    link.click();
  };

  return (
    <tr className="table__row about-docs-table__row">
      <td className="doc-link">
        {isEditingRow ? (
          <div>
            <label htmlFor="searchTitle" className="visuallyhidden">
              {title}
            </label>
            <input
              type="text"
              className="input"
              style={{ width: "90%" }}
              name="document-title"
              id="document-title"
              value={inputTitleValue}
              onChange={(e) => setInputTitleValue(e.target.value)}
            />
          </div>
        ) : (
          <button className="download-button" onClick={handleDownload}>
            {inputTitleValue}
          </button>
        )}
      </td>
      <td className="description">
        {isEditingRow ? (
          <div className="input-wrapper">
            <label htmlFor="searchTitle" className="visuallyhidden">
              {title}
            </label>
            <input
              type="text"
              className="input"
              style={{ width: "90%" }}
              name="document-description"
              id="document-description"
              value={inputDescriptionValue}
              onChange={(e) => setInputDescriptionValue(e.target.value)}
            />
          </div>
        ) : (
          <p>{inputDescriptionValue}</p>
        )}
        {isEditing && !isEditingRow && (
          <div className="edit-buttons">
            <button
              className="button button--secondary button--small"
              onClick={() => setIsEditingRow(true)}
            >
              {t("general.button.edit")}
            </button>
            <button
              className="button button--secondary button--small"
              onClick={() => setIsDeleting(true)}
            >
              {t("general.button.delete")}
            </button>
          </div>
        )}
        {isEditingRow && (
          <div className="edit-buttons">
            <button
              className={classNames("button button--primary button--small", {
                "button--disabled": isDisabled,
              })}
              onClick={handleDocumentUpdate}
              disabled={isDisabled}
            >
              {t("general.button.save")}
            </button>
          </div>
        )}
        {isDeleting && (
          <ModalWrapper
            isOpen={true}
            requestCloseHandler={() => setIsDeleting(false)}
            disableSlide
          >
            <DeleteModal
              id="delete-document"
              deleteButtonText={t("about.modals.delete")}
              message={t("about.modals.deleteConfirmation", {
                name: inputTitleValue,
              })}
              title={t("about.modals.deleteDocument", {
                name: inputTitleValue,
              })}
              cancelHandler={() => setIsDeleting(false)}
              submitHandler={() => handleDocumentDelete()}
            />
          </ModalWrapper>
        )}
      </td>
    </tr>
  );
};

export default TableRow;
