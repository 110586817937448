import { takeLatest, call, put, select, all, delay } from "redux-saga/effects";
import * as actions from "./../state/search/actions";

import history from "../utils/history";
import * as documentSelectors from "../state/search/selectors";
import { buildSearchQueryParams, sortingType } from "../services/documents/url";
import { sortBoxHidingDelay } from "../utils/constants";

import {
  getSearchCommittees,
  getSearchTypes,
  getSearchActions,
} from "../api/resources/values";
import { getSearch, getSuggestions } from "../api/resources/search";
import { getSearchFilters } from "../state/search/selectors";
import { selectedNationalCommittee } from "../state/national-committees/selectors";
import { transformCommitteeName } from "../utils/transform";

function* filterSearch(action) {
  try {
    if (
      (action && action.payload === "POP") ||
      (history && history.location.state && history.location.state.searching)
    ) {
      yield call(initSearchFilters);
    }

    const queryParams = buildSearchQueryParams();
    const response = yield call(getSearch, queryParams);

    if (response.status === 200) {
      yield put(actions.filterSearchSucceeded(response.data));
    } else {
      yield put(actions.filterSearchFailed(response.data.message));
    }
  } catch (error) {
    console.error(`filter search failed - ${error}`);
  }
}

export function* fetchSearchFilters() {
  try {
    const committeeId = yield select(selectedNationalCommittee);

    const response = yield all([
      call(getSearchActions),
      call(getSearchTypes),
      call(getSearchCommittees, transformCommitteeName(committeeId)),
    ]);

    const filters = {
      actions: {
        results: response[0].data.map((action, index) => ({
          id: index,
          value: action.value,
          label: action.label,
        })),
      },
      types: {
        results: response[1].data.map((action, index) => ({
          id: index,
          value: action.value,
          label: action.label,
        })),
      },
      committees: {
        results: response[2].data.map((action, index) => ({
          id: index,
          value: action.value,
          label: action.label,
        })),
      },
    };
    const currentFilters = yield select(getSearchFilters);

    if (currentFilters.actions && currentFilters.actions.selected) {
      filters.actions.selected = currentFilters.actions.selected;
    }
    if (currentFilters.types && currentFilters.types.selected) {
      filters.types.selected = currentFilters.types.selected;
    }
    if (currentFilters.committees && currentFilters.committees.selected) {
      filters.committees.selected = currentFilters.committees.selected;
    }

    yield put(actions.fetchSearchFiltersSucceeded(filters));
  } catch (error) {
    console.error(`fetch search filters failed - ${error}`);
  }
}

function* addToSearchUrl() {
  try {
    const filters = yield select(documentSelectors.getSearchFilters);
    const currentPage = yield select(documentSelectors.getCurrentPage);
    const sortingType = yield select(documentSelectors.getSortingType);

    const url = new URLSearchParams();

    if (filters.title) {
      url.set("title", filters.title);
    }

    if (filters.actions && filters.actions.selected) {
      const committee = filters.actions.results.find(
        (committee) => committee.name === filters.actions.selected,
      );
      if (committee) {
        url.set("action", filters.actions.selected);
      }
    }

    if (filters.types && filters.types.selected) {
      const committee = filters.types.results.find(
        (committee) => committee.name === filters.types.selected,
      );
      if (committee) {
        url.set("type", filters.types.selected);
      }
    }

    if (filters.committees && filters.committees.selected) {
      const committee = filters.committees.results.find(
        (committee) => committee.name === filters.committees.selected,
      );
      if (committee) {
        url.set("committee", filters.committees.selected);
      }
    }

    if (sortingType) {
      url.set("sortingType", sortingType);
    }

    url.set("currentPage", currentPage);

    history.push(`${history.location.pathname}?${url.toString()}`);
  } catch (error) {
    console.error(`add to search url failed - ${error}`);
  }
}

function* handleSearchFilterChange(action) {
  try {
    if (action.payload && action.payload.changeFilter) {
      yield put(actions.setCurrentPage({ value: 1, changeFilter: true }));
    }
  } catch (error) {
    console.error(`search filter change failed - ${error}`);
  }
}

function* handleSearchPagination(action) {
  try {
    if (action.payload && action.payload.changePagination) {
      yield call(addToSearchUrl);
    }
  } catch (error) {
    console.error(`search pagination failed - ${error}`);
  }
}

function* handleSearchSorting(action) {
  try {
    if (action.payload && action.payload.changeSorting) {
      yield put(actions.setCurrentPage({ value: 1, changeFilter: true }));
      yield delay(sortBoxHidingDelay);
      yield call(addToSearchUrl);
    }
  } catch (error) {
    console.error(`search sorting failed - ${error}`);
  }
}

function* handleResetSearchFilters() {
  yield call(addToSearchUrl);
  yield call(initSearchFilters);
}

function* initSearchFilters() {
  try {
    yield put(actions.fetchSearchFiltersRequest());

    const query = new URLSearchParams(history.location.search);
    let isFiltered = false;

    if (query.has("type")) {
      yield put(actions.selectSearchType({ value: query.get("type") }));
      isFiltered = true;
    } else {
      yield put(actions.selectSearchType({ value: null }));
    }

    if (query.has("action")) {
      yield put(actions.selectSearchAction({ value: query.get("action") }));
      isFiltered = true;
    } else {
      yield put(actions.selectSearchAction({ value: null }));
    }

    if (query.has("committee")) {
      yield put(
        actions.selectSearchCommittee({ value: query.get("committee") }),
      );
      isFiltered = true;
    } else {
      yield put(actions.selectSearchCommittee({ value: null }));
    }

    if (query.has("title")) {
      yield put(actions.setSearchTitle({ value: query.get("title") }));
      isFiltered = true;
    } else {
      yield put(actions.setSearchTitle({ value: "" }));
    }

    if (isFiltered) {
      yield put(actions.setSearchIsFiltered({ value: true }));
    } else {
      yield put(actions.setSearchIsFiltered({ value: false }));
    }

    if (query.has("currentPage")) {
      yield put(actions.setCurrentPage({ value: query.get("currentPage") }));
    }

    if (query.has("sortingType")) {
      yield put(actions.setSortingType({ value: query.get("sortingType") }));
    } else {
      yield put(actions.setSortingType({ value: sortingType.date_descending }));
    }
  } catch (error) {
    console.error(`init search filters failed - ${error}`);
  }
}

function* initSearch() {
  yield call(initSearchFilters);
  yield call(filterSearch);
}

export function* getSearchSuggestions(action) {
  const entry = action.payload;
  const queryParams = { query: entry };
  try {
    const response = yield call(getSuggestions, queryParams);

    if (response.status === 200) {
      yield put(actions.getSearchSuggestionsSucceeded(response.data));
    } else {
      yield put(actions.getSearchSuggestionsFailed(response.data));
    }
  } catch (error) {
    console.error(`get search suggestions failed - ${error}`);
  }
}

export default function* searchSaga() {
  yield takeLatest(
    [
      actions.setSearchTitle,
      actions.selectSearchCommittee,
      actions.selectSearchAction,
      actions.selectSearchType,
    ],
    handleSearchFilterChange,
  );

  yield takeLatest(actions.addSearchFiltersToUrl, addToSearchUrl);
  yield takeLatest(actions.fetchSearchFiltersRequest, fetchSearchFilters);
  yield takeLatest(actions.initSearch, initSearch);
  yield takeLatest(actions.filterSearchRequest, filterSearch);
  yield takeLatest(actions.setCurrentPage, handleSearchPagination);
  yield takeLatest(actions.setSortingType, handleSearchSorting);
  yield takeLatest(actions.resetSearchFilters, handleResetSearchFilters);
  yield takeLatest(actions.getSearchSuggestions, getSearchSuggestions);
}
