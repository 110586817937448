import { transformCommitteeName } from "../../utils/transform";

export const buildQueryParams = (committeeId, page, filters) => {
  let queryParams = {};

  queryParams.nationalCommittee = transformCommitteeName(committeeId);

  if (filters.committee) {
    queryParams.committee = filters.committee;
  }

  if (filters.type) {
    queryParams.type = filters.type;
  }

  queryParams.page = page;
  queryParams.pageSize = 10;

  return queryParams;
};
