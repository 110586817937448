import React from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { Provider } from "react-redux";
import { runSagas } from "./sagas";
import createStore from "./utils/state";
import { getAppLanguage } from "./state/user/selectors";
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";

class PageLanguage extends React.Component {
  render() {
    return <Helmet htmlAttributes={{ lang: this.props.appLanguage }} />;
  }
}

const mapStateToProps = (state) => ({
  appLanguage: getAppLanguage(state),
});

const PageLanguageWithState = connect(mapStateToProps, null)(PageLanguage);

export default ({ children }) => {
  const store = createStore();
  runSagas();

  return (
    <Provider store={store}>
      <PageLanguageWithState />
      <I18nextProvider i18n={i18next}>{children}</I18nextProvider>
    </Provider>
  );
};
