import React, { Fragment, Component } from "react";
import { withTranslation } from "react-i18next";
import Avatar from "../../../../common/avatar/Avatar";
import Loader from "../../../../common/loader/Loader";
import "./RelatedVotes.scss";
import { connect } from "react-redux";
import { compose } from "redux";
import { getMembers } from "../../../../../state/members/selectors";
import { initVotesMemberNames } from "../../../../../state/votes/actions";
import classNames from "classnames";
import {
  voteType,
  regularVotes,
  reviewVotes,
  reviewStageValue,
} from "../../../../../utils/constants";

class RelatedVotes extends Component {
  componentDidMount() {
    this.props.initVotesMemberNames(this.props.allVotes);
  }

  getMemberInfo = (id) => {
    // some members id are string instead of number...
    let member = this.props.members.filter((member) => member.userID === id);
    return member.length ? member[0] : [];
  };

  filterVote = (vote) => {
    if (!vote.member || vote.member.loading) return false;

    return this.props.deadlineStage === reviewStageValue
      ? reviewVotes.includes(vote.vote.value)
      : regularVotes.includes(vote.vote.value);
  };

  get isLoadingMembers() {
    if (!this.props.allVotes) return true;
    return (
      this.props.allVotes.find((v) => !v.member || v.member.loading) != null
    );
  }

  renderList = () => {
    const { allVotes, t } = this.props;

    return allVotes
      ? allVotes.filter(this.filterVote).map((vote) => {
          const member = vote.member || { name: "", work: "", photo: "" };
          return (
            <div
              key={vote.userID}
              className="group--small group--underlined u-flex-wrap"
            >
              <div className="u-row u-no-v-padding">
                <Avatar
                  profileImage={member.photo}
                  name={member.name}
                  isSmall={true}
                />
                <div className="group--field">
                  <p className="u-text-bold">{member.name}</p>
                  <p className="text-small">{member.work}</p>
                </div>
              </div>
              <div className="u-row user-vote">
                <p
                  className={classNames(
                    "user-vote__single u-text-bold",
                    `user-vote__single--${
                      Object.keys(voteType)[vote.vote.value]
                    }`,
                  )}
                >
                  VOTED {t(vote.vote.label)}
                </p>
              </div>
            </div>
          );
        })
      : null;
  };

  render() {
    const { t, closeHandler, headline } = this.props;

    return (
      <Fragment>
        <div className="related-votes">
          <div className="group group--first group--underlined">
            <h3 className="headline-4">{headline}</h3>
          </div>

          {this.renderList()}
          {this.isLoadingMembers && (
            <div className="group--small group--underlined u-flex-wrap">
              {<Loader type="inline" />}
            </div>
          )}

          <div className="group group--btn u-align-right">
            <button className="button button--primary" onClick={closeHandler}>
              {t("deadlines.details.RelatedVotesClose")}
            </button>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  members: getMembers(state),
});

const mapDispatchToProps = {
  initVotesMemberNames: initVotesMemberNames,
};

export default compose(
  withTranslation("common"),
  connect(mapStateToProps, mapDispatchToProps),
)(RelatedVotes);
