import { handleActions } from "redux-actions";
import * as actions from "./actions";
import { messageType } from "../../components/common/form/message-box/MessageBox";

const initialState = {
  isFetching: false,
  isFetched: true,
  data: [],
  apiResponse: {
    message: null,
    type: null,
  },
};

const actionHandlers = {
  [actions.getEmailsFrequency]: (state) => ({
    ...state,
    isFetching: true,
    isFetched: false,
  }),
  [actions.getEmailsFrequencySucceeded]: (state, { payload }) => ({
    ...state,
    isFetching: false,
    isFetched: true,
    data: {
      emailsFrequencies: [...payload],
    },
    apiResponse: {
      message: null,
      type: null,
    },
  }),

  [actions.getEmailsFrequencyFailed]: (state, { payload }) => ({
    ...state,
    isFetching: true,
    isFetched: false,
    apiResponse: {
      message: payload,
      type: messageType.error,
    },
  }),
};

export default handleActions(actionHandlers, initialState);
