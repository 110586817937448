import { createSelector } from "reselect";

export const getDocumentValues = (state) => state.values.data.document;
export const getMeetingFilters = (state) => state.values.data.meeting;
export const getDeadlineFilters = (state) => state.values.data.deadline;
export const getStages = (state) => state.values.data.stages;
export const getCommentFilters = (state) => state.values.data.comment;

export const getDocumentCommitteeFilterSelectItems = createSelector(
  [getDocumentValues],
  (documentFilters) => documentFilters.committees,
);

export const getMeetingCommitteeFilterSelectItems = createSelector(
  [getMeetingFilters],
  (meetingFilters) => {
    if (meetingFilters && meetingFilters.committees) {
      return meetingFilters.committees.map((committee, index) => ({
        id: index,
        value: committee.value,
        label: committee.label,
      }));
    }
    return [];
  },
);

export const getMeetingMonthFilterSelectItems = createSelector(
  [getMeetingFilters],
  (meetingFilters) => {
    if (meetingFilters && meetingFilters.months) {
      return meetingFilters.months.map((month) => ({
        id: month.value.replace(",", ""),
        value: month.value,
        label: month.label,
        date: new Date(month.value),
      }));
    }
    return [];
  },
);

export const getDeadlineCommitteeFilterSelectItems = createSelector(
  [getDeadlineFilters],
  (deadlineFilters) => {
    if (deadlineFilters && deadlineFilters.committees) {
      return deadlineFilters.committees.map((committee, index) => ({
        id: index,
        value: committee.value,
        label: committee.label,
      }));
    }
    return [];
  },
);

export const getDeadlineMonthFilterSelectItems = createSelector(
  [getDeadlineFilters],
  (deadlineFilters) => {
    if (deadlineFilters && deadlineFilters.months) {
      return deadlineFilters.months.map((month) => ({
        id: month.value.replace(",", ""),
        value: month.value,
        label: month.label,
        date: month.date,
      }));
    }
    return [];
  },
);

export const getDeadlineTypeFilterSelectItems = createSelector(
  [getDeadlineFilters],
  (deadlineFilters) => {
    if (deadlineFilters && deadlineFilters.types) {
      return deadlineFilters.types.map((type) => ({
        id: type.label,
        value: type.value,
        label: type.label,
      }));
    }
    return [];
  },
);

export const getDeadlineStagesSelectItems = createSelector(
  [getStages],
  (stages) => {
    if (stages) {
      return stages.map((stage) => ({
        id: stage.label,
        value: stage.value,
        label: stage.label,
      }));
    }
    return [];
  },
);

const getActions = () => ["VOTE", "COMMENT", "REPLY", "ACT"];
export const getDeadlineActionsSelectItems = createSelector(
  [getActions],
  (actions) => {
    if (actions) {
      return actions.map((action) => ({
        id: action,
        name: action,
      }));
    }
    return [];
  },
);

export const getCommentTypeSelectItems = createSelector(
  [getCommentFilters],
  (commentFilters) => {
    if (commentFilters && commentFilters.types) {
      return commentFilters.types.map((type) => ({
        id: type.label,
        name: type.label,
        value: type.value,
      }));
    }
    return [];
  },
);
