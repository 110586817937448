import React, { Component } from "react";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";

import { selectedNationalCommittee } from "../../../state/national-committees/selectors";
import {
  DIALOG_TYPES,
  generateDialogLinkForPage,
  PAGE_TYPES,
} from "../../../utils/dynamicLink";
import Toggler from "../toggler/Toggler";

import "./ConsultantMenu.scss";

class ConsultantMenu extends Component {
  render() {
    const { t, selectedNationalCommittee } = this.props;
    return (
      <div className="consultant-menu">
        <Toggler
          calculatePosition={false}
          clickHandler={() => {}}
          isLast={true}
        >
          <div className="consultant-menu__wrapper">
            <button className="toggler__button button button--link consultant-menu__toggler">
              <span className="consultant-menu__icon" aria-hidden="true"></span>
              <span className="consultant-menu__icon" aria-hidden="true"></span>
              <span className="visuallyhidden">
                {t("consultant.action.createNewElement")}
              </span>
            </button>
            <div className="toggler__content panel panel--border consultant-menu__content">
              <ul className="list">
                <li className="list__element consultant-menu__element">
                  <Link
                    to={generateDialogLinkForPage(
                      selectedNationalCommittee,
                      PAGE_TYPES.NOTIFICATION,
                      DIALOG_TYPES.CREATE_NOTIFICATION,
                    )}
                    className="button button--link consultant-menu__button"
                  >
                    {t("consultant.action.createNewNotification")}
                  </Link>
                </li>
                <li className="list__element consultant-menu__element">
                  <Link
                    to={generateDialogLinkForPage(
                      selectedNationalCommittee,
                      PAGE_TYPES.DEADLINES,
                      DIALOG_TYPES.CREATE_DEADLINE,
                    )}
                    className="button button--link consultant-menu__button"
                  >
                    {t("consultant.action.createNewDeadline")}
                  </Link>
                </li>
                <li className="list__element consultant-menu__element">
                  <Link
                    to={generateDialogLinkForPage(
                      selectedNationalCommittee,
                      PAGE_TYPES.MEETING,
                      DIALOG_TYPES.CREATE_MEETING,
                    )}
                    className="button button--link consultant-menu__button"
                  >
                    {t("consultant.action.createNewMeeting")}
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </Toggler>
      </div>
    );
  }
}

const mapDispatchToProps = {};
const mapStateToProps = (state) => ({
  selectedNationalCommittee: selectedNationalCommittee(state),
});

export default compose(
  withTranslation("common"),
  connect(mapStateToProps, mapDispatchToProps),
)(ConsultantMenu);
