import React, {
  Component,
  Children,
  isValidElement,
  cloneElement,
} from "react";
import { NavLink } from "react-router-dom";
import classNames from "classnames";

export class SlimTabsSwitcher extends Component {
  state = {
    internalTabState: "",
  };

  onTabSelected = (name) => {
    if (this.props.enabled) {
      if (this.props.onTab) {
        this.props.onTab(name);
      }

      if (this.props.useInternalState) {
        this.setState({ internalTabState: name });
      }
    }
  };

  render() {
    const { children, enabled } = this.props;
    const selectedTab =
      (this.props.useInternalState
        ? this.state.internalTabState
        : this.props.selectedTab) || this.props.defaultTab;

    const childrenWithProps = Children.map(children, (child) => {
      if (isValidElement(child)) {
        return cloneElement(child, {
          isActive: selectedTab === child.props.name,
          onTabSelected: this.onTabSelected,
        });
      }
    });

    return (
      <ul
        className={classNames(
          "navigation navigation--align-left navigation--underline",
          { "navigation--inactive": !enabled },
        )}
      >
        {childrenWithProps}
      </ul>
    );
  }
}

export class SlimTab extends Component {
  onTabSelected = (e) => {
    e.preventDefault();
    this.props.onTabSelected(this.props.name);
  };

  render() {
    const { label, isActive } = this.props;
    return (
      <li className="navigation__link">
        <a
          href="/"
          onClick={this.onTabSelected}
          className={classNames({ active: isActive })}
        >
          {label}
        </a>
      </li>
    );
  }
}

export class SlimTabsNavigator extends Component {
  render() {
    const { children, enabled } = this.props;

    return (
      <ul
        className={classNames(
          "navigation navigation--align-left navigation--underline",
          { "navigation--inactive": !enabled },
        )}
      >
        {children}
      </ul>
    );
  }
}

export class SlimTabLink extends Component {
  render() {
    const { label, exact, to } = this.props;
    return (
      <li className="navigation__link">
        <NavLink exact={exact} to={to} activeClassName="active">
          {label}
        </NavLink>
      </li>
    );
  }
}
