import React, { Component } from "react";
import userManager from "../../../services/auth/openIdClient";

import { compose } from "redux";
import { connect } from "react-redux";
import { NotificationTopButton } from "../layout/NotificationTopButton";
import { selectedNationalCommittee } from "../../../state/national-committees/selectors";

class TopBar extends Component {
  state = {
    userBoxOpened: false,
  };

  componentDidMount() {
    window.addEventListener("click", this.documentClick);
  }

  componentWillUnmount() {
    window.removeEventListener("click", this.documentClick);
  }

  documentClick = (e) => {
    if (
      !(
        e.target.classList.contains("user-box__button") ||
        (e.target.parentElement &&
          e.target.parentElement.classList.contains("user-box__button"))
      )
    ) {
      this.setState({
        userBoxOpened: false,
      });
    }
  };

  onClick = () => {
    this.toggleUserProfile();
    userManager.signoutRedirect();
    userManager.removeUser();
  };

  toggleUserProfile = () => {
    this.setState({
      userBoxOpened: !this.state.userBoxOpened,
    });
  };
  render() {
    const { selectedNationalCommittee } = this.props;
    const { userBoxOpened } = this.state;

    return (
      <div className="layout__top-bar">
        <button
          className="layout__heading u-only-small"
          onClick={this.props.buttonClickHandler}
        >
          <span className="heading-text">
            {this.props.headingTitleFunction()}
          </span>
          <span
            className="heading-icon icon icon-menu-arrow"
            aria-hidden="true"
          ></span>
        </button>
        {/* 
        {window.__ENABLE_TOPBAR_SEARCH && <Search />}

        <WithPrivileges>
          <ToolbarTool
            icon="question-mark"
            link={process.env.REACT_APP_BEST_PRACTICE_DOC}
          />
        </WithPrivileges>

        <LanguageSwitcher />

        <div className="user-box">
          <button className="user-box__button" onClick={this.toggleUserProfile}>
            <span className="icon icon-profile-icon" aria-hidden="true"></span>
            <span className="visuallyhidden">
              {t("navigation.userMenu.userProfile")}
            </span>
          </button>
          {userBoxOpened ? (
            <div className="user-box__dropdown panel panel--border">
              <ul className="list">
                <li className="list__element">
                  <Link
                    to="/user/profile"
                    onClick={this.toggleUserProfile}
                    className="button button--link"
                  >
                    {t("navigation.userMenu.yourAccount")}
                  </Link>
                </li>
                <li className="list__element">
                  <button
                    className="button button--link"
                    onClick={this.onClick}
                  >
                    {t("navigation.userMenu.logout")}
                  </button>
                </li>
              </ul>
            </div>
          ) : null}
        </div> */}
        {/* <NotificationTopButton hideText /> */}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  selectedNationalCommittee: selectedNationalCommittee(state),
});

export default compose(connect(mapStateToProps, null))(TopBar);
