import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import ModalWrapper from "../../../../common/modal-wrapper/ModalWrapper";
import AddModal from "../add-modal/AddModal";
import Row from "./table-row/TableRow";

const AboutTable = ({
  list,
  isEditing,
  isSpecial,
  onDocumentAdd,
  onDocumentDelete,
  onDocumentUpdate,
}) => {
  const [isAdding, setIsAdding] = useState(false);
  const { t } = useTranslation("common");

  return (
    <>
      <div className="layout__content panel panel--border about-docs-panel">
        <div className="table-wrapper">
          <div className="table-content">
            <table className="table">
              <tbody className="about-docs-table">
                {list.map((item) => (
                  <Row
                    {...item}
                    key={item.id}
                    isEditing={isEditing}
                    onDocumentDelete={onDocumentDelete}
                    onDocumentUpdate={onDocumentUpdate}
                    isSpecial={isSpecial}
                  />
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {isEditing && (
        <div className="layout__content">
          <button
            className="button button--small button--primary"
            onClick={() => setIsAdding(true)}
          >
            {t("general.button.addDocument")}
          </button>
        </div>
      )}
      {isAdding && (
        <ModalWrapper
          isOpen={true}
          requestCloseHandler={() => setIsAdding(false)}
          disableSlide
        >
          <AddModal
            id="delete-document"
            cancelHandler={() => setIsAdding(false)}
            submitHandler={onDocumentAdd}
            isSpecial={isSpecial}
          />
        </ModalWrapper>
      )}
    </>
  );
};

export default AboutTable;
