import React, { Component } from "react";

class ListBreadcrumb extends Component {
  render() {
    const { element, handleElementClick } = this.props;

    return (
      <button
        className="list__element list__header list__pointer-cursor"
        onClick={(element) => handleElementClick(element)}
      >
        <div className="name">
          <span
            className="icon icon-arrow-left icon--large"
            aria-hidden="true"
          ></span>
          <span>{element.name}</span>
        </div>
        <div className="label">{element.description}</div>
        <div className="last empty"></div>
      </button>
    );
  }
}

export default ListBreadcrumb;
