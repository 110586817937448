import { create } from "axios";
import userManager from "../services/auth/openIdClient";

function createApiOptions() {
  return {
    baseURL: process.env.REACT_APP_OPENID_AUTHORITY,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Pragma: "no-cache",
    },
  };
}

const addAuthorizationHeader = async (request) => {
  if (request.headers["Authorization"]) return request;

  let user = await userManager.getUser();

  if (!user) {
    await userManager.signoutRedirect();
    return request;
  }

  if (user.expired) {
    try {
      user = await userManager.signinSilent();
    } catch (error) {
      await userManager.signoutRedirect();
      return request;
    }
  }

  request.headers["Authorization"] = `Bearer ${user.access_token}`;
  return request;
};

const unauthorizedRequestHandler = (error) => {
  if (error.response.status === 401) {
    return userManager.signoutRedirect();
  }

  return Promise.reject(error);
};

function createAxiosApiInstance() {
  const api = create(createApiOptions());

  api.interceptors.request.use(addAuthorizationHeader);
  api.interceptors.response.use(
    (request) => request,
    unauthorizedRequestHandler,
  );

  return api;
}

function createApi() {
  const axios = createAxiosApiInstance();

  return {
    get(url, ...props) {
      return axios.get(url, ...props);
    },
    post(url, ...props) {
      return axios.post(url, ...props);
    },
    put(url, ...props) {
      return axios.put(url, ...props);
    },
    delete(url, ...props) {
      return axios.delete(url, ...props);
    },
    patch(url, ...props) {
      return axios.patch(url, ...props);
    },
  };
}

export const api = createApi();
