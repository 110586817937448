import React from "react";
import userManager from "../../../services/auth/openIdClient";

class SignIn extends React.Component {
  state = {
    redirectTimeout: null,
  };
  updateBrowserUrl = (redirectUrl) => {
    window.history.replaceState(
      {},
      window.document.title,
      window.location.origin,
    );

    if (redirectUrl) {
      window.location = redirectUrl;
    } else {
      window.location = "/";
    }
  };

  componentDidMount() {
    userManager
      .signinRedirectCallback()
      .then((user) => this.updateBrowserUrl(user.state.redirectUrl))
      .catch((error) => {
        console.error(error);
        window.location = "/";
      });

    const timeout = setTimeout(function () {
      window.location = "/";
    }, 5000);
    this.setState({ redirectTimeout: timeout });
  }

  componentWillUnmount() {
    if (this.state.redirectTimeout) {
      clearTimeout(this.state.redirectTimeout);
    }
  }

  render() {
    return null;
  }
}

export default SignIn;
