import { createAction } from "redux-actions";

export const FETCH_USER_DETAILS_REQUESTED = "USER/fetch_user_details_requested";
export const FETCH_USER_DETAILS_SUCCEEDED = "USER/fetch_user_details_succeeded";
export const FETCH_USER_DETAILS_FAILED = "USER/fetch_user_details_failed";

export const UPDATE_USER_PROFILE = "USER/update_user_profile";
export const UPDATE_USER_PROFILE_SUCCEEDED =
  "USER/update_user_profile_succeeded";
export const UPDATE_USER_PROFILE_FAILED = "USER/update_user_profile_failed";

export const UPDATE_USER_PHOTO = "USER/update_user_photo";
export const UPDATE_USER_PHOTO_SUCCEEDED = "USER/update_user_photo_succeeded";
export const UPDATE_USER_PHOTO_FAILED = "USER/update_user_photo_failed";

export const FETCH_USER_SETTINGS = "USER/fetch_user_settings";
export const FETCH_USER_SETTINGS_SUCCEEDED =
  "USER/fetch_user_settings_succeeded";
export const FETCH_USER_SETTINGS_FAILED = "USER/fetch_user_settings_failed";

export const UPDATE_USER_CREDENTIALS = "USER/update_user_credentials";
export const UPDATE_USER_CREDENTIALS_SUCCEEDED =
  "USER/update_user_credentials_succeeded";
export const UPDATE_USER_CREDENTIALS_FAILED =
  "USER/update_user_credentials_failed";

export const RESET_USER_API_RESPONSE = "USER/reset_user_api_response";

export const TURN_OFF_COMMITTEES = "USER/turn_off_committees";
export const TURN_ON_COMMITTEES = "USER/turn_on_committees";
export const TURN_OFF_COMMITTEE = "USER/turn_off_committee";
export const TURN_OFF_COMMITTEE_SUCCEEDED = "USER/turn_off_committee_succeeded";
export const TURN_ON_COMMITTEE = "USER/turn_on_committee";
export const TURN_ON_COMMITTEE_SUCCEEDED = "USER/turn_on_committee_succeeded";
export const UPDATE_TURN_OFF_COMMITTEES = "USER/update_turn_off_committees";
export const UPDATE_USER_EMAIL_SETTING_ALL_COMMITTEES =
  "USER/update_user_email_setting_all_committees";
export const UPDATE_USER_EMAIL_SETTING_ALL_COMMITTEES_SUCCEEDED =
  "USER/update_user_email_setting_all_committees_succeeded";
export const UPDATE_USER_EMAIL_SETTING = "USER/update_user_email_setting";
export const UPDATE_USER_EMAIL_SETTING_SUCCEEDED =
  "USER/update_user_email_setting_succeeded";
export const TURN_ON_OFF_COMMITTEE = "USER/turn_on_off_committee";
export const SET_APP_LOCALE = "USER/set_app_locale";

export const FETCH_USER_DATA_REQUESTED = "USER/fetch_user_data_request";

export const FETCH_RECENT_SEARCHES = "USER/fetch_recent_searches";
export const FETCH_RECENT_SEARCHES_SUCCEEDED =
  "USER/fetch_recent_searches_succeeded";
export const FETCH_RECENT_SEARCHES_FAILED = "USER/fetch_recent_searches_failed";

export const UPDATE_RECENT_SEARCHES = "USER/update_recent_searches";
export const UPDATE_RECENT_SEARCHES_SUCCEEDED =
  "USER/update_recent_searches_succeeded";
export const UPDATE_RECENT_SEARCHES_FAILED =
  "USER/update_recent_searches_failed";

export const CHECK_USER_PRIVILLEGES = "USER/check_user_privilleges";

export const ACCPET_GDPR = "USER/accpet_gdpr";

const DECREASE_NUMBER_OF_UNSEEN_NOTIFICATIONS =
  "USER/decrease_number_of_unseen_notifications";

export const fetchUserDataRequest = createAction(FETCH_USER_DATA_REQUESTED);

export const getUserDetails = createAction(FETCH_USER_DETAILS_REQUESTED);
export const getUserDetailsSucceeded = createAction(
  FETCH_USER_DETAILS_SUCCEEDED,
);
export const getUserDetailsFailed = createAction(FETCH_USER_DETAILS_FAILED);

export const updateUserProfile = createAction(UPDATE_USER_PROFILE);
export const updateUserProfileSucceeded = createAction(
  UPDATE_USER_PROFILE_SUCCEEDED,
);
export const updateUserProfileFailed = createAction(UPDATE_USER_PROFILE_FAILED);

export const updateUserPhoto = createAction(UPDATE_USER_PHOTO);
export const updateUserPhotoSucceeded = createAction(
  UPDATE_USER_PHOTO_SUCCEEDED,
);
export const updateUserPhotoFailed = createAction(UPDATE_USER_PHOTO_FAILED);

export const fetchUserSettings = createAction(FETCH_USER_SETTINGS);
export const fetchUserSettingsSucceeded = createAction(
  FETCH_USER_SETTINGS_SUCCEEDED,
);
export const fetchUserSettingsFailed = createAction(FETCH_USER_SETTINGS_FAILED);

export const updateUserCredentials = createAction(UPDATE_USER_CREDENTIALS);
export const updateUserCredentialsSucceeded = createAction(
  UPDATE_USER_CREDENTIALS_SUCCEEDED,
);
export const updateUserCredentialsFailed = createAction(
  UPDATE_USER_CREDENTIALS_FAILED,
);

export const resetUserApiResponse = createAction(RESET_USER_API_RESPONSE);

export const turnOffCommittees = createAction(TURN_OFF_COMMITTEES);
export const turnOnCommittees = createAction(TURN_ON_COMMITTEES);
export const turnOffCommittee = createAction(TURN_OFF_COMMITTEE);
export const turnOffCommitteeSucceeded = createAction(
  TURN_OFF_COMMITTEE_SUCCEEDED,
);
export const turnOnCommittee = createAction(TURN_ON_COMMITTEE);
export const turnOnCommitteeSucceeded = createAction(
  TURN_ON_COMMITTEE_SUCCEEDED,
);
export const updateTurnOffCommittees = createAction(UPDATE_TURN_OFF_COMMITTEES);
export const updateUserEmailSettingAllCommittees = createAction(
  UPDATE_USER_EMAIL_SETTING_ALL_COMMITTEES,
);
export const updateUserEmailSettingAllCommitteesSucceeded = createAction(
  UPDATE_USER_EMAIL_SETTING_ALL_COMMITTEES_SUCCEEDED,
);

export const updateUserEmailSetting = createAction(UPDATE_USER_EMAIL_SETTING);
export const updateUserEmailSettingSucceeded = createAction(
  UPDATE_USER_EMAIL_SETTING_SUCCEEDED,
);

export const turnOnOffCommittee = createAction(TURN_ON_OFF_COMMITTEE);
export const setAppLocale = createAction(SET_APP_LOCALE);

export const fetchRecentSearches = createAction(FETCH_RECENT_SEARCHES);
export const fetchRecentSearchesSucceeded = createAction(
  FETCH_RECENT_SEARCHES_SUCCEEDED,
);
export const fetchRecentSearchesFailed = createAction(
  FETCH_RECENT_SEARCHES_FAILED,
);

export const updateRecentSearches = createAction(UPDATE_RECENT_SEARCHES);
export const updateRecentSearchesSucceeded = createAction(
  UPDATE_RECENT_SEARCHES_SUCCEEDED,
);
export const updateRecentSearchesFailed = createAction(
  UPDATE_RECENT_SEARCHES_FAILED,
);
export const handleCheckUserPrivilleges = createAction(CHECK_USER_PRIVILLEGES);

export const decreaseNumberOfUnseenNotifications = createAction(
  DECREASE_NUMBER_OF_UNSEEN_NOTIFICATIONS,
);

export const accpetGdpr = createAction(ACCPET_GDPR);
