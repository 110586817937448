import { saveAs } from "file-saver";
import { takeLatest, call, select, put, fork, all } from "redux-saga/effects";
import * as actions from "../state/comments/actions";
import { selectedNationalCommittee } from "../state/national-committees/selectors";
import {
  getComments,
  getComment,
  updateComment,
  deleteComment,
  createComment,
  exportCommentsToWord,
  getDeadlineCommentsStatistics,
} from "../api/resources/comments";
import { deleteDocumentUrl } from "../api/resources/document";
import {
  waitForNotificationDocumentId,
  waitForNotificationTypedDocumentId,
} from "../api/resources/notifications";
import { handleCheckUserPrivilleges } from "../state/user/actions";
import { transformCommitteeName } from "../utils/transform";
import history from "../utils/history";
import { uploadFile } from "./document";
import { notificationActionType, notificationType } from "../utils/constants";
import { generateDialogLink, DIALOG_TYPES } from "../utils/dynamicLink";
import { handleCreateNotificationBySystem } from "./notifications";

function* handleFetchingComments(action) {
  try {
    const response = yield call(getComments, action.payload);

    if (response.status === 200) {
      yield put(actions.fetchCommentsSucceeded(response.data));
    } else {
      yield put(actions.fetchCommentsFailed("Failed to fetch comments"));
    }
  } catch (error) {
    console.error(`fetching comments failed - ${error}`);
  }
}

function* handleFetchingComment(action) {
  try {
    const response = yield call(getComment, action.payload);

    if (response && response.status === 200) {
      yield put(actions.fetchCommentSucceeded(response.data));
    } else {
      yield put(actions.fetchCommentFailed(response.data));
    }
  } catch (error) {
    console.error(`fetch comment failed - ${error}`);
  }
}

function* handleUpdateComment(action) {
  try {
    const comment = action.payload;
    const filesToUpload = comment.attachments.filter((file) => !file.uri);
    const uploadedFiles = comment.attachments.filter((file) => file.uri);

    yield fork(deleteCommentAttachments, comment.attachmentsToRemoveFromServer);

    action.payload.attachments = [
      ...(yield call(uploadCommentAttachments, filesToUpload)),
      ...uploadedFiles,
    ];
    const response = yield call(updateComment, comment);

    if (response.status === 200) {
      // yield call(waitForNotificationDocumentId, comment.uri);
      yield call(handleCreateNotificationBySystem, {
        payload: {
          ...response.data,
          type: notificationType.comment,
          eventType: notificationActionType.update,
          references: [
            {
              type: notificationType.deadline,
              uri: response.data.deadlineUri,
            },
          ],
        },
      });
      yield put(actions.updateCommentSucceeded(response.data));
      history.push(
        generateDialogLink(
          DIALOG_TYPES.DEADLINE_DETAILS,
          response.data.deadlineUri,
          null,
          "deadline_comments",
        ),
      );
    } else {
      yield put(actions.updateCommentFailed("Failed to update comment"));
    }
  } catch (error) {
    console.error(`update comment failed - ${error}`);
  }
}

function* handleDeletingComment(action) {
  try {
    const { uri, attachments } = action.payload;

    const privillegesAction = {
      type: action.type,
    };

    yield call(handleCheckUserPrivilleges, privillegesAction);
    yield fork(deleteCommentAttachments, attachments);

    const response = yield call(deleteComment, uri);
    if (response.status === 200) {
      yield put(actions.deleteCommentSucceeded(response.data));
    } else {
      yield put(actions.deleteCommentFailed("Failed to delete comment"));
    }
  } catch (error) {
    console.error(`delete comment failed - ${error}`);
  }
}

function* deleteAttachment(attachment) {
  try {
    yield fork(deleteDocumentUrl, attachment.uri);
  } catch (error) {
    console.error(error);
  }
}

function* deleteCommentAttachments(attachments) {
  try {
    for (let i = 0; i < attachments.length; i++) {
      yield fork(deleteAttachment, attachments[i]);
    }
  } catch (error) {
    console.error(`failed to delete files - ${error}`);
  }
}

function* handleCreateComment(action) {
  try {
    action.payload.attachments = yield call(
      uploadCommentAttachments,
      action.payload.attachments,
    );
    const committeeId = yield select(selectedNationalCommittee);

    const response = yield call(
      createComment,
      transformCommitteeName(committeeId),
      action.payload,
    );

    if (response.status === 200) {
      yield call(handleCreateNotificationBySystem, {
        payload: {
          ...response.data,
          type: notificationType.comment,
          eventType: notificationActionType.add,
          references: [
            {
              type: notificationType.deadline,
              uri: response.data.deadlineUri,
            },
          ],
        },
      });
      yield put(actions.createCommentSucceeded(response.data));
      // TODO: THIS IS HOW IT TRACK BACK TO COMMENT MODAL
      history.push(
        generateDialogLink(
          DIALOG_TYPES.DEADLINE_DETAILS,
          response.data.deadlineUri,
          null,
          "deadline_comments",
        ),
      );
    } else {
      yield put(actions.createCommentFailed("Failed to create comment"));
    }
  } catch (error) {
    console.error(`create comment failed - ${error}`);
  }
}

function* handleExportCommentsToWord({ payload }) {
  try {
    const committeeId = yield select(selectedNationalCommittee);

    const response = yield call(exportCommentsToWord, committeeId, payload);
    if (response.status === 200) {
      saveAs(new Blob([response.data]), "comments.docx");
    }
  } catch (error) {
    console.error(error);
  }
}

function* uploadCommentAttachments(files) {
  try {
    let attachments = [];

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const uploadedFileUri = yield call(uploadFile, file);

      if (uploadedFileUri) {
        attachments.push({
          uri: uploadedFileUri,
          name: file.name,
        });
      }
    }
    yield put(
      actions.attachFilesToCommentSucceeded("Upload attachments succeeded"),
    );
    return attachments;
  } catch (error) {
    yield put(
      actions.attachFilesToCommentFailed("Upload attachments succeeded"),
    );
    console.error(error);
  }
}

export function* fetchDeadlineCommentsStatistics(deadlineUri) {
  try {
    const deadlineCommentsStatisticsResponse = yield call(
      getDeadlineCommentsStatistics,
      deadlineUri,
    );

    if (deadlineCommentsStatisticsResponse.status === 200) {
      return {
        deadlineUri,
        statistics: deadlineCommentsStatisticsResponse.data,
      };
    }

    return null;
  } catch (error) {
    console.error(`Fetching deadline comments statistics failed -- ${error}`);
  }
}

export function* fetchDeadlinesCommentsStatistics(deadlines) {
  try {
    const deadlinesCommentsStatistics = yield all(
      deadlines.map((deadline) =>
        call(fetchDeadlineCommentsStatistics, deadline.uri),
      ),
    );

    return deadlinesCommentsStatistics.reduce((prev, current) => {
      if (current) {
        prev[current.deadlineUri] = current.statistics;
      }
      return prev;
    }, {});
  } catch (error) {
    console.error(`Fetching deadline comments statistics failed -- ${error}`);
  }
}

export default function* commentsSaga() {
  yield takeLatest([actions.fetchComments], handleFetchingComments);
  yield takeLatest(actions.fetchComment, handleFetchingComment);
  yield takeLatest(actions.updateComment, handleUpdateComment);
  yield takeLatest(actions.deleteComment, handleDeletingComment);
  yield takeLatest(actions.createComment, handleCreateComment);
  yield takeLatest(actions.exportCommentsToWord, handleExportCommentsToWord);
}
