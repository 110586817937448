import React, { Component, Fragment } from "react";
import { withTranslation } from "react-i18next";
import { compose } from "redux";
import { connect } from "react-redux";
import classNames from "classnames";
import "./Profile.scss";
import {
  updateUserProfile,
  updateUserPhoto,
  resetUserApiResponse,
} from "../../../../../state/user/actions";
import {
  apiResponse,
  userDetails,
  updateInProgress,
  updatePhotoInProgress,
} from "../../../../../state/user/selectors";
import validatePhone from "../../../../../utils/validation/phoneNumberValidator";
import validateLinkedIn from "../../../../../utils/validation/linkedInProfileValidator";
import FormMessageBox, {
  messageType,
} from "../../../../common/form/message-box/MessageBox";
import PanelContainer from "../../../../common/layout/PanelContainer";
import ProfileImage from "./components/ProfileImage";
import { setPageTitle } from "../../../../../utils/pageTitle";
import { Helmet } from "react-helmet";

class UserProfile extends Component {
  state = {
    phone: this.props.user.phone || "",
    messages: [],
    linkedIn: this.props.user.linkedIn || "",
    photo: this.props.user.profileImage || "",
    imageFormData: "",
  };

  componentWillUnmount() {
    this.props.resetUserApiResponse();
  }

  componentDidUpdate(prevProps) {
    const { user } = this.props;
    if (prevProps.user !== this.props.user) {
      this.setState({
        phone: user.phone || "",
        linkedIn: user.linkedIn || "",
        photo: user.profileImage || user.photo || "",
      });
    }
  }

  updateMessageList = (messages, message, validate, value) => {
    let results = [...messages];

    // remove message from list if validation passed
    if (messages.some((msg) => msg === message) && validate(value)) {
      results = results.filter((msg) => msg !== message);
    }

    // add message to list if validation failed and there is no meesage like this
    if (!messages.some((msg) => msg === message) && !validate(value)) {
      results.push(message);
    }

    return results;
  };

  updateMessageListState = (validationTable) => {
    let validationMessages = this.state.messages;

    validationTable.forEach((validationSet) => {
      validationMessages = this.updateMessageList(
        validationMessages,
        validationSet.message,
        validationSet.validate,
        validationSet.value,
      );
    });

    this.setState({
      messages: validationMessages,
    });
  };

  onLinkedInChange = (event) => {
    this.props.resetUserApiResponse();

    this.setState({
      linkedIn: event.target.value,
    });
  };

  onPhoneChange = (event) => {
    this.props.resetUserApiResponse();

    this.setState({
      phone: event.target.value,
    });
  };

  saveUserDetails = (e) => {
    this.props.resetUserApiResponse();
    e.preventDefault();

    const { phone, linkedIn } = this.state;
    const userDetails =
      phone !== this.props.user.phone || linkedIn !== this.props.user.linkedIn
        ? { phone, linkedIn }
        : undefined;
    let messages = this.updateMessageList(
      this.state.messages,
      "userProfile.validation.phoneNumber",
      validatePhone,
      phone,
    );
    messages = this.updateMessageList(
      messages,
      "userProfile.validation.linkedin",
      validateLinkedIn,
      linkedIn,
    );

    this.setState({
      messages,
    });

    if (messages.length === 0 && userDetails) {
      this.props.updateUserProfile(userDetails);
    }
  };

  onImageUpdated = (img, imageBlob) => {
    const formData = new FormData();
    formData.append("file", imageBlob);

    if (img !== this.props.user.profileImage) {
      this.props.updateUserPhoto({ data: formData });
    }

    this.setState({
      photo: img,
      imageFormData: formData,
    });
  };

  onRemoveImage = () => {
    this.props.updateUserPhoto({ data: null });

    this.setState({
      photo: null,
      imageFormData: null,
    });
  };

  render() {
    const { t } = this.props;
    const { name, email, company } = this.props.user;
    const { apiResponse, updateInProgress } = this.props;
    const { phone, linkedIn, messages, photo } = this.state;

    return (
      <Fragment>
        <Helmet>
          <title>{setPageTitle(["User Profile"])}</title>
        </Helmet>
        <PanelContainer>
          <header className="layout__title">
            <h2 className="headline-2">{t("userProfile.header.name")}</h2>
          </header>
          <div className="layout__content panel panel--border">
            <div className="group group--underlined personal-info__wrapper">
              <dl className="personal-info">
                <dt className="personal-info__key">
                  {t("userProfile.label.name")}
                </dt>
                <dd className="personal-info__value">{name}</dd>

                <dt className="personal-info__key">
                  {t("userProfile.label.email")}
                </dt>
                <dd className="personal-info__value">{email}</dd>

                <dt className="personal-info__key">
                  {t("userProfile.label.work")}
                </dt>
                <dd className="personal-info__value">{company}</dd>
              </dl>

              <div className="u-text-light">
                {t("userProfile.changeContactInfo")}
              </div>
            </div>
            <FormMessageBox
              messages={messages}
              messageType={messageType.error}
            />
            <FormMessageBox
              messages={apiResponse.message ? [apiResponse.message] : []}
              messageType={apiResponse.type}
            />
            <div className="group group--underlined">
              <div className="label">
                <label className="label__wrapper">
                  {t("userProfile.label.phone")}
                </label>
              </div>
              <input
                className="input input--short"
                type="tel"
                value={phone}
                onChange={this.onPhoneChange}
              />
            </div>
            <div className="group group--underlined">
              <div className="label">
                <label className="label__wrapper">
                  {t("userProfile.label.linkedIn")}
                </label>
              </div>
              <input
                className="input input--short"
                type="text"
                value={linkedIn}
                onChange={this.onLinkedInChange}
              />
            </div>
            <div className="group group group--last-underlined">
              <ProfileImage
                photo={photo}
                userName={name}
                onImageUpdated={this.onImageUpdated}
                onRemoveImage={this.onRemoveImage}
                updateMessageListState={this.updateMessageListState}
              />
            </div>
            <div className="group group--btn u-align-right">
              <button
                // className="button button--primary button--awaiting button--disabled"
                className={classNames("button button--primary", {
                  "button--awaiting button--disabled": updateInProgress,
                })}
                onClick={this.saveUserDetails}
              >
                {t("general.button.save")}
              </button>
            </div>
          </div>
        </PanelContainer>
      </Fragment>
    );
  }
}

const mapDispatchToProps = {
  updateUserProfile,
  updateUserPhoto,
  resetUserApiResponse,
};

const mapStateToProps = (state) => ({
  user: userDetails(state),
  apiResponse: apiResponse(state),
  updateInProgress: updateInProgress(state),
  updatePhotoInProgress: updatePhotoInProgress(state),
});

export default compose(
  withTranslation("common"),
  connect(mapStateToProps, mapDispatchToProps),
)(UserProfile);
