import React, { Component } from "react";

export default class OutsideClick extends Component {
  componentDidMount() {
    document.addEventListener("click", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.handleClickOutside);
  }

  setWrapperRef = (node) => {
    this.wrapperRef = node;
  };

  handleClickOutside = (event) => {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.props.changeState();
    }
  };
  render() {
    return <div ref={this.setWrapperRef}>{this.props.children}</div>;
  }
}
