import history from "../../utils/history";

export const sortingType = {
  date_descending: "date_desc",
  date_ascending: "date_asc",
  due_date_descending: "due_date_desc",
  due_date_ascending: "due_date_asc",
  document_id_descending: "document_id_desc",
  document_id_ascenting: "document_id_asc",
};

function mapSortTypeToUrlParam(type) {
  switch (type) {
    case sortingType.date_descending:
      return "document_creation_date_dsc";
    case sortingType.date_ascending:
      return "document_creation_date_asc";
    case sortingType.due_date_descending:
      return "document_deadline_dsc";
    case sortingType.due_date_ascending:
      return "document_deadline_asc";
    case sortingType.document_id_descending:
      return "document_id_dsc";
    case sortingType.document_id_ascenting:
      return "document_id_asc";
    default:
      return "document_creation_date_dsc";
  }
}

export const buildQueryParams = () => {
  let queryParams = {};

  const query = new URLSearchParams(history.location.search);

  if (query.has("action")) {
    queryParams.documentAction = query.get("action");
  }

  if (query.has("type")) {
    queryParams.documentType = query.get("type");
  }

  if (query.has("committee")) {
    queryParams.committeeReference = query.get("committee");
  }

  if (query.has("title")) {
    queryParams.documentTitle = query.get("title");
  }

  queryParams.sort = mapSortTypeToUrlParam(query.get("sortingType"));
  queryParams.pageLength = 10;
  return queryParams;
};

export const buildSearchQueryParams = () => {
  let queryParams = {};

  const query = new URLSearchParams(history.location.search);

  if (query.has("action")) {
    queryParams.action = query.get("action");
  }

  if (query.has("type")) {
    queryParams.type = query.get("type");
  }

  if (query.has("committee")) {
    queryParams.committeeReference = query.get("committee");
  }

  if (query.has("title")) {
    queryParams.query = query.get("title");
  }

  queryParams.sort = mapSortTypeToUrlParam(query.get("sortingType"));

  if (query.has("currentPage")) {
    queryParams.start = (query.get("currentPage") - 1) * 10 + 1;
  }

  return queryParams;
};
