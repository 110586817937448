import { createSelector } from "reselect/lib/index";
import { transformCommitteeName } from "../../../utils/transform";
import { userCommittees } from "../../../state/user/selectors";
import { committees } from "../../../state/committees/selectors";

const searchPhrase = (state, searchPhrase) => searchPhrase;

function getCommitteeComparer(userCommitteesNames) {
  return (a, b) => {
    if (userCommitteesNames) {
      const userBelongsToA = userCommitteesNames.includes(a.id);
      const userBelongsToB = userCommitteesNames.includes(b.id);

      if (userBelongsToA !== userBelongsToB) {
        return userBelongsToA ? -1 : 1;
      }
    }

    return a.id.localeCompare(b.id);
  };
}

function getMemberedCommittees(
  committees,
  userCommitteesNames,
  userCommittees,
) {
  return committees
    .filter((committee) =>
      userCommitteesNames.some(
        (userCommitteesName) => userCommitteesName.id === committee.id,
      ),
    )
    .sort(getCommitteeComparer())
    .map((committee) => ({
      name: committee.id,
      title: committee.title,
      responsibleNames: committee.responsibleNames,
      url: `/${transformCommitteeName(committee.id)}/notifications`,
      counter:
        userCommittees[committee.id].unseenNotifications < 0
          ? 0
          : userCommittees[committee.id].unseenNotifications,
    }));
}

function getSearchResultCommittees(
  committees,
  userCommitteesNames,
  userCommittees,
  searchPhrase,
) {
  return committees
    .filter(
      (committee) =>
        committee.id.toLowerCase().indexOf(searchPhrase.toLowerCase()) !== -1 ||
        committee.title.toLowerCase().indexOf(searchPhrase.toLowerCase()) !==
          -1 ||
        committee.responsibleNames
          .toLowerCase()
          .indexOf(searchPhrase.toLowerCase()) !== -1,
    )
    .sort(getCommitteeComparer(userCommitteesNames))
    .map((committee) => ({
      name: committee.id,
      title: committee.title,
      responsibleNames: committee.responsibleNames,
      url: `/${transformCommitteeName(committee.id)}/notifications`,
      counter: userCommitteesNames.includes(committee.id)
        ? userCommittees[committee.id].unseenNotifications < 0
          ? 0
          : userCommittees[committee.id].unseenNotifications
        : null,
    }));
}

export const userCommitteesLinkList = createSelector(
  [userCommittees, committees, searchPhrase],
  (userCommittees, committees, searchPhrase) => {
    const userCommitteesNames = userCommittees
      ? Object.keys(userCommittees).map((id) => ({ id: id, title: "" }))
      : [];

    if (!searchPhrase) {
      return userCommittees
        ? getMemberedCommittees(committees, userCommitteesNames, userCommittees)
        : [];
    }

    return getSearchResultCommittees(
      committees,
      userCommitteesNames,
      userCommittees,
      searchPhrase,
    );
  },
);
