import React, { Fragment } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import DeadlineCard from "../../components/deadline-card/DeadlineCard";
import PanelContainer from "../../../../common/layout/PanelContainer";
import {
  SlimTabsSwitcher,
  SlimTab,
} from "../../../../common/slim-tabs/SlimTabs";
import Sidebar from "../../components/sidebar/Sidebar";
import {
  getDeadlines,
  isFetchingDeadlines,
  isDeletingDeadline,
  hasMoreDeadlinesToLoad,
  getDeadlinesFilters,
} from "../../../../../state/deadlines/selectors";

import "./DeadlineTab.scss";
import history from "../../../../../utils/history";
import UrlPattern from "url-pattern";
import {
  initDeadlines,
  setDeadlinesFilterValue,
  resetDeadlinesFilters,
  pullDeadlinesPageRequest,
  deleteDeadline,
} from "../../../../../state/deadlines/actions";

import { selectedNationalCommittee } from "../../../../../state/national-committees/selectors";
import { getDeadlineStagesSelectItems } from "../../../../../state/values/selectors";
import { isConsultant, userId } from "../../../../../state/user/selectors";
import { voteStatistics, votes } from "../../../../../state/votes/selectors";
import { setPageTitle } from "../../../../../utils/pageTitle";
import { Helmet } from "react-helmet";
import DeadlineDeleteModal from "../../components/delete-modal/DeadlineDeleteModal";

import { applicationTriggerUserHint } from "../../../../../state/app/actions";
import { applicationUserHints } from "../../../../../utils/constants";

import AnalyticsTracking, {
  ResourcesTypes,
} from "../../../../../services/analytics-tracking/analytics-tracking";
import DeadlineList from "./DeadlineList";
import { TIMEFRAME } from "../../../../../api/utils";

export const SHOW_UPCOMING_DEADLINES = "_show_upcoming";
export const SHOW_PAST_DEADLINES = "_show_past";

class DeadlineTab extends React.Component {
  parser = new UrlPattern("/:committeeId/*");

  state = {
    isDeleteModalOpen: false,
    deadlineToDelete: {},
    previousCommittee: "",
    markedAsRemoved: "",
  };

  componentDidMount() {
    const result = this.parser.match(window.location.pathname);
    this.setState({ previousCommittee: result.committeeId });

    this.unlisten = history.listen((location) => {
      const matchResult = this.parser.match(location.pathname);
      if (
        matchResult &&
        this.state.previousCommittee !== matchResult.committeeId
      ) {
        this.setState({ previousCommittee: matchResult.committeeId }, () => {
          this.props.resetDeadlinesFilters();
          this.props.initDeadlines();
        });
      }
    });

    this.props.resetDeadlinesFilters();
    this.props.initDeadlines();

    // This has been replaced with a hint on header of the page
    // this.props.applicationTriggerUserHint({
    //   hintId: applicationUserHints.listDeadlines,
    // });
  }

  componentWillUnmount() {
    this.unlisten();
  }

  onCancelDelete = (uri) => {
    AnalyticsTracking.closeDeleteDialog(ResourcesTypes.deadline, uri);
    this.setState({ isDeleteModalOpen: false });
  };

  onSubmitDelete = (uri) => {
    AnalyticsTracking.deletionConfirmed(ResourcesTypes.deadline, uri);
    this.props.deleteDeadline(uri);
    this.setState({ isDeleteModalOpen: false, markedAsRemoved: uri });
  };

  handleDeadlinesUpcomingFilter = (selected) => {
    this.props.setDeadlinesFilterValue({
      timeframe:
        selected === SHOW_PAST_DEADLINES ? TIMEFRAME.PAST : TIMEFRAME.FUTURE,
      month: null,
    });
    this.props.initDeadlines();
  };

  render() {
    const { t, selectedNationalCommittee, filters, isFetchingDeadlines } =
      this.props;

    return (
      <Fragment>
        <Helmet>
          <title>
            {setPageTitle(["Deadlines"], selectedNationalCommittee)}
          </title>
        </Helmet>
        <PanelContainer>
          <header className="layout__title">
            <h2 className="headline-2">{t("deadlines.header.name")}</h2>
          </header>
          <div className="layout__content">
            <div className="info-top">
              <span>{t("deadlines.pageInfo")}</span>
            </div>
            <SlimTabsSwitcher
              onTab={this.handleDeadlinesUpcomingFilter}
              defaultTab={SHOW_UPCOMING_DEADLINES}
              selectedTab={
                filters.timeframe === TIMEFRAME.PAST
                  ? SHOW_PAST_DEADLINES
                  : SHOW_UPCOMING_DEADLINES
              }
              enabled={!isFetchingDeadlines}
            >
              <SlimTab
                label={t("deadlines.sidebar.showUpcoming")}
                name={SHOW_UPCOMING_DEADLINES}
              />
              <SlimTab
                label={t("deadlines.sidebar.showPast")}
                name={SHOW_PAST_DEADLINES}
              />
            </SlimTabsSwitcher>
          </div>
          <div className="layout__content layout__content--with-cards deadlines">
            <DeadlineList deadlines={this.props.deadlines} />
            <div className="layout__sidebar deadlines__sidebar">
              <Sidebar />
            </div>
          </div>
        </PanelContainer>
        <DeadlineDeleteModal
          showModal={this.state.isDeleteModalOpen}
          onCancel={this.onCancelDelete}
          onSubmit={this.onSubmitDelete}
          itemUri={this.state.deadlineToDelete.uri}
          itemTitle={this.state.deadlineToDelete.title}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  isFetchingDeadlines: isFetchingDeadlines(state),
  isDeletingDeadline: isDeletingDeadline(state),
  deadlines: getDeadlines(state),
  filters: getDeadlinesFilters(state),
  votes: votes(state),
  voteStatistics: voteStatistics(state),
  selectedNationalCommittee: selectedNationalCommittee(state),
  hasMoreDeadlinesToLoad: hasMoreDeadlinesToLoad(state),
  deadlineStages: getDeadlineStagesSelectItems(state),
  userId: userId(state),
  isConsultant: isConsultant(state),
});

const mapDispatchToProps = {
  initDeadlines,
  setDeadlinesFilterValue,
  resetDeadlinesFilters,
  pullDeadlinesPageRequest,
  deleteDeadline,
  applicationTriggerUserHint,
};

export default compose(
  withTranslation("common"),
  connect(mapStateToProps, mapDispatchToProps),
)(DeadlineTab);
