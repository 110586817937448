import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";

import DocumentsTab from "../../../../common/documents-tab/DocumentsTab";
// import {addSelectedDeadlineDocuments, addFormDeadlineDocuments} from "../../../../../state/meetings/actions"
import {
  setFormSelectedMeetingDocuments,
  addFormSelectedMeetingDocuments,
} from "../../../../../state/meetings/actions";
import { uploadSelectedDocuments } from "../../../../../state/document/actions";

// Manages documents list in the form list
class MeetingFormDocumentsEditor extends Component {
  removeDocument = (id) => {
    this.props.setFormSelectedMeetingDocuments(
      this.props.data.references.filter((d) => d.url !== id),
    );
  };

  setDeadlineDocuments = (documents) => {
    this.props.setFormSelectedMeetingDocuments(documents);
  };

  updateDocumentAfterEdit = (document) => {
    const updatedDocuments = this.props.data.references.map((d) => {
      if (d.url === document.url) {
        return document;
      }
      return d;
    });

    this.props.setFormSelectedMeetingDocuments(updatedDocuments);
  };

  render() {
    return (
      <DocumentsTab
        onDocumentEdited={this.updateDocumentAfterEdit}
        removeDocumentFromList={this.removeDocument}
        uploadSelectedDocuments={this.props.uploadSelectedDocuments}
        formDocuments={this.props.data.references}
        addUploadedDocuments={addFormSelectedMeetingDocuments}
        addSelectedDocuments={this.setDeadlineDocuments}
        uploadDocumentsCallback={addFormSelectedMeetingDocuments}
        removeFromLabel={"documents.action.removeFromDeadline"}
        showExtendedActions={false}
        showDownloadAllFiles={false}
      />
    );
  }
}

const mapStateToProps = (state, props) => ({});

const mapDispatchToProps = {
  setFormSelectedMeetingDocuments,
  uploadSelectedDocuments,
};

export default compose(
  withTranslation("common"),
  connect(mapStateToProps, mapDispatchToProps),
)(MeetingFormDocumentsEditor);
