import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { NavLink } from "react-router-dom";
import { Trans, withTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import FileDownload from "js-file-download";

import DeadlineDetailsTab from "../../components/deadline-details/DeadlineDetailsTab";
import DeadlineCommentsTab from "../../components/deadline-details/DeadlineCommentsTab";
import DateThumbnail from "../../../../common/date-thumbnail/DateThumbnail";

import {
  SlimTabsSwitcher,
  SlimTab,
} from "../../../../common/slim-tabs/SlimTabs";
import {
  updateDeadlineReferenceInfo,
  removeReferenceFromDeadline,
  fetchDeadline,
  fetchDeadlineReferences,
  clearDeadline,
  deleteDeadline,
  downloadDeadlineDocuments,
} from "../../../../../state/deadlines/actions";
import { fetchComments } from "../../../../../state/comments/actions";
import {
  getDeadline,
  isFetchingDeadlines,
} from "../../../../../state/deadlines/selectors";
import { getStages } from "../../../../../state/values/selectors";

import { selectedNationalCommittee } from "../../../../../state/national-committees/selectors";
import {
  generateDialogLink,
  generateCloseDialogLink,
  DIALOG_TYPES,
  PAGE_TYPES,
  generateDialogLinkForPage,
} from "../../../../../utils/dynamicLink";
import PageNotFound from "../../../404/PageNotFound";
import Loader from "../../../../common/loader/Loader";
import { userDetails } from "../../../../../state/user/selectors";
import { getComments } from "../../../../../state/comments/selectors";
import { getMembers } from "../../../../../state/members/selectors";

import { setPageTitle } from "../../../../../utils/pageTitle";

import WithPrivileges from "../../../../common/with-privileges/WithPrivileges";
import ReadonlyGuard from "../../../../common/readonly-guard/ReadonlyGuard";
import { initDocuments } from "../../../../../state/document/actions";
import DeadlineDeleteModal from "../../components/delete-modal/DeadlineDeleteModal";

import AnalyticsTracking, {
  ResourcesTypes,
} from "../../../../../services/analytics-tracking/analytics-tracking";

import { createCalendarEvent } from "../../../../../utils/calendarEvent";
import { addDays } from "date-fns";
import { transformUri } from "../../../../../utils/transform";
import { isDownloadingFile } from "../../../../../state/document/selectors";
import GrayboxLabel from "../../../../common/label/GrayboxLabel";
import { calculateVotableDate } from "../../components/deadline-card/DeadlineCard";
import ActionBox from "../../../../common/action-box/ActionBox";
import Toggler from "../../../../common/toggler/Toggler";

const SHOW_DEADLINE_DETAILS = "deadline_details";
const SHOW_DEADLINE_COMMENTS = "deadline_comments";
const SHOW_DEADLINE_REFERENCES = "deadline_references";

class DeadlineDetails extends Component {
  state = {
    isDeleteModalOpen: false,
    currentCard: SHOW_DEADLINE_DETAILS,

    showLabel: false,
    showYear: false,
    labelYear: "",
    labelMonth: "",
    labelDate: "",

    actionBoxOpened: false,
  };

  constructor(props) {
    super(props);

    const uri = this.props.context.resource;
    if (this.props.context.option) {
      this.state.currentCard = this.props.context.option;
    }

    this.props.initDocuments();
    this.props.fetchDeadline(uri);
    this.props.fetchComments(uri);
    this.props.fetchDeadlineReferences();
  }

  componentDidUpdate(prevProps) {
    const {
      t,
      deadline: { date },
    } = this.props;

    if (prevProps.deadline !== this.props.deadline) {
      this.setState({ ...calculateVotableDate(t, date) });
    }
  }

  onDeleteClick = (uri) => {
    AnalyticsTracking.showDeleteDialog(ResourcesTypes.deadline, uri);
    this.setState({ isDeleteModalOpen: true });
  };

  onCancelDelete = (uri) => {
    AnalyticsTracking.closeDeleteDialog(ResourcesTypes.deadline, uri);
    this.setState({ isDeleteModalOpen: false });
  };

  onSubmitDelete = (uri) => {
    AnalyticsTracking.deletionConfirmed(ResourcesTypes.deadline, uri);
    this.props.deleteDeadline(uri);
    this.setState({ isDeleteModalOpen: false });
  };

  newComments = () => {
    if (!this.props.comments) return null;
    const newest = this.props.comments.filter((comment) =>
      this.isNewComment(comment.createdDate),
    );
    if (newest.length) {
      return (
        <span className="tab__label">
          {this.props.t("comments.tab.new", { count: newest.length })}
        </span>
      );
    }
    return null;
  };

  isNewComment = (date) => {
    return (
      new Date(date).getTime() >=
      new Date(this.props.user.lastLoginDate).getTime()
    );
  };

  get isDeadlineDatePassed() {
    if (this.props.deadline) {
      return (
        addDays(new Date(this.props.deadline.date), 1).getTime() <
        new Date().getTime()
      );
    }

    return false;
  }

  handleCardChange = (name) => {
    this.setState({
      currentCard: name,
    });
  };

  createEventBody = ({
    committeeName,
    description,
    stage,
    references,
    referencesPerType,
    url,
    linkText,
    documentsText,
  }) => {
    const { selectedNationalCommittee, t } = this.props;
    const domain = window.location.hostname;

    const header = `<h2>DS Deadline: ${committeeName}</h2>`;
    const body = `<p>${description}</p>`;
    const currentStage = stage
      ? `<h3>Stage: <b>${stage.label} (${stage.value})</b></h3>`
      : "";
    const docsHeader = `<h4>${documentsText}:</h4>`;
    const meetingHeader = `<h4>${t("meetings.header.name")}:</h4>`;

    const meetingsList = referencesPerType?.meeting
      ?.map((doc) => {
        const uri = generateDialogLinkForPage(
          selectedNationalCommittee,
          PAGE_TYPES.MEETING,
          DIALOG_TYPES.MEETING_DETAILS,
          doc.uri,
        );
        return `<li><a href="https://${domain}${uri}">${doc.title}</a></li>`;
      })
      .join("");

    const meetings = meetingsList
      ? [meetingHeader, `<ul>${meetingsList}</ul>`].join("")
      : null;

    const documentsList = references
      .map((doc) => `<li><a href="${doc.downloadUrl}">${doc.filename}</a></li>`)
      .join("");
    const docs = documentsList
      ? [docsHeader, `<ul>${documentsList}</ul>`].join("")
      : null;
    const link = `<h3><a href="${url}">${linkText}</a></h3>`;

    const eventBody = [header, body, currentStage, meetings, docs, link].join(
      "",
    );

    return eventBody;
  };

  // downloadAllFiles = () => {
  //   this.props.downloadDeadlineDocuments(transformUri(this.props.uri));
  // };

  handleExportToCalender = () => {
    const { deadline, t, selectedNationalCommittee, stages } = this.props;

    const {
      title,
      committeeName,
      date,
      references,
      referencesPerType,
      description,
      stage,
    } = deadline;

    const linkText = t("deadlines.details.linkToDeadline");
    const documentsText = t("documents.header.name");

    const fileTitle = `DS Deadline: ${selectedNationalCommittee} - ${title}`;
    const url = window.location.href;
    const currentStage = stages.find((st) => st.value === stage);

    const eventBody = this.createEventBody({
      committeeName,
      description,
      stage: currentStage,
      references,
      referencesPerType,
      linkText,
      documentsText,
      url,
    });

    const data = {
      title: fileTitle,
      html: eventBody,
      startDate: date,
      allDay: true,
      url,
    };

    const eventFile = createCalendarEvent(data);

    FileDownload(eventFile, `${fileTitle}.ics`);
  };

  // onUpdateDocument = (document) => {
  //   this.props.updateDeadlineDocumentInfo(document);
  // };

  // onRemoveReferenceFromDeadline = (id) => {
  //   this.props.removeReferenceFromDeadline(id);
  // };

  renderHeader() {
    const {
      t,
      deadline,
      selectedNationalCommittee,
      comments,
      isProcessingRequest,
    } = this.props;

    const { showLabel, showYear, labelYear, labelMonth, labelDate } =
      this.state;

    const headerLabelText = showLabel
      ? t("deadlines.details.cards.label", {
          date: labelDate,
          month: labelMonth,
        }) + (showYear ? `<strong>, ${labelYear}</strong>` : "")
      : "";

    return (
      <>
        <Helmet>
          <title>
            {setPageTitle(
              ["Deadlines", deadline.title, "Edit comment"],
              selectedNationalCommittee,
            )}
          </title>
        </Helmet>
        <header>
          <div className="layout__title-wrapper">
            <div className="layout__title-group layout__title-group--extended group group--inline">
              {!isProcessingRequest && deadline.date && (
                <DateThumbnail type="big" date={deadline.date} />
              )}
              <div className="group group--inline">
                <h2 className="headline-4">
                  {deadline.committee}
                  <span className="u-text-normal">
                    {deadline.committeeName && ` - ${deadline.committeeName}`}
                  </span>
                </h2>
                <h3 className="headline-3">{deadline.title}</h3>
              </div>
            </div>
            <WithPrivileges>
              <div className="layout__title-group group group--btn group--inline">
                <ReadonlyGuard>
                  <Toggler>
                    <ActionBox>
                      <li className="list__element">
                        <button
                          className="button button--medium button--link actions-list__button"
                          onClick={() => this.handleExportToCalender()}
                        >
                          {t("general.button.calendarExport")}
                        </button>
                      </li>
                      <li className="list__element">
                        <NavLink
                          to={generateDialogLink(
                            DIALOG_TYPES.EDIT_DEADLINE,
                            deadline.uri,
                          )}
                          className="button button--medium button--link actions-list__button"
                        >
                          {t("general.button.edit")}
                        </NavLink>
                      </li>
                      <li className="list__element">
                        <button
                          className="button button--medium button--link actions-list__button"
                          onClick={() => this.onDeleteClick(deadline.uri)}
                        >
                          {t("general.button.delete")}
                        </button>
                      </li>
                    </ActionBox>
                  </Toggler>
                </ReadonlyGuard>
              </div>
            </WithPrivileges>
          </div>
          <div className="layout__label-wrapper">
            {showLabel && (
              <GrayboxLabel>
                <span>
                  <Trans useDangerouslySetInnerHTML={true}>
                    {headerLabelText}
                  </Trans>
                </span>
              </GrayboxLabel>
            )}
          </div>
        </header>

        <div className="layout__content">
          <SlimTabsSwitcher
            enabled={true}
            onTab={this.handleCardChange}
            defaultTab={SHOW_DEADLINE_DETAILS}
            selectedTab={this.state.currentCard}
          >
            <SlimTab
              name={SHOW_DEADLINE_DETAILS}
              label={t("deadlines.details.details")}
            />
            {/* <SlimTab
              name={SHOW_DEADLINE_REFERENCES}
              label={t("references.name")}
            /> */}
            {deadline.enableCommenting && (
              <SlimTab
                name={SHOW_DEADLINE_COMMENTS}
                label={t("deadlines.details.commentsCounter", {
                  count:
                    !comments || comments.length === 0 ? 0 : comments.length,
                })}
              />
            )}
          </SlimTabsSwitcher>
        </div>
      </>
    );
  }

  renderDetails() {
    const { comments } = this.props;

    if (this.state.currentCard === SHOW_DEADLINE_DETAILS) {
      return (
        <div className="layout__content panel">
          <DeadlineDetailsTab
            // {...props}
            {...this.props.deadline}
            user={this.props.user}
          />
          {/* <ReferenceComponent
            disableRows={[REFERENCE_TAB.DEADLINES]}
            onUpdateDocument={this.onUpdateDocument}
            onRemoveDocument={this.onRemoveReferenceFromDeadline}
            onRemoveMeeting={this.onRemoveReferenceFromDeadline}
            // for download zip file
            downloadDocuments={{
              onClick: this.downloadAllFiles,
              isAwaiting: this.props.isDownloadingZipPackage,
              isDisabled:
                this.props.isProcessingRequest ||
                this.props.deadline.referencesPerType.document.length === 0,
            }}
            formData={this.props.deadline}
            disableEdit
          /> */}
        </div>
      );
    }
    if (this.state.currentCard === SHOW_DEADLINE_COMMENTS) {
      return (
        <div className="layout__content">
          <DeadlineCommentsTab
            //  {...props}
            {...this.props.deadline}
            isDeadlineDatePassed={this.isDeadlineDatePassed}
            comments={comments}
            closedForComments={this.props.deadline.closedForComments}
          />
        </div>
      );
    }
  }

  render() {
    const { deadline, isProcessingRequest } = this.props;
    const deadlineLoaded = !!deadline && !!deadline.uri;

    if (!deadlineLoaded) {
      if (!isProcessingRequest) return <PageNotFound />;
      return <Loader type="content" />;
    }

    return (
      <Fragment>
        {this.renderHeader()}
        {this.renderDetails()}
        {isProcessingRequest && <Loader type="content" />}
        <DeadlineDeleteModal
          showModal={this.state.isDeleteModalOpen}
          onCancel={this.onCancelDelete}
          onSubmit={this.onSubmitDelete}
          itemUri={deadline.uri}
          itemTitle={this.props.deadline.description}
        />
      </Fragment>
    );
  }
}

const mapDispatchToProps = {
  initDocuments,
  fetchDeadline,
  fetchDeadlineReferences,
  fetchComments,
  deleteDeadline,
  clearDeadline,
  updateDeadlineReferenceInfo,
  removeReferenceFromDeadline,
  downloadDeadlineDocuments,
};

const mapStateToProps = (state, props) => ({
  stages: getStages(state),
  deadline: getDeadline(state),
  selectedNationalCommittee: selectedNationalCommittee(state),
  user: userDetails(state),
  comments: getComments(state),
  members: getMembers(state),
  isProcessingRequest: isFetchingDeadlines(state),
  isDownloadingZipPackage: isDownloadingFile(
    state,
    transformUri(props.context.resource),
  ),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation("common"),
)(DeadlineDetails);
