import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import Toggler from "../../../../common/toggler/Toggler";
import ActionBox from "../../../../common/action-box/ActionBox";
import classNames from "classnames";
import { getDateString } from "../../../../../utils/transform";
import Checkbox from "../../../../common/form/checkbox/Checkbox";
import { userDetails } from "../../../../../state/user/selectors";
import { compose } from "redux";
import { connect } from "react-redux";
import { getComments } from "../../../../../state/comments/selectors";

class Row extends Component {
  classGenerator = () => {
    const { comment } = this.props;
    if (this.props.enableSelection) {
      return "table__row";
    }
    return classNames("table__row", {
      expanded: comment.expanded,
      collapsed: !comment.expanded,
    });
  };

  createMarkup = (content) => {
    return { __html: content };
  };

  revertGuidPlaceholdersToSpans(htmlString) {
    const comments = this.props.comments;

    // Iterate through each comment and replace the placeholders with the corresponding span elements
    comments.forEach((comment) => {
      const guidPlaceholder = `{{${comment.guid}}}`;
      const spanElement = `<span id="comment_${comment.referenceId}" class="comment__label-box" contenteditable="false" data-guid="${comment.guid}">@ID:${comment.referenceId}</span>`;

      // Replace all occurrences of the placeholder with the span element
      htmlString = htmlString.replace(
        new RegExp(guidPlaceholder, "g"),
        spanElement,
      );
    });

    return htmlString;
  }

  isNewComment = (date) => {
    if (!this.props.user) return null;
    if (
      new Date(date).getTime() >=
      new Date(this.props.user.lastLoginDate).getTime()
    ) {
      return (
        <span className="tag tag--new">
          {this.props.t("comments.table.labels.newComment")}
        </span>
      );
    }
    return null;
  };

  renderAttachments = () => {
    const { attachments } = this.props.comment;

    return (
      attachments && (
        <ul className="list list--links">
          {attachments.map((attachment, index) => (
            <a
              key={`${attachment.name}_${index}`}
              href={attachment.downloadUrl}
              target="_blank"
              rel="noopener noreferrer"
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <li>
                <span className="icon icon-paperclip"></span>
                <button className="button button--link button--link-no-margin">
                  {attachment.name}
                </button>
              </li>
            </a>
          ))}
        </ul>
      )
    );
  };

  render() {
    const {
      comment,
      clickHandler,
      enableSelection,
      showActionBox,
      onSelectItem,
      t,
    } = this.props;

    //todo: add some sense here
    const commentActions = this.props.commentActions({
      name: comment.author,
      uri: comment.uri,
      authorId: comment.authorId,
    });

    return (
      <tr className="table__toggler">
        <td className="table__toggler-row">
          <table className="expandable">
            <tbody>
              <tr className={this.classGenerator()} onClick={clickHandler}>
                {enableSelection && (
                  <td className="id">
                    <Checkbox
                      element={{
                        url: comment.id,
                        title: t("comments.table.header.title.id"),
                        selected: comment.isSelected,
                      }}
                      onClick={onSelectItem}
                    />
                  </td>
                )}
                <td className="showAll">
                  <span className="icon icon-arrow-right" aria-hidden="true" />
                </td>
                <td className="commentId">{comment.referenceId}</td>
                <td className="line">{comment.line}</td>
                <td className="clause">{comment.clause}</td>
                <td className="element">{comment.paragraph}</td>
                <td className="type">{comment.type}</td>
                <td className="author">{comment.author}</td>
                <td className="date">
                  {getDateString(new Date(comment.updateDate))}
                </td>
                {!enableSelection && (
                  <td className="tags">
                    {this.isNewComment(comment.updateDate)}
                  </td>
                )}
                {showActionBox && (
                  <td className="options">
                    {commentActions && (
                      <Toggler
                        calculatePosition={true}
                        clickHandler={() =>
                          this.setState({ actionBoxOpened: false })
                        }
                        isLast={comment.isLast}
                      >
                        <ActionBox>
                          {Object.keys(commentActions).map((name, index) => {
                            return (
                              <li key={index} className="list__element">
                                <button
                                  className="button button--link actions-list__button"
                                  onClick={() => commentActions[name](comment)}
                                >
                                  {name}
                                </button>
                              </li>
                            );
                          })}
                        </ActionBox>
                      </Toggler>
                    )}
                  </td>
                )}
              </tr>
              {!enableSelection && (
                <tr className="table__row">
                  <td colSpan="4">
                    <span className="label">
                      {t("comments.table.header.comment")}
                    </span>
                    <div
                      dangerouslySetInnerHTML={this.createMarkup(
                        this.revertGuidPlaceholdersToSpans(comment.comment),
                      )}
                    ></div>
                    {this.renderAttachments()}
                  </td>
                  <td colSpan="4">
                    <span className="label">
                      {t("comments.table.header.proposal")}
                    </span>
                    <div
                      dangerouslySetInnerHTML={this.createMarkup(
                        this.revertGuidPlaceholdersToSpans(comment.proposal),
                      )}
                    ></div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </td>
      </tr>
    );
  }
}

const mapStateToProps = (state) => ({
  user: userDetails(state),
  comments: getComments(state),
});
export default compose(
  connect(mapStateToProps, null),
  withTranslation("common"),
)(Row);
