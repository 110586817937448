import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Switch, Redirect } from "react-router-dom";

import DeadlineTab from "./pages/deadline-list/DeadlineTab";
import { resetFiltersForm } from "../../../state/document/actions";
import { generateDialogLink, DIALOG_TYPES } from "../../../utils/dynamicLink";

class Deadlines extends Component {
  componentWillUnmount() {
    this.props.resetFiltersForm();
  }

  render() {
    return (
      <Fragment>
        <DeadlineTab />
        <Switch>
          <Redirect
            from={`${this.props.match.path}/:deadlineUri/details*`}
            to={`${this.props.match.path}/${generateDialogLink(
              DIALOG_TYPES.DEADLINE_DETAILS,
              ":deadlineUri",
            )}.xml`}
          />
        </Switch>
      </Fragment>
    );
  }
}

const mapDispatchToProps = {
  resetFiltersForm,
};

export default connect(null, mapDispatchToProps)(Deadlines);
