import React, { Component, Fragment } from "react";
import classNames from "classnames";
import { withTranslation } from "react-i18next";
import OptionalLabel from "../../optional-label/OptionalLabel";

class Select extends Component {
  state = {
    placeholder:
      this.props.placeholder || this.props.t("general.select.placeholder"),
    focused: false,
  };

  generateSelectOptions = (element) => {
    return (
      <option
        key={element.id || element.value.replace(" ", "-")}
        value={element.value}
      >
        {element.label ? element.label : element.value}
      </option>
    );
  };

  setSelectId = () => {
    const { placeholder, id } = this.props;

    if (placeholder) {
      return placeholder.toLowerCase().replace(/\W/g, "");
    }

    if (id) {
      return id;
    }
  };

  render() {
    const {
      label,
      labelOnlyForScreenReaders,
      selected,
      list,
      changeHandler,
      customClasses,
    } = this.props;
    const { focused, placeholder } = this.state;

    const selectedListElement = list.find((e) => e.value === selected);

    let selectedDisplay = selected;
    if (selectedListElement && selectedListElement.label) {
      selectedDisplay = selectedListElement.label;
    }

    return (
      <Fragment>
        <label
          htmlFor={this.setSelectId()}
          className={classNames("field-label", {
            visuallyhidden: labelOnlyForScreenReaders,
          })}
        >
          {label}
          {this.props.optional && <OptionalLabel />}
        </label>
        <div
          className={classNames("select", { focused: focused }, customClasses)}
        >
          <select
            id={this.setSelectId()}
            onChange={(e) => {
              changeHandler(e.target.value);
            }}
            onBlur={() => this.setState({ focused: false })}
            onFocus={() => this.setState({ focused: true })}
            value={selected ? selected : list[0].value}
          >
            {list.map(this.generateSelectOptions)}
          </select>
          <span className="select__text">{selectedDisplay || placeholder}</span>
          <span className="icon icon-arrow-right" aria-hidden="true"></span>
        </div>
      </Fragment>
    );
  }
}

export default withTranslation("common")(Select);
