import ical from "ical-generator";

export const createCalendarEvent = ({
  title,
  description,
  startDate,
  endDate,
  html,
  url,
  location,
  allDay = false,
}) => {
  const calendar = ical();

  calendar.createEvent({
    start: startDate,
    end: endDate,
    summary: title,
    description: {
      plain: description,
      html: html,
    },
    location,
    url,
    allDay,
  });

  const eventString = calendar.toString();

  const file = new File([eventString], "invite.ics", { type: "text/plain" });

  return file;
};
