import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Route } from "react-router-dom";

import UserProfile from "./tabs/profile/Profile";
import UserCredentials from "./tabs/credentials/Credentials";
import { getUserDetails } from "../../../state/user/actions";
import { userFullName } from "../../../state/user/selectors";

import { Content } from "../../common/layout/Content";
import Settings from "../settings/Settings";
import { setHeaderTitle } from "../../../state/header/actions";

export const UserPageLinks = [
  {
    name: "userProfile.navigation.tab.profile",
    url: "profile",
    component: UserProfile,
  },
  {
    name: "userProfile.navigation.tab.credentials",
    url: "credentials",
    component: UserCredentials,
  },
  {
    name: "userProfile.navigation.tab.settings",
    url: "settings",
    component: Settings,
  },
];

class User extends Component {
  constructor(props) {
    super(props);
    props.getUserDetails();
  }

  componentDidUpdate(prevProps) {
    const { setHeaderTitle } = this.props;
    setHeaderTitle("User Accounts");
  }

  generateRoutes = (element, index) => {
    const { match } = this.props;
    return (
      <Route
        key={index}
        path={`${match.path}/${element.url}`}
        component={element.component}
      />
    );
  };

  render() {
    return (
      <Fragment>
        <Content className="user-account-container">
          {UserPageLinks.map(this.generateRoutes)}
        </Content>
      </Fragment>
    );
  }
}

const mapDispatchToProps = {
  getUserDetails,
  setHeaderTitle,
};

const mapStateToProps = (state) => ({
  name: userFullName(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(User);
