import { createSelector } from "reselect";
import { userCommittees } from "../user/selectors";
import { selectedNationalCommittee } from "../national-committees/selectors";

const id = (state, id) => id;
const rawNotifications = (state) => state.notifications.data;
export const notifications = (state) => state.notifications;

export const getPagination = (state) => state.notifications.pagination;

export const getNotifications = createSelector(
  [rawNotifications],
  (notifications) => {
    return Object.values(notifications).sort((a, b) => {
      if (a.pinned) return -1;
      if (b.pinned) return 1;
      return 0;
    });
  },
);

export const getPoll = (state) => state.notifications.notification.poll;

export const getNewNotificationFormData = (state) => state.notifications.form;

export const getNotification = (state) => state.notifications.notification;

export const isNotificationPinned = createSelector(
  [rawNotifications, id],
  (notifications, id) => {
    return notifications[id] ? notifications[id].pinned : false;
  },
);

export const isNotificationInstantSent = createSelector(
  [rawNotifications, id],
  (notifications, id) => {
    return notifications[id] ? notifications[id].isInstantSent : false;
  },
);

export const getNotificationDocumentsUri = createSelector(
  [getNotification],
  (notification) =>
    notification.referencesRawResponsePerType?.document?.map((el) => el.uri),
);

export const getNotificationUri = createSelector(
  [getNotification],
  (notification) => notification.uri,
);
export const getNotificationsFilters = createSelector(
  [notifications],
  (notifications) => notifications.filters,
);

export const getNumberOfUnseenNotifications = createSelector(
  [userCommittees, selectedNationalCommittee],
  (userCommittees, selectedNationalCommittee) =>
    userCommittees[selectedNationalCommittee]
      ? userCommittees[selectedNationalCommittee].unseenNotifications < 0
        ? 0
        : userCommittees[selectedNationalCommittee].unseenNotifications
      : null,
);

export const hasMoreNotificationsToLoad = createSelector(
  [getPagination],
  (pagination) => pagination.hasMoreToLoad,
  //(pagination) => pagination.pagesLoaded < pagination.pagesToLoad || pagination.pagesLoaded === 0
);

export const markAllNotificationsAsSeenRequestProcessing = (state) =>
  state.notifications.markAllNotificationsAsSeenRequestProcessing;

export const getNotificationRepository = (state) =>
  state.notifications.notificationRepository;
export const isProcessingRequest = (state) =>
  state.notifications.isProcessingRequest;
