import { combineActions, handleActions } from "redux-actions";
import * as actions from "./actions";
import * as deadlinesActions from "../deadlines/actions";
import * as commentsActions from "../comments/actions";
import * as votesActions from "../votes/actions";
import * as meetingsActions from "../meetings/actions";

import { NotificationsRepository } from "../../services/notifications/notificationsRepository";

import {
  normalizeNotificationDetails,
  normalizeNotifications,
  markNotificationsAsSeen,
  updateNotificationsOnDeadlineUpdate,
  addCommnetToDeadlineNotifications,
  addVoteToDeadlineNotifications,
  removeVoteToDeadlineNotifications,
  removeCommnetToDeadlineNotifications,
  updateNotificationsOnMeetingUpdate,
  removeNotificationsRelatedToResource,
  removeNotificationById,
  setIsInstantSent,
} from "./normalizers";

import {
  normalizeDocument,
  normalizeListOfDocuments,
  removeReferenceById,
  updateEditedDocument,
} from "../document/normalizers";
import { referenceListToReferenceDataPerType } from "../../utils/references";

export const INSTANT_EMAIL_PARAM_TYPE = {
  COMMITTEE_ID: "committeeId",
  NOTIFICATION_ID: "notificationId",
};

const initialForm = {
  title: "",
  description: "",
  notifyMembers: false,
  references: [],
};

const initialState = {
  isProcessingRequest: false,
  isProcessed: false,
  notification: null,
  data: {},
  pagination: {
    pagesLoaded: 0,
    pagesToLoad: 0,
    hasMoreToLoad: true,
  },
  form: initialForm,
  filters: {
    committee: null,
    type: null,
    onlyUnseen: false,
  },
  poll: {},
  markAllNotificationsAsSeenRequestProcessing: false,
  notificationRepository: new NotificationsRepository(),
};

const actionHandlers = {
  [combineActions(
    actions.updateNotification,
    actions.deleteNotification,
    actions.createNotification,
  )]: (state) => ({
    ...state,
    isProcessingRequest: true,
  }),
  [combineActions(actions.initNotifications)]: (state) => ({
    ...state,
    isProcessingRequest: true,
    pagination: {
      pagesLoaded: 0,
      pagesToLoad: 0,
      hasMoreToLoad: true,
    },
    data: {},
    notificationRepository: new NotificationsRepository(),
  }),
  [actions.pullNotificationsPageSucceeded]: (state, { payload }) => ({
    ...state,
    isProcessingRequest: false,
    isProcessed: true,
    data: {
      ...state.data,
      ...normalizeNotifications(payload.results),
    },
    pagination: {
      pagesLoaded: state.pagination.pagesLoaded + 1,
      pagesToLoad: payload.pagesToLoad,
      hasMoreToLoad: payload.hasMoreToLoad,
    },
  }),

  [actions.setFilterValue]: (state, { payload }) => ({
    ...state,
    filters: {
      ...state.filters,
      ...payload,
    },
  }),
  [actions.resetNotificationsFilters]: (state) => ({
    ...state,
    filters: {
      ...state.filters,
      committee: null,
      type: null,
      onlyUnseen: false,
    },
  }),
  [combineActions(
    actions.pullNotificationsPageFailed,
    actions.updateNotificationFailed,
    actions.deleteNotificationFailed,
    actions.updateNotificationSucceeded,
    actions.createNotificationSucceeded,
    actions.createNotificationFailed,
  )]: (state, { payload }) => {
    // console.log("state : check referencePerType!", state)
    // console.log("payload : check referencePerType!", payload)

    return {
      ...state,
      isProcessingRequest: false,
    };
  },
  // [combineActions(
  //   actions.removeReferenceFromNotificationSucceeded,
  //   )]: (state, { payload }) => {
  //   console.log("state : check referencePerType!", state)
  //   console.log("payload : check referencePerType!", payload)

  //   const newNotification = payload;
  //   newNotification.referencesRawResponsePerType = referenceListToReferenceDataPerType(newNotification.references);
  //   newNotification.referencesPerType = normalizeObjectOfReferences(newNotification.references);

  //   return ({
  //   ...state,
  //   isProcessingRequest: false,
  // })},
  [actions.deleteNotificationSucceeded]: (state, { payload }) => ({
    ...state,
    isProcessingRequest: false,
    data: removeNotificationById(state.data, payload),
  }),
  [actions.setSelectedNotificationDocuments]: (state, { payload }) => ({
    ...state,
    form: {
      ...state.form,
      referencesPerType: {
        ...state.form.referencesPerType,
        document: [...payload],
      },
    },
  }),
  [actions.setSelectedNotificationDeadlines]: (state, { payload }) => ({
    ...state,
    form: {
      ...state.form,
      referencesPerType: {
        ...state.form.referencesPerType,
        deadline: [...payload],
      },
    },
  }),
  [actions.setSelectedNotificationMeetings]: (state, { payload }) => ({
    ...state,
    form: {
      ...state.form,
      referencesPerType: {
        ...state.form.referencesPerType,
        meeting: [...payload],
      },
    },
  }),

  [actions.addUploadedNotificationDocuments]: (state, { payload }) => ({
    ...state,
    form: {
      ...state.form,
      references: [...state.form.references, normalizeDocument(payload)],
    },
  }),

  [actions.addGeneralNotificationInformation]: (state, { payload }) => ({
    ...state,
    form: {
      ...state.form,
      ...payload,
    },
  }),

  [actions.initNotificationFormWithDocuments]: (state, { payload }) => ({
    ...state,
    form: {
      ...state.form,
      referencesPerType: {
        document: normalizeListOfDocuments(payload),
      },
    },
  }),

  [actions.clearNotificationForm]: (state) => ({
    ...state,
    form: initialForm,
  }),

  [actions.fetchUpdatedNotificationDocumentSucceeded]: (
    state,
    { payload },
  ) => ({
    ...state,
    form: {
      ...state.form,
      references: updateEditedDocument(state.form.references, payload),
    },
  }),

  [actions.removeDocumentFromNotification]: (state, { payload }) => ({
    ...state,
    form: {
      ...state.form,
      references: removeReferenceById(state.form.references, payload),
    },
  }),

  [actions.getNotificationDetailsSucceeded]: (state, { payload }) => ({
    ...state,
    data: {
      ...state.data,
      [payload.notificationId]: {
        ...state.data[payload.notificationId],
        details: normalizeNotificationDetails(
          payload,
          state.data[payload.notificationId],
        ),
        fetched: true,
      },
    },
  }),

  // [actions.getCommentNotificationDetailsFailed] : (state, {payload}) => {
  //     return {
  //         ...state,
  //         isProcessingRequest: false,
  //         data: {
  //             ...state.data,
  //             [payload.notificationId]: {
  //                 ...state.data[payload.notificationId],
  //                 details: payload
  //             }
  //         }
  //     }
  // },

  [combineActions(
    actions.fetchNotificationSucceeded,
    actions.removeReferenceFromNotificationSucceeded,
  )]: (state, { payload }) => {
    return {
      ...state,
      notification: {
        ...payload,
        referencesRawResponsePerType: referenceListToReferenceDataPerType(
          payload.references,
        ),
      },
    };
  },

  [actions.fetchNotificationReferencesSucceeded]: (state, { payload }) => ({
    ...state,
    notification: {
      ...state.notification,
      referencesPerType: payload.referencesPerType,
    },
  }),
  [actions.fetchNotificationDocumentsSucceeded]: (state, { payload }) => ({
    ...state,
    notification: {
      ...state.notification,
      references: normalizeListOfDocuments(payload),
    },
  }),
  [actions.clearNotificationList]: (state) => ({
    ...state,
    data: {},
    notificationRepository: new NotificationsRepository(),
  }),

  [actions.pinNotificationSucceeded]: (state, { payload }) => ({
    ...state,
    data: {
      ...state.data,
      [payload]: {
        ...state.data[payload],
        pinned: true,
      },
    },
  }),

  [actions.unpinNotificationSucceeded]: (state, { payload }) => ({
    ...state,
    data: {
      ...state.data,
      [payload]: {
        ...state.data[payload],
        pinned: false,
      },
    },
  }),

  [actions.markNotificationsAsSeenSucceeded]: (state, { payload }) => ({
    ...state,
    data: {
      ...state.data,
      [payload]: {
        ...state.data[payload],
        markedAsSeen: true,
      },
    },
  }),
  [actions.getNotificationDetailsFailed]: (state, { payload }) => ({
    ...state,
    data: {
      ...state.data,
      [payload.notificationId]: {
        ...state.data[payload.notificationId],
        failedFetch: true,
        fetched: true,
      },
    },
  }),
  [actions.markAllNotificationsAsSeen]: (state, { payload }) => ({
    ...state,
    markAllNotificationsAsSeenRequestProcessing: true,
  }),
  [actions.markAllNotificationsAsSeenSucceeded]: (state, { payload }) => ({
    ...state,
    markAllNotificationsAsSeenRequestProcessing: false,
    data: markNotificationsAsSeen({ ...state.data }),
  }),
  [actions.markAllNotificationsAsSeenFailed]: (state, { payload }) => ({
    ...state,
    markAllNotificationsAsSeenRequestProcessing: false,
  }),
  [actions.updateNotificationDocumentInfo]: (state, { payload }) => ({
    ...state,
    form: {
      ...state.form,
      references: state.form.references.map((d) =>
        d.url !== payload.url ? d : payload,
      ),
    },
  }),

  [actions.sendInstantEmailSucceeded]: (state, { payload }) => ({
    ...state,
    data: setIsInstantSent(state.data, payload, true),
  }),
  [actions.updatePollSucceeded]: (state, { payload }) => ({
    ...state,
    notification: {
      ...state.notification,
      poll: payload,
    },
  }),

  // deadlines updates
  [deadlinesActions.updateDeadlineSucceeded]: (state, { payload }) => ({
    ...state,
    data: updateNotificationsOnDeadlineUpdate(state.data, payload),
  }),
  [deadlinesActions.deleteDeadlineSucceeded]: (state, { payload }) => ({
    ...state,
    data: removeNotificationsRelatedToResource(state.data, payload),
  }),
  [commentsActions.createCommentSucceeded]: (state, { payload }) => ({
    ...state,
    data: addCommnetToDeadlineNotifications(state.data, payload),
  }),
  [commentsActions.deleteCommentSucceeded]: (state, { payload }) => ({
    ...state,
    data: removeCommnetToDeadlineNotifications(state.data, payload),
  }),
  [votesActions.castNewVoteSucceeded]: (state, { payload }) => ({
    ...state,
    data: addVoteToDeadlineNotifications(state.data, payload),
  }),
  [votesActions.removeVoteSucceeded]: (state, { payload }) => ({
    ...state,
    data: removeVoteToDeadlineNotifications(state.data, payload),
  }),

  // meetings updates
  [meetingsActions.updateMeetingSucceeded]: (state, { payload }) => ({
    ...state,
    data: updateNotificationsOnMeetingUpdate(state.data, payload),
  }),
  [meetingsActions.deleteMeetingSucceeded]: (state, { payload }) => ({
    ...state,
    data: removeNotificationsRelatedToResource(state.data, payload),
  }),
};

export default handleActions(actionHandlers, initialState);
