import { handleActions } from "redux-actions";
import { normalizeDocumentSummary, updateDocument } from "./normalizers";
import * as actions from "./actions";

const initialState = {
  isFetching: false,
  hasFailed: false,
  details: null,
};

const actionHandlers = {
  [actions.generateDocumentsSummary]: (state) => ({
    ...state,
    isFetching: true,
    details: null,
  }),
  [actions.generateDocumentsSummarySucceeded]: (state, { payload }) => ({
    ...state,
    isFetching: false,
    hasFailed: false,
    details: normalizeDocumentSummary(payload),
  }),
  [actions.generateDocumentsSummaryFailed]: (state) => ({
    ...state,
    isFetching: false,
    hasFailed: true,
    details: null,
  }),
  [actions.updateDocumentState]: (state, { payload }) => ({
    ...state,
    details: updateDocument(state.details, payload),
  }),
};

export default handleActions(actionHandlers, initialState);
