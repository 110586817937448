import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import {
  addGeneralMeetingInformation,
  initMeetingFormWithGeneralInfo,
  updateMeeting,
} from "../../../../../state/meetings/actions";
import { getNewMeetingFormData } from "../../../../../state/meetings/selectors";
import {
  DIALOG_TYPES,
  generateDialogLink,
} from "../../../../../utils/dynamicLink";
import MeetingForm from "../meeting-form/MeetingForm";

class EditMeeting extends Component {
  onMount = () => {
    const uri = this.props.context.resource;
    this.props.initMeetingFormWithGeneralInfo(uri);
  };

  setMeetingData = (object) => {
    this.props.addGeneralMeetingInformation(object);
  };

  get editorSubmitAction() {
    return this.props.updateMeeting;
  }

  get editorCancelAction() {
    return generateDialogLink(
      DIALOG_TYPES.MEETING_DETAILS,
      this.props.formData.uri,
    );
  }

  render() {
    const { t, formData } = this.props;
    const detailTitle = t("meetings.detail.edit.title");

    return (
      <MeetingForm
        formData={formData}
        onMount={this.onMount}
        editorSubmitAction={this.editorSubmitAction}
        editorCancelAction={this.editorCancelAction}
        setMeetingData={this.setMeetingData}
        detailTitle={detailTitle}
      />
    );
  }
}

const mapDispatchToProps = {
  updateMeeting,
  addGeneralMeetingInformation,
  initMeetingFormWithGeneralInfo,
};

const mapStateToProps = (state) => ({
  formData: getNewMeetingFormData(state),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation("common"),
)(EditMeeting);
