import React, { Component } from "react";
import ReactModal from "react-modal";
import classNames from "classnames";

import types from "./contentTypes";
import { dialogSizes } from "../../../utils/constants";
import history from "../../../utils/history";
import {
  getModalDepth,
  getModalStackHistory,
  getPreviousModal,
} from "../../../state/modal-stack/selectors";
import { connect } from "react-redux";
import { compose } from "redux";
import { emptyModalStack } from "../../../state/modal-stack/actions";
import { v4 } from "uuid";

const SLIDE_DURATION = 300;
const SLIDE_STATUS = {
  TO_LEFT: "before",
  TO_RIGHT: "after",
};

class ModalWrapper extends Component {
  state = {
    slided: SLIDE_STATUS.TO_RIGHT,
    // This closing is to prevent re-rendering of the children while closing
    // (due to setTimeout in handleClose, it will rerender the children before closing)
    closing: false,
  };

  componentDidMount() {
    // after it's rendered
    if (!this.props.disableSlide) {
      setTimeout(() => {
        this.setSlided(SLIDE_STATUS.TO_LEFT);
      });
    }
  }

  modalClassName = classNames("modal__content", {
    "modal__content--fixed-height":
      types.EDIT_FILE === this.props.contentType ||
      this.props.sizeType === dialogSizes.fixedHeight,
    "modal__content--wide":
      types.EXPORT_TO_WORD === this.props.contentType ||
      this.props.sizeType === dialogSizes.wide,
    "modal__content--big": this.props.sizeType === dialogSizes.big,
  });

  setSlided = (slided) => {
    if (this._isUnmounted) return;

    this.setState({ slided });

    // When closing, by having closing state, we prevent re-rendering of the children
    if (slided === SLIDE_STATUS.TO_RIGHT) {
      this.setState({ closing: true });
    }
  };

  get isNestedModal() {
    const modalDepth = this.props.getModalDepth;
    return modalDepth > 1;
  }

  handleClose = (event, cb) => {
    if (this.isNestedModal || this.props.disableSlide) {
      cb(event);
    } else {
      this.setSlided(SLIDE_STATUS.TO_RIGHT);
      setTimeout(() => cb(event), SLIDE_DURATION);
    }
  };

  onClickClose = (event) => {
    const { requestCloseHandler } = this.props;

    this.props.emptyModalStack();
    this.handleClose(event, requestCloseHandler);
  };

  onGoBack = () => {
    // history.goBack();
    this.handleClose(null, history.goBack);
  };

  // Function to add props to children
  renderChildrenWithProps = () => {
    const { children, requestCloseHandler } = this.props;

    if (!children) return null;

    /**
    ** This is for multiple children **
    For example;
      <ModalWrapper>
        <Child1 />
        <Child2 />
      </ModalWrapper>

      To avoid this, have only one root element under ModalWrapper

      <ModalWrapper>
        <>
          <Child1 />
          <Child2 />
        </>
      </ModalWrapper>

     */
    // return children.map((child) =>
    //   React.cloneElement(child, {
    //     cancelHandler: requestCloseHandler,
    //   }),
    // );

    return React.cloneElement(children, {
      cancelHandler: requestCloseHandler,
    });
  };

  componentWillUnmount() {
    this._isUnmounted = true;
  }

  render() {
    const {
      isOpen,
      // children,
      verticalCentered = true,
      disableSlide,
      // requestCloseHandler,
      showGoBackButton,
      showCloseButton,
      shouldCloseOnOverlayClick,
      // footer,
    } = this.props;

    if (!isOpen) return null;

    return (
      <ReactModal
        isOpen={isOpen}
        overlayClassName={classNames("modal__overlay", {
          "modal__overlay--center": verticalCentered,
          slide: !disableSlide,
        })}
        className="modal__tabindex-wrapper"
        bodyOpenClassName="is-modal-opened"
        htmlOpenClassName="is-modal-opened"
        onRequestClose={this.onClickClose}
        shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      >
        <div
          className={
            "modal__content-wrapper" +
            (!disableSlide && this.state.slided === SLIDE_STATUS.TO_LEFT
              ? " after-slide"
              : " before-slide")
          }
        >
          <div className={this.modalClassName}>
            <div className="modal__header">
              {showGoBackButton && this.isNestedModal && (
                <div className="modal__header__left">
                  <button
                    className="modal__go_back_button icon-arrow-left"
                    onClick={this.onGoBack}
                  />
                  <span className="modal__title">
                    {this.props.getModalStackHistory ?? ""}
                  </span>
                </div>
              )}
              {showCloseButton && (
                <div
                  className="modal__close_button"
                  onClick={this.onClickClose}
                >
                  <span aria-hidden="true">&times;</span>
                </div>
              )}
            </div>
            <div className="modal__body">
              {isOpen && !this.state.closing
                ? this.renderChildrenWithProps()
                : null}
            </div>
          </div>
        </div>
      </ReactModal>
    );
  }
}

const mapStateToProps = (state) => ({
  getModalDepth: getModalDepth(state),
  getPreviousModal: getPreviousModal(state),
  getModalStackHistory: getModalStackHistory(state),
});

const mapDispatchToProps = {
  emptyModalStack: emptyModalStack,
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  ModalWrapper,
);
