import { handleActions } from "redux-actions";
import * as actions from "./actions";
import { normalizeMembers, setActiveState } from "./normalizer";

const initialState = {
  isFetching: true,
  isFetched: false,
  data: {},
  errorMessage: null,
};

const actionHandlers = {
  [actions.getCommitteeMembers]: (state) => ({
    ...state,
    isFetching: true,
    isFetched: false,
  }),
  [actions.getCommitteeMembersSucceeded]: (state, { payload }) => ({
    ...state,
    isFetching: false,
    isFetched: true,
    data: {
      ...state.data,
      ...normalizeMembers(payload),
    },
  }),
  [actions.getCommitteeMembersFailed]: (state, { payload }) => ({
    ...state,
    isFetching: false,
    isFetched: true,
    errorMessage: payload,
  }),
  [actions.setMemberActiveStateSucceeded]: (state, { payload }) => ({
    ...state,
    data: setActiveState(state.data, payload.userId, payload.state),
  }),
};

export default handleActions(actionHandlers, initialState);
