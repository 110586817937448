import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withTranslation } from "react-i18next";

import "./RelatedContent.scss";
import RelatedDeadlineContent from "./RelatedDeadlineContent";
import RelatedNotificationContent from "./RelatedNotificationContent";
import RelatedMeetingContent from "./RelatedMeetingContent";

class RelatedContent extends Component {
  render() {
    const { document, closeModal } = this.props;

    const relatedDeadlines = document.relatedContent?.filter(
      (item) => item.type === "Deadline",
    );
    const relatedNotifications = document.relatedContent?.filter(
      (item) => item.type === "Notification",
    );
    const relatedMeetings = document.relatedContent?.filter(
      (item) => item.type === "Meeting",
    );

    return (
      <div className="related-content-wrapper">
        <RelatedNotificationContent
          relatedNotifications={relatedNotifications}
          id={this.props.id}
          closeModal={closeModal}
        />
        <RelatedDeadlineContent
          relatedDeadlines={relatedDeadlines}
          id={this.props.id}
        />
        <RelatedMeetingContent
          relatedMeetings={relatedMeetings}
          id={this.props.id}
        />
      </div>
    );
  }
}

// const mapStateToProps = (state) => ({
// });

// const mapDispatchToProps = {
// };

export default compose(
  withTranslation("common"),
  // connect(null, mapDispatchToProps),
)(RelatedContent);
