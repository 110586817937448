import React, { Component, Fragment } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";

import { withRouter } from "react-router";
import { NavLink } from "react-router-dom";
import CardContent from "../../../../common/card/CardContent";
import CardAttachments from "../../../../common/card/CardAttachments";
import { transformUri } from "../../../../../utils/transform";
import Loader from "../../../../common/loader/Loader";
import {
  DIALOG_TYPES,
  PAGE_TYPES,
  generateDialogLink,
  generateDialogLinkForPage,
} from "../../../../../utils/dynamicLink";
import { isConsultant } from "../../../../../state/user/selectors";
import history from "../../../../../utils/history";
import { selectedNationalCommittee } from "../../../../../state/national-committees/selectors";
import NotificationBar from "../notification-bar/NotificationBar";
import Avatar from "../../../../common/avatar/Avatar";

class UserNotification extends Component {
  getCardActions() {
    const {
      t,
      selectedNationalCommittee,
      data: { notificationId },
      isConsultant,
    } = this.props;

    if (!isConsultant) return [];

    const editActionCallback = () => {
      const link = generateDialogLinkForPage(
        selectedNationalCommittee,
        PAGE_TYPES.NOTIFICATION,
        DIALOG_TYPES.EDIT_NOTIFICATION,
        notificationId,
      );
      history.push(link);
    };

    return [{ label: t("card.edit"), callback: editActionCallback }];
  }

  render() {
    const { comments, messages, details, notificationId, eventDate } =
      this.props.data;
    const { t } = this.props;

    const notificationDetailsUrl = generateDialogLink(
      DIALOG_TYPES.NOTIFICATION_DETAILS,
      notificationId,
    );

    return details ? (
      <Fragment key={notificationId}>
        <NotificationBar
          title={t("notifications.newPostAdded")}
          date={eventDate}
          notificationMenu={{
            committee: details.committee,
            notificationId: notificationId,
            customActions: this.getCardActions(),
          }}
        />

        <div className="notification-card__user-bar">
          <Avatar
            isSmall={true}
            profileImage={details.member.profileImage}
            name={details.member.name}
          />
          <div>
            <span>
              <strong>{details.member.name}</strong>
            </span>
            <div>
              <div className="card__header">
                <div className="card__headings notification-card__heading">
                  <NavLink
                    to={notificationDetailsUrl}
                    className="card__heading-2"
                    data-link-type="open"
                  >
                    {details.title}
                  </NavLink>
                </div>
              </div>

              <CardContent description={details.description} />

              <CardAttachments
                // TODO: This `references` should be fixed - DS-backend
                // Possibly column name should be changed too.
                references={details.references && details.references.length}
                comments={comments && comments.length}
                messages={messages && messages.length}
                uri={notificationDetailsUrl}
              />
            </div>
          </div>
        </div>
      </Fragment>
    ) : (
      <Loader />
    );
  }
}

const mapStateToProps = (state) => ({
  isConsultant: isConsultant(state),
  selectedNationalCommittee: selectedNationalCommittee(state),
});

export default compose(
  withTranslation("common"),
  connect(mapStateToProps),
  withRouter,
)(UserNotification);
