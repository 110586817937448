import {
  referencesPerTypeToPatchQuery,
  referencesPerTypeToReferences,
} from "./references";

// THIS IS TO CREATE DEADLINE
export function mapToApiModel(deadline) {
  const references = referencesPerTypeToReferences(deadline.referencesPerType);

  return {
    ...deadline,
    // id: deadline.id,
    // uri: deadline.uri,
    committeeReference: deadline.committee,
    committeeReferenceTitle: deadline.committeeName,
    title: deadline.title.trim(),
    description: deadline.description.trim(),
    // type: deadline.type,
    // stage: deadline.stage,
    actionDueDate: deadline.date,
    // action: deadline.action,
    // enableVoting: deadline.enableVoting,
    // enableCommenting: deadline.enableCommenting,
    // closedForComments: deadline.closedForComments,
    references,
  };
}

export function mapDeadlineToPatchQuery(deadline, notify) {
  const toEncodedIsoString = (date) => {
    return date ? encodeURIComponent(date) : null;
  };
  const toEncodedUri = (value) =>
    value !== null ? encodeURIComponent(value) : null;
  return {
    uri: toEncodedUri(deadline.uri ? deadline.uri : null),
    committeeReference: toEncodedUri(deadline.committee),
    title: toEncodedUri(deadline.title),
    description: toEncodedUri(deadline.description),
    type: toEncodedUri(deadline.type),
    stage: toEncodedUri(deadline.stage),
    actionDueDate: toEncodedIsoString(deadline.date),
    action: toEncodedUri(deadline.action),
    enableVoting: toEncodedUri(deadline.enableVoting),
    enableCommenting: toEncodedUri(deadline.enableCommenting),
    notify: toEncodedUri(notify),
    closedForComments: toEncodedUri(deadline.closedForComments),
  };
}

export function mapDeadlineReferencesToPatchQuery(deadline) {
  // const toEncodedUri = (value) =>
  //   value !== null ? encodeURIComponent(value) : null;

  const references = referencesPerTypeToPatchQuery(deadline.referencesPerType);

  return references;
}

export function mapToAggDeadlinesQuery(committeeIds, params) {
  return {
    nationalCommittees: committeeIds,
    start: params.start,
    pageLength: params.pageLength,
    actionDueDates: [params.actionDueDate],
    sort: params.sort,
  };
}
