import React from "react";
import userManager from "../../../services/auth/openIdClient";

class SilentRefresh extends React.Component {
  componentDidMount() {
    userManager.signinSilentCallback().catch((error) => console.error(error));
  }

  render() {
    return null;
  }
}

export default SilentRefresh;
