import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { compose } from "redux";
import "./Committees.scss";
import List from "../../common/list/List";
import ListBreadcrumb from "../../common/list/BreadcrumbList";
import {
  getInternationalCommittees,
  selectedNationalCommittee,
} from "../../../state/national-committees/selectors";
import { Helmet } from "react-helmet";
import { setPageTitle } from "../../../utils/pageTitle";

import Loader from "../../common/loader/Loader";
import { getAssociatedCommitteesByCommitteeId } from "../../../state/national-committees/actions";

class Committees extends Component {
  state = {
    selectedParent: null,
  };

  componentDidMount() {
    const {
      internationalCommittees,
      getAssociatedCommitteesByCommitteeId,
      committee,
    } = this.props;

    if (internationalCommittees.length === 0) {
      getAssociatedCommitteesByCommitteeId({
        committeeId: committee,
      });
    }
  }

  //todo: move to selectors!
  getChildrenList = (list, parent) => {
    return list.filter((element) => element.parent === parent);
  };

  getList = (list, parent) => {
    return list
      .filter((element) => element.parent === (parent && parent.name))
      .map((element) => {
        element.children = this.getChildrenList(list, element.name);
        return element;
      });
  };

  getPrevList = (list, element) => {
    return (
      (element &&
        element.name &&
        list.find((x) => x.name === element.parent)) ||
      null
    );
  };

  render() {
    const { t } = this.props;

    return (
      <Fragment>
        <Helmet>
          <title>
            {setPageTitle(
              [t("internationalCommittees.title")],
              this.props.committee,
            )}
          </title>
        </Helmet>
        <header className="layout__title">
          <h2 className="headline-2">
            {t("internationalCommittees.header", {
              committee: this.props.committee,
            })}
          </h2>
        </header>

        <div className="layout__content panel panel--border committee-content">
          <div className="committee-content__list-wrapper">
            {this.state.selectedParent && (
              <ListBreadcrumb
                element={this.state.selectedParent}
                handleElementClick={() =>
                  this.setState({
                    selectedParent: this.getPrevList(
                      this.props.internationalCommittees,
                      this.state.selectedParent,
                    ),
                  })
                }
              />
            )}
            <List
              list={this.getList(
                this.props.internationalCommittees,
                this.state.selectedParent,
              )}
              handleElementClick={(element) =>
                this.setState({ selectedParent: element })
              }
            />
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapDispatchToProps = {
  getAssociatedCommitteesByCommitteeId: getAssociatedCommitteesByCommitteeId,
};

const mapStateToProps = (state) => ({
  internationalCommittees: getInternationalCommittees(state),
  committee: selectedNationalCommittee(state),
});

export default compose(
  withTranslation("common"),
  connect(mapStateToProps, mapDispatchToProps),
)(Committees);
