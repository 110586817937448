import React, { Component, Fragment, createRef } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";
import {
  getDocuments,
  hasMoreDocumentsToLoad,
} from "../../../../../state/document/selectors";
import Loader from "../../../../common/loader/Loader";
import Filters from "../filters/DocumentFilters";
import {
  uploadSelectedDocuments,
  resetFiltersForm,
  pullNextDocumentsPageRequest,
  initDocuments,
  reloadDocuments,
} from "../../../../../state/document/actions";

import history from "../../../../../utils/history";
import UploadButtons from "../upload-buttons/UploadButtons";
import ActionButtons from "../action-buttons/ActionButtons";
import DocumentsDrop from "../documents-drop/DocumentsDrop";
import { documentUploadType } from "../../../../../utils/constants";
import WithPrivileges from "../../../../common/with-privileges/WithPrivileges";
import DocumentList from "../../../../common/document-list/List";
import UrlPattern from "url-pattern";
import { setPageTitle } from "../../../../../utils/pageTitle";
import { Helmet } from "react-helmet";
import { selectedNationalCommittee } from "../../../../../state/national-committees/selectors";

import { InfiniteScrollWrapper } from "../../../../common/infinite-scroll-wrapper/InfiniteScrollWrapper";

class CommitteeDocuments extends Component {
  infinityLoaderRef = createRef();

  state = {
    selectedFiles: [],
  };

  componentDidMount() {
    this.parser = new UrlPattern("/:committeeId/:segment*");
    this.unlisten = history.listen((location, action) => {
      const result = this.parser.match(location.pathname);
      if (result && result.segment === "documents") {
        this.props.reloadDocuments(action);
      }
    });

    this.props.initDocuments();
    this.infinityLoaderRef.current.reset();
  }

  componentWillUnmount() {
    this.unlisten();
    this.props.resetFiltersForm();
  }

  createHandler = (event) => {
    this.props.uploadSelectedDocuments({
      documents: event.target.files,
      uploadType: documentUploadType.file,
      notifyOnUpdate: true,
      notifyImmediately: false,
    });
  };

  onDropFiles = (files) => {
    this.props.uploadSelectedDocuments({
      documents: files,
      uploadType: documentUploadType.file,
      notifyOnUpdate: true,
      notifyImmediately: false,
    });
  };

  componentDidUpdate(prevProps) {
    if (prevProps.documents !== this.props.documents) {
      this.setState({ selectedFiles: [] });
    }
  }

  selectHandler = (element) => {
    this.state.selectedFiles.filter((el) => el.url === element.url).length
      ? this.setState({
          selectedFiles: this.state.selectedFiles.filter(
            (el) => el.url !== element.url,
          ),
        })
      : this.setState({
          selectedFiles: [...this.state.selectedFiles, element],
        });
  };

  onCreateNewNotification = () => {};

  onCreateNewMeeting = () => {
    // this.props.createMeetingFromSelectedDocuments(this.state.selectedFiles);
  };

  onCreateNewDeadline = () => {
    // this.props.createDeadlineFromSelectedDocuments(this.state.selectedFiles);
  };

  loadMoreItems = (page) => {
    this.props.pullNextDocumentsPageRequest();
  };

  render() {
    const { selectedNationalCommittee } = this.props;
    return (
      <Fragment>
        <Helmet>
          <title>
            {setPageTitle(["Documents"], selectedNationalCommittee)}
          </title>
        </Helmet>
        <WithPrivileges>
          {!this.state.selectedFiles.length ? (
            <UploadButtons
              handleLinkDocuments={this.props.handleLinkDocuments}
              createHandler={this.createHandler}
            />
          ) : (
            <ActionButtons
              onCreateNewNotification={this.onCreateNewNotification}
              onCreateNewMeeting={this.onCreateNewMeeting}
              onCreateNewDeadline={this.onCreateNewDeadline}
            />
          )}
        </WithPrivileges>
        <Filters />
        <DocumentsDrop onDropFiles={this.onDropFiles}>
          <InfiniteScrollWrapper
            pageStart={0}
            initialLoad={false}
            loadMore={this.loadMoreItems}
            hasMore={this.props.hasMoreDocumentsToLoad}
            loader={<Loader key="infinity_scroll_loader" type="content" />}
            ref={this.infinityLoaderRef}
          >
            <DocumentList
              list={this.props.documents}
              selectHandler={this.selectHandler}
              showActionBox={true}
              enableSelection={false}
              manualSort={false}
              showConnections={true}
              showExtendedActions={true}
            />
          </InfiniteScrollWrapper>
        </DocumentsDrop>
      </Fragment>
    );
  }
}

const mapDispatchToProps = {
  uploadSelectedDocuments,
  resetFiltersForm,
  pullNextDocumentsPageRequest,
  initDocuments,
  reloadDocuments,
};

const mapStateToProps = (state) => ({
  documents: getDocuments(state),
  selectedNationalCommittee: selectedNationalCommittee(state),
  hasMoreDocumentsToLoad: hasMoreDocumentsToLoad(state),
});

export default compose(
  withTranslation("common"),
  connect(mapStateToProps, mapDispatchToProps),
)(CommitteeDocuments);
