import { createAction } from "redux-actions";

const GENERATE_DOCUMENTS_SUMMARY = "DOCUMENTSUMMARY/generate_summary";
const GENERATE_DOCUMENTS_SUMMARY_SUCCEEDED =
  "DOCUMENTSUMMARY/generate_summary_succeeded";
const GENERATE_DOCUMENTS_SUMMARY_FAILED =
  "DOCUMENTSUMMARY/generate_summary_failed";
const UPDATE_DOCUMENT_STATE = "DOCUMENTSUMMARY/update_document_state";

const DOWNLOAD_DOCUMENTS_SUMMARY_FILES = "DOCUMENTSUMMARY/download_files";

export const generateDocumentsSummary = createAction(
  GENERATE_DOCUMENTS_SUMMARY,
);
export const generateDocumentsSummarySucceeded = createAction(
  GENERATE_DOCUMENTS_SUMMARY_SUCCEEDED,
);
export const generateDocumentsSummaryFailed = createAction(
  GENERATE_DOCUMENTS_SUMMARY_FAILED,
);
export const updateDocumentState = createAction(UPDATE_DOCUMENT_STATE);

export const downloadDocumentsSummaryFiles = createAction(
  DOWNLOAD_DOCUMENTS_SUMMARY_FILES,
);
