import { takeEvery, call, put, all, select } from "redux-saga/effects";
import {
  getVotes,
  createVote,
  updateVote,
  removeVote,
  getVoteOverride,
  updateVoteOverride,
} from "../api/resources/votes";
import { waitForNotificationDocumentId } from "../api/resources/notifications";
import { getMemberById } from "../api/resources/members";

import * as actions from "../state/votes/actions";
import { isRequestSuccessful } from "../api/utils";

import { getMembers } from "../state/members/selectors";
import { handleCreateNotificationBySystem } from "./notifications";
import { notificationActionType, notificationType } from "../utils/constants";

function* initDeadlineVotes(action) {
  try {
    const [votes, override] = yield all([
      call(getVotes, action.payload),
      call(getVoteOverride, action.payload),
    ]);
    if (!isRequestSuccessful(votes) || !isRequestSuccessful(override)) {
      console.error("Init votes failed");
      console.error(votes);
      console.error(override);
    }

    let data = {
      votes: votes.data,
      officialVoteOverride: override.data,
    };

    yield put(actions.initVotesSucceeded(data));
  } catch (error) {
    console.error("Init votes failed");
    console.error(error);
  }
}

export function* fetchVotes(action) {
  try {
    const results = yield call(getVotes, action.payload);

    if (results.status === 200) {
      yield put(actions.getAllVotesSucceeded(results.data));
    } else {
    }
  } catch (error) {
    console.error(error);
  }
}

export function* fetchDeadlinesVotesStats(deadlines) {
  try {
    yield all(
      deadlines.map((deadline) => put(actions.getAllVotes(deadline.uri))),
    );
  } catch (error) {
    console.error(`Fetching deadline votes statistics failed -- ${error}`);
  }
}

export function* castVote(action) {
  try {
    const response = yield call(createVote, action.payload);
    if (response.status === 200) {
      // yield call(waitForNotificationDocumentId, action.payload.deadlineUri);
      yield call(handleCreateNotificationBySystem, {
        payload: {
          ...response.data,
          type: notificationType.vote,
          eventType: notificationActionType.add,
          references: [
            {
              type: notificationType.deadline,
              uri: action.payload.deadlineUri,
            },
          ],
        },
      });

      yield put(actions.castNewVoteSucceeded(response.data));
    }
  } catch (error) {
    console.error(error);
  }
}

export function* updateExistingVote(action) {
  try {
    const response = yield call(updateVote, action.payload);
    if (response.status === 200) {
      yield call(handleCreateNotificationBySystem, {
        payload: {
          ...response.data,
          type: notificationType.vote,
          eventType: notificationActionType.update,
          references: [
            {
              type: notificationType.deadline,
              uri: action.payload.deadlineUri,
            },
          ],
        },
      });

      yield put(actions.updateVoteSucceeded(action.payload));
    }
  } catch (error) {
    console.error(error);
  }
}

export function* handleRemoveVote(action) {
  try {
    const response = yield call(removeVote, action.payload.uri);
    if (response.status === 200) {
      yield put(actions.removeVoteSucceeded(action.payload));
    }
  } catch (error) {
    console.error(error);
  }
}

function* handleOverrideOfficialVote(action) {
  try {
    const response = yield call(
      updateVoteOverride,
      action.payload.uri,
      action.payload.vote,
    );
    if (isRequestSuccessful(response)) {
      yield put(actions.overrideOfficialVoteSucceeded(response.data));
    }
  } catch (error) {
    console.error(error);
  }
}

function* handleInitVotesMemberNames(action) {
  const members = yield select(getMembers);
  const actionVotes = action.payload;

  const votesWithoutMembers = actionVotes.filter(
    (v) => !v.member || typeof v.member === "undefined",
  );
  let updatedVotes = votesWithoutMembers.map((vote) => {
    const votingMember = members.find((m) => m.userID === vote.userID);
    if (votingMember) {
      return {
        ...vote,
        member: {
          name: votingMember.name,
          work: votingMember.work,
          photo: votingMember.photo,
        },
      };
    } else {
      return {
        ...vote,
        member: {
          loading: true,
        },
      };
    }
  });

  const votesReadyToShow = updatedVotes.filter((v) => !v.member.loading);
  const votesToLoad = updatedVotes.filter((v) => v.member.loading);
  yield all(
    votesReadyToShow.map((vote) =>
      put(
        actions.initVotesMemberNamesSucceeded({
          ...vote,
        }),
      ),
    ),
  );

  const missingNamesTasks = votesToLoad.map((v) => {
    return getMemberById(v.userID);
  });

  const missingMembers = yield all(missingNamesTasks);

  const loadedVotes = votesToLoad.map((vote) => {
    const votingMember = missingMembers.find((m) => m.data.id === vote.userID);

    if (votingMember) {
      return {
        ...vote,
        member: {
          name: votingMember.data.name,
          work: votingMember.data.company,
          photo: votingMember.data.profileImage,
        },
      };
    }

    return vote;
  });

  yield all(
    loadedVotes.map((vote) =>
      put(actions.initVotesMemberNamesSucceeded({ ...vote })),
    ),
  );
}

export default function* votesSaga() {
  yield takeEvery(actions.initDeadlineVotes, initDeadlineVotes);
  yield takeEvery(actions.GET_VOTES, fetchVotes);
  yield takeEvery(actions.CREATE_VOTE, castVote);
  yield takeEvery(actions.UPDATE_VOTE, updateExistingVote);
  yield takeEvery(actions.REMOVE_VOTE, handleRemoveVote);
  yield takeEvery(actions.overrideOfficialVote, handleOverrideOfficialVote);

  yield takeEvery(actions.initVotesMemberNames, handleInitVotesMemberNames);
}
