import { getBinaryFileDownloadUri } from "../../utils/dynamicLink";
import {
  makeOtherCommitteeIdIfEmpty,
  normalizeDate,
  removeWrongCharacters,
} from "../../utils/transform";

export function mergeDocuments(state, data) {
  const prevDocuments =
    state.data.results && state.data.results.hasOwnProperty(data.id)
      ? state.data.results[data.id]
      : [];

  const prevDocumentsUrls = prevDocuments.map(
    (prevDocument) => prevDocument.url,
  );

  const newDocuments = data.results
    .map(normalizeDocument)
    .filter((document) => !prevDocumentsUrls.includes(document.url));

  return {
    ...state.results,
    [data.id]: [...prevDocuments, ...newDocuments],
  };
}

export function normalizeDocuments(data) {
  return data.results
    .filter((d) => !!d)
    .reduce((previous, current) => {
      const document = normalizeDocument(current);

      previous[data.id]
        ? previous[data.id].push(document)
        : (previous[data.id] = [document]);

      return previous;
    }, {});
}

export function normalizeListOfDocuments(responses) {
  return responses
    .filter((d) => !!d)
    .map((response) =>
      normalizeDocument({
        ...response.data,
      }),
    );
}

export function normalizeDocument(document) {
  // TODO: THIS FILE WOULD NEED A HUGE REFACTORING ...

  function buildDownloadLink(document) {
    if (!document.references[0]) return "";

    if (document.references[0].link) return document.references[0].link;

    return getBinaryFileDownloadUri(document);
  }

  function isLink(document) {
    if (!document.references[0]) return false;

    return document.references[0].link ? true : false;
  }

  function hasDocumentExpired(document) {
    try {
      const data = document.metadata;
      if (
        data.documentType === "90" &&
        data.documentSubType === "90" &&
        data.documentDeadline
      ) {
        const now = new Date();
        return new Date(data.documentDeadline) < now;
      }
    } catch (e) {
      console.error(e);
    }

    return false;
  }

  if (document.metadata) {
    return {
      committee: makeOtherCommitteeIdIfEmpty(
        document.metadata.committeeReference,
      ),
      committeeID: document.metadata.committeeID,
      id: document.metadata.documentID,
      filename: document.references[0] ? document.references[0].fileName : "",
      title: removeWrongCharacters(document.metadata.documentTitle),
      description: document.metadata.documentDescription,
      action: document.metadata.documentAction,
      type: document.metadata.documentType,
      dueDate: normalizeDate(document.metadata.documentDeadline),
      date: normalizeDate(document.metadata.documentCreationDate),
      // TODO: URL or URI?
      url: document.metadata.notificationUri,
      uri: document.metadata.notificationUri,
      downloadUrl: buildDownloadLink(document),
      notifyOnUpdate: document.metadata.notifyInSummation,
      isLink: isLink(document),
      accessForbidden: hasDocumentExpired(document),
      relatedContent: null,
      // referenceType: document.type,
    };
  }

  return document;
}

export function updateEditedDocument(documents, editedDocument) {
  return documents.map((document) =>
    document.url === editedDocument.metadata.notificationUri
      ? normalizeDocument(editedDocument)
      : document,
  );
}

export function removeReferenceById(referencesPerType, id) {
  const newReferencesPerType = { ...referencesPerType };
  for (const key in newReferencesPerType) {
    newReferencesPerType[key] = newReferencesPerType[key].filter(
      (doc) => doc.uri !== id,
    );
  }
  return newReferencesPerType;
}

export function updateRelatedResources(state, payload) {
  const relatedContent = payload.results;
  const newState = {};

  if (!state.data.results) return newState;
  const keys = Object.keys(state.data.results);

  keys.forEach((k) => {
    newState[k] = state.data.results[k].map((d) => {
      if (d.url === payload.documentUri) {
        return { ...d, relatedContent: relatedContent };
      }
      return { ...d };
    });
  });

  return newState;
}
